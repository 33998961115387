<template>
  <div class="consumption-dashboard-container">
    <div class="content-drilldown-header-container">
      <div>
        <h2>{{ $t('insights.content.detailsHeadline') }}</h2>
        <span>{{ $t('insights.content.detailsSubHeadline') }}</span>
      </div>

      <ContentCSVDownload
        :orgNameInFocus="orgNameInFocus"
        :orgInFocus="orgInFocus ? orgInFocus.id : 0"
        :skillOrgInFocus="orgInFocus ? orgInFocus.id : structure.length ? structure[0].id : 0"
        :timeFilter="selectedTimeOptionString"
        :drillDownTableFilters="displaySelectedFilters"
        :searchInputText="searchText"
        :isC1="false"
        :dateRange="dateRange"
      />
    </div>

    <div class="dashboard-details-table-header-actions" :style="displaySelectedFilters.length && 'padding-bottom: 0rem;'" v-show="structureReady">
      <div class="dashboard-details-table-search">
        <v-text-field
          class="details-search-text-field"
          v-model="searchText"
          @input="checkSearchLength"
          @keypress.enter="searchName"
          @click:clear="handleSearchClear"
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('common.searchByName')"
          filled
          rounded
          solo
          clearable
          single-line
          variant="solo"
        />
      </div>
      <div class="consumption-details-table-filters">
        <InsightsDrillDowntableFilters @filter-applied="applyFilters" :filterResults="filterResults" @interface="getChildInterface" />
      </div>
    </div>

    <div class="dashboard-details-search-chips" :style="displaySelectedFilters.length && 'margin-top: -1.5625rem'" v-show="displaySelectedFilters.length && structureReady">
      <div>
        <span v-for="(item, index) in displaySelectedFilters" :key="index" id="chips-container">
          <v-chip in class="chip t-text-xs ma-2 t-bg-black" closable @click:close="onRemoveFilter(item)"> {{ item.name }} </v-chip>
        </span>
        <span class="clear-all-filters" @click="onClearFilters">{{ $t('common.clearAllFilters') }}</span>
      </div>
    </div>

    <div class="dashboard-details-table-container" v-show="structureReady">
      <div class="consumption-details-treeview-container">
        <div class="tree-view-header">{{ $t('orgStructure.orgChart') }}</div>
        <!-- 
        <TreeView v-if="treeViewReady" class="dashboard-details-treeview" :items="structure" activatable>
          <template v-slot:label="{ item, active }">
            <div v-if="item.hasOwnProperty('lead')" @click="active ? $event.stopPropagation() : onGetPlan(item)" class="t-flex t-justify-between t-items-center">
              <span :id="`treeview-node-label-${item.id}`" class="dept-name">{{ item.name }}</span>
            </div>
          </template>
          <template v-slot:append="{ item }">
            <div v-if="item.hasOwnProperty('lead')">
              <v-tooltip :disabled="item.downloadStatus != 'READY'" content-class="download-csv-tooltip" right>
                <template v-slot:activator="{ props }">
                  <span class="t-cursor-pointer" v-bind="props">
                    <svg-icon
                      v-if="item.downloadStatus == 'READY'"
                      icon-class="icon-download"
                      class="download-csv t-cursor-pointer t-w-5 t-h-5"
                      @click="
                        $event.stopPropagation();
                        downloadCSVPerORG(item);
                      "
                    />
                  </span>
                </template>
                <span>{{ $t('common.titleCase.learningRecord') }}_{{ item.name }}</span>
              </v-tooltip>

              <v-progress-circular v-if="item.downloadStatus == 'LOADING'" indeterminate color="black" :width="3" :size="20"></v-progress-circular>
              <img v-if="item.downloadStatus == 'SUCCESS'" src="@/assets/images/svgs/green_check_icon.svg" />
              <img v-if="item.downloadStatus == 'ERROR'" src="@/assets/images/svgs/error_icon.svg" />
            </div>
          </template>
        </TreeView> 
        -->
        <DataTableTreeView v-if="treeViewReady" class="dashboard-details-treeview" :items="structure" :row="0" @on-get-plan="onGetPlan" :withDownload="true" @download-csv="downloadCSVPerORG" :onGetPlan="onGetPlan" :onDownloadCSV="downloadCSVPerORG" />

        <div v-else>
          <div class="loader t-flex t-justify-center t-items-center">
            <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Tree View" />
          </div>
        </div>
      </div>

      <v-data-table-server
        class="consumption-details-table"
        aria-describedby="table-caption"
        :headers="headers"
        :items="filterEmployees"
        :items-per-page="pageSize"
        :items-per-page-options="pageSizeOption"
        :items-length="people.totalLearners || 0"
        @current-items="currentEmployees"
        :sort-by="sortBy"
        hide-default-footer
        :fixed-header="true"
        :key="$i18n.locale"
        @update:options="loadItems"
      >
        <template v-slot:caption>
          <div id="table-caption" class="caption" :aria-label="`${$t('common.titleCase.organizationChart')}`">{{ $t('common.titleCase.organizationChart') }}</div>
        </template>

        <template v-slot:[`no-data`]>
          <span class="no-data-available">{{ $t('common.noData') }}</span>
        </template>

        <template v-slot:[`header.learningPlan`]="{ column }">
          <div class="v-data-table-header__content">
            <span style="margin-right: 0.375rem">{{ column.title }}</span>
            <svg-icon icon-class="info-icon" class="info-icon" />
            <span class="info-tooltip">{{ $t('insights.learningPlanToolTip') }}</span>
          </div>
        </template>

        <template v-slot:[`header.progress`]="{ column }">
          <div class="v-data-table-header__content">
            <span style="margin-right: 0.375rem">{{ column.title }}</span>
            <span class="progress-tooltip-wrapper">
              <ToolTip :content="$t('insights.progressToolTip')" width="16.3125rem" />
            </span>
          </div>
        </template>

        <template v-slot:[`body`] v-if="!tableDataReady">
          <div class="dashboard-details-table-loader">
            <v-card-text class="loader-text">{{ $t('common.titleCase.loading') }}</v-card-text>
            <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
          </div>
        </template>

        <!-- body -->
        <template v-slot:[`item.userGuid`]="{ item }">
          <span>
            <v-tooltip :disabled="item.downloadStatus != 'READY'" content-class="download-csv-tooltip" right>
              <template v-slot:activator="{ props }">
                <span class="t-cursor-pointer" v-bind="props">
                  <button :aria-label="`download learning record ${item.name}`" @click="downloadCSVPerUser(item)">
                    <svg-icon v-if="item.downloadStatus == 'READY'" icon-class="icon-download" class="t-cursor-pointer t-w-5 t-h-5" />
                  </button>
                </span>
              </template>
              <span>{{ $t('common.titleCase.learningRecord') }}_{{ item.name }}</span>
            </v-tooltip>

            <v-progress-circular v-if="item.downloadStatus == 'LOADING'" indeterminate color="black" :width="3" :size="20" />
            <img v-if="item.downloadStatus == 'SUCCESS'" src="@/assets/images/svgs/green_check_icon.svg" />
            <img v-if="item.downloadStatus == 'ERROR'" src="@/assets/images/svgs/error_icon.svg" />
          </span>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.name }}</span>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.email`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.email }}</span>
              </template>
              <span>{{ item.email }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.status }}</span>
              </template>
              <span>{{ item.status }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.title }}</span>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.role`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.role }}</span>
              </template>
              <span>{{ item.role }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.license`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.license }}</span>
              </template>
              <span>{{ item.license }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.progress`]="{ item }">
          <span>{{ item.progress }}%</span>
        </template>

        <template v-slot:footer>
          <InsightsPagination :totalPages="totalPages" :pageSize="pageSize" :pageNumberChangeEvent="pageNumberChangeEvent" :pageSizeChangeEvent="pageSizeChangeEvent" :pageNum="pageNum" :pageRange="pageRange" :handlePageClick="handlePageClick" />
        </template>
      </v-data-table-server>
    </div>

    <div v-show="!structureReady">
      <div class="loader drilldown-loader t-flex t-justify-center t-items-center">
        <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Drilldown Table" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import InsightsDrillDowntableFilters from './InsightsDrillDowntableFilters.vue';
import { getDateForCSV, generateCSVTimeFilter, getLearningTranscriptCSVLabels } from '@/utils';
import { numberWithCommas, convertHourMinuteStringtoStringWithCommas, getElementWidth } from '@/utils';
import InsightsPagination from '../components/InsightsPagination.vue';
import ContentCSVDownload from '../components/ContentCSVDownload.vue';
import ToolTip from '../../../components/molecules/ToolTip.vue';
import DataTableTreeView from '@/components/molecules/DataTableTreeView';
import { translate } from '@/plugins/i18n.js';

const initialState = () => ({
  jsonTitle: '',
  selectedTimeOptionString: 'ALLTIME',
  displaySelectedFilters: [],
  searchText: '',
  structureReady: false,
  structure: [],
  tableDataReady: false,
  treeViewReady: true,
  orgInFocus: null,
  orgNameInFocus: 'All Orgs',
  pageSize: 25,
  pageNum: 1,
  pageRange: 6,
  filterResults: {},
  sortBy: [],
  headers: [],
  employees: [],
  titles: [],
  roles: [],
  licenses: [],
  statusValues: [],
  people: {},
  totalPages: 0,
  pageSizeChangeEvent: 'consumption-c2-page-size-change',
  pageNumberChangeEvent: 'consumption-c2-page-number-change',
  isComponentActive: false,
  isComponentRefreshed: false,
  pageSizeOption: [
    { value: 10, title: '10' },
    { value: 25, title: '25' },
    { value: 50, title: '50' },
    { value: 100, title: '100' }
  ]
});

export default {
  name: 'ConsumptionDrillDownTable',
  props: {
    timeFilter: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    updateContentProps: { type: Function, required: true },
    dateRange: {
      type: Object,
      required: false
    }
  },
  components: { InsightsDrillDowntableFilters, InsightsPagination, ContentCSVDownload, ToolTip, DataTableTreeView },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters(['enabledFeatureFlags']),
    filterEmployees() {
      return this.employees.map((x) => ({ ...x, isSelectable: !x.disabled }));
    }
  },
  created() {
    this.EventBus.on(this.pageSizeChangeEvent, this.onPageSizeChange);
    this.EventBus.on(this.pageNumberChangeEvent, this.onPageNumberChange);
  },
  deactivated() {
    this.isComponentActive = false;
  },
  async activated() {
    Object.assign(this.$data, initialState());
    this.setupPagination();
    this.isComponentActive = true;
  },
  updated() {
    this.isComponentRefreshed = false;
    this.isComponentActive = true;
  },
  watch: {
    structureReady() {
      this.$emit('setIsStructureReady', this.structureReady);
    },
    '$i18n.locale'() {
      if (this.isComponentActive) {
        this.setHeaders();
        this.fetchInitialData();
        this.isComponentRefreshed = true;
      }
    },
    dateRange: {
      handler() {
        if (!this.isComponentRefreshed && this.isComponentActive) {
          this.setHeaders();
          this.fetchInitialData();
          this.isComponentRefreshed = true;
        } else {
          this.isComponentRefreshed = false;
        }
      },
      deep: true
    },
    tableDataReady: function (val) {
      this.$nextTick(async () => {
        const container = document.getElementsByClassName('consumption-details-table')[0];

        if (container) {
          const wrapper = document.getElementsByClassName('v-table__wrapper')[0];
          wrapper.scrollLeft && (wrapper.scrollLeft = 0);
          const thead = container.getElementsByTagName('thead')[0];
          const tbody = container.getElementsByTagName('tbody')[0];

          if (val) {
            thead.style.display = 'table-header-group';
            tbody.style.overflow = 'auto';
          } else {
            thead.style.display = 'inline-table';
            tbody.style.overflow = 'hidden';
            tbody.style.width = `${container.clientWidth / 16}rem`;
          }
        }
      });
    },
    searchText() {
      if (this.searchText) this.searchText = this.searchText.trim();
      this.updateContentProps('searchText', this.searchText);
    },
    timeFilter: function () {
      if (this.timeFilter.length) {
        this.people = {};
        this.selectedTimeOptionString = this.timeFilter;
        this.fetchInitialData();
      }
    },
    orgInFocus: function () {
      this.updateContentProps('orgInFocus', this.orgInFocus?.id || null);
      this.orgNameInFocus = this.orgInFocus?.name;
    }
  },
  childInterface: {
    onClearFilters: () => {},
    onRemoveFilter: () => {}
  },
  methods: {
    getElementWidth,
    getDateForCSV,
    generateCSVTimeFilter,
    numberWithCommas,
    convertHourMinuteStringtoStringWithCommas,
    getLearningTranscriptCSVLabels,
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    async fetchInitialData() {
      this.structureReady = false;
      this.tableDataReady = false;
      this.searchText = '';
      this.searchTextForCSV = '';
      await this.getTreeData();

      if ({}.hasOwnProperty.call(this.structure, 'code') && this.structure.code == 401) {
        this.$stopSessionTimer();
      }

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus);
      } else {
        await this.getAllEmployees();
      }
      await this.setup();
      this.structureReady = true;
      this.tableDataReady = true;
    },
    async getAllEmployees(pageNumber, searchText, filtersRequired = true, titles, roles) {
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      const payload = {
        pageNo: pageNumber || this.pageNum,
        pageSize: this.pageSize,
        titles: titles || [],
        roles: roles || [],
        filterValuesRequired: true,
        searchText: searchText || '',
        startDate: this.timeFilter === 'CUSTOM' ? this.dateRange.startDate : '',
        endDate: this.timeFilter === 'CUSTOM' ? this.dateRange.endDate : '',
        dateFormat: 'MM-dd-yyyy'
      };
      const reqParams = {
        timeFilter: this.timeFilter || 'ALLTIME',
        tenantGuid: tenantGuid
      };
      await this.$planAdmin
        .getConsumptionDetailAllEmployees(reqParams, payload)
        .then((res) => {
          res.learnerDetails.forEach((item) => {
            Object.keys(item).forEach((key) => {
              if (typeof item[key] === 'number') {
                item[key] = parseFloat(item[key]);
              }
              if (['completedTimeSpent', 'inProgressTimeSpent', 'overallCompletedTimeSpent', 'totalLearning'].includes(key)) {
                item[key] = convertHourMinuteStringtoStringWithCommas(item[key]);
              }
            });
          });
          this.people = Object.assign({}, res);
          this.totalPages = this.people.totalPages;
          if (filtersRequired) {
            this.titles = this.people.titles || [];
            this.roles = this.people.roles || [];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getEmployees(org, pageNumber, searchText, titles, roles, licenses, statusValues) {
      this.orgInFocus = org;
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      const reqParams = {
        timeFilter: this.timeFilter || 'ALLTIME',
        tenantGuid: tenantGuid,
        id: org.id
      };
      const payload = {
        pageNo: pageNumber || this.pageNum,
        pageSize: this.pageSize,
        titles: titles || [],
        roles: roles || [],
        licenses: licenses || [],
        statusValues: statusValues || [],
        searchText: searchText || '',
        startDate: this.timeFilter === 'CUSTOM' ? this.dateRange.startDate : '',
        endDate: this.timeFilter === 'CUSTOM' ? this.dateRange.endDate : '',
        dateFormat: 'MM-dd-yyyy'
      };
      this.people = await this.$planAdmin.getConsumptionDetailAllEmployeesFromOrg(reqParams, payload);
      this.people.learnerDetails.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (typeof item[key] === 'number') {
            item[key] = parseFloat(item[key]);
          }
          if (['completedTimeSpent', 'inProgressTimeSpent', 'overallCompletedTimeSpent', 'totalLearning'].includes(key)) {
            item[key] = convertHourMinuteStringtoStringWithCommas(item[key]);
          }
        });
      });
      this.totalPages = this.people.totalPages;
    },
    async setup() {
      this.setHeaders();
      this.employees = this.people.learnerDetails || [];
      // add a download status flag per employee
      this.employees = this.employees.map((x) => ({ ...x, downloadStatus: 'READY' }));
      this.generateFilterData();
    },
    async getTreeData() {
      const data = await this.$planAdmin.getRootOrgManagePeopleReporting();
      let startingStruct = data || [];
      this.addStatus(startingStruct, 1);
      this.structure = startingStruct;
    },
    addStatus(arr) {
      arr.forEach((i) => {
        i.downloadStatus = 'READY';
        if (i.children && i.children.length) {
          this.addStatus(i.children || []);
        }
      });
    },
    currentEmployees(employees) {
      this.currentEmployeeGuids = employees.map((employee) => employee.guid);
    },
    async applyFilters(filters, selectedFilters) {
      this.$emit('filters-applied', selectedFilters);
      this.displaySelectedFilters = selectedFilters;
      this.tableDataReady = false;
      this.pageNum = 1;
      const titles = [...filters[1].values];
      const roles = [...filters[2].values];

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText, titles, roles);
      } else {
        await this.getAllEmployees(this.pageNum, this.searchText, true, titles, roles);
      }

      this.totalPages = this.people.totalPages;
      await this.setup();
      this.tableDataReady = true;
    },
    async downloadCSVPerUser(user) {
      user.downloadStatus = 'LOADING';

      try {
        const tenantGuid = await localforage.getItem('my_tenant_guid');
        const payload = {
          orgId: this.orgInFocus ? this.orgInFocus.id : 'root',
          tenantGuid: tenantGuid,
          userGuid: user.userGuid,
          timePeriod: this.timeFilter,
          startDate: this.timeFilter === 'CUSTOM' ? this.dateRange.startDate : '',
          endDate: this.timeFilter === 'CUSTOM' ? this.dateRange.endDate : '',
          dateFormat: 'MM-dd-yyyy'
        };
        const reqId = await this.$planAdmin.getUserLearningRecord(payload);
        let loadingStatus = 'IN_PROGRESS';
        let res = {};
        let counter = 0;

        while (loadingStatus == 'IN_PROGRESS' && counter < 200) {
          // keep checking every 3 seconds
          res = await this.$planAdmin.consumptionDownloadPerORGgetUpdate(reqId);
          loadingStatus = res.status;
          await new Promise((resolve) => setTimeout(resolve, 3000));
          counter++;
        }
        if (loadingStatus == 'FINISHED') {
          let filename = `Required Learning_${generateCSVTimeFilter('ALLTIME')}_${this.getDateForCSV()}`;
          let a = document.createElement('a');
          a.href = res.generatedUri;
          a.setAttribute('download', filename);
          a.click();
          user.downloadStatus = 'SUCCESS';
          setTimeout(() => {
            user.downloadStatus = 'READY';
          }, 10000);
        } else {
          if (loadingStatus == 'IN_PROGRESS') {
            // this means we timed out while trying to download
            console.log('Retrieving Learner Records is taking too long');
          } else {
            console.log(res.message);
          }
        }
      } catch (error) {
        console.log('there has been an error');
        console.log(error);
        user.downloadStatus = 'READY';

        // maybe we could show an X to inform the user that there has been an error
        // user.downloadStatus = 'FAILED'
        // setTimeout(() => {
        //   user.downloadStatus = 'READY'
        // }, 10000)
        // maybe we could show an X to inform the user that there has been an error
      }
    },
    async downloadCSVPerORG(org) {
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      org.downloadStatus = 'LOADING';
      const payload = { orgId: org.id, tenantGuid: tenantGuid, timePeriod: this.timeFilter, startDate: this.timeFilter === 'CUSTOM' ? this.dateRange.startDate : '', endDate: this.timeFilter === 'CUSTOM' ? this.dateRange.endDate : '', dateFormat: 'MM-dd-yyyy' };
      const reqId = await this.$planAdmin.consumptionDownloadPerORGgetRequestID(payload);
      let loadingStatus = 'IN_PROGRESS';
      let res = {};
      let counter = 0;

      while (loadingStatus == 'IN_PROGRESS' && counter < 200) {
        // keep checking every 3 seconds
        res = await this.$planAdmin.consumptionDownloadPerORGgetUpdate(reqId);
        loadingStatus = res.status;
        await new Promise((resolve) => setTimeout(resolve, 3000));
        counter++;
      }

      if (loadingStatus == 'FINISHED') {
        let filename = translate('common.titleCase.learningRecord') + `_${org.name}_${generateCSVTimeFilter(this.timeFilter)}_${this.getDateForCSV()}`;
        let a = document.createElement('a');
        a.href = res.generatedUri;
        a.setAttribute('download', filename);
        a.click();
        org.downloadStatus = 'SUCCESS';
      } else {
        // this can also mean we timed out/counter went to 200(10 minutes)
        org.downloadStatus = 'ERROR';
        if (loadingStatus == 'IN_PROGRESS') {
          // this means we timed out while trying to download
          console.log('Retrieving Learner Records is taking too long');
        } else {
          console.log(res.message);
        }
      }
    },
    async onGetPlan(org) {
      this.pageNum = 1;
      this.tableDataReady = false;
      this.people = {};
      this.selectDepartment = true;

      if (org.id) {
        await this.getEmployees(org);
      }

      this.$options.childInterface.clearFilters();
      await this.setup();
      await this.setupPagination(true);
      this.tableDataReady = true;
    },
    setHeaders() {
      this.headers = [];
      if (!this.headers.length) {
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.name') + ` (${this.numberWithCommas(this.people.totalLearners || '0')})`,
          key: 'name',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.name') + ` (${this.numberWithCommas(this.people.totalLearners || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.name') + ` (${this.numberWithCommas(this.people.totalLearners || '0')})`)
        });
        this.headers.push({
          title: '',
          key: 'userGuid',
          width: '2rem',
          minWidth: '2rem'
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.email'),
          key: 'email',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.email')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.email'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.title') + ` (${this.numberWithCommas(this.people.totalTitles || '0')})`,
          key: 'title',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.title') + ` (${this.numberWithCommas(this.people.totalTitles || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.title') + ` (${this.numberWithCommas(this.people.totalTitles || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.role') + ` (${this.numberWithCommas(this.people.totalRoles || '0')})`,
          key: 'role',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.role') + ` (${this.numberWithCommas(this.people.totalRoles || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.role') + ` (${this.numberWithCommas(this.people.totalRoles || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.overallLearning'),
          key: 'overallLearning',
          sortable: false,
          width: '5rem',
          minWidth: '5rem'
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.overallCompletedTimeSpent') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalOverallCompletedTimeSpent || '0')})`,
          key: 'overallCompletedTimeSpent',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.overallCompletedTimeSpent') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalOverallCompletedTimeSpent || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.overallCompletedTimeSpent') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalOverallCompletedTimeSpent || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.credentialsInProgress') + ` (${this.numberWithCommas(this.people.totalCredentialInProgress || '0')})`,
          key: 'credentialsInProgress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.credentialsInProgress') + ` (${this.numberWithCommas(this.people.totalCredentialInProgress || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.credentialsInProgress') + ` (${this.numberWithCommas(this.people.totalCredentialInProgress || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.credentialsCompleted') + ` (${this.numberWithCommas(this.people.totalCredentialCompleted || '0')})`,
          key: 'credentialsCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.credentialsCompleted') + ` (${this.numberWithCommas(this.people.totalCredentialCompleted || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.credentialsCompleted') + ` (${this.numberWithCommas(this.people.totalCredentialCompleted || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.coursesInProgress') + ` (${this.numberWithCommas(this.people.totalCourseInProgress || '0')})`,
          key: 'coursesInProgress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.coursesInProgress') + ` (${this.numberWithCommas(this.people.totalCourseInProgress || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.coursesInProgress') + ` (${this.numberWithCommas(this.people.totalCourseInProgress || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.coursesCompleted') + ` (${this.numberWithCommas(this.people.totalCourseCompleted || '0')})`,
          key: 'coursesCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.coursesCompleted') + ` (${this.numberWithCommas(this.people.totalCourseCompleted || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.coursesCompleted') + ` (${this.numberWithCommas(this.people.totalCourseCompleted || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.audiosInProgress') + ` (${this.numberWithCommas(this.people.totalAudioInProgress || '0')})`,
          key: 'audiosInProgress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.audiosInProgress') + ` (${this.numberWithCommas(this.people.totalAudioInProgress || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.audiosInProgress') + ` (${this.numberWithCommas(this.people.totalAudioInProgress || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.audiosCompleted') + ` (${this.numberWithCommas(this.people.totalAudioCompleted || '0')})`,
          key: 'audiosCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.audiosCompleted') + ` (${this.numberWithCommas(this.people.totalAudioCompleted || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.audiosCompleted') + ` (${this.numberWithCommas(this.people.totalAudioCompleted || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.videosInProgress') + ` (${this.numberWithCommas(this.people.totalVideoInProgress || '0')})`,
          key: 'videosInProgress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.videosInProgress') + ` (${this.numberWithCommas(this.people.totalVideoInProgress || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.videosInProgress') + ` (${this.numberWithCommas(this.people.totalVideoInProgress || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.videosCompleted') + ` (${this.numberWithCommas(this.people.totalVideoCompleted || '0')})`,
          key: 'videosCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.videosCompleted') + ` (${this.numberWithCommas(this.people.totalVideoCompleted || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.videosCompleted') + ` (${this.numberWithCommas(this.people.totalVideoCompleted || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.articlesInProgress') + ` (${this.numberWithCommas(this.people.totalArticleInProgress || '0')})`,
          key: 'articlesInProgress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.articlesInProgress') + ` (${this.numberWithCommas(this.people.totalArticleInProgress || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.articlesInProgress') + ` (${this.numberWithCommas(this.people.totalArticleInProgress || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.articlesCompleted') + ` (${this.numberWithCommas(this.people.totalArticleCompleted || '0')})`,
          key: 'articlesCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.articlesCompleted') + ` (${this.numberWithCommas(this.people.totalArticleCompleted || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.articlesCompleted') + ` (${this.numberWithCommas(this.people.totalArticleCompleted || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.learningPlan'),
          key: 'learningPlan',
          sortable: false,
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.learningPlan')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.learningPlan'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.trackItems') + ` (${this.numberWithCommas(this.people.totalTrackItems || '0')})`,
          key: 'trackItems',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.trackItems') + ` (${this.numberWithCommas(this.people.totalTrackItems || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.trackItems') + ` (${this.numberWithCommas(this.people.totalTrackItems || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.trackItemsCompleted') + ` (${this.numberWithCommas(this.people.totalTrackItemsCompleted || '0')})`,
          key: 'trackItemsCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.trackItemsCompleted') + ` (${this.numberWithCommas(this.people.totalTrackItemsCompleted || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.trackItemsCompleted') + ` (${this.numberWithCommas(this.people.totalTrackItemsCompleted || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.completedTimeSpent') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalCompletedTimeSpent || '0')})`,
          key: 'completedTimeSpent',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.completedTimeSpent') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalCompletedTimeSpent || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.completedTimeSpent') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalCompletedTimeSpent || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.inProgressTimeSpent') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalInProgressTimeSpent || '0')})`,
          key: 'inProgressTimeSpent',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.inProgressTimeSpent') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalInProgressTimeSpent || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.inProgressTimeSpent') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalInProgressTimeSpent || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.totalLearning') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalLearning || '0')})`,
          key: 'totalLearning',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.totalLearning') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalLearning || '0')})`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.totalLearning') + ` (${this.convertHourMinuteStringtoStringWithCommas(this.people.totalLearning || '0')})`)
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.progress') + ` (${this.people.progress}%)`,
          key: 'progress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.progress') + ` (${this.people.progress}%)`),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.progress') + ` (${this.people.progress}%)`)
        });
      }
    },
    checkSearchLength(val) {
      !val ? (this.clearSearchCross ? (this.clearSearchCross = false) : this.handleSearchClear()) : void 0;
    },
    async resetTreeView() {
      this.treeViewReady = false;
      await this.getTreeData();
      this.treeViewReady = true;
    },
    async searchName(e) {
      if (!e.target.value.length) return;
      this.$emit('search-text', this.searchText);
      this.tableDataReady = false;
      this.pageNum = 1;
      this.orgInFocus = null;
      await this.resetTreeView();
      this.$options.childInterface.clearFilters();
      this.searchTextForCSV = this.searchText;
      await this.getAllEmployees(this.pageNum, this.searchText, true);
      await this.setup();
      this.tableDataReady = true;
    },
    async handleSearchClear() {
      this.clearSearchCross = true;
      this.pageNum = 1;
      this.searchText = '';
      this.$options.childInterface.clearFilters();
      this.orgInFocus = '';
      this.searchTextForCSV = '';
      await Promise.all([this.resetTreeView(), this.getAllEmployees(this.pageNum, '', true)]);

      await this.setup();
    },
    async setupPagination() {
      if (this.employees) {
        const elements = document.getElementsByClassName('pagination');
        if (elements.length && elements[0].childNodes) {
          elements[0].childNodes.forEach((element) => {
            if (element.childNodes.length && element.className.includes('pagination')) {
              const pageNumber = element.childNodes[0].innerHTML;
              if (element.className.includes('pagination-nav')) {
                if (pageNumber === '‹') {
                  element.setAttribute('data-tooltip-content', `Go to previous page`);
                  element.setAttribute('aria-label', `Go to previous page`);
                } else {
                  element.setAttribute('data-tooltip-content', `Go to next page`);
                  element.setAttribute('aria-label', `Go to next page`);
                }
              }
              if (element.className.includes('pagination-item active')) {
                element.setAttribute('aria-current', `page`);
              } else {
                element.setAttribute('aria-current', `false`);
                if (element.className.includes('pagination-item') && !isNaN(pageNumber)) {
                  element.setAttribute('aria-label', `page ${pageNumber}`);
                }
              }
            }
          });
        }
      }
    },
    async handlePageClick(pageNumber) {
      this.pageNum = pageNumber;
      const titles = [];
      const roles = [];
      const licenses = [];
      const statusValues = [];
      this.tableDataReady = false;
      for (let filter of this.displaySelectedFilters) {
        switch (filter.type) {
          case 'Job Title':
            titles.push(filter.name);
            break;
          case 'Role':
            roles.push(filter.name);
            break;
          default:
            break;
        }
      }

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText, titles, roles, licenses, statusValues);
      } else {
        await this.getAllEmployees(this.pageNum, this.searchText, true, titles, roles, licenses, statusValues);
      }
      await this.setup();
      await this.setupPagination();
      this.tableDataReady = true;
    },
    generateFilterData() {
      this.filterResults = {
        jobTitleFilterValues: [],
        roleFilterValues: []
      };
      const keys = ['titles', 'roles'];

      for (const property of keys) {
        if (this.people.titles || this.people.roles) {
          const payloadObj = this.people[property];

          switch (property) {
            case 'titles':
              for (let name of payloadObj) {
                this.filterResults.jobTitleFilterValues.push({ name });
              }
              break;
            case 'roles':
              for (let name of payloadObj) {
                this.filterResults.roleFilterValues.push({ name });
              }
              break;
            default:
              break;
          }
        }
      }
    },
    onRemoveFilter(item) {
      this.$options.childInterface.removeFilter(item);
    },
    async onClearFilters() {
      this.$options.childInterface.clearFilters();
      this.pageNum = 1;
      this.$nextTick(async () => {
        if (this.orgInFocus) {
          await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText);
        } else {
          await this.getAllEmployees(this.pageNum, this.searchText, true);
        }
        await this.setup();
        await this.setupPagination();
      });
    },
    onPageNumberChange(page) {
      this.pageNum = page;
    },
    async onPageSizeChange(size) {
      this.pageSize = size;
      this.pageNum = 1;
      this.tableDataReady = false;
      this.handlePageClick(this.pageNum);
    },
    loadItems(tableData) {
      const { page, itemsPerPage, sortBy } = tableData;

      if (this.pageSize !== itemsPerPage) {
        this.onPageSizeChange(itemsPerPage);
      }

      if (this.pageNum !== page) {
        this.handlePageClick(page);
      }

      if (this.sortBy !== sortBy) {
        this.sortBy = sortBy;
        if (sortBy.length) {
          let sortKey = sortBy[0].key;
          if (['overallCompletedTimeSpent', 'inProgressTimeSpent', 'totalLearning', 'completedTimeSpent'].includes(sortKey)) {
            sortKey = `${sortKey}InSec`;
          }
          this.employees = this.lodash.orderBy(this.employees, [sortKey], [sortBy[0].order]);
        } else {
          this.employees = this.people.learnerDetails || [];
        }
      }
    }
  }
};
</script>

<style lang="scss">
@mixin scrollable {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 1.25rem;
    width: 0.25rem;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.content-drilldown-header-container {
  display: grid;
  grid-template-columns: 6fr 6fr !important;
}

.consumption-dashboard-container {
  max-width: 90rem;
  margin: 0 auto;

  .info-icon {
    width: 16px;
    height: 16px;
    align-items: center;
    vertical-align: middle;
  }
  .info-tooltip {
    visibility: hidden;
    text-align: center;
    padding: 8px 16px;
    position: absolute !important;
    z-index: 999;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000001f;
    border-radius: 4px;
    opacity: 1;
    color: #2d2d2d !important;
    top: 65%;
    font: normal normal normal 12px source sans !important;
    left: 68.3%;
    width: max-content;
    align-items: center;
    color: var(--Text-Body-DarkGrey, #2d2d2d);
    line-height: 18px;
  }
  .info-icon:hover ~ .info-tooltip {
    visibility: visible;
  }
  .progress-tooltip {
    position: relative;
    &:after {
      content: '\24D8';
      font-size: 0.625rem;
      color: #000;
    }
    &:hover {
      .tooltip-text {
        z-index: 999;
        visibility: visible;
        left: 0rem;
        top: 1rem;
        .tooltip-text-content {
          margin: 1rem 0rem !important;
        }
      }
    }
  }
  .dashboard-details-table-header-actions {
    display: grid;
    grid-template-columns: 5fr 7fr;
    padding: 1.5rem 0rem;

    @media screen and (max-width: 960px) {
      grid-template-columns: initial;
    }

    .dashboard-details-table-search {
      padding: 1.5rem 0rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .v-input__control {
        box-shadow: none !important;
        min-height: 2.625rem !important;
        width: 32.25rem;

        @media screen and (max-width: 960px) {
          width: initial;
        }
      }

      .v-field--variant-solo {
        background-color: white !important;
        height: 2.625rem;
      }

      .v-field__field,
      .v-field__prepend-inner {
        height: 2.625rem;
      }

      .v-field__input {
        padding-top: 0rem !important;
      }
      .mdi-magnify {
        font-size: 1.725rem;
        margin-right: 0.725rem;
      }

      .v-input__control,
      .v-btn--is-elevated {
        border: none !important;
        box-shadow: none !important;
        border-radius: 1.3125rem;
        backdrop-filter: blur(1.25rem);
        -webkit-backdrop-filter: blur(1.25rem);
      }
    }

    .consumption-details-table-filters {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .dashboard-details-search-chips {
    padding: 1.5rem 0rem;
    padding-top: 0rem;
    display: flex;
    align-items: center;
    flex-direction: row;

    .clear-all-filters {
      text-align: left;
      font: normal normal bold 0.6875rem/1.875rem Arial;
      letter-spacing: -0.0069rem;
      color: #1f3370;
      opacity: 1;
      cursor: pointer;
      display: inline-block;
    }
  }
  .dashboard-details-table-container {
    display: grid;
    grid-template-columns: 19.6875rem 9fr;
    margin-bottom: 5rem;

    @media screen and (max-width: 960px) {
      grid-template-columns: 1fr 2fr;
    }

    .consumption-details-treeview-container {
      .tree-view-header {
        height: 4.6875rem;
        display: flex;
        align-items: center;
        padding: 2rem;
        font: normal normal bold 1rem/1.125rem Arial;
        letter-spacing: 0rem;
        color: #000000;
        border-bottom-left-radius: 0.3125rem;
        border-top-left-radius: 0.3125rem;
        background-color: #d0d0d0;
      }
      .dashboard-details-treeview {
        margin-right: 2rem;
        overflow: scroll;
        @include scrollable;
        height: 30.25rem;

        .v-treeview-node__root {
          .download-csv {
            opacity: 0;
            transition: all 0.5s ease-in-out;
          }
          &:hover {
            .download-csv {
              opacity: 1;
            }
          }
        }

        .v-treeview-node__label {
          overflow: initial;
          text-overflow: initial;
        }

        .v-treeview-node__root {
          cursor: pointer;
          .v-icon {
            background: #e4e5e4 0% 0% no-repeat padding-box;
            border: 0.0625rem solid #e5e5e5;
            opacity: 1;
            border-radius: 6.25rem;
            height: 1.9375rem;
            width: 1.9375rem;
          }

          .dept-name {
            font: normal normal normal 1rem/2.5rem Arial;
            letter-spacing: 0rem;
            color: #000000;
            opacity: 1;
            cursor: pointer;
          }
        }
        .v-treeview-node__children {
          .v-treeview-node__root {
            .dept-name {
              font: normal normal normal 1rem/2.5rem Arial;
              letter-spacing: 0rem;
              color: #000000;
              opacity: 1;
            }
          }
        }

        .mdi-menu-down::before {
          content: '\F035D';
          content: '\F0140';
        }
        .v-treeview-node--active {
          background: rgba(255, 255, 255, 0.6);
          color: $color-white !important;
          min-width: fit-content;

          .dept-name {
            font-weight: bold !important;
          }
          .download-csv {
            opacity: 1;
          }
        }
      }
    }

    .v-data-table--fixed-header {
      border: none !important;
    }

    .consumption-details-table {
      background: inherit;
      display: grid;

      .caption {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
      }

      .no-data-available {
        font-size: 1.5rem;
        font-weight: normal;
        font-family: 'Futura PT Demi';
        text-align: start;
        padding: 12rem 2rem 2rem 14rem;
        color: rgba(0, 0, 0, 0.38);
      }

      .dashboard-details-table-loader {
        margin: 10rem 3.125rem 3.125rem;
        text-align: center;
        width: 56rem;

        .v-input {
          display: contents !important;
          .v-field__overlay {
            display: none;
          }
        }

        .loader-text {
          font-size: 1.875rem;
          color: $color-primary;
        }

        .loader-bar {
          position: relative;
          top: -1.875rem;
        }
      }

      .v-table__wrapper {
        @include scrollable;
        height: 35rem !important;
        overflow: auto;
        position: relative;

        &::-webkit-scrollbar-track {
          background: #fff;
          border-radius: 1.25rem;
          margin-bottom: 1.25rem;
          margin-right: 7rem;
          margin-top: 4.6875rem;
        }

        &::-webkit-scrollbar:vertical {
          border-radius: 0rem;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #d0d0d0 56%);
          margin-bottom: 1.25rem;
        }

        table {
          tbody {
            overflow: auto;
            @include scrollable;

            .v-data-table-rows-no-data {
              height: 35vh;
              text-align: left;
            }
          }

          thead {
            th:nth-child(6),
            th:nth-child(7),
            th:nth-child(8),
            th:nth-child(9),
            th:nth-child(10),
            th:nth-child(11),
            th:nth-child(12),
            th:nth-child(13),
            th:nth-child(14),
            th:nth-child(15),
            th:nth-child(16),
            th:nth-child(17),
            th:nth-child(18),
            th:nth-child(19),
            th:nth-child(20),
            th:nth-child(21),
            th:nth-child(22),
            th:nth-child(23),
            th:nth-child(24) {
              padding: 0rem;

              .v-data-table-header__content {
                background: #fff;
                height: 80%;
                align-items: center;
                display: inline-flex;
                padding: 0rem 1rem;
                width: 100%;
              }

              span {
                font-size: 0.825rem;
              }
            }
          }
          thead th:nth-child(n + 19) {
            z-index: 1;
          }
          thead th:nth-child(6),
          thead th:nth-child(18) {
            .v-data-table-header__content {
              border-top-left-radius: 3.125rem;
              border-bottom-left-radius: 3.125rem;
            }
          }

          thead th:nth-child(17),
          thead th:nth-child(24) {
            .v-data-table-header__content {
              border-top-right-radius: 3.125rem !important;
              border-bottom-right-radius: 3.125rem !important;
            }
          }

          thead {
            height: 4.6875rem;
            background: #d0d0d0;
            border-radius: 0px;

            th {
              box-shadow: none;
            }

            .v-data-table__td {
              background-color: #d0d0d0;
            }
            span {
              text-align: left;
              font: normal normal bold 1rem/1.125rem Arial;
              letter-spacing: 0rem;
              color: #000000;
              opacity: 1;
            }
          }

          tbody {
            tr:hover:not(.v-table__expanded__content) {
              &:nth-child(even) {
                background: #fff;
              }
            }

            .v-input {
              margin: 0rem;
              display: flex;
              align-items: center;
              height: 2.75rem;
              justify-content: center;
              padding-top: 1.375rem;
            }

            .employee-data-field {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 15rem;
            }
            .half-opacity {
              opacity: 0.5;
            }

            tr {
              td {
                height: 2.75rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 15rem;
              }
              .v-input {
                &:not(.v-input--is-disabled) {
                  .mdi-checkbox-blank-outline {
                    border-radius: 0.25rem;
                    color: #1f3370;
                  }
                }
              }
            }

            tr:nth-child(even) {
              td {
                background: #fff;
              }
            }
            tr:nth-child(odd) {
              td {
                background: #efefef 0% 0% no-repeat padding-box;
              }
            }
            tr.group-active-row-selection > td {
              transition: all 0.7s ease-in-out;
            }
            tr.group-active-row-selection > td > div {
              padding: 1.25rem;
              height: 2.5rem;
            }
            @keyframes anim {
              0% {
                transform: scale(1, 0);
              }
              100% {
                transform: scale(1, 1);
              }
            }
            tr.group-inactive > td {
              opacity: 0;
              line-height: 0;
              padding: 0 0.625rem;
              height: 0 !important;
            }
          }
          .v-data-table__td {
            border: none !important;
          }
        }
      }
      .v-data-table-footer {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 0 0.5rem;
        justify-content: center;
        margin-top: 24px;
      }
    }
  }

  .loader {
    margin: 3.125rem;
    margin-left: 2.1875rem;
    text-align: center;

    .loader-text {
      font-size: 1.875rem;
      color: $color-primary;
    }

    .loader-bar {
      position: relative;
      top: -1.875rem;
    }
  }
  .drilldown-loader {
    height: 49.1875rem;
    background: #fff;
    margin: 2rem 0rem 0rem 0rem !important;
    border-radius: 1.25rem;
  }
}

.download-csv-tooltip {
  // the tooltip is not readable with this background color
  // background: #ddd !important;
  color: #5c5c5c;
  font: normal normal normal 1rem/1.375rem Arial;
  padding: 0.725rem 2rem !important;
  opacity: 1 !important;
}

.progress-tooltip-wrapper {
  position: relative;
  &:after {
    content: '\24D8';
    font-size: 0.625rem;
    color: #000;
  }
  &:hover {
    .tooltip-text {
      z-index: 999;
      visibility: visible;
      left: 0rem;
      top: 1rem;
      .tooltip-text-content {
        margin: 1rem 0rem !important;
      }
    }
  }
}
</style>
