<template>
  <div class="search">
    <div class="search-container t-relative">
      <v-text-field
        id="input"
        class="search-input"
        data-el="searchSkill"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        ref="searchInput"
        :placeholder="translate('track.searchPlaceholder')"
        :value="query"
        @keyup="handleAutocomplete"
        @keypress.enter="handleSearchInput"
        @click:clear="handleSearchClear"
        flat
        filled
        solo
        rounded
        dense
        clearable
        v-model="query"
        v-click-outside="onClickOutside"
        variant="solo"
      >
      </v-text-field>
      <v-card class="t-max-w-md t-mt-0 t-ml-2 t-rounded-xl t-absolute t-z-10 t-top-10 t-pt-3" v-if="showAutocomplete" id="list">
        <ul class="t-list-none t-px-0">
          <li @click="handleSearchInput(item.queryText)" class="t-px-6 t-py-2 t-cursor-pointer hover:t-text-primary-color hover:t-bg-indigo-100" v-for="(item, index) in autoCompleteList" :tabindex="index + 1" :key="index">
            <div style="padding-left: 2em; text-indent: -2em"><v-icon style="color: #1f3370">mdi-magnify</v-icon> <span class="t-text-base t-ml-2 t-font-arial" v-html="item.text"></span></div>
          </li>
        </ul>
        <p v-if="autoCompleteList.length > 0 && query" class="autocomplete-see-all t-text-primary-color" @click="handleSearchInput(query)">{{ $t('search.searchFor') }}"{{ $sanitize(query) }}"</p>
      </v-card>
    </div>
  </div>
</template>

<script>
import { reverseConversion } from '../../../model/reverse-conversion';
import { mapGetters } from 'vuex';
import { translate } from '@/plugins/i18n.js';
export default {
  name: 'Search',
  emits: ['search-applied'],
  props: {
    ccc_items: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: { ...mapGetters(['tenantAccessTags', 'enabledFeatureFlags']) },
  data() {
    return {
      showAutocomplete: false,
      query: '',
      queryFilter: '',
      fileteredOptions: [],
      items: ['courses', 'credentials', 'videos'],
      showExpandedFilter: false,
      searchListVisible: false,
      filterItems: ['foo', 'bar', 'fizz', 'buzz'],
      values: [],
      showFilters: false,
      selectedFilters: [
        { name: 'Content Type', values: [] },
        { name: 'Skill', values: [] },
        { name: 'Publisher', values: [] },
        { name: 'Duration', values: [] },
        { name: 'Function', values: [] },
        { name: 'Level', values: [] },
        { name: 'Accreditation', values: [] }
      ],
      filterValues: [
        { name: 'Content Type', values: ['Course', 'Credential', 'Video', 'Podcast', 'Article'], showExpandedFilter: false, disabled: false },
        { name: 'Skill', values: ['Search Algorithms', 'Research', 'Vocabulary', 'Robotic Process Automation', 'Planning', 'Credit Risk', 'Exhibitions'], showExpandedFilter: false, disabled: false },
        { name: 'Publisher', values: ['Microsoft', 'Pluralsight', 'Forbes'], showExpandedFilter: false, disabled: false },
        { name: 'Duration', values: ['Under 4 minutes', '4-20 minutes', '20-60 minutes', '1-4 hours', '> 4 hours'], showExpandedFilter: false, disabled: false },
        {
          name: 'Function',
          values: [
            'Actuarial',
            'Board of Directors',
            'Customer Service and Success',
            'Cybersecurity',
            'Executive Office',
            'Finance',
            'Human Resources',
            'Information Technology',
            'Legal',
            'Manufacturing',
            'Marketing',
            'Procurement',
            'Research and Development',
            'Risk',
            'Compliance and Internal Audit',
            'Sales',
            'Supply Chain',
            'Tax'
          ],
          showExpandedFilter: false,
          disabled: false
        },
        { name: 'Level', values: ['Beginner', 'Intermediate', 'Advanced'], showExpandedFilter: false, disabled: false },
        { name: 'Accreditation', values: ['NASBA', 'PMI', 'SHRM', 'CFA Institute', 'HRCI', 'CISSP'], showExpandedFilter: false, disabled: false }
      ],
      autoCompleteList: []
    };
  },
  methods: {
    translate,
    reverseConversion,
    onClickOutside() {
      if (this.showAutocomplete) {
        this.showAutocomplete = false;
      }
    },
    async handleSearchInput(event, pageNum, filters) {
      this.showAutocomplete = false;
      const actionType = filters && Object.keys(filters).length ? 'filter' : 'search';
      this.autoCompleteList = [];
      // we may need to add this back
      // this.query = event.target && event.target.value ? event.target.value.replace(/[^0-9a-z]/gi, ' ').trim() : event.replace(/[^0-9a-z]/gi, ' ').trim()
      this.query = event.target && event.target.value ? event.target.value.trim() : event.trim();
      var maininput = document.getElementById('input');
      maininput.value = this.query;
      let payload = {
        pageNum: pageNum ? pageNum : 1,
        pageSize: 18,
        ...filters,
        query: encodeURIComponent(this.query),
        accessTags: this.tenantAccessTags,
        action: actionType
      };
      if (this.ccc_items.length) {
        let trackItems = reverseConversion(this.ccc_items.map((item) => ({ ...item })));
        payload = Object.assign({ ...payload }, { learning_track: { ccc_items: trackItems } });
      }
      const response = await this.$planAdmin.searchCCC(payload);
      if (pageNum && !event.target) {
        return { filters: response.filterValuesMap, results: response.catalog, totalPages: response.totalPages, totalResults: response.totalResults };
      } else {
        this.$emit('search-applied', response.filterValuesMap, response.catalog, this.query, response.totalPages, response.totalResults);
      }
    },
    scrollList() {
      const first = document.getElementById('list')?.firstChild;
      const maininput = document.getElementById('input');
      document.onkeydown = function (e) {
        switch (e.key) {
          case 'Enter': {
            document.activeElement?.click();
            break;
          }
          case 'ArrowUp': // if the UP key is pressed
            if (document.activeElement == (maininput || first)) {
              break;
            } else {
              maininput.value = document.activeElement?.previousSibling?.textContent ? document.activeElement?.previousSibling?.textContent : document.activeElement.textContent;
              document?.activeElement?.previousSibling?.focus();
            }
            break;
          case 'ArrowDown': {
            // if the DOWN key is pressed
            e.preventDefault();
            if (document.activeElement == maininput) {
              first.firstChild.focus();
            } else {
              maininput.value = document.activeElement?.nextSibling?.textContent ? document.activeElement?.nextSibling?.textContent : document.activeElement.textContent;
              document.activeElement?.nextSibling?.focus();
            }
            break;
          }
        }
      };
    },

    async handleAutocomplete(event) {
      if (this.enabledFeatureFlags && !this.enabledFeatureFlags['plan_autocomplete_enabled']) return;
      if (event.key === 'Enter') {
        this.showAutocomplete = false;
        this.autoCompleteList = [];
        return;
      }
      this.showAutocomplete = true;
      const query = event.target && event.target.value ? event.target.value.replace(/[^0-9a-z]/gi, ' ').trim() : '';
      const response = await this.$planAdmin.searchAutocomplete({ query: encodeURIComponent(query), accessTags: this.tenantAccessTags });
      let regex = new RegExp(this.query.replace('|', ''), 'ig');
      let mask = `<span style="font-size:16px;font-weight:normal; font-family: 'Arial'; color: #5C5C5C;">${this.query.replace('|', '')}</span>`;
      response.forEach((text) => {
        text.queryText = text.text;
        text.text = text.text.replace(regex, mask);
      });
      this.scrollList();
      this.autoCompleteList = response;
    },
    handleSearchClear() {
      this.autoCompleteList = [];
      this.EventBus.emit('search-cleared');
    }
  }
};
</script>
<style lang="scss">
.filters {
  .v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot {
    background-color: none !important;
    border: 2px black solid;
  }
  .theme--light.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot {
    background: none;
  }
}

.filter {
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    margin-bottom: 0;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
  }
}

.v-autocomplete__content.v-menu__content {
  border-radius: 20px;
  background-color: $color-grey !important;
}
</style>
<style lang="scss" scoped>
.autocomplete-see-all {
  font-family: 'Arial';
  font-weight: bold;
  font-size: 16px;
  border-top: solid;
  border-top-color: rgb(0, 0, 0, 0.2);
  padding: 10px 20px;
  cursor: pointer;
}
.scrollable {
  padding-right: 30px;
  max-height: 200px;
  overflow-y: scroll;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #d8d8d8;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4c5c8d;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #4c5c8d;
  }
}
.filter-pill {
  padding: 7px 15px;
  max-height: 36px;
  border: 2px solid rgb(0, 0, 0, 0.2);
}
.search .mdi-chevron-down::before {
  color: $color-black;
}
.filter-dropdown {
  padding: 30px;
  padding-right: 30px;
  width: max-content;
  background-color: $color-skill-table !important;
  border-radius: 20px;
  box-shadow: 2px 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 5px 5px 0 rgb(0 0 0 / 12%);
  position: absolute;
  z-index: 2 !important;
}
.backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: none;
  z-index: 0;
}
.v-autocomplete__content.menuable__content__active {
  border-radius: 100px !important;
}
.v-autocomplete__content.v-menu__content .v-select-list {
  padding: 30px;
  background-color: $color-grey-lighter !important;
  max-height: 80% !important;
  overflow-y: scroll;
}
.cancel-button {
  width: 100px;
  background-color: #fff !important;
  color: $button-primary-color-text !important;
  border: none;
  box-shadow: none;
}
.save-button {
  width: 100px;
  background: $button-primary-color-bg !important;
  color: black;
}

.ccc-dropdown {
  position: absolute;
  right: 40px;
  top: 8px;
  text-decoration: none;
}
</style>
<style lang="scss">
.search-container {
  .v-field--variant-solo {
    background-color: white !important;
    height: 2.625rem;
    &:focus-within {
      outline-color: rgb(44, 62, 80) !important;
      outline-style: solid;
      outline-width: thin;
    }
  }
  .v-field__field,
  .v-field__prepend-inner {
    height: 2.625rem;
  }

  .v-field__input {
    padding-top: 0rem !important;
  }
}
.search-container .v-label.v-label--active.theme--light {
  display: none;
}
.ccc-dropdown.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.search .theme--light.v-select.v-text-field--solo-inverted.v-input--is-focused .v-select__selections {
  color: #000 !important;
}
.search .theme--light.v-select.v-text-field--solo-inverted .v-select__selections {
  color: #000 !important;
}
.search .v-select__selections {
  white-space: nowrap;
}
.search .v-select__selections > div {
  color: $color-black;
  font-size: 11px;
  font-weight: bold;
}

.search .mdi-close-circle::before {
  color: $color-black;
}
.search .theme--light.v-chip:not(.v-chip--active) {
  background: transparent;
  border: 1px solid $color-black;
  cursor: pointer;
}
.search .v-select__slot .v-label {
  font-size: 11px;
  font-weight: bold;
  color: $color-black;
}
.search .filter-input .v-input__slot {
  cursor: pointer !important;
}
.search h3 {
  color: $color-black;
}
</style>
