<template>
  <div>
    <div class="dashboard-details-table-container" v-show="structureReady">
      <div class="dashboard-details-treeview-container">
        <div class="tree-view-header">{{ $t('orgStructure.orgChart') }}</div>
        <!-- 
        <TreeView v-if="treeViewReady" class="dashboard-details-treeview" :items="structure" activatable>
          <template v-slot:label="{ item, active }">
            <div v-if="item.hasOwnProperty('lead')" @click="active ? $event.stopPropagation() : onGetPlan(item)">
              <span :id="`treeview-node-label-${item.id}`" class="dept-name">{{ item.name }}</span>
            </div>
          </template>
        </TreeView> 
        -->
        <DataTableTreeView v-if="treeViewReady" class="dashboard-details-treeview" :items="structure" :row="0" @on-get-plan="onGetPlan" />
        <div v-else>
          <div class="loader t-flex t-justify-center t-items-center">
            <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Tree View" />
          </div>
        </div>
      </div>
      <v-data-table-server
        class="dashboard-details-table"
        :headers="headers"
        :items="filterEmployees"
        :items-per-page="itemsPerPage"
        :items-per-page-options="pageSizeOption"
        :items-length="totalItems"
        @current-items="currentEmployees"
        :sort-by="sortBY"
        :sort-desc="sortDescending"
        :fixed-header="true"
        @update:options="loadItems"
        :item-key="'item.guid'"
      >
        <template v-slot:caption>
          <div id="table-caption" class="caption" :aria-label="`${$t('common.titleCase.organizationChart')}`">{{ $t('common.titleCase.organizationChart') }}</div>
        </template>
        <!-- headers -->
        <template v-slot:[`header.status`]="{ column }">
          <span class="enrollment-header">{{ column.title }}</span>
          <span class="enrollment-status"><ToolTip :content="$t('insights.statusToolTip')" width="16.3125rem" /></span>
        </template>

        <template v-slot:[`body`] v-if="!tableDataReady">
          <div class="dashboard-details-table-loader">
            <v-card-text class="loader-text">{{ $t('common.titleCase.loading') }}</v-card-text>
            <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
          </div>
        </template>

        <!-- body -->
        <template v-slot:[`item.name`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.name }}</span>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.empName`]="{ item }">
          <div :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.empName }}</span>
              </template>
              <span>{{ item.empName }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.email`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.email }}</span>
              </template>
              <span>{{ item.email }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip v-if="caller === 'usage'" location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ getLocalizedUsageStatus(item.status) }}</span>
              </template>
              <span>{{ getLocalizedUsageStatus(item.status) }}</span>
            </v-tooltip>
            <v-tooltip v-if="caller === 'enrollments'" location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ getLocalizedEnrollmentStatus(item.status) }}</span>
              </template>
              <span>{{ getLocalizedEnrollmentStatus(item.status) }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.enrollmentStatus`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ getLocalizedEnrollmentRateStatus(item.enrollmentStatus) }}</span>
              </template>
              <span>{{ getLocalizedEnrollmentRateStatus(item.enrollmentStatus) }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.enrollmentDate`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props" class="capitalize">{{ localizedDate(moment, item.enrollmentDate) }}</span>
              </template>
              <span class="capitalize">{{ localizedDate(moment, item.enrollmentDate) }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.title }}</span>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.role`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.role }}</span>
              </template>
              <span>{{ item.role }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.license`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.license }}</span>
              </template>
              <span>{{ item.license }}</span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:[`item.inviteDate`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props" class="capitalize">{{ localizedDate(moment, item.inviteDate) }}</span>
              </template>
              <span class="capitalize">{{ localizedDate(moment, item.inviteDate) }}</span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:[`item.firstInviteDate`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props" class="capitalize">{{ localizedDate(moment, item.firstInviteDate) }}</span>
              </template>
              <span class="capitalize">{{ localizedDate(moment, item.firstInviteDate) }}</span>
            </v-tooltip>
          </span>
        </template>
        <template v-slot:[`item.lastAccessed`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props }">
                <span v-bind="props" class="capitalize">{{ localizedDate(moment, item.lastAccessed) }}</span>
              </template>
              <span class="capitalize">{{ localizedDate(moment, item.lastAccessed) }} </span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:footer>
          <InsightsPagination :totalPages="totalPages" :pageSize="pageSize" :pageNumberChangeEvent="pageNumberChangeEvent" :pageSizeChangeEvent="pageSizeChangeEvent" :pageNum="pageNum" :pageRange="pageRange" :handlePageClick="handlePageClick" />
        </template>
      </v-data-table-server>
    </div>
    <div v-show="!structureReady">
      <div class="loader drilldown-loader t-flex t-justify-center t-items-center card">
        <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Drilldown Table" />
      </div>
    </div>
  </div>
</template>

<script>
import ToolTip from '../../../components/molecules/ToolTip.vue';
import DataTableTreeView from '@/components/molecules/DataTableTreeView';
import InsightsPagination from './InsightsPagination.vue';
import { localizedDate, getLocalizedUsageStatus, getLocalizedEnrollmentRateStatus, getLocalizedEnrollmentStatus } from '@/utils';

export default {
  name: 'InsightsDrilldownTable',
  emits: ['on-data-table-change'],
  components: { InsightsPagination, ToolTip, DataTableTreeView },
  props: {
    caller: { type: String },
    structureReady: { type: Boolean },
    treeViewReady: { type: Boolean },
    structure: { type: Array, default: () => [] },
    headers: { type: Array, default: () => [] },
    onGetPlan: { type: Function },
    sortBy: { type: Array, default: () => [] },
    sortDesc: { type: Boolean },
    currentEmployees: { type: Function },
    tableDataReady: { type: Boolean },
    totalPages: { type: Number },
    totalItems: { type: Number, default: () => 0 },
    pageSize: { type: Number },
    pageRange: { type: Number },
    handlePageClick: { type: Function },
    pageSizeChangeEvent: { type: String },
    pageNumberChangeEvent: { type: String },
    pageNum: { type: Number },
    employees: { type: Array }
  },
  data() {
    return {
      sortBY: this.sortBy,
      sortDescending: this.sortDesc,
      itemsPerPage: this.pageSize,
      pageSizeOption: [
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' }
      ]
    };
  },
  watch: {
    tableDataReady: function (val) {
      this.$nextTick(async () => {
        const container = document.getElementsByClassName('dashboard-details-table')[0];
        if (container) {
          const wrapper = document.getElementsByClassName('v-table__wrapper')[0];
          wrapper.scrollLeft && (wrapper.scrollLeft = 0);
          const thead = container.getElementsByTagName('thead')[0];
          const tbody = container.getElementsByTagName('tbody')[0];

          if (val) {
            thead.style.display = 'table-header-group';
            tbody.style.overflow = 'auto';
          } else {
            thead.style.display = 'inline-table';
            tbody.style.overflow = 'hidden';
            tbody.style.width = `${container.clientWidth / 16}rem`;
          }
        }
      });
    }
  },
  computed: {
    filterEmployees() {
      return this.employees.map((x) => ({ ...x, isSelectable: !x.disabled }));
    }
  },
  methods: {
    localizedDate,
    getLocalizedUsageStatus,
    getLocalizedEnrollmentRateStatus,
    getLocalizedEnrollmentStatus,
    loadItems(tableData) {
      this.$emit('on-data-table-change', tableData);
    }
  }
};
</script>

<style lang="scss">
.dashboard-details-table-container .dashboard-details-treeview-container .dashboard-details-treeview .v-treeview-node__root .v-icon:focus-visible {
  outline: black 2px solid;
  border-radius: unset;
}

@mixin scrollable {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 1.25rem;
    width: 0.25rem;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.dashboard-details-table-container {
  display: grid;
  grid-template-columns: 19.6875rem 9fr;
  margin-bottom: 5rem;

  .dashboard-details-treeview-container {
    .tree-view-header {
      height: 4.6875rem;
      display: flex;
      align-items: center;
      padding: 2rem;
      font: normal normal bold 1rem/1.125rem Arial;
      letter-spacing: 0rem;
      color: #000000;
      border-bottom-left-radius: 0.3125rem;
      border-top-left-radius: 0.3125rem;
      background-color: #d0d0d0;
    }
    .dashboard-details-treeview {
      margin-right: 2rem;
      overflow: scroll;
      @include scrollable;
      height: 30.25rem;

      .dept-name {
        font: normal normal normal 1rem/2.5rem Arial;
        letter-spacing: 0rem;
        color: #000000;
        opacity: 1;
        cursor: pointer;
      }

      .v-treeview-node__label {
        overflow: initial;
        text-overflow: initial;
      }

      .v-treeview-node__root {
        cursor: pointer;
        .v-icon {
          background: #e4e5e4 0% 0% no-repeat padding-box;
          border: 0.0625rem solid #e5e5e5;
          opacity: 1;
          border-radius: 6.25rem;
          height: 1.9375rem;
          width: 1.9375rem;
        }

        .dept-name {
          font: normal normal normal 1rem/2.5rem Arial;
          letter-spacing: 0rem;
          color: #000000;
          opacity: 1;
          cursor: pointer;
        }
      }
      .v-treeview-node__children {
        .v-treeview-node__root {
          .dept-name {
            font: normal normal normal 1rem/2.5rem Arial;
            letter-spacing: 0rem;
            color: #000000;
            opacity: 1;
          }
        }
      }

      .mdi-menu-down::before {
        content: '\F035D';
        content: '\F0140';
      }
      .v-treeview-node--active {
        background: rgba(255, 255, 255, 0.6);
        color: $color-white !important;
        min-width: fit-content;

        .dept-name {
          font-weight: bold !important;
        }
      }
    }
  }

  .v-data-table--fixed-header {
    border: none !important;
  }

  .dashboard-details-table {
    background: inherit;
    display: grid;

    .caption {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }

    .dashboard-details-table-loader {
      margin: 10rem 3.125rem 3.125rem;
      text-align: center;
      width: 56rem;

      .v-input {
        display: contents !important;
        .v-field__overlay {
          display: none;
        }
      }

      .loader-text {
        font-size: 1.875rem;
        color: $color-primary;
      }

      .loader-bar {
        position: relative;
        top: -1.875rem;
      }
    }

    .v-table__wrapper {
      @include scrollable;
      height: 35rem !important;
      overflow: auto;
      position: relative;

      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 1.25rem;
        margin-right: 7rem;
        margin-top: 4.6875rem;
      }

      &::-webkit-scrollbar:vertical {
        border-radius: 0rem;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #d0d0d0 56%);
        margin-bottom: 1.25rem;
      }
      table {
        tbody {
          overflow: auto;
          @include scrollable;
        }

        thead {
          border-bottom-right-radius: 0.3125rem !important;
          border-top-right-radius: 0.3125rem !important;
          height: 4.6875rem;
          border-radius: 0.3125rem;
          background: #d0d0d0;

          th {
            box-shadow: none;
            &:nth-of-type(3) {
              z-index: 5;
            }
          }
          .enrollment-header {
            margin-right: 0.375rem;
          }
          .v-data-table__td {
            background-color: #d0d0d0;
          }
          span {
            text-align: left;
            font: normal normal bold 1rem/1.125rem Arial;
            letter-spacing: 0rem;
            color: #000000;
            opacity: 1;
          }
        }

        tbody {
          tr:hover:not(.v-table__expanded__content) {
            &:nth-child(even) {
              background: #fff;
            }
          }

          .v-input {
            margin: 0rem;
            display: flex;
            align-items: center;
            height: 2.75rem;
            justify-content: center;
            padding-top: 1.375rem;
          }

          .employee-data-field {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 15rem;
          }
          .half-opacity {
            opacity: 0.5;
          }

          tr {
            td {
              height: 2.75rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 15rem;
            }

            .v-input {
              &:not(.v-input--is-disabled) {
                .mdi-checkbox-blank-outline {
                  border-radius: 0.25rem;
                  color: #1f3370;
                }
              }
            }
          }

          tr:nth-child(even) {
            td {
              background: #fff;
            }
          }
          tr:nth-child(odd) {
            td {
              background: #efefef 0% 0% no-repeat padding-box;
            }
          }
          tr.group-active-row-selection > td {
            transition: all 0.7s ease-in-out;
          }
          tr.group-active-row-selection > td > div {
            padding: 1.25rem;
            height: 2.5rem;
          }
          @keyframes anim {
            0% {
              transform: scale(1, 0);
            }
            100% {
              transform: scale(1, 1);
            }
          }
          tr.group-inactive > td {
            opacity: 0;
            line-height: 0;
            padding: 0 0.625rem;
            height: 0 !important;
          }
        }
        .v-data-table__td {
          border: none !important;
        }
      }
    }
    .v-data-table-footer {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding: 0 0.5rem;
      justify-content: center;
      margin-top: 1.5rem;
    }
  }
}

.drilldown-loader {
  height: 49.1875rem;
  background: #fff;
  margin: 0rem !important;
  border-radius: 1.25rem;
}
</style>
