<template>
  <div :class="dueDatesExpanel ? 'due-dates-expansion-container' : ''">
    <v-row v-if="item.formatType.toLocaleLowerCase() == 'course'">
      <v-col cols="8" class="card1">
        <div class="t-mb-6 t-font-arial t-text-base">{{ item.synopsis }}</div>
        <div v-if="item.skills" class="t-font-bold t-text-sm t-font-arial t-mb-4">{{ $tc('common.titleCase.skill', 2) }}</div>
        <div v-if="item.skills" class="content-skills t-font-arial t-text-base">{{ item.skills }}</div>
      </v-col>
      <v-col cols="4" class="card2">
        <v-row class="t-mt-3" v-if="item.subCategory">
          <v-col cols="2"
            ><v-icon class="t-text-3xl t-text-black">{{ 'mdi-lightbulb-on' }}</v-icon></v-col
          >
          <v-col>
            <div class="t-font-bold t-text-xs">{{ $t('catalog.dialogCategoriesTopicsNoSpace') }}</div>
            <div class="t-text-xs">{{ item.subCategory }}</div>
          </v-col>
        </v-row>
        <v-row class="t-mt-3" v-if="item.accreditation && item.accreditation.length">
          <v-col cols="2"
            ><v-icon class="t-text-3xl t-text-black">{{ 'mdi-seal' }}</v-icon></v-col
          >
          <v-col>
            <div class="t-font-bold t-text-xs">{{ $t('catalog.dialogAccreditation') }}</div>
            <div class="t-text-xs">
              {{
                item.accreditation
                  .map(({ org }) => {
                    return org;
                  })
                  .join(', ')
              }}
            </div>
          </v-col>
        </v-row>
        <v-row class="t-mt-3" v-if="item.accreditation && item.accreditation.length">
          <v-col cols="2"
            ><v-icon class="t-text-3xl t-text-black">{{ 'mdi-check-circle-outline' }}</v-icon></v-col
          >
          <v-col>
            <div class="t-font-bold t-text-xs">{{ $t('catalog.dialogCreditType') }}</div>
            <div class="t-text-xs">
              {{
                item.accreditation
                  .map(({ creditType }) => {
                    return creditType;
                  })
                  .join(', ')
              }}
            </div>
          </v-col>
        </v-row>
        <v-row class="t-mt-3" v-if="item.accreditation && item.accreditation.length">
          <v-col cols="2"
            ><v-icon class="t-text-3xl t-text-black">{{ 'mdi-clock-outline' }}</v-icon></v-col
          >
          <v-col>
            <div class="t-font-bold t-text-xs">{{ $t('catalog.dialogCreditHours') }}</div>
            <div class="t-text-xs">
              {{
                item.accreditation
                  .map(({ creditHours }) => {
                    return creditHours;
                  })
                  .join(' hrs, ')
              }}
              hr
            </div>
          </v-col>
        </v-row>
        <v-row class="t-mt-3" v-if="item.fieldOfStudy.length">
          <v-col cols="2"
            ><v-icon class="t-text-3xl t-text-black">{{ ' mdi-school' }}</v-icon></v-col
          >
          <v-col>
            <div class="t-font-bol t-text-sm">{{ $t('catalog.dialogFieldOfStudy') }}</div>
            <div class="t-text-xs">{{ item.fieldOfStudy }}</div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="['article', 'audio', 'video'].includes(item.formatType.toLocaleLowerCase())">
      <v-col cols="8" class="card1">
        <div class="t-font-bold t-text-xs t-pb-7" v-if="item.publishedDate">
          <span>{{ localizedDate(moment, item.publishedDate) }} </span>
        </div>
        <div class="t-text-base t-font-arial">{{ item.synopsis }}</div>
      </v-col>
      <v-col v-if="item.skills" cols="4" class="card2">
        <div class="t-font-bold t-font-arial t-text-xs t-mb-7">{{ $t('catalog.dialogSkillsAllCaps') }}</div>
        <div class="content-skills t-text-base t-font-arial">{{ item.skills }}</div>
      </v-col>
    </v-row>
    <v-row v-if="item.formatType.toLocaleLowerCase() == 'credential'">
      <v-col cols="8" class="card1">
        <div class="t-mb-7" v-html="item.synopsis"></div>
      </v-col>
      <v-col cols="4" class="card2">
        <div v-if="item.skills" class="t-font-bold t-font-arial t-text-xs t-mb-7">{{ $t('catalog.dialogSkills') }}</div>
        <div v-if="item.skills" class="content-skills">{{ item.skills }}</div>
        <v-row class="t-mt-3">
          <v-col cols="1"
            ><v-icon class="t-text-xxl t-text-black">{{ 'mdi-check-circle-outline' }}</v-icon></v-col
          >
          <v-col class="t-ml-3">
            <div class="t-font-bold t-text-xs t-mt-1">{{ $t('catalog.vdiSupported') }}</div>
            <div class="t-text-xs">
              {{ item.vdiRequired ? 'Yes' : 'No' }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div v-if="item.formatType.toLocaleLowerCase() == 'credential'" class="skill-builder-row t-flex">
      <v-col class="skill-builder-col">
        <v-card v-for="(ele, i) in item.skillBuilder" :key="ele.title" class="skill-builder-card">
          <div class="t-text-xs t-mb-2 t-text-primary-color">Skill Builder {{ i + 1 }}</div>
          <div class="content-wrapper">
            <div class="t-font-futura-pt-demi t-mb-2 t-text-primary-color skill-builder-title">{{ ele.title }}</div>
            <div class="t-text-xs skill-builder-text">{{ ele.synopsis }}</div>
          </div>
          <div class="t-absolute t-bottom-8 t-w-4/6">
            <v-row>
              <v-col v-if="ele.level" cols="6">
                <v-icon class="t-text-2xl t-text-black">{{ 'mdi-check-circle-outline' }}</v-icon> <span class="t-text-xs">{{ ele.level }}</span>
              </v-col>

              <v-col v-if="ele.Duration" cols="6">
                <v-icon class="t-text-2xl t-mr-0.5 t-text-black">{{ 'mdi-clock-outline' }}</v-icon> <span class="t-text-xs">{{ $sanitize(formatDuration(ele.Duration)) }}</span>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <v-card class="skill-builder-card" v-if="item.capstone">
          <div class="t-mb-2 t-text-xs t-text-primary-color">{{ $tc('common.titleCase.capstone') }}</div>
          <div class="content-wrapper">
            <div class="t-font-futura-pt-demi t-mb-2 t-text-primary-color skill-builder-title" style="font-size: 18px">{{ item.capstone.title }}</div>
            <div class="t-text-xs skill-builder-text">{{ item.capstone.synopsis }}</div>
          </div>
          <div class="t-absolute t-bottom-8 t-w-4/6">
            <v-row>
              <v-col v-if="item.capstone.level" cols="6">
                <v-icon class="t-text-2xl t-text-black">{{ 'mdi-check-circle-outline' }}</v-icon> <span class="t-text-xs">{{ item.capstone.level }}</span>
              </v-col>

              <v-col v-if="item.capstone.Duration" cols="6">
                <v-icon class="t-text-2xl t-mr-0.5 t-text-black">{{ 'mdi-clock-outline' }}</v-icon> <span class="t-text-xs">{{ $sanitize(formatDuration(item.capstone.Duration)) }}</span>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </div>
  </div>
</template>

<script>
import { localizedDate, formatDuration } from '@/utils';
export default {
  name: 'CourseCard',
  props: {
    item: {
      type: Object,
      require: true,
      default: () => {
        return {};
      }
    },
    dueDatesExpanel: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      }
    }
  },
  methods: {
    formatDuration,
    localizedDate
  }
};
</script>

<style lang="scss" scoped>
.content-skills {
  max-height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}
.card1 {
  border-right: 1px solid #707070;
  margin-bottom: 30px;
  // padding-left: 90px;
  // padding-right: 30px;
}
.card2 {
  padding: 0px 90px 80px 50px;
}
.skill-builder-row {
  background-color: #f8f8f8;
  border-radius: 20px;
}

.skill-builder-col {
  margin: 0px 100px 70px 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.skill-builder-title {
  font-size: 18px;
  line-height: 22px;
}
.skill-builder-card {
  margin-right: 10px;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  max-width: 32.333%;
  padding: 3.125rem 1.88rem 0.75rem 1.88rem;
  margin-bottom: 1rem;
  border: 0;
  flex-basis: 33.333%;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  min-height: 380px;
}
.skill-builder-card:nth-child(3n) {
  margin-right: 0;
}

.content-wrapper {
  max-height: 237px;
  overflow-y: auto;
}
.due-dates-expansion-container {
  margin-top: 1rem;
  .card1 {
    padding-left: 4.25rem;
  }
  .card2 {
    padding-left: 5.725rem;
  }
}
@media screen and (min-width: 1700px) and (max-width: 1920px) {
  .due-dates-expansion-container {
    .card2 {
      padding-left: 6.5rem;
    }
  }
}
@media screen and (max-width: 1554px) {
  .skill-builder-col {
    margin: 0px 40px 70px 40px;
  }
}
</style>
