<template>
  <div class="component-container insights-tab-container">
    <div class="tab-background">
      <div class="tab-container">
        <div v-for="(tab, index) in tabs" :key="index" class="tab" :class="tabIndex === index ? 'selected' : null" @click="setTabIndex(index)" :data-el="`tab-${tab.title.replace(' ', '')}`">{{ tab.title }}</div>
      </div>
    </div>
    <div style="margin-top: 40px">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InsightsTab',
  props: {
    tabs: { type: Array, required: true },
    selected: {
      type: Number,
      required: false,
      default: () => {
        return 0;
      }
    }
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  created() {
    this.tabIndex = this.selected;
  },
  methods: {
    setTabIndex(index) {
      this.tabIndex = index;
      this.$emit('updateTabIndex', index);
    }
  }
};
</script>

<style lang="scss" scoped>
.component-container {
  background: $color-grey-lighter;
  height: 100%;
}

.tab-background {
  position: absolute;
  background: $color-primary-lighter;
  right: 0px;
  top: 98px;
  padding-top: 10px;
  height: 65px;
  width: 100%;
}
.insights-tab-container .tab-background {
  top: 50px;
}

.tab-container {
  flex-direction: row;
  margin: 15px 65px;
  overflow: hidden;
  display: flex;
  min-width: 500px;

  .tab {
    margin-right: 5%;
    padding-bottom: 14px;
    // font-size: 20px;
    font-weight: bold;
    color: $color-grey;

    &.selected {
      color: $color-primary;
      border-bottom: 3px solid $color-primary;
      margin-bottom: 0px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
