<template>
  <div class="content-container">
    <ReportLastUpdatedHeader />

    <div class="content-header-container">
      <div class="content-header-title">
        <h1>{{ $t('insights.content.insightsHeader') }}</h1>
        <div>{{ $t('insights.content.insightsSubHeader') }}</div>
        <div v-if="this.showNoCCCMessage">{{ $t('insights.content.showNoCCCMessage') }}</div>
      </div>
      <div>
        <RadioFilter v-show="isStructureReady" :filter="timeFilter" :selectedOption="selectedTimeOption" :openDropdown="openDropdown" :onCancel="onCancel" :onApply="onApply" @onFilterChange="onTimeChange" />
        <div v-if="isStructureReady && selectedTimeOptionString === 'CUSTOM'" style="float: right">
          <div id="chips-container">
            <v-chip in class="chip t-text-xs mt-4 t-bg-black" closable @click:close="onRemoveDateRange()"> {{ showSelectedFilter() }} </v-chip>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!insightsAPIError" class="content-insights-container">
      <ContentInsightTileRow
        :insightsDataLoaded="insightsDataLoaded"
        :columns="[5, 7]"
        :headers="[$t('insights.content.contentHeading'), $t('insights.content.capstoneProjects')]"
        :data="[
          [insightsData.credentialCompletedConsumptionData, insightsData.credentialInProgressConsumptionData],
          [insightsData.capstoneUploadsConsumptionData, insightsData.capstonePassedConsumptionData, insightsData.capstoneFailedConsumptionData, insightsData.capstonePendingConsumptionData]
        ]"
        :showHoursPosition="['right', null]"
        :toolTipText="[
          [$t('insights.content.credentialsCompletedToolTip'), $t('insights.content.credentialsInProgressToolTip')],
          [$t('insights.content.capstoneUploadsToolTip'), $t('insights.content.capstonePassedToolTip'), $t('insights.content.capstoneFailedToolTip'), $t('insights.content.capstonePendingToolTip')]
        ]"
      />

      <ContentInsightTileRow
        :insightsDataLoaded="insightsDataLoaded"
        :columns="showAssetsTile ? [5, 7] : [12]"
        :headers="[$t('insights.content.courses'), $t('insights.content.assets')]"
        :data="[
          [insightsData.courseCompletedConsumptionData, insightsData.courseInProgressConsumptionData],
          [insightsData.assetsBookmarkedConsumptionData, insightsData.assetsDownloadedConsumptionData, insightsData.assetsViewedConsumptionData]
        ]"
        :showHoursPosition="['right', null]"
        :toolTipText="[[$t('insights.content.courseToolCompletedTip'), $t('insights.content.courseToolProgressTip')]]"
        :dateRange="dateRange"
      />

      <ContentInsightTileRow
        :insightsDataLoaded="insightsDataLoaded"
        :columns="[4, 4, 4]"
        :headers="[$t('insights.content.audios'), $t('insights.content.videos'), $t('insights.content.articles')]"
        :data="[
          [insightsData.audioCompletedConsumptionData, insightsData.audioInProgressConsumptionData],
          [insightsData.videoCompletedConsumptionData, insightsData.videoInProgressConsumptionData],
          [insightsData.articleCompletedConsumptionData, insightsData.articleInProgressConsumptionData]
        ]"
        :showHoursPosition="['bottom', 'bottom', 'bottom']"
        :toolTipText="[
          [$t('insights.content.audioCompletedToolTip'), $t('insights.content.audioInProgressToolTip')],
          [$t('insights.content.videoCompletedToolTip'), $t('insights.content.videoInProgressToolTip')],
          [$t('insights.content.articlesCompletedToolTip'), $t('insights.content.articlesInProgressToolTip')]
        ]"
      />
    </div>

    <div v-else class="content-insights-container-error card">
      <h3>{{ $t('insights.content.apiError') }}</h3>
    </div>

    <div class="content-consumption-header-container">
      <div>
        <h2>{{ $t('insights.content.consumptionTrendsChartHeader') }}</h2>
        <span>{{ $t('insights.content.consumptionTrendsHeaderChartSubhHeader') }}</span>
      </div>

      <RadioFilter :filter="progressFilter" :selectedOption="progressOption" :openDropdown="openProgressDropdown" :onCancel="onProgressCancel" :onApply="onProgressApply" @onFilterChange="onProgressChange" />
    </div>

    <div class="content-trends-chart-container">
      <div class="content-trends-chart">
        <ConsumptionTrends :timeFilter="selectedTimeOptionString" :drillDownTableFilters="selectedFilters" :searchInputText="searchTextForCSV" :statusCategory="progressOptionString" :orgInFocus="orgInFocus" :dateRange="dateRange" />
      </div>
    </div>

    <div class="download-transcripts-container" v-if="!isC1">
      <h3>{{ $t('insights.content.downloadGroupTranscriptsHeader') }}</h3>
      <div>
        {{ $t('insights.content.downloadGroupTranscriptsText1') }}
        <svg-icon icon-class="icon-download" />
        {{ $t('insights.content.downloadGroupTranscriptsText2') }}
      </div>
    </div>

    <div v-if="isC1">
      <ConsumptionDrillDownTableC1 :timeFilter="selectedTimeOptionString" @setIsStructureReady="setIsStructureReady" @filters-applied="setActiveFilters" @search-text="setSearchInput" :updateContentProps="updateContentProps" />
    </div>
    <div v-else>
      <ConsumptionDrillDownTable :timeFilter="selectedTimeOptionString" @setIsStructureReady="setIsStructureReady" @filters-applied="setActiveFilters" @search-text="setSearchInput" :updateContentProps="updateContentProps" :dateRange="dateRange" />
    </div>

    <div class="content-footer">
      <div>
        <p>{{ $t('insights.content.footerHeadline') }}</p>
        <p>{{ $t('insights.content.footerSubHeadline') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import localforage from 'localforage';
import ContentInsightTileRow from './components/ContentInsightTileRow.vue';
import ConsumptionTrends from './components/ConsumptionTrends';
import ConsumptionDrillDownTable from '@/views/insights/components/ConsumptionDrillDownTable';
import ConsumptionDrillDownTableC1 from '@/views/insights/components/ConsumptionDrillDownTableC1';
import RadioFilter from './components/RadioFilter.vue';
import ReportLastUpdatedHeader from './components/ReportLastUpdatedHeader.vue';
import { translate } from '@/plugins/i18n.js';

const initialState = () => ({
  clientCategory: null,
  assetsEnabled: false,
  isC1: null,
  timeFilter: {},
  progressFilter: {
    options: [
      { id: 1, translationKey: 'common.filter.progressOptions.allProgress', value: 'ALL' },
      { id: 2, translationKey: 'common.filter.progressOptions.inProgress', value: 'IN_PROGRESS' },
      { id: 3, translationKey: 'common.filter.progressOptions.completed', value: 'COMPLETED' }
    ],
    showExpandedFilter: false,
    disabled: false
  },
  selectedTimeOption: '',
  selectedTimeOptionString: '',
  insightsData: {},
  insightsDataLoaded: false,
  insightsAPIError: false,
  progressOption: '',
  progressOptionString: '',
  selectedFilters: [],
  searchTextForCSV: '',
  showNoCCCMessage: false,
  orgInFocus: null,
  isStructureReady: false,
  dateRange: {
    startDate: '',
    endDate: ''
  }
});
export default {
  name: 'ContentView',
  components: { ConsumptionTrends, ContentInsightTileRow, ConsumptionDrillDownTable, ConsumptionDrillDownTableC1, RadioFilter, ReportLastUpdatedHeader },
  data() {
    return initialState();
  },

  async mounted() {
    Object.assign(this.$data, initialState());
    const clientCategory = await localforage.getItem('my_client_category');
    this.clientCategory = clientCategory && clientCategory.toLowerCase();
    this.isC1 = this.clientCategory && this.clientCategory.toLowerCase() === 'c1';
    const tenantSettings = await localforage.getItem('my_tenant_settings');
    this.assetsEnabled = tenantSettings?.['apply-access-enabled'] === true || false;
    this.setupTimeFilters();
    this.progressOption = translate('common.filter.progressOptions.allProgress');
    this.progressOptionString = 'ALL';
    await this.getInsightsData();
    document.addEventListener('click', this.closePopup);
  },
  computed: {
    showAssetsTile() {
      return this.assetsEnabled && this.clientCategory.toLowerCase() === 'c2';
    }
  },
  filters: {
    formatLastUpdated(date) {
      const d = new Date(date);
      const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
      let datePart = d.toLocaleDateString('en-US', options);
      return datePart;
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closePopup);
  },
  methods: {
    updateContentProps(key, value) {
      this[key] = value;
    },
    setupTimeFilters() {
      if (this.isC1) {
        this.timeFilter = {
          options: [
            { id: 1, translationKey: 'insights.time.30Days', value: 'DAYS30' },
            { id: 2, translationKey: 'insights.time.90Days', value: 'DAYS90' },
            { id: 3, translationKey: 'insights.time.12Months', value: 'YEAR' }
          ],
          showExpandedFilter: false
        };
        this.selectedTimeOption = translate('insights.time.12Months');
        this.selectedTimeOptionString = 'YEAR';
      } else {
        this.timeFilter = {
          options: [
            { id: 1, translationKey: 'insights.time.allTime', value: 'ALLTIME' },
            { id: 2, translationKey: 'insights.time.30Days', value: 'DAYS30' },
            { id: 3, translationKey: 'insights.time.90Days', value: 'DAYS90' },
            { id: 4, translationKey: 'insights.time.12Months', value: 'YEAR' },
            { id: 5, translationKey: 'Date range', value: 'CUSTOM', startDate: '', endDate: '' }
          ],
          showExpandedFilter: false
        };
        this.selectedTimeOption = translate('insights.time.allTime');
        this.selectedTimeOptionString = 'ALLTIME';
      }
    },
    async openDropdown() {
      this.timeFilter.showExpandedFilter = true;
    },
    async openProgressDropdown() {
      this.progressFilter.showExpandedFilter = true;
    },
    setIsStructureReady(bool) {
      this.isStructureReady = bool;
    },
    async onApply() {
      const selectedTimeOptionObject = this.timeFilter.options.find((o) => translate(o.translationKey) === this.selectedTimeOption);
      this.selectedTimeOptionString = selectedTimeOptionObject.value;
      this.dateRange.startDate = this.selectedTimeOptionString === 'CUSTOM' ? this.moment(selectedTimeOptionObject.startDate).format('MM-DD-YYYY') : '';
      this.dateRange.endDate = this.selectedTimeOptionString === 'CUSTOM' ? this.moment(selectedTimeOptionObject.endDate).format('MM-DD-YYYY') : '';
      this.timeFilter.showExpandedFilter = false;
      this.insightsDataLoaded = false;
      this.insightsAPIError = false;
      this.showNoCCCMessage = false;
      await this.getInsightsData();
    },
    onCancel() {
      const selectedTimeOptionObject = this.timeFilter.options.find((o) => o.value === this.selectedTimeOptionString);
      this.selectedTimeOption = translate(selectedTimeOptionObject.translationKey);
      this.timeFilter.showExpandedFilter = false;
    },
    async onProgressApply() {
      const selectedProgressOptionObject = this.progressFilter.options.find((o) => translate(o.translationKey) === this.progressOption);
      this.progressOptionString = selectedProgressOptionObject.value;
      this.progressFilter.showExpandedFilter = false;
    },
    onProgressCancel() {
      const selectedProgressOptionObject = this.progressFilter.options.find((o) => o.value === this.progressOptionString);
      this.progressOption = translate(selectedProgressOptionObject.translationKey);
      this.progressFilter.showExpandedFilter = false;
    },
    async getInsightsData() {
      try {
        const tenantGuid = await localforage.getItem('my_tenant_guid');
        const time =
          this.selectedTimeOptionString === 'CUSTOM' && this.dateRange.startDate && this.dateRange.endDate ? `${this.selectedTimeOptionString}?startDate=${this.dateRange.startDate}&endDate=${this.dateRange.endDate}&dateFormat=MM-dd-yyyy` : this.selectedTimeOptionString;
        this.insightsData = await this.$planAdmin.getDashboardConsumptionInsights(tenantGuid, time);

        if (this.insightsData.status) {
          this.insightsAPIError = true;
        } else {
          this.insightsDataLoaded = true;
          if (!this.insightsData.assetsBookmarkedConsumptionData || !this.insightsData.assetsDownloadedConsumptionData || !this.insightsData.assetsViewedConsumptionData) {
            this.assetsEnabled = false;
          }
        }

        let total = 0;
        for (const key in this.insightsData) {
          if (key.includes('capstone') || key.includes('credential')) {
            total += this.insightsData[key].count;
          }
        }

        if (!total) {
          this.showNoCCCMessage = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    onTimeChange(time) {
      this.selectedTimeOption = time;
    },
    onProgressChange(selection) {
      this.progressOption = selection;
    },
    setActiveFilters(filters) {
      this.selectedFilters = filters;
    },
    setSearchInput(input) {
      this.searchTextForCSV = input || '';
    },
    closePopup(e) {
      if (this.timeFilter.showExpandedFilter && document.getElementById('time-filter-dropdown') && !document.getElementById('time-filter-dropdown').contains(e.target) && !e.target.parentNode.className.includes('mx') && !e.target.parentNode.className.includes('cell')) {
        this.onCancel();
      }
    },
    onRemoveDateRange() {
      this.dateRange.startDate = '';
      this.dateRange.endDate = '';
      this.selectedTimeOption = translate('insights.time.allTime');
      this.onApply();
    },
    showSelectedFilter() {
      const from = this.dateRange.startDate ? this.moment(this.dateRange.startDate).format('D MMM YYYY') : '';
      const to = this.dateRange.endDate ? this.moment(this.dateRange.endDate).format('D MMM YYYY') : '';
      return to && from ? `${from} - ${to}` : '';
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.content')
    };
  }
};
</script>
<style lang="scss">
.content-container {
  max-width: 90rem;
  padding: 0 3rem;
  margin: 6.5rem auto 0;

  .v-btn {
    box-shadow: none;
    background: transparent;
    &:hover {
      box-shadow: none;
      background: transparent;
    }
  }

  .content-header-container {
    display: grid;
    grid-template-columns: 10fr 2fr;

    .content-header-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h1 {
        font: normal normal normal 2.625rem/3.375rem 'Futura PT Demi';
        letter-spacing: 0rem;
        color: #1f3370;
      }
      div {
        font: normal normal normal 1rem/1.125rem Arial;
        letter-spacing: 0rem;
        color: #2d2d2d;
      }
    }
  }

  .content-report-update-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3rem;

    .content-report-update-text {
      text-align: left;
      font: normal normal normal 0.75rem/0.875rem Arial;
      letter-spacing: 0rem;
      color: #000000;
    }
  }

  .drop-filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .content-trends-chart {
    height: 100%;
  }
  .card {
    padding: 1rem;
    border-radius: 1.25rem;
    min-width: 15.625rem;
    box-shadow: none !important;
    background: #fff;
  }

  .content-insights-container {
    display: block;
    margin: 2rem 0 4rem;

    h3 {
      text-align: left;
      font: normal normal bold 1.125rem/1.875rem Arial;
      letter-spacing: 0.0006rem;
      color: #1f3370;
      opacity: 1;
    }
  }

  .content-insights-tiles-api-error {
    height: 42.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    h3 {
      text-align: left;
      font: normal normal bold 1.125rem/1.875rem Arial;
      letter-spacing: 0.0006rem;
      color: #1f3370;
      opacity: 1;
    }
  }

  .content-drilldown-header-container,
  .content-drilldown-header-container-c1,
  .content-consumption-header-container,
  .content-details-header-container {
    display: grid;
    grid-template-columns: 10fr 2fr;
    margin: 2rem 0rem;

    h2 {
      font: normal normal normal 2.125rem/2.6875rem 'Futura PT Demi';
      letter-spacing: 0rem;
      color: #1f3370;
    }
    span {
      font: normal normal normal 0.875rem/1.25rem Arial;
      letter-spacing: 0rem;
      color: #5c5c5c;
    }
  }

  .content-drilldown-header-container {
    margin-bottom: 0rem;
  }

  .content-consumption-chart-container {
    height: 27.5625rem;
    display: grid;
    grid-template-columns: 12fr;
    margin: 1rem 0rem;
  }
  .temp-drilldown {
    height: 41.75rem;
    background: #9f9f9f 0% 0% no-repeat padding-box;
    margin-bottom: 2rem;
  }

  .content-footer {
    display: grid;
    grid-template-columns: 10fr 2fr;
    margin: 3rem 0rem;
    text-align: left;
    font: normal normal normal 0.75rem/0.875rem Arial;
    letter-spacing: 0rem;
    color: #2d2d2d;
    opacity: 1;

    p {
      margin: 0rem;
    }
    .mdi:before,
    .mdi-set {
      display: inline-block;
      font: normal normal normal 3.5rem/1 'Material Design Icons';
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .content-footer-chevron {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .content-trends-chart-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 1.25rem;
    padding: 2rem;
    height: 29.0625rem;
  }

  .download-transcripts-container {
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 1.25rem;
    padding: 2rem;
    margin-top: 1.875rem;

    h3 {
      font: normal normal normal 1.75rem/2.1875rem 'Futura PT Demi';
      letter-spacing: 0px;
      color: #142a5d;
    }

    div {
      margin-top: 1rem;
      font: normal normal normal 1rem/1.25rem Arial;
      letter-spacing: 0;
      color: #5c5c5c;
    }
  }
}
</style>
