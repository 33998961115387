import { translate } from '../../plugins/i18n.js';

const state = {
  tabs: [
    {
      tidx: 0,
      className: 'edit-details',
      title: translate('stepper.detailsTabTitle'),
      path: '/catalog/stepper/create',
      completed: false,
      disabled: false
    },
    {
      tidx: 1,
      className: 'edit-content',
      title: translate('stepper.contentTabTitle'),
      path: '/catalog/stepper/edit',
      completed: false,
      disabled: false
    },
    {
      tidx: 2,
      className: 'manage-people',
      title: translate('stepper.peopleTabTitle'),
      path: '/catalog/stepper/manage',
      completed: false,
      disabled: false
    }
  ]
};
const actions = {
  async resetStepperState({ commit }, index) {
    await commit('RESET_STEPPER_STATE', index);
  },
  async setStepperState({ commit }, index) {
    await commit('SET_STEPPER_STATE', index);
  },
  async clearStepperState({ commit }) {
    await commit('CLEAR_STEPPER_STATE');
  },
  async refreshStepperState({ commit }) {
    await commit('REFRESH_STEPPER_STATE');
  }
};
const mutations = {
  CLEAR_STEPPER_STATE: (state) => {
    state.tabs.forEach((item) => {
      item.completed = false;
      item.disabled = false;
    });
  },
  SET_STEPPER_STATE: (state, index) => {
    const tabs = [...state.tabs];
    tabs[index].completed = true;
    state.tabs = [...tabs];
  },
  RESET_STEPPER_STATE: (state, index) => {
    const tabs = [...state.tabs];
    tabs[index].completed = false;
    state.tabs = [...tabs];
  },
  REFRESH_STEPPER_STATE: (state) => {
    state.tabs[0].title = translate('stepper.detailsTabTitle');
    state.tabs[1].title = translate('stepper.contentTabTitle');
    state.tabs[2].title = translate('stepper.peopleTabTitle');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
