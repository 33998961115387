<template>
  <div class="review-details-container t-bg-white t-p-5 t-rounded-2xl t-text-3xl t-opacity-100">
    <div class="custom-toast custom-toast-loading" v-if="showDownloadProgress">
      <div style="margin-top: 2rem">
        <v-progress-linear color="teal" indeterminate></v-progress-linear>
      </div>
      <div>
        <div class="download-status">{{ $t('manageReviewDetailContent.downloadIsInProgress') }} {{ downloadPercentage }}%</div>
      </div>
      <div>
        <v-btn class="cancel-btn-text download-cancel-btn" rounded color="primary" small @click="cancelDownload()"> {{ $t('manageReviewDetailContent.cancelDownload') }} </v-btn>
      </div>
      <div class="toast-footer"></div>
    </div>
    <AssetMediaDialog v-if="isMediaModalVisible" @close-media-dialog="mediaModalVisible(false, 0)" :media="submittedAsset.galleryImages" :selectedMedia="mediaModalIndex" />
    <h2 class="t-mb-8 t-text-3xl t-font-futura-pt-demi t-font-normal">{{ $t('manageReviewDetailContent.assetDetails') }}</h2>
    <div class="review-details-item t-flex t-items-start t-mb-12" v-if="submittedAsset.externalAssetLink || submittedAsset.assetReference">
      <label class="t-font-futura-pt-demi t-text-sm t-font-thin t-text-right t-mr-4" v-text="submittedAsset.externalAssetLink ? $t('manageReviewDetailContent.assetLink') : $t('manageReviewDetailContent.assetFile')"></label>
      <div v-if="submittedAsset.externalAssetLink" class="asset-link t-flex t-items-center t-h-14 t-opacity-100 t-rounded t-px-6 t-ml-1 t-text-black t-text-base t-justify-between" @copy="copyExternalAssetLink">
        <p class="t-truncate t-m-0" :title="submittedAsset.externalAssetLink">{{ submittedAsset.externalAssetLink }}</p>
        <svg-icon icon-class="icon-expand-out" class="t-cursor-pointer t-text-2xl" @click="onClickAssetLink" />
      </div>
      <div v-else-if="submittedAsset.assetReference" class="reference-container t-flex t-items-center t-h-14 t-opacity-100 t-rounded t-px-3 t-ml-1 t-text-black t-text-base t-justify-between">
        <div class="t-flex t-items-center asset-file-name">
          <span class="pdf-icon t-mr-2 t-text-2xl"><svg-icon icon-class="icons-file-zip"></svg-icon></span>
          <p class="t-text-base t-truncate t-m-0" :title="submittedAsset.assetReference.name">{{ submittedAsset.assetReference.name }}</p>
        </div>
        <div class="t-flex t-items-center">
          <span class="file-size t-mr-2 t-text-sm">{{ formatSize(submittedAsset.assetReference.size) }}</span>
          <button aria-label="download Asset file" class="download-btn t-cursor-pointer t-text-2xl" @click="downloadAssetZipFile()"><svg-icon icon-class="icon-download" /></button>
        </div>
      </div>
    </div>
    <div class="review-details-item t-flex t-items-start t-mb-12">
      <label class="t-font-futura-pt-demi t-text-sm t-font-thin t-text-right t-mr-4">{{ $t('manageReviewDetailContent.header') }}</label>
      <div class="asset-header t-text-3xl t-font-futura-pt-demi t-text-primary-color">{{ submittedAsset.promoHeader }}</div>
    </div>
    <div class="review-details-item t-flex t-items-baseline t-mb-12">
      <label class="t-font-futura-pt-demi t-text-sm t-font-thin t-text-right t-mr-4">{{ $t('manageReviewDetailContent.description') }}</label>
      <div class="asset-description">
        <ul class="t-p-0">
          <template v-for="(val, index) in submittedAsset.promoDescription.split('\n')">
            <li :key="index" v-if="val" class="t-text-base t-text-demi-gray t-pl-6 t-m-0 t-list-none">
              <span v-html="$options.filters.formattText(val)"></span>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="review-details-item t-flex t-items-baseline t-mb-12" v-if="submittedAsset.fullDescription">
      <label class="t-font-futura-pt-demi t-text-sm t-font-thin t-text-right t-mr-4">{{ $t('manageReviewDetailContent.howItWorks') }}</label>
      <div class="asset-how-it-works">
        <ul class="t-p-0">
          <template v-for="(val, index) in submittedAsset.fullDescription.split('\n')">
            <li v-if="val" :key="index" class="t-text-base t-text-demi-gray t-pl-6 t-m-0 t-list-none">
              <span v-html="$options.filters.formattText(val)"></span>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="review-details-item t-flex t-items-start t-mb-12" v-if="submittedAsset.galleryImages && submittedAsset.galleryImages.length > 0">
      <label class="t-font-futura-pt-demi t-text-sm t-font-thin t-text-right t-mr-4">{{ $t('manageReviewDetailContent.screenshots') }}</label>
      <div class="media-container">
        <CarouselDesktop :slidesShow="2" :responsive="false" class="media-carousel">
          <div v-for="(image, index) in submittedAsset.galleryImages" :key="index" class="image-container t-cursor-pointer t-px-4 t-pt-4" @click="mediaModalVisible(true, index)">
            <img :key="index" :src="image.signedUrl" alt="screenshots" class="t-h-60" />
            <div class="caption-container t-flex t-justify-between t-items-center t-h-12 t-text-sm">
              <p class="t-m-0 t-truncate" :title="image.caption">{{ image.caption }}</p>
              <div class="download-btn t-cursor-pointer t-text-2xl"><svg-icon icon-class="icon-download" class="g-icon--xsmall svg-container" @click="onImgDownload(image)" /></div>
            </div>
          </div>
        </CarouselDesktop>
      </div>
    </div>
    <div class="review-details-item t-flex t-items-baseline t-mb-12">
      <label class="t-font-futura-pt-demi t-text-sm t-font-thin t-text-right t-mr-4">{{ $t('manageReviewDetailContent.about') }}</label>
      <div class="about-container">
        <div class="asset-info-detail t-flex t-justify-between">
          <div class="asset-info-title t-text-base t-text-black t-font-bold">{{ $t('manageReviewDetailContent.assetType') }}</div>
          <div class="asset-info-value">
            <div class="t-text-base">{{ submittedAsset.inventoryType }}</div>
          </div>
        </div>
        <div class="divider t-mb-3"></div>
        <div class="asset-info-detail t-flex t-justify-between">
          <div class="asset-info-title t-text-base t-text-black t-font-bold">{{ $t('manageReviewDetailContent.requiredTech') }}</div>
          <div class="asset-info-value">
            <div class="t-flex t-items-center t-mb-2" v-for="application in submittedAsset.applicationsVM" :key="application.id">
              <img :src="application.image" class="t-mr-2 t-w-6 t-h-6" alt="app" />
              <p class="application-name t-text-base t-m-0">{{ application.name }}</p>
            </div>
          </div>
        </div>
        <div class="divider t-mb-3"></div>
        <div class="asset-info-detail t-flex t-justify-between">
          <div class="asset-info-title t-text-base t-text-black t-font-bold t-flex-1">{{ $t('manageReviewDetailContent.function') }}</div>
          <div class="asset-info-value t-flex-1 t-text-right t-leading-none">
            <span class="t-text-base t-m-0" v-if="isAllFunctions">{{ $t('manageReviewDetailContent.allFunctions') }}</span>
            <span class="t-text-base t-m-0" v-else v-for="(func, i) in submittedAsset.functions" :key="func"><i v-if="i > 0 && i != submittedAsset.functions.length">, </i>{{ func }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="review-details-item t-flex t-items-start t-mb-12" v-if="submittedAsset.supportingFile && submittedAsset.supportingFile.name">
      <label class="t-font-futura-pt-demi t-text-sm t-font-thin t-text-right t-mr-4">{{ $t('manageReviewDetailContent.reference') }}</label>
      <div class="reference-container t-flex t-items-center t-h-14 t-opacity-100 t-rounded t-px-6 t-ml-1 t-text-black t-text-base t-justify-between">
        <div class="t-flex t-items-center">
          <span class="pdf-icon t-mr-2 t-text-2xl"><svg-icon icon-class="icons-file-pdf"></svg-icon></span>
          <span class="t-flex t-items-center"
            ><a :href="submittedAsset.supportingFile.signedUrl" target="_blank" style="max-width: 29rem" class="t-no-underline t-inline-block t-text-base t-truncate" :title="submittedAsset.supportingFile.name">{{ submittedAsset.supportingFile.name }}</a></span
          >
        </div>
        <div class="t-flex t-items-center">
          <span class="file-size t-mr-2 t-text-sm">{{ formatSize(submittedAsset.supportingFile.size) }}</span>
          <span class="download-btn t-cursor-pointer t-text-2xl"><svg-icon icon-class="icon-download" @click="onDownload" /></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// we need to do this another way
import CarouselDesktop from '@/components/organisms/CarouselDesktop.vue';
import AssetMediaDialog from '@/views/manage-submissions/components/AssetMediaDialog.vue';
export default {
  name: 'ManageReviewDetailContent',
  props: {
    submittedAsset: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    functionsList: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },
  components: { AssetMediaDialog, CarouselDesktop },
  deactivated() {
    if (this.showDownloadProgress) {
      this.cancelDownload();
    }
  },
  data() {
    return {
      isMediaModalVisible: false,
      mediaModalIndex: 0,
      showDownloadProgress: false,
      downloadPercentage: 0,
      isAllFunctions: false
    };
  },
  async created() {
    this.functionsList.length == this.submittedAsset.functions.length ? (this.isAllFunctions = true) : (this.isAllFunctions = false);
  },
  filters: {
    formattText(inputText) {
      const pattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
      let text = inputText.replace(pattern1, '<a href="$1" class="t-text-demi-gray" target="_blank">$1</a>');
      const pattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
      text = text.replace(pattern2, '$1<a href="http://$2" class="t-text-demi-gray" target="_blank">$2</a>');
      return text;
    }
  },
  methods: {
    formatSize(size) {
      if (!size) return '0 bytes';
      else if (size < 1024) return `${size} bytes`;
      else if (size >= 1024 && size < Math.pow(1024, 2)) return `${parseInt(size / 1024).toLocaleString()} KB`;
      else if (size >= Math.pow(1024, 2) && size < Math.pow(1024, 3)) return `${parseInt(size / Math.pow(1024, 2)).toLocaleString()} MB`;
      else return `${parseInt(size / Math.pow(1024, 3)).toLocaleString()} GB`;
    },
    cancelDownload() {
      this.axiosController.abort();
      this.showDownloadProgress = false;
      this.downloadPercentage = 0;
      this.$emit('downloadInProgress', false);
    },
    onDownloadProgress(progressEvent) {
      const total = this.submittedAsset.assetReference ? this.submittedAsset.assetReference.size : 0;
      const loaded = progressEvent.loaded || 0;
      this.downloadPercentage = Math.floor((loaded * 100) / total);
    },
    async downloadAssetZipFile() {
      if (!this.showDownloadProgress) {
        const payload = {
          id: this.submittedAsset.id
        };
        this.showDownloadProgress = true;
        this.downloadPercentage = 0;
        this.axiosController = new AbortController();
        this.$emit('downloadInProgress', true);
        this.$planAdmin
          .downloadAssetZipFile(payload, this.axiosController, this.onDownloadProgress)
          .then((res) => {
            this.showDownloadProgress = false;
            this.saveAs(res.data, this.submittedAsset.assetReference.name);
            this.$emit('downloadInProgress', false);
          })
          .catch(() => {
            this.showDownloadProgress = false;
            this.downloadPercentage = 0;
            this.$emit('downloadInProgress', false);
          });
      }
    },
    onClickAssetLink() {
      this.submittedAsset.externalAssetLink.indexOf('http://') == 0 || this.submittedAsset.externalAssetLink.indexOf('https://') == 0 ? window.open(this.submittedAsset.externalAssetLink, '_blank') : window.open(`https://${this.submittedAsset.externalAssetLink}`, '_blank');
      const event = {
        name: 'Asset',
        category: 'Admin Asset Detail',
        payload: {
          action: 'Asset link clicked',
          asset: {
            id: this.submittedAsset.id,
            name: this.submittedAsset.name,
            author: this.submittedAsset.submitUser,
            applications: this.submittedAsset.applicationsVM,
            type: ''
          }
        }
      };
      this.GAEventTrack(event);
    },
    copyExternalAssetLink(event) {
      event.preventDefault();
      if (event.clipboardData) {
        event.clipboardData.setData('text/plain', this.submittedAsset.externalAssetLink);
      }
    },
    async onDownload() {
      const data = await fetch(this.submittedAsset.supportingFile?.signedUrl);
      const blobData = await data.blob();
      this.saveAs(blobData, this.submittedAsset.supportingFile?.name);
    },
    async onImgDownload(galleryImage) {
      const data = await fetch(galleryImage.signedUrl);
      const blobData = await data.blob();
      this.saveAs(blobData, galleryImage.caption);
    },
    saveAs(blob, fileName) {
      const url = window.URL.createObjectURL(blob);
      const anchorElem = document.createElement('a');
      anchorElem.style = 'display:none';
      anchorElem.href = this.$sanitize(url);
      anchorElem.download = this.$sanitize(fileName);
      anchorElem.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 1000);
    },
    GAEventTrack(event) {
      this.$gtag.event(event.name, {
        event_category: event.category,
        event_label: JSON.stringify(event.payload),
        value: JSON.stringify(event.payload)
      });
    },
    mediaModalVisible(visible, index) {
      this.isMediaModalVisible = visible;
      this.mediaModalIndex = index + 1;
    }
  }
};
</script>
<style lang="scss" scoped>
.main-container > .review-details-container {
  width: 66%;
  height: auto;

  .review-details-item {
    label {
      color: #4c5c8d;
      width: 14%;
    }
    .asset-header {
      width: 80%;
    }
    .asset-link,
    .reference-container {
      width: 80%;
      background: #efefef80 0% 0% no-repeat padding-box;
      border: 1px solid #e0e1e1;
      p {
        width: 90%;
      }
    }
    .about-container {
      width: 80%;
      .divider {
        border-bottom: 1px solid #e0e1e1;
      }
    }
    .media-container {
      width: 80%;
      .image-container {
        background: #efefef80;
        img {
          width: 100%;
        }
      }
    }
    .asset-description,
    .asset-how-it-works {
      width: 80%;
      ul li {
        background: url('~@/assets/images/icons/li-checkmark-16.png') no-repeat left top;
        background-position-y: 0.7rem;
      }
    }
  }
}
.custom-toast {
  width: 26.25rem;
  height: 8.5rem;
  box-shadow: 0px 5px 10px #0000003d;
  opacity: 1;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 100;
}
.toast-footer {
  height: 0.5rem;
  /* UI Properties */
  box-shadow: 0px 0.3125rem 0.625rem #0000003d;
  width: 100%;
  position: fixed;
  bottom: 0px;
  width: 26.25rem;
  background: #ffb92e 0% 0% no-repeat padding-box;
}
.status-message {
  margin-top: 2.25rem !important;
  margin-left: 7.5rem !important;
  text-align: center;
  font: normal normal normal 0.875rem /1.375rem Arial !important;
}
.custom-toast-loading {
  height: 8.5rem;
  background: #fff4df 0% 0% no-repeat padding-box;
}

.asset-file-name {
  width: 87%;
}

.download-cancel-btn {
  margin-left: 8.43rem !important;
}
.download-status {
  text-align: center;
  font: normal normal normal 14px/22px Arial !important;
}
.download-progress {
  height: 8.5rem;
}
</style>
