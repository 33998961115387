<template>
  <div class="help-container">
    <v-card>
      <img alt="" class="image" :src="require(`@/assets/images/help/help-center.gif`)" />
      <v-card-title v-if="isLoggedIn" class="card-title" style="margin-top: 10px">
        <h1 class="t-text-xl t-leading-8">{{ $t('helpCenter.welcomeTitle') }} {{ capitalize(name) }}!</h1>
      </v-card-title>
      <v-card-title v-else class="card-title" style="margin-top: 10px">{{ $t('helpCenter.welcomeMessage') }} </v-card-title>
      <v-card-text style="padding: 0 20px">
        <div class="text" style="padding: 5px 0px 40px 0px">
          {{ $t('helpCenter.weStriveToDeliver') }}
        </div>
      </v-card-text>
    </v-card>
    <v-card v-if="isLoggedIn">
      <v-card-title class="card-title">
        <h2 class="t-text-xl t-leading-8">{{ $t('helpCenter.FAQTitle') }}</h2>
        <span class="downloadable">
          <a href="https://static.proedge.pwc.com/docs/ProEdgePlanFAQ.pdf" target="_blank"> <img src="@/assets/images/svgs/capstone-download.svg" /> </a
        ></span>
      </v-card-title>
    </v-card>
    <v-card>
      <v-card-title class="card-title"
        ><h2 class="t-text-xl t-leading-8">{{ $t('helpCenter.contactSupport') }}</h2></v-card-title
      >
      <v-card-text v-if="!isSupportContacted" style="padding: 0 20px">
        <label for="pickTopicPrompt" class="text content-prompt">{{ $t('helpCenter.pickTopicPrompt') }} <span class="asterisk">*</span></label>
        <SelectView
          id="pickTopicPrompt"
          class="dropdown"
          v-model="selectedTopic"
          aria-required="true"
          :items="topics"
          item-title="name"
          item-value="value"
          return-object
          :placeholder="$t('helpCenter.selectTopicPlaceholder')"
          @update:modelValue="handleTopicSelect()"
          variant="outlined"
        ></SelectView>
        <label for="letUsKnowPrompt" class="text content-prompt" style="padding-top: 0">{{ $t('helpCenter.letUsKnowPrompt') }} <span class="asterisk">*</span></label>
        <v-textarea id="letUsKnowPrompt" v-model="ticket.description" aria-required="true" name="input-7-4" :placeholder="$t('helpCenter.enterDescPlaceholder')" variant="outlined"> </v-textarea>
        <div class="drop-zone dashed-border" @drop="handleDrop($event, 'upload-img-input', ticket.file)" @dragover="handleDragover" @dragenter="handleDragover">
          <div class="upload-info">{{ $t('helpCenter.dropOrBrowseFile') }}</div>
          <input ref="upload-img-input" class="upload-input" type="file" @change="handleClick($event, 'upload-img-input', ticket.file)" />
          <button class="browse-button" :disabled="isSending" @click="handleUpload('upload-img-input')">{{ $t('helpCenter.browse') }}</button>
          <div v-if="ticket.file.name" class="text-note">{{ ticket.file.name }}</div>
        </div>
        <div v-if="!isLoggedIn" class="text content-prompt">{{ $t('helpCenter.leaveEmailPrompt') }}</div>
        <v-text-field v-if="!isLoggedIn" v-model="ticket.email" outlined :placeholder="$t('helpCenter.enterDescPlaceholder')" v-bind:value="ticket.email"></v-text-field>
        <div v-if="notificationMessage.length" class="notification">{{ $t('helpCenter.error') }} {{ notificationMessage }}</div>
        <div v-if="isSending" class="loader">
          <v-card-text class="loader-text">{{ $t('helpCenter.sending') }}</v-card-text>
          <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
        </div>
        <div style="text-align: right; padding: 0 0 10px 40px">
          <v-btn color="#ffb92e" dark rounded class="black--text action-btn" style="min-width: 100px" :disabled="isSending" @click="onSend">{{ $t('helpCenter.send') }}</v-btn>
        </div>
      </v-card-text>
      <v-card-text v-else style="padding: 0 20px">
        <div class="confirmation text">
          <div>{{ $t('helpCenter.thankYou') }}</div>
          <div>{{ $t('helpCenter.emailWillBeSent') }}</div>
        </div>
        <div class="close-btn-wrapper">
          <v-btn color="#ffb92e" dark rounded class="black--text close-btn" style="min-width: 100px" @click="onClose">{{ $t('helpCenter.close') }}</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import SelectView from '@/components/molecules/SelectView';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'HelpCenter',
  components: { SelectView },
  data() {
    return {
      token: '',
      name: '',
      isLoggedIn: false,
      isSending: false,
      isSupportContacted: false,
      selectedTopic: null,
      topics: [
        { value: 'Issue', name: translate('helpCenter.topics.issue') },
        { value: 'Feedback', name: translate('helpCenter.topics.feedback') },
        { value: 'Question', name: translate('helpCenter.topics.question') }
      ],
      ticket: {
        product: 'PLN',
        topic: '',
        name: '',
        description: '',
        email: '',
        file: {
          name: '',
          file: null,
          allowedExtensions: ['.png', '.jpg', '.jpeg']
        },
        reference: ''
      },
      notificationMessage: ''
    };
  },
  computed: {
    ...mapGetters(['username'])
  },
  async mounted() {
    await this.setup();
  },
  methods: {
    capitalize(str) {
      return str[0].toUpperCase() + str.substr(1);
    },
    async setup() {
      this.name = await localforage.getItem('my_name');
      this.ticket.email = (await localforage.getItem('my_username')) || this.username;
      this.isLoggedIn = this.name && this.name.length;
    },
    handleTopicSelect() {
      this.ticket.topic = this.selectedTopic.value;
      this.ticket.name = this.selectedTopic.name;
    },
    validInputs() {
      if (!this.ticket.topic) {
        this.notificationMessage = translate('helpCenter.validation.selectTopic');
        return false;
      }
      if (!this.ticket.description.length) {
        this.notificationMessage = translate('helpCenter.validation.descNotEmpty');
        return false;
      }
      if (!this.ticket.email || !this.ticket.email.length) {
        this.notificationMessage = translate('helpCenter.validation.emailNotEmpty');
        return false;
      }
      // eslint-disable-next-line
      const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailPattern.test(this.ticket.email)) {
        this.notificationMessage = translate('helpCenter.validation.emailNotValid');
        return false;
      }
      if (this.ticket.file.name && !this.isValidFileType(this.ticket.file)) {
        this.notificationMessage = translate('helpCenter.validation.cannotReadScreenshot');
        return false;
      }
      return true;
    },
    handleDrop(e, ref, targetField) {
      e.stopPropagation();
      e.preventDefault();
      const files = e.dataTransfer.files;
      const rawFile = files[0];
      this.upload(rawFile, ref, targetField);
      e.stopPropagation();
      e.preventDefault();
    },
    async upload(rawFile, ref, targetField) {
      targetField.name = rawFile.name;
      targetField.file = rawFile;
      this.$refs[ref].value = null;
    },
    handleClick(e, ref, targetField) {
      const files = e.target.files;
      const rawFile = files[0];
      if (!rawFile) return;
      this.upload(rawFile, ref, targetField);
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleUpload(ref) {
      this.$refs[ref].click();
    },
    isValidFileType(file) {
      const filename = file.name.toLowerCase();
      return /\.(png)$/.test(filename) || /\.(jpg)$/.test(filename) || /\.(jpeg)$/.test(filename);
    },
    onClose() {
      this.clearInputs();
      this.$router.go(-1);
    },
    async onSend() {
      this.isSending = true;
      this.notificationMessage = '';
      if (!this.validInputs()) {
        this.isSending = false;
        return;
      }
      const data = new FormData();
      data.append('product', this.ticket.product);
      data.append('topic', this.ticket.topic);
      data.append('name', this.ticket.name);
      data.append('description', this.ticket.description);
      data.append('email', this.ticket.email);
      if (this.ticket.file.name) {
        data.append('file', this.ticket.file.file);
      }
      const response = await this.$planAdmin.submitHelpTicket({ form: data });
      this.isSending = false;
      if (response && response.code === 200) {
        this.ticket.reference = response.data;
        this.isSupportContacted = true;
      } else {
        this.notificationMessage = translate('helpCenter.submitTicketErrorMessage');
        return;
      }
    },
    clearInputs() {
      this.isSending = false;
      this.isSupportContacted = false;
      this.name = '';
      this.ticket = {
        product: 'PLN',
        topic: '',
        name: '',
        description: '',
        email: '',
        file: {
          name: '',
          file: null,
          allowedExtensions: ['.png', '.jpg', '.jpeg']
        },
        reference: ''
      };
      this.notificationMessage = '';
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.helpCenter')
    };
  }
};
</script>
<style lang="scss">
.help-container {
  .v-card-title {
    align-items: center !important;
    display: flex !important;
    flex-wrap: wrap;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    word-break: break-all !important;
  }
}
</style>

<style lang="scss" scoped>
.help-container {
  max-width: 90rem;
  padding: 0 3rem;
  margin: 6.5rem auto 0;
}

.v-card {
  margin: 1.5% 0%;
  border-radius: 10px;
  padding: 0.5rem 1rem;
}

.card-title {
  font-size: 30px;
  font-weight: bold;
  color: $color-primary !important;
}

.text {
  font-size: 16px;
}

.image {
  float: right;
  width: 250px;
  margin: 0 20px 0 30px;
}

.content-prompt {
  padding: 5px 0px;
}
.asterisk {
  color: red;
}
.dropdown {
  width: 50%;
}

.action-btn {
  text-transform: capitalize;
}

.notification {
  font-size: 16px;
  color: $color-data-2;
}

.upload-input {
  display: none;
  z-index: -9999;
}
.upload-info {
  font-size: 16px;
}
.upload-btn {
  margin: 12px;
}
.drop-zone {
  margin: 0% 20%;
  text-align: center;
  width: 60%;
  padding: 16px 0px;
  margin-bottom: 16px;
}

.dashed-border {
  border: 1px dashed #acacac;
  border-radius: 10px;
}

.text-note {
  color: $color-grey;
  font-size: 14px;
  margin-top: 10px;
}

.browse-button {
  width: 110px;
  height: 35px;
  border: 1px solid $color-primary;
  color: $color-white;
  font-weight: bold;
  background: $color-primary;
  border-radius: 20px;
  margin-top: 10px;
}

.confirmation {
  margin: 1rem 0;
  text-align: center;
  color: $color-black;
}

.close-btn-wrapper {
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
  .close-btn {
    text-transform: capitalize;
  }
}

.loader {
  margin: 10px 50px;
  text-align: center;

  .loader-text {
    font-size: 30px;
    color: $color-primary;
  }

  .loader-bar {
    position: relative;
    top: -30px;
  }
}

.downloadable {
  margin-left: 2rem;
  color: $color-primary !important;
  cursor: pointer;
}
</style>
