<template>
  <div>
    <div class="manage-people-search-filter-container">
      <div class="manage-people-search-filter" v-for="(filter, i) in filterValues.slice(0, 5)" :key="filter.name" :style="filter.showExpandedFilter ? 'zIndex:1' : 'zIndex:2'">
        <div class="backdrop" v-if="filter.showExpandedFilter" @click="onBackdrop(i)"></div>
        <div class="filter">
          <div
            :style="filter.disabled || filter.values.length == 0 ? 'pointer-events: none' : ''"
            :class="filter.showExpandedFilter ? 't-border-black' : ''"
            class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black"
            @click="openDropdown(filter, i)"
          >
            <span class="t-font-arial t-text-sm" :style="filter.values.length == 0 || filter.disabled ? 'color:rgb(0, 0, 0, 0.2)' : ''">{{ sentenceCase($t(filter.translationKey)) }}</span>
            <v-icon :style="filter.values.length == 0 || filter.disabled ? 'opacity:0.3' : ''">mdi-chevron-down</v-icon>
          </div>
        </div>
        <div class="filter-dropdown" v-if="filter.showExpandedFilter && (filter.values.length <= 5 || filter.name == 'Status')">
          <ul class="list-with-no-filter">
            <li v-for="(item, index) in filter.values" :key="index" style="max-height: 30px; display: flex; align-items: center" class="catalog-list-item">
              <v-checkbox
                :key="item.name"
                :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable"
                :label="filter.name == 'Status' ? getLocalizedEnrollmentStatus(item.name) : item.name"
                dense
                :value="item.name"
                v-model="selectedFilters[i].values"
                style="display: inline-block"
              />
            </li>
          </ul>
          <div class="filter-option-footer">
            <div class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</div>
            <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
          </div>
        </div>
        <div class="filter-dropdown" v-if="filter.showExpandedFilter && filter.values.length > 5 && filter.name != 'Status'">
          <v-text-field
            class="manage-people-text-input"
            prepend-inner-icon="mdi-magnify"
            :label="$t('common.filter.searchPrompt') + $t(filter.translationKey).toLocaleLowerCase()"
            v-model="queryFilter"
            @keyup="filterOptions($event, i)"
            rounded
            clearable
            single-line
            hide-details
            filled
            dense
            solo
            variant="solo"
          >
          </v-text-field>

          <div class="t-text-center" v-if="filteredOptions.length == 0">{{ $t('common.noMatchesFound') }}</div>
          <ul :class="filteredOptions.length > 5 ? 'scrollable' : ''">
            <li v-for="(item, index) in filteredOptions" :key="index" style="max-height: 1.875rem; display: flex; align-items: center" class="catalog-list-item">
              <v-checkbox :key="item.name" :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable" :label="item.name" dense :value="item.name" v-model="selectedFilters[i].values" style="display: inline-block" />
            </li>
          </ul>
          <div class="filter-option-footer">
            <div class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</div>
            <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- KEEPING FOR TESTING PURPOSES  -->
    <!-- 
    <div class="manage-people-search-filter-container" v-if="showFilters">
      <div>
        <span v-for="(item, index) in displaySelectedFilters" :key="index" id="chips-container">
          <v-chip in class="chip t-text-xs ma-2 t-bg-black" close @click:close="removeFilter(item)"> {{ item.name }} </v-chip>
        </span>
        <span class="clear-all-filters" @click="clearFilters()">{{ $t('common.clearAllFilters') }}</span>
      </div>
    </div> 
    -->
  </div>
</template>

<script>
import { mapDurations, numberWithCommas, sentenceCase, getLocalizedEnrollmentStatus } from '@/utils';

export default {
  name: 'ManagePeopleFilters',
  emits: ['filter-applied', 'interface'],
  props: {
    filterResults: {
      type: Object,
      require: true,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      mapDurations,
      queryFilter: '',
      filteredOptions: [],
      showExpandedFilter: false,
      showFilters: false,
      values: [],
      displaySelectedFilters: [],
      selectedFilters: [
        { name: 'Job Title', key: 'job_title', values: [] },
        { name: 'Role', key: 'skills', values: [] },
        { name: 'License', key: 'publishers', values: [] },
        { name: 'Status', key: 'durations', values: [] }
      ],
      filterValues: [
        {
          name: 'Job Title',
          translationKey: 'common.filter.jobTitle',
          values: [],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false
        },
        { name: 'Role', translationKey: 'common.filter.role', values: [], showExpandedFilter: false, showAccordion: false, disabled: false },
        { name: 'License', translationKey: 'common.filter.license', values: [], showExpandedFilter: false, showAccordion: false, disabled: false },
        {
          name: 'Status',
          translationKey: 'common.filter.status',
          values: [],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false
        }
      ]
    };
  },
  mounted() {
    this.setFilterValues();
    this.emitInterface();
  },
  watch: {
    filterResults() {
      this.setFilterValues();
    },
    'displaySelectedFilters.length': function () {
      this.$emit('filter-applied', this.selectedFilters, this.displaySelectedFilters);
    }
  },
  methods: {
    sentenceCase,
    numberWithCommas,
    getLocalizedEnrollmentStatus,
    onCancel(index) {
      this.filterValues[index].showExpandedFilter = !this.filterValues[index].showExpandedFilter;
      this.resetDropDownValues(index);
    },
    onShow(index, toggle = true) {
      this.applyFilters(index, toggle);
    },
    onBackdrop(index) {
      this.queryFilter = '';
      this.filterValues[index].showExpandedFilter = false;
      this.resetDropDownValues(index);
    },
    resetDropDownValues(index) {
      /****
       * remove values from filter if clicked outside, but keep the ones already applied from displaySelected array
       */
      if (!this.displaySelectedFilters.length) {
        this.selectedFilters[index].values = [];
      } else {
        const doesKeyExistSelectedFilters = this.displaySelectedFilters.some((item) => {
          return item.key === this.selectedFilters[index].key;
        });
        if (doesKeyExistSelectedFilters) {
          const selectedArrayItems = [];
          this.displaySelectedFilters.filter((item) => {
            if (this.selectedFilters[index].key === item.key) {
              if (!this.selectedFilters[index].values.includes(item.name)) {
                this.selectedFilters[index].values.push(item.name);
                selectedArrayItems.push(item.name);
              } else {
                selectedArrayItems.push(item.name);
              }
            }
          });
          this.selectedFilters[index].values = selectedArrayItems;
        } else {
          this.selectedFilters[index].values = [];
        }
      }
    },
    async openDropdown(filterItem, i) {
      this.filteredOptions = this.filterValues[i].values;
      this.filterValues.map((filter, index) => {
        // if previously any filter was open, then reset its value
        if (filter.showExpandedFilter) {
          this.resetDropDownValues(index);
        }
        if (filterItem.name === filter.name) {
          filter.showExpandedFilter = true;
        } else {
          filter.showExpandedFilter = false;
        }
      });
    },
    filterOptions(event, i) {
      if (this.queryFilter.length == 0) this.filteredOptions = this.filterValues[i].values;
      else
        this.filteredOptions = this.filterValues[i].values.filter((ele) => {
          return ele.name.toLowerCase().includes(event.target.value.toLowerCase());
        });
    },
    applyFilters(i, toggle = true) {
      if (i >= 0) {
        if (toggle) {
          this.filterValues[i].showExpandedFilter = !this.filterValues[i].showExpandedFilter;
        }

        /****
         * add value to displaySelectedFilter if new value has been checked from filter dropdown
         */
        let key = '';
        this.selectedFilters.map(async (item) => {
          if (item.name === this.filterValues[i].name) {
            key = item.key;
            item.values.forEach((filterItem) => {
              const obj = {};
              obj.key = key;
              obj.type = item.name;
              obj.name = filterItem;
              if (!this.displaySelectedFilters.some((selectedItem) => selectedItem.key == obj.key && selectedItem.type === obj.type && selectedItem.name === obj.name)) {
                this.displaySelectedFilters.push(obj);
              }
            });
            /***
             * find item which has been deselected from dropdown and remove it from displaySelectedFilter
             */
            const itemToBeRemoved = await this.displaySelectedFilters.filter((changeItem) => {
              if (changeItem.key === key) {
                if (!item.values.includes(changeItem.name)) {
                  return changeItem;
                }
              }
            });
            if (itemToBeRemoved.length) {
              this.displaySelectedFilters = this.displaySelectedFilters.filter(function (itemInDisplay) {
                return !itemToBeRemoved.find(function (removeItem) {
                  return itemInDisplay.name === removeItem.name && itemInDisplay.key === removeItem.key;
                });
              });
            }
            /****
             * clickstream update
             */
            const appliedFilters = {
              filters: item.values,
              count: item.values.length
            };
            analytics.setVarAndTrack({ c: item.name + ' filter', a: 'Apply filter', l: appliedFilters });
          }
        });
      }
      this.showFilters = this.selectedFilters.some((item) => {
        return item.values.length;
      });
    },
    setFilterValues() {
      this.filterValues.forEach((ele) => {
        if (ele.name == 'Job Title') {
          const index = this.filterValues.findIndex((x) => x.name === 'Job Title');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'jobTitleFilterValues') ? this.filterResults.jobTitleFilterValues : [];
        }
        if (ele.name == 'Role') {
          const index = this.filterValues.findIndex((x) => x.name === 'Role');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'roleFilterValues') ? this.filterResults.roleFilterValues : [];
        }
        if (ele.name == 'License') {
          const index = this.filterValues.findIndex((x) => x.name === 'License');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'licenseFilterValues') ? this.filterResults.licenseFilterValues : [];
        }
        if (ele.name == 'Status') {
          const index = this.filterValues.findIndex((x) => x.name === 'Status');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'statusFilterValues') ? this.filterResults.statusFilterValues : [];
        }
      });
    },
    removeFilter(item) {
      this.selectedFilters.forEach((filterItem) => {
        if (filterItem.name == item.type) {
          const index = filterItem.values.indexOf(item.name);
          filterItem.values.splice(index, 1);
        }
      });
      if (this.displaySelectedFilters.includes(item)) {
        const index = this.displaySelectedFilters.findIndex((selectedFilter) => selectedFilter.name === item.name && selectedFilter.type === item.type);
        /**
         * clickstream update
         */
        analytics.setVarAndTrack({ c: 'Clear single filter', a: 'click', l: item.name });
        this.displaySelectedFilters.splice(index, 1);
      }
      this.applyFilters();
    },
    clearFilters() {
      if (this.showFilters) {
        this.selectedFilters.forEach((item) => {
          item.values = [];
          this.showFilters = false;
        });
      }
      /**
       * clickstream update
       */
      const tempArry = [];
      this.displaySelectedFilters.forEach((item) => {
        tempArry.push(item.name);
      });
      analytics.setVarAndTrack({ c: 'Clear all filters', a: 'click', l: { clearedValues: tempArry } });
      this.displaySelectedFilters = this.displaySelectedFilters.length ? [] : this.displaySelectedFilters;
      // this.$emit('filter-applied', this.selectedFilters, this.displaySelectedFilters)
    },

    emitInterface() {
      this.$emit('interface', {
        clearFilters: () => this.clearFilters(),
        removeFilter: (item) => this.removeFilter(item)
      });
    }
  }
};
</script>

<style lang="scss">
#chips-container .v-chip .v-chip__content {
  color: white;
}

#chips-container .v-chip .v-chip__close {
  color: white;
}

.filter {
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    border: 2px solid black;
    background: none;
    margin-bottom: 0;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
  }
}

.filter-dropdown {
  .catalog-list-item {
    label {
      opacity: 1;
    }
  }
  .v-input--selection-controls .v-input__slot > .v-label,
  .v-input--selection-controls .v-radio > .v-label {
    font-size: 14px;
  }
  .v-input--selection-controls .v-input__slot > .v-label {
    color: $color-black;
  }
  .v-input--selection-controls .v-input__slot > .v-label.v-label--is-disabled {
    color: rgba(0, 0, 0, 0.2);
  }

  .v-text-field {
    .v-input__slot {
      border: 2px solid #c4c4c4;

      .v-label {
        color: #5c5c5c;
        font-weight: normal;
        font-family: Arial;
        font-size: 0.875rem;
        padding-left: 1rem;
        top: 0.5625rem;
      }
      input[type='text'] {
        padding-left: 1rem;
      }
    }
  }
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none;
  }
}

.filter-dropdown .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: transparent;
  transform: none;
  transition: none;
}
.filter-dropdown .v-input--selection-controls__input .v-ripple__container > span {
  opacity: 0 !important;
}
.filter-dropdown .mdi-checkbox-blank-outline {
  color: #bcc2d4;
  font-size: 22px !important;
}
.filter-dropdown .mdi-checkbox-blank-outline.theme--light.v-icon:focus:after {
  opacity: 0 !important;
}
.filter-dropdown .v-input--is-disabled .v-input--selection-controls__input {
  opacity: 0.3;
}
.filter-option-footer-btn-apply {
  span {
    font-family: 'Futura PT Demi';
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}
.manage-people-search-filter-container {
  .manage-people-text-input {
    padding: 1rem 1rem;

    .v-label {
      font-weight: normal;
      font-family: Arial;
      font-size: 0.875rem;
      color: black;
    }

    .v-field--variant-solo {
      background-color: white !important;
      height: 2.3125rem;
      border: 0.125rem solid #c4c4c4;

      input[type='text'] {
        padding-left: 1rem;
      }
    }

    .v-field__field,
    .v-field__prepend-inner {
      height: 2.3125rem;
    }

    .v-field__input {
      min-height: unset;
      padding: 0rem;
      height: inherit;
      height: 2.3125rem;
      input {
        margin: 0px;
      }
    }
  }

  .list-with-no-filter {
    padding-top: 2rem !important;
  }
  .filter-dropdown .v-input__control {
    padding: unset;
  }
}
</style>
<style lang="scss" scoped>
.manage-people-search-filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  position: relative;

  .scrollable {
    padding-right: 30px;
    max-height: 200px;
    overflow-y: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #d8d8d8;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #4c5c8d;
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #4c5c8d;
    }
  }

  .manage-people-search-filter {
    &:nth-of-type(1) {
      .filter .filter-pill {
        width: 12.5rem;
      }
    }
    &:nth-of-type(2) {
      .filter .filter-pill {
        width: 130px;
      }
    }
    &:nth-of-type(3) {
      .filter .filter-pill {
        width: 160px;
      }
    }
    &:nth-of-type(4) {
      .filter .filter-pill {
        width: 160px;
      }
    }
    .filter-pill {
      display: flex;
      align-items: center;
      border: 2px solid rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      font-family: Arial;
      font-weight: 700;
      font-size: 14px;
      color: #000;
      padding: 5px 15px;
      width: 10rem;
      justify-content: space-between;
    }
    .filter-pill:hover {
      border: 2px solid rgb(0, 0, 0);
    }

    // .mdi-chevron-down::before {
    //   color: $color-black;
    // }

    .filter-dropdown {
      width: max-content;
      background-color: $color-skill-table !important;
      border-radius: 20px;
      box-shadow: 2px 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 5px 5px 0 rgb(0 0 0 / 12%);
      position: absolute;
      z-index: 999 !important;
    }

    .filter-dropdown ul {
      padding: 1rem;
      list-style: none;
    }

    .filter-dropdown ul.scrollable {
      margin-top: 0.625rem;
      margin-right: 1.25rem;
    }
    .backdrop {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0px;
      left: 0px;
      background-color: none;
      z-index: 1;
      margin-top: 5rem;
    }
    .filter-option-footer {
      display: flex;
      gap: 3rem;
      padding: 0.825rem 2rem 0.875rem 2rem;
      align-items: center;
      border-top: 0.0625rem solid #e0e1e1;
    }
    .filter-option-footer-cancel {
      font-family: 'Futura PT Demi';
      font-size: 1rem;
      color: #1f3370;
      cursor: pointer;
    }
    .filter-option-footer-btn-apply {
      cursor: pointer;
      text-transform: none;
      width: 5.75rem;
      box-shadow: none;
    }
  }
}
</style>
