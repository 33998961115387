<template>
  <v-navigation-drawer class="filter-navigation-drawer" v-model="allFiltersDrawer" temporary location="right">
    <div class="all-filter-container" ref="parent" @keydown.esc="toggleSideMenu">
      <div class="all-filter-heading t-mb-8">
        <button @click="toggleSideMenu" class="drawer-close t-ml-1" aria-label="close drawer menu">
          <svg-icon icon-class="filters-close-icon" class-name="close-button-icon" />
        </button>
        <span class="t-text-2xl t-ml-4 t-font-futura-pt-demi t-font-normal">{{ $tc('common.titleCase.filter', 2) }}</span>
      </div>
      <div class="all-filters-container">
        <v-row v-for="(filter, i) in filterValues" :key="filter.name" class="filter-cols">
          <v-col class="t-text-base t-font-arial">
            <div
              tabindex="0"
              class="t-cursor-pointer selected-filters-text"
              :style="filter.disabled || filter.values.length == 0 ? 'pointer-events: none' : ''"
              :class="filter.showAccordion ? 'active' : 'inactive'"
              @click="openAccordion(filter, i)"
              @keydown.prevent.space="openAccordion(filter, i)"
              @keydown.prevent.enter="openAccordion(filter, i)"
            >
              <div class="t-inline-block">
                <v-icon color="black" class="t-text-3xl" :style="{ transform: filter.showAccordion ? 'none' : 'rotate(-90deg)', color: filter.disabled || filter.values.length === 0 ? 'rgb(0, 0, 0, 0.2)!important' : '' }">mdi-chevron-down</v-icon>
                <span class="t-w-24 t-font-normal t-text-base" :style="filter.disabled || filter.values.length === 0 ? 'color:rgb(0, 0, 0, 0.2)!important' : ''">{{ sentenceCase($t(filter.translationKey)) }}</span>
              </div>
              <div class="t-inline-block t-ml-3">
                <span class="t-font-normal t-font-arial t-text-xs all-filter-selected-values" v-if="!filter.showAccordion">{{ getSelectedFilterValues(filter, i) }}</span>
              </div>
            </div>

            <div class="filter-dropdown" v-if="filter.showAccordion && (filter.values.length <= 5 || filter.name == 'Duration')">
              <ul>
                <li v-for="(item, index) in filter.values" :key="index" style="max-height: 2.25rem; display: flex; align-items: center" class="catalog-list-item">
                  <v-checkbox
                    v-if="filter.name == 'Provider' || filter.name == 'Assigned as' || filter.name == 'Skill' || filter.name == 'Accreditation' || filter.name == 'Technology' || filter.name == 'Language' || filter.name == 'Content feature'"
                    :key="item.name"
                    :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable"
                    :label="filter.name == 'Language' ? item.display : item.name"
                    dense
                    :value="item.name"
                    v-model="selectedFilters[i].values"
                    style="display: inline-block"
                  />
                  <v-checkbox
                    v-if="!(filter.name == 'Provider' || filter.name == 'Assigned as' || filter.name == 'Skill' || filter.name == 'Accreditation' || filter.name == 'Technology' || filter.name == 'Language' || filter.name == 'Content feature')"
                    :key="item.name"
                    :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable"
                    :label="$t(item.translationKey)"
                    dense
                    :value="item.name"
                    v-model="selectedFilters[i].values"
                    style="display: inline-block"
                  />
                  <span class="filter-value-count" :class="item.count > 0 ? '' : 'disabled-count'">({{ numberWithCommas(item.count) }})</span>
                </li>
              </ul>
              <div class="filter-option-footer">
                <button class="filter-option-footer-clear t-font-futura-pt-demi t-text-base t-cursor-pointer" @click="onClear(i)">{{ $t('common.clearFilters') }}</button>
                <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('common.titleCase.apply') }} {{ $t(filter.translationKey).toLocaleLowerCase() }}</v-btn>
              </div>
            </div>

            <div class="filter-dropdown" v-if="!filter.filterType && filter.showAccordion && filter.values.length > 5 && filter.name != 'Duration'">
              <v-text-field
                rounded
                prepend-inner-icon="mdi-magnify"
                :label="$t('common.filter.searchPrompt') + $t(filter.translationKey).toLocaleLowerCase()"
                v-model="queryFilter"
                @keyup="filterOptions($event, i)"
                clearable
                single-line
                hide-details
                filled
                dense
                solo
                variant="solo"
              ></v-text-field>

              <div v-if="filteredOptions.length == 0">{{ $t('common.noMatchesFound') }}</div>
              <ul :class="filteredOptions.length > 5 ? 'scrollable' : ''">
                <li v-for="(item, index) in filteredOptions" :key="index" style="max-height: 2.25rem; display: flex; align-items: center" class="catalog-list-item">
                  <v-checkbox
                    v-if="filter.name == 'Provider' || filter.name == 'Skill' || filter.name == 'Accreditation' || filter.name == 'Technology' || filter.name == 'Language' || filter.name == 'Content feature'"
                    :key="item.name"
                    :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable"
                    :label="filter.name == 'Language' ? item.display : item.name"
                    dense
                    :value="item.name"
                    v-model="selectedFilters[i].values"
                    style="display: inline-block"
                  />
                  <v-checkbox
                    v-if="!(filter.name == 'Provider' || filter.name == 'Skill' || filter.name == 'Accreditation' || filter.name == 'Technology' || filter.name == 'Language' || filter.name == 'Content feature')"
                    :key="item.name"
                    :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable"
                    :label="$t(item.translationKey)"
                    dense
                    :value="item.name"
                    v-model="selectedFilters[i].values"
                    style="display: inline-block"
                  />
                  <span class="filter-value-count" :class="item.count > 0 ? '' : 'disabled-count'">({{ numberWithCommas(item.count) }})</span>
                </li>
              </ul>
              <div class="filter-option-footer">
                <div class="filter-option-footer-clear t-font-futura-pt-demi t-text-base t-cursor-pointer" @click="onClear(i)">{{ $t('common.clearFilters') }}</div>
                <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('common.titleCase.apply') }} {{ $t(filter.translationKey).toLocaleLowerCase() }}</v-btn>
              </div>
            </div>

            <div class="filter-dropdown" v-if="filter.filterType === 2 && filter.showAccordion && filter.values.length > 5 && filter.name != 'Duration' && filter.name != 'Level' && filter.name != 'Content Type'">
              <v-text-field
                rounded
                prepend-inner-icon="mdi-magnify"
                :label="$t('common.filter.searchPrompt') + $t(filter.translationKey).toLocaleLowerCase()"
                v-model="queryFilter"
                @keyup="filterOptions($event, i)"
                clearable
                single-line
                hide-details
                filled
                dense
                solo
                variant="solo"
              ></v-text-field>
              <div class="t-text-center" v-if="filteredOptions.length == 0">{{ $t('catalog.noMatchesFound') }}</div>

              <ul :class="filteredOptions.length > 5 ? 'scrollable' : ''">
                <li v-for="(item, index) in filteredOptions" :key="index" style="max-height: 1.875rem; display: flex; align-items: center" class="catalog-list-item">
                  <div class="checkbox-container">
                    <!-- we may need this for the future - delete on 06/01/23  -->
                    <!-- <i v-if="!selectedFilters[i].values.includes(item.name)" aria-hidden="true" class="v-icon notranslate v-icon--dense mdi mdi-checkbox-blank-outline theme--light"></i> -->
                    <!-- <i v-else aria-hidden="true" class="v-icon notranslate v-icon--dense mdi mdi-checkbox-marked theme--light primary--text"></i> -->
                    <!-- we may need this for the future - delete on 06/01/23  -->
                    <input class="mdi-checkbox-blank-outline" type="checkbox" :id="`input-${index}`" :value="item.name" v-model="selectedFilters[i].values" :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable" />
                    <label :for="`input-${index}`" :value="item.name">{{ item.name }}</label>
                    <span :class="item.count > 0 ? '' : 'disabled-count'" :disabled="{}.hasOwnProperty.call(item, 'disable')" style="margin-left: 0.375rem; font-weight: normal; font-size: 0.875rem">({{ numberWithCommas(item.count) }})</span>
                  </div>
                </li>
              </ul>
              <div class="filter-option-footer">
                <div class="filter-option-footer-clear t-font-futura-pt-demi t-text-base t-cursor-pointer" @click="onClear(i)">{{ $t('common.clearFilters') }}</div>
                <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('common.titleCase.apply') }} {{ filter.name.toLocaleLowerCase() }}</v-btn>
              </div>
            </div>
            <div class="all-filters-border"></div>
          </v-col>
        </v-row>
      </div>
      <div class="t-mt-4">
        <button class="filter-option-footer-all-clear t-font-futura-pt-demi t-text-base t-cursor-pointer t-text-primary-color" @click="clearAllFilters()">{{ $t('common.clearAllFilters') }}</button>
        <v-btn color="#feb92e" class="t-ml-4 t-rounded-full filter-option-footer-btn-apply" @click="viewResults()">{{ $tc('common.titleCase.view') }} {{ numberWithCommas(this.totalResults) }} {{ $tc('common.result', 2) }}</v-btn>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script>
import { sentenceCase, numberWithCommas, getLocalizedContentType, getLocalizedLevel, getLocalizedDuration, inverseMapDurations } from '@/utils';
import en_json from '@/locales/en-us.json';

export default {
  name: 'AllFiltersSideBar',
  emits: ['toggle-side-menu-overlay', 'all-filters-toggle-side-menu', 'clear-side-filter'],
  props: {
    isFilterSideMenuActive: {
      type: Boolean,
      required: true,
      default: () => {
        return false;
      }
    },
    filterValues: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    selectedFilters: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    displaySelectedFilters: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    totalResults: {
      type: Number,
      required: true,
      default: () => {
        return 0;
      }
    }
  },
  data() {
    return {
      filteredOptions: [],
      queryFilter: ''
    };
  },
  computed: {
    allFiltersDrawer: {
      get() {
        return this.$props.isFilterSideMenuActive;
      },
      set(val) {
        this.$emit('toggle-side-menu-overlay', val);
        if (!val) {
          this.filterValues.map((filter, index) => {
            if (filter.showAccordion) {
              this.resetDropDownValues(index);
            }
            filter.showAccordion = false;
          });
        }
      }
    }
  },
  methods: {
    sentenceCase,
    numberWithCommas,
    getSelectedFilterValues(filter, i) {
      if (filter.name === 'Language') {
        return this.selectedFilters[i].values.map((value) => (en_json.languagePicker && en_json.languagePicker[value] ? en_json.languagePicker[value] : value)).join(', ');
      } else {
        if (['content_types', 'durations', 'levels'].includes(this.selectedFilters[i].key) && this.selectedFilters[i].values.length > 0) {
          let returnString = '';
          for (let stringVal of this.selectedFilters[i].values)
            switch (this.selectedFilters[i].key) {
              case 'durations':
                returnString += getLocalizedDuration(inverseMapDurations[stringVal]) + ',';
                break;
              case 'levels':
                returnString += getLocalizedLevel(stringVal) + ',';
                break;
              case 'content_types':
                returnString += getLocalizedContentType(stringVal) + ',';
                break;
              default:
                break;
            }
          //remove the extra comma
          return returnString.substring(0, returnString.length - 1);
        } else {
          return this.selectedFilters[i].values.join(', ');
        }
      }
    },
    viewResults() {
      this.filterValues.map((filter, index) => {
        if (filter.showAccordion) {
          this.resetDropDownValues(index);
        }
      });
      this.toggleSideMenu();
    },
    clearAllFilters() {
      this.toggleSideMenu();
      this.EventBus.emit('all-filters-cleared');
    },
    onClear(index) {
      this.selectedFilters[index].values = [];
      this.$emit('clear-side-filter', index);
      this.filterValues[index].showAccordion = false;
    },
    onShow(i) {
      this.filterValues[i].showAccordion = false;
      this.EventBus.emit('show-filters', i, false);
    },
    openAccordion(filterItem, index) {
      this.filteredOptions = this.filterValues[index].values;
      this.queryFilter = '';
      this.filterValues.map((filter, i) => {
        if (filter.showAccordion) {
          this.resetDropDownValues(i);
        }
        if (filterItem.name === filter.name) {
          filter.showAccordion = filter.showAccordion ? false : true;
        } else {
          filter.showAccordion = false;
        }
      });
    },
    toggleSideMenu() {
      this.filterValues.map((filter) => {
        filter.showAccordion = false;
      });
      this.$emit('all-filters-toggle-side-menu');
    },
    filterOptions(event, index, name) {
      if (this.queryFilter.length == 0) this.filteredOptions = this.filterValues[index].values;
      else
        this.filteredOptions = this.filterValues[index].values.filter((ele) => {
          return name == 'Language' ? ele.display.toLowerCase().includes(event.target.value.toLowerCase()) : ele.name.toLowerCase().includes(event.target.value.toLowerCase());
        });
    },
    resetDropDownValues(index) {
      /****
       * remove values from filter if clicked outside, but keep the ones already applied from displaySelected array
       */
      if (!this.displaySelectedFilters.length) {
        this.selectedFilters[index].values = [];
      } else {
        const doesKeyExistSelectedFilters = this.displaySelectedFilters.some((item) => {
          return item.key === this.selectedFilters[index].key;
        });
        if (doesKeyExistSelectedFilters) {
          const selectedArrayItems = [];
          this.displaySelectedFilters.filter((item) => {
            if (this.selectedFilters[index].key === item.key) {
              if (!this.selectedFilters[index].values.includes(item.name)) {
                this.selectedFilters[index].values.push(item.name);
                selectedArrayItems.push(item.name);
              } else {
                selectedArrayItems.push(item.name);
              }
            }
          });
          this.selectedFilters[index].values = selectedArrayItems;
        } else {
          this.selectedFilters[index].values = [];
        }
      }
    }
  },
  watch: {
    isFilterSideMenuActive(newValue) {
      if (!newValue) return;
      this.$nextTick(() => {
        const focusable = this.$refs?.parent?.querySelector('[tabindex]');
        focusable?.focus({ focusVisible: true });
      });
    }
  }
};
</script>
<style lang="scss">
.filter-navigation-drawer {
  .v-navigation-drawer__content {
    .filter-dropdown {
      .v-input__control {
        padding: 0rem !important;
      }
    }

    // .scrollable {
    // margin-top: 1rem;
    // margin-right: 1.25rem;
    // }
  }
}
</style>
<style lang="scss" scoped>
.filter-navigation-drawer {
  width: 27.4375rem !important;
  background: #efefef 0% 0% no-repeat padding-box;
  border: 0.0625rem solid #707070;
  top: 3rem !important;
  position: fixed;
  transition: all 0.5s ease !important;
}

.scrollable {
  padding-right: 0;
  max-height: 15.75rem;
  max-width: 21.75rem;
  overflow-y: scroll;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #d8d8d8;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4c5c8d;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #4c5c8d;
  }
}

.all-filter-container {
  margin-left: 3.8125rem;
  margin-top: 6.625rem;

  .filter-dropdown {
    margin-top: 1rem;

    ul {
      padding-left: 0.125rem;
      padding-top: 1rem;
    }
  }
}
.all-filters-border {
  width: 16.25rem;
  margin-top: 0.875rem;
  margin-left: 0.625rem;
  border-bottom: 0.0625rem solid #e0e1e1;
}
.all-filter-selected-values {
  color: #626262;
}
.all-filters-container {
  height: 33.625rem;
  overflow-y: scroll;
  overflow-x: hidden;

  .filter-value-count {
    margin-left: 6px;
    margin-top: -1.5rem;
    font-weight: normal;
    font-size: 14px;
  }
  .disabled-count {
    color: rgba(0, 0, 0, 0.38);
  }
}

.filter-option-footer {
  margin-top: 1rem;
  margin-left: 0.175rem;
  max-width: 21.75rem;
}
.filter-option-footer-clear,
.filter-option-footer-all-clear {
  margin-right: 1rem;
  display: inline-block;
}
.filter-dropdown .v-text-field {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 20rem;
}
.selected-filters-text {
  width: 350px;
  overflow: hidden;
  white-space: nowrap;
}
.filter-dropdown {
  .catalog-list-item {
    label {
      opacity: 1;
    }
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.4375rem;

    span,
    label {
      margin-top: 0.25rem;
      font-size: 0.875rem;
    }

    input[type='checkbox'] {
      margin: 0rem 0.625rem 0rem 0rem;
      position: relative;
      width: 1rem;
      height: 1rem;
      color: black;
      border: 0.125rem solid #bcc2d4;
      border-radius: 0.125rem;
      appearance: none;
      outline: 0;
      cursor: pointer;
      transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

      &::before {
        position: absolute;
        content: '';
        display: none;
        left: 0.25rem;
        width: 0.3125rem;
        height: 0.6875rem;
        border-style: solid;
        border-color: white;
        border-width: 0 0.125rem 0.125rem 0;
        transform: rotate(45deg);
      }

      &:checked {
        color: #fff;
        border-color: #1f3370;
        background: #1f3370;

        &::before {
          display: block;
        }
      }
    }
  }
}
</style>
