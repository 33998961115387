<template>
  <div class="admin-planner">
    <h2>{{ $t('pageTitles.adminPlanner') }}</h2>
    <div class="tab-container">
      <router-link v-for="(tab, index) in tabs" :key="index" :class="selectedTab === index ? 'selected' : ''" :to="tab.path" @click.once="onTabClick(index)">{{ tab.name }}</router-link>
    </div>
    <router-view>
      <div v-if="$route.path.includes('/admin-planner/add-access')">
        <AddAccess />
      </div>
      <div v-if="$route.path.includes('/admin-planner/add-permissions')">
        <AddPermissions />
      </div>
    </router-view>
  </div>
</template>

<script>
import { translate } from '@/plugins/i18n.js';
import { ref } from 'vue';
import AddAccess from './components/AddAccess';
import AddPermissions from './components/AddPermissions';

export default {
  setup() {
    const tabs = ref([
      { name: translate('admin_planner.tabs.addAccess'), path: '/admin-planner/add-access' },
      { name: translate('admin_planner.tabs.AddPermission'), path: '/admin-planner/add-permissions' }
    ]);
    const selectedTab = ref(0);
    return {
      tabs,
      selectedTab
    };
  },
  components: { AddAccess, AddPermissions },
  mounted() {
    this.selectedTab = this.$route.path.includes('/admin-planner/add-permissions') ? 1 : this.$route.path.includes('/admin-planner/add-access') ? 0 : 0;
  },
  methods: {
    onTabClick(index) {
      this.selectedTab = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.admin-planner {
  max-width: 1700px;
  margin: 3rem 110px;
  @media screen and (max-width: 1264px) {
    margin: 2rem;
  }
  h2 {
    @include header-font-3;
    color: #1f3370;
    margin: 0 0 3rem 0;
  }
  .tab-container {
    border-bottom: 2px solid #adadad;
    display: flex;
    gap: 2rem;
    margin: 0 0 2rem 0;
    a {
      text-decoration: unset;
      padding: 0 0 1rem 0;
      @include body-font-bold-1;
      color: #2d2d2d;
    }
    .selected {
      @include body-font-bold-1;
      border-bottom: 4px solid #00d1b0;
    }
  }
}
</style>
