<template>
  <v-card flat>
    <v-card-title style="padding: 0.5rem 1rem">
      <v-row>
        <v-col cols="12" style="padding: 0 0.5rem">
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('workforcePlannerSummary.searchSkill')" single-line hide-details @input="handleSearchInput"></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table :headers="headers" :items="skills" :items-per-page="25" :footer-props="footerProps" :search="search">
      <template v-slot:[`item.name`]="{ item }">
        <div :class="item.isFutureSkill ? 'future-skill' : ''">{{ item.name }}</div>
      </template>
      <template v-slot:[`item.importance`]="{ item }">
        <v-row>
          <v-col style="padding-top: 7%">
            <v-progress-linear :value="item.importance" color="#1F3370" height="10" rounded class="progress" />
          </v-col>
          <v-col class="progress-text">{{ formatImportance(item.importance) }}%</v-col>
        </v-row>
      </template>
      <template v-slot:[`item.progress`]="{ item }">
        <v-row>
          <v-col v-if="!hidden" style="padding-top: 7%">
            <v-progress-linear :value="item.progress" color="#1F3370" height="10" rounded class="progress" />
          </v-col>
          <v-col v-if="!hidden" class="progress-text">{{ item.empLacking }} / {{ item.empNeeded }}</v-col>
          <v-col v-if="hidden" class="progress-text" style="padding-left: 10%">{{ item.empNeeded }}</v-col>
        </v-row>
      </template>
      <template v-slot:[`footer.page-text`]="items"> {{ items.pageStart }} - {{ items.pageStop }} {{ $t('workforcePlannerSummary.paginateOf') }} {{ items.itemsLength }} </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import { translate } from '@/plugins/i18n.js';
export default {
  name: 'WorkforcePlannerSummary',
  props: {
    plan: { type: Object, required: false }
  },
  data() {
    return {
      skills: [],
      search: '',
      hidden: false
    };
  },
  computed: {
    headers() {
      return [
        { title: translate('workforcePlannerSummary.skill'), key: 'name', width: 150, align: 'start' },
        { title: translate('workforcePlannerSummary.importance'), key: 'importance', width: 120, align: 'start' },
        { title: translate('workforcePlannerSummary.gap'), key: 'progress', width: 120, align: 'start' }
      ];
    },
    footerProps() {
      return { 'items-per-page-options': [25, 50, 100], 'items-per-page-text': translate('workforcePlannerSummary.paginateRowsPerPage') };
    },
    ...mapGetters(['client_category'])
  },
  async created() {
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.hidden = clientCategory && clientCategory.toLowerCase() === 'c1';
    if (this.hidden) {
      this.headers[2].text = 'Required';
    }
  },
  async mounted() {
    this.skills = [...this.plan.futureSkills, ...this.plan.currentSkills];
    await this.skills.map((skill) => {
      skill.empLacking = skill.numEmployeesLacking < 0 ? 0 : skill.numEmployeesLacking;
      skill.empNeeded = skill.numEmployeesNeeded ? skill.numEmployeesNeeded : 1;
      skill.progress = Math.round((skill.empLacking / skill.empNeeded) * 100);
    });
    analytics.setVarAndTrack({ c: 'WorkforcePlannerSummary', l: 'WorkforcePlannerSummary', a: 'load' });
    this.$gtag.event('WorkforcePlannerSummary', {
      event_category: 'load',
      event_label: 'WorkforcePlannerSummary',
      value: 1
    });
  },
  methods: {
    formatImportance(val) {
      return Math.round(val);
    },
    handleSearchInput(skill) {
      if (skill.length > 2) {
        analytics.setVarAndTrack({ c: 'WorkforcePlannerSummmary', l: skill, a: 'search skill summary' });
        this.$gtag.event('WorkforcePlannerSummary', {
          event_category: 'search skill summary',
          event_label: skill,
          value: 1
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  margin: 1rem 0 !important;
}

.right-th {
  width: 25%;
}

.left-th.text-left,
.right-th.text-left {
  text-transform: uppercase;
  font-size: 16px !important;
  color: $color-primary !important;
  font-weight: bold !important;
}

.left-td.text-left {
  padding: 0px 0px 0px 15px;
  font-size: 16px !important;
}
.righ-td.text-left {
  padding: 0px;
  font-size: 16px !important;
}

.progress-bar {
  display: flex;
  width: 90%;
  align-items: center;
  font-size: 16px !important;

  .progress {
    margin-right: 10px;
  }

  .progress-text {
    width: 10rem;
    text-align: left;
  }
}

.future-skill {
  font-weight: bold;
}

.button {
  position: relative;
  float: right;
  width: 37px;
  margin-right: 1rem;
  text-transform: capitalize;
  background: $button-primary-color-bg !important;
  color: $button-primary-color-text !important;
}
</style>
