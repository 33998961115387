<template>
  <div class="app-container">
    <div class="dashboard">
      <Banner :company="organization" :logo="logoImg" :left-btn="bannerLeftBtn" :right-btn="bannerRightBtn" style="margin-top: 18px" />
    </div>
    <div class="back" @click="onBack">
      <button aria-label="Back to list">
        <v-icon>mdi-arrow-left</v-icon>
      </button>
      <h3>Back to list</h3>
    </div>
    <v-container class="assessment-container">
      <v-row>
        <v-col cols="8">
          <v-card v-if="dataReady" class="assessment-detail">
            <div class="assessment-credentials">
              <v-row class="credentials-row">
                <v-col cols="2" class="credentials-item">
                  <div class="credentials">{{ $t('assessments.credential') }}</div></v-col
                >
                <v-col cols="9" class="credentials-item">{{ assessment.credentialTitle }}</v-col>
              </v-row>
            </div>
            <div class="assessment-header">
              <h2 class="assessment-title">{{ $sanitize(assessment.name) }}</h2>
            </div>
            <div class="assessment-section">
              <div class="assessment-section-header">Project Details</div>
              <div class="assessment-section-content">{{ $sanitize(assessment.shortDescription) }}</div>
            </div>
            <div class="assessment-section">
              <div class="assessment-section-header">Reviewer Guide</div>
              <div class="assessment-section-content">{{ assessment.reviewerGuide || 'None available' }}</div>
            </div>
            <div class="assessment-section">
              <div class="assessment-section-header">The Creator's Note</div>
              <div class="assessment-section-content">{{ assessment.creatorNote || 'None available' }}</div>
            </div>
            <div class="assessment-section">
              <div class="assessment-section-header">Uploaded File</div>
              <button class="assessment-section-content" :class="assessment.supportingFile ? 'downloadable' : ''" :aria-label="`download file ${assessment.supportingFile || 'None available'}`" @click="onDownload" data-el="onDownload">
                <img src="@/assets/images/svgs/capstone-download.svg" />{{ assessment.supportingFile || 'None available' }}
              </button>
            </div>
            <div class="assessment-section">
              <div class="assessment-section-header">Creator</div>
              <div class="assessment-section-pill">
                <div class="assessment-section-pill-item">
                  <img class="author-avatar" :src="assessment.creatorUser.avatar || defaultLogo" />
                  <div class="author-name">{{ assessment.creatorUser.firstName || 'None Available' }}</div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="4">
          <div>
            <v-card class="pa-4 text-center">
              <div class="assessment-header no-border center" style="margin: 0">
                <div class="assessment-title center" style="margin: 0; width: 100%">Status</div>
              </div>
              <v-btn rounded color="#ffbf1f" class="#1d4b83--text no-uppercase" :disabled="notReviewable" @click="onStartReview" data-el="startReview">{{ reviewBtnText }} </v-btn>
            </v-card>
            <v-card>
              <div class="assessment-header no-border center" style="margin: 0">
                <div class="assessment-title center" style="margin: 0; width: 100%">Fail Assessment</div>
              </div>
              <v-textarea v-model="message" filled name="input-7-4" placeholder="Note To Author" style="margin-top: 10px"> </v-textarea>
              <p v-if="emptyNote" class="font-weight-regular error-message">* Must provide note to author</p>
              <v-row>
                <v-col class="center">
                  <v-btn elevation="2" rounded class="fail" color="#DD5217" :disabled="reviewDisabled" @click="onReview('REJECTED')" data-el="fail">Fail</v-btn>
                </v-col>
              </v-row>
            </v-card>
            <v-card class="pa-4 text-center" style="margin-top: 20px">
              <div class="assessment-header no-border center" style="margin: 0">
                <div class="assessment-title center" style="margin: 0; width: 100%">Pass Assessment</div>
              </div>
              <v-btn rounded color="#00d7b8" class="pass #1d4b83--text" :disabled="reviewDisabled" @click="onReview('APPROVED')" data-el="pass">Pass</v-btn>
            </v-card>
          </div>
        </v-col>
        <v-col cols="12">
          <NotesSection v-if="assessment" :id="assessment.id" :caseNotes="assessment.caseNotes" style="margin-top: 30px" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import Banner from '@/components/molecules/Banner';
import NotesSection from '@/components/organisms/NotesSection';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'AssessmentDetail',
  components: {
    Banner,
    NotesSection
  },
  data() {
    return {
      organization: '',
      logoImg: '',
      token: '',
      userGuid: '',
      assessmentId: '',
      assessment: null,
      dataReady: false,
      message: '',
      emptyNote: false,
      defaultLogo: require('@/assets/images/users/no-avatar.png'),
      creatorProfile: null,
      reviewBtnText: '',
      reviewDisabled: true,
      notReviewable: false,
      bannerLeftBtn: {},
      bannerRightBtn: {}
    };
  },
  computed: {
    ...mapGetters(['planCreated', 'company', 'logo', 'user_guid'])
  },
  async created() {
    this.organization = (await localforage.getItem('my_company')) || this.company;
    this.planExist = (await localforage.getItem('my_plan_created')) || this.planCreated;
    this.userGuid = (await localforage.getItem('my_user_guid')) || this.user_guid;
    this.bannerLeftBtn = { text: '', action: null };
    this.bannerRightBtn = { text: '', action: null };
    this.assessmentId = this.$route.params.id;
    this.assessment = await this.$planAdmin.getCapstoneById({ id: this.assessmentId });
    this.creatorProfile = await this.$planAdmin.getCapstoneCreatorProfile({ guid: this.assessment.creatorUser.guid });
    this.reviewBtnText = ['preview', 'approved'].includes(this.assessment.status.toLowerCase()) ? 'Reviewed' : !this.assessment.currentReviewer ? 'Start Review' : this.assessment.currentReviewer.userGuid === this.userGuid ? "I'm Reviewing" : 'In Review';
    this.reviewDisabled = !this.assessment.currentReviewer || !this.assessment.currentReviewer.userGuid === this.userGuid;
    this.notReviewable = ['In Review', 'Reviewed'].includes(this.reviewBtnText);
    this.dataReady = true;
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    },
    async onStartReview() {
      analytics.setVarAndTrack({ c: 'Assessments', l: 'AssessmentStartReview', a: 'click' });
      this.$gtag.event('Assessments', {
        event_category: 'click',
        event_label: 'AssessmentStartReview' + this.assessmentId,
        value: 1
      });
      await this.$planAdmin.startCapstoneReview({ id: this.assessmentId });
      this.reviewDisabled = false;
      this.reviewBtnText = "I'm Reviewing";
    },
    async onReview(status) {
      this.emptyNote = this.message.length <= 0;
      if (status === 'REJECTED' && this.emptyNote) {
        analytics.setVarAndTrack({ c: 'Assessments', l: 'AssessmentReviewRejected', a: 'click' });
        this.$gtag.event('Assessments', {
          event_category: 'click',
          event_label: 'AssessmentReviewRejected' + this.assessmentId,
          value: 1
        });
        return;
      }
      const payload = {
        id: this.assessment.id,
        reviewRequest: { comment: status.toLowerCase(), reviewResult: status, rejectReason: this.message || 'no reject reason' }
      };
      analytics.setVarAndTrack({ c: 'Assessments', l: 'AssessmentReviewApproved', a: 'click' });
      this.$gtag.event('Assessments', {
        event_category: 'click',
        event_label: 'AssessmentReviewApproved' + this.assessmentId,
        value: 1
      });
      await this.$planAdmin.reviewCapstone(payload);
      this.message = '';
      this.onBack();
    },
    async onDownload() {
      if (this.assessment.supportingFile) {
        const blob = await this.$planAdmin.downloadCapstoneFile({ id: this.assessment.id });
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.setAttribute('download', this.assessment.supportingFile);
        a.click();
        analytics.setVarAndTrack({ c: 'Assessments', l: 'AssessmentDownload', a: 'download' });
        this.$gtag.event('Assessments', {
          event_category: 'download',
          event_label: 'AssessmentDownload' + this.assessmentId,
          value: 1
        });
      }
    }
  },
  deactivated() {
    this.$destroy();
  },
  metaInfo() {
    return {
      title: translate('pageTitles.assessmentsDetail')
    };
  }
};
</script>

<style lang="scss" scoped>
.app-container {
  background: $color-grey-lighter;
  height: 100%;
  z-index: 0;
}
.dashboard {
  background: $color-primary-lighter;
}

.back {
  margin: 20px 10% 0 10%;
  display: flex;
  h3 {
    margin-left: 5px;
  }
  cursor: pointer;
}

.assessment-container {
  width: 80%;
  margin: 0 10% 30px 10%;
  padding: 0;
}

.v-card {
  padding: 30px 40px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.v-btn {
  min-width: 120px !important;
  text-transform: none;
}

.assessment-header {
  display: flex;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid $color-grey-light;
  .assessment-icon {
    width: 35px;
    height: 35px;
  }
  .assessment-title {
    font-size: 25px;
    font-weight: bold;
  }
}

.assessment-section {
  margin: 20px 0px;
  .assessment-section-header {
    padding: 2px 0px;
    font-size: 20px;
    font-weight: bold;
  }
  .assessment-section-subheader {
    padding: 2px 0px;
    font-size: 20px;
    font-weight: bold;
    color: $color-violet;
  }
  .assessment-section-content {
    padding: 2px 0px;
    // font-size: 18px;
    color: $color-grey;
    img {
      margin: 0px 10px;
    }
  }
  .assessment-section-card {
    display: flex;
    flex-flow: column;
    height: 365px;
    overflow: scroll;
    .card-item {
      margin: 10px 0px;
      width: 100%;
    }
  }

  .assessment-section-pill {
    display: flex;
    flex-flow: row wrap;

    .assessment-section-pill-item {
      display: flex;
      padding: 5px 20px;
      border-radius: 20px;
      color: $color-grey-dark;
      font-size: 18px;
      font-weight: bold;
      margin: 10px 0px;
      margin-right: 15px;
      box-shadow: 5px 5px 8px$color-grey-light;

      .author-avatar {
        position: relative;
        left: -15px;
        height: 35px;
        width: 35px;
        border-radius: 50%;
        border: 2px solid $color-secondary;
      }
      .author-name {
        margin-top: 5px;
      }
    }
  }
}
.assessment-credentials {
  padding: 10px 0px 20px 0px;
  display: flex;
  .credentials-item {
    padding: 0px 12px 0px 0px;
    font-size: 14px;
    text-transform: uppercase;
    color: $color-black;
    font-weight: 600;
  }
}
.credentials-row {
  padding: 0px;
  margin: 0px;
}
.credentials {
  background: $color-secondary;
  border-radius: 12px;
  text-align: center;
}
.stats-panel {
  margin: 0;
  padding: 0;
  .row {
    padding: 5px 10px;
    margin: 0;
    .col {
      padding: 0;
      margin: 0 10px;
    }
    .stats-field {
      text-align: right;
    }
    .stats-val {
      font-weight: bold;
      color: $color-violet;
    }
  }
}

.center {
  text-align: center;
  padding: 0;
}

.pass {
  color: #1f3370;
  width: 144px;
  height: 50px;
  margin-top: 10px;
}
.fail {
  width: 144px;
  height: 50px;
  color: white;
}
.start {
  width: 144px;
  height: 50px;
  color: white;
}

.no-border {
  border-bottom: none;
}

.error-message {
  color: $color-data-2;
  text-align: left;
  margin: -15px 0 0 0;
  padding: 0 20px 20px 20px;
}

.downloadable {
  cursor: pointer;
  color: $color-primary !important;
}
</style>
