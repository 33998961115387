<template>
  <div id="footer-container">
    <div class="footer-content">
      <div class="terms">
        <div class="copyright">
          <span v-if="$route.path.includes('/hr-tools')">{{ $t('phrase.disclaimer.workforce_automation_rightsReserved', { year: currentYear }) }}</span>
          <span v-else>{{ $t('phrase.disclaimer.rightsReserved', { year: currentYear }) }}</span>
        </div>
        <div class="links-container">
          <a href="/help-center">{{ $t('legal.help') }}</a>
          <a href=" https://privacyhub.pwc.com/us/en/proedge/privacy-policy.html" target="_blank">{{ $t('legal.privacy') }}</a>
          <a href="https://privacyhub.pwc.com/us/en/proedge/cookie-notice.html" target="_blank">{{ $t('legal.cookie') }}</a>
          <a href="https://productcentral.products.pwc.com/legal/Terms-of-Use" target="_blank">{{ $t('legal.terms') }}</a>
        </div>
      </div>
      <feature-flag name="plan_i18n_enable-language-picker" v-if="multiLingualEnabled && currentLanguage.length">
        <div class="locale-picker">
          <div id="dropdown-container">
            <button class="dropdown-button" @click="isLocalPickerOpen = !isLocalPickerOpen">
              <svg-icon class="locale-picker-globe" icon-class="locale-picker-globe" />
              <span>{{ currentLanguage }}</span>
              <svg-icon class="locale-picker-caret" icon-class="locale-picker-caret" />
            </button>
            <div class="dropdown-content" :class="isLocalPickerOpen && 'active'">
              <div class="list-item-container" v-for="(locale, index) in languages" :key="index" @click="setLocale(index)">
                <div class="list-item-content">{{ locale.language }}</div>
              </div>
            </div>
          </div>
        </div>
      </feature-flag>
    </div>
  </div>
</template>
<script>
import { getSupportedLanguagesForLanguageDropdown, setNewLocale } from '../../plugins/i18n.js';
import { mapGetters } from 'vuex';
import { momentLocales } from '@/utils';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'FooterView',
  data() {
    return {
      currentYear: '',
      isLocalPickerOpen: false,
      languages: {},
      currentLanguage: '',
      multiLingualEnabled: false
    };
  },
  watch: {
    async $route() {
      this.isLocalPickerOpen = false;
    },
    '$i18n.locale'() {
      this.updateLanguageDropdown(this.$i18n.locale);
    }
  },
  async mounted() {
    document.addEventListener('click', this.closeLocalPicker);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeLocalPicker);
  },

  async created() {
    const tenant = await this.$planAdmin.getUserTenantInfo();
    const settings = tenant.settings && tenant.settings !== '' ? JSON.parse(tenant.settings) : null;

    this.multiLingualEnabled = settings['multi-lingual-enabled'] ? settings['multi-lingual-enabled'] : false;
    const date = new Date();
    this.currentYear = date.getFullYear();
    if (this.enabledFeatureFlags && this.enabledFeatureFlags['plan_i18n_enable-language-picker']) {
      const response = await this.$planAdmin.getUserInfo();

      if (response && response.guid) {
        // if the preferred language is set, use it
        if (response.preferredLanguage) {
          setNewLocale(response.preferredLanguage);
          this.updateMomentLocal(response.preferredLanguage);
          this.updateLanguageDropdown(response.preferredLanguage);
        } else if (response.defaultLanguage) {
          setNewLocale(response.defaultLanguage);
          this.updateMomentLocal(response.defaultLanguage);
          this.updateLanguageDropdown(response.defaultLanguage);
        } else {
          // Fallback language is English
          this.currentLanguage = 'English';
          this.$i18n.locale = 'en-us';
          this.updateLanguageDropdown('en-us');
        }
      }
    }
  },
  computed: {
    ...mapGetters(['enabledFeatureFlags'])
  },
  methods: {
    closeLocalPicker(event) {
      const footerContainer = document.querySelector('#dropdown-container');
      if (footerContainer && !footerContainer.contains(event.target)) {
        this.isLocalPickerOpen = false;
      }
    },
    updateLanguageDropdown(localeCode) {
      const languageNameTag = 'languagePicker.' + localeCode;
      this.currentLanguage = translate(languageNameTag);
      const supLang = getSupportedLanguagesForLanguageDropdown();
      this.languages = {};
      for (const language of supLang) {
        const languageNameTag = 'languagePicker.' + language.languageCode;
        this.languages[language.languageCode] = { language: translate(languageNameTag) };
      }
    },
    setLocale(localeCode) {
      try {
        setNewLocale(localeCode);
        this.isLocalPickerOpen = !this.isLocalPickerOpen;
        this.updateMomentLocal(localeCode);
        const payload = {
          languageCode: localeCode
        };
        this.$planAdmin.updateUserLanguage(payload);
        this.updateLanguageDropdown(localeCode);
      } catch (e) {
        console.log(e);
      }
    },
    updateMomentLocal(locale) {
      locale && locale.length === 0 ? this.moment.locale('en') : momentLocales.includes(locale) ? this.moment.locale(locale) : this.moment.locale(locale.split('-')[0]);
    }
  }
};
</script>
<style lang="scss">
#footer-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3rem 0 3.125rem;
  background-color: #efefef;

  .footer-content {
    display: grid;
    grid-template-columns: 8fr 4fr;
    padding: 0rem 3rem;
    width: 100%;

    .terms {
      .copyright {
        padding-bottom: 1rem;
        span {
          font-family: 'Futura PT Demi';
          font-style: normal;
          font-size: 0.875rem;
          line-height: 1.3125rem;
          color: #5c5c5c;
        }
      }
      .links-container {
        display: flex;

        a {
          padding: 0rem 2rem;
          font-family: 'Futura PT Demi';
          font-style: normal;
          font-size: 0.875rem;
          line-height: 1.3125rem;
          color: #1f3370;
          flex: none;
          order: 0;
          flex-grow: 0;
          text-decoration: none;

          &:first-of-type {
            padding-left: 0rem;
          }
          &:last-of-type {
            padding-right: 0rem;
          }
        }
      }
    }

    .locale-picker {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .dropdown-button {
        background: #1f3370;
        border-radius: 2.8125rem;
        padding: 0rem 1.5rem;
        min-width: 12rem;
        height: 2.25rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .locale-picker-globe {
          margin-right: 0.5rem;
        }

        .locale-picker-caret {
          margin-left: 0.5rem;
        }

        span {
          font-family: 'Futura PT Demi';
          font-style: normal;
          font-size: 1rem;
          line-height: 1.25rem;
          color: #ffffff;
        }
      }

      #dropdown-container {
        position: relative;
        display: inline-block;

        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #f1f1f1;
          box-shadow: 0rem 0.5rem 1rem 0rem rgba(0, 0, 0, 0.2);
          z-index: 1;
          bottom: 2.25rem;

          .list-item-container {
            color: black;
            padding: 0.75rem 1rem;
            text-decoration: none;
            cursor: pointer;
            width: 100%;

            .list-item-content {
              width: 100%;
              padding: 0.5rem 1.5rem 0.5rem 0.5rem;
              border-radius: 0.25rem;
              font-style: normal;
              line-height: 1.125rem;
              font-family: 'Futura PT Demi';
            }

            &:hover {
              .list-item-content {
                background: #dde0e9;
              }
            }
          }
        }

        .active {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          margin: 0 auto;
          width: -webkit-fill-available;
          left: 1rem;
          right: 1rem;
        }
      }
    }
  }
}
</style>
