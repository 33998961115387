<template>
  <div class="progress-container" :style="{ background: updateAllowed ? 'rgba(179, 220, 249, 0.15)' : 'unset' }">
    <div class="progress-value">
      <p>{{ translate('hr_tools.fow.level_automation') }} {{ progress }}% {{ translate('hr_tools.fow.automated') }}</p>
    </div>
    <div>
      <div class="progress-bar" @mousedown="startDrag" @click="handleBarClick" ref="progressBar">
        <div class="progress-fill" :style="{ width: `${progress}%`, backgroundColor: color }"></div>
        <div class="progress-handle" :style="{ left: progressHandleStyle, backgroundColor: color, cursor: updateAllowed ? 'grab' : 'auto' }" @mousedown.stop="startDrag"></div>
      </div>
      <div class="markers">
        <div v-for="(value, index) in rangeValues" :key="value" class="marker" :style="{ left: `${value}%` }">
          <div class="marker-value" :class="updateAllowed ? `marker-value-container marker-value-${index}` : `marker-value-${index}`" @click="handleMarkerClick(value)">{{ value }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { translate } from '@/plugins/i18n.js';
export default {
  name: 'DraggableProgressBar',
  props: {
    item: {
      type: Object,
      required: true
    },
    roleGuid: {
      type: String,
      required: true
    },
    updateAllowed: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isDragging: false,
      rangeValues: [0, 30, 70, 100],
      progress: 0,
      color: '#000000',
      colors: { 0: '#141414', 30: '#003DAB', 70: '#4DACF1', 100: '#B3DCF9' }
    };
  },
  computed: {
    progressHandleStyle() {
      return this.progress == 0 ? '0.8%' : this.progress == 100 ? '99.2%' : `${this.progress}%`;
    }
  },
  mounted() {
    this.progress = this.item.automationValue;
    const findAutomationLevelValue = this.progress > 0 && this.progress <= 30 ? 30 : this.progress > 30 && this.progress <= 99 ? 70 : this.progress == 100 ? 100 : 0;
    this.color = this.colors[findAutomationLevelValue];
    document.addEventListener('mousemove', this.onDrag);
    document.addEventListener('mouseup', this.stopDrag);
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.stopDrag);
  },
  watch: {
    progress(val) {
      const findAutomationLevelValue = val > 0 && val <= 30 ? 30 : val > 30 && val <= 99 ? 70 : val == 100 ? 100 : 0;
      this.color = this.colors[findAutomationLevelValue];
      this.$emit('change', { val: val, roleGuid: this.roleGuid, task: this.item });
    }
  },
  methods: {
    translate,
    startDrag(e) {
      if (!this.updateAllowed) return;
      this.isDragging = true;
      // Immediately update progress on drag start
      this.updateProgressFromEvent(e);
    },
    stopDrag(val) {
      this.isDragging = false;
      // Emit final value when drag ends
      // console.log('drag', val);
      // console.log('on drag', this.progress);
      // this.$emit('change', this.progress);
    },
    onDrag(e) {
      if (!this.updateAllowed) return;
      if (!this.isDragging) return;
      this.updateProgressFromEvent(e);
      // console.log('on drag', this.progress);
    },
    updateProgressFromEvent(e) {
      const progressBar = this.$refs.progressBar;
      const rect = progressBar.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const width = rect.width;

      let percentage = (x / width) * 100;
      percentage = Math.max(0, Math.min(100, percentage));

      // Snap to closest point if within 5% range
      const snapPoint = this.findClosestSnapPoint(percentage);
      if (Math.abs(snapPoint - percentage) < 5) {
        percentage = snapPoint;
      }

      this.progress = Math.round(percentage);
      // Emit value during drag
      // this.$emit('input', this.progress);
    },
    findClosestSnapPoint(value) {
      return this.rangeValues.reduce((prev, curr) => {
        return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
      });
    },
    handleBarClick(e) {
      if (!this.updateAllowed) return;
      if (!this.isDragging) {
        // console.log('bar');
        this.updateProgressFromEvent(e);
        // this.$emit('change', { val: this.progress, roleGuid: this.roleGuid, task: this.item });
      }
    },
    handleMarkerClick(value) {
      if (!this.updateAllowed) return;
      this.progress = value;
      // console.log('marker');
      // this.$emit('input', value);
      // this.$emit('change', { val: this.progress, roleGuid: this.roleGuid, task: this.item });
    }
  }
};
</script>

<style lang="scss" scoped>
.font-body {
  font-family: 'Helvetica Neue';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.progress-container {
  width: 100%;
  padding: 8px 1rem;
}

.progress-bar {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: #dddddd;
  border-radius: 5px;
  cursor: pointer;
}

.progress-value {
  margin-bottom: 9px;
  p {
    @extend .font-body !optional;
    color: #252525;
  }
}

.progress-fill {
  height: 100%;
  background-color: #4caf50;
  border-radius: 5px;
  transition: width 0.2s ease;
}

.progress-handle {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  z-index: 1;
}

.progress-handle:active {
  cursor: grabbing;
}

.markers {
  position: relative;
  width: 100%;
  height: 30px;
}

.marker {
  position: absolute;
  transform: translateX(-50%);
}

.marker-line {
  width: 2px;
  height: 10px;
  background-color: #666;
  margin: 0 auto;
  transition: background-color 0.2s ease;
}

.marker-value {
  font-size: 12px;
  margin-top: 5px;
  transition: all 0.2s ease;
  @extend .font-body !optional;
  color: #696969;
}

.marker-value-container {
  text-align: center;
  padding: 2px 6px;
  border-radius: 10px;
  cursor: pointer;
}

.marker-value-0 {
  margin-left: 1rem;
}

.marker-value-3 {
  margin-right: 30px;
}

.marker:hover .marker-line {
  background-color: #4caf50;
}

.marker:hover:first-child {
  .marker-value-container {
    color: #141414;
    background: #dddddd;
  }
}

.marker:hover:nth-child(2) {
  .marker-value-container {
    color: #003dab;
    background: #dddddd;
  }
}

.marker:hover:nth-child(3) {
  .marker-value-container {
    color: #4dacf1;
    background: #dddddd;
  }
}

.marker:hover:last-child {
  .marker-value-container {
    color: #b3dcf9;
    background: #ebe8e8;
    // border-color: rgba(179, 220, 249, 0.15);
  }
}
</style>
