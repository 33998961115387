<template>
  <div class="insights-percentage">
    <span role="text" :aria-label="value === 0 ? `${Math.abs(value)}%` : value > 0 ? `increase ${Math.abs(value)}%` : `decrease ${Math.abs(value)}%`" :class="value === 0 ? '' : value > 0 ? 'insights-percentage-up-arrow' : 'insights-percentage-down-arrow'"></span>
    <span aria-hidden="true">{{ Math.abs(value) }}%</span>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  }
};
</script>

<style lang="scss" scoped>
.insights-percentage {
  margin: 0rem 0.5rem;
  font: normal normal normal 0.625rem/0.6875rem Arial;
  letter-spacing: 0rem;
  color: #1f3370;
  display: grid;
  grid-template-rows: 8fr 4fr;

  .insights-percentage-down-arrow {
    background: no-repeat center url('~@/assets/images/svgs/dashboard-arrow-red.svg');
    transform: rotate(180deg);
  }

  .insights-percentage-up-arrow {
    background: no-repeat center url('~@/assets/images/svgs/dashboard-arrow-green.svg');
  }
}
</style>
