<template>
  <div v-if="dataReady">
    <v-card v-if="tracks" flat class="detail-card">
      <v-row class="header" no-gutters>
        <v-col cols="6" align-self="center">
          <div align-self="center" class="t-pb-2 t-font-futura-pt-demi t-text-xl t-text-primary-color">{{ tracks.length > 1 ? $t('learningTrackDetail.tracksPlural') : $t('learningTrackDetail.tracksSingular') }}</div>
        </v-col>
        <v-col cols="3" align-self="center" class="t-font-arial t-text-xxs">{{ $t('learningTrackDetail.lastModified') }} </v-col>
        <v-col cols="2" align-self="center" class="t-font-arial t-text-xxs">{{ $t('learningTrackDetail.learningTime') }}</v-col>
        <v-col cols="1"></v-col>
      </v-row>
      <v-row no-gutters v-for="track in tracks" :key="track.id">
        <v-col cols="6" align-self="center" class="t-font-arial t-text-xxs mt-4 t-cursor-pointer t-text-primary-color" @click="manageLearningTrack(track.guid, track.roleGuid, track.roleName)"> {{ track.name }} </v-col>
        <v-col cols="3" align-self="center" class="t-font-arial t-text-xxs mt-4 t-text-primary-color capitalize"> {{ localizedDate(moment, track.updatedAt) }} </v-col>
        <v-col cols="2" align-self="center" class="t-font-arial t-text-xxs mt-4 t-text-primary-color"> {{ track.duration }} </v-col>
        <v-col cols="1" align-self="center" class="t-mt-3">
          <v-menu bottom right>
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props">
                <v-icon style="color: black" size="16">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="t-cursor-pointer">
                <v-list-item-title @click="manageLearningTrack(track.guid, track.roleGuid, track.roleName)">{{ $t('learningTrackDetail.editContent') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-else flat class="detail-card">
      <v-row class="header" no-gutters>
        <v-col cols="6" align-self="start">
          <div class="t-font-futura-pt-demi t-text-xl t-text-primary-color" style="padding-top: 0.5rem">{{ $t('learningTrackDetail.title') }}</div>
        </v-col>
        <v-col cols="4" align-self="end"></v-col>
        <v-col cols="2" align-self="end" style="padding-bottom: 0.5rem">
          <v-btn name="learning-track-btn" class="t-ml-4 t-h-8 t-min-w-min t-px-4 t-rounded-full t-font-bold t-text-xs t-font-futura-pt-demi t-bg-yellow-400 t-border-yellow-400 hover:t-bg-primary-color hover:t-text-white float-right" @click="openLearningTrack()">
            {{ $t('learningTrackDetail.createNew') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col style="text-align: left; margin: 15px 0 30px">
          <div style="color: #5c5c5c">{{ $t('learningTrackDetail.noLearningTracks') }}</div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { formatDuration, localizedDate } from '@/utils';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'LearningTrackDetail',
  props: {
    userId: { type: Number, required: false },
    roleName: { type: String, required: true },
    roleGuid: { type: String, required: true },
    roleSkills: { type: Array, required: true }
  },
  watch: {
    async roleName(newVal, oldVal) {
      if (newVal !== oldVal) await this.fetchTracks();
    }
  },
  data() {
    return {
      dataReady: false,
      headers: [
        { text: translate('learningTrackDetail.tracksLabel'), value: 'trackName', width: 150, align: 'start' },
        { text: translate('learningTrackDetail.lastModifiedLabel'), value: 'lastModified', width: 120, align: 'center' },
        { text: translate('learningTrackDetail.learningTimeLabel'), value: 'learningTime', width: 120, align: 'center' },
        { text: translate('learningTrackDetail.assigneesLabel'), value: 'assignees', width: 120, align: 'center' },
        { text: '', value: 'trackSlug', width: 60, align: 'right' }
      ],
      trackingData: [],
      tracks: null
    };
  },
  async mounted() {
    await this.fetchTracks();
  },
  methods: {
    localizedDate,
    ...mapActions('track', ['setRoleGuid', 'setTrackGuid', 'setRoleNameTrack', 'setSkillsTrack']),
    async fetchTracks() {
      let tracksForUser = this.userId ? await this.$planAdmin.getTracksForUser({ userId: this.userId }) : await this.$planAdmin.getTracksForRole({ roleGuid: this.roleGuid });
      if (tracksForUser && tracksForUser.length > 0) {
        if (!this.userId) tracksForUser = tracksForUser.data;
        this.tracks = tracksForUser
          .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          .map((item) => {
            if (item.duration.type === 'hours') item.duration = item.duration.value + 'h';
            else item.duration = formatDuration(item.duration.value);
            item.roleGuid = item?.assignees?.roles && item.assignees.roles[0]?.guid;
            item.roleName = item?.assignees?.roles && item.assignees.roles[0]?.name;
            return item;
          });
      } else {
        this.tracks = null;
      }
      this.dataReady = true;
    },
    async openLearningTrack() {
      const payload = {
        roleGuid: this.roleGuid,
        action: 'recommend',
        pageNum: 1
      };

      try {
        const response = await this.$planAdmin.recommendNewTrack(payload);
        const trackGuid = response.learning_track.guid;
        this.setTrackGuid(trackGuid);
        this.setRoleNameTrack(this.roleName);
        this.setRoleGuid(this.roleGuid);
        this.$router.push({ path: '/catalog/stepper/edit' });
      } catch (error) {
        this.showToast(translate('track.failureCreatingTrackMessage'), 'error', 3000, false);
      }
    },
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });

      this.$toasted.show(msg, options);
    },
    manageLearningTrack(trackGuid, roleGuid, roleName) {
      this.setRoleNameTrack(roleName);
      //this.setSkillsTrack(this.roleSkills)
      this.setRoleGuid(roleGuid);
      this.setTrackGuid(trackGuid);

      this.$router.push({ path: '/catalog/stepper/edit' });
    }
  }
};
</script>
<style lang="scss" scoped>
.v-btn {
  box-shadow: none;
  background: transparent;
  height: 2px;
  width: 2rem;

  &:hover {
    box-shadow: none;
    background: transparent;
  }
}
.v-data-table > .v-table__wrapper > table {
  border-collapse: separate;
  padding: 20px 60px;

  border: none;

  tbody tr:nth-of-type(even) {
    background-color: #efefef80;
  }
  tbody tr:nth-of-type(odd) {
    background-color: #ffffff;
  }

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
}
.v-data-footer {
  margin-bottom: 40px;
}
.v-table__wrapper {
  padding: 20px;
}

.searchTrack {
  padding: 0;
  width: 100%;
  position: relative;
  background-color: #ffffff !important;
  //left: 0%;
}
.v-application .title {
  display: inline-block;
  color: $color-primary;
  font-size: 1.75rem !important;
  font-weight: 700;
}
.header {
  border-bottom: 2px solid #dedede;
  margin-bottom: 0.5rem;
}

.detail-card {
  margin: 0px;
  padding: 1rem;
  border-radius: 10px;
}

.v-list-item {
  min-height: 40px;
}

.v-list {
  padding: 4px 0;
}

.v-btn {
  height: 30px;
  text-transform: none;
}
</style>
