<template>
  <iframe :src="props.embedUrl" width="90%" height="100%" frameborder="0"> </iframe>
</template>
<script>
export default {
  props: {
    embedUrl: {
      type: String,
      required: true
    }
  },
  setup(props) {
    return {
      props
    };
  },
  mounted() {}
};
</script>
<style>
iframe {
  display: block;
  margin: auto;
}
</style>
