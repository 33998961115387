<template>
  <v-dialog content-class="save-dialog-tracks" class="save-dialog" :modelValue="saveDialog" @update:modelValue="saveDialog = $event" persistent width="370">
    <v-card class="t-rounded-20-xl t-pt-6 t-px-0">
      <div class="t-pl-4">
        <v-btn icon class="unsaved-close-btn t-float-right" @click="cancelNavigation">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <p class="t-text-2xl t-tracking-normal t-font-futura-pt-demi t-text-black t-mb-0 pl-3">{{ $t('confirmation.saveTrack.message') }}</p>
        <v-card-text class="t-text-base t-tracking-normal t-font-arial"> {{ $t('confirmation.saveTrack.details') }} </v-card-text>
        <v-card-actions class="t-pb-8 t-place-content-end t-pr-8">
          <button class="t-font-futura-pt-book t-mx-1 t-color-white t-py-2 min-w-min t-px-6 t-font-bold t-text-base t-border-primary-color" text @click="cancelNavigation()">
            {{ $t('button.cancel') }}
          </button>
          <button class="t-font-futura-pt-book t-mx-1 t-color-white t-py-3 min-w-min t-px-6 t-rounded-full t-font-bold t-text-base t-bg-credential t-border-credential hover:t-bg-primary-color hover:t-text-white" text @click="cancelAndNavigate()">
            {{ $t('button.dontSave') }}
          </button>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  emits: ['cancel-and-navigate', 'cancel-navigation'],
  props: {
    saveDialog: {
      type: Boolean,
      required: true,
      default() {
        return false;
      }
    }
  },
  methods: {
    cancelAndNavigate() {
      this.$emit('cancel-and-navigate');
    },
    cancelNavigation() {
      this.$emit('cancel-navigation');
    }
  }
};
</script>
<style lang="scss" scoped>
.save-dialog-tracks {
  button:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
  }
  .unsaved-close-btn {
    margin-right: 1.125rem;
    margin-top: -0.125rem;
    .v-icon {
      font-size: 1.875rem;
    }
  }
  .v-card-text {
    color: #5c5c5c;
  }
  &.v-dialog {
    box-shadow: none;
  }
  .v-dialog > .v-overlay__content > .v-card {
    padding: 2rem !important;
  }
}
</style>
