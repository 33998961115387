import axios from 'axios';
import { RecommendTrackContainer } from '../model/recommend-track';
import { getMetaData } from '../utils/helper';

export default {
  async searchCCC(url, payload) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.post(url, payload, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    const resultSet = new RecommendTrackContainer(response.data);
    return resultSet.getDataObject();
  },
  async searchAutocomplete(url, payload) {
    const { jsonCharHeader } = await getMetaData();
    const response = await axios.post(url, payload.accessTags, { headers: jsonCharHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  }
};
