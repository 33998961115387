<template>
  <div class="common-stepper-container">
    <div class="common-stepper-header-container" v-if="$route.path !== '/catalog/stepper/edit-due-dates'">
      <div class="header-tabs">
        <div class="tabs-container">
          <router-link @click="updateTabIndex(tab.tidx)" v-for="tab in tabs" :key="tab.tidx" :to="!tab.disabled ? tab.path : ''" tag="li" class="tab" :class="tabIndex === tab.tidx ? 'selected' : ''">
            <a :class="[tab.className, tab.disabled ? 'tab-disabled' : 'tab-enabled']">
              {{ tab.title }}
            </a>
            <ToolTip v-if="tab.className === 'edit-content' && tab.disabled" :content="$t('stepper.editContentDisabled')" />
            <ToolTip v-if="tab.className === 'manage-people' && tab.disabled" :content="$t('stepper.managePeopleDisabled')" />
          </router-link>
        </div>
        <div class="tabs-border" ref="tabsBorder"></div>
      </div>
    </div>

    <div class="component-container">
      <router-view v-slot="{ Component, route }">
        <keep-alive :key="route.fullPath">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { translate } from '@/plugins/i18n.js';

import ToolTip from '../../../components/molecules/ToolTip.vue';
export default {
  name: 'CommonStepper',
  components: { ToolTip },
  data() {
    return {
      disabled: true,
      editDetailsComplete: false,
      tabIndex: 0
    };
  },
  computed: {
    ...mapState('stepper', ['tabs']),
    ...mapState('track', ['trackGuid', 'roleGuid', 'trackList', 'totalItemsInTrack', 'trackSaveButtonState', 'trackName', 'trackDescription', 'contentId'])
  },
  watch: {
    contentId(val) {
      if (val) {
        this.tabs[0].disabled = true;
        this.tabs[1].disabled = true;
        this.tabs[2].disabled = false;
      }
    },
    trackGuid(val) {
      if (val) {
        this.tabs[1].disabled = false;
        this.tabs[2].disabled = this.totalItemsInTrack ? false : true;
      }
      if (val && this.roleGuid) {
        this.tabs[0].disabled = false;
      }
    },
    totalItemsInTrack(val) {
      this.tabs[2].disabled = val > 0 && this.trackGuid ? false : true;
    },
    async $route(to, from) {
      if (!to.path.includes('/catalog/stepper/') && from.path.includes('/catalog/stepper/')) {
        await Promise.all([this.clearTrackData(), this.clearStepperState()]);
      }
      if (to.path.includes('/catalog/stepper/') && from.path.includes('/catalog/stepper/')) {
        this.initializeStepperData();
      }
    },
    '$i18n.locale'() {
      this.refreshStepperState();
    }
  },
  created() {
    window.addEventListener('resize', this.getTabsLength);
  },
  beforeMount() {
    this.EventBus.on('step-complete', (path) => {
      const index = this.tabs.findIndex((item) => item.path === path);
      this.setStepperState(index);
    });
    this.EventBus.on('step-incomplete', (path) => {
      const index = this.tabs.findIndex((item) => item.path === path);
      this.resetStepperState(index);
    });
  },
  mounted() {
    this.initializeStepperData();
    this.refreshStepperState();
  },
  destroyed() {
    window.removeEventListener('resize', this.getTabsLength);
  },
  methods: {
    ...mapActions('stepper', ['setStepperState', 'resetStepperState', 'clearStepperState', 'refreshStepperState']),
    ...mapActions('track', ['clearTrackData']),
    initializeStepperData() {
      if (this.contentId) {
        this.tabs[0].disabled = true;
        this.tabs[1].disabled = true;
        this.tabs[2].disabled = false;
      } else {
        this.tabs[0].disabled = this.roleGuid ? (this.trackGuid ? false : true) : false;
        this.tabs[1].disabled = this.trackGuid || this.roleGuid ? false : true;
        this.tabs[2].disabled = this.trackGuid && this.totalItemsInTrack ? false : true;
      }

      switch (this.$route.path) {
        case '/catalog/stepper/create':
          this.tabIndex = 0;
          break;
        case '/catalog/stepper/edit':
        case '/catalog/stepper/organize':
          this.tabIndex = 1;
          break;
        case '/catalog/stepper/manage':
          this.tabIndex = 2;
          break;
        default:
          break;
      }

      this.getTabsLength();
      // apply checkmark to previous tabs
      this.$nextTick(function () {
        for (let index in this.tabs) {
          const tab = this.tabs[index];
          if (this.tabIndex === tab.tidx && index) {
            let counter = 0;
            while (counter < index) {
              this.setStepperState(counter);
              counter++;
            }
          }
        }
      });
    },
    async updateTabIndex(index) {
      this.tabIndex = index;
    },
    async redirectToHome() {
      this.$router.push('/home');
    },
    getTabsLength() {
      this.$nextTick(function () {
        const container = document.getElementsByClassName('tabs-container');

        if (!container.length) {
          return;
        }

        const elements = container[0].childNodes;
        let progressBarOuterWidth = 0;
        const rightMargin = 48;

        for (let index = 0; index < elements.length; index++) {
          const element = elements[index];

          if (index === elements.length - 1) {
            progressBarOuterWidth += element.offsetWidth;
          } else {
            progressBarOuterWidth += element.offsetWidth + rightMargin;
          }
        }

        if (this.$refs.tabsBorder) this.$refs.tabsBorder.style.width = `${progressBarOuterWidth}px`;
      });
    },
    saveAndContinue() {
      switch (this.$route.path) {
        case '/catalog/stepper/manage':
          return true;
        case '/catalog/stepper/edit':
          this.EventBus.emit('save-track-edit-content');
          break;
        case '/catalog/stepper/organize':
          this.EventBus.emit('save-track-edit-content-organize');
          break;
        case '/catalog/stepper/create':
          this.EventBus.emit('save-track-edit-details');
          break;
        case '/catalog/stepper/edit-due-dates':
          this.EventBus.emit('save-track-edit-due-dates');
          break;
      }
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.commonStepper')
    };
  }
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1920px) {
  .common-stepper-container {
    padding: 0 !important;
    .component-container {
      min-width: 106.25rem !important;
    }
  }
}
.common-stepper-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .common-stepper-header-container {
    display: grid;
    height: 4.8125rem;
    margin: 3.5625rem auto auto -2rem;
    width: calc(100vw - 0.25rem);
    max-width: 106.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .logo-container {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      svg {
        width: 7.6875rem;
        height: 2.375rem;
      }
    }
    .header-tabs {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      position: relative;
      height: 2.25rem;

      .tabs-container {
        width: 100%;
        padding: 0rem;
        display: flex;
        justify-content: flex-end;

        .tab {
          position: relative;
          cursor: pointer;
          display: inline-flex;
          align-items: center;
          justify-content: flex-start;
          font: normal normal bold 1rem/1.375rem Arial;
          letter-spacing: 0.02rem;
          text-decoration: none;
          margin-right: 3rem;

          &:nth-of-type(4) {
            margin-right: 0rem;
          }

          &:last-of-type {
            margin-right: 0rem;
          }

          a {
            text-decoration: none;
            display: flex;
            padding-bottom: 0.725rem;
            color: #1f3370;
            &.tab-disabled {
              color: rgba(0, 0, 0, 0.3);
              pointer-events: none;
              span {
                color: rgba(0, 0, 0, 0.3);
              }
            }
          }

          &:hover .tooltip-text {
            visibility: visible;
            width: 22rem;
            left: auto !important;
            right: 0 !important;
          }

          .tab-pill {
            border-radius: 1.438 rem;
            padding: 0.32rem 0.5rem;
            font: normal normal bold 0.875rem/1.875rem Arial;
            letter-spacing: 0.009rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0rem;
            max-height: 1.25rem;
            max-width: 1.25rem;
            height: 1.25rem;
            width: 1.25rem;
            margin: 0 0.5rem 0 0;
            background: #e3e3e3;
            color: #000;
          }

          &.selected {
            border-bottom: 0.125rem solid #00d1b0;
          }
          &:not(.selected) {
            margin-bottom: 0.125rem;
          }

          span {
            margin-left: 0.3rem;
            padding: 0rem 0.7rem;
            border-radius: 1rem;
            font-size: 0.875rem;
            font-family: Arial;
          }
        }
      }
      .tabs-border {
        background: #c4c4c45a;
        opacity: 1;
        width: 100%;
        height: 0.125rem;
        position: absolute;
        // right: 3rem;
      }
      .progress-bar-outer {
        height: 0.25rem;
        background: #ccc;
        .progress-bar-inner {
          height: 0.25rem;
          background: #00d1b0;
        }
      }
    }
  }
  .component-container {
    width: Calc(100vw - 0.25rem);
    padding: 0 3rem;
    max-width: 106.25rem;
    margin: auto;
    flex: 1 0 auto;
  }
  .footer-container-track {
    width: 100%;
    background: #fff;
    height: 4.6875rem;
    position: sticky;
    bottom: 0;
    z-index: 1;
    .save-and-continue:disabled {
      background-color: rgba(255, 185, 46, 0.5) !important;
      color: rgba(31, 51, 112, 0.26);
      pointer-events: none;
    }
  }
}
</style>
<style lang="scss">
.common-stepper-container {
  .exit-button.v-btn {
    width: 5.375rem;
    height: 3.125rem;
    background: #1f3370 0% 0% no-repeat padding-box;
    border-radius: 1.5625rem !important;
    opacity: 1;
    margin-right: 0.5rem;
    box-shadow: none !important;
    &.save-button {
      background: #ffb92e 0 0 no-repeat padding-box;
      span {
        color: inherit;
      }
    }
    span {
      font: normal normal normal 1rem/1.375rem 'Futura PT Demi';
      letter-spacing: 0.0013rem;
      color: #ffffff;
    }
    &:disabled {
      border: 0.125rem solid #c4c4c4;
      span {
        color: inherit;
      }
    }
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>
