<template>
  <div class="container">
    <v-card class="privacy-card">
      <v-card-title class="privacy-title">ProEdge Privacy Statement</v-card-title>
      <v-card-text style="padding: 20px">
        <h4 class="xsmall">Last updated: April 2022</h4>
        <br />
        <h3>Introduction</h3>
        <p>
          ProEdge is an offering of PwC Product Sales LLC ("PwC"), a member of the PricewaterhouseCoopers global network of firms. Each PricewaterhouseCoopers member firm is a separate legal entity. For further details, please see
          <a href="https://www.pwc.com/structure" target="_blank">www.pwc.com/structure</a>.
        </p>
        <p>
          PwC is committed to protecting your privacy. This privacy statement explains our information collection practices with respect to ProEdge (the "Application") and how we use, share, and protect that information. Note, this privacy statement applies solely to the
          Application and does not apply to other PwC websites or applications.
        </p>
        <p>This Application enables organizations and individuals to assess and grow critical skills needed to be competitive. The Application encompasses several modules:</p>
        <ul>
          <li>Plan - Enables the organization to identify critical skills related to job and skills data, including benchmarking by industry and location.</li>
          <li>Learn - Builds a path to success through personalized learning and skill-building activities.</li>
          <li>Share - Allows users to find and share digital assets.</li>
        </ul>
        <br />

        <h3>Information Collection</h3>
        <p>
          When you access or otherwise use our Application, we will collect information about you and how you interact with our Application. We collect information about you through: (1) information you provide; (2) automatic tracking technologies; (3) third parties, namely your
          employer.
        </p>

        <h3>Information You Provide</h3>
        <p>
          When you access the Application, you may choose to provide information about yourself such as: identifiers and contact information including your name, address, email address, telephone number, fax number, job title, and company name. By way of example, you may choose
          to provide your information in the following circumstances:
        </p>
        <ul>
          <li>Contact details (e.g. name, email, address, telephone number)</li>
          <li>Profile photo</li>
          <li>Audio and video enabled by the user</li>
          <li>Education, academic and professional qualifications</li>
          <li>Employment history</li>
          <li>Areas of interest and specialized knowledge/subject matter</li>
          <li>Other information included in your resume/CV that you choose to submit</li>
          <li>Performance evaluation, management and monitoring information</li>
          <li>Information re: course transcripts, certifications, assessment</li>
          <li>Learning and development information</li>
          <li>Social Media Profile Information, if you choose to provide us with a link to your profile page</li>
          <li>"Share" information (e.g. friend's or family member's email address)</li>
        </ul>
        <br />

        <h3>Automatically Collected Information</h3>
        <p>
          We may use online identification technologies, such as cookies, web beacons, pixels, tags, localstorage, or similar technologies ("Cookies") on our Application. The information we collect using these technologies includes identifiers and internet activity information
          such as: IP address, browser type and language, access time, and referring website. We use these technologies to compile aggregated statistics about visitors who interact with PwC online content, to gauge the effectiveness of our ads and to better provide more pertinent
          information to our visitors. For more information about the Cookies used on the Application, please review our
          <a href="./cookie-notice" target="_blank">Cookie Notice</a>.
        </p>
        <p>
          PwC also uses third-party analytics providers, which may set Cookies in your browser or device. Application activity information collected by these analytics providers may be combined with personal information held by PwC. For more information about these third-party
          tools, please review our
          <a href="./cookie-notice" target="_blank">Cookie Notice.</a>
        </p>
        <p>
          When we send you emails, we may use technologies to determine whether the email has been opened and whether the links contained within the message have been clicked on. We may combine information collected through these means with other information we maintain about you
          in order to measure interest in our specific offerings and email campaigns, improve our offerings to specific target audiences, and tailor our interactions with you.
        </p>

        <h3>Information from Third Parties</h3>
        <p>We may also obtain information about you from your employer and from your social media profile should you choose to link it to the Application, such as:</p>
        <ul>
          <li>Contact details (e.g. name, email, address, telephone number)</li>
          <li>Education, language, academic and professional qualifications</li>
          <li>Employment history</li>
          <li>Performance evaluation, management and monitoring information</li>
          <li>Information re: course transcripts, certifications, assessment</li>
          <li>Learning and development information</li>
          <li>Social Media Profile Information, if you choose to provide us with a link to your profile page</li>
          <li>Login credentials</li>
        </ul>
        <br />

        <h3>Information Use</h3>
        <p>
          We use the information we collect as described above for various purposes depending on the context in which we collected such information. For example, it may be necessary for us to process your information in order to process a transaction or provide a service you have
          requested or otherwise in accordance with a contract between us, or we may process your information in accordance with your prior consent (where you have provided such consent).
        </p>
        <p>We use the information we collect for the following business purposes:</p>
        <ul>
          <li>Registering and authenticating users;</li>
          <li>Operating and improving the Application;</li>
          <li>Understanding how the Application is being used;</li>
          <li>Maintaining and improving the safety and security of the Application;</li>
          <li>Preventing and enhancing protection against fraud, spam, harassment, intellectual property infringement, crime and security risks;</li>
          <li>Running our operations;</li>
          <li>Complying with law and legal obligations as well as professional and industry standards;</li>
          <li>Responding to your inquiries;</li>
          <li>Informing organizational workforce, learning &amp; development, and/or digital strategy;</li>
          <li>Participation in bulletin boards, discussion, or message forums;</li>
          <li>Subscription or ordering newsletters and/or publications;</li>
          <li>Conducting customer satisfaction surveys;</li>
          <li>Customizing the Application for you;</li>
          <li>Exploring ways to develop and grow our business;</li>
          <li>Improving our products and services; and</li>
          <li>Sending communications about PwC products, services, and events, if consent is provided by the user.</li>
        </ul>
        <p>We may also use the information we collect to create aggregate or anonymized information, which we may use to build upon and improve the Application and our other products and services as well as for other lawful business purposes.</p>

        <h3>Information Sharing</h3>
        <p>
          As we are administering this Application to you on behalf of your Organization, we share the information we collect through the Application with your Organization. Each Organization using the Application is responsible for its handling of information collected by the
          Application in accordance with its internal policies and applicable law.
        </p>
        <p>
          We are part of a global network of firms. In common with other professional service providers, PwC may transfer or disclose the information it collects, including your information, to third-party contractors, subcontractors, subsidiaries, and/or other PwC firms for the
          purposes for which you have submitted the information and for the administration of our system or Application and/or other internal, administrative purposes. We transfer your information to third-party service providers of identity management, website hosting and
          management, data analysis, data backup, security, and storage services.
        </p>
        <p>PwC may also disclose information to third parties under the following circumstances:</p>
        <ul>
          <li>When explicitly requested by a visitor;</li>
          <li>As needed to deliver publications or reference materials requested by a user;</li>
          <li>For login, authentication, and security purposes;</li>
          <li>
            As necessary to comply with law, rules, regulations, legal obligations, professional and industry standards, as well as respond to and complying with subpoena, search warrant or other legal process, including establishing, exercising, or defending our legal rights;
          </li>
          <li>As necessary in connection with a corporate reorganization, merger, sale, joint venture or other disposition of all or any portion of our business, assets or capital;</li>
          <li>To issue digital badges that certify web-enabled credentials and accomplishments.</li>
        </ul>
        <p>
          We may also share aggregate information about Application users with third parties for any lawful business purpose. We do not sell personal information collected in connection with the Application nor do we disseminate personal information collected in connection with
          the Application to outside parties for consumer marketing purposes or host mailings on behalf of third parties.
        </p>

        <h3>Information Transfer</h3>
        <p>
          Your information may be transferred outside of the U.S. or your country of residence. This includes to countries outside the European Economic Area (“EEA”) and to countries that do not have laws that provide the same degree of protection for personal information. In
          accordance with applicable legal requirements, we take appropriate measures to facilitate adequate protection for any information so transferred.
        </p>
        <p>
          Where we transfer personal information outside of the EEA to a country or framework not determined by the European Commission as providing an adequate level of protection for personal information, the transfers will be under an agreement which covers European Union
          requirements for such transfers, such as standard contractual clauses. The European Commission approved standard contractual clauses are available
          <a href="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en" target="_blank">here</a>.
        </p>
        <p>
          Although not currently relied upon as an authorized mechanism for cross border transfers, PricewaterhouseCoopers LLP and its affiliated U.S. subsidiaries also adhere to the privacy principles in the EU - U.S. Privacy Shield Framework and the Swiss - U.S. Privacy Shield
          Framework as set forth by the U.S. Department of Commerce. To learn more, see our
          <a href="https://www.pwc.com/us/en/site/privacy-shield.html" target="_blank">Privacy Shield Policy</a>.
        </p>

        <h3>Information Retention</h3>
        <p>
          We will retain the personal information collected by us through the Application for as long as is necessary for the purpose(s) for which it was collected as set out in this privacy statement, provided that personal information may be held for longer periods where
          extended retention periods are required by law, regulation or professional standards, and in order to establish, exercise or defend our legal rights.
        </p>

        <h3>Your Choices</h3>
        <h3>
          <i>Unsubscribe</i>
        </h3>
        <p>Should you wish to unsubscribe from our mailing list or any registrations, you may contact us at: us_support_proedge@pwc.com.</p>

        <h3>
          <i>Managing Cookies</i>
        </h3>
        <p>
          If you are concerned about cookies, most browsers permit individuals to decline cookies. In most cases, you may refuse or delete one or more cookies and still access the Application, but Application functionality may be impaired. After you finish browsing this
          Application, you may delete Application cookies from your system if you wish. For more information about how to manage your cookie preferences, please review our
          <a href="./cookie-notice" target="_blank">Cookie Notice</a>.
        </p>

        <h3>
          <i>Do Not Track</i>
        </h3>
        <p>
          Do Not Track ("DNT") is a privacy preference that you can set in your web browser to send a message to the website operator requesting not to be tracked. Currently, we do not respond to these DNT signals. For more information about DNT, visit
          <a href="https://allaboutdnt.com/" target="_blank">https://allaboutdnt.com/</a>.
        </p>

        <h3>
          <i>Access</i>
        </h3>
        <p>
          You may review and change your personal information by logging into the Application and visiting your account profile page. If you would like to update your information or request to have outdated information removed, please contact us at: us_support_proedge@pwc.com.
        </p>

        <h3>
          <i>Rights</i>
        </h3>
        <p>Depending on the jurisdiction in which you are located, you may have certain rights with respect to your personal information. Subject to applicable law, these rights may include the right to:</p>
        <ul>
          <li>Know what personal information about you we have collected, disclosed, and/or sold, as applicable;</li>
          <li>Obtain a copy of your personal information;</li>
          <li>Delete your personal information;</li>
          <li>Direct us not to sell your personal information (but as noted above, we do not sell personal information collected about you in connection with the Application);</li>
          <li>Correct or update your personal information;</li>
          <li>Restrict the processing of your personal information;</li>
          <li>Object to the processing of your personal information;</li>
          <li>Withdraw your consent for processing (where processing is based on your consent). Please note that if you withdraw your consent, we may not be able to provide you the services for which it was necessary to process your information based on your consent; and</li>
          <li>Exercise your privacy rights without discriminatory treatment from us.</li>
        </ul>

        <p>
          To exercise any of the rights described above, please submit a verifiable request to us by either using our
          <a href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/f13f2198-97ab-4c25-a5cd-0fca8ada2e21/eb60a416-648e-47a1-914f-4dc7fba2ce27.html" target="_blank">request form</a>
          or calling us at 1-888-438-4427.
        </p>
        <p>
          The verifiable request must describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it. Further, you must provide us with sufficient information that allows us to reasonably verify you are the person about whom we
          collected personal information or an authorized representative. Please, however, refrain from sending us sensitive personal information. Where permitted by law, you may use an authorized agent to submit a request on your behalf if you have provided the authorized agent
          signed permission to do so.
        </p>
        <p>
          We may not be able to fulfill your request if we cannot verify your identity or authority to make the request and confirm the personal information relates to you. Making a verifiable request does not require you to create an account with us. We will only use personal
          information provided in a verifiable request to verify the requestor's identity or authority to make the request.
        </p>
        <p>
          We will respond to your request within a reasonable timeframe in accordance with applicable law. We reserve the right to charge an appropriate fee for complying with a request where permitted by applicable law, and to deny a request where it may be manifestly unfounded,
          excessive, or unwarranted under applicable law.
        </p>
        <p>
          We hope that you won't ever need to, but if you do want to complain about our use of personal information, please send us a message with the details of your complaint using our
          <a href="https://www.pwc.com/us/en/forms/us-privacy-office-contact.html" target="_blank">general contact form</a>. You may also contact our EU Representative, PwC Malta, by completing our
          <a href="https://www.pwc.com/us/en/forms/contact-eu-representative.html" target="_blank">EU Representative contact form</a>. Applicable laws may also give you the right to lodge a complaint with the data protection authority in your country.
        </p>

        <h3>Security</h3>
        <p>
          PwC has implemented generally accepted standards of technology and operational security designed to protect personal information from unauthorized access, loss, misuse, alteration, or destruction. Only authorized PwC personnel and the third parties described in this
          privacy statement are provided access to personal information and these employees and third parties have agreed to maintain the confidentiality of this information.
        </p>

        <h3>Third-Party Sites</h3>
        <p>
          This Application may link to other websites which do not operate under PwC's privacy practices. When you link to other websites, PwC's privacy practices no longer apply. We encourage you to review the privacy statement of each website you visit before disclosing any
          personal information.
        </p>

        <h3>Children</h3>
        <p>
          PwC understands the importance of protecting children's privacy, especially in an online environment. The Application is not intentionally designed for or directed at children under the age of 13 years. It is PwC's policy never to knowingly collect or maintain personal
          information about children under the age of 13.
        </p>

        <h3>Modifications</h3>
        <p>PwC may update this privacy statement at any time by publishing an updated version here. You can access the most current version of this privacy statement at any time on this Application.</p>
        <br />

        <h3>Contact Us</h3>
        <p>If you have questions about this privacy statement or about our privacy practices, please contact us at us_support_proedge@pwc.com</p>
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'Privacy',
  metaInfo() {
    return {
      title: translate('pageTitles.privacy')
    };
  }
};
</script>

<style lang="scss">
body {
  background-color: #efefef;
}

.v-application--wrap {
  background-color: #efefef;
}
</style>

<style lang="scss" scoped>
.privacy-card {
  margin: 3.5% 0% 1% 0%;
  border-radius: 10px;
  padding: 0.5rem 1rem;
}
.privacy-title {
  font-size: 25px;
  margin-top: 15px;
  color: $color-primary !important;
}

.xsmall {
  font-size: 12px;
}
</style>
