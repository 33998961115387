<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
      default: ''
    }
  },
  computed: {
    enabled() {
      return this.$store.state.flag.enabled[this.name];
    }
  },
  render() {
    return this.enabled ? this.$slots.default() : null;
  }
};
</script>
