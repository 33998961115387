<template>
  <div class="fow-screen-2">
    <h3>{{ translate('hr_tools.fow.org_structure') }}</h3>
    <div class="org-structure-section">
      <div class="function-section">
        <p>{{ translate('hr_tools.fow.function_desc') }}</p>
        <h4>{{ translate('hr_tools.fow.function_title') }}</h4>
        <div class="function-list">
          <v-text-field
            id="search-function"
            class="search-function"
            data-el="searchFunction"
            hide-details
            append-inner-icon="mdi-magnify"
            single-line
            ref="searchInput"
            :placeholder="`Search`"
            :value="functionQuery"
            flat
            filled
            solo
            dense
            clearable
            v-model="functionQuery"
            variant="solo"
          >
          </v-text-field>
          <div class="function-data">
            <div class="scrollable-content">
              <v-checkbox :label="translate('hr_tools.fow.select_all_label')" @update:modelValue="onSelectAllFunctions" :ripple="false" color="#415385"></v-checkbox>
              <v-checkbox v-for="(func, index) in functionList" :key="index" :label="func.name" :value="func.name" v-model="selectedFunctions" :ripple="false" color="#415385" :style="func?.style ? func.style : ''"></v-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="function-section" v-if="selectedFunctions.length">
        <p>{{ translate('hr_tools.fow.role_desc') }}</p>
        <h4>{{ translate('hr_tools.fow.role_title') }}</h4>
        <div class="function-list">
          <v-text-field id="search-function" class="search-function" data-el="searchFunction" hide-details append-inner-icon="mdi-magnify" single-line ref="searchInput" :placeholder="`Search`" :value="roleQuery" flat filled solo dense clearable v-model="roleQuery" variant="solo">
          </v-text-field>
          <div class="function-data">
            <div class="scrollable-content">
              <v-checkbox :label="translate('hr_tools.fow.select_all_label')" @update:modelValue="onSelectAllRoles" :ripple="false" color="#415385"></v-checkbox>
              <v-checkbox v-for="(roleData, index) in roleList" :key="index" :label="roleData.name" :value="roleData.name" v-model="selectedRoles" :ripple="false" color="#415385" :style="roleData?.style ? roleData.style : ''"></v-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="role-detail-section">
      <v-data-table :headers="headers" :items="roleList" :items-per-page="-1" hide-default-footer class="role-table">
        <template v-slot:[`item.name`]="{ item }">
          <div class="role-name-cell">
            <span class="role-name">{{ item.name }}</span>
          </div>
        </template>

        <template v-slot:[`item.employeeCount`]="{ item }">
          <div class="employee-count">
            {{ item.employeeCount }}
          </div>
        </template>

        <template v-slot:[`item.avgSalary`]="{ item }">
          <div class="avg-salary">${{ item.annualSalary.median.toLocaleString() }}</div>
        </template>

        <template #bottom></template>
      </v-data-table>
    </div> -->
  </div>
</template>

<script>
import { translate } from '@/plugins/i18n.js';
import localforage from 'localforage';
import { mapState, mapActions } from 'vuex';
import constant from '../../../api/constant';

export default {
  name: 'FowScreen2',
  emits: [],
  components: {},
  props: {
    currentPage: Number,
    prevPage: Number
  },
  computed: {
    ...mapState('workforce', ['storeSelectedCompanyName', 'storeSelectedOption', 'storeSelectedFileName', 'storeFunctionList', 'storeSelectedFunctions', 'storeRoleList', 'storeSelectedRoles', 'storeOnetCompanyGuid'])
  },
  data() {
    return {
      functionQuery: '',
      roleQuery: '',
      selectedFunctions: [],
      selectedRoles: [],
      company: {},
      file: {},
      functionList: [],
      roleList: [],
      selectedDataType: '',
      onetCompanyGuid: '',
      headers: [
        {
          title: 'Role',
          align: 'start',
          key: 'name',
          sortable: true,
          width: '40%'
        },
        {
          title: 'Number of Employees (per role)',
          align: 'center',
          key: 'employeeCount',
          sortable: true,
          width: '30%'
        },
        {
          title: 'Average annual salary (per role)',
          align: 'end',
          key: 'avgSalary',
          sortable: true,
          width: '30%'
        }
      ]
    };
  },

  watch: {
    functionQuery() {
      const searchQuery = this.functionQuery ? this.functionQuery.trim().toLocaleLowerCase() : '';
      this.functionList.map((tag) => {
        const find = tag.name.toLowerCase().indexOf(searchQuery) > -1;
        tag.style = find ? 'display: flex' : 'display: none';
      });
    },

    roleQuery() {
      const searchQuery = this.roleQuery ? this.roleQuery.trim().toLocaleLowerCase() : '';
      this.roleList.map((tag) => {
        const find = tag.name.toLowerCase().indexOf(searchQuery) > -1;
        tag.style = find ? 'display: flex' : 'display: none';
      });
    },

    selectedRoles: {
      handler: function (val) {
        this.$emit('on-selected-roles', val);
        this.setSelectedRoles(JSON.stringify(val));
      }
    },

    selectedFunctions: {
      handler: async function (val) {
        if (val) await this.fetchRoles(val);
        this.setSelectedFunctions(JSON.stringify(val));
      }
    },

    functionList: {
      handler: function (val) {
        if (val && val.length === 0) this.$emit('on-empty-functions', val);
      }
    }
  },

  async mounted() {
    // Fetch data in parallel using Promise.all
    const [companyData, fileData, selectedDataType, onetCompanyGuid] = await Promise.all([
      this.storeSelectedCompanyName || localforage.getItem('storeSelectedCompanyName'),
      this.storeSelectedFileName || localforage.getItem('storeSelectedFileName'),
      this.storeSelectedOption || localforage.getItem('storeSelectedOption'),
      this.storeOnetCompanyGuid || localforage.getItem('storeOnetCompanyGuid')
    ]);

    // Parse JSON safely with error handling
    try {
      this.company = typeof companyData === 'string' ? JSON.parse(companyData) : companyData;
      this.file = typeof fileData === 'string' ? JSON.parse(fileData) : fileData;
      this.selectedDataType = selectedDataType;
      this.onetCompanyGuid = onetCompanyGuid;
    } catch (parseError) {
      console.error('Error parsing JSON:', parseError);
      return;
    }

    if (this.prevPage === 3) {
      const [functions, selectedFunctions, roles, selectedRoles] = await Promise.all([
        this.storeFunctionList || localforage.getItem('storeFunctionList'),
        this.storeSelectedFunctions || localforage.getItem('storeSelectedFunctions'),
        this.storeRoleList || localforage.getItem('storeRoleList'),
        this.storeSelectedRoles || localforage.getItem('storeSelectedRoles')
      ]);

      // Parse JSON safely with error handling
      try {
        this.functionList = typeof functions === 'string' ? JSON.parse(functions) : functions;
        this.selectedFunctions = typeof selectedFunctions === 'string' ? JSON.parse(selectedFunctions) : selectedFunctions;
        this.roleList = typeof roles === 'string' ? JSON.parse(roles) : roles;
        this.selectedRoles = typeof selectedRoles === 'string' ? JSON.parse(selectedRoles) : selectedRoles;
      } catch (parseError) {
        console.error('Error parsing JSON:', parseError);
        return;
      }
    } else {
      await this.setup();
    }
  },

  async unMounted() {},

  deactivated() {},

  methods: {
    translate,
    ...mapActions('workforce', ['setFunctionList', 'setSelectedFunctions', 'setRoleList', 'setSelectedRoles']),
    async setup() {
      const isOnetData = this.selectedDataType === 'onet';

      const payload = {
        companyGuid: isOnetData ? this.onetCompanyGuid : this.company.guid,
        sourceType: isOnetData ? constant.public : constant.client,
        ...(!isOnetData && {
          fileGuid: this.file.metadata.fileGuid
        })
      };
      const response = await this.$planAdmin.getFunctionList(payload);
      this.functionList = response.map((item) => {
        return {
          name: item,
          style: 'display: flex'
        };
      });
      this.setFunctionList(JSON.stringify(this.functionList));
    },

    async onSelectAllFunctions(val) {
      if (val) {
        this.selectedFunctions = this.functionList.map((item) => item.name);
        await this.fetchRoles(this.selectedFunctions);
      } else {
        this.selectedFunctions = [];
      }
    },

    onSelectAllRoles(val) {
      if (val) {
        this.selectedRoles = this.roleList.map((item) => item.name);
      } else {
        this.selectedRoles = [];
      }
    },

    async fetchRoles() {
      const isOnetData = this.selectedDataType === 'onet';
      const payload = {
        companyGuid: isOnetData ? this.onetCompanyGuid : this.company.guid,
        functionNames: this.selectedFunctions,
        sourceType: isOnetData ? constant.public : constant.client,
        ...(!isOnetData && {
          fileGuid: this.file.metadata.fileGuid
        })
      };
      const response = await this.$planAdmin.getRoleList(payload);
      this.roleList = response.map((item) => {
        return {
          name: item,
          style: 'display: flex'
        };
      });
      this.setRoleList(JSON.stringify(this.roleList));
    }
  },

  metaInfo() {
    return {
      title: translate('pageTitles.hr_tools')
    };
  }
};
</script>

<style lang="scss">
.header-font {
  font-family: 'Helvetica Neue';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.4px;
}
.body-font {
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.search-function {
  .v-label {
    font-weight: normal;
    @extend .body-font !optional;
    color: #474747;
  }
  .v-field--variant-solo {
    background-color: white !important;
    height: 3rem;
    border: 0.125rem solid #e0e1e1;
    display: flex;
    flex-direction: row-reverse;
    padding: 0 1rem;
  }

  .v-field__field,
  .v-field__prepend-inner {
    height: 3rem;
    margin-left: 8px;
  }
  .v-field__input {
    min-height: unset;
    padding: 0 0 4px;
    height: inherit;
    height: 3rem;

    input {
      margin: 0px;
      &::placeholder {
        @extend .body-font !optional;
        letter-spacing: 0.0006rem;
        color: #696969;
        opacity: 1;
      }
    }
  }
}
.search-user .v-input__slot {
  min-height: 2.625rem !important;
  width: 272px;
}
.search-user .mdi-magnify {
  font-size: 1.725rem;
}

.function-data {
  .v-selection-control__input,
  .v-selection-control__wrapper {
    height: 1rem;
    width: 2rem;
  }
  .v-input__details {
    display: none;
  }
  .v-selection-control .v-label {
    color: #474747;
    @extend .body-font !optional;
    opacity: 1;
  }
  .v-checkbox .v-selection-control {
    min-height: 2rem !important;
    display: flex;
    align-items: center;
    padding: 12px 0;
  }
  .v-selection-control--disabled {
    opacity: 1;
    color: #1f3370;
  }
}

.scrollable-content {
  height: 260px;
  overflow-y: scroll;
}

.role-detail-section {
  background: white;
  border-radius: 8px;
  padding: 16px;
  padding: 12px;
  border: 1px solid #d1d1d1;
  margin-top: 1.5rem;

  .v-table__wrapper {
    table {
      border-spacing: 0 8px !important;
      border-collapse: separate !important;
      background: transparent;

      thead {
        tr {
          th {
            border-bottom: none !important;
            font-family: 'Helvetica Neue';
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px; /* 150% */
            letter-spacing: -0.4px;
            columns: #252525;
            background: white;
            padding: 12px;
            height: 48px;
            .v-data-table-header__content {
              justify-content: flex-start !important;
              flex-direction: row !important;
              span {
                font-family: 'Helvetica Neue';
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 150% */
                letter-spacing: -0.4px;
                color: #252525;
              }
            }
          }
        }
      }

      tbody {
        tr {
          transition: background-color 0.2s;
          cursor: pointer;

          td {
            border: none !important;
            padding: 12px;
            height: 48px;
            .role-name-cell {
              span {
                font-family: 'Helvetica Neue';
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
                letter-spacing: -0.4px;
                color: #474747;
              }
            }

            .employee-count,
            .avg-salary {
              @extend .body-font !optional;
              color: #474747;
              text-align: left;
            }

            &:first-child {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }

            &:last-child {
              border-top-right-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.header-font {
  font-family: 'Helvetica Neue';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.4px;
}
.body-font {
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.fow-screen-2 {
  h3 {
    color: #474747;
    @extend .header-font !optional;
  }
  .org-structure-section {
    display: flex;
    margin-top: 1.5rem;
    width: 688px;
    justify-content: space-between;
    .function-section {
      width: 304px;
      p {
        color: #474747;
        @extend .body-font !optional;
        height: 96px;
      }
      h4 {
        margin-top: 1rem;
        color: #474747;
        @extend .body-font !optional;
        font-weight: 500;
      }
      .function-list {
        margin-top: 1rem;
        display: flex;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid #d1d1d1;
        max-height: 356px;
        flex-direction: column;
      }
    }
  }
}
</style>
