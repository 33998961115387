<template>
  <div class="t-mx-28 t-mb-16 t-mt-4">
    <Manage-submissions-list></Manage-submissions-list>
  </div>
</template>

<script>
import ManageSubmissionsList from '@/views/manage-submissions/components/ManageSubmissionsList';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'ManageSubmissions',
  components: { ManageSubmissionsList },
  metaInfo() {
    return {
      title: translate('pageTitles.manageSubmissions')
    };
  }
};
</script>
