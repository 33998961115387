<template>
  <div class="hr-tools">
    <h2>{{ $t('pageTitles.hr_tools') }}</h2>
    <div class="tab-container">
      <router-link data-el="app-plan" class-active="active" :class="selectedTab === index ? 'selected' : ''" v-for="(tab, index) in tabs" :key="index" :to="tab.path" @click.once="onTabClick(index)">
        {{ tab.name }}
      </router-link>
    </div>
    <router-view>
      <div v-if="$route.path.includes('/hr-tools/skill-extraction')">
        <SkillExtraction />
      </div>
      <div v-if="$route.path.includes('/hr-tools/create-skill-profile')">
        <CreateSkillProfile />
      </div>
      <div v-if="$route.path.includes('/hr-tools/talent-architecture')">
        <TalentGpt />
      </div>
      <div v-if="$route.path.includes('/hr-tools/future-of-work')">
        <FOW />
      </div>
    </router-view>
  </div>
</template>

<script>
import { translate } from '@/plugins/i18n.js';
import SkillExtraction from './components/SkillExtraction';
import CreateSkillProfile from './components/CreateSkillProfile';
import TalentGpt from './components/TalentGpt';
import FOW from './components/FutureOfWork';

export default {
  name: 'HrTools',
  components: { SkillExtraction, CreateSkillProfile, TalentGpt, FOW },
  data() {
    return {
      tabs: [
        { name: translate('hr_tools.tabs.skill_extraction'), path: '/hr-tools/skill-extraction' },
        { name: translate('hr_tools.tabs.create_skill_profile'), path: '/hr-tools/create-skill-profile' },
        { name: translate('hr_tools.tabs.talent_architecture'), path: '/hr-tools/talent-architecture' },
        { name: translate('hr_tools.tabs.fow'), path: '/hr-tools/future-of-work' }
      ],
      selectedTab: 0
    };
  },
  mounted() {
    const pathToTabMap = {
      'hr-tools/skill-extraction': 0,
      'hr-tools/create-skill-profile': 1,
      'hr-tools/talent-architecture': 2,
      'hr-tools/future-of-work': 3
    };

    this.selectedTab = pathToTabMap[Object.keys(pathToTabMap).find((path) => this.$route.path.includes(path))] || 0;
  },
  methods: {
    onTabClick(index) {
      this.selectedTab = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.hr-tools {
  max-width: 1700px;
  margin: 3rem 110px;
  @media screen and (max-width: 1264px) {
    margin: 2rem;
  }
  h2 {
    @include header-font-3;
    color: #1f3370;
    margin: 0 0 3rem 0;
  }
  .tab-container {
    border-bottom: 2px solid #adadad;
    display: flex;
    gap: 2rem;
    margin: 0 0 2rem 0;
    a {
      text-decoration: unset;
      padding: 0 0 1rem 0;
      @include body-font-bold-1;
      color: #2d2d2d;
    }
    .selected {
      @include body-font-bold-1;
      border-bottom: 4px solid #00d1b0;
    }
  }
}
</style>
