<template>
  <v-card class="card">
    <v-container>
      <v-row class="centered">
        <v-col class="centered">
          <v-card-text class="banner centered">
            <div class="center-item">{{ $t('uploadExcel.importOrganizationStructure') }}</div>
          </v-card-text>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="upload-prompt">
          {{ $t('uploadExcel.requiredMessage') }}
          <br />{{ $t('uploadExcel.clickForTemplate[0]') }} <span class="template" @click="onDownloadTemplate">{{ $t('uploadExcel.clickForTemplate[1]') }}</span> {{ $t('uploadExcel.clickForTemplate[2]') }}
        </v-col>
      </v-row>
      <v-row class="centered">
        <v-col class="centered">
          <span class="container">
            <input ref="excel-upload-input" class="excel-upload-input" type="file" accept=".xlsx, .xls" @change="handleClick" />
            <div class="drop" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover">
              <div>{{ $t('uploadExcel.uploadExcelHere') }}</div>
              <button class="button" @click="handleUpload">{{ $t('uploadExcel.browse') }}</button>
            </div>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-if="isUploading" style="text-align: center; font-size: 30px">{{ $t('uploadExcel.uploading') }}</div>
          <div v-if="isError" role="alert" class="error">{{ errorMsg }}</div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import XLSX from 'xlsx';
import { mapGetters } from 'vuex';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'UploadExcel',
  props: {
    beforeUpload: Function,
    onSuccess: Function,
    uploadType: { type: String, required: false }
  },
  computed: {
    ...mapGetters(['company'])
  },
  data() {
    return {
      token: '',
      isUploading: false,
      loading: false,
      displayData: false,
      excelData: {
        header: null,
        results: null
      },
      errorMsg: 'Error! Upload was unsuccessful',
      isError: false
    };
  },
  methods: {
    generateData({ header, results }) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$message.error(translate('uploadExcel.onlyOneFile'));
        return;
      }
      const rawFile = files[0];

      if (!this.isExcel(rawFile)) {
        this.$message.error(translate('uploadExcel.onlyAllowedFormats'));
        return false;
      }
      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleUpload() {
      const browserObj = { c: 'Integrate', l: translate('uploadExcel.browse'), a: 'click' };
      analytics.setVarAndTrack(browserObj);
      this.$gtag.event('Integrate', {
        event_category: 'click',
        event_label: 'Browse',
        value: 1
      });
      this.$refs['excel-upload-input'].click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0];
      if (!rawFile) return;
      const uploadObj = { c: 'Integrate', l: 'Excel upload', a: 'click' };
      analytics.setVarAndTrack(uploadObj);
      this.$gtag.event('Integrate', {
        event_category: 'click',
        event_label: 'Excel upload' + rawFile.name,
        value: 1
      });
      this.upload(rawFile);
    },
    async upload(rawFile) {
      this.isUploading = true;
      this.$refs['excel-upload-input'].value = null;
      const form = new FormData();
      form.append('file', rawFile);
      const payload = { form: form };
      await this.$planAdmin
        .uploadOrganizationStructure(payload, this.uploadType)
        .then((response) => {
          this.isUploading = false;
          if (response.error) {
            this.errorMsg = response.message;
            this.isError = true;
            return;
          }
          this.$router.push({ name: 'OrgDetails', query: { uploadDone: true } });
        })
        .catch((error) => {
          this.isUploading = false;
          this.errorMsg = error.message;
          this.isError = true;
        });
    },
    readerData(rawFile) {
      this.displayData = false;
      this.loading = true;
      const self = this;
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          this.generateData({ header, results });
          setTimeout(function () {
            self.loading = false;
            self.displayData = true;
            self.$router.push('/plan-org-details');
            resolve();
          }, 1500);
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet['!ref']);
      let C;
      const R = range.s.r;
      for (C = range.s.c; C <= range.e.c; ++C) {
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        let hdr = 'UNKNOWN ' + C;
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    },
    async onDownloadTemplate() {
      const blob = await this.$planAdmin.downloadOrganizationStructureTemplate();
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.setAttribute('download', 'org-template.xlsx');
      a.click();
    }
  }
};
</script>

<style scoped lang="scss">
.centered {
  height: 100%;
  display: flex;
  align-items: center;
}
.card {
  height: 100%;
  min-height: 500px;
  border-radius: 10px;
  margin-top: 20px;
}
.banner {
  margin: 0px 0px;
  justify-content: center;
  font-size: 18px;

  .left-item {
    float: left;
    color: #dd5217;
    font-weight: bold;
  }

  .center-item {
    float: left;
    color: #dd5217;
    font-weight: bold;
    width: inherit;
    text-align: center;
    text-transform: uppercase;
  }

  .right-item {
    float: right;
    color: #1f3370;
  }
}

.button {
  background-color: #ffb92e;
  color: #1f3370;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02px;
  font-weight: 600;
  font-family: 'Futura';
  margin: 0;
  border: none;
  padding: 0 30px;
  height: 40px;
  border-radius: 20px;
  line-height: 40px;
}
.excel-upload-input {
  display: none;
  z-index: -9999;
}

.container {
  width: 100%;
}

.drop {
  border: 2px dashed #bbb;
  width: 50%;
  line-height: 80px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}

.upload-prompt {
  text-align: center;
  font-size: 20px;
}

a {
  text-decoration: none;
  color: $color-data-2;
}

.error {
  margin: 0px 100px;
  font-size: 25px;
  text-align: center;
  color: $color-white;
}

.template {
  cursor: pointer;
  color: #dd5217;
}
</style>
