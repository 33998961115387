import { loginFlow } from '@/utils';
import localforage from 'localforage';
import store from '@/store';
import { createRouter, createWebHistory } from 'vue-router';

export const getRouter = (app) => {
  const planAdmin = app.config.globalProperties.$planAdmin;
  const routes = [
    {
      path: '/',
      name: 'Welcome',
      component: () => import('@/views/welcome/Welcome')
    },
    {
      path: '/maintenance',
      name: 'Maintenance',
      component: () => import('@/views/Maintenance')
    },
    {
      path: '/home',
      name: 'Home',
      redirect: '/role-explorer'
    },
    {
      path: '/enrollment',
      name: 'Enrollment',
      component: () => import('@/views/insights/Enrollments')
    },
    {
      path: '/usage',
      name: 'Usage',
      component: () => import('@/views/insights/Usage')
    },
    {
      path: '/looker-dashboard',
      name: 'LookerDashboard',
      component: () => import('@/views/insights/LookerDashboard')
    },
    {
      path: '/content',
      name: 'Content',
      component: () => import('@/views/insights/ContentView')
    },
    {
      path: '/trending',
      name: 'Trending',
      component: () => import('@/views/insights/Trending')
    },
    {
      path: '/required-learning',
      name: 'Required Learning',
      component: () => import('@/views/insights/RequiredLearning'),
      beforeEnter: async (to, from, next) => {
        const tenantSettings = await localforage.getItem('my_tenant_settings');
        tenantSettings['required-learning'] ? next() : next('/enrollment');
      }
    },
    {
      path: '/assessment/:id',
      name: 'AssessmentDetail',
      component: () => import('@/views/assessments/components/AssessmentsDetail')
    },
    {
      path: '/assessments/:page?',
      name: 'Assessments',
      component: () => import('@/views/assessments/Assessments'),
      beforeEnter: async (to, from, next) => {
        const email = (await localforage.getItem('my_username')) || this.username;
        const pwcEmail = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(pwc)\.com$/g;
        pwcEmail.test(email) ? next() : next('/plan');
      }
    },
    {
      path: '/plan-import-org',
      name: 'PlanImportOrg',
      component: () => import('@/views/plan/PlanImportOrg')
    },
    {
      path: '/plan-org-details',
      name: 'OrgDetails',
      component: () => import('@/views/plan/OrgDetails')
    },
    {
      path: '/privacy',
      name: 'Privacy',
      component: () => import('@/views/Privacy'),
      meta: {
        window: true
      }
    },
    {
      path: '/cookie-notice',
      name: 'CookieNotice',
      component: () => import('@/views/CookieNotice'),
      meta: {}
    },
    {
      path: '/terms',
      name: 'Terms',
      component: () => import('@/views/Terms')
    },
    {
      path: '/help-center',
      name: 'HelpCenter',
      component: () => import('@/views/HelpCenter')
    },
    {
      path: '/catalog',
      name: 'Catalog',
      component: () => import('@/views/catalog/Catalog'),
      children: [
        {
          path: 'landing',
          name: 'CatalogLanding',
          component: () => import('@/views/catalog/CatalogLanding'),
          props: true
        },
        {
          path: 'stepper',
          name: 'CommonStepper',
          component: () => import('@/views/catalog/components/CommonStepper'),
          children: [
            {
              path: 'create',
              name: 'CreateTrack',
              //component: () => import('@/views/catalog/components/EditDetails'),
              component: () => import('@/views/catalog/components/CreateEditTrack'),
              props: true
            },
            {
              path: 'edit',
              name: 'EditContent',
              component: () => import('@/views/catalog/components/EditContent'),
              props: true
            },
            {
              path: 'organize',
              name: 'OrganizeLearningTrack',
              component: () => import('@/views/catalog/components/OrganizeLearningTrack')
            },
            {
              path: 'manage',
              name: 'ManagePeople',
              component: () => import('@/views/catalog/components/ManagePeople'),
              props: true
            },
            {
              path: 'edit-due-dates',
              name: 'EditDueDates',
              component: () => import('@/views/catalog/components/EditDueDates')
            }
          ]
        }
      ]
    },
    {
      path: '/role-explorer',
      name: 'RoleExplorer',
      component: () => import('@/views/plan/components/RoleExplorer/RoleExplorer'),
      children: [
        {
          name: 'RoleAdditionRoute',
          path: 'create-role',
          component: () => import('@/views/plan/components/RoleExplorer/RoleAddition')
        },
        {
          name: 'RoleDetails',
          path: ':guid',
          component: () => import('@/views/plan/components/RoleExplorer/RoleDetails')
        }
      ]
    },
    { name: 'WorkforcePlanner', path: '/workforce-planner', component: () => import('@/views/plan/components/WorkforcePlanner/WorkforcePlanner') },
    {
      path: '/manage-submissions',
      name: 'ManageSubmissions',
      component: () => import('@/views/manage-submissions/ManageSubmissions')
    },
    {
      path: '/admin-planner/:tab',
      name: 'AdminPlanner',
      component: () => import('@/views/adminPlanner/AdminPlanner')
    },
    {
      path: '/hr-tools/:tab',
      name: 'HrTools',
      component: () => import('@/views/hrTools/HrTools')
    },
    {
      path: '/asset-review-details/:id',
      name: 'AssetReviewDetail',
      component: () => import('@/views/manage-submissions/ManageReviewDetail')
    },
    {
      path: '/asset-edit-form/:id',
      name: 'AssetEditForm',
      component: () => import('@/views/manage-submissions/ManageReviewDetail')
    },
    {
      path: '/settings/:tab',
      name: 'Settings',
      component: () => import('@/views/settings/Settings')
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('@/views/404'),
      beforeEnter: async (to, from, next) => {
        const tenantSettings = await localforage.getItem('my_tenant_settings');
        if (tenantSettings && tenantSettings['ui-insight-display'] == true) {
          next('/enrollment');
        } else {
          next('/role-explorer');
        }
      }
    }
  ];
  const router = createRouter({ history: createWebHistory(), base: process.env.BASE_URL, routes });

  router.beforeEach(async (to, from, next) => {
    const response = await planAdmin.getFeatureFlags();
    const enabledFeatureFlags = response.features || {};
    const showMaintenance = enabledFeatureFlags.filter((el) => el.name == 'plan_maintenance-page');
    const underMaintenance = showMaintenance.length > 0 ? showMaintenance[0]['enabled'] : false;
    // if under maintenance - catch it at every route change and log out user

    if (underMaintenance && to.path != '/maintenance') {
      app.config.globalProperties.EventBus.emit('log-out');
      window.location.href = '/maintenance';
      return;
    }

    window.scrollTo(0, 0);
    app.config.globalProperties.$checkVersion();
    browserNavsCheck(to, from);
    if (to.query.ProAuthSSO || to.query.ProAuthSSOError) {
      const sso = JSON.parse(decodeURIComponent(escape(window.atob(to.query.ProAuthSSO))));
      if (sso && sso.accessToken) {
        await loginFlow({ planAdmin: planAdmin, token: sso.accessToken, trustedDeviceId: sso.trustedDeviceId });
        app.config.globalProperties.$startSessionTimer();
        next(to.path);
      } else if (sso && sso.error) {
        const error = encodeURIComponent(JSON.stringify(sso));
        next(`/?ProAuthSSOError=${error}`);
      } else {
        next('/');
      }
    }

    let ssoCookieAvailable = document.cookie && document.cookie.includes('ProAuthSSO=') ? true : false;
    if (to.name === 'Terms' && ssoCookieAvailable) {
      next();
    } else if (to.name === 'Welcome' && ssoCookieAvailable) {
      ssoCookieAvailable = false;
    }
    const sso = ssoCookieAvailable ? await setupSSO() : null;
    if (sso && sso.error) {
      const error = encodeURIComponent(JSON.stringify(sso));
      next(`/?ProAuthSSOError=${error}`);
    } else if (sso && sso.mustAcceptTerms) {
      next('/');
    }

    let token = sso && sso.accessToken ? sso.accessToken : await localforage.getItem('my_access_token');

    const whitelistRoutes = ['Welcome', 'Terms', 'Privacy', 'HelpCenter', 'CookieNotice', 'Maintenance'];
    if (!token && !whitelistRoutes.includes(to.name)) {
      next('/');
    }

    const tokenExpired = jwtTokenExpired(token);
    const toPath = to.path;
    if (toPath !== '/' && !whitelistRoutes.includes(to.name) && (!token || (token && tokenExpired))) {
      next(`?redirect=${toPath}`);
    } else {
      next();
    }
  });

  router.afterEach(async () => {
    const isAutoRefresh = await store.getters.enabledFeatureFlags['plan_refresh_automatic-reload'];
    const isNewVersion = await app.config.globalProperties.$isNewVersion();
    if (isNewVersion && isAutoRefresh) {
      app.config.globalProperties.$loadVersion();
      window.location.reload();
    }
  });

  async function setupSSO() {
    if (!document.cookie.includes('ProAuthSSO=')) {
      return null;
    }
    let cookie = document.cookie
      .replace(/; /g, ';')
      .split(';')
      .find((row) => row.startsWith('ProAuthSSO='));
    cookie = cookie ? cookie.split('=')[1] : null;
    const sso = cookie ? JSON.parse(decodeURIComponent(cookie)) : null;
    if (sso && sso.accessToken) {
      const user = await loginFlow({ planAdmin: planAdmin, token: sso.accessToken, trustedDeviceId: sso.trustedDeviceId });
      app.config.globalProperties.$startSessionTimer();
      if (!user.hasAcceptedTerms) {
        //wrap the following inside feature flag
        if (store?.getters?.enabledFeatureFlags['plan_sso_accept_terms']) {
          const isRegistration = await localforage.getItem('sso-user-registration');
          const isLogin = await localforage.getItem('sso-user-login');
          if (isRegistration && !isLogin) {
            await planAdmin.ssoUserAcceptTerms();
            await localforage.removeItem('sso-user-registration');
            return { mustAcceptTerms: false };
          } else if (!isRegistration && isLogin) {
            await localforage.removeItem('sso-user-login');
            this.EventBus.emit('show-registration-popup');
            return { mustAcceptTerms: true };
          }
        }
      } else {
        app.config.globalProperties.$startSessionTimer();
        if (store?.getters?.enabledFeatureFlags['plan_sso_accept_terms']) {
          await localforage.removeItem('sso-user-registration');
          await localforage.removeItem('sso-user-login');
        }
      }
    }
    return sso;
  }
  /*******
   * Fn checks for click of browserNavs (browser back and forward buttons) or forceful navigation
   * In case of browserNavs, no change to be done
   * In case of forceful Navigation, clear the respective state variables
   */
  async function browserNavsCheck(to, from) {
    if (app.config.globalProperties.$browserNavClicked) {
      app.config.globalProperties.$browserNavClicked = false;
    } else if (from.path !== '/') {
      if (!store.state.plan.clearSearchResult & !to.path.includes('/role-explorer')) {
        await store.dispatch('plan/setClearSearchResult', true);
      }
      if (store.state.plan.selectedProfile) {
        await store.dispatch('plan/setSelectedProfile', false);
        await store.dispatch('plan/resetSelectedProfile', true);
      }
      if (store.state.plan.selectedFunc) {
        await store.dispatch('plan/setSelectedFunc', null);
      }
      if (store.state.plan.selectedDept) {
        await store.dispatch('plan/setSelectedDept', null);
      }
    }
  }
  //

  /**
   *
   * @param {JWT token to validate the expiry} token
   * @returns {true or false}
   */
  function jwtTokenExpired(token) {
    let expired = false;
    if (token != null) {
      const tokenObj = JSON.parse(atob(token.split('.')[1]));
      if (tokenObj['exp']) {
        expired = tokenObj['exp'] < Math.floor(Date.now() / 1000, 2);
      }
    }
    return expired;
  }

  return router;
};
