export function reverseConversion(list) {
  return list.map((item) => {
    return convertItem(item)
  })
}

export function convertItem(item) {
  item.format = { name: item.formatType }
  item.provider = { name: item.provider }
  item.fieldOfStudy = item.fieldOfStudy && item.fieldOfStudy.length ? item.fieldOfStudy.split(',') : []
  item.emsiSkills = item.skills && item.skills.length ? item.skills.split(',') : []
  item.levels = item.levels && item.levels.length ? item.levels.split(',') : []
  delete item.formatType
  delete item.skills
  return item
}
