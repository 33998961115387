<template>
  <v-row v-if="viz">
    <v-col :class="type === 'error' ? 'error' : type === 'warning' ? 'warning' : 'success'">
      <v-btn icon x-small class="close-btn" @click="onCloseNotification">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      {{ display.msg }}
      <div v-if="type === 'error' && cta !== ''">
        Click <span @click="$router.push(ctaDisplay.push)" :style="ctaDisplay.style">here</span>
        {{ ctaDisplay.text }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Notification',
  emits: ['on-close-notification'],
  props: {
    viz: { type: Boolean, required: true, default: false },
    type: { type: String, required: true, default: 'error' },
    msg: { type: String, required: true, default: '' },
    cta: { type: String, required: false, default: '' }
  },
  data() {
    return {
      display: { visible: this.viz, msg: this.msg },
      ctaDisplay: { push: '', style: '', text: '' },
      callToActions: {
        reuploadOrg: {
          push: '/plan-org-details',
          style: 'cursor: pointer; font-style: oblique; text-decoration: underline',
          text: 'to re-upload the organization structure'
        }
      }
    };
  },
  created() {
    if (this.cta !== '') {
      this.ctaDisplay = this.callToActions[this.cta];
    }
  },
  methods: {
    onCloseNotification() {
      this.display.msg = '';
      this.display.visible = false;
      this.$emit('on-close-notification');
    }
  }
};
</script>

<style lang="scss" scoped>
.error,
.warning,
.success {
  margin: 20px;
  font-size: 25px;
  text-align: center;
  color: $color-white;
  border-radius: 15px;
  padding-top: 15px;
}

.close-btn {
  position: relative;
  top: 0rem;
  float: right;
  width: 25px;
  height: 25px;
  background: $color-white !important;
  color: $color-data-2 !important;
}

.close-btn-success {
  position: relative;
  top: 0rem;
  float: right;
  width: 25px;
  height: 25px;
  background: $color-white !important;
  color: $color-success !important;
}
</style>
