<template>
  <div class="modal-container">
    <v-dialog v-model="isRegister" max-width="500px" activator="parent" transition="none">
      <template v-slot:activator="{ props }">
        <v-btn id="nav_bar-register-button" name="nav_bar-register-button" color="#ffb92e" rounded dark v-bind="props" class="black--text btn">{{ $t('button.register') }}</v-btn>
      </template>
      <v-card class="register-modal">
        <v-toolbar role="presentation" flat>
          <v-toolbar-title><img alt="pro edge" src="@/assets/logos/Proedge_Logo.svg" style="margin: auto; display: block; height: 50px" /></v-toolbar-title>
          <v-toolbar-items>
            <v-btn color="error" aria-label="close" class="cancel-btn" title="close" icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-alert v-model="isAlert" :type="alertType" border="start" close-text="Close Alert" role="alert" dark dismissible>{{ alertMsg }}</v-alert>
            <img alt="" src="@/assets/images/svgs/FPO_4.svg" class="modal-image" />
            <v-row>
              <v-col cols="12" class="form-field">
                <v-text-field v-model="email" @keyup="checkSSOByEmail" :label="$t('common.titleCase.email')" variant="underlined" autofocus required></v-text-field>
              </v-col>
              <v-col cols="12" class="form-field" v-if="showPasswordField">
                <v-text-field v-model="password" :label="$t('common.titleCase.password')" variant="underlined" required :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (showPwd = !showPwd)" :type="showPwd ? 'text' : 'password'"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <div class="privacy-terms-container">
            <input type="checkbox" id="termsAndConditionsCheckbox" v-model="has_accepted_terms" class="privacy-terms-checkbox" />
            <i18n-t class="privacy-terms" keypath="phrase.register.agreeAndAcknowledge" tag="label" for="termsAndConditionsCheckbox">
              <a class="terms" to="terms-and-conditions" @click="goToTerms" href="javascript:void(0)">{{ $t('legal.terms') }}</a>
              <a class="privacy" to="privacy-policy" @click="goToPrivacy" href="javascript:void(0)">{{ $t('legal.privacy') }}</a>
              <a class="cookie-notice" to="cookie-notice" @click="goToCookieNotice" href="javascript:void(0)">{{ $t('legal.cookie') }}</a>
            </i18n-t>
          </div>
        </v-card-text>
        <v-row class="text-center">
          <v-col cols="12">
            <v-btn class="modal-btn" color="#ffb92e" rounded @click="onRegister" :disabled="isRegistering || !has_accepted_terms">{{ $t('button.register') }}</v-btn>
          </v-col>
          <v-col class="disclaimer" style="padding: 1rem 0">
            <div>{{ $t('phrase.disclaimer.nonRepresentative') }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isActivate" max-width="500px">
      <v-card class="register-modal">
        <v-toolbar role="presentation" flat>
          <v-toolbar-title><img src="@/assets/logos/Proedge_Logo.svg" style="margin-top: 1rem; height: 50px" /></v-toolbar-title>
          <v-toolbar-items>
            <v-btn color="error" class="cancel-btn" icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-alert v-model="isAlert" :type="alertType" border="start" close-text="Close Alert" role="alert" dark dismissible>{{ alertMsg }}</v-alert>
            <img src="@/assets/images/svgs/FPO_4.svg" class="modal-image" />
            <v-row>
              <v-col cols="12" class="form-field">
                <v-card-text>{{ $t('phrase.otp') }}</v-card-text>
              </v-col>
              <v-col cols="12" class="form-field">
                <v-text-field v-model="vericode" :label="$t('common.titleCase.verificationCode')" autofocus required @keypress.enter="onActivate"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row class="text-center">
          <v-col cols="12">
            <v-btn class="modal-btn" color="#ffb92e" rounded @click="onActivate">{{ $t('button.activate') }}</v-btn>
          </v-col>
          <v-col class="disclaimer" style="padding: 1rem 0">
            <div>{{ $t('phrase.disclaimer.nonRepresentative') }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isInfo" max-width="500px">
      <v-card class="register-modal">
        <v-card-text>
          <v-container>
            <v-alert v-model="isAlert" :type="alertType" border="start" close-text="Close Alert" role="alert" dark dismissible>{{ alertMsg }}</v-alert>
            <img src="@/assets/images/svgs/FPO_4.svg" class="modal-image" />
            <v-row>
              <v-col cols="12" class="form-field">
                <v-card-text class="text">{{ $t('phrase.register.welcome') }}</v-card-text>
                <v-card-text class="text">{{ $t('phrase.register.canLogin') }}</v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row class="text-center">
          <v-col cols="12">
            <v-btn class="modal-btn" color="#ffb92e" rounded @click="onOK">{{ $t('button.ok') }}</v-btn>
          </v-col>
          <v-col class="disclaimer" style="padding: 1rem 0">
            <div>{{ $t('phrase.disclaimer.nonRepresentative') }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import localforage from 'localforage';
import { getCloudEnvFeatureFlag, checkMaintenanceFlag } from '@/utils';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'RegisterModal',
  data() {
    return {
      isRegister: false,
      isRegistering: false,
      isActivate: false,
      isInfo: false,
      isAlert: false,
      alertMsg: translate('registerModal.enterValidUsernamePassword'),
      alertType: 'error',
      showPwd: false,
      showPasswordField: false,
      sso: null,
      email: '',
      password: '',
      vericode: '',
      cloudEnv: {},
      has_accepted_terms: false
    };
  },
  async mounted() {
    if (this?.$store?.getters?.enabledFeatureFlags['plan_sso_accept_terms']) {
      this.EventBus.on('show-registration-popup', () => {
        setTimeout(() => {
          this.isRegister = true;
        }, 1000);
      });
    }
  },
  methods: {
    ...mapActions('user', ['registerUser', 'activateUser']),
    ...mapActions('environment', ['setCloudEnvInfo', 'setUserConfig', 'setCompanyConfig', 'setFlagConfig']),
    isValidEmail(email) {
      // eslint-disable-next-line
      const pattern = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,5}$/;
      return pattern.test(email);
    },
    isValidPassword(password) {
      const pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
      return pattern.test(password);
    },
    async checkSSOByEmail() {
      // eslint-disable-next-line
      const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.email = this.email.trim();
      if (emailPattern.test(this.email)) {
        const isCloudEnvFeatureFlagSet = await getCloudEnvFeatureFlag();
        if (isCloudEnvFeatureFlagSet) {
          await this.setCloudEnvData();
        }
        this.sso = await this.$planAdmin.checkSSOByEmail({ email: this.email });
        this.showPasswordField = !(this.sso && this.sso.isSSOLogin);
        if (this.sso.isSSOLogin) {
          await localforage.setItem('ssoRedirect', this.sso['sso-url']);
        }
      }
    },
    async onRegister() {
      if (checkMaintenanceFlag()) return;
      this.isRegistering = true;
      await this.checkSSOByEmail();
      if (this.sso && this.sso.isSSOLogin) {
        if (this.$store.getters.enabledFeatureFlags['plan_sso_accept_terms']) {
          await localforage.setItem('sso-user-registration', true);
        }
        this.isRegistering = false;
        const ssoURL = this.sso['sso-url'];
        window.location.href = `${ssoURL}?referring_url=${window.location.origin}/role-explorer`;
        return;
      }
      const payload = { email: this.email, password: this.password, username: this.email, hasAcceptedTerms: this.has_accepted_terms };
      if (!this.isValidEmail(this.email)) {
        this.isRegistering = false;
        const error = translate('registerModal.enterValidEmail');
        this.alert('error', error);
        return;
      }
      if (!this.isValidPassword(this.password)) {
        this.isRegistering = false;
        const error = translate('registerModal.passwordReqHint');
        this.alert('error', error);
        return;
      }
      await this.$planAdmin
        .registerUser(payload)
        .then((response) => {
          if (response.error) {
            throw response.message;
          }
          this.isAlert = false;
          this.isRegister = false;
          this.isActivate = true;
        })
        .catch((error) => {
          this.alert('error', error);
        });
    },
    onClose() {
      this.email = '';
      this.password = '';
      this.vericode = '';
      this.sso = null;
      this.isAlert = false;
      this.isRegister = false;
      this.isRegistering = false;
      this.isActivate = false;
      this.isInfo = false;
      this.showPasswordField = false;
    },
    async onActivate() {
      const payload = { email: this.email, verificationCode: this.vericode.trim() };
      await this.$planAdmin
        .activateUser(payload)
        .then((response) => {
          if (response.userVerified) {
            this.isInfo = true;
            this.isActivate = false;
            this.vericode = '';
            this.alert('success', translate('registerModal.passwordReqHint'));
          } else {
            throw response.message;
          }
        })
        .catch((error) => {
          this.alert('error', error);
        });
    },
    onOK() {
      this.email = '';
      this.password = '';
      this.vericode = '';
      this.sso = null;
      this.isAlert = false;
      this.isInfo = false;
      this.isRegistering = false;
      this.showPasswordField = false;
    },
    alert(type, message) {
      if (message !== null && message !== undefined && message !== '') {
        this.alertMsg = message;
      }
      this.alertType = type;
      this.isAlert = true;
    },
    async setCloudEnvData() {
      const response = process.env.VUE_APP_USE_FFAPI === 'true' ? await this.$planAdmin.getCloudEnvByUserEmailAddress(this.email) : await this.$planAdmin.getCloudEnvFromDefaultEnv(this.email);
      if (!response.data || !response.data.cloudEnv) {
        return false;
      }
      await this.setCloudEnvInfo(response.data.cloudEnv);
      await this.$planAdmin.updateEnvFromLocalForage();

      this.setUserConfig(response.data.config.user);
      this.setCompanyConfig(response.data.config.company);
      this.setFlagConfig(response.data.config.flags);
      return true;
    },
    goToPrivacy() {
      window.open('https://privacyhub.pwc.com/us/en/proedge/privacy-policy.html', '_blank');
    },
    goToCookieNotice() {
      window.open('https://privacyhub.pwc.com/us/en/proedge/cookie-notice.html', '_blank');
    },
    goToTerms() {
      window.open('https://productcentral.products.pwc.com/legal/Terms-of-Use', '_blank');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-btn {
  min-width: 80px !important;

  @media screen and (min-width: 600px) {
    min-width: 100px !important;
  }
}

.register-modal {
  overflow-x: hidden;
  padding-bottom: 20px;
  align-content: center;
}

.form-field {
  margin: 0;
  padding: 0px 10px;

  .text {
    font-size: 1.1rem;
    margin: 10px 10px;
    padding: 0;
    text-align: center;
  }
}

.v-toolbar {
  background: transparent;
}

.form-field-indicate {
  font-size: 15px;
}

.v-toolbar__title {
  margin-left: 32%;
  font-weight: bold;
  margin-top: 5px;
  color: $color-primary;

  @media screen and (max-width: 432px) {
    margin-left: initial;
  }
}

.v-card-text {
  text-align: center;
}

.cancel-btn {
  position: absolute;
  top: 0;
  right: 20px;
}

.v-btn {
  text-transform: capitalize !important;
}

.modal-image {
  width: 100%;
  height: 200px;
  position: relative;
  top: -15px;
}

.disclaimer {
  padding: 0;
  div {
    padding: 0 10%;
    font-size: 0.6rem;
    color: $color-grey-dark;
  }
}

.privacy-terms-container {
  text-align: left;
  padding: 0 3%;
  margin: 0.9375rem 0 0.3125rem 0;
  display: flex;

  .privacy-terms {
    flex: 1;
  }
}
.privacy-terms-checkbox {
  margin-right: 0.625rem;
  height: 1.25rem;
}
</style>
