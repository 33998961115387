<template>
  <div id="app">
    <Layout />
    <LoginTimer v-if="showDebugTimer" />
    <div id="navigation-announcer" role="status" aria-live="polite" tabindex="-1"></div>
  </div>
</template>

<script>
import Layout from '@/layouts/Layout';
import { mapGetters } from 'vuex';
import LoginTimer from './views/LoginTimer.vue';
export default {
  ...mapGetters(['enabledFeatureFlags']),
  components: { Layout, LoginTimer },
  data() {
    return {
      showDebugTimer: process.env.VUE_APP_SHOW_DEBUG_LOGIN_TIMER && process.env.VUE_APP_SHOW_DEBUG_LOGIN_TIMER.trim() === 'true'
    };
  }
};
</script>
<style scoped></style>
