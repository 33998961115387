<template>
  <div class="employee-data">
    <EmployeeList />
  </div>
</template>

<script setup>
import EmployeeList from './EmployeeList';
</script>

<style lang="scss">
.employee-data {
  display: flex;
  gap: 3rem;
}
</style>
