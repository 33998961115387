<template>
  <div class="employee-container" v-if="dataReady">
    <div class="employee-list-container">
      <h2>{{ $t('admin_planner.employee_list') }}</h2>
      <div>
        <v-text-field
          id="search-user"
          class="search-user"
          data-el="searchUser"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          ref="searchInput"
          :placeholder="$t('admin_planner.search_placeholder')"
          :value="query"
          flat
          filled
          solo
          rounded
          dense
          clearable
          v-model="query"
          variant="solo"
          @keypress.enter="searchEmployee"
          @click:clear="clearSearch"
        >
        </v-text-field>
      </div>
      <div class="user-type-container">
        <v-checkbox v-for="(userType, index) in userTypes" :key="index" :label="userType.name" :value="userType.value" v-model="selectedUserTypes" :ripple="false" color="#1F3370" style="display: flex"></v-checkbox>
      </div>
      <div id="scrollable-content-data" class="scrollable-content" @scroll.prevent="scrollNext">
        <div v-for="(employee, index) in employeeList" :key="index" class="employee" :class="selectedUserIndex === index ? 'selected' : ''" @click="onUserClick(employee, index)">
          {{ employee.name }} <span v-if="employee.roles">{{ getAdminType(employee) }}</span>
        </div>
      </div>
    </div>
    <div>
      <EmployeeDetails v-if="employeeData" :employee="employeeData" />
    </div>
  </div>
  <div v-else class="loader-admin-planner t-flex t-justify-center t-items-center">
    <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" :alt="$t('common.titleCase.loadingLearningTrack')" />
  </div>
</template>

<script>
import { ref } from 'vue';

import EmployeeDetails from './EmployeeDetails';
import { translate } from '@/plugins/i18n.js';
export default {
  setup() {
    const employeeList = ref([]);
    const nextEmployeeListIsEmpty = false;
    const query = ref('');
    const selectedUserIndex = ref(0);
    const employeeData = ref();
    const dataReady = ref(false);
    const pageNo = ref(1);
    const userTypes = ref([
      { name: translate('admin_planner.admin_type_options.shortOption1'), value: 'GROUP_ADMIN' },
      { name: translate('admin_planner.admin_type_options.shortOption2'), value: 'GLOBAL_ADMIN' }
    ]);
    const selectedUserTypes = ref([]);
    return {
      employeeList,
      nextEmployeeListIsEmpty,
      query,
      selectedUserIndex,
      employeeData,
      pageNo,
      dataReady,
      userTypes,
      selectedUserTypes
    };
  },
  components: { EmployeeDetails },
  watch: {
    async selectedUserTypes() {
      this.dataReady = false;
      this.pageNo = 1;
      await this.setUp();
    }
  },
  async mounted() {
    await this.setUp();
  },
  methods: {
    async setUp() {
      this.selectedUserIndex = 0;
      this.employeeList = await this.getEmployeeList(this.pageNo, this.query);
      this.nextEmployeeListIsEmpty = this.employeeList.length === 0;
      this.employeeData = this.employeeList[0];
      const firstChar = this.employeeData.firstName != '' ? this.employeeData.firstName[0] : '';
      const lastChar = this.employeeData.lastName != '' ? this.employeeData.lastName[0] : '';
      this.employeeData.shortName = `${firstChar}${lastChar}`;
      this.dataReady = true;
    },
    onUserClick(employee, index) {
      this.employeeData = {};
      this.selectedUserIndex = index;
      this.employeeData = employee;
      const firstChar = this.employeeData.firstName != '' ? this.employeeData.firstName[0] : '';
      const lastChar = this.employeeData.lastName != '' ? this.employeeData.lastName[0] : '';
      this.employeeData.shortName = `${firstChar}${lastChar}`;
    },
    async scrollNext() {
      try {
        const scrollEle = document.getElementById('scrollable-content-data');
        const bottomOfWindow = scrollEle.clientHeight + scrollEle.scrollTop >= scrollEle.scrollHeight - 1;
        if (bottomOfWindow) {
          this.pageNo = this.pageNo + 1;
          const nextEmployeeList = !this.nextEmployeeListIsEmpty ? await this.getEmployeeList(this.pageNo, this.query) : [];
          this.nextEmployeeListIsEmpty = nextEmployeeList.length === 0;
          this.employeeList = [...this.employeeList, ...nextEmployeeList];
        }
      } catch (e) {
        const err = new Error(e);
        throw new Error(err.message);
      }
    },
    async getEmployeeList(pageNo, query) {
      try {
        const payload = {
          pageSize: 10,
          pageNo: pageNo,
          query: query,
          userType: this.selectedUserTypes
        };
        return await this.$planAdmin.getAllUsers(payload);
      } catch (e) {
        const err = new Error(e);
        throw new Error(err.message);
      }
    },
    async searchEmployee() {
      this.pageNo = 1;
      this.query = this.query ? this.query.trim() : '';
      this.employeeList = await this.getEmployeeList(this.pageNo, this.query);
      if (this.employeeList.length) {
        this.nextEmployeeListIsEmpty = this.employeeList.length === 0;
        this.selectedUserIndex = 0;
        this.employeeData = this.employeeList[0];
        const firstChar = this.employeeData.firstName != '' ? this.employeeData.firstName[0] : '';
        const lastChar = this.employeeData.lastName != '' ? this.employeeData.lastName[0] : '';
        this.employeeData.shortName = `${firstChar}${lastChar}`;
      } else {
        this.employeeData = null;
      }
    },
    async clearSearch() {
      this.pageNo = 1;
      this.query = '';
      this.employeeList = await this.getEmployeeList(this.pageNo, this.query);
      this.nextEmployeeListIsEmpty = this.employeeList.length === 0;
      this.selectedUserIndex = 0;
      this.employeeData = this.employeeList[0];
      const firstChar = this.employeeData.firstName != '' ? this.employeeData.firstName[0] : '';
      const lastChar = this.employeeData.lastName != '' ? this.employeeData.lastName[0] : '';
      this.employeeData.shortName = `${firstChar}${lastChar}`;
    },
    getAdminType(employee) {
      if (employee.roles.includes('GLOBAL_ADMIN')) return `(${translate('admin_planner.admin_type_options.shortOption2')})`;
      if (employee.roles.includes('GROUP_ADMIN')) return `(${translate('admin_planner.admin_type_options.shortOption1')})`;
    }
  }
};
</script>

<style lang="scss">
.employee-container {
  display: flex;
  gap: 3rem;
  .employee-list-container {
    padding: 2rem;
    max-width: 390px;
    background-color: #ffffff;
    border-radius: 24px;
    h2 {
      color: #1f3370;
      @include header-font-1-75;
      margin-bottom: 1rem;
    }

    .search-user {
      margin-bottom: 2rem;
      min-height: 2.625rem !important;
      .v-label {
        font-weight: normal;
        font-family: Arial;
        font-size: 0.875rem;
        color: black;
      }
      .v-field--variant-solo {
        background-color: white !important;
        height: 2.3125rem;
        border: 0.125rem solid #e0e1e1;
        display: flex;
        flex-direction: row-reverse;
        padding: 0 1rem;
      }

      .v-field__field,
      .v-field__prepend-inner {
        height: 2.3125rem;
      }
      .v-field__input {
        min-height: unset;
        padding: 0rem;
        height: inherit;
        height: 2.3125rem;

        input {
          margin: 0px;
          &::placeholder {
            @include body-font-bold-0-875;
            letter-spacing: 0.0006rem;
            color: #5c5c5c;
            opacity: 1;
          }
        }
      }
    }
    .search-user .v-input__slot {
      min-height: 2.625rem !important;
      width: 468px;
    }
    .search-user .mdi-magnify {
      font-size: 1.725rem;
    }

    .employee {
      padding: 1rem;
      @include body-font-1;
      color: #2d2d2d;
      margin-right: 0.5rem;
      cursor: pointer;
      &.selected {
        background-color: #f5f5f5;
        @include body-font-bold-1;
      }
    }

    .scrollable-content {
      height: 500px;
      overflow-y: scroll;
      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #efefef;
        border: 1px solid #efefef;
        border-radius: 4px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #1f3370;
        border-radius: 4px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #1f3370;
      }
    }
  }

  .user-type-container {
    display: flex;
    border-bottom: 2px solid #d6d6d6;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    .v-selection-control__input,
    .v-selection-control__wrapper {
      height: fit-content;
      width: fit-content;
    }
    .v-input__details {
      display: none;
    }
    .v-selection-control .v-label {
      color: #2d2d2d;
      @include body-font-1;
      opacity: 1;
    }
    .v-checkbox .v-selection-control {
      min-height: 2rem !important;
      display: flex;
      gap: 1rem;
      align-items: center;
    }
    .v-selection-control--disabled {
      opacity: 1;
      color: #1f3370;
    }
  }
}
.loader-admin-planner {
  height: 37.5rem;
  width: 100%;
}
</style>
