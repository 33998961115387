peopleListForCSV
<template>
  <div class="csv-download-container">
    <div class="csv-download-content">
      <p>{{ $t('insights.trending.trendingPopularContent') }}</p>
      <json2csv :data="csvData" :labels="getLabelsForCSV()" :title="`${$t('insights.trending.orgTrendingPopularContent')}_${orgName}_${generateCSVTimeFilter(duration)}_${getDateForCSV()}`">
        <span :disabled="Boolean(csvData && csvData.length) || noChartData">
          <v-btn icon class="trendingPopularContentFocus" aria-label="download Trending Popular Content" title="download Trending Popular Content">
            <svg-icon :icon-class="noChartData ? 'CSV_icon_disabled' : 'CSV_icon'" class="t-w-8 t-h-8" />
          </v-btn>
        </span>
      </json2csv>
    </div>
  </div>
</template>
<script>
import JSON2CSV from '@/components/molecules/JSON2CSV';
import localforage from 'localforage';
import { getDateForCSV, generateCSVTimeFilter } from '@/utils';
import { translate } from '@/plugins/i18n.js';

const initialState = () => ({
  isC1: null,
  labelsForCSV: {},
  csvData: [],
  noChartData: false,
  isComponentRefreshed: false
});

export default {
  name: 'TrendingCSVDownload',
  components: { json2csv: JSON2CSV },
  props: {
    orgId: {
      type: String
    },
    orgName: {
      type: String
    },
    duration: {
      type: String,
      default() {
        return 'ALLTIME';
      }
    },
    progress: {
      type: String,
      default() {
        return 'ALL';
      }
    },
    type: {
      type: String,
      default() {
        return 'ALL';
      }
    },
    dateRange: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return initialState();
  },
  async mounted() {
    Object.assign(this.$data, initialState());
    this.$data.labelsForCSV = {
      completeCount: { title: translate('insights.trending.drilldownHeaders.completeCount') },
      progressCount: { title: translate('insights.trending.drilldownHeaders.progressCount') },
      title: { title: translate('insights.trending.drilldownHeaders.title') },
      type: { title: translate('insights.trending.drilldownHeaders.type') }
    };
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
    this.getTrendingData();
    this.isComponentRefreshed = true;
  },
  updated() {
    this.isComponentRefreshed = false;
  },
  watch: {
    orgId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isComponentRefreshed = true;
        this.refreshChart();
      }
    },
    duration: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isComponentRefreshed = true;
        this.refreshChart();
      }
    },
    progress: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isComponentRefreshed = true;
        this.refreshChart();
      }
    },
    type: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isComponentRefreshed = true;
        this.refreshChart();
      }
    },
    dateRange: {
      handler() {
        if (!this.isComponentRefreshed && this.duration) {
          this.refreshChart();
        } else {
          this.isComponentRefreshed = false;
        }
      },
      deep: true
    }
  },
  methods: {
    getDateForCSV,
    generateCSVTimeFilter,
    getLabelsForCSV() {
      if (!this.noChartData) {
        return Object.assign({}, this.labelsForCSV);
      }
    },
    refreshChart() {
      this.getTrendingData();
      this.dataReady = false;
    },
    async getTrendingData() {
      const tenantGuid = await localforage.getItem('my_tenant_guid');

      const payload = {
        tenantGuid: tenantGuid,
        orgId: this.orgId,
        duration: this.duration,
        progress: this.progress,
        type: this.type,
        startDate: this.duration === 'CUSTOM' ? this.moment(this.dateRange.startDate).format('MM-DD-YYYY') : '',
        endDate: this.duration === 'CUSTOM' ? this.moment(this.dateRange.endDate).format('MM-DD-YYYY') : '',
        dateFormat: 'MM-dd-yyyy'
      };
      await this.$planAdmin
        .getDashboardContentTrendingInsights(payload)
        .then((response) => {
          this.dataReady = true;
          if (response && response.contentTitleCountList) {
            this.csvData = response.contentTitleCountList;

            this.dataReady = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
.trendingPopularContentFocus:focus-visible {
  height: 32px;
  width: 32px;
  margin: 2px;
}
.csv-download-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  .csv-download-content {
    display: flex;
    align-items: center;
    p {
      margin: 0rem 1rem 0rem 0rem;
    }
    &:nth-of-type(2) {
      margin-left: 2rem;
    }
  }
}
</style>
