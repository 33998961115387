import axios from 'axios';
import { getMetaData } from '../utils/helper';

export default {
  async getCloudEnvByEmail(url) {
    const { contentTypeHeader } = await getMetaData();
    const response = await axios.get(url, { headers: contentTypeHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  }
};
