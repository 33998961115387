<template>
  <div v-if="dateReady" class="dashboard-report-update-container">
    <div class="header-status">
      <span class="status-info"> <ToolTip :content="getToolTipText()" width="13.8125rem" /> </span>
    </div>
    <div class="dashboard-report-update-text">{{ $t('insights.reportLastUpdated') }} {{ localizedDate(moment, tenantType ? reportLastUpdated : new Date(), true) }}.</div>
  </div>
  <div v-else class="header-placeholder"></div>
</template>

<script>
import ToolTip from '../../../components/molecules/ToolTip.vue';
import localforage from 'localforage';
import { localizedDate } from '@/utils';
import { translate } from '@/plugins/i18n.js';

export default {
  components: { ToolTip },
  data() {
    return {
      reportLastUpdated: new Date(),
      isC1: false,
      tenantType: '',
      dateReady: false
    };
  },
  async mounted() {
    this.dateReady = false;
    const tenantSettings = await localforage.getItem('my_tenant_settings');
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
    this.tenantType = tenantSettings?.['tenant-license-type'];
    const generatedDates = await this.$planAdmin.getReportGenerationDate();
    const date = this.moment(generatedDates.currentDate, 'DD-MM-YYYY');
    this.reportLastUpdated = date.toDate();
    this.dateReady = true;
  },
  methods: {
    localizedDate,
    getToolTipText() {
      return this.isC1 ? (this.tenantType === 'TRIAL' ? translate('insights.reportUpdatedToolTipTextC1Trial') : translate('insights.reportUpdatedToolTipTextC2Paid')) : translate('insights.reportUpdatedToolTipTextC2');
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard-report-update-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;

  .header-status {
    font: normal normal normal 1rem/1rem Arial;
    letter-spacing: 0rem;
    color: #5c5c5c;
    opacity: 1;
    padding-right: 0.625rem;

    .status-info {
      position: relative;
      &:after {
        content: '\24D8';
        font-size: 0.625rem;
        color: #000;
      }
      &:hover {
        .tooltip-text {
          z-index: 999;
          visibility: visible;
          left: -6.9063rem;
        }
      }
    }
  }
  .dashboard-report-update-text {
    padding-top: 0.125rem;
    text-align: left;
    font: normal normal normal 0.75rem/0.875rem Arial;
    letter-spacing: 0rem;
    color: #000000;
    text-transform: capitalize;
  }
}
.header-placeholder {
  min-height: 4.125rem;
}
</style>
