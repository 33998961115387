<template>
  <div>
    <div v-if="userDetailsReady" class="profile">
      <v-row>
        <v-col cols="7">
          <v-card class="profile-card">
            <div class="profile-card-content">
              <div class="t-pb-2 t-font-futura-pt-demi t-text-xl t-text-primary-color" style="margin-bottom: 1rem">{{ $t('workforcePlannerProfile.basicInfo') }}</div>
              <v-row class="profile-row border-bottom" no-gutters>
                <v-col cols="4" class="profile-header">
                  <ProfileHeader :profile-header="profileHeader" :profile-size="70" />
                  <div v-if="!hidden" class="header-follow">
                    <div>
                      <b>{{ numberWithCommas(userDetails.social.followers) }}</b> {{ $t('workforcePlannerProfile.followers') }}
                    </div>
                    <div>
                      <b>{{ numberWithCommas(userDetails.social.followings) }}</b> {{ $t('workforcePlannerProfile.followings') }}
                    </div>
                  </div>
                </v-col>
                <v-col cols="8" class="profile-header" style="text-align: left; padding-left: 0.5rem; margin-top: 0.3rem">
                  <div class="t-font-futura-pt-demi t-text-m t-text-primary-color">{{ userDetails.firstName }} {{ userDetails.lastName }}</div>
                  <div class="t-font-arial t-text-s t-text-demi-gray">{{ $t('workforcePlannerProfile.title') }} {{ userDetails.title }}</div>
                  <div v-if="!isEditUserRole" class="t-font-arial t-text-s t-text-demi-gray">
                    {{ $t('workforcePlannerProfile.learnerRole') }} {{ encodeDecodeRole(userDetails.roleName) }}
                    <v-btn icon color="#ffb92e" style="position: relative; top: -5px; left: 0px" @click="onEditUserRole">
                      <v-icon dark> mdi-pencil </v-icon>
                    </v-btn>
                  </div>
                  <div v-else class="header-edit-title">
                    <SelectView v-model="selectedUserRole" :items="availableUserRoles" item-title="name" item-value="guid" return-object variant="underlined" :placeholder="userDetails.role"></SelectView>
                    <v-btn v-if="selectedUserRole.guid != '' && selectedUserRole.name != userDetails.roleName" icon color="primary" @click="emailDialogOpen = true">
                      <v-icon dark> mdi-check-bold </v-icon>
                    </v-btn>
                  </div>

                  <v-btn v-if="isEditUserEmail" icon color="primary" @click="onEmailEdit" style="float: right; position: relative; top: -8px">
                    <v-icon dark> mdi-check-bold </v-icon>
                  </v-btn>
                  <div v-if="!isEditUserEmail" class="t-font-arial t-text-s t-text-primary-color">
                    {{ userDetails.email }}
                    <div style="display: inline-block" v-if="disableEmailUpdate">
                      <v-btn
                        v-show="tenantType !== 'workday'"
                        v-if="!isEditUserEmail && !(userDetails.engagement.enrolled === 'Onboarded')"
                        icon
                        color="#ffb92e"
                        @click="
                          () => {
                            isEditUserEmail = true;
                          }
                        "
                        style="position: relative; top: -5px"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                      <v-tooltip bottom color="#fff" v-if="userDetails.engagement.enrolled === 'Onboarded'">
                        <template v-slot:activator="{ props }">
                          <v-icon class="edit-email-tooltip" v-bind="props">mdi-help</v-icon>
                        </template>
                        <span style="color: #4b4b4b">{{ $t('workforcePlannerProfile.contactSysadminToChangeEmail') }}</span>
                      </v-tooltip>
                    </div>
                    <div style="display: inline-block" v-else>
                      <v-btn
                        v-show="tenantType !== 'workday'"
                        v-if="!isEditUserEmail"
                        icon
                        color="#ffb92e"
                        @click="
                          () => {
                            isEditUserEmail = true;
                          }
                        "
                        style="position: relative; top: -5px"
                      >
                        <v-icon dark> mdi-pencil </v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <v-text-field v-else v-model="newEmail" :placeholder="emailPlaceholder" @keypress.enter="onEmailEdit" variant="underlined" style="margin: 0; padding: 0"></v-text-field>
                </v-col>
              </v-row>
            </div>

            <div class="t-font-futura-pt-demi t-text-xl t-text-primary-color">{{ $t('workforcePlannerProfile.experience') }}</div>
            <v-row class="profile-row" no-gutters>
              <v-col class="profile-info scrollable" :style="{ 'max-height': experienceHeight }">
                <div v-if="userDetails.experience.length" class="profile-info-item">
                  <v-card v-for="(experience, index) in userDetails.experience" :key="index" class="info-card" flat>
                    <img v-if="experience.companyImage" :src="experience.companyImage" />
                    <div>{{ experience.name }}</div>
                    <div class="info-details">{{ experience.company }}</div>
                    <div v-if="experience.endedAt" class="capitalize">{{ localizedDate(moment, experience.startedAt) }} - {{ localizedDate(moment, experience.endedAt) }}</div>
                    <div v-else class="capitalize">{{ localizedDate(moment, experience.startedAt) }} {{ $t('workforcePlannerProfile.untilPresent') }}</div>
                  </v-card>
                </div>
                <div v-if="!userDetails.experience.length">{{ $t('workforcePlannerProfile.notAvailable') }}</div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card class="profile-card expand">
            <v-row no-gutters>
              <v-col class="profile-engagement border-bottom">
                <button class="invite-button" :class="inviteDisabled ? 'disabled-invite' : ''" :disabled="inviteDisabled" @click="broadcast">
                  <svg-icon v-if="!inviteDisabled" icon-class="Invite_icon" class="t-w-8 t-h-8" />
                  <svg-icon v-else icon-class="Invite_icon_disabled" class="t-w-8 t-h-8" />
                  <span class="t-font-futura-pt-demi t-text-xl t-text-primary-color">{{ $t('workforcePlannerProfile.invite') }}</span>
                </button>
                <div class="t-font-futura-pt-demi t-text-xl t-text-primary-color">{{ $t('workforcePlannerProfile.enrollment') }}</div>
                <v-container fluid style="padding: 10px 5px">
                  <v-row class="engagement-details" no-gutters>
                    <v-col cols="5">
                      <div class="engagement-label">{{ $t('workforcePlannerProfile.status') }}</div>
                    </v-col>
                    <v-col>
                      <div class="engagement-value">{{ getLocalizedEnrollmentStatus(userDetails.enrollment.status) }}</div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="profile-engagement border-bottom">
                <div class="t-font-futura-pt-demi t-text-xl t-text-primary-color">{{ $t('workforcePlannerProfile.license') }}</div>
                <v-container fluid style="padding: 10px 5px">
                  <v-row class="engagement-details" no-gutters>
                    <v-col cols="5">
                      <div class="engagement-label">{{ $t('workforcePlannerProfile.license') }}</div>
                    </v-col>
                    <v-col>
                      <div class="engagement-value license-type">{{ userDetails.licenseType }}</div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row v-if="!hidden" no-gutters>
              <v-col class="profile-engagement border-bottom">
                <div class="t-font-futura-pt-demi t-text-xl t-text-primary-color">{{ $t('workforcePlannerProfile.transcript') }}</div>
                <v-container fluid style="padding: 10px 5px">
                  <v-row class="engagement-details" no-gutters>
                    <v-col cols="12" class="transcript-download-blurb">
                      <div>{{ $t('workforcePlannerProfile.transcriptDownloadBlurb') }}</div>
                    </v-col>
                  </v-row>
                  <v-row class="engagement-details" no-gutters>
                    <v-col cols="12" class="transcript-download-button">
                      <button
                        class="button"
                        :class="loading || !['Onboarded', 'Registered'].includes(this.userDetails.enrollment.status) ? 'disabled' : ''"
                        :disabled="loading || !['Onboarded', 'Registered'].includes(this.userDetails.enrollment.status)"
                        @click="downloadCSVPerUser(userDetails)"
                      >
                        {{ $tc('common.titleCase.download') }}
                      </button>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
            <v-row v-if="!hidden" no-gutters>
              <v-col class="profile-status" :class="!hideResumeUpload ? 'border-bottom' : ''">
                <div class="t-font-futura-pt-demi t-text-xl t-text-primary-color">{{ $t('workforcePlannerProfile.status') }}</div>
                <div class="status-details">
                  <v-container fluid>
                    <v-row class="profile-row" no-gutters>
                      <v-col cols="5">
                        <div class="status-label">{{ $t('workforcePlannerProfile.achievement') }}</div>
                        <div class="status-label">{{ $t('workforcePlannerProfile.lifetimePoints') }}</div>
                        <!-- <div class="status-label">Balance Points</div> -->
                      </v-col>
                      <v-col>
                        <div class="status-value">{{ numberWithCommas(userDetails.status.achievement) }}</div>
                        <div class="status-value">{{ numberWithCommas(userDetails.status.lifetimePts) }}</div>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="!hideResumeUpload">
              <v-col class="profile-upload-resume">
                <div class="t-pb-2 t-font-futura-pt-demi t-text-xl t-text-primary-color">{{ $t('workforcePlannerProfile.resume') }}</div>
                <input ref="pdf-upload-input" class="pdf-upload-input" type="file" accept=".pdf" @change="handleClick" />
                <div class="drop" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover">
                  <i18n-t keypath="workforcePlannerProfile.dropPDFFile">{{ userDetails.firstName }}</i18n-t>
                  <button class="button" :class="loading ? 'disabled' : ''" :disabled="loading" @click="handleUpload">{{ $t('workforcePlannerProfile.upload') }}</button>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else class="loader">
      <v-card-text class="loader-text">{{ $t('workforcePlannerProfile.loading') }}</v-card-text>
      <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
    </div>
    <div v-if="isError" role="alert" class="error">{{ errorMsg }}</div>
    <div v-if="loading" class="uploading-floater">
      <p>{{ $t('workforcePlannerProfile.uploadingResume') }}</p>
    </div>
    <div v-if="!loading && !displayData && userDetailsReady" class="loader">
      <v-card-text class="loader-text">{{ $t('workforcePlannerProfile.analyzingSkills') }}</v-card-text>
      <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
    </div>
    <v-container v-if="displayData" fluid class="skill-analysis" style="margin-top: 1rem">
      <v-row>
        <v-col cols="4" v-if="!hidden" style="padding: 0 1rem 0 0">
          <v-card class="card">
            <div class="t-pb-2 t-font-futura-pt-demi t-text-xl t-text-primary-color">{{ $t('workforcePlannerProfile.employeeSkills') }}</div>
            <div class="skill-panel scrollable">
              <ul v-if="empSkills.length || acquiredSkills.length" class="employee-skill-list">
                <li v-for="(skill, index) in empSkills" :key="index" :class="[skill.isFutureSkill ? 'future-skill' : 'none-skill', skill.isMatch ? 'match-skill' : 'none-skill']">{{ skill.name }}</li>
                <li v-for="skill in acquiredSkills" :key="skill" :class="[skill.isFutureSkill ? 'future-skill' : 'none-skill', skill.isMatch ? 'match-skill' : 'none-skill']">{{ skill }}</li>
              </ul>
              <div v-else style="margin-left: 15px">{{ $t('workforcePlannerProfile.notAvailable') }}</div>
            </div>
          </v-card>
        </v-col>
        <v-col :cols="!hidden ? 8 : 5" style="padding: 0">
          <v-card class="card">
            <div class="compare" :class="hidden ? 'compare-no-padding' : ''">
              <div v-if="!hidden" class="t-pb-2 t-font-futura-pt-demi t-text-xl t-text-primary-color">{{ $t('workforcePlannerProfile.compareLearnerRole') }}</div>
              <div v-else class="t-pb-2 t-font-futura-pt-demi t-text-xl t-text-primary-color">{{ $t('workforcePlannerProfile.roles') }}</div>
              <SelectView class="dropdown compare-dropdown" v-model="selectedRole" :items="roles" item-title="name" item-value="guid" outlined return-object :placeholder="$t('workforcePlannerProfile.noSuggestedRole')" @update:modelValue="onSelectingRole(true)"></SelectView>
            </div>
            <div v-if="!selectingRole" class="skill-match-container">
              <div v-if="!hidden" class="compare-chart">
                <RadialBarChart
                  :data="{
                    label: skillMatch.label,
                    percentage: skillMatch.percentage,
                    color: '#1f3370',
                    height: 250
                  }"
                  :label-font-size="15"
                  v-if="skillMatch.isAvailable"
                />
                <div v-if="!skillMatch.isAvailable" style="position: relative; top: 2rem; margin-bottom: 80px">{{ $t('workforcePlannerProfile.matchScoreNotAvailable') }}</div>
                <v-container class="pt-0">
                  <v-row>
                    <v-col cols="9" class="d-flex justify-end align-end" style="padding-right: 5px">
                      <p class="match-label">{{ $t('workforcePlannerProfile.matchScore') }}</p>
                    </v-col>
                    <v-col class="d-flex justify-start align-start" style="padding-top: 14px; padding-left: 0px">
                      <v-tooltip top content-class="tooltip-class arrow-top">
                        <template v-slot:activator="{ props }">
                          <div style="display: inline-block"><img class="tooltip-image" v-bind="props" src="@/assets/images/icons/info-icon.png" /></div>
                        </template>
                        <span
                          ><b>{{ $t('workforcePlannerProfile.theMatchScore') }}</b> {{ $t('workforcePlannerProfile.showsRelationship') }}</span
                        >
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div>
                <div class="skill-panel-header">
                  <div class="skill-importance">{{ $t('workforcePlannerProfile.importance') }}</div>
                  <div class="skill-label">{{ $t('workforcePlannerProfile.skillsAndPriorityDigitalSkills') }}</div>
                </div>
                <div class="skill-panel-container scrollable">
                  <div v-for="(skill, index) in roleSkills" :key="index" class="progress-bar">
                    <v-progress-linear :model-value="skill.importance" height="10" reverse rounded class="progress"></v-progress-linear>
                    <span class="progress-text" :class="skill.isFutureSkill ? 'future-skill' : 'none-skill'">{{ skill.name }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="selectingRole" class="loader">
              <v-card-text class="loader-text">{{ $t('workforcePlannerProfile.analyzingSkills') }}</v-card-text>
              <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <div style="margin-top: 1.5rem; margin-bottom: 1rem">
        <v-row v-if="userDetailsReady && displayData">
          <v-col class="track-detail">
            <keep-alive>
              <LearningTrackDetail :userId="userDetails.eid" :roleName="userDetails.roleName" :roleGuid="userDetails.roleGuid" :roleSkills="userDetails.roleSkills"></LearningTrackDetail>
            </keep-alive>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col>
          <v-dialog v-model="emailDialogOpen" max-width="600px">
            <v-card style="padding: 1rem">
              <v-card-title class="dialog-title">{{ $t('workforcePlannerProfile.notifyLearner') }}</v-card-title>
              <div class="dialog-content">
                <p>
                  {{ $t('workforcePlannerProfile.notifyConfirm') }}<br />
                  {{ $t('workforcePlannerProfile.notifyCancel') }}
                </p>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded class="save-button" @click="onEditUserRole(true)" text>{{ $t('button.confirm') }}</v-btn>
                <v-btn rounded class="cancel-button" @click="onEditUserRole(false)" text>{{ $t('button.cancel') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import ProfileHeader from '@/views/profile/components/ProfileHeader';
import SelectView from '@/components/molecules/SelectView';
import RadialBarChart from '@/components/molecules/chart/RadialBarChart';
import LearningTrackDetail from '@/views/catalog/components/LearningTrackDetail';
import { getEnrollmentStatus, getLocalizedEnrollmentStatus, encodeDecodeRole, getDateForCSV, getLearningTranscriptCSVLabels, generateCSVTimeFilter, numberWithCommas, localizedDate } from '@/utils';

export default {
  name: 'WorkforcePlannerProfile',
  components: { RadialBarChart, ProfileHeader, LearningTrackDetail, SelectView },
  props: {
    profileId: {
      type: Number,
      required: true,
      default: 0
    },
    profileGuid: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {
      token: '',
      errorMsg: 'Error! Cannot get the user skills',
      isError: false,
      loading: false,
      displayData: false,
      selectingRole: false,
      userDetails: {},
      userDetailsReady: false,
      skillMatch: {
        label: '0%',
        percentage: 0
      },
      skillCategory: {},
      roleSkills: [],
      empSkills: [],
      acquiredSkills: [],
      profileHeader: {
        avatarImage: require('@/assets/images/users/no-avatar.png'),
        activeCount: 0,
        dashCount: 0
      },
      roles: [],
      availableUserRoles: [],
      selectedRole: { name: '', guid: '' },
      selectedUserRole: { name: '', guid: '' },
      emailPlaceholder: '',
      newEmail: '',
      clientCategory: '',
      tenantType: '',
      userGuid: '',
      hidden: false,
      hideResumeUpload: false,
      learningRecord: {},
      isEditUserRole: false,
      isEditUserEmail: false,
      inviteDisabled: false,
      encodeDecodeRole,
      emailDialogOpen: false,
      cccReady: false,
      disableEmailUpdate: false,
      experienceHeight: '24rem'
    };
  },
  watch: {
    userDetailsReady() {
      this.$nextTick(() => {
        this.setExperienceHeight();
      });
    }
  },
  created() {
    window.addEventListener('resize', this.setExperienceHeight);
  },
  destroyed() {
    window.addEventListener('resize', this.setExperienceHeight);
  },
  computed: {
    ...mapGetters(['client_category', 'tenant_type', 'enabledFeatureFlags']),
    cssBullet() {
      return {
        '--skillImportance': `url(${this.data.imgSrc})`
      };
    }
  },
  async mounted() {
    this.mountAction();
    this.clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.hidden = this.clientCategory && this.clientCategory.toLowerCase() === 'c1';
    this.tenantType = (await localforage.getItem('my_tenant_type')) || this.tenant_type;
    this.tenantType = !this.tenantType ? '' : this.tenantType.toLowerCase();
    this.hideResumeUpload = this.tenantType && this.tenantType === 'workday';
    const payload = {
      id: this.profileId,
      guid: this.profileGuid
    };
    const userProfile = await this.$planAdmin.getUserProfile(payload);

    try {
      const resp = await this.$planAdmin.getUserAcquiredSkillsByGuid(payload);
      if (resp.status !== 500) {
        this.acquiredSkills = resp;
      } else {
        this.acquiredSkills = [];
      }
    } catch {
      this.acquiredSkills = [];
    }

    if ({}.hasOwnProperty.call(userProfile, 'code') && userProfile.code == 401) {
      this.$stopSessionTimer();
    }
    const activity = await this.$planAdmin.getUserActivitySummary({ guid: userProfile.userInfo.guid });
    this.availableUserRoles = await this.$planAdmin.getAllJobs();
    this.availableUserRoles.sort((a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });
    this.availableUserRoles.map((role) => (role.name = encodeDecodeRole(role.name)));
    this.roles = this.availableUserRoles;
    this.userDetails = {
      eid: userProfile.userInfo.id,
      guid: userProfile.userInfo.guid,
      employeeID: userProfile.userInfo.employeeId,
      firstName: userProfile.userInfo.firstName,
      lastName: userProfile.userInfo.lastName,
      title: userProfile.userInfo.title ? userProfile.userInfo.title : '---',
      roleName: userProfile.userJobInfo.length ? userProfile.userJobInfo[0].name : '---',
      roleGuid: userProfile.userJobInfo.length ? userProfile.userJobInfo[0].guid : '',
      email: userProfile.userInfo.email || 'Email Not Available',
      department: userProfile.userDepartment ? userProfile.userDepartment.name : 'Department Not Available',
      hireDate: 'Not available',
      roleSkills: userProfile.userJobReqSkillInfo,
      empSkills: userProfile.userResumeSkillInfo,
      experience: userProfile.pastRoles,
      education: [],
      licenses: [],
      certifications: [],
      engagement: {
        invite: userProfile.userInfo.inviteStatus,
        enrolled: userProfile.userInfo.userState ? 'Onboarded' : 'None',
        progress: 0
      },
      enrollment: {
        status: ''
      },
      licenseType: userProfile.accessTags ? userProfile.accessTags.functionGroupTagNames.toString().toUpperCase() : '',
      social: {
        followers: {}.hasOwnProperty.call(activity, 'socialStats') && activity.socialStats ? activity.socialStats.numFollowers : 0,
        followings: {}.hasOwnProperty.call(activity, 'socialStats') && activity.socialStats ? activity.socialStats.numFollowings : 0
      },
      status: {
        tier: {}.hasOwnProperty.call(activity, 'learningStats') && activity.learningStats ? activity.learningStats.tier : 0,
        achievement: {}.hasOwnProperty.call(activity, 'learningStats') && activity.learningStats ? activity.learningStats.numAchievements : 0,
        lifetimePts: {}.hasOwnProperty.call(activity, 'learningStats') && activity.learningStats ? activity.learningStats.learningPoints : 0,
        balancePts: {}.hasOwnProperty.call(activity, 'learningStats') && activity.learningStats ? activity.learningStats.learningPoints - activity.learningStats.pointsRedeemed : 0
      },
      courses: {
        credential: [],
        coursera: [],
        exchange: []
      }
    };
    this.emailPlaceholder = `Enter ${this.userDetails.firstName}'s email address`;
    this.inviteDisabled = this.isNonInvitable();
    this.selectedRole = this.roles.length ? this.roles.filter((role) => role.name === encodeDecodeRole(this.userDetails.roleName))[0] : null;
    this.onSelectingRole();
    this.getCourseraStatus();
    this.userDetails.enrollment.status = getEnrollmentStatus(userProfile);
    this.userDetailsReady = true;
    this.cccReady = true;
  },
  methods: {
    numberWithCommas,
    getLearningTranscriptCSVLabels,
    generateCSVTimeFilter,
    getDateForCSV,
    localizedDate,
    getLocalizedEnrollmentStatus,
    setExperienceHeight() {
      this.experienceHeight = this.generateProfileInfoHeight();
    },
    generateProfileInfoHeight() {
      let baseHeight = 384;

      const expand = document.getElementsByClassName('expand')[0];
      const profile_card_content = document.getElementsByClassName('profile-card-content')[0];

      if (expand && profile_card_content) {
        baseHeight = expand.clientHeight - profile_card_content.clientHeight - 80;
      }

      return `${baseHeight / 16}rem`;
    },
    async mountAction() {
      this.disableEmailUpdate = this.enabledFeatureFlags && this.enabledFeatureFlags['plan_disable_email_update'] ? this.enabledFeatureFlags['plan_disable_email_update'] : false;
    },
    isNonInvitable() {
      return this.userDetails.email === 'Email Not Available' || [''].includes(this.userDetails.licenseType.toLowerCase()) || this.userDetails.roleName === '---';
    },
    async getCourseraStatus() {
      const courseraStatus = await this.$planAdmin.getCourseraStatus({ guid: this.userDetails.guid });
      return courseraStatus;
    },
    async broadcast() {
      this.userDetailsReady = false;
      this.cccReady = false;
      this.isError = false;
      let payload = { guid: this.userDetails.guid };
      await this.$planAdmin.userBroadcast(payload);
      payload = { id: this.profileId };
      const userProfile = await this.$planAdmin.getUserProfile(payload);
      this.userDetails.enrollment.status = getEnrollmentStatus(userProfile);
      this.userDetailsReady = true;
      await new Promise((resolve) => setTimeout(resolve, 5000));
      this.cccReady = true;
    },
    async onEmailEdit() {
      this.isError = false;
      if (this.newEmail.length) {
        const payload = {
          eid: this.userDetails.eid,
          info: { email: this.newEmail }
        };
        this.userDetailsReady = false;
        const response = await this.$planAdmin.updateUserInfo(payload);
        if (response.error) {
          this.errorMsg = response.message;
          this.isError = true;
        } else {
          this.userDetails.email = this.newEmail;
        }
        this.newEmail = '';
      }
      const payload = { id: this.profileId };
      const userProfile = await this.$planAdmin.getUserProfile(payload);
      this.userDetails.enrollment.status = getEnrollmentStatus(userProfile);
      this.isEditUserEmail = !this.isEditUserEmail;
      this.userDetailsReady = true;
      this.inviteDisabled = this.isNonInvitable();
    },
    async onEditUserRole(sendEmail) {
      this.emailDialogOpen = false;
      this.isError = false;
      if (this.isEditUserRole) {
        this.cccReady = false;
        const payload = {
          eid: this.userDetails.eid,
          info: { jobGuid: this.selectedUserRole.guid, sendRoleUpdateMail: sendEmail }
        };
        this.userDetailsReady = false;
        if (this.selectedUserRole.name && this.selectedUserRole.guid) {
          await this.$planAdmin.updateUserInfo(payload);
          this.userDetails.roleName = this.selectedUserRole.name;
          this.userDetails.roleGuid = this.selectedUserRole.guid;
          this.selectedRole = this.selectedUserRole;
          this.onSelectingRole();
          this.selectedUserRole = { name: '', guid: '' };
        }
        this.userDetailsReady = true;
        this.inviteDisabled = this.isNonInvitable();
        await new Promise((resolve) => setTimeout(resolve, 5000));
        this.cccReady = true;
      }
      this.isEditUserRole = !this.isEditUserRole;
    },
    async onSelectingRole(comparing = false) {
      this.displayData = !comparing ? false : this.displayData;
      this.selectingRole = comparing;
      if (this.selectedRole && {}.hasOwnProperty.call(this.selectedRole, 'guid')) {
        this.userDetails.roleSkills = await this.$planAdmin.getAllSkillsForRole({ guid: this.selectedRole.guid });
      }
      await this.analyzeSkillMatch(comparing);
      this.selectingRole = comparing ? false : this.selectingRole;
    },
    async analyzeSkillMatch(comparing = false) {
      this.empSkills = comparing ? this.empSkills : [];
      this.roleSkills = [];
      const payload = {
        eid: this.userDetails.eid,
        rguid: this.selectedRole ? this.selectedRole.guid : ''
      };
      const matchSkillCodes = await this.determineSkillMatch(this.userDetails.roleSkills, this.userDetails.empSkills);
      const matchScores = this.selectedRole ? await this.$planAdmin.getUserSkillMatchingScore(payload) : null;
      const roleSkillPromise = Promise.resolve(
        this.userDetails.roleSkills.map((skill) => {
          this.roleSkills.push({
            name: skill.name,
            code: skill.skillCode,
            isFutureSkill: skill.isFutureSkill,
            importance: skill.importance,
            isMatch: matchSkillCodes.includes(skill.skillCode)
          });
        })
      );
      const resumeSkillPromise = !comparing
        ? Promise.resolve(
            this.userDetails.empSkills.map((skill) => {
              this.empSkills.push({
                name: skill.name,
                code: skill.skillCode,
                isFutureSkill: skill.isFutureSkill,
                isMatch: matchSkillCodes.includes(skill.skillCode)
              });
            })
          )
        : null;

      Promise.allSettled([roleSkillPromise, resumeSkillPromise]).then(() => {
        this.roleSkills = this.selectedRole ? this.roleSkills : [];
        const matchPercentage = matchScores && matchScores.userRoleMatchSummary ? Math.round(matchScores.userRoleMatchSummary.matchingScore * 100) : -1;
        this.skillMatch = {
          label: `${matchPercentage}%`,
          percentage: matchPercentage,
          isAvailable: matchPercentage > -1
        };
        this.skillCategory = {
          series: [{ name: '', data: [40, 20, 60, 80, 40, 50] }],
          categories: ['Cat 1', 'Cat 2', 'Cat 3', 'Cat 4', 'Cat 5', 'Cat 6']
        };
        this.displayData = true;
      });
    },
    async determineSkillMatch(roleSkills, empSkills) {
      const roleSC = await roleSkills.map((skill) => skill.skillCode);
      const empSC = await empSkills.map((skill) => skill.skillCode);
      return roleSC.filter((code) => empSC.includes(code));
    },
    async reloadProfile() {
      this.userDetailsReady = false;
      this.acquiredSkills = await this.$planAdmin.getUserAcquiredSkillsByGuid({ id: this.profileId, guid: this.profileGuid });
      const userProfile = await this.$planAdmin.getUserProfile({ id: this.profileId });
      this.userDetails.eid = userProfile.userInfo.id;
      this.userDetails.guid = userProfile.userInfo.guid;
      this.userDetails.employeeID = userProfile.userInfo.employeeId;
      this.userDetails.firstName = userProfile.userInfo.firstName;
      this.userDetails.lastName = userProfile.userInfo.lastName;
      this.userDetails.title = userProfile.userInfo.title ? userProfile.userInfo.title : '---';
      this.userDetails.roleName = userProfile.userJobInfo.length ? userProfile.userJobInfo[0].name : '---';
      this.userDetails.roleGuid = userProfile.userJobInfo.length ? userProfile.userJobInfo[0].guid : '';
      this.userDetails.email = userProfile.userInfo.email || 'Email Not Available';
      this.userDetails.department = userProfile.userDepartment ? userProfile.userDepartment.name : 'Department Not Available';
      this.userDetails.roleSkills = userProfile.userJobReqSkillInfo;
      this.userDetails.empSkills = userProfile.userResumeSkillInfo;
      this.userDetails.experience = userProfile.pastRoles;
      this.userDetailsReady = true;
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$message.error('Only support uploading one file!');
        return;
      }
      const rawFile = files[0];

      if (!this.isPdf(rawFile)) {
        this.$message.error('Only supports upload .pdf files');
        return false;
      }
      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleUpload() {
      const browserObj = { c: 'Settings', l: 'Browse PDF resume file', a: 'click' };
      analytics.setVarAndTrack(browserObj);
      this.$gtag.event('Settings', {
        event_category: 'click',
        event_label: `Browse PDF resume file ${this.userDetails.firstName}`,
        value: 1
      });
      this.$refs['pdf-upload-input'].click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0];
      if (!rawFile) return;
      this.upload(rawFile);
    },
    async upload(rawFile) {
      this.loading = true;
      this.displayData = false;
      this.isError = false;
      this.$refs['pdf-upload-input'].value = null;
      const form = new FormData();
      form.append('file', rawFile);
      const payload = {
        form: form,
        eid: this.userDetails.eid
      };
      await this.$planAdmin
        .uploadResume(payload)
        .then((response) => {
          if (response.error) {
            throw response;
          }
          this.userDetails.empSkills = [];
          const promise = response.map((skill) => {
            this.userDetails.empSkills.push({
              name: skill.skill,
              skillCode: skill.skillGuid
            });
          });
          Promise.allSettled([promise]).then(async () => {
            this.loading = false;
            await this.reloadProfile();
            await this.onSelectingRole();
          });
        })
        .catch((error) => {
          this.errorMsg = error.message;
          this.isError = true;
        });
    },
    isPdf(file) {
      return /\.(pdf)$/.test(file.name);
    },
    async downloadCSVPerUser(details) {
      const { guid } = details;

      try {
        const tenantGuid = await localforage.getItem('my_tenant_guid');
        const payload = { orgId: 'root', tenantGuid: tenantGuid, userGuid: guid, timePeriod: 'ALLTIME' };
        const reqId = await this.$planAdmin.getUserLearningRecord(payload);

        let loadingStatus = 'IN_PROGRESS';
        let res = {};
        let counter = 0;

        while (loadingStatus == 'IN_PROGRESS' && counter < 200) {
          res = await this.$planAdmin.consumptionDownloadPerORGgetUpdate(reqId);
          loadingStatus = res.status;
          // keep checking every 3 seconds
          await new Promise((resolve) => setTimeout(resolve, 3000));
          counter++;
        }
        if (loadingStatus == 'FINISHED') {
          let filename = `Required Learning_${generateCSVTimeFilter('ALLTIME')}_${this.getDateForCSV()}`;
          let a = document.createElement('a');
          a.href = res.generatedUri;
          a.setAttribute('download', filename);
          a.click();
        } else {
          if (loadingStatus == 'IN_PROGRESS') {
            // this means we timed out while trying to download
            console.log('Retrieving Learner Records is taking too long');
          } else {
            console.log(res.message);
          }
        }
      } catch (error) {
        console.log('there has been an error');
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  margin: 0px;
  padding: 1rem;
  border-radius: 10px;
  height: 500px;
}

.progress {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: none;
  color: $color-primary;
  width: 100%;
  align-items: center;
  left: unset !important;
  transform: unset !important;
}

.track-detail {
  padding: 0;
  margin: none;
  width: 100%;
}

.progress-card {
  margin: 0.5rem 0;
  padding: 1.2rem 1.2rem;
  border-radius: 10px;
  width: 100%;
}

.activity-progress-bar {
  display: flex;
  position: relative;
  margin-top: 1rem;
  height: 10rem;
  align-items: center;
}

.profile-card {
  padding: 1.2rem 1.2rem;
  border-radius: 10px;
  // height: 100%;
}

.dashboard {
  margin-top: 20px;
  background: $color-primary-lighter;
}

.pdf-upload-input {
  display: none;
  z-index: -9999;
}

.drop {
  position: relative;
  padding: 10px 5px 10px;
  border: 1px dashed $color-primary;
  width: 80%;
  height: 120px;
  line-height: 1;
  margin: 0 auto;
  font-size: 16px;
  border-radius: 5px;
  text-align: center;
  color: $color-primary;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  .button {
    display: block;
    margin: 16px auto 0;
  }
}

.label {
  display: block;
  text-transform: uppercase;
  color: $color-data-2;
  font-weight: bold;
}

.employee-skills {
  padding-bottom: 1rem;
}

.compare {
  display: block;
  padding-bottom: 2rem;
}

.compare-no-padding {
  padding-bottom: 0rem;
}

.compare-label {
  display: inline-block;
}

.compare-dropdown {
  float: right;
  width: 70%;
}

.error {
  font-size: 25px;
  text-align: center;
  color: $color-white;
}

.future-skill {
  font-weight: bold;
}

.match-skill {
  color: $color-primary !important;
}

.none-skill {
  color: #5c5c5c;
}

.back-btn,
.button {
  background-color: $color-secondary;
  color: $color-primary;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02px;
  font-weight: 600;
  font-family: 'Futura';
  border: none;
  padding: 0 30px;
  height: 35px;
  border-radius: 20px;
  line-height: 35px;
  margin: 0px 0px 0px 0px;
  position: relative;
  top: -10px;
}

.invite-button {
  position: absolute;
  right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  .svg-icon {
    width: 1.5rem !important;
  }
}

.edit-email-input {
  margin-top: 15px;
  padding-left: 40px;
  .edit-btn {
    position: relative;
    left: -20px;
  }
}

.uploading-floater {
  position: absolute;
  top: 25px;
  left: 30%;
  margin: auto;
  padding: auto;
  text-align: center;
  font-size: 25px;
  color: white;
  width: 40%;
  height: 60px;
  p {
    margin-top: 15px;
  }
}

.profile {
  margin-top: 14px;
  .profile-card {
    min-height: 100%;
    .profile-row {
      height: 100% !important;
      .profile-header {
        display: inline-block;
        position: relative;
        margin: auto;
        padding: auto;
        text-align: center;

        .header-name {
          font-size: 1.7rem;
          font-weight: 500;
          color: $color-primary;
        }
        .header-title {
          font-size: 1.1rem;
          color: $color-grey;
        }
        .header-follow {
          position: relative;
          top: -10px;
          font-size: 14px;
          color: $color-grey;
        }
        .header-email {
          font-size: 1.1rem;
          color: $color-primary;
        }
        .header-dept {
          margin-top: 0.5rem;
          font-size: 1.1rem;
          color: $color-primary;
          font-weight: bold;
        }
      }
      .profile-info {
        padding: none;
        position: relative;
        min-width: 50%;
        max-height: 24rem;
        padding: 0px 0px;
        overflow: auto;
        .profile-info-item {
          margin-right: 10px;

          &:last-child {
            padding-right: 10px;
          }

          .info-title {
            font-weight: bold;
            color: $color-data-2;
            text-transform: uppercase;
          }
          .info-card {
            margin: 0px;
            padding: 5px 10px !important;
            border-radius: 5px;
            color: $color-primary;

            img {
              float: left;
              width: 40px;
              height: 40px;
              margin-top: 5px;
              margin-right: 10px;
            }
            p {
              margin: 0;
              padding: 0;
            }
            .info-details {
              font-weight: 500;
            }
          }
        }
      }
    }

    .profile-engagement {
      display: inline-block;
      position: relative;

      .engagement-title {
        font-weight: bold;
        color: $color-data-2;
        text-transform: uppercase;
      }

      .engagement-details {
        min-width: 250px;
        font-size: 1rem;
        color: $color-primary;
        .engagement-label {
          margin-right: 30px;
        }
        .engagement-value {
          font-weight: bold;
          color: $color-data-1-dark;
        }
        .transcript-download-blurb {
          margin-bottom: 2rem;
        }
        .transcript-download-button {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .profile-status {
      .status-title {
        font-weight: bold;
        color: $color-data-2;
        text-transform: uppercase;
      }
      .status-details {
        font-size: 1rem;
        color: $color-primary;

        .tier-icon {
          float: left;
          padding-top: 0px;
          position: relative;
          top: 10px;
        }
        .status-value {
          font-weight: bold;
          color: $color-data-1;
        }
      }
    }
  }
}

.profile-upload-resume {
  display: flex;
  flex-direction: column;
  align-items: left;
  .info-title {
    margin-bottom: 20px;
    font-weight: bold;
    color: $color-data-2;
    text-transform: uppercase;
  }
}

.header-edit-title {
  position: relative;
  top: -20px;
  font-size: 18px;
  color: $color-grey;
  display: flex;
  margin: 0 auto;
  padding: 0 auto;

  .v-input {
    width: 90%;
  }

  .v-btn {
    position: relative;
    top: 10px;
  }
}

.match-label {
  color: $color-primary;
  font-size: 20px;
  font-weight: bold;
}

.skill-match-container {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-auto-rows: 100%;
  width: 100%;

  .compare-chart {
    position: relative;
    z-index: 6;
    order: 2;
    width: 100%;
    align-items: center;
    display: grid;
    grid-auto-rows: max-content;
    position: relative;
    grid-auto-columns: 12rem;
    align-items: center;
    justify-content: center;
  }
}

.skill-panel-header {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 0.25rem 0rem;
  border-bottom: 1px solid $color-grey-light;

  .skill-importance {
    width: 100px;
    margin-right: 5px;
    border-radius: 30px;
  }
}
.edit-email-tooltip {
  position: relative;
  top: -2px;
  left: 2px;
  background: #1f3370 0% 0% no-repeat padding-box;
  padding: 3px;
  font-size: 12px;
  color: #fff;
  border-radius: 23px;
  cursor: pointer;
}

@media screen and (max-width: 1650px) {
  .skill-panel-container {
    height: 73%;
    overflow: hidden;
    overflow-y: auto;
  }
}

@media screen and (min-width: 800px) {
  .skill-panel-container {
    height: 70%;
    overflow: hidden;
    overflow-y: auto;
  }
}
.future-skill {
  font-weight: bold;
}

.none-skill {
  color: #5c5c5c;
}

.progress-bar {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 16px !important;

  .progress {
    width: 25%;
    margin-right: 10px;
  }

  .progress-text {
    width: 70%;
    text-align: left;
  }
}

.skill-panel {
  float: left;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  height: 90%;
  .employee-skill-list {
    list-style: none;
  }
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.legend {
  float: right;
  height: 20px;
  vertical-align: middle;
  .legend-label {
    height: 20px;
    padding: 2px;
    float: left;
    font-size: 15px;
    color: $color-primary;
    font-style: oblique;
    vertical-align: middle;
  }
  .legend-box {
    vertical-align: middle;
    float: left;
    width: 15px;
    height: 15px;
    margin: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: $color-data-1;
  }
}

.learning-history {
  max-height: 700px;
  overflow: hidden;
  overflow-y: scroll;
  margin-bottom: 1rem;
}

.scrollable {
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: $color-grey-lighter;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 20px;
    width: 4px;
  }
}

.loader {
  margin: 50px;
  text-align: center;

  .loader-text {
    font-size: 30px;
    color: $color-primary;
  }

  .loader-bar {
    position: relative;
    top: -30px;
  }
}

.disabled {
  background: $color-grey-light !important;
  color: $color-grey-dark !important;
}

.disabled-invite {
  span {
    color: $color-grey-light !important;
  }
}

.border-bottom {
  margin-bottom: 1rem;
  border-bottom: 2px solid $color-grey-light;
}

.tooltip-class {
  width: 400px;
  border: 3px solid #a7a7a7;
  color: $COLOR-DARKGRAY;
  font-size: 1rem;
  background-color: white;
  opacity: 1 !important;
  box-shadow: 5px 5px 5px #f0f0f0;
}

.tooltip-class.arrow-top {
  margin-bottom: 15px;
}

.tooltip-class.arrow-top:after {
  content: ' ';
  position: absolute;
  right: 38%;
  bottom: -10px;
  border-top: 10px solid #a7a7a7;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: none;
}

.tooltip-image {
  cursor: pointer;
  height: 25px;
  width: 28px;
}

.save-button {
  background: $button-primary-color-bg !important;
  color: $button-primary-color-text !important;
}

.cancel-button {
  width: 100px;
  background: $button-secondary-color-bg !important;
  color: $button-secondary-color-text !important;
}

.dialog-title {
  font-size: 1rem !important;
  font-weight: bold !important;
  padding: 0rem !important;
}

.dialog-content {
  padding: 0.2rem 0rem 0.5rem 0rem;
}
</style>
<style lang="scss">
.no-ccc-role.v-input--selection-controls .v-input__slot > .v-label {
  top: 9px;
}
.v-tooltip__content {
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1 !important;
}
</style>
