// eslint-disable-next-line

import FeatureFlag from '@/components/molecules/FeatureFlag';
import PlanAdminWeb from '@/api/plan-web';

const PlanAdminProvider = new PlanAdminWeb();

const unleashPlugin = {
  FeatureFlag,
  async install(app, options) {
    const { host, store, strategyProviders } = options;
    if (!host) throw new Error('Please initialize feature flag with an Unleash host.');
    if (!store) throw new Error('Please initialize feature flag with a Vuex store.');
    app.config.applicationHostname = 'localhost';
    app.component('feature-flag', FeatureFlag);
    const response = await PlanAdminProvider.getFeatureFlags();
    if (response && response.features) {
      await store.dispatch('flag/setFeatureFlags', { featureFlags: response.features, strategyProviders: strategyProviders });
    }
  }
};

export default unleashPlugin;

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(unleashPlugin);
}
