<template>
  <div class="role-container">
    <v-container style="padding: 0; margin: 0; max-width: 100% !important">
      <div class="confirmation-upload" v-if="showModal">
        <v-card>
          <h4>{{ $t('roleAddition.upload') }}</h4>
          <p>{{ option.confirmationMessage }}</p>

          <v-row no-gutters>
            <v-btn data-el="confirmRecommend" class="save-button" rounded @click="uploadCurrentFile">{{ $t('button.confirm') }}</v-btn>
            <a data-el="cancelEditLearning" class="cancel-link" rounded color="primary" @click="onCancelModal">{{ $t('button.cancel') }}</a>
          </v-row>
        </v-card>
      </div>
      <div class="confirmation-upload-success" v-if="showModal && successModal">
        <v-card>
          <div class="success" style="border-radius: 20px">{{ $t('roleAddition.uploadSuccessful') }} {{ uploadingResponse['success'] }}</div>
          <v-row no-gutters>
            <v-btn data-el="confirmRecommend" class="save-button btm-btn" rounded @click="onCloseSuccessModal">Ok</v-btn>
          </v-row>
        </v-card>
      </div>
      <div class="confirmation-upload" v-if="showModal && errorModal">
        <v-card>
          <h4>{{ $t('roleAddition.errors') }}</h4>
          <div class="uploading-errors">
            <ul>
              <li v-for="(error, key) in JSON.parse(uploadError.message)" :key="key">
                <span class="smaller">{{ error }}</span>
              </li>
            </ul>
          </div>
          <v-row no-gutters>
            <a data-el="cancelEditLearning" class="cancel-link" rounded color="primary" @click="onCancelModal">{{ $t('button.cancel') }}</a>
          </v-row>
        </v-card>
      </div>
      <!-- Role Detail -->
      <v-row no-gutters>
        <v-col style="padding: 0">
          <v-card v-if="!createRole" flat class="card" style="min-height: 300px">
            <h2 class="card-title h2">{{ $t('roleAddition.createNewRole') }}</h2>
            <div class="options-select-container">
              <SelectView v-model="roleUploadDropdown" :items="options" item-title="title" item-value="code" class="option-select" :label="options[0].title" @update:modelValue="handleSelectChange" return-object single-line dense variant="outlined" density="compact"></SelectView>
            </div>
            <div v-if="option.code === 0">
              <label for="add_new_role-title-input" class="role-title">{{ $t('roleAddition.titleOfRole') }} <span class="asterisk">*</span></label>
              <v-text-field id="add_new_role-title-input" data-el="roleName" v-model="role.name" single-line :placeholder="$t('roleAddition.namePlaceholder')" filled dense aria-required="true"></v-text-field>
              <label for="add_new_role-description-input" class="role-title">{{ $t('roleAddition.descriptionOfRole') }} <span class="asterisk">*</span></label>
              <v-textarea id="add_new_role-description-input" data-el="roleDesc" v-model="role.description" single-line :placeholder="$t('roleAddition.descPlaceholder')" filled dense aria-required="true"></v-textarea>
              <v-row dense>
                <v-col md="4" sm="12" class="function-dropdown-container">
                  <SelectView
                    :aria-label="`${role.function ? `Function ${role.function} selected` : `${$t('roleAddition.functionPlaceholder')} *`}`"
                    id="add_new_role-function-dropdown"
                    data-el="functionDropdown"
                    ref="displayFunctionRef"
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="role.function"
                    :items="this.displayFunctions"
                    :label="`${$t('roleAddition.functionPlaceholder')}`"
                    aria-required="true"
                    dense
                    outlined
                    @update:modelValue="handleFunctionChange"
                    variant="outlined"
                    density="compact"
                  >
                    <template v-slot:label> {{ $t('roleAddition.functionPlaceholder') }} <span class="asterisk">*</span> </template>
                  </SelectView>
                </v-col>
                <v-col offset-md="2" md="4" sm="10" class="department-dropdown-container">
                  <SelectView
                    :aria-label="`${role.department ? `Department ${role.department} selected` : ` ${$t('roleAddition.deptPlaceholder')} *`}`"
                    data-el="departmentDropdown"
                    ref="displayDepartmentRef"
                    :menu-props="{ bottom: true, offsetY: true }"
                    v-model="role.department"
                    :items="this.displayDepartments"
                    :label="`${$t('roleAddition.deptPlaceholder')}`"
                    aria-required="true"
                    dense
                    outlined
                    @update:modelValue="handleDepartmentChange"
                    variant="outlined"
                    density="compact"
                  >
                    <template v-slot:label> {{ $t('roleAddition.deptPlaceholder') }} <span class="asterisk">*</span> </template>
                  </SelectView>
                </v-col>
                <v-col md="2" sm="2" class="add-department-container">
                  <v-dialog v-model="departmentDialog" max-width="500px">
                    <template v-slot:activator="{ props }">
                      <v-btn data-el="addDepartment" aria-label="Add Department" icon color="#1f3370" class="circle-add-btn" v-bind="props" :aria-haspopup="undefined" :aria-expanded="undefined" :role="undefined" variant="text">
                        <v-icon dark style="font-size: 35px"> mdi-plus-circle </v-icon>
                      </v-btn>
                    </template>
                    <v-card style="padding: 1rem">
                      <v-card-title class="dialog-title">{{ $t('roleAddition.createNewDept') }}</v-card-title>
                      <div class="dialog-content">{{ $t('roleAddition.inputInfo') }}</div>
                      <v-alert v-model="alert.isAlert" type="error" border="start" close-text="Close Alert" role="alert" dark dismissible>{{ alert.message }}</v-alert>
                      <v-text-field data-el="newDepartmentName" v-model="newDepartmentInputText" outlined :placeholder="$t('roleAddition.newDeptPlaceholder')"></v-text-field>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn data-el="departmentConfirm" rounded class="save-button" @click="onConfirmInputDepartment" text>{{ $t('button.confirm') }}</v-btn>
                        <v-btn data-el="departmentCancel" rounded class="cancel-button" @click="onCancelModal" text>{{ $t('button.cancel') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <div v-if="errorMsg.length" role="alert" class="error-msg">{{ $t('roleAddition.error') }} {{ errorMsg }}</div>
              <div class="d-flex justify-center">
                <v-btn class="cancel-button mr-md-8 mr-sm-2" data-el="cancelNewRole" :disabled="isMatching" rounded color="primary" @click="onCancel">
                  {{ $t('button.cancel') }}
                </v-btn>
                <v-btn class="save-button ml-md-8 mr-sm-2" data-el="confirmNewRole" :disabled="isMatching" rounded @click="onCreate">
                  {{ $t('button.create') }}
                </v-btn>
              </div>
            </div>

            <div v-else-if="option.code === 1">
              <div v-if="option.code === 1" style="color: #1f3370; font-weight: bold">{{ $t('roleAddition.serchToClone') }}</div>
              <div v-if="option.code === 2" style="color: #1f3370; font-weight: bold">{{ $t('roleAddition.searchRefRoles') }}</div>
              <v-text-field
                v-model="searchRole"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :label="$t('roleAddition.searchPlaceholder')"
                filled
                dense
                clearable
                @change="onSearchRole()"
                @click:clear="onClearSearchRole"
                style="width: 40%; margin-top: 0.5rem"
                outlined
                variant="outlined"
                density="compact"
              >
              </v-text-field>

              <div v-if="searchCompleted && !searchSelected" class="search-role-panel scrollable">
                <v-row v-for="(role, index) in foundRoles" :key="index" style="padding: 0">
                  <v-col cols="8" style="padding: 0 0 5px 0">{{ encodeDecodeRole(role.name) }}</v-col>
                  <v-col cols="4" style="padding: 0; text-align: right">
                    <v-btn class="select-button" rounded @click="onSelectRole(role)">{{ $t('roleAddition.select') }}</v-btn>
                  </v-col>
                </v-row>
                <div v-if="errorMsg.length" role="alert" class="error-msg">Error: {{ errorMsg }}</div>
                <div v-if="!foundRoles.length" style="padding: 0rem 2rem">{{ $t('roleAddition.noRole') }} {{ $sanitize(searchRole) }}</div>
              </div>

              <div v-if="searchSelected && option.code === 1" style="color: #1f3370; margin-top: 1rem">
                Cloning: <span style="font-weight: bold">{{ selectedRoles[0].name }}</span>
              </div>
              <div v-if="searchSelected && option.code === 2">
                <div style="color: #1f3370; font-weight: bold; margin-top: 1rem">{{ $t('roleAddition.referencing') }}</div>
                <div style="display: inline-block; width: 100%">
                  <div v-for="(role, index) in selectedRoles" :key="index" class="selected-role">
                    {{ $sanitize(role.name) }}
                    <span>
                      <v-btn icon small @click="onDeselectRole(role)"><v-icon>mdi-close</v-icon></v-btn>
                    </span>
                  </div>
                </div>
                <div v-if="selectedRoles.length" style="margin-top: 1%">
                  <div class="role-title">{{ $t('roleAddition.titleOfRole') }}</div>
                  <v-text-field v-model="role.name" single-line :label="$t('roleAddition.namePlaceholder')" filled dense></v-text-field>
                  <div class="role-title">{{ $t('roleAddition.descriptionOfRole') }}</div>
                  <v-textarea v-model="role.description" single-line :label="$t('roleAddition.descPlaceholder')" filled dense></v-textarea>
                  <v-row dense>
                    <v-col cols="6">
                      <SelectView
                        ref="displayFunctionRef"
                        :menu-props="{ bottom: true, offsetY: true }"
                        v-model="role.function"
                        :items="this.displayFunctions"
                        :label="$t('roleAddition.functionPlaceholder')"
                        dense
                        outlined
                        @update:modelValue="handleFunctionChange"
                        style="width: 68%"
                      />
                    </v-col>
                    <v-col cols="4">
                      <SelectView ref="displayDepartmentRef" :menu-props="{ bottom: true, offsetY: true }" v-model="role.department" :items="this.displayDepartments" :label="$t('roleAddition.deptPlaceholder')" dense outlined @update:modelValue="handleDepartmentChange" />
                    </v-col>
                    <v-col cols="2">
                      <v-dialog v-model="departmentDialog" max-width="500px">
                        <template v-slot:activator="{ props }">
                          <v-btn icon color="#1f3370" class="circle-add-btn" v-bind="props">
                            <v-icon dark style="font-size: 35px"> mdi-plus-circle </v-icon>
                          </v-btn>
                        </template>
                        <v-card style="padding: 1rem">
                          <v-card-title class="dialog-title">{{ $t('roleAddition.createNewDept') }}</v-card-title>
                          <div class="dialog-content">{{ $t('roleAddition.inputInfo') }}</div>
                          <v-alert v-model="alert.isAlert" type="error" border="start" close-text="Close Alert" role="alert" dark dismissible>{{ alert.message }}</v-alert>
                          <v-text-field v-model="newDepartmentInputText" outlined :placeholder="$t('roleAddition.newDeptPlaceholder')"></v-text-field>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn rounded class="save-button" @click="onConfirmInputDepartment" text>{{ $t('button.confirm') }}</v-btn>
                            <v-btn rounded class="cancel-button" @click="onCancelModal" text>{{ $t('button.cancel') }}</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <div v-if="errorMsg.length" role="alert" class="error-msg">Error: {{ errorMsg }}</div>
                  <v-row no-gutters style="width: 30%; margin: 1% 35%">
                    <v-col style="text-align: center; padding: 0"
                      ><v-btn class="cancel-button" :disabled="isMatching" rounded color="primary" @click="onCancel">{{ $t('button.cancel') }}</v-btn></v-col
                    >
                    <v-col style="text-align: center; padding: 0"><v-btn class="save-button" :disabled="isMatching" rounded @click="onAdd">Add</v-btn></v-col>
                  </v-row>
                </div>
              </div>
            </div>
            <div v-else-if="option.code === 2">
              <!-- Upload Skills CSV -->
              <UploadFile :uploadPrompt="$t('roleAddition.uploadRolesPrompt')" :ReminderText="$t('roleAddition.uploadRolesReminder')" acceptedFormats=".csv" ref="option-2" @cancel-upload="cancelUpload" @downloadTemplate="downloadSkillTemplate" />
            </div>
            <div v-else-if="option.code === 3">
              <!-- Upload Credentials CSV -->
              <UploadFile :uploadPrompt="$t('roleAddition.uploadCredsPrompt')" :ReminderText="$t('roleAddition.uploadCredsReminder')" acceptedFormats=".xlsx,.xls,.csv" ref="option-3" @cancel-upload="cancelUpload" @downloadTemplate="downloadCredentialTemplate" />
            </div>
            <div v-else-if="option.code === 4">
              <!-- Upload Courses CSV -->
              <UploadFile :uploadPrompt="$t('roleAddition.uploadCoursesPrompt')" :ReminderText="$t('roleAddition.uploadCoursesReminder')" acceptedFormats=".xlsx,.xls,.csv" ref="option-4" @cancel-upload="cancelUpload" @downloadTemplate="downloadCourseTemplate" />
            </div>
            <div v-else-if="option.code === 5">
              <!-- Upload Content CSV -->
              <UploadFile :uploadPrompt="$t('roleAddition.uploadContentPrompt')" :ReminderText="$t('roleAddition.uploadContentReminder')" acceptedFormats=".xlsx,.xls,.csv" ref="option-5" @cancel-upload="cancelUpload" @downloadTemplate="downloadContentTemplate" />
            </div>
          </v-card>
          <v-card v-else flat class="card">
            <h2 class="card-title h2">{{ $t('roleAddition.createNewRole') }}</h2>
            <SelectView :items="options" item-text="title" item-value="code" class="option-select" return-object outlined variant="outlined" v-model="option" disabled dense></SelectView>
            <div v-if="option.code === 0">
              <div class="role-title" style="font-size: 1.2rem"><span v-text="$sanitize(role.name)"></span></div>
              <div class="role-description"><span v-text="$sanitize(role.description)"></span></div>
              <div v-if="errorMsg.length && createRole && !isMatching">
                <div role="alert" class="error-msg">Error: {{ errorMsg }}</div>
                <div style="text-align: center">
                  <v-btn class="cancel-button" rounded color="primary" @click="onCancel">{{ $t('button.cancel') }}</v-btn>
                </div>
              </div>
            </div>
            <div v-if="option.code === 1">
              <div class="role-title">{{ $t('roleAddition.titleOfRole') }}</div>
              <v-text-field v-model="role.name" single-line :label="$t('roleAddition.namePlaceholder')" filled dense style="width: 90%"></v-text-field>
              <div class="role-title">{{ $t('roleAddition.descriptionOfRole') }}</div>
              <v-textarea v-model="role.description" single-line :label="$t('roleAddition.descPlaceholder')" filled dense style="width: 90%"></v-textarea>
              <v-row dense>
                <v-col cols="6"><SelectView ref="displayFunctionRef" :menu-props="{ bottom: true, offsetY: true }" v-model="role.function" :items="this.displayFunctions" label="Function" dense outlined @update:modelValue="handleFunctionChange" style="width: 68%" /></v-col>
                <v-col cols="4"><SelectView ref="displayDepartmentRef" :menu-props="{ bottom: true, offsetY: true }" v-model="role.department" :items="this.displayDepartments" label="Department" dense outlined @update:modelValue="handleDepartmentChange" /></v-col>
                <v-col cols="2">
                  <v-dialog v-model="departmentDialog" max-width="500px">
                    <template v-slot:activator="{}">
                      <v-btn icon color="#1f3370" class="circle-add-btn" v-bind="props">
                        <v-icon dark style="font-size: 35px"> mdi-plus-circle </v-icon>
                      </v-btn>
                    </template>
                    <v-card style="padding: 1rem">
                      <v-card-title class="dialog-title">{{ $t('roleAddition.createNewDept') }}</v-card-title>
                      <div class="dialog-content">{{ $t('roleAddition.inputInfo') }}</div>
                      <v-alert v-model="alert.isAlert" type="error" border="start" close-text="Close Alert" role="alert" dark dismissible>{{ alert.message }}</v-alert>
                      <v-text-field v-model="newDepartmentInputText" outlined :placeholder="$t('roleAddition.newDeptPlaceholder')"></v-text-field>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn rounded class="save-button" @click="onConfirmInputDepartment" text>{{ $t('button.confirm') }}</v-btn>
                        <v-btn rounded class="cancel-button" @click="onCancelModal" text>{{ $t('button.cancel') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <div v-if="errorMsg.length" role="alert" class="error-msg">{{ $t('roleAddition.error') }} {{ errorMsg }}</div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <!-- Skill Matching -->
      <v-row v-if="matchSkill">
        <v-col>
          <v-card flat class="card">
            <v-row no-gutters>
              <v-col style="padding: 0">
                <div class="card-title h2">{{ $t('roleAddition.roleSkillGroup') }}</div>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="5" style="padding: 0">
                <div class="skill-panel-header">
                  <div class="skill-importance">{{ $t('roleAddition.importance') }}</div>
                  <div class="skill-label">{{ $t('roleAddition.skillsAndPriorityDigitalSkills') }}</div>
                </div>
                <div class="skill-panel-container">
                  <div v-for="(skill, index) in role.skills" :key="index" class="progress-bar" @click="onSelectSkill(index)" :class="index === selectSkillIndex ? 'select' : ''">
                    <v-progress-linear :value="skill.importance" height="10" reverse rounded class="progress"></v-progress-linear>
                    <span class="progress-text" :class="skill.isFutureSkill ? 'future-skill' : 'none-skill'">{{ $sanitize(skill.name) }}</span>
                  </div>
                  <div v-if="role.skills && !role.skills.length" style="padding: 1rem">{{ $t('roleAddition.noneAvailable') }}</div>
                </div>
              </v-col>
              <v-col cols="7" v-if="selectSkill && selectSkillIndex > -1" class="skill-detail">
                <div class="skill-detail-title">{{ $sanitize(selectSkill.name) }}</div>
                <div class="skill-detail-text">{{ $sanitize(selectSkill.descriptionText) }}</div>
                <div v-if="selectSkill.isFutureSkill" class="skill-detail-title" style="margin: 1rem 0rem">{{ $t('roleAddition.priorityDigSkill') }}</div>
                <div class="skill-detail-title" style="margin-top: 1rem">{{ $t('roleAddition.importance') }}</div>
                <div style="padding-left: 1rem; margin-bottom: 0.5rem">
                  <i
                    >{{ $t('roleAddition.editImportanceMessage[0]') }} <b>{{ $t('roleAddition.editImportanceMessage[1]') }}</b> {{ $t('roleAddition.editImportanceMessage[2]') }}</i
                  >
                </div>
                <v-row no-gutters>
                  <v-col cols="2" style="padding: 0"><v-text-field v-model="editImportance" single-line filled rounded dense style="width: 80px" @change="handleInputChange" /></v-col>
                  <v-col style="padding-top: 5px" class="skill-detail-importance">/100</v-col>
                </v-row>
                <v-row no-gutters v-if="errorMsg.length" class="important-error">
                  <v-col role="alert">* {{ errorMsg }}</v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters style="width: 30%; margin: 0 35%">
              <v-col data-el="cancelRoleToSkillGroup" style="text-align: center; padding: 0"
                ><v-btn class="cancel-button" rounded color="primary" @click="onCancel">{{ $t('button.cancel') }}</v-btn></v-col
              >
              <v-col data-el="addRoleToSkillGroup" style="text-align: center; padding: 0"
                ><v-btn class="save-button" rounded @click="onAdd">{{ $t('button.add') }}</v-btn></v-col
              >
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- Loader -->
      <div class="loader" v-if="isMatching">
        <v-card-text class="loader-text">{{ $t('roleAddition.loading') }}</v-card-text>
        <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState, mapActions } from 'vuex';
import { handleRoleFunctionChange, handleRoleDepartmentChange, encodeDecodeRole, getLocalizedFunctions } from '@/utils';
import store from '@/store';
import UploadFile from '../UploadFile';
import SelectView from '@/components/molecules/SelectView';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'RoleAddition',
  components: { UploadFile, SelectView },
  data() {
    return {
      roleUploadDropdown: 0,
      token: '',
      functions: [],
      departments: [],
      role: {
        name: '',
        description: '',
        skills: [],
        function: '',
        department: '',
        cme: []
      },
      errorMsg: '',
      createRole: false,
      isMatching: false,
      selectSkillIndex: -1,
      selectSkill: null,
      matchSkill: false,
      editImportance: 0,
      filteredOptions: [],
      options: [
        { title: translate('roleAddition.options.createWithJobDesc'), code: 0, displayIfNoRoles: true },
        { title: translate('roleAddition.options.cloneExisting'), code: 1, displayIfNoRoles: true },
        { title: translate('roleAddition.options.uploadRolesAndSkills'), code: 2, uploadFunctionName: 'uploadRolesAndSkills', confirmationMessage: translate('roleAddition.options.uploadRolesAndSkillsConfirmMessage'), displayIfNoRoles: true }
      ],
      option: {},
      searchRole: '',
      foundRoles: [],
      selectedRoles: [],
      searchCompleted: false,
      searchSelected: false,
      fileToUpload: null,
      successModal: false,
      errorModal: false,
      uploadingResponse: {},
      functionDialog: false,
      newFunctionInputText: '',
      departmentDialog: false,
      newDepartmentInputText: '',
      alert: {
        isAlert: false,
        message: ''
      },
      displayRoles: [],
      displayDepartments: [],
      displayFunctions: [],
      displayCME: [
        { displayValue: translate('roleAddition.citizen'), value: 'C' },
        { displayValue: translate('roleAddition.manager'), value: 'M' },
        { displayValue: translate('roleAddition.executive'), value: 'E' }
      ],
      selectedFunction: '',
      selectedDepartment: '',
      functionFlag: false,
      departmentFlag: false,
      uploadError: null,
      encodeDecodeRole,
      componentIsActive: false
    };
  },
  computed: {
    ...mapState('plan', ['allFunctions', 'allDepartments', 'allRoles']),
    ...mapGetters(['showModal', 'enabledFeatureFlags', 'getUploadStatus'])
  },
  watch: {
    async getUploadStatus(value) {
      if (value) {
        await this.setUploadStatus(false);
        this.resetRoleAddition(this.options[0]);
      }
    },
    async '$i18n.locale'() {
      if (this.componentIsActive) {
        this.setOptions();
        //get a translated functions list
        this.displayFunctions = [];
        let functionsList = await this.$planAdmin.getGovernanceFunctions();
        functionsList.map((func) => {
          this.displayFunctions.push(getLocalizedFunctions(func.name));
        });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetRoleAddition();
    next();
  },
  async created() {
    this.option = this.options[0];
  },
  mounted() {
    this.roles = this.allRoles;
    this.displayRoles = this.roles;
    analytics.setVarAndTrack({ c: 'RoleAddition', l: 'AddNewRole', a: 'load' });
    this.$gtag.event('RoleAddition', {
      event_category: 'load',
      event_label: 'AddNewRole',
      value: 1
    });
  },
  deactivated() {
    this.componentIsActive = false;
  },
  activated() {
    this.role = {
      name: '',
      description: '',
      skills: [],
      function: '',
      department: '',
      cme: []
    };
    this.matchSkill = false;
    this.option.code = 0;
    this.createRole = false;
    this.displayFunctions = this.allFunctions;
    this.displayDepartments = this.allDepartments;
    this.componentIsActive = true;
  },
  methods: {
    ...mapMutations('user', ['SET_SHOW_MODAL', 'SET_ROLES', 'SET_UPLOADING']),
    ...mapMutations('plan', ['SET_ALL_DEPARTMENTS', 'SET_ALL_FUNCTIONS', 'SET_ALL_ROLES']),
    ...mapActions('plan', ['setUploadStatus']),
    async resetRoleAddition() {
      this.roleUploadDropdown = 0;
      this.handleSelectChange(this.options[0]);
    },
    setOptions() {
      this.options = [
        { title: translate('roleAddition.options.createWithJobDesc'), code: 0, displayIfNoRoles: true },
        { title: translate('roleAddition.options.cloneExisting'), code: 1, displayIfNoRoles: true },
        { title: translate('roleAddition.options.uploadRolesAndSkills'), code: 2, uploadFunctionName: 'uploadRolesAndSkills', confirmationMessage: translate('roleAddition.options.uploadRolesAndSkillsConfirmMessage'), displayIfNoRoles: true }
      ];
    },
    async onCancel() {
      this.clearInputs();
      this.onClearSearchRole();
      this.resetRoleAddition();
      this.$router.push({ name: 'RoleExplorer' });
      analytics.setVarAndTrack({ c: 'RoleAddition', l: encodeURIComponent(this.role.name), a: 'cancel' });
      this.$gtag.event('RoleAddition', {
        event_category: 'cancel',
        event_label: encodeURIComponent(this.role.name),
        value: 1
      });
    },
    onCancelModal() {
      this.SET_SHOW_MODAL(false);
      this.errorModal = false;
      this.successModal = false;
      this.functionDialog = false;
      this.departmentDialog = false;
      this.newFunctionInputText = '';
      this.newDepartmentInputText = '';
      this.alert.isAlert = false;
    },
    onCloseSuccessModal() {
      this.SET_SHOW_MODAL(false);
      this.successModal = false;
      this.SET_ROLES(true);
    },
    onConfirmInputFunction() {
      const isExists = this.functions.some((func) => func.toLowerCase().includes(this.newFunctionInputText.toLowerCase()));
      if (this.newFunctionInputText.length && !isExists) {
        this.displayFunctions.push(this.newFunctionInputText);
        this.role.function = this.newFunctionInputText;
        this.onCancelModal();
      } else if (!this.newFunctionInputText.length) {
        this.alert.isAlert = true;
        this.alert.message = translate('roleAddition.validation.functionEmpty');
      } else {
        this.alert.isAlert = true;
        this.alert.message = translate('roleAddition.validation.functionExists');
      }
    },
    onConfirmInputDepartment() {
      const isExists = this.departments.some((dept) => dept.toLowerCase().includes(this.newDepartmentInputText.toLowerCase()));
      if (this.newDepartmentInputText.length && !isExists) {
        this.displayDepartments.push(this.newDepartmentInputText);
        this.role.department = this.newDepartmentInputText;
        this.onCancelModal();
      } else if (!this.newDepartmentInputText.length) {
        this.alert.isAlert = true;
        this.alert.message = translate('roleAddition.validation.deptEmpty');
      } else {
        this.alert.isAlert = true;
        this.alert.message = translate('roleAddition.validation.deptExists');
      }
    },
    async uploadCurrentFile() {
      this.uploadingResponse = {};
      this.successModal = false;
      this.errorModal = false;
      const uploadComp = this.$refs[`option-${this.option.code}`];
      const form = await uploadComp.upload();
      const payload = { form: form };
      this.$planAdmin[this.option.uploadFunctionName](payload)
        .then(async (response) => {
          this.handleUploadSuccess(response);
          this.$router.push({ name: 'RoleAdditionRoute' });
        })
        .catch((error) => {
          this.handleUploadError(error);
        });
    },
    handleUploadSuccess(response) {
      this.isUploading = false;
      this.SET_UPLOADING(false);
      this.uploadingResponse = response;
      if (response.status === 201) {
        this.SET_SHOW_MODAL(true);
        this.errorModal = false;
        this.successModal = true;
      } else {
        this.successModal = false;
        this.errorModal = false;
        this.option.code == 2 ? this.EventBus.emit('on-csv-upload') : this.EventBus.emit('on-ccc-csv-upload');
      }
    },
    handleUploadError(error) {
      this.isUploading = false;
      this.errorMsg = error.message;
      this.isError = true;
      this.SET_UPLOADING(false);
      this.SET_ROLES(false);
    },
    cancelUpload() {
      this.EventBus.emit('cancel-add-new-role');
    },
    clearInputs() {
      this.onCancelModal();
      this.role = {
        name: '',
        description: '',
        skills: [],
        function: '',
        department: ''
      };
      this.selectSkillIndex = -1;
      this.selectSkill = null;
      this.isMatching = false;
      this.matchSkill = false;
    },
    validateInputs() {
      if (!this.role.name.length) {
        this.errorMsg = translate('roleAddition.validation.title');
        return false;
      }
      if (!this.role.description || this.role.description.length < 5) {
        this.errorMsg = translate('roleAddition.validation.roleDesc');
        return false;
      }
      if ((this.option.code != 1 && !this.role.function.length) || !this.displayFunctions.includes(this.role.function)) {
        this.errorMsg = translate('roleAddition.validation.roleFunc');
        return false;
      }
      if (this.option.code != 1 && !this.role.department.length) {
        this.errorMsg = translate('roleAddition.validation.roleDept');
        return false;
      }
      return true;
    },
    async onCreate() {
      this.errorMsg = '';
      if (!this.validateInputs()) {
        return;
      }
      this.isMatching = true;
      const role = await this.$planAdmin.createRole({ role: this.role });
      if (role.error) {
        this.errorMsg = role.message;
      } else {
        this.role.skills = role.skills;
        this.matchSkill = true;
      }
      this.isMatching = false;
      this.createRole = true;
      analytics.setVarAndTrack({ c: 'RoleAddition', l: encodeURIComponent(this.role.name), a: 'save' });
      this.$gtag.event('RoleAddition', {
        event_category: 'save',
        event_label: encodeURIComponent(this.role.name),
        value: 1
      });
    },
    async onSelectSkill(index) {
      this.errorMsg = '';
      this.selectSkillIndex = index;
      const detail = await this.$planAdmin.getSkillDescription({ skill: encodeURIComponent(this.role.skills[index].name) });
      this.role.skills[index].descriptionText = detail.description;
      this.selectSkill = this.role.skills[index];
      this.editImportance = this.selectSkill.importance;
    },
    async onAdd() {
      let response;
      if (!this.validateInputs()) {
        return;
      }
      if (this.option.code != 1) {
        let skills = [];
        await this.role.skills.map((skill) => {
          skills.push({
            name: skill.name,
            importance: parseInt(skill.importance),
            isFutureSkill: skill.isFutureSkill
          });
        });
        this.isMatching = this.option.code === 2;
        response = await this.$planAdmin.addRole({ role: this.role });
        this.isMatching = false;
      }
      if (this.option.code == 1) {
        const payload = {
          roleGuid: this.role.guid,
          role: { name: encodeURIComponent(this.role.name), description: this.role.description, function: this.role.function, department: this.role.department, reProcessDescription: false }
        };
        this.isMatching = true;
        response = await this.$planAdmin.cloneRole(payload);
        this.isMatching = false;
      } else if (this.role.guid) {
        const payload = {
          roleGuid: this.role.guid,
          role: { name: encodeURIComponent(this.role.name), description: this.role.description, function: this.role.function, department: this.role.department, reProcessDescription: false }
        };
        this.isMatching = true;
        response = await this.$planAdmin.editRole(payload);
        this.isMatching = false;
      }
      if (response.error) {
        this.errorMsg = response.message;
        return;
      }
      this.EventBus.emit('add-new-role');
      /****
       * Reset Functions and Departments used by RoleStructure
       */
      store.dispatch('plan/setSelectedFunc', null);
      store.dispatch('plan/setSelectedDept', null);

      this.roles = await this.$planAdmin.getAllJobs();
      //this.SET_ALL_ROLES(this.roles)
      let roleGuid = null;
      this.roles.map((role) => {
        if (role.name === this.role.name) {
          roleGuid = role.guid;
        }
      });
      const sortedRoles = this.roles.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase());
      this.SET_ALL_ROLES(sortedRoles);
      this.$router.push({ name: 'RoleDetails', params: { guid: roleGuid } });

      analytics.setVarAndTrack({ c: 'RoleAddition', l: encodeURIComponent(this.role.name), a: 'add' });
      this.$gtag.event('RoleAddition', {
        event_category: 'add',
        event_label: encodeURIComponent(this.role.name),
        value: 1
      });
    },
    handleInputChange(value) {
      this.errorMsg = '';
      const importance = parseInt(value) < 0 ? 0 : parseInt(value) > 100 ? 100 : parseInt(value);
      if (importance == 0) {
        this.errorMsg = 'Skill importance must be more than 0';
        return;
      }
      this.editImportance = importance;
      this.role.skills[this.selectSkillIndex].importance = importance;
    },
    handleSelectChange(option) {
      this.option = option;
      this.searchSelected = false;
      this.selectedRoles = [];
      this.onClearSearchRole();
    },
    onClearSearchRole() {
      this.errorMsg = '';
      this.searchRole = '';
      this.foundRoles = [];
      this.searchCompleted = false;
    },
    async onSearchRole() {
      this.errorMsg = '';
      this.searchSelected = false;
      if (this.searchRole.length) {
        const role = encodeURIComponent(this.searchRole).replace(/'/g, '%27').replace(/"/g, '%22');
        this.foundRoles = await this.$planAdmin.searchRole({ role: role });
        if (this.foundRoles.error) {
          this.errorMsg = this.foundRoles.message;
          this.foundRoles = [];
        } else {
          await this.selectedRoles.map((selected) => {
            const index = this.foundRoles.findIndex((found) => found.guid == selected.guid);
            if (this.option.code === 2 && index > -1) {
              this.foundRoles.splice(index, 1);
            }
          });
          analytics.setVarAndTrack({ c: 'RoleAddition', l: this.searchRole, a: 'search exisiting role for cloning' });
          this.$gtag.event('RoleAddition', {
            event_category: 'search exisiting role for cloning',
            event_label: this.searchRole,
            value: 1
          });
        }
        this.searchCompleted = true;
      }
    },
    onSelectRole(role) {
      this.searchSelected = true;
      this.option.code === 1 ? this.selectedRoles.splice(0, 1, role) : this.selectedRoles.push(role);
      this.option.code === 1 ? this.onCloneRole() : this.onCompositeRole(role);
    },
    async onDeselectRole(role) {
      const index = this.selectedRoles.findIndex((selected) => selected.guid == role.guid);
      this.selectedRoles.splice(index, 1);
      const skills = await this.$planAdmin.getRoleSkills({ guid: role.guid });
      await skills.map((skill) => {
        const index = this.role.skills.findIndex((rskill) => rskill.guid === skill.guid);
        if (index > -1) {
          this.role.skills.splice(index, 1);
        }
      });
    },
    async onCloneRole() {
      this.isMatching = true;
      this.role = await this.$planAdmin.getCloneRoleByGuid({ roleGuid: this.selectedRoles[0].guid });
      this.role.name = this.role.name + '_Clone';
      this.matchSkill = true;
      this.isMatching = false;
      this.createRole = true;
      analytics.setVarAndTrack({ c: 'RoleAddition', l: this.selectedRoles[0].guid, a: 'clone searched role' });
      this.$gtag.event('RoleAddition', {
        event_category: 'clone searched role',
        event_label: this.selectedRoles[0].guid,
        value: 1
      });
    },
    async onCompositeRole(role) {
      const skills = await this.$planAdmin.getRoleSkills({ guid: role.guid });
      this.role.skills = [...this.role.skills, ...skills];
    },
    async handleFunctionChange(val) {
      await handleRoleFunctionChange(this, val);
    },
    async handleDepartmentChange(val) {
      await handleRoleDepartmentChange(this, val);
    },
    async downloadOrgTemplate() {
      const blob = await this.$planAdmin.downloadOrganizationStructureTemplate();
      this.downloadTemplate(blob, 'org_template.xlsx');
    },
    async downloadSkillTemplate() {
      const blob = await this.$planAdmin.downloadRoleSkillUploadTemplate();
      this.downloadTemplate(blob, 'role_skill_upload_template.csv');
    },
    async downloadCredentialTemplate() {
      const blob = await this.$planAdmin.downloadCredentialStructureTemplate();
      this.downloadTemplate(blob, 'role_credential_upload_template.csv');
    },
    async downloadCourseTemplate() {
      const blob = await this.$planAdmin.downloadCourseStructureTemplate();
      this.downloadTemplate(blob, 'role_course_upload_template.csv');
    },
    async downloadContentTemplate() {
      const blob = await this.$planAdmin.downloadContentStructureTemplate();
      this.downloadTemplate(blob, 'role_content_upload_template.csv');
    },
    async downloadTemplate(blob, fileName) {
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.setAttribute('download', fileName);
      a.click();
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.roleAddition')
    };
  }
};
</script>

<style lang="scss" scoped>
.card {
  margin: 0px;
  padding: 1rem 1.5rem;
  border-radius: 10px;

  .role-description {
    margin-top: 0.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden; /* hide text if it more than N lines  */
    position: relative; /* for set '...' in absolute position */
    line-height: 1.2em; /* use this value to count block height */
    max-height: 112px; /* max-height = line-height (1.2) * lines max number (3) */
    text-align: justify; /* fix problem when last visible word doesn't adjoin right side  */
    margin-right: -1em; /* place for '...' */
    padding-right: 1em;
  }
  .role-description:before {
    content: ''; /* points in the end */
    position: absolute; /* absolute position */
    right: 0; /* set position to right bottom corner of block */
    bottom: 0;
  }
  .role-description:after {
    content: ''; /* points in the end */
    position: absolute; /* absolute position */
    right: 0; /* set position to right bottom corner of text */
    width: 1em; /* set width and height */
    height: 1em;
    margin-top: 0.2em;
    background: white; /* bg color = bg color under block */
  }
}

.card-title {
  font-weight: bold;
  color: $color-blue;
  text-transform: none;
}

.h2 {
  font-weight: 700;
  color: #1f3370 !important;
  text-transform: none;
  font-size: 1.25rem !important;
}

.role-title {
  font-size: 1rem;
  font-weight: bold;
  color: $color-grey;
}

.asterisk {
  color: red;
}

.cancel-button {
  width: 100px;
  background: $button-secondary-color-bg !important;
  color: $button-secondary-color-text !important;
  text-transform: capitalize;
}

.error-msg {
  padding: 0.5rem 0rem;
  color: $color-error;
}

.loader {
  margin: 20px 50px;
  text-align: center;

  .loader-text {
    font-size: 30px;
    color: $color-primary;
  }

  .loader-bar {
    position: relative;
    top: -30px;
  }
}

.skill-panel-header {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 0.25rem 0rem;
  border-bottom: 1px solid $color-grey-light;

  .skill-importance {
    width: 100px;
    margin-right: 5px;
    border-radius: 30px;
  }
}

.skill-panel-container {
  height: 390px;
  padding-bottom: 10px;
  overflow: hidden;
  overflow-y: auto;
}

.progress-bar {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 16px !important;
  cursor: pointer;

  .progress {
    width: 30%;
    margin-right: 10px;
    left: auto !important;
    transform: none !important;
  }

  .progress-text {
    width: 70%;
    text-align: left;
  }
}

.select {
  background: $color-focus;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.future-skill {
  font-weight: bold;
}

.none-skill {
  color: #5c5c5c;
}

.skill-detail {
  padding: 30px 20px 10px 20px;
  color: $color-grey;
  height: 390px;
  overflow: hidden;
  overflow-y: scroll;
}
.skill-detail-title {
  font-weight: bold;
  font-size: 1.1rem;
}
.skill-detail-text {
  font-size: 0.9rem;
}
.skill-detail-importance {
  font-size: 1.5rem;
}

.option-select {
  margin: 0.5rem 0;
  width: 55%;
}

.scrollable {
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: $color-grey-lighter;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 20px;
    width: 4px;
  }
}

.search-role-panel {
  margin: 1rem 0rem 0rem 0rem;
  border: 1px solid $color-primary;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  max-height: 350px;
  overflow: hidden;
  overflow-y: auto;
  width: 40%;
}

.success {
  font-size: 25px;
  text-align: center;
  border-radius: 20px;
  color: $color-white;
}

.select-button {
  width: 80px;
  height: 25px !important;
  background: $button-primary-color-bg !important;
  color: $button-primary-color-text !important;
  text-transform: capitalize;
}

.selected-role {
  float: left;
  width: fit-content;
  margin-right: 0.5rem;
  padding: 0.15rem 1rem;
  background: $color-data-1-lighter;
  color: $color-data-1-darker;
  border-radius: 20px;
}

.confirmation-upload {
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 460px;
  z-index: 3040;
  .v-card {
    padding: 20px;
    h4,
    p {
      font-size: 16px;
    }
    h4 {
      margin: 0 0 20px;
    }
  }
  .no-gutters {
    justify-content: flex-end;
    align-items: center;
    button {
      margin-right: 20px;
    }
  }
}

.confirmation-upload-success {
  overflow: hidden;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 460px;
  z-index: 3040;
  .v-card {
    height: 270px;
    padding: 100px;
  }
  .no-gutters {
    justify-content: flex-end;
    align-items: center;
    button {
      margin-right: 20px;
    }
  }
}

.save-button {
  width: 100px;
  background: $button-primary-color-bg !important;
  color: $button-primary-color-text !important;
  text-transform: capitalize;
  &.btm-btn {
    position: absolute;
    bottom: 30px;
    right: 10px;
  }
}

.uploading-errors {
  overflow-y: scroll;
  height: 65%;
  margin-bottom: 10px;
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      &.smaller {
        font-size: smaller;
      }
      padding: 7px;
      &:nth-child(odd) {
        background: #e0e1e1;
      }
    }
  }
}

.circle-add-btn {
  width: 30px;
  height: 30px;
  position: relative;
  top: 0.2rem;
}

.dialog-title {
  font-size: 1rem !important;
  font-weight: bold !important;
  padding: 0rem !important;
}

.dialog-content {
  padding: 0.2rem 0rem 0.5rem 0rem;
}

.important-error {
  padding-top: 1rem;
  color: $color-data-2;
}

#add_new_role-cme {
  overflow-y: hidden;
  height: 40px;
}
</style>
<style lang="scss">
.options-select-container {
  .v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense .v-select__selections {
    input {
      &::-webkit-input-placeholder {
        color: #000;
      }
    }
  }

  @media screen and (max-width: 960px) {
    .option-select {
      width: 100%;
    }
  }
}
</style>
