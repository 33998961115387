<template>
  <v-card class="notes-section">
    <div class="notes-section-title"><svg-icon icon-class="edit-title" class="edit-title" />Case Notes</div>
    <div v-if="!notes.length" class="no-note">No note has been made</div>
    <v-card-text>
      <ul>
        <li v-for="(note, index) in notes" :key="index">
          <span class="action">{{ note.note }}</span>
          <span class="meta"> {{ localizedDate24Hour(moment, note.createdAt) }} {{ $t('common.utc') }}</span>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
import { localizedDate24Hour } from '@/utils';

export default {
  name: 'NotesSection',
  props: {
    id: {
      type: String,
      required: true,
      default: ''
    },
    caseNotes: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      notes: [],
      disabled: true
    };
  },
  async created() {
    this.notes = this.caseNotes || [];
  },
  methods: {
    localizedDate24Hour
  }
};
</script>
<style lang="scss" scoped>
.notes-section {
  padding: 25px 40px;
  .no-note {
    padding: 0px 20px;
    color: $color-grey;
  }
  .notes-section-title {
    font-size: 25px;
    font-weight: bold;
    .edit-title {
      width: 60px;
      height: 60px;
      vertical-align: middle;
      margin-left: -10px;
    }
  }
  .v-card-text {
    padding: 0 30px;
    height: 265px;
    overflow-y: scroll;

    /* width */
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #d8d8d8;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #4c5c8d;
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #4c5c8d;
    }

    ul {
      padding: 0;
      margin: 0 2%;
    }
    li {
      position: relative;
      list-style-type: none;
      min-height: 40px;
      margin: 7px 25px 0 0;
      display: block;
      background: #e9ebf0;
      box-shadow: 2px 2px 2px #ccc;
      border-radius: 3px;
      padding: 15px;
      span.action {
        color: #000;
        display: block;
        margin-bottom: 15px;
        font-size: 16px;
      }
      span.meta {
        position: absolute;
        bottom: 5px;
        right: 10px;
        font-size: smaller;
      }
    }
  }
  .feedback-section {
    position: relative;
    padding: 10px 0px;
  }
  .feedback {
    border: 1px solid #eaeaea;
    border-radius: 30px;
    width: 100%;
    padding: 5px;
    outline: none;
    height: 40px;
    box-shadow: 2px 4px 4px #ccc;
  }
  .feedback-submit {
    position: absolute;
    top: 0.5em;
    right: 0.25em;
    width: 2.4em;
    height: 2.4em;
    .send-btn {
      width: 3em;
      height: 3em;
      outline: none;
      &.disabled {
        opacity: 0.5;
      }
    }
  }
}
</style>
