<template>
  <div class="drop-filter-container">
    <div class="dashboard-enrollment-header-filter filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black">
      <v-col class="filter-cols t-relative" id="time-filter-dropdown" :style="filter.showExpandedFilter ? 'zIndex:1' : 'zIndex:2'">
        <v-col role="combobox" tabindex="0" class="allFilter" @click="openDropdown()" @keydown.enter="openDropdown()">
          <div class="filter">
            <div :class="filter.showExpandedFilter ? 't-border-black' : ''" class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black">
              <span class="t-font-arial t-text-sm" :style="filter.disabled ? 'color:rgb(0, 0, 0, 0.2)' : ''">{{ $t(selectedOption) }}</span>
              <v-icon :style="filter.disabled ? 'opacity:0.3' : ''">mdi-chevron-down</v-icon>
            </div>
          </div>
        </v-col>
        <div class="time-filter-dropdown" v-if="filter.showExpandedFilter">
          <v-radio-group v-model="timeOption">
            <v-radio v-for="option in filter.options" :key="option.id" :label="$t(option.translationKey)" :value="$t(option.translationKey)"></v-radio>
            <div v-if="filter.options && filter.options.length === 5" class="date-range">
              <div class="date-label">From</div>
              <date-picker
                v-model:value="fromDate"
                placeholder="DD Mon YYYY"
                confirm
                confirm-text="Select"
                @confirm="onSelectDate($event, 'fromDate')"
                format="D MMM YYYY"
                title-format="D MMM YYYY"
                :clearable="false"
                :editable="false"
                :disabled-date="disableDatesFrom"
                :disabled="disabledDatePicker"
              ></date-picker>
            </div>
            <div v-if="filter.options.length === 5" class="date-range">
              <div class="date-label">To</div>
              <date-picker
                v-model:value="toDate"
                placeholder="DD Mon YYYY"
                confirm-text="Select"
                confirm
                @confirm="onSelectDate($event, 'toDate')"
                format="D MMM YYYY"
                title-format="D MMM YYYY"
                :clearable="false"
                :editable="false"
                :disabled-date="disableDatesTo"
                :disabled="disabledDatePicker"
              ></date-picker>
            </div>
          </v-radio-group>
          <div class="filter-option-footer">
            <div class="filter-option-footer-cancel" @click="onCancel()">{{ $t('insights.cancelButton') }}</div>
            <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" :disabled="disableApply" @click="onApply()">{{ $t('insights.applyButton') }}</v-btn>
          </div>
        </div>
      </v-col>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
export default {
  emits: ['onFilterChange'],
  components: { DatePicker },
  props: {
    filter: {
      type: Object
    },
    selectedOption: {
      type: String
    },
    openDropdown: {
      type: Function
    },
    onCancel: {
      type: Function
    },
    onApply: {
      type: Function
    }
  },
  data() {
    return {
      timeOption: this.selectedOption,
      fromDate: null,
      toDate: null,
      disabledDatePicker: true,
      disableApply: false
    };
  },
  watch: {
    timeOption() {
      this.disabledDatePicker = this.timeOption.toLowerCase() !== 'date range';
      if (this.disabledDatePicker) {
        this.fromDate = '';
        this.toDate = '';
        this.disableApply = false;
      } else {
        this.disableApply = this.fromDate === '' || this.toDate === '';
      }
      this.$emit('onFilterChange', this.timeOption);
    },
    selectedOption() {
      this.timeOption = this.selectedOption;
    }
  },
  methods: {
    onSelectDate() {
      this.filter.options[4].startDate = this.fromDate;
      this.filter.options[4].endDate = this.toDate;
      this.disableApply = this.fromDate === '' || this.toDate === '';
    },
    disableDatesFrom(date) {
      return date > new Date() || this.toDate ? date > this.toDate : '';
    },
    disableDatesTo(date) {
      return date > new Date() || date < this.fromDate;
    }
  }
};
</script>

<style lang="scss">
.mx-datepicker-popup {
  z-index: 100000;
}
.mx-calendar {
  width: 332px;
}
.mx-calendar-content {
  height: 308px !important;
}
.mx-calendar-header {
  .mx-btn-text {
    color: #5c5c5c;
    font: normal normal bold 1.5rem source serif;
  }
}
.mx-table {
  th {
    color: #2d2d2d;
    border-bottom: 1px solid #d6d6d6;
    font: normal normal 1rem source serif;
  }
  td {
    color: #5c5c5c;
    font: normal normal bold 1.5rem source serif;
    div {
      padding: 6px;
    }
  }
  .cell.active {
    background-color: #ffffff;
    div {
      color: #000000;
      background-color: #ffb92e;
      border-radius: 30px;
    }
  }
}
.mx-datepicker-footer {
  .mx-btn {
    color: #000000;
    background: #ffb92e;
    border: unset;
    border-radius: 20px;
    font: normal normal 1rem Source Sans Bold;
  }
}
</style>

<style lang="scss" scoped>
.drop-filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .dashboard-enrollment-header-filter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    height: 2.5rem;
    border: 0.125rem solid rgba(0, 0, 0, 0.2);

    .allFilter {
      width: max-content;
      padding: 0;
    }

    .allFilter:focus-visible {
      height: 2.5rem;
      max-width: 10rem;
      border: 0.125rem solid rgba(0, 0, 0, 0.2);
      width: 9.375rem;
      border-radius: 9999px !important;
      display: block;
      padding: 6px 12px 0;
      align-items: center;
      margin: -0.5rem -0.875rem;
    }

    #time-filter-dropdown {
      .time-filter-dropdown {
        width: max-content;
        background-color: $color-skill-table !important;
        border-radius: 1.25rem;
        box-shadow: 0.125rem 0.1875rem 0.0625rem -0.125rem rgb(0 0 0 / 20%), 0 0.125rem 0.125rem 0 rgb(0 0 0 / 14%), 0 0.3125rem 0.3125rem 0 rgb(0 0 0 / 12%);
        position: absolute;
        z-index: 2 !important;
        right: 0;
        top: 3rem;
        padding-top: 1rem;
        .v-radio {
          padding: 0rem 1rem 0 5px;
          max-height: 32px;
          // .v-selection-control__wrapper {
          //   height: 24px;
          //   width: 24px;
          // }
          &:nth-child(5) {
            margin-top: 0.5rem;
          }
        }
        .filter-option-footer {
          display: flex;
          gap: 3rem;
          padding: 0.825rem 1.5rem 0.875rem 1.5rem;
          align-items: center;
          border-top: 0.0625rem solid #e0e1e1;
          .filter-option-footer-cancel {
            font-family: 'Futura PT Demi';
            font-size: 1rem;
            color: #1f3370;
            cursor: pointer;
          }
          .filter-option-footer-btn-apply {
            cursor: pointer;
            text-transform: none;
            width: 5.75rem;
            box-shadow: none;
          }
        }
        .date-range {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 10px;
          padding: 0 1rem;
          .date-label {
            font: normal normal bold 1rem Arial;
            color: #2d2d2d;
          }
        }
      }
      span {
        display: flex;
        align-items: center;
        text-align: left;
        font: normal normal bold 0.875rem/1.875rem Arial;
        letter-spacing: -0.0088rem;
        color: #000000;
        opacity: 1;
        padding-right: 1rem;
      }
      i {
        color: #000000;
      }
    }
  }

  .dashboard-details-search-chips {
    padding: 0 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
</style>
