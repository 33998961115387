<template>
  <div :class="$route.path === '/catalog/stepper/edit-due-dates' && trackGuid ? 'footer-container-track footer-container-flex' : $route.path === '/catalog/stepper/edit-due-dates' && contentId ? 'footer-container-track t-content-center' : 'footer-container-track'">
    <div v-if="$route.path === '/catalog/stepper/edit-due-dates' && trackGuid" class="csv-download">
      {{ $t('editDueDates.csvDownloadForDuedates') }}
      <div class="csv-icon-container" @click="downloadCsv">
        <svg-icon icon-class="csv-download" />
      </div>
    </div>
    <div class="footer-buttons-track t-flex t-justify-end t-relative" :class="$route.path === '/catalog/stepper/edit-due-dates' ? '' : 't-top-4'">
      <button class="button-style t-text-primary-color" @click="cancelNavigationPath">{{ $t('track.cancel') }}</button>
      <button v-if="$route.path === '/catalog/stepper/organize' && !trackGuid" class="button-style re-craete-btn t-rounded-full t-text-primary-color t-ml-6 t-text-center t-py-4 t-px-8 t-flex t-justify-center t-items-center" @click="reCreateTrack">
        {{ $t('track.recreate') }}
      </button>
      <button :disabled="getSaveButtonState()" @click="saveAndContinue" class="button-style save-and-continue t-ml-4 t-mx-1 t-color-white t-py-3 min-w-min t-px-6 t-rounded-full t-bg-credential t-border-credential hover:t-bg-primary-color hover:t-text-white">
        {{ this.$route.path.includes('/settings/company-logo') ? $t('track.save') : this.$route.path === '/catalog/stepper/edit-due-dates' ? $t('track.saveExit') : this.$route.path === '/catalog/stepper/create' && !trackGuid ? $t('track.createTrack') : $t('track.saveContinue') }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {},
  name: 'CommonStepperFooter',
  computed: {
    ...mapState('stepper', ['tabs']),
    ...mapState('user', ['companyLogoSaveBtnState']),
    ...mapState('track', ['trackGuid', 'roleGuid', 'trackList', 'totalItemsInTrack', 'trackSaveButtonState', 'trackName', 'saveExitButton', 'trackDescription', 'contentId'])
  },
  methods: {
    ...mapActions('track', ['setReCreateTrack']),
    getSaveButtonState() {
      switch (this.$route.path) {
        case '/catalog/stepper/manage':
          return true;
        case '/catalog/stepper/edit':
          return this.trackSaveButtonState || this.trackList.length === 0;
        case '/catalog/stepper/organize':
          return this.saveExitButton ? false : this.trackSaveButtonState || this.trackList.length === 0;
        case '/catalog/stepper/create':
          return !this.trackSaveButtonState || !this.trackName || !this.trackDescription;
        case '/settings/company-logo':
          return !this.companyLogoSaveBtnState;
      }
    },
    reCreateTrack() {
      this.EventBus.off('save-track-edit-content-organize');
      this.setReCreateTrack(true);
      this.$router.push({ path: '/catalog/stepper/create' });
    },
    cancelNavigationPath() {
      if (this.$route.path == '/settings/company-logo') {
        this.EventBus.emit('cancel-company-logo');
      } else {
        this.$router.push({ path: '/catalog/landing' });
      }
    },
    saveAndContinue() {
      switch (this.$route.path) {
        case '/catalog/stepper/manage':
          return true;
        case '/catalog/stepper/edit':
          this.EventBus.emit('save-track-edit-content');
          break;
        case '/settings/company-logo':
          this.EventBus.emit('upload-company-logo');
          break;
        case '/catalog/stepper/organize':
          this.EventBus.emit('save-track-edit-content-organize');
          break;
        case '/catalog/stepper/create':
          this.EventBus.emit('save-track-edit-details');
          break;
        case '/catalog/stepper/edit-due-dates':
          this.EventBus.emit('save-track-edit-due-dates');
          break;
      }
    },
    downloadCsv() {
      this.EventBus.emit('download-csv-for-due-dates');
    }
  }
};
</script>
<style lang="scss">
.footer-container-track {
  width: 100%;
  background: #fff;
  height: 4.6875rem;
  position: sticky;
  bottom: 0;
  z-index: 1;

  .footer-buttons-track {
    margin-right: 2.5rem;
    .button-style {
      @include body-font-bold-1;
    }
  }
  .re-craete-btn {
    border: 2px solid var(--Border-Border-Midnight, #1f3370);
  }
  .save-and-continue:disabled {
    background-color: rgba(255, 185, 46, 0.5) !important;
    color: rgba(31, 51, 112, 0.26);
    pointer-events: none;
  }
}

.footer-container-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .csv-download {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 2rem;
    font-family: 'source sans';
    font-size: 1rem;
    color: #1f3370;
    font-weight: 700;
    .csv-icon-container {
      height: 40px;
      width: 40px;
      border: 1px solid #d6d6d6;
      border-radius: 200px;
      padding: 8px;
      cursor: pointer;
      .svg-icon {
        height: 24px !important;
        width: 24px !important;
      }
    }
  }
}
</style>
