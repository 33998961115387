<!-- DEPRECATED -->
<template>
  <div class="dashboard-details-pagination-container">
    <div class="insights-page-size-select-container">
      <SelectView class="insights-pagination-select" v-model="sizeOfPage" :items="paginationSelectOptions" dense></SelectView>
      <span>{{ $t('common.rowsPerPage') }}</span>
    </div>
    <paginate
      v-if="totalPages > 1"
      v-model="pageNumber"
      :page-count="totalPages"
      :page-range="pageRange"
      :margin-pages="1"
      :click-handler="handlePageClick"
      :container-class="'pagination'"
      :page-class="'pagination-item'"
      :page-link-class="'pagination-item-link'"
      :prev-text="'&lsaquo;'"
      :prev-class="'pagination-nav pagination-tooltip'"
      :prev-link-class="'pagination-nav-link'"
      :next-text="'&rsaquo;'"
      :next-class="'pagination-nav pagination-tooltip'"
      :next-link-class="'pagination-nav-link'"
      :hide-prev-next="true"
    />
  </div>
</template>
<script>
import SelectView from '@/components/molecules/SelectView';

export default {
  name: 'InsightsPagination',
  components: { SelectView },
  props: {
    totalPages: { type: Number },
    pageRange: { type: Number },
    pageNum: { type: Number },
    pageSize: { type: Number },
    pageSizeChangeEvent: { type: String },
    pageNumberChangeEvent: { type: String },
    handlePageClick: { type: Function }
  },
  data() {
    return {
      pageNumber: this.pageNum,
      sizeOfPage: this.pageSize,
      paginationSelectOptions: [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    };
  },
  watch: {
    pageNum() {
      this.pageNumber = this.pageNum;
    },
    pageNumber() {
      this.EventBus.emit(this.pageNumberChangeEvent, this.pageNumber);
    },
    pageSize() {
      this.sizeOfPage = this.pageSize;
    },
    sizeOfPage() {
      this.EventBus.emit(this.pageSizeChangeEvent, this.sizeOfPage);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list {
  padding: 0px;
}
</style>

<style lang="scss">
.dashboard-details-pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #efefef 0% 0% no-repeat padding-box;
  position: relative;
  height: 8.25rem;

  .insights-page-size-select-container {
    display: flex;

    .insights-pagination-select {
      .v-input__slot {
        &:before,
        &:after {
          display: none !important;
        }
      }
    }

    .v-select.v-input--dense .v-select__selection--comma {
      margin: 0px !important;
    }

    .v-text-field__details {
      display: none;
    }

    .v-select-list {
      padding: 0px !important;
    }
    .v-select__slot,
    select {
      outline: none;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 0.125rem;
      opacity: 1;
      width: 3.625rem;
      height: 1.75rem;
      padding-left: 0.25rem;
      font: normal normal bold 0.875rem/1.875rem Arial;
      letter-spacing: 0rem;
      color: #101a38;
    }

    span {
      font: normal normal normal 0.875rem/1.875rem Arial;
      letter-spacing: 0rem;
      color: #5c5c5c;
      opacity: 1;
      margin-left: 1rem;
    }
  }

  .pagination {
    display: flex;
    list-style-type: none;
    padding: 0rem;
    justify-content: center;
    width: 50%;
  }

  .pagination-item {
    margin: 0 0.313rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  li.pagination-nav {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .pagination-item-link {
    color: var(--ion-color-post-gray);
    font-size: 0.875rem !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination-nav-link {
    position: relative;
    color: #5c5c5c;
    font-size: 3rem !important;
    vertical-align: middle;
    font-family: 'Futura PT Book';
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.9375rem;
  }

  li.disabled {
    border: none;
    cursor: default;
    .pagination-item-link {
      cursor: default;
    }
  }

  li.active {
    border: 0.125rem solid #1dd1b0;
    border-radius: 0.25rem;
    background: white;
    .pagination-item-link {
      color: #000000;
    }
  }

  li:hover {
    background: #e0e1e1;
  }

  li .tooltiptext {
    visibility: hidden;
    width: max-content;
    height: 1.875rem;
    background-color: #efefef;
    color: #000000de;
    text-align: center;
    padding: 0.4375rem 0.625rem;
    border-radius: 0.25rem;
    position: absolute;
    z-index: 1;
    bottom: 90%;
    left: 50%;
    margin-left: -1.25rem;
    font-size: 0.75rem;
    font-family: 'Futura PT Demi';
  }

  .li:hover .tooltiptext {
    visibility: visible;
  }

  .pagination-tooltip {
    position: relative;
  }

  .pagination-tooltip::after {
    content: attr(data-tooltip-content);
    padding: 0.5rem;
    border-radius: 0.25rem;
    position: absolute;
    color: white;
    bottom: 135%;
    right: 50%;
    left: 50%;
    width: max-content;
    opacity: 0;
    font-size: 0.8rem;
    visibility: hidden;
    transform: translate(-50%, 1.125rem) scale(0.8);
    transition: visibility, opacity, transform 250ms;
    text-align: left;
    font: normal normal normal 0.75rem/1rem Arial;
    letter-spacing: 0rem;
    color: #000000de;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.125rem 0.375rem #0000001f;
    border-radius: 0.25rem;
  }

  .pagination-tooltip:hover::after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
  }

  .pagination-buttons {
    position: absolute;
    right: 0;

    .v-btn--is-elevated {
      border: none !important;
      box-shadow: none !important;
      border-radius: 1.3125rem;
      backdrop-filter: blur(1.25rem);
      -webkit-backdrop-filter: blur(1.25rem);
    }

    .add-people-button {
      width: 8rem;
      height: 2.9375rem;
      background: #1f3370 0% 0% no-repeat padding-box;
      background: #ffb92e 0% 0% no-repeat padding-box;

      span {
        font: normal normal normal 1rem/1.375rem 'Futura PT Demi';
        letter-spacing: 0.0013rem;
      }

      &:disabled {
        border: 0.125rem solid #c4c4c4;
        span {
          color: inherit;
        }
      }
    }
  }
}
</style>
