import axios from 'axios';
import { getMetaData } from '../utils/helper';

export default {
  async basicReportingPostRequest(url, payload) {
    const { jsonHeaders } = await getMetaData();
    const response = await axios.post(url, { ...payload }, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicReportingGetRequest(url) {
    const { jsonHeaders } = await getMetaData();
    const response = await axios.get(url, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  }
};
