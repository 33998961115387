<template>
  <div class="modal-container">
    <v-dialog v-model="isLogin" max-width="500px" activator="parent" transition="none">
      <template v-slot:activator="{ props }">
        <v-btn id="nav_bar-login-button" name="nav_bar-login-button" data-el="loginModal-landing-login" color="#ffffff" dark rounded v-bind="props" class="black--text" style="min-width: 100px" v-track="'button,Login Modal,open'">{{ $t('button.login') }}</v-btn>
      </template>
      <v-card class="login-modal">
        <v-toolbar role="presentation" flat>
          <v-toolbar-title><img alt="pro edge" src="@/assets/logos/Proedge_Logo.svg" style="margin: auto; display: block; height: 50px" /></v-toolbar-title>
          <v-toolbar-items>
            <v-btn color="error" aria-label="close" class="cancel-btn" title="close" icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-alert v-model="isAlert" :type="alertType" border="start" close-text="Close Alert" role="alert" dark dismissible>{{ alertMsg }}</v-alert>
            <img alt="" src="@/assets/images/svgs/FPO_4.svg" class="modal-image" />
            <v-row>
              <v-col cols="12" class="form-field">
                <v-text-field variant="underlined" data-el="loginModal-email" v-model="username" v-on:keyup="checkSSOByEmail" :label="$t('common.titleCase.email')" autofocus required></v-text-field>
              </v-col>
              <v-col cols="12" class="form-field" v-show="showPasswordField">
                <form @submit.prevent="submit">
                  <v-text-field
                    data-el="loginModal-password"
                    v-model="password"
                    :label="$t('common.titleCase.password')"
                    required
                    @keypress.enter="onLogin"
                    :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (showPwd = !showPwd)"
                    :type="showPwd ? 'text' : 'password'"
                    variant="underlined"
                    autocomplete
                  ></v-text-field>
                </form>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row class="text-center" style="margin: 0">
          <v-col cols="12">
            <v-btn data-el="loginModal-login" class="btn" color="#ffb92e" rounded :loading="loading" @click="onLogin" :disabled="loading">{{ $t('button.login') }}</v-btn>
          </v-col>
          <v-col cols="12">
            <button @click="onResetPwd" class="form-field form-field-reset-pwd" v-track="'link,Login Modal, Reset Password'">{{ $t('button.resetPassword') }}</button>
          </v-col>
          <v-col class="disclaimer">
            <div>{{ $t('phrase.disclaimer.nonRepresentative') }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isVerifyToLogin" max-width="500px">
      <v-card class="reset-modal">
        <v-toolbar role="presentation" flat>
          <v-toolbar-title><img src="@/assets/logos/Proedge_Logo.svg" style="margin-top: 1rem; height: 50px" /></v-toolbar-title>
          <v-toolbar-items>
            <v-btn color="error" class="cancel-btn" icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-alert v-model="isAlert" :type="alertType" border="start" close-text="Close Alert" role="alert" dark dismissible>{{ alertMsg }}</v-alert>
            <img src="@/assets/images/svgs/FPO_4.svg" class="modal-image" />
            <v-row>
              <v-col cols="12" class="form-field">
                <v-card-text style="padding: 0rem 1rem 0.5rem 1rem; font-size: 0.95rem">{{ $t('loginModal.weSentVerificationCode') }}</v-card-text>
              </v-col>
              <v-col cols="12" class="form-field">
                <v-text-field data-el="loginModal-vericode" v-model="vericode" :label="$t('loginModal.verificationCode')" autofocus required @keypress.enter="onVerifyToLogin"></v-text-field>
                <v-checkbox data-el="loginModal-trustedDevice" v-model="trustedDevice" :label="$t('loginModal.trustThisDevice')">{{ $t('loginModal.trustThisDevice') }}</v-checkbox>
                <v-card-text style="padding: 1rem 2rem 0rem 2rem; font-size: 0.95rem">{{ $t('loginModal.30DaysReprieve') }}</v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row class="text-center">
          <v-col cols="12" style="padding: 0">
            <v-btn data-el="loginModal-verify" color="#ffb92e" rounded @click="onVerifyToLogin">{{ $t('loginModal.verify') }}</v-btn>
          </v-col>
          <v-col cols="12">
            <button @click="onResetPwd" class="form-field form-field-reset-pwd" v-track="'link,Login Modal, Reset Password'">{{ $t('loginModal.resetPassword') }}</button>
          </v-col>
          <v-col class="disclaimer" style="padding-bottom: 1rem">
            <div>{{ $t('loginModal.disclaimer') }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isResetPwd" max-width="500px">
      <v-card class="reset-modal">
        <v-toolbar role="presentation" flat>
          <v-toolbar-title style="margin-left: 30%"> {{ $t('loginModal.resetPasswordAllCaps') }}</v-toolbar-title>
          <v-toolbar-items>
            <v-btn color="error" class="cancel-btn" icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-alert v-model="isAlert" :type="alertType" border="start" close-text="Close Alert" role="alert" dark dismissible>{{ alertMsg }}</v-alert>
            <img src="@/assets/images/svgs/FPO_4.svg" class="modal-image" />
            <v-row>
              <v-col cols="12" class="form-field">
                <v-card-text>{{ $t('loginModal.enterUsernameforCode') }}</v-card-text>
              </v-col>
              <v-col cols="12" class="form-field">
                <v-text-field v-model="username" :label="$t('loginModal.email')" autofocus required @keypress.enter="onReset"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row class="text-center">
          <v-col cols="12">
            <v-btn class="btn" color="#ffb92e" rounded @click="onReset" v-track="'button,Login Modal,Reset'">{{ $t('loginModal.reset') }}</v-btn>
          </v-col>
          <v-col class="disclaimer" style="padding: 1rem 0">
            <div>{{ $t('loginModal.disclaimer') }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isVerify" max-width="500px">
      <v-card class="reset-modal">
        <v-toolbar role="presentation" flat>
          <v-toolbar-title style="margin-left: 30%">{{ $t('loginModal.resetPasswordAllCaps') }}</v-toolbar-title>
          <v-toolbar-items>
            <v-btn color="error" class="cancel-btn" icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-alert v-model="isAlert" :type="alertType" border="start" close-text="Close Alert" role="alert" dark dismissible>{{ alertMsg }}</v-alert>
            <img src="@/assets/images/svgs/FPO_4.svg" class="modal-image" />
            <v-row>
              <v-col cols="12" class="form-field">
                <v-card-text>{{ $t('loginModal.confirmReset') }}</v-card-text>
              </v-col>
              <v-col cols="12" class="form-field">
                <v-text-field v-model="username" :label="$t('loginModal.email')" autofocus required></v-text-field>
              </v-col>
              <v-col cols="12" class="form-field">
                <v-text-field v-model="vericode" :label="$t('loginModal.verificationCode')" required></v-text-field>
              </v-col>
              <v-col cols="12" class="form-field">
                <v-text-field v-model="password" :label="$t('loginModal.password')" required :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (showPwd = !showPwd)" :type="showPwd ? 'text' : 'password'"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row class="text-center">
          <v-col cols="12">
            <v-btn color="#ffb92e" rounded @click="onConfirm">{{ $t('loginModal.confirmResetShort') }}</v-btn>
          </v-col>
          <v-col class="disclaimer" style="padding: 1rem 0">
            <div>{{ $t('loginModal.disclaimer') }}</div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import localforage from 'localforage';
import { getCloudEnvFeatureFlag, loginFlow, checkMaintenanceFlag } from '@/utils';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'LoginModal',
  props: {
    error: {
      type: Object,
      required: false,
      default: () => {
        return {
          exist: false,
          message: ''
        };
      }
    }
  },
  data() {
    return {
      isC1: null,
      loading: false,
      isLogin: false,
      isResetPwd: false,
      isVerify: false,
      isVerifyToLogin: false,
      token: '',
      username: '',
      password: '',
      vericode: '',
      isAlert: false,
      alertMsg: translate('loginModal.enterValidUsernamePassword'),
      alertType: 'error',
      showPwd: false,
      isPlanCreated: false,
      showPasswordField: false,
      sso: null,
      incorrectLoginAttempt: 0,
      trustedDevice: false,
      trustedDeviceId: ''
    };
  },
  async mounted() {
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
  },
  async created() {
    this.isPlanCreated = (await localforage.getItem('my_plan_created')) || this.planCreated;
    if (this.error.exist) {
      this.isLogin = true;
      this.alert('error', this.error.message);
    }
  },
  computed: {
    ...mapGetters(['planCreated', 'tenant_settings', 'trustedDeviceToken', 'allFeatureFlags'])
  },
  methods: {
    ...mapActions('user', [
      'setAccessToken',
      'setUserInfo',
      'setClientCategory',
      'setTenantType',
      'setTenantGuid',
      'setTenantSettings',
      'setTenantAccessTags',
      'setShowShare',
      'setShowIdea',
      'setNextReportDate',
      'setLastReportDate',
      'setLastUpdated',
      'setIsDashboardDownloadOn',
      'setTrustedDevice',
      'setTrackingDisabled',
      'setRequiredLearning'
    ]),
    ...mapActions('environment', ['setCloudEnvInfo', 'setDetails', 'setUserConfig', 'setCompanyConfig', 'setFlagConfig']),
    ...mapActions('plan', ['setPlanCreated']),
    ...mapActions('flag', ['setFeatureFlags']),
    isValidEmail(email) {
      // eslint-disable-next-line
      var pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/;
      return pattern.test(email);
    },
    isValidPassword(password) {
      var pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/;
      return pattern.test(password);
    },
    async checkSSOByEmail() {
      // eslint-disable-next-line
      var emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.username = this.username.trim();
      this.buttonDisabled = !emailPattern.test(this.username);
      if (emailPattern.test(this.username)) {
        const isCloudEnvFeatureFlagSet = await getCloudEnvFeatureFlag();
        if (isCloudEnvFeatureFlagSet) {
          await this.setCloudEnvData();
        }
        this.sso = await this.$planAdmin.checkSSOByEmail({ email: this.username });
        this.showPasswordField = !(this.sso && this.sso.isSSOLogin);
        if (this.sso.isSSOLogin) {
          await localforage.setItem('ssoRedirect', this.sso['sso-url']);
        }
      }
    },
    async setAccessTokenAndLogin(response) {
      await loginFlow({ planAdmin: this.$planAdmin, token: response.accessToken, trustedDeviceId: response.trustedDeviceId });
      this.$startSessionTimer();
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);
      } else {
        const tenantSettings = await localforage.getItem('my_tenant_settings');
        if (tenantSettings['ui-insight-display'] == true) this.$router.push('/enrollment');
        else this.$router.push('/role-explorer');
      }
    },
    async onLogin() {
      if (checkMaintenanceFlag()) return;
      this.loading = true;
      await this.checkSSOByEmail();
      if (this.sso && this.sso.isSSOLogin) {
        if (this.$store?.getters?.enabledFeatureFlags['plan_sso_accept_terms']) {
          await localforage.setItem('sso-user-login', true);
        }
        this.loading = false;
        const ssoURL = this.sso['sso-url'];
        const refUrl = this.$route.query.redirect ? this.$route.query.redirect : '/role-explorer';
        window.location.href = `${ssoURL}?referring_url=${window.location.origin}${refUrl}`;
        return;
      }
      this.trustedDeviceId = (await localforage.getItem('my_trusted_device_token')) || this.trustedDeviceToken;
      this.trustedDeviceId = this.trustedDeviceId ? this.trustedDeviceId : '';
      const payload = { email: this.username, password: this.password, username: this.username, trustedDeviceId: this.trustedDeviceId };
      await this.$planAdmin
        .login(payload)
        .then((response) => {
          if (response.error) {
            throw response.message;
          }
          if (response.accessToken) {
            this.setAccessTokenAndLogin(response);
            this.incorrectLoginAttempt = 0;
          } else {
            this.isAlert = false;
            this.isVerifyToLogin = true;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.incorrectLoginAttempt = this.incorrectLoginAttempt + 1;
          const errorMsg = this.incorrectLoginAttempt > 3 ? error + translate('loginModal.checkEmailResetPassword') : error;
          this.alert('error', errorMsg);
        });
    },
    onResetPwd() {
      this.username = '';
      this.password = '';
      this.vericode = '';
      this.isAlert = false;
      this.isLogin = false;
      this.isResetPwd = true;
      this.isVerifyToLogin = false;
    },
    onClose() {
      this.username = '';
      this.password = '';
      this.vericode = '';
      this.loading = false;
      this.isLogin = false;
      this.isAlert = false;
      this.isResetPwd = false;
      this.isVerify = false;
      this.isVerifyToLogin = false;
    },
    async onVerifyToLogin() {
      this.isAlert = false;
      const payload = { email: this.username, verificationCode: this.vericode.trim(), trustedDevice: this.trustedDevice };
      await this.$planAdmin
        .verifyToLogin(payload)
        .then((response) => {
          if (response.error) {
            throw response.message;
          }
          this.setAccessTokenAndLogin(response);
          this.isVerifyToLogin = false;
        })
        .catch((error) => {
          this.alert('error', error);
        });
    },
    async onReset() {
      this.isAlert = false;
      await this.$planAdmin
        .resetPassword({ email: this.username })
        .then((response) => {
          if (response.error) {
            throw response.message;
          }
          this.username = '';
          this.isResetPwd = false;
          this.isVerify = true;
        })
        .catch((error) => {
          this.alert('error', error);
        });
    },
    async onConfirm() {
      this.isAlert = false;
      const payload = { email: this.username, newPassword: this.password, verificationCode: this.vericode };
      if (!this.isValidPassword(this.password)) {
        const error = translate('loginModal.passwordReqHint');
        this.alert('error', error);
        return;
      }
      await this.$planAdmin
        .setPassword(payload)
        .then((response) => {
          if (response.error) {
            throw response.message;
          } else if (response && response.status === 'error') {
            throw response.message;
          }
          this.username = '';
          this.password = '';
          this.vericode = '';
          this.isVerify = false;
          this.isLogin = true;
          this.alert('success', translate('loginModal.successfullyReset'));
        })
        .catch((error) => {
          this.alert('error', error);
        });
    },
    alert(type, message) {
      if (message !== null && message !== undefined && message !== '') {
        this.alertMsg = message;
      }
      this.alertType = type;
      this.isAlert = true;
    },
    async setCloudEnvData() {
      const response = process.env.VUE_APP_USE_FFAPI === 'true' ? await this.$planAdmin.getCloudEnvByUserEmailAddress(this.username) : await this.$planAdmin.getCloudEnvFromDefaultEnv(this.username);
      if (!response.data || !response.data.cloudEnv) {
        return false;
      }
      await this.setCloudEnvInfo(response.data.cloudEnv);
      await this.$planAdmin.updateEnvFromLocalForage();
      this.setUserConfig(response.data.config.user);
      this.setCompanyConfig(response.data.config.company);
      this.setFlagConfig(response.data.config.flags);
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  min-width: 100px !important;
}

.v-toolbar {
  background: transparent;
}

.login-modal {
  overflow-x: hidden;
  padding-bottom: 20px;
  align-content: center;
}

.reset-modal {
  padding: 20px;
}

.form-field {
  margin: 0;
  padding: 0px 10px;
}

.form-field-reset-pwd {
  color: $color-primary;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: underline;
}

.cancel-btn {
  position: absolute;
  top: 0;
  right: 20px;
}
.modal-image {
  width: 100%;
  height: 200px;
  position: relative;
  top: -15px;
}

.disclaimer {
  padding: 0;
  div {
    padding: 0 10%;
    font-size: 0.6rem;
    color: $color-grey-dark;
  }
}
</style>
