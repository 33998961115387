import axios from 'axios';
import { getMetaData } from '../utils/helper';

export default {
  async getFeatureFlags() {
    const prefix = process.env.VUE_APP_ULFF_PREFIX ? `?namePrefix=${process.env.VUE_APP_ULFF_PREFIX}` : '';
    const headers = {
      'UNLEASH-APPNAME': process.env.VUE_APP_ULFF_NAME,
      'UNLEASH-INSTANCEID': process.env.VUE_APP_ULFF_INSTANCE_ID
    };
    const response = await axios.get(`${process.env.VUE_APP_ULFF_HOST}/features${prefix}`, { headers: headers }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async getBackupFeatureFlags() {
    const { authorizationHeader } = await getMetaData();
    const response = await axios.get(`${this.ffUri}flags/${process.env.NODE_ENV}`, { headers: authorizationHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  }
};
