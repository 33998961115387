<template>
  <div class="assessment-list-wrapper">
    <div v-if="!dataReady" class="loader">
      <img src="@/assets/images/icons/plan-loading.gif" width="150" alt="Loading Capstone Submissions" />
    </div>
    <div v-else>
      <v-row>
        <v-col cols="9">
          <AssessmentCard v-for="(assessment, index) in assessments" :key="index" :assessment="assessment" />
          <h3 v-if="!this.assessments.length" style="position: relative; left: -10%">None available</h3>
        </v-col>
        <v-col>
          <AssessmentStats v-if="assessments.length" :stats="stats" />
        </v-col>
      </v-row>
      <v-row class="t-place-content-center paginate-btns">
        <button v-if="hasPrev" class="t-font-futura-pt-book t-mx-4 t-color-white t-py-2 min-w-min t-px-6 t-rounded-full t-font-bold t-text-base t-bg-yellow-400 t-border-yellow-400 hover:t-bg-primary-color hover:t-text-white" text @click="onPrevious()">Previous</button>
        <button v-else disabled class="t-font-futura-pt-book t-mx-4 t-color-white t-py-2 min-w-min t-px-6 t-rounded-full t-border-solid t-text-gray-400" text @click="onPrevious()">Previous</button>
        <button v-if="hasNext" class="t-font-futura-pt-book t-mx-4 t-color-white t-py-2 min-w-min t-px-6 t-rounded-full t-font-bold t-text-base t-bg-yellow-400 t-border-yellow-400 hover:t-bg-primary-color hover:t-text-white" text @click="onNext()">Next</button>
        <button v-else disabled class="t-font-futura-pt-book t-mx-4 t-color-white t-py-2 min-w-min t-px-6 t-rounded-full t-border-solid t-text-gray-400" text @click="onNext()">Next</button>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AssessmentStats from '@/views/assessments/components/AssessmentStats';
import AssessmentCard from '@/views/assessments/components/AssessmentCard';

export default {
  name: 'AssessmentList',
  components: { AssessmentCard, AssessmentStats },
  data() {
    return {
      dataReady: false,
      token: '',
      assessments: [],
      pagedAssessments: {},
      stats: {
        uploads: 0,
        fails: 0,
        passes: 0,
        pendingReview: 0
      },
      currentPage: 1,
      totalPages: 1,
      totalContent: 0,
      pageSize: 10,
      hasPrev: false,
      hasNext: false
    };
  },
  computed: {
    ...mapGetters([])
  },
  watch: {
    $route(to, from) {
      if (from.name === 'AssessmentDetail') {
        this.stats = {
          uploads: 0,
          fails: 0,
          passes: 0,
          pendingReview: 0
        };
        this.getAssessmentList().then(() => {
          this.stats.uploads = this.assessments.length;
        });
      }
    }
  },
  async mounted() {
    await this.loadAssessments();
  },
  methods: {
    async loadAssessments() {
      this.dataReady = false;
      if (this.$route.params?.page != '' && this.$route.params?.page < 1) {
        this.$router.push('/assessments');
        return;
      }
      this.currentPage = this.$route.params?.page >= 1 ? this.$route.params?.page : 1;
      await this.getAssessmentList();
      if (this.pagedAssessments?.pageSize > 1) {
        this.pageSize = this.pagedAssessments.pageSize;
        this.totalContent = this.pagedAssessments.totalContent;
        this.totalPages = this.pagedAssessments.totalPages;
        this.hasPrev = this.currentPage == 1 ? false : true;
        this.hasNext = this.currentPage == this.totalPages ? false : this.currentPage > this.totalPages ? false : true;
      }
      await this.getAllStats();
      this.dataReady = true;
    },
    async getAssessmentList() {
      const payload = { pageNo: this.currentPage, pageSize: this.pageSize };
      this.pagedAssessments = await this.$planAdmin.getAllCapstones_Paged(payload);

      this.assessments = this.pagedAssessments.content;
      if ({}.hasOwnProperty.call(this.assessments, 'code') && this.assessments.code == 401) {
        this.$stopSessionTimer();
      }
      this.hasPrev = this.currentPage == 1 ? false : true;
      this.hasNext = this.currentPage == this.totalPages ? false : this.currentPage > this.totalPages ? false : true;
    },
    async getAllStats() {
      const summary = await this.$planAdmin.getCapstoneSummary();

      this.stats = {
        uploads: summary.uploads,
        fails: summary.failed,
        passes: summary.passed,
        pendingReview: summary.pendingReview
      };
    },
    async onPrevious() {
      this.doNavigate('previous');
    },
    async onNext() {
      this.doNavigate('next');
    },
    async doNavigate(direction) {
      this.dataReady = false;

      let diff = direction === 'previous' ? -1 : 1;
      let pageToNavigateTo = parseInt(this.currentPage) + diff;
      if (pageToNavigateTo > 0 && pageToNavigateTo <= this.totalPages) {
        this.currentPage = pageToNavigateTo;
        this.$router.push('/assessments/' + pageToNavigateTo);
      } else if (pageToNavigateTo > 0 && pageToNavigateTo > this.totalPages) {
        this.currentPage = this.totalPages;
        this.$router.push('/assessments/' + this.currentPage);
      }
      await this.getAssessmentList();
      this.dataReady = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.assessment-list-wrapper {
  padding: 30px 10% !important;
}

.loader {
  margin: 50px;
  margin-left: 35px;
  text-align: center;

  .loader-text {
    font-size: 30px;
    color: $color-primary;
  }

  .loader-bar {
    position: relative;
    top: -30px;
  }
}
.paginate-btns {
  width: 939px;
}
</style>
