<template>
  <div class="app-container redesign-role-explorer">
    <v-container fluid>
      <v-row v-if="notification.available && notification.type == 'error'">
        <v-col :class="'error'">
          <v-btn icon x-small class="close-btn" @click="onCloseNotification">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <div v-if="JSON.parse(notification.message).hasOwnProperty('err')">
            <span role="alert" class="smaller">{{ JSON.parse(notification.message).err }}</span>
          </div>
          <div v-else>
            <ul class="error-list scrollable">
              <li v-for="(error, key) in JSON.parse(notification.message)" :key="key">
                <span class="smaller">{{ error }}</span>
              </li>
            </ul>
          </div>
          <div>
            {{ $t('roleExplorer.reuploadCSV.click') }}<span @click="onCreateNewRole" style="cursor: pointer; font-style: oblique; text-decoration: underline">{{ $t('roleExplorer.reuploadCSV.here') }}</span
            >{{ $t('roleExplorer.reuploadCSV.toReupload') }}
          </div>
        </v-col>
      </v-row>

      <div v-if="notification.available && notification.type != 'error'" class="warning">{{ notification.message }}</div>
      <div v-if="isRoleRemoved" class="success">{{ $t('roleExplorer.roleRemovedSuccess') }}</div>
      <div v-if="isRoleAdded" class="success">{{ $t('roleExplorer.roleaAddedSuccess') }}</div>
      <div v-if="uploadSuccess" class="success t-relative">
        <p class="t-px-8">{{ $t('roleExplorer.uploadSuccessful') }} {{ this.uploadSuccessMsg }}</p>
        <!--we may need to show close icon in future
          <v-icon class="t-cursor-pointer t-absolute t-right-2 t-top-1 t-text-white" @click="closeSuccessNotification">mdi-close</v-icon>-->
      </div>
      <v-row>
        <v-col>
          <h1>{{ $t('roleExplorer.title') }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4" sm="6">
          <v-card v-if="!structureReady" class="structure-panel">
            <div class="loader">
              <img src="@/assets/images/icons/plan-loading.gif" width="150" alt="Loading Roles" />
            </div>
          </v-card>

          <RoleStructure v-else :roles="allRoles" :functions="functions" :departments="departments" @create-new-role="onCreateNewRole" :key="allRoles.length + functions.length + departments.length + $i18n.locale" />
        </v-col>
        <v-col md="8" sm="6">
          <router-view v-slot="{ Component }">
            <keep-alive :key="$route.fullPath">
              <component :is="Component" />
            </keep-alive>
          </router-view>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import localforage from 'localforage';
import RoleStructure from '@/views/plan/components/RoleStructure';
import { encodeDecodeRole, getLocalizedFunctions } from '@/utils';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'RoleExplorer',
  components: { RoleStructure },
  data() {
    return {
      notification: { available: false, type: '', message: '' },
      guid: null,
      tabIndex: 0,
      roles: [],
      functions: [],
      departments: [],
      addRole: false,
      selectRole: null,
      structureReady: false,
      isRoleRemoved: false,
      isRoleAdded: false,
      isUploading: false,
      uploadSuccess: false,
      uploadSuccessMsg: '',
      fromPath: '',
      encodeDecodeRole,
      getLocalizedFunctions
    };
  },
  //need to grab find out where we're coming from for the possible redirect later
  beforeRouteEnter(_to, from, next) {
    next((vm) => {
      vm.fromPath = from.path;
    });
  },
  computed: {
    ...mapState('user', ['roleStructure', 'uploadingState']),
    ...mapState('plan', ['allRoles']),
    ...mapGetters(['planLocation', 'company', 'logo', 'gcs_token', 'csvUploaded'])
  },
  watch: {
    '$i18n.locale'() {
      this.getRoles();
    }
  },
  async beforeMount() {
    const tenantSettings = await localforage.getItem('my_tenant_settings');
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';

    // there is a bug here - we need a ticket for this
    if (tenantSettings && tenantSettings['ui-insight-display'] == true && this.fromPath == '/') this.$router.push('/enrollment');
    // there is a bug here - we need a ticket for this
    await this.getRoles();
  },
  async created() {
    //check whether all the events already exist
    const events = ['upload-error', 'on-csv-upload', 'on-ccc-csv-upload', 'add-new-role', 'on-delete-role', 'role-name-change', 'cancel-add-new-role'];
    if (events.every((ele) => Object.keys(this.EventBus.all).includes(ele))) return;
    this.EventBus.on('upload-error', this.uploadError);
    this.EventBus.on('on-csv-upload', this.onCsvUpload);
    this.EventBus.on('on-ccc-csv-upload', this.oncccCsvUpload);
    this.EventBus.on('add-new-role', this.onAddNewRole);
    this.EventBus.on('on-delete-role', this.onDeleteRole);
    this.EventBus.on('role-name-change', this.onChangeRole);
    this.EventBus.on('cancel-add-new-role', this.onCancelAddNewRole);
  },
  async mounted() {
    this.structureReady = false;
    this.getSelectedRole(this.$route.params.guid);
  },
  methods: {
    ...mapMutations('user', ['SET_ROLES', 'SET_UPLOADING', 'SET_SHOW_MODAL']),
    ...mapMutations('plan', ['SET_ALL_DEPARTMENTS', 'SET_ALL_FUNCTIONS', 'SET_ALL_ROLES']),
    ...mapActions('plan', ['setCsvUploaded', 'setUploadStatus']),
    async getRoles() {
      this.functionsList = await this.$planAdmin.getGovernanceFunctions();
      this.roles = await this.$planAdmin.getAllJobs();
      this.SET_ALL_ROLES(this.roles);

      if ({}.hasOwnProperty.call(this.roles, 'code') && this.roles.code == 401) {
        this.$stopSessionTimer();
      }
      const nameSort = (a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        return 0;
      };
      const funcDeptSort = (a, b) => {
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        return 0;
      };
      const rolePromise = this.roles.map((role) => {
        role.name = encodeDecodeRole(role.name);
        role.name = role.name.replace('&amp;', '&');
      });
      this.functions = [];

      const funcPromise = this.functionsList.map((func) => {
        this.functions.push(getLocalizedFunctions(func.name));
      });

      const deptPromise = this.roles.map((role) => {
        if (role.department && !this.departments.includes(role.department)) {
          this.departments.push(role.department);
        }
      });
      await Promise.allSettled([rolePromise, funcPromise, deptPromise]);
      this.roles.sort(nameSort);
      this.functions.sort(funcDeptSort);
      this.departments.sort(funcDeptSort);
      this.functions.unshift(translate('common.titleCase.all'));
      this.departments.unshift(translate('common.titleCase.all'));

      this.SET_ALL_DEPARTMENTS(this.departments);
      this.SET_ALL_FUNCTIONS(this.functions);
      this.structureReady = true;
    },
    getEmptyUploadState() {
      return {
        id: null,
        tenantId: null,
        userId: null,
        fileName: null,
        status: 'none',
        message: null,
        exceptionMessage: null,
        created: null,
        updated: null,
        uploadActionType: null
      };
    },
    async oncccCsvUpload() {
      let upload = await this.$planAdmin.getCCCUploadState();
      if (upload.length != 0) upload = upload[0];
      const status = upload.status.toLowerCase();
      await this.setCsvUploaded(false);
      if (upload.status != 'SUCCESS') {
        this.isUploading = true;
        if (status.includes('running')) {
          this.EventBus.emit('upload-running');
          setTimeout(() => {
            this.oncccCsvUpload();
          }, 10000);
        }
        this.notification.type = status.includes('running') ? 'warning' : status.includes('error') ? 'error' : '';
        this.notification.message = status.includes('running') ? 'Uploading CSV in progress...' : status.includes('error') ? upload.message : '';
        this.notification.available = status.includes('running') || status.includes('error');
        this.uploadSuccess = false;
      }
      if (upload.status === 'SUCCESS') {
        this.uploadSuccessful('CCC', upload);
        this.refresh(false, null);
      }
    },
    async onCsvUpload() {
      const cccUploadStatus = await this.$planAdmin.getCCCUploadState();
      const upload = cccUploadStatus.length > 0 ? cccUploadStatus[0] : this.getEmptyUploadState();
      const status = upload.status.toLowerCase();
      await this.setCsvUploaded(false);
      if (upload.status != 'SUCCESS') {
        this.isUploading = true;
        if (status.includes('running')) {
          this.EventBus.emit('upload-running');
          setTimeout(() => {
            this.onCsvUpload();
          }, 10000);
        }
        this.notification.type = status.includes('running') ? 'warning' : status.includes('error') ? 'error' : '';
        this.notification.message = status.includes('running') ? 'Uploading CSV in progress...' : status.includes('error') ? upload.message : '';
        this.notification.available = status.includes('running') || status.includes('error');
        this.uploadSuccess = false;
      }
      if (upload.status === 'SUCCESS') {
        this.uploadSuccessful('CSV', upload);
        this.refresh(false, null);
      }
    },
    async uploadSuccessful(uploadType, upload) {
      const status = upload.status.toLowerCase();
      this.notification.message = '';
      this.notification.type = '';
      this.notification.available = false;
      this.uploadSuccess = status.includes('success') && !((await localforage.getItem('my_csv_uploaded')) || this.csvUploaded);
      if (this.uploadSuccess) {
        this.uploadSuccessMsg = uploadType === 'CSV' ? (JSON.parse(upload.message) || {})[status] : (JSON.parse(upload.message) || {})[0];
      }
      setTimeout(async () => {
        this.uploadSuccess = false;
        this.uploadSuccessMsg = '';
        await this.setUploadStatus(true);
      }, 10000);
      await this.setCsvUploaded(status.includes('success'));
    },
    async closeSuccessNotification() {
      this.uploadSuccess = false;
      this.uploadSuccessMsg = '';
      await this.setUploadStatus(true);
    },
    onCloseNotification() {
      this.notification.message = '';
      this.notification.available = false;
    },
    async onCreateNewRole() {
      this.refresh(true, null);
    },
    async onAddNewRole() {
      this.isRoleAdded = true;
      setTimeout(() => {
        this.isRoleAdded = false;
      }, 3000);
      this.refresh(false, null);
    },
    async onChangeRole() {
      this.refresh(false, null);
    },
    async onCancelAddNewRole() {
      this.refresh(false, null);
      this.$router.replace({ path: '/role-explorer' });
    },
    async onDeleteRole() {
      this.isRoleRemoved = true;
      this.$router.replace({ path: '/role-explorer' });
      setTimeout(() => {
        this.isRoleRemoved = false;
      }, 3000);
      this.refresh(false, null);
    },
    async refresh(addRole, selectRole) {
      this.SET_UPLOADING(true);
      this.structureReady = false;
      this.addRole = addRole;
      this.selectRole = selectRole;

      await this.getRoles();
      this.SET_SHOW_MODAL(false);
      if (this.roleStructure) {
        this.SET_ROLES(false);
      }
      this.SET_UPLOADING(false);
    },
    getSelectedRole(guid) {
      this.addRole = false;
      const selectedRole = this.roles.filter((role) => role.guid == guid);
      this.selectRole = selectedRole.length ? selectedRole[0] : null;
    },
    uploadError(errMsg) {
      this.notification.type = 'error';
      this.notification.available = true;
      this.notification.message = JSON.stringify(errMsg);
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.roleExplorer')
    };
  }
};
</script>

<style lang="scss" scoped>
.redesign-role-explorer {
  margin: 0 8%;
}
.redesign-role-explorer h1 {
  font-size: 34px;
  font-weight: normal;
  font-family: 'Futura PT Demi';
  margin-top: 20px;
  color: $color-primary;
}

.scrollable {
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-track {
    background: $color-grey-lighter;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 8px;
    width: 4px;
  }
}
.error-list {
  list-style-type: none;
  max-height: 100px;
  overflow-y: auto;
}
.warning {
  font-size: 20px;
  text-align: center;
  border-radius: 8px;
  color: $color-white;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px;
}
.error {
  font-size: 20px;
  text-align: center;
  border-radius: 8px;
  color: $color-white;
  margin-bottom: 0px;
  margin-top: 10px;
  padding: 12px;
}

.success {
  font-size: 20px;
  text-align: center;
  border-radius: 8px;
  color: $color-white;
  margin: 10px 0;
  padding: 10px;
}

.close-btn {
  top: 50%;
  transform: translateY(-50%);
  float: right;
  background: $color-white !important;
  color: $color-error !important;
}
.app-container {
  background: $color-grey-lighter;
  height: 100%;
}
.role-removed {
  position: relative;
  left: calc(50% - 250px);
  font-size: 25px;
  text-align: center;
  border-radius: 20px;
  color: $color-grey-lighter;
  background-color: $color-success;
  max-width: 500px;
}

.loader {
  margin: 50px;
  margin-left: 35px;
  text-align: center;

  .loader-text {
    font-size: 30px;
    color: $color-primary;
  }

  .loader-bar {
    position: relative;
    top: -30px;
  }
}

.select-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-around;
  .buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    .create-button {
      width: 169px;
      margin: 0 0 1rem;
    }
    .clone-button {
      width: 123px;
      margin: 0 0 1rem;
    }
  }
}

.toolbar {
  .icon {
    float: left;
    border-radius: 50%;
    background: #3b4572;
    width: 25px;
    height: 25px;
    .tools-icon {
      display: inline-block;
      margin: auto;
      padding: auto;
    }
  }
  .title {
    margin-left: 20px;
  }
  .search {
    margin-left: 20px;
  }
}
.v-tabs {
  margin-left: unset !important;
  margin-left: 0rem !important;
  width: 100%;
  overflow-y: scroll;
}
.v-tab {
  margin-left: unset !important;
  margin-left: 0rem !important;
  justify-content: unset !important;
  width: 100%;
}

.tab-detail {
  height: 100%;
  div {
    height: 100%;
    div {
      height: 100%;
      div {
        height: 100%;
      }
    }
  }
}
</style>
