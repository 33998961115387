<template>
  <div class="asset-edit-container t-bg-white t-py-8 t-px-24 t-rounded-2xl t-opacity-100">
    <SaveDialog v-if="showDialog" :saveDialog="showDialog" :status="status" @confirm="onConfirm($event)" @cancel="onCancel()" :getComment="isAdminEdited ? comment : ''" :adminEdited="isAdminEdited" />
    <div class="custom-toast custom-toast-loading download-progress" v-if="showDownloadProgress">
      <div class="toast-progress-top">
        <v-progress-linear color="teal" indeterminate></v-progress-linear>
      </div>
      <div>
        <div class="download-status">Download is in progress {{ downloadPercentage }}%</div>
      </div>
      <div>
        <v-btn class="cancel-btn-text download-cancel-btn" rounded color="primary" small @click="cancelDownload()"> Cancel Download</v-btn>
      </div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-loading" v-if="showSaveEdits">
      <div class="toast-progress-top">
        <span class="toast-progress-left">
          <v-progress-circular indeterminate color="primary" :width="3" :size="20"></v-progress-circular>
        </span>
        <span class="status-message-save"> Saving your edits </span>
      </div>
      <div class="toast-footer"></div>
    </div>
    <h5 class="t-mb-8 t-font-futura-pt-demi t-text-2xl t-font-normal h5-width-cls">Edit asset details</h5>
    <div class="form-action-items">
      <div class="save-icn-img save-tooltip">
        <svg-icon @click="saveEdits()" :class="disableSaveBtn() ? 'svg-disabled' : ''" icon-class="icon-save" class="g-icon--small icn-size" alt="save" />
        <span class="save-tooltiptext">Save edits</span>
      </div>
      <div class="edit-icn-img save-tooltip">
        <svg-icon icon-class="icon-new-close" :class="disableCancelBtn() ? 'svg-disabled' : ''" @click="setEditModeFlag()" class="g-icon--small icn-size" />
        <span class="save-tooltiptext">{{ $t('button.cancel') }}</span>
      </div>
    </div>
    <div class="submission-details-container">
      <div class="submission-form-content">
        <div class="submission-form-header">
          <div class="asset-description-form">
            <div class="form-col t-mb-8">
              <div class="section-title">{{ $t('manageSubmissions.assetEditForm.popoverContent.assetName.title') }}*</div>
              <v-text-field :rules="rules.name" ref="name" maxlength="100" class="asset-name-in t-pt-0" :placeholder="$t('manageSubmissions.assetEditForm.popoverContent.assetName.placeholder')" v-model="name" @input="isObjectEqual"></v-text-field>
              <div class="tip-content">
                <Collapsible>
                  <template #title>Tip</template>
                  <template #section>
                    <p>{{ $t('manageSubmissions.assetEditForm.popoverContent.assetName.tip') }}</p>
                  </template>
                </Collapsible>
              </div>
            </div>
          </div>
          <div class="asset-header-form">
            <div class="form-col t-mb-8">
              <div class="section-title">{{ $t('manageSubmissions.assetEditForm.popoverContent.promoHeader.title') }}*</div>
              <v-textarea solo name="input-7-4" v-model="promoHeader" :rules="rules.promoHeader" :placeholder="$t('manageSubmissions.assetEditForm.popoverContent.promoHeader.placeholder')" @input="isObjectEqual"> </v-textarea>
              <div class="tip-content">
                <Collapsible>
                  <template #title>Tip</template>
                  <template #section>
                    <p>{{ $t('manageSubmissions.assetEditForm.popoverContent.promoHeader.tip') }}</p>
                  </template>
                </Collapsible>
              </div>
            </div>
          </div>
          <div class="asset-description-form">
            <div class="form-col t-mb-8">
              <div class="section-title">{{ $t('manageSubmissions.assetEditForm.popoverContent.promoDescription.title') }}*</div>
              <v-textarea solo name="input-7-4" v-model="promoDescription" :rules="rules.promoDescription" :placeholder="$t('manageSubmissions.assetEditForm.popoverContent.promoDescription.placeholder')" @input="isObjectEqual"> </v-textarea>
              <div class="tip-content">
                <Collapsible>
                  <template #title>Tip</template>
                  <template #section>
                    <p>{{ $t('manageSubmissions.assetEditForm.popoverContent.promoDescription.tip') }}</p>
                  </template>
                </Collapsible>
              </div>
            </div>
          </div>
          <div class="asset-fullDescription-form">
            <div class="form-col t-mb-8">
              <div class="section-title">{{ $t('manageSubmissions.assetEditForm.popoverContent.fullDescription.title') }}</div>
              <v-textarea solo name="input-7-4" v-model="fullDescription" :placeholder="$t('manageSubmissions.assetEditForm.popoverContent.fullDescription.placeholder')" @input="isObjectEqual"> </v-textarea>
              <div class="tip-content">
                <Collapsible>
                  <template #title>Tip</template>
                  <template #section>
                    <p>{{ $t('manageSubmissions.assetEditForm.popoverContent.fullDescription.tip') }}</p>
                  </template>
                </Collapsible>
              </div>
            </div>
          </div>
          <div @drop="handleDrop($event, 'png')" @dragover="handleDragover" @dragenter="handleDragover">
            <div class="section-title">
              {{ $t('manageSubmissions.assetEditForm.popoverContent.imageOrVideo.title') }}
            </div>
            <div class="file-upload-container t-bg-white">
              <div class="upload-button">
                <div class="upload-btn-content">
                  <svg-icon icon-class="cloud-upload-outline" class="g-icon--large"></svg-icon>
                  <div class="t-text-xs t-mb-6 t-font-bold">PNG</div>
                </div>
              </div>
              <label class="file-upload-wrapper t-font-futura-pt-demi t-opacity-50 hover:t-opacity-100 t-text-center" :class="{ 't-opacity-100': fileUploadStatus == 'U' || fileUploadStatus == 'S' }" tabindex="0">
                <div class="t-text-sm t-text-black t-font-arial">Drop files here to upload or</div>
                <input data-cy="asset-file" ref="upload-support-input" class="upload-input t-font-futura-pt-demi" type="file" accept="image/png" multiple @change="handleClick($event, 'png')" />
                Choose files
              </label>
              <div class="file-details" v-for="(imageFile, index) in uploadScreenshots" :key="index">
                <div class="file-meta-data">
                  <div class="section-subheading">
                    <svg-icon icon-class="image-outline" class="g-icon--large img-svg-icon"></svg-icon>
                    <span>{{ imageFile.name }}</span>
                  </div>
                  <div class="section-meta-info">
                    <span class="section-meta-text">{{ formatSize(imageFile.size) }}</span>
                    <div class="download-button" v-if="imageFile.signedUrl">
                      <svg-icon icon-class="icon-download" @click="onDownload(imageFile)" />
                    </div>
                    <div class="download-button">
                      <svg-icon icon-class="trash-outline" @click="showDeletePopup('png', index)"> </svg-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="error-container t-mb-4 t-text-xs t-font-arial">{{ $t('manageSubmissions.assetEditForm.popoverContent.imageOrVideo.error') }}</div>
            <div class="tip-content">
              <Collapsible>
                <template #title>Tip</template>
                <template #section>
                  <p>{{ $t('manageSubmissions.assetEditForm.popoverContent.imageOrVideo.tip') }}</p>
                </template>
              </Collapsible>
            </div>
          </div>
          <!-- TODO: FIX THESE SELECT COMPS  -->
          <div class="asset-inventory-form">
            <div class="form-col t-mb-8">
              <div class="section-title">{{ $t('manageSubmissions.assetEditForm.popoverContent.inventoryType.title') }}*</div>
              <SelectView v-model="inventoryType" :items="inventoryTypeList" :menu-props="menuPosition" outlined dense placeholder="Select one" @update:modelValue="isObjectEqual"> </SelectView>
            </div>
          </div>
          <div class="asset-applications-form">
            <div class="form-col t-mb-8">
              <div class="section-title">{{ $t('manageSubmissions.assetEditForm.popoverContent.applications.title') }}*</div>
              <SelectView v-model="applications" :items="toolsList" :menu-props="menuPosition" item-title="name" outlined dense multiple placeholder="Select all that apply" @changupdate:modelValuee="isObjectEqual"></SelectView>
            </div>
          </div>
          <div class="asset-functions-form">
            <div class="form-col t-mb-8">
              <div class="section-title">{{ $t('manageSubmissions.assetEditForm.popoverContent.functions.title') }}*</div>
              <SelectView v-model="functions" :items="functionsList" :menu-props="menuPosition" item-title="name" outlined dense multiple placeholder="Select all that apply" @update:modelValue="isObjectEqual">
                <template v-slot:append-item>
                  <div class="append-content">
                    <v-divider class="mt-2"></v-divider>
                    <v-list-item ripple @mousedown.prevent @click="toggleSelectAll" class="t-bg-white">
                      <v-list-item-action>
                        <v-icon :color="functions.length > 0 ? 'indigo darken-4' : ''">
                          {{ getIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                </template>
                <template v-slot:selection="{ item, index }">
                  <div v-if="functions.length == functionsList.length && index == 0">All functions</div>
                  <div class="v-select__selection v-select__selection--comma" v-if="functions.length != functionsList.length">{{ item.name }}<i v-if="index != functions.length - 1">, </i></div>
                </template>
              </SelectView>
            </div>
          </div>
          <div @drop="handleDrop($event, 'pdf')" @dragover="handleDragover" @dragenter="handleDragover">
            <div class="section-title">
              {{ $t('manageSubmissions.assetEditForm.popoverContent.uploadDocumentation.title') }}
            </div>
            <div class="file-upload-container t-bg-white">
              <div class="upload-button">
                <div class="upload-btn-content">
                  <svg-icon icon-class="cloud-upload-outline" class="g-icon--large"></svg-icon>
                  <div class="t-text-xs t-mb-6 t-font-bold">PDF</div>
                </div>
              </div>
              <label class="file-upload-wrapper t-font-futura-pt-demi t-text-center t-opacity-50 hover:t-opacity-100" :class="{ 't-opacity-100': fileUploadStatus == 'U' || fileUploadStatus == 'S' }" tabindex="0">
                <div class="t-text-sm t-text-black t-font-arial" v-text="uploadPdfFiles.length > 0 ? 'Drop file here to replace or' : 'Drop file here to upload or'"></div>
                <input data-cy="asset-file" ref="upload-support-input" class="upload-input t-font-futura-pt-demi" type="file" accept="application/pdf" multiple @change="handleClick($event, 'pdf')" />
                <span class="t-font-futura-pt-demi" v-text="uploadPdfFiles.length > 0 ? 'Choose another file' : 'Choose a file'"></span>
              </label>
              <div class="file-details" v-for="(imageFile, index) in uploadPdfFiles" :key="index">
                <div class="file-meta-data">
                  <div class="section-subheading">
                    <svg-icon icon-class="image-outline" class="g-icon--large img-svg-icon"></svg-icon>
                    <span>{{ imageFile.name }}</span>
                  </div>
                  <div class="section-meta-info">
                    <span class="section-meta-text">{{ formatSize(imageFile.size) }}</span>
                    <div class="download-button" v-if="imageFile.signedUrl">
                      <svg-icon icon-class="icon-download" @click="onDownload(supportingFile)" />
                    </div>
                    <div class="download-button">
                      <svg-icon icon-class="trash-outline" @click="showDeletePopup('pdf', index)" class="g-icon--large" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="error-container t-mb-4 t-text-xs t-font-arial">{{ $t('manageSubmissions.assetEditForm.popoverContent.uploadDocumentation.error') }}</div>
            <div class="tip-content">
              <Collapsible>
                <template #title>Tip</template>
                <template #section>
                  <p>{{ $t('manageSubmissions.assetEditForm.popoverContent.uploadDocumentation.tip') }}</p>
                </template>
              </Collapsible>
            </div>
          </div>

          <template>
            <v-tabs class="t-mt-5 asset-tab-border" v-model="assetTabIndex">
              <v-tabs-slider color="#00d1b0"></v-tabs-slider>
              <v-tab key="assetUpload" v-show="assetFileUploadAccess !== 'link'" :disabled="!Object.keys(this.assetReference).length > 0 && assetLinkPresent" @click="assetTabIndex = 0" class="t-normal-case t-font-bold t-font-arial t-text-base t-text-black">{{
                $t('manageSubmissions.assetEditForm.popoverContent.uploadAsset.title')
              }}</v-tab>
              <v-tab
                v-show="assetFileUploadAccess !== 'zip'"
                key="assetLink"
                :disabled="(!assetLinkPresent && Object.keys(this.assetReference).length > 0) || fileUploadStatus === 'U' || fileUploadStatus === 'S'"
                @click="
                  assetTabIndex != 1 && resetExternalAssetLink();
                  assetTabIndex = 1;
                "
                class="t-normal-case t-font-bold t-font-arial t-text-base t-text-black"
                >{{ $t('manageSubmissions.assetEditForm.popoverContent.externalAssetLink.title') }}</v-tab
              >
            </v-tabs>
          </template>
          <v-tabs class="t-my-4" v-model="assetTabIndex" style="height: 15rem">
            <v-tab key="assetUpload">
              <div @drop="handleDrop($event, 'zip')" @dragover="handleDragover" @dragenter="handleDragover">
                <div class="file-upload-container">
                  <div class="upload-button">
                    <div class="upload-btn-content">
                      <svg-icon icon-class="cloud-upload-outline" class="g-icon--large"></svg-icon>
                      <div class="t-text-xs t-mb-6 t-font-bold">ZIP (MAX 150 MB)</div>
                    </div>
                  </div>
                  <label class="file-upload-wrapper t-font-futura-pt-demi t-opacity-50 t-text-center hover:t-opacity-100" :class="{ 't-opacity-100': fileUploadStatus == 'U' || fileUploadStatus == 'S' }" tabindex="0">
                    <div class="t-text-sm t-text-black t-font-arial" v-text="assetReference.name ? 'Drop file here to replace or' : 'Drop file here to upload or'"></div>
                    <input data-cy="asset-file" ref="upload-support-input" class="upload-input t-font-futura-pt-demi" type="file" accept="application/zip" multiple @change="handleClick($event, 'zip')" />
                    <span class="t-font-futura-pt-demi" v-text="assetReference.name ? 'Choose another file' : 'Choose a file'"></span>
                  </label>
                  <div class="file-details" v-if="assetReference.name">
                    <div class="file-meta-data">
                      <div class="section-subheading">
                        <svg-icon icon-class="image-outline" class="g-icon--large img-svg-icon"></svg-icon>
                        <span>{{ assetReference.name }}</span>
                      </div>
                      <div class="section-meta-info">
                        <span class="section-meta-text"> {{ formatSize(assetReference.size) }} </span>
                        <div class="download-button">
                          <svg-icon icon-class="icon-download" @click="downloadAssetZipFile()" />
                        </div>
                        <div class="download-button">
                          <svg-icon icon-class="trash-outline" @click="showDeletePopup('zip', assetReference)" class="g-icon--large" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="error-container t-mb-4 t-text-xs t-font-arial">{{ $t('manageSubmissions.assetEditForm.popoverContent.uploadAsset.error') }}</div>
              </div>
            </v-tab>
            <v-tab key="assetLink">
              <div>
                <div class="form-col t-mb-8">
                  <v-textarea solo name="input-7-4" ref="externalAssetLink" v-model="externalAssetLink" :rules="rules.externalAssetLink" :placeholder="$t('manageSubmissions.assetEditForm.popoverContent.externalAssetLink.placeholder')" @input="onAssetLinkChange($event)">
                  </v-textarea>
                </div>
              </div>
            </v-tab>
          </v-tabs>

          <div class="asset-comment-form">
            <div class="form-col t-mb-8">
              <div class="section-title">{{ $t('manageSubmissions.assetEditForm.popoverContent.comment.title') }}</div>
              <v-textarea solo name="input-7-4" v-model="comment" :placeholder="$t('manageSubmissions.assetEditForm.popoverContent.comment.placeholder')" @input="isObjectEqual"> </v-textarea>
            </div>
          </div>
        </div>
        <div>
          <v-btn rounded class="t-bg-yellow-400 btn-text-font" :disabled="disableSaveBtn()" @click="saveEdits()"><span class="t-font-futura-pt-demi">Save edits</span> </v-btn>
          <v-btn rounded :disabled="disableCancelBtn()" class="t-bg-primary-color t-text-white btn-text-font" @click="setEditModeFlag()">
            <span class="t-font-futura-pt-demi">{{ $t('button.cancel') }}</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="custom-toast custom-toast-info" v-if="fileUploadStatus === 'U' && uploadPercentage < 100">
      <div class="t-mt-4">
        <v-progress-linear color="teal" indeterminate></v-progress-linear>
      </div>
      <div class="status-message">
        <span class="status-message-bold">Step 1 of 2</span><span class="status-message-normal">: File upload in progress {{ uploadPercentage }}%</span>
      </div>
      <div class="cancel-btn">
        <v-btn class="cancel-btn-text" rounded color="primary" small @click="cancelUploadAsset()"> Cancel</v-btn>
      </div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-success" v-else-if="fileUploadStatus === 'C'">
      <div class="toast-close-icon">
        <svg-icon icon-class="close" class="g-icon--small" @click="fileUploadStatus = ''"></svg-icon>
      </div>
      <div class="toast-success-icon">
        <svg-icon icon-class="icon-success"></svg-icon>
      </div>
      <div class="status-message"><span class="status-message-normal">Success! Your file has been uploaded.</span></div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-info" v-else-if="fileUploadStatus === 'S' || uploadPercentage == 100">
      <div class="t-mt-4">
        <v-progress-linear color="teal" indeterminate></v-progress-linear>
      </div>
      <div class="status-message"><span class="status-message-bold">Step 2 of 2</span><span class="status-message-normal">: File scan in progress</span></div>
      <div class="cancel-btn">
        <v-btn class="cancel-btn-text" rounded color="primary" small @click="cancelUploadAsset()"> Cancel</v-btn>
      </div>
      <div class="toast-footer"></div>
    </div>
    <div class="custom-toast custom-toast-failed" v-else-if="fileUploadStatus === 'F'">
      <div class="toast-close-icon">
        <svg-icon icon-class="close" class="g-icon--small" @click="fileUploadStatus = ''"></svg-icon>
      </div>
      <div class="toast-failed-icon">
        <svg-icon icon-class="icon-alert-black"></svg-icon>
      </div>
      <div class="status-message"><span class="status-message-normal">Your file did not upload successfully</span></div>
      <div class="cancel-btn">
        <v-btn class="cancel-btn-text" rounded color="primary" small @click="tryAgain()">Try again</v-btn>
      </div>
      <div class="toast-footer"></div>
    </div>
    <AlertsModal v-if="showAlertsModal && modalMessageObj.header" :modalProperties="modalMessageObj" @closeAlertsModal="closeModal"></AlertsModal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SelectView from '@/components/molecules/SelectView';
import Collapsible from '@/components/molecules/Collapsible';
import AlertsModal from '@/views/manage-submissions/components/AlertsModal';
import SaveDialog from '@/views/manage-submissions/components/SaveDialog';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'EditSubmissionForm.vue',
  props: {
    submittedAsset: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    functionsList: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    }
  },
  components: { Collapsible, AlertsModal, SaveDialog, SelectView },
  data() {
    return {
      assetTabIndex: 0,
      downloadPercentage: 0,
      assetFileUploadAccess: '',
      deleteObj: {},
      isAdminEdited: false,
      uploadPercentage: 0,
      fileUploadStatus: '',
      axiosController: null,
      toastMessagesList: {
        approved: {
          message: translate('manageSubmissions.assetEditForm.toastMessages.approved'),
          type: 'asset-review-success'
        },
        rejected: {
          message: translate('manageSubmissions.assetEditForm.toastMessages.rejected'),
          type: 'asset-review-success'
        },
        returned: {
          message: translate('manageSubmissions.assetEditForm.toastMessages.returned'),
          type: 'asset-review-success'
        }
      },
      menuPosition: {
        bottom: true,
        offsetY: true,
        contentClass: 'select-dropdown-item'
      },
      status: '',
      showDialog: false,
      showSaveEdits: false,
      modalMessageObj: {},
      loadingMessage: false,
      showAlertsModal: false,
      showDownloadProgress: false,
      toolsList: [],
      inventoryTypeList: [],
      name: '',
      promoHeader: '',
      promoDescription: '',
      fullDescription: '',
      applications: '',
      functions: '',
      inventoryType: '',
      assetLinkPresent: false,
      externalAssetLink: '',
      comment: '',
      assetReference: {},
      galleryImages: [],
      supportingFile: {},
      uploadScreenshots: [],
      uploadPdfFiles: [],
      pdfFile: [],
      isEquivalent: true,
      navigateNext: false,
      toPath: '',
      rules: {
        name: [(v) => !/[^\w\d\s]/.test(v) || 'Asset name can not have special characters', (v) => v.length > 0 || 'Asset name cannot be empty'],
        promoHeader: [(v) => v.length > 0 || 'Header cannot be empty'],
        promoDescription: [(v) => v.length > 0 || 'Description cannot be empty'],
        externalAssetLink: [
          (v) => (v && v.length > 0) || 'Asset link cannot be empty',
          (v) => v.indexOf('http://') == 0 || v.indexOf('https://') == 0 || `Please enter a valid URL (should begin with https:// or http://)`,
          (v) => new RegExp(/^((https|http):\/\/)+[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi).test(v) || `Please provide a valid path for the asset file`
        ]
      },
      duplicateFileErrorMsg: {
        header: 'Duplicate file',
        message: '',
        btnText: 'Ok',
        buttons: [],
        type: 'error'
      },
      exceedFileLimitMsg: {
        header: 'Exceeded file limit',
        message: 'You can upload up to 10 files total. Delete any files you would like to replace.',
        btnText: 'Ok',
        buttons: [],
        type: 'error'
      },
      pdfFileExceedLimitMsg: {
        header: 'Exceeded file limit',
        message: 'You can only upload one file.',
        btnText: 'Ok',
        buttons: [],
        type: 'error'
      },
      submitSuccessMsg: {
        header: 'Success!',
        message: `Thanks for submitting your asset. We’ll pass it along to your admin for review and notify you by email of any updates`,
        btnText: 'Ok',
        buttons: [],
        type: 'success'
      },
      zipFileExceedError: {
        header: 'Exceeded file limit',
        message: 'You can upload only max 150 MB size file',
        btnText: 'Ok',
        buttons: [],
        type: 'error'
      },
      duplicateAssetMsg: {
        header: 'Duplicate asset',
        message: '',
        btnText: 'Ok',
        buttons: [],
        type: 'error'
      },
      cancelEdit: {
        header: 'You have unsaved changes',
        message: 'Leaving this page now means you will lose any unsaved edits. Would you like to save them now?',
        buttons: [
          {
            text: 'Save now',
            type: 'btn',
            class: 't-bg-yellow-400 btn-text-font',
            click: () => {
              this.saveEdits();
            }
          },
          {
            text: 'Ignore edits',
            type: 'link',
            class: 'primary',
            click: () => {
              this.$emit('setEditMode', false);
            }
          }
        ],
        type: 'warn'
      },
      deleteFilesObj: {
        closeIcon: true,
        header: 'Delete Files ?',
        message: 'Are you sure want to delete the files ?',
        buttons: [
          {
            text: 'No',
            type: 'btn',
            class: 't-bg-yellow-400 btn-text-font',
            click: () => {
              this.showAlertsModal = false;
              this.modalMessageObj = {};
            }
          },
          {
            text: 'Yes',
            type: 'btn',
            class: 't-bg-primary-color t-text-white btn-text-font btn-margin',
            click: () => {
              this.deleteFiles(this.deleteObj.type, this.deleteObj.id);
            }
          }
        ],
        type: 'warn'
      },
      wentWrong: {
        header: 'Something went wrong',
        message: 'There was a problem saving your edits. You can try again later or <a target="_blank" href="mailto:support@proedge.pwc.com?subject=Proedge%20Support">contact support</a> for more assistance.',
        buttons: [
          {
            text: 'Close',
            type: 'btn',
            class: 't-bg-yellow-400 btn-text-font',
            click: () => {
              this.$emit('setEditMode', false);
            }
          }
        ],
        btnText: 'Close',
        type: 'warn'
      },
      duplicateAsset: {
        header: 'Duplicate asset',
        message: '',
        buttons: [
          {
            text: 'Ok',
            type: 'btn',
            class: 't-bg-yellow-400 btn-text-font',
            click: () => {
              this.$emit('setEditMode', false);
            }
          }
        ],
        btnText: 'Ok',
        type: 'error'
      },
      editSuccess: {
        header: 'Edits saved successfully',
        message: 'Ready to approve the asset with the edits you made or send the edited version back to the asset creator?',
        buttons: [
          {
            text: 'Approve',
            type: 'btn',
            class: 't-bg-yellow-400 btn-text-font',
            click: () => {
              this.onReviewSubmit('approved');
            }
          },
          {
            text: 'Return',
            type: 'btn',
            class: 't-bg-primary-color t-text-white btn-text-font btn-margin',
            click: () => {
              this.onReviewSubmit('returned');
            }
          },
          {
            text: 'Skip',
            type: 'link',
            class: 'primary',
            click: () => {
              this.$emit('setEditMode', false);
            }
          }
        ],
        type: 'success'
      }
    };
  },
  computed: {
    ...mapGetters(['tenant_settings']),
    getIcon() {
      if (this.functions.length === this.functionsList.map((func) => func.name).length) return 'mdi-checkbox-marked';
      else if (this.functions.length > 0) return 'mdi-checkbox-blank-outline';
      return 'mdi-checkbox-blank-outline';
    }
  },
  async created() {
    this.assetFileUploadAccess = this.tenant_settings['apply-asset-file-upload-access'] || '';
    this.assetLinkPresent = this.submittedAsset.externalAssetLink.length > 0 ? true : false;
    this.loadDetails();
  },
  deactivated() {
    this.isEquivalent = true;
    this.navigateNext = false;
    this.toPath = '';
    if (this.showDownloadProgress) {
      this.cancelDownload();
    }
  },

  methods: {
    formatSize(size) {
      if (!size) return '0 bytes';
      else if (size < 1024) return `${size} bytes`;
      else if (size >= 1024 && size < Math.pow(1024, 2)) return `${parseInt(size / 1024).toLocaleString()} KB`;
      else if (size >= Math.pow(1024, 2) && size < Math.pow(1024, 3)) return `${parseInt(size / Math.pow(1024, 2)).toLocaleString()} MB`;
      else return `${parseInt(size / Math.pow(1024, 3)).toLocaleString()} GB`;
    },
    showDeletePopup(type, id) {
      this.deleteObj = {
        type: type,
        id: id
      };
      this.showAlertsModal = true;
      this.modalMessageObj = this.deleteFilesObj;
      this.isObjectEqual();
    },
    toggleSelectAll() {
      if (this.functions.length === this.functionsList.map((func) => func.name).length) {
        this.functions = [];
      } else {
        this.functions = this.functionsList.map((func) => func.name);
      }
      this.isObjectEqual();
    },
    hideToastMessage() {
      setTimeout(() => {
        this.fileUploadStatus = '';
      }, 5000);
    },
    tryAgain() {
      this.fileUploadStatus = '';
    },
    resetExternalAssetLink() {
      if (this.$refs.externalAssetLink) this.$refs.externalAssetLink.reset();
    },
    cancelUploadAsset() {
      this.axiosController.abort();
      this.fileUploadStatus = 'F';
      this.assetReference = {};
    },
    cancelDownload() {
      this.axiosController.abort();
      this.showDownloadProgress = false;
      this.downloadPercentage = 0;
      this.$emit('downloadInProgress', false);
    },
    onUploadProgress(progressEvent) {
      const { loaded, total } = progressEvent;
      this.uploadPercentage = Math.floor((loaded * 100) / total);
    },
    onDownloadProgress(progressEvent) {
      const total = this.submittedAsset.assetReference ? this.submittedAsset.assetReference.size : 0;
      const loaded = progressEvent.loaded || 0;
      this.downloadPercentage = Math.floor((loaded * 100) / total);
    },
    onAssetLinkChange(assetLink) {
      this.assetLinkPresent = assetLink && assetLink.length > 0 ? true : false;
      this.isObjectEqual();
    },
    async uploadAsset(file) {
      this.fileUploadStatus = 'U';
      const assetId = this.submittedAsset.id;
      this.axiosController = new AbortController();
      const formData = new FormData();
      formData.set('governanceItemId', assetId);
      formData.set('governanceItemName', this.submittedAsset.name);
      formData.append('assetFile', file);
      const payload = {
        body: formData
      };
      this.$planAdmin
        .uploadAssets(payload, this.axiosController, this.onUploadProgress)
        .then((res) => {
          this.assetReference = res.data.assetReference || {};
          this.submittedAsset.assetReference = this.assetReference;
          this.fileUploadStatus = 'C';
          this.uploadPercentage = 0;
          this.hideToastMessage();
          this.isObjectEqual();
        })
        .catch(() => {
          this.fileUploadStatus = 'F';
          this.uploadPercentage = 0;
          this.hideToastMessage();
          this.assetReference = {};
        });
    },
    showToast(msg, className) {
      let toastDuration = 5000;
      this.$toasted.show(msg, {
        containerClass: 'toast-container',
        className: className,
        position: 'bottom-right',
        iconPack: 'icon-container',
        duration: toastDuration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      });
    },
    async onConfirm(comment) {
      const payload = {
        itemId: this.submittedAsset.id,
        action: this.status,
        comment: comment
      };
      await this.$planAdmin.onReviewSubmit(payload).then((res) => {
        if (res.status == 200) {
          this.showDialog = false;
          const toastObj = this.toastMessagesList[this.status];
          this.showToast(toastObj.message, toastObj.type);
          this.navigateNext = true;
          this.$router.push({ name: 'ManageSubmissions' });
        }
      });
    },
    onCancel() {
      this.showDialog = false;
      this.status = this.submittedAsset.reviewStatus;
      this.$emit('setEditMode', false);
    },
    onReviewSubmit(status) {
      this.showAlertsModal = false;
      this.modalMessageObj = {};
      this.status = status;
      this.showDialog = true;
    },
    async downloadAssetZipFile() {
      if (!this.showDownloadProgress) {
        const payload = {
          id: this.submittedAsset.id
        };
        this.showDownloadProgress = true;
        this.downloadPercentage = 0;
        this.axiosController = new AbortController();
        this.$emit('downloadInProgress', true);
        this.$planAdmin
          .downloadAssetZipFile(payload, this.axiosController, this.onDownloadProgress)
          .then((res) => {
            this.showDownloadProgress = false;
            this.axiosController = null;
            this.saveAs(res.data, this.submittedAsset.assetReference.name);
            this.$emit('downloadInProgress', false);
          })
          .catch(() => {
            this.showDownloadProgress = false;
            this.axiosController = null;
            this.$emit('downloadInProgress', false);
          });
      }
    },
    async saveEdits(tabNavigation) {
      if (this.disableSaveBtn()) {
        return true;
      }
      this.showSaveEdits = true;
      const obj = {
        id: this.submittedAsset.id,
        name: this.name,
        promoHeader: this.promoHeader,
        promoDescription: this.promoDescription,
        fullDescription: this.fullDescription,
        applications: this.applications,
        functions: this.functions,
        inventoryType: this.inventoryType,
        externalAssetLink: this.externalAssetLink,
        comment: this.comment,
        inventoryCategory: 'asset',
        userAction: 'admin-edit'
      };
      const formData = new FormData();
      formData.set('governanceItem', JSON.stringify(obj));
      this.uploadPdfFiles.forEach((el) => {
        if (!el.signedUrl) formData.append('howToGuideFile', el.file);
      });
      this.uploadScreenshots.forEach((el) => {
        if (!el.signedUrl) formData.append('mediaFiles', el.file);
      });
      const payload = {
        body: formData
      };
      this.$planAdmin
        .submitAsset(payload)
        .then((res) => {
          this.showSaveEdits = false;
          if (res.data.status == 400 && res.data.message == 'Invalid input Item with same set of name and applications already exist') {
            this.showAlertsModal = true;
            this.duplicateAsset.message = `A ${this.applications.join(', ')} asset with the name "${this.name}" already exists. Update the asset name or technology selection and submit again`;
            this.modalMessageObj = this.duplicateAsset;
            return;
          } else if (res.data.status == 400) {
            this.showAlertsModal = true;
            this.modalMessageObj = this.wentWrong;
            return;
          } else if (typeof tabNavigation !== 'boolean' && !tabNavigation) {
            this.showAlertsModal = true;
            this.modalMessageObj = this.editSuccess;
            this.navigateNext = false;
          } else {
            this.showAlertsModal = false;
            this.modalMessageObj = '';
            this.navigateNext = true;
            typeof tabNavigation === 'boolean' && tabNavigation && this.toPath ? this.$router.push({ path: this.toPath }) : this.$router.push({ name: 'AssetReviewDetail' });
          }
          this.showToast('Edits saved', 'success');
          this.isAdminEdited = true;
        })
        .catch(() => {
          this.showSaveEdits = false;
          this.showAlertsModal = true;
          this.modalMessageObj = this.wentWrong;
        });
    },
    closeModal(flag = 0) {
      this.showAlertsModal = false;
      if (flag > 0) this.$emit('setEditMode', false);
    },
    mapImagesAndVideos(galleryImages) {
      if (galleryImages && galleryImages.length) {
        this.uploadScreenshots = galleryImages.map((img) => {
          return {
            name: img.caption,
            file: img,
            size: img.size,
            dateAdded: img.dateAdded,
            signedUrl: img.signedUrl
          };
        });
      }
    },
    mapPdfFiles(pdfFile) {
      if (pdfFile && pdfFile != null) {
        this.uploadPdfFiles = [
          {
            name: pdfFile.name,
            file: pdfFile,
            size: pdfFile.size,
            dateAdded: pdfFile.dateAdded,
            signedUrl: pdfFile.signedUrl
          }
        ];
      }
    },
    async loadDetails() {
      this.toolsList = await this.$planAdmin.getGovernanceTools();
      const inventoryTypeList = await this.$planAdmin.getAssetInventoryTypes();
      inventoryTypeList.forEach((el) => this.inventoryTypeList.push(el.name));
      this.formInEditMode(this.submittedAsset);
      this.isObjectEqual();
    },
    formInEditMode(assetObj) {
      for (const [key, value] of Object.entries(assetObj)) {
        if (key == 'comment') {
          this[key] = ''; // Always empty the comment section in the plan admin edit form
        } else if (typeof value !== 'function') {
          this[key] = value;
        }
      }
      this.mapImagesAndVideos(assetObj.galleryImages);
      this.mapPdfFiles(assetObj.supportingFile);
      this.assetTabIndex = assetObj.externalAssetLink || this.assetFileUploadAccess == 'link' ? 1 : 0;
      this.assetReference = assetObj.assetReference || {};
    },
    setEditModeFlag() {
      if (this.disableCancelBtn()) {
        return true;
      }
      if (this.disableSaveBtn()) {
        this.cancelEdit.buttons[0].disabled = true;
      }
      this.showAlertsModal = true;
      this.modalMessageObj = this.cancelEdit;
    },
    disableSaveBtn() {
      const valid = [this.promoHeader, this.promoDescription, this.applications, this.functions, this.inventoryType].every((el) => {
        return el.length > 0;
      });
      return !this.isEquivalent && !this.showDownloadProgress && valid && this.validAssetName() && this.validAsset() && this.fileUploadStatus == '' && !this.showSaveEdits ? false : true;
    },
    disableCancelBtn() {
      return this.showDownloadProgress || this.fileUploadStatus != '' || this.showSaveEdits ? true : false;
    },
    validAssetName() {
      return !/[^\w\d\s]/.test(this.name) && this.name.length > 0 ? true : false;
    },
    validAsset() {
      if (this.assetLinkPresent) {
        return new RegExp(/^((https|http):\/\/)+[-a-zA-Z0-9@:%_.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_.~#?&//=]*)?/gi).test(this.externalAssetLink) && this.externalAssetLink.length > 0 ? true : false;
      } else {
        return Object.keys(this.assetReference).length > 0 ? true : false;
      }
    },
    async onDownload(imageFile) {
      const data = await fetch(imageFile.signedUrl);
      const blobData = await data.blob();
      this.saveAs(blobData, imageFile.name);
    },
    saveAs(blob, fileName) {
      const url = window.URL.createObjectURL(blob);
      const anchorElem = document.createElement('a');
      anchorElem.style = 'display:none';
      anchorElem.href = this.$sanitize(url);
      anchorElem.download = this.$sanitize(fileName);
      anchorElem.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 1000);
    },
    async upload(rawFile, type) {
      const file = { name: rawFile.name, file: rawFile, size: rawFile.size };
      let fileSize = Math.floor(rawFile.size / (1024 * 1024), 2);
      switch (type) {
        case 'png':
          if (['image/png'].includes(rawFile.type)) {
            if (this.uploadScreenshots.some((e) => e.name === rawFile.name)) {
              this.showAlertsModal = true;
              this.duplicateFileErrorMsg.message = `A file with the name ${rawFile.name} already exists. Delete the duplicate to replace it.`;
              this.modalMessageObj = this.duplicateFileErrorMsg;
            } else if (this.uploadScreenshots.length >= 10) {
              this.showAlertsModal = true;
              this.modalMessageObj = this.exceedFileLimitMsg;
            } else {
              let totalFileSize = this.uploadScreenshots.reduce((sum, obj) => sum + obj.size, fileSize);
              totalFileSize = totalFileSize / (1024 * 1024);
              if (totalFileSize < 250 && this.uploadScreenshots.length < 10) {
                this.uploadScreenshots.push(file);
              }
            }
            this.isObjectEqual();
          }
          break;
        case 'pdf':
          if (['application/pdf'].includes(rawFile.type)) {
            let totalFileSize = this.uploadPdfFiles.reduce((sum, obj) => sum + obj.size, fileSize);
            totalFileSize = totalFileSize / (1024 * 1024);
            if (this.uploadPdfFiles.length > 0) {
              this.showAlertsModal = true;
              this.modalMessageObj = this.pdfFileExceedLimitMsg;
            } else if (totalFileSize < 250 && this.uploadPdfFiles.length < 10) {
              this.uploadPdfFiles.push(file);
            }
            this.isObjectEqual();
          }
          break;
        case 'zip':
          if (rawFile.type.includes('zip')) {
            if (Object.keys(this.assetReference).length > 0) {
              this.showAlertsModal = true;
              this.modalMessageObj = this.pdfFileExceedLimitMsg;
            } else if (fileSize > 150) {
              this.showAlertsModal = true;
              this.modalMessageObj = this.zipFileExceedError;
            } else {
              this.assetReference = rawFile;
              this.uploadAsset(rawFile);
            }
            this.isObjectEqual();
          }
          break;
      }
    },
    deletePdfDocument() {
      this.uploadPdfFiles = [];
    },
    handleClick(event, type) {
      const files = event.target.files;
      if (!files.length) return;
      files.forEach((file) => {
        this.upload(file, type);
      });
    },
    handleDrop(event, type) {
      event.stopPropagation();
      event.preventDefault();
      const files = event.dataTransfer.files;
      files.forEach((file) => {
        this.upload(file, type);
      });
      event.stopPropagation();
      event.preventDefault();
    },
    handleDragover(event) {
      event.stopPropagation();
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
    },
    deleteFiles(type, id) {
      this.deleteObj = {};
      this.showAlertsModal = false;
      this.modalMessageObj = {};
      if (type == 'zip') {
        const payload = {
          id: this.submittedAsset.id
        };
        this.showZipUpload = false;
        this.$planAdmin
          .deleteAssetFile(payload)
          .then(() => {
            this.assetReference = {};
            this.showZipUpload = true;
            this.isObjectEqual();
          })
          .catch(() => {
            this.showZipUpload = true;
            this.showToast('There was a problem deleting your asset. You can try again later', 'error');
          });
      } else if (type == 'pdf') {
        this.resetPdfEl = true;
        if (this.uploadPdfFiles[id].signedUrl) {
          const payload = {
            id: this.submittedAsset.id,
            pdfPointer: this.uploadPdfFiles[id].file.pointer
          };
          this.$planAdmin.deletePdfFromCreateAsset(payload).then((res) => {
            if (res.status == 200) {
              this.showToast('File deleted successfully', 'success');
              this.uploadPdfFiles = this.uploadPdfFiles.filter((el, index) => index !== id);
              this.isObjectEqual();
            } else {
              this.showToast('There was a problem deleting your asset. You can try again later', 'error');
            }
          });
        } else {
          this.uploadPdfFiles = this.uploadPdfFiles.filter((_el, index) => index !== id);
        }
      } else {
        if (this.uploadScreenshots[id].signedUrl) {
          const payload = {
            id: this.submittedAsset.id,
            imagePointer: this.uploadScreenshots[id].file.pointer
          };
          this.$planAdmin.deleteImageFromCreateAsset(payload).then(() => {
            this.uploadScreenshots = this.uploadScreenshots.filter((_el, index) => index !== id);
            this.isObjectEqual();
          });
        } else {
          this.uploadScreenshots = this.uploadScreenshots.filter((_el, index) => index !== id);
          this.isObjectEqual();
        }
      }
    },
    prepareToExit(to, from, next) {
      if (this.navigateNext) {
        next();
      } else {
        this.isObjectEqual();
        if (!this.isEquivalent && this.submittedAsset) {
          this.toPath = to.path || '';
          this.setEditModeFlag();
          this.cancelEdit.buttons[0].click = () => {
            this.saveEdits(true);
          };
          this.cancelEdit.buttons[1].click = () => {
            this.$emit('setEditMode', false, true);
          };
          return true;
        } else {
          return false;
        }
      }
    },
    isObjectEqual() {
      this.galleryImages =
        this.submittedAsset.galleryImages && this.submittedAsset.galleryImages.length > 0
          ? this.submittedAsset.galleryImages.map((img) => {
              return {
                name: img.caption,
                file: img,
                size: img.size,
                dateAdded: img.dateAdded,
                signedUrl: img.signedUrl
              };
            })
          : [];
      this.pdfFile =
        this.submittedAsset.supportingFile && this.submittedAsset.supportingFile != null
          ? [
              {
                name: this.submittedAsset.supportingFile.name,
                file: this.submittedAsset.supportingFile,
                size: this.submittedAsset.supportingFile.size,
                dateAdded: this.submittedAsset.supportingFile.dateAdded,
                signedUrl: this.submittedAsset.supportingFile.signedUrl
              }
            ]
          : [];
      const EditedAssetDetails = {
        id: this.submittedAsset.id,
        name: this.name,
        promoHeader: this.promoHeader,
        promoDescription: this.promoDescription,
        fullDescription: this.fullDescription,
        applications: this.applications,
        functions: this.functions,
        inventoryType: this.inventoryType,
        externalAssetLink: this.externalAssetLink,
        comment: this.comment,
        galleryImages: this.uploadScreenshots && this.uploadScreenshots.length > 0 ? this.uploadScreenshots : [],
        supportingFile: this.uploadPdfFiles && this.uploadPdfFiles.length > 0 ? this.uploadPdfFiles : [],
        assetReference: this.assetReference || {}
      };
      const submittedAssetDetails = {
        id: this.submittedAsset.id,
        name: this.submittedAsset.name,
        promoHeader: this.submittedAsset.promoHeader,
        promoDescription: this.submittedAsset.promoDescription,
        fullDescription: this.submittedAsset.fullDescription,
        applications: this.submittedAsset.applications,
        functions: this.submittedAsset.functions,
        inventoryType: this.submittedAsset.inventoryType,
        externalAssetLink: this.submittedAsset.externalAssetLink,
        comment: '',
        galleryImages: this.galleryImages,
        supportingFile: this.pdfFile,
        assetReference: this.submittedAsset.assetReference || {}
      };
      this.isEquivalent = Object.keys(submittedAssetDetails).every((key) => {
        return JSON.stringify(submittedAssetDetails[key]) === JSON.stringify(EditedAssetDetails[key]);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.append-content {
  bottom: 0;
  position: sticky;
  background: #fff;
}
.submission-details-container {
  width: 96%;
}

.asset-tab-border {
  border-bottom: 2px solid #e0e1e1;
}

.h5-width-cls {
  width: 50%;
}

.toast-progress-top {
  margin-top: 2rem;
}

.toast-progress-left {
  margin-left: 2rem;
}

.custom-toast {
  width: 26.25rem;
  height: 8.5rem;
  box-shadow: 0px 5px 10px #0000003d;
  opacity: 1;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 100;
}

.toast-footer {
  height: 0.5rem;
  /* UI Properties */
  box-shadow: 0px 0.3125rem 0.625rem #0000003d;
  width: 100%;
  position: fixed;
  bottom: 0px;
  width: 26.25rem;
  background: #ffb92e 0% 0% no-repeat padding-box;
}

.status-message {
  margin-top: 8px !important;
  margin-left: 7.5rem !important;
  text-align: center;
  font: normal normal normal 14px/22px Arial !important;
}

.status-message-save {
  margin-top: 0.5rem !important;
  margin-left: 0.5rem !important;
  text-align: center;
  font: normal normal normal 14px/22px Arial !important;
}

.custom-toast-loading {
  height: 6rem;
  background: #fff4df 0% 0% no-repeat padding-box;
}

.svg-icon {
  height: 1.5rem !important;
  width: 1.5rem !important;
}

.asset-edit-container {
  width: 66%;
  height: auto;
}

.btn-text-font {
  font-size: 1rem !important;
  font-family: 'Futura PT Demi' !important;
  text-transform: none;
  margin-right: 1.5rem;
  width: 118px;
  height: 47px;
}

.section-title {
  font-weight: bold;
  font-family: Arial;
  font-size: 14px;
  margin-bottom: 0.8rem;
}

.upload-button {
  min-width: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;

  .upload-btn-content {
    color: black;
    text-align: center;
  }
}

.file-upload-container {
  border: 1px dashed #efefef;
  background: #f7f7f7;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0.5rem 0 1rem 0;
  min-height: 178px;
}

.file-upload-wrapper {
  color: #1f3370;
  cursor: pointer;
  letter-spacing: 0.2px;
  margin-bottom: 1rem;

  input {
    display: none;
  }
}

.section-subheading {
  color: #000000;
  display: flex;
  flex: 3;
  align-items: center;
  font: normal normal normal 1rem/1.25rem Arial;
  .img-svg-icon {
    min-width: 1.5rem;
    margin-right: 0.25rem;
  }
  span {
    font: normal normal normal 1rem/1.25rem Arial;
    margin: 0 0 0 0.2rem;
  }
}

.file-details {
  width: 100%;

  .file-meta-data {
    background-color: #f7f7f7;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.4rem;
    padding: 0.5rem;

    .section-meta-info {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;

      .section-meta-text {
        color: #5c5c5c;
        font-size: 0.75rem;
      }

      .cancel-button,
      .download-button {
        display: flex;
        align-items: center;
        margin-left: 0.75rem;
        cursor: pointer;
      }
    }
  }
}

.icon-container {
  display: inline-grid;
  margin-top: 4rem;
  margin-right: -6rem;
  float: right;

  @media screen and (max-width: 768px) {
    display: block;
    justify-content: center;
    margin-bottom: 1rem;
    float: unset;
  }

  .svg-disabled {
    opacity: 0.5;
    cursor: auto;
    color: unset;
    text-decoration: unset;
    padding: 0.25rem 0.5rem;
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    border: 1px solid var(--ion-color-dark-grey);
    margin-bottom: 0.8rem;

    @media screen and (max-width: 768px) {
      margin-right: 1rem;
    }
  }

  .action-icon:hover .tooltiptext {
    visibility: visible;
  }

  .action-icon:hover {
    background: #efefef 0% 0% no-repeat padding-box;
  }
}

.form-action-items {
  position: relative;
  width: 8.125rem;
  height: 3.125rem;
  left: 5.8rem;
  bottom: 4.25rem;
  height: 1.31rem;
  float: right;
}

.action-icon {
  color: unset;
  text-decoration: unset;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 50%;
  height: 1.5rem !important;
  width: 1.5rem !important;
  border: 1px solid var(--ion-color-dark-grey);
  margin-bottom: 0.8rem;

  @media screen and (max-width: 768px) {
    margin-right: 1rem;
  }
}

.icn-size {
  width: 1.5rem !important;
  height: 1.5rem !important;
  cursor: pointer;
}

.save-icn-img {
  float: left;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e1e1;
  opacity: 1;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
}

.edit-icn-img {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e1e1;
  opacity: 1;
  width: 3rem;
  height: 3rem;
  float: left;
  border-radius: 50%;
  text-align: center;
  padding-top: 10px;
  margin-left: 16px;
}

.custom-toast {
  width: 26.25rem;
  height: 8.5rem;
  box-shadow: 0px 5px 10px #0000003d;
  opacity: 1;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 100;

  .toast-footer {
    height: 0.5rem;
    /* UI Properties */
    box-shadow: 0px 0.3125rem 0.625rem #0000003d;
    width: 100%;
    position: fixed;
    bottom: 0px;
    width: 26.25rem;
  }

  .cancel-btn {
    border: 1p solid red;
    width: 3.625rem;
    height: 1.68rem;
    margin-left: 5.43rem;
    margin-top: 1.05rem;

    .cancel-btn-text {
      font: normal normal normal 0.75rem Futura PT Demi !important;
      text-transform: none !important;
    }
  }

  .status-message {
    margin-top: 1.8rem !important;
    margin-left: 5.43rem !important;
    text-align: left;

    .status-message-bold {
      font: normal normal bold 16px/22px Arial !important;
      color: #000000;
    }

    .status-message-normal {
      font: normal normal normal 16px/22px Arial !important;
      color: #000000;
    }
  }

  .toast-close-icon {
    height: 1.5rem;
    width: 1.5rem;
    float: right;
    margin: 1rem 1rem 0 0;
    cursor: pointer;

    svg {
      width: 1rem !important;
      height: 1rem !important;
    }
  }
}

.custom-toast-success {
  background: #d9f8f3 0% 0% no-repeat padding-box;
  width: 26.25rem;
  height: 6.5rem;

  .toast-footer {
    background: #00d1b0 0% 0% no-repeat padding-box;
  }

  .toast-success-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 1.9rem;
    float: left;
    margin-left: 2.5rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .status-message {
    margin-top: 2rem !important;
    margin-left: 5.43rem !important;
    text-align: left;

    .status-message-bold {
      font: normal normal bold 16px/22px Arial !important;
      color: #000000;
    }

    .status-message-normal {
      font: normal normal normal 16px/22px Arial !important;
      color: #000000;
    }
  }
}

.custom-toast-info {
  background: #fff4df 0% 0% no-repeat padding-box;

  .toast-footer {
    background: #ffb92e 0% 0% no-repeat padding-box;
  }
}

.custom-toast-failed {
  background: #fae5dc 0% 0% no-repeat padding-box;
  box-shadow: #0000003d;

  .toast-footer {
    background: #d04910 0% 0% no-repeat padding-box;
  }

  .toast-failed-icon {
    width: 2rem;
    height: 2rem;
    margin-top: 1.9rem;
    float: left;
    margin-left: 2.5rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}

.asset-zip-name {
  padding-left: 0.5rem;
  font: normal normal normal 1rem/1.25rem Arial;
}

.show-mandatory-message {
  height: 4rem;
  background: #fff4df 0% 0% no-repeat padding-box;
  border-radius: 0.25rem;
  opacity: 1;
  display: flex;

  .show-icons {
    padding-top: 1.5rem;
    margin-left: 1.5rem;
  }

  .show-message {
    padding-top: 1.5rem;
    padding-left: 0.3rem;
    font: normal normal normal 0.75rem/1.125rem Arial;
    color: #000000;
  }
}

.custom-toast-loading {
  height: 6rem;
  background: #fff4df 0% 0% no-repeat padding-box;

  .toast-footer {
    background: #ffb92e 0% 0% no-repeat padding-box;
  }
}

.download-progress {
  height: 8.5rem;
}
.tip-content {
  background: #efefef;
  padding: 1px 1rem;
}

.asset-inventory-form {
  margin-top: 1.25rem !important;
}

.asset-external-link-form {
  margin-top: 1.25rem !important;
}
</style>
<style lang="scss">
.tip-content {
  h2 {
    color: var(--ion-color-tertiary) !important;
    font-weight: bold;
  }

  .section {
    background: #efefef;

    p {
      color: #5c5c5c;
      font: normal normal normal 1rem/1.375rem Arial;
      letter-spacing: 0px;
    }
  }
}

.toast-container {
  bottom: 0 !important;
  right: 0 !important;

  .toasted {
    color: #000000 !important;
    padding-left: 4rem !important;
    width: 20rem !important;
    height: 5rem !important;
    font-size: 0.75rem !important;
    font-family: Arial !important;

    &.success {
      background: #d9f8f3 !important;
      background-image: url('~@/assets/images/svgs/icon-success.svg') !important;
      background-repeat: no-repeat !important;
      background-position: left !important;
      background-position-x: 1.375rem !important;
      background-size: 1.25rem !important;
      border-bottom: 0.4rem solid #28dabd !important;
    }

    &.info {
      background: #fff4df !important;
      background-image: url('~@/assets/images/svgs/icon-info.svg') !important;
      background-repeat: no-repeat !important;
      background-position: left !important;
      background-position-x: 1.375rem !important;
      background-size: 1.25rem !important;
      border-bottom: 0.4rem solid #ffc758 !important;
    }

    &.error {
      background: #fae5dc !important;
      background-image: url('~@/assets/images/svgs/icon-alert.svg') !important;
      background-repeat: no-repeat !important;
      background-position: left !important;
      background-position-x: 1.375rem !important;
      background-size: 1.25rem !important;
      border-bottom: 0.4rem solid #d04910 !important;
    }

    .action {
      background-image: url('~@/assets/images/svgs/icon-cancel.svg') !important;
      background-position: center !important;
      background-size: contain !important;
      position: absolute !important;
      right: 11px !important;
      top: 0 !important;
    }
  }
}

.select-dropdown-item {
  .v-select-list {
    padding-bottom: 0;
  }
  .v-list-item {
    margin: 5px;
    .v-list-item__title {
      font-size: 0.875rem;
    }
  }
}

.svg-disabled {
  opacity: 0.5;
  cursor: auto !important;
}
.download-cancel-btn {
  margin: 0.5rem 0px 0px 8.43rem !important;
}
.download-status {
  text-align: center;
  font: normal normal normal 14px/22px Arial !important;
}

.save-tooltip {
  position: relative;
  display: inline-block;
}

.save-tooltip .save-tooltiptext {
  visibility: hidden;
  width: 7.5rem;
  text-align: center;
  padding: 5px 0;
  bottom: 3.2rem;
  right: -70%;
  position: absolute;
  z-index: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000001f;
  border-radius: 4px;
  opacity: 1;
  font-size: 0.88rem;
  font-family: Arial;
}

.save-tooltip:hover .save-tooltiptext {
  visibility: visible;
}
</style>
