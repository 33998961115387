<template>
  <div>
    <v-card v-show="dataReady" class="card">
      <v-btn name="download" aria-label="download enrollment" title="download enrollment" icon class="t-absolute t-right-0 t-w-8 t-h-8 enrollment-image" @click="downloadEnrollmentChartPNG()">
        <svg-icon icon-class="PNG_icon" class="t-w-8 t-h-8" />
      </v-btn>
      <h2 class="card-header h2">{{ $t('insights.enrollmentChartTitle') }}</h2>
      <div class="enrollment-donut-chart-container">
        <div v-if="componentIsActive">
          <apexchart type="radialBar" ref="enrollmentChart" :height="300" :options="options" :series="[percentage]" />
        </div>
        <div class="chart-labels">
          <div class="chart-labels-content">
            <span class="chart-circle-blue"></span>
            <span class="chart-labels-text">{{ $t('insights.enrollments.enrolledLabel') }}</span>
            <span class="status-info">
              <ToolTip :content="$t('insights.enrollments.tooltipEnrolled')" width="20.0625rem" />
            </span>
          </div>
          <div class="chart-labels-content">
            <span class="chart-circle-silver"></span>
            <span class="chart-labels-text">{{ $t('insights.enrollments.notEnrolledLabel') }}</span>
            <span class="status-info">
              <ToolTip :content="$t('insights.enrollments.tooltipNotEnrolled')" width="20.0625rem" />
            </span>
          </div>
        </div>
      </div>
      <v-container>
        <v-row class="v-row-container">
          <v-col cols="8">
            <span class="chart-blurb">{{ $t('insights.enrollmentChartBlurb') }}</span>
          </v-col>
          <v-col cols="4">
            <img alt="" src="@/assets/images/icons/coloredboxes.png" class="colorbox" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card v-show="!dataReady" class="card loading-card">
      <div class="loader t-flex t-justify-center t-items-center">
        <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Chart Data" />
      </div>
    </v-card>
  </div>
</template>
<script>
import localforage from 'localforage';
import ToolTip from '../../../components/molecules/ToolTip.vue';
import { getDateForCSV, generateCSVTimeFilter } from '@/utils';
import { translate } from '@/plugins/i18n.js';

export default {
  components: { ToolTip },
  props: {
    selectedTimeOptionString: {
      type: String
    },
    dateRange: {
      type: Object
    }
  },
  data() {
    return {
      percentage: 0,
      dataReady: false,
      componentIsActive: false,
      isComponentRefreshed: false,
      options: {
        chart: {
          height: 300,
          type: 'radialBar',
          toolbar: { show: false }
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '60%'
            },
            dataLabels: {
              showOn: 'always',
              name: {
                offsetY: 20,
                color: '#1f3370',
                formatter: () => {
                  return translate('insights.enrollments.enrolledLabel');
                }
              },
              value: {
                offsetY: -20,
                show: true,
                fontSize: '2.4rem',
                fontWeight: 500,
                color: '#1f3370'
              }
            }
          }
        },
        colors: ['#1f3370', '#e1e1e1'],
        stroke: {
          lineCap: 'round'
        },
        legend: {
          show: false,
          position: 'right',
          offsetY: 80,
          offsetX: 100,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: false
          }
        }
      }
    };
  },
  async mounted() {
    this.dataReady = false;
    this.componentIsActive = true;
  },
  updated() {
    this.isComponentRefreshed = false;
  },
  deactivated() {
    this.componentIsActive = false;
    this.isComponentRefreshed = false;
  },
  watch: {
    selectedTimeOptionString() {
      this.dataReady = false;
      this.isComponentRefreshed = true;
      this.refreshData();
    },
    dateRange: {
      handler() {
        if (!this.isComponentRefreshed) {
          this.dataReady = false;
          this.refreshData();
        } else {
          this.isComponentRefreshed = false;
        }
      },
      deep: true
    }
  },
  methods: {
    getDateForCSV,
    generateCSVTimeFilter,
    async refreshData() {
      this.dataReady = false;
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      const time = this.selectedTimeOptionString === 'CUSTOM' && this.dateRange.startDate && this.dateRange.endDate ? `${this.selectedTimeOptionString}?startDate=${this.dateRange.startDate}&endDate=${this.dateRange.endDate}&dateFormat=MM-dd-yyyy` : this.selectedTimeOptionString;
      const payload = { tenantGuid: tenantGuid, timePeriod: time };
      this.$planAdmin
        .getEnrolledRate(payload)
        .then((response) => {
          if (response && response.enrolled_percent) {
            this.percentage = Math.round(response.enrolled_percent);
          } else {
            this.percentage = 0;
          }
          this.dataReady = true;
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    downloadEnrollmentChartPNG() {
      this.$refs.enrollmentChart.dataURI().then(({ imgURI }) => {
        let enrollmentImageAnchor = document.createElement('a');
        enrollmentImageAnchor.href = imgURI;
        enrollmentImageAnchor.setAttribute('download', `Org Enrollment Rate Chart_${generateCSVTimeFilter(this.selectedTimeOptionString)}_${this.getDateForCSV()}`);
        enrollmentImageAnchor.click();
      });
    }
  }
};
</script>

<style lang="scss">
.theme--light.v-btn.v-btn--icon:focus-visible {
  outline: black 2px solid;
  border-radius: unset;
  color: white;
}
.enrollment-donut-chart-container {
  display: grid;
  grid-template-columns: 8fr 4fr;

  .apexcharts-datalabel-value {
    fill: #1f3370;
    font-size: 2.625rem;
    font-family: 'Futura PT Demi' !important;
  }

  .apexcharts-datalabel-label {
    fill: #5c5c5c;
    font-size: 0.875rem;
    font-family: Arial !important;
  }

  .apexcharts-legend {
    .apexcharts-legend-series {
      display: none;
    }
  }

  .chart-labels {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .chart-labels-content {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      font: normal normal normal 1rem/1.125rem Arial;
      letter-spacing: 0rem;
      color: #2d2d2d;

      opacity: 1;
      .chart-circle-blue {
        width: 0.75rem;
        height: 0.75rem;
        background: #19295a 0% 0% no-repeat padding-box;
        opacity: 1;
        color: red;
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.5rem;
      }

      .chart-circle-silver {
        width: 0.75rem;
        height: 0.75rem;
        background: #e0e1e1 0% 0% no-repeat padding-box;
        opacity: 1;
        color: red;
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.5rem;
      }

      .chart-labels-text {
        width: max-content;
        white-space: nowrap;
      }

      .status-info {
        position: relative;
        margin-left: 0.5rem;
        &:after {
          content: '\24D8';
          font-size: 0.625rem;
          color: #000;
          font-weight: bold;
        }
        &:hover {
          .tooltip-text {
            z-index: 999;
            visibility: visible;
            left: -10.0313rem;
          }
        }
      }
    }
  }
}
.v-row-container {
  margin: 0.2rem 1rem;
}
.chart-blurb {
  float: left;
}
.colorbox {
  float: right;
  height: 1.875rem;
}
.enrollment-image {
  display: inline;
  margin: 1.5625rem;
}
</style>
