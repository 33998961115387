// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/futura/Futura-PT-Book.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/futura/Futura-PT-Demi.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/futura/Futura-PT-Light.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/assets/fonts/futura/Futura-PT-Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("@/assets/fonts/source-serif/SourceSerif4Subhead-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("@/assets/fonts/source-serif/SourceSerif4Subhead-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("@/assets/fonts/source-sans/SourceSans3-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("@/assets/fonts/source-sans/SourceSans3-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("@/assets/fonts/source-sans/SourceSans3-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("@/assets/fonts/source-sans/SourceSans3-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Futura PT Book;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:Futura PT Demi;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@font-face{font-family:Futura PT Light;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}@font-face{font-family:Futura PT Md;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}@font-face{font-family:\"Source Serif\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"opentype\")}@font-face{font-family:Source Sans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"opentype\")}@font-face{font-family:Source Sans Bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"opentype\")}.redesign *{font-family:Arial,sans-serif}.redesign h1,.redesign h2,.redesign h3,.redesign h4,.redesign h5,.redesign h6{font-family:Arial,Futura PT Demi,sans-serif}.texty-input{border:1px solid #dedede;border-radius:2px;font-size:14px;padding-left:10px}.filters .v-text-field--filled.v-input--dense>.v-input__control>.v-input__slot{background-color:none!important;border:2px solid #000}.filters .theme--light.v-text-field--solo-inverted.v-input--is-focused>.v-input__control>.v-input__slot{background:none}.filter .theme--light.v-text-field--solo>.v-input__control>.v-input__slot{margin-bottom:0}.filter .v-text-field.v-text-field--enclosed .v-text-field__details{display:none}.v-autocomplete__content.v-menu__content{background-color:#5c5c5c!important;border-radius:20px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
