<template>
  <div class="fow-screen-3">
    <h3>{{ translate('hr_tools.fow.screen3_title') }}</h3>
    <div class="current-maturity-measures">
      <div class="maturity-level" v-for="(level, index) in automationList" :key="index" :style="{ borderLeft: `1rem solid ${level.color}`, paddingLeft: '1rem' }">
        <h4>{{ level.title }}</h4>
        <p>{{ level.subTitle }}</p>
      </div>
    </div>
    <div class="curr-automation-maturity" v-for="roleInfo in roleData" :key="roleInfo.guid">
      <v-data-table :headers="headers" :items="roleInfo.tasks" :items-per-page="-1" hide-default-footer="true" class="role-table-maturity">
        <!-- First header with button, task name and task type - customized -->
        <template v-slot:[`header.roleName`]="{ column }">
          <span class="custom-header-1">{{ roleInfo.roleName }}</span>
          <tr>
            <v-btn data-el="addTag" variant="outlined" :class="roleInfo.isEditBtnClick ? 'submit-btn' : 'edit-btn'" @click="editMaturityLevel(roleInfo)" :disabled="selectedType === 'onet'">
              <template v-slot:prepend> <svg-icon :icon-class="roleInfo.isEditBtnClick ? 'edit-icon-white' : 'edit-icon'" class="svg-section" /> </template>{{ btnName(roleInfo) }}
            </v-btn>
          </tr>
          <tr class="header-1-sub-header">
            <th v-for="(col, index) in column.children" :key="col.key">
              <span :class="`sub-header-span-${index}`">{{ col.title }}</span>
            </th>
          </tr>
        </template>

        <!-- Second header with all maturity levels - customized -->
        <template v-slot:[`header.AML`]="{ column }">
          <span class="custom-header-2">{{ column.title }}</span>
          <tr class="header-2-sub-header">
            <th style="padding: unset !important" class="sub-header-th">
              <div class="maturity-level" v-for="(level, index) in automationList" :key="index" :class="`sub-header-th-${index}`">
                <span class="sub-header-span">{{ level.title }}</span>
                <p class="sub-header-p">{{ level.subTitle }}</p>
              </div>
            </th>
          </tr>
        </template>

        <!-- Removing duplicate headers -->
        <template v-slot:[`header.taskName`]="{}"> </template>
        <template v-slot:[`header.taskType`]="{}"> </template>
        <template v-slot:[`header.automation`]="{}"> </template>

        <!-- Customizing body data -->
        <template v-slot:item="{ item }">
          <tr>
            <td :title="item.taskName" class="task-name">{{ item.taskName }}</td>
            <td>{{ item.taskType }}</td>
            <td>
              <draggable-progress-bar @input="handleProgressInput" @change="handleProgressChange" :item="item" :roleGuid="roleInfo.guid" :updateAllowed="roleInfo.isEditBtnClick" />
            </td>
          </tr>
        </template>

        <template #bottom></template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { translate } from '@/plugins/i18n.js';
import { mapState } from 'vuex';
import DraggableProgressBar from './DraggableProgressBar';
import constant from '../../../api/constant';

export default {
  name: 'FowScreen3',
  emits: [],
  components: { DraggableProgressBar },
  computed: {
    ...mapState('workforce', ['storeSelectedRoles', 'storeSelectedCompanyName', 'storeSelectedFileName', 'storeSelectedFunctions', 'storeSelectedOption', 'storeOnetCompanyGuid'])
  },
  data() {
    return {
      headers: [
        {
          title: '',
          align: 'start',
          width: '30%',
          key: 'roleName',
          children: [
            {
              title: 'Task',
              align: 'start',
              key: 'taskName',
              width: '15%'
            },
            {
              title: 'Task type',
              align: 'start',
              key: 'taskType',
              width: '15%'
            }
          ]
        },
        {
          title: 'Automation Maturity level',
          align: 'start',
          width: '70%',
          key: 'AML',
          children: [
            {
              align: 'start',
              key: 'automation'
            }
          ]
        }
      ],
      roleData: [],
      file: {},
      company: {},
      selectedRoles: [],
      updatedVal: '50',
      selectedFuncs: [],
      selectedType: '',
      onetCompanyGuid: '',
      automationLevels: {
        100: {
          color: '#B3DCF9',
          type: 'fullAutomated',
          potential: 'highPotentialForAutomation',
          subTitle: '0% no manual labour needed\n100% Automated',
          progress: 100
        },
        70: {
          color: '#4DACF1',
          type: 'augmented',
          potential: 'highPotentialForAugmentation',
          subTitle: '30% manual labour needed\n70% Automated',
          progress: 70
        },
        30: {
          color: '#003DAB',
          type: 'lowPotential',
          potential: 'lowPotentialForAutomationOrAugmentation',
          subTitle: '70% manual labour needed\n30% Automated',
          progress: 30
        },
        0: {
          color: '#141414',
          type: 'unaffectedAuto',
          potential: 'unaffected',
          subTitle: '100% manual labour needed\n0% Automated',
          progress: 0
        }
      },
      automationList: [
        {
          title: 'Full automated',
          subTitle: '0% no manual labour needed\n100% Automated',
          align: 'start',
          key: 'fullAutomated',
          color: '#B3DCF9'
        },
        {
          title: 'Augmented',
          subTitle: '30% manual labour needed\n70% Automated',
          align: 'start',
          key: 'augmented',
          color: '#4DACF1'
        },
        {
          title: 'Low potential',
          subTitle: '70% manual labour needed\n30% Automated',
          align: 'start',
          key: 'lowPotential',
          color: '#003DAB'
        },
        {
          title: 'Unaffected',
          subTitle: '100% manual labour needed\n0% Automated',
          align: 'start',
          key: 'unaffectedAuto',
          color: '#141414'
        }
      ]
    };
  },

  watch: {},

  async mounted() {
    // Fetch data in parallel using Promise.all
    const [companyData, fileData, selectedRoles, selectedFuncs, selectedType, onetCompanyGuid] = await Promise.all([
      this.storeSelectedCompanyName || localforage.getItem('storeSelectedCompanyName'),
      this.storeSelectedFileName || localforage.getItem('storeSelectedFileName'),
      this.storeSelectedRoles || localforage.getItem('storeSelectedRoles'),
      this.storeSelectedFunctions || localforage.getItem('storeSelectedFunctions'),
      this.storeSelectedOption || localforage.getItem('storeSelectedOption'),
      this.storeOnetCompanyGuid || localforage.getItem('storeOnetCompanyGuid')
    ]);

    // Parse JSON safely with error handling
    try {
      this.company = typeof companyData === 'string' ? JSON.parse(companyData) : companyData;
      this.file = typeof fileData === 'string' ? JSON.parse(fileData) : fileData;
      this.selectedRoles = typeof selectedRoles === 'string' ? JSON.parse(selectedRoles) : selectedRoles;
      this.selectedFuncs = typeof selectedFuncs === 'string' ? JSON.parse(selectedFuncs) : selectedFuncs;
      this.selectedType = selectedType;
      this.onetCompanyGuid = onetCompanyGuid;
    } catch (parseError) {
      console.error('Error parsing JSON:', parseError);
      return;
    }

    const isOnetData = this.selectedType === 'onet';
    const payload = {
      companyGuid: isOnetData ? this.onetCompanyGuid : this.company.guid,
      roleNames: this.selectedRoles,
      functionNames: this.selectedFuncs,
      sourceType: isOnetData ? constant.public : constant.client,
      ...(!isOnetData && {
        fileGuid: this.file.metadata.fileGuid
      })
    };
    const response = await this.$planAdmin.getFow(payload);

    this.roleData = response.map((roleInfo) => ({
      ...roleInfo,
      isEditBtnClick: false,
      tasks: roleInfo.taskCategorization.map((task) => ({
        ...task,
        isUpdated: false
      }))
    }));
  },

  async unMounted() {},

  deactivated() {},

  methods: {
    translate,
    getColorsOnValues(val) {
      return {
        background: val,
        height: '100%',
        width: '98%'
      };
    },

    editMaturityLevel(roleInfo) {
      roleInfo.isEditBtnClick = !roleInfo.isEditBtnClick;
      roleInfo.tasks.map((task) => ({
        ...task,
        isUpdated: false
      }));
      if (!roleInfo.isEditBtnClick) {
        this.updateAutomation(roleInfo);
      }
    },

    btnName(roleInfo) {
      return roleInfo.isEditBtnClick ? translate('hr_tools.fow.edit_automation_btn_submit') : translate('hr_tools.fow.edit_automation_btn');
    },

    updateVal(progressInfo) {
      const val = progressInfo.val;
      const item = progressInfo.task;
      const roleGuid = progressInfo.roleGuid;
      this.roleData.forEach((role) => {
        if (role.guid === roleGuid) {
          role.tasks.forEach((task) => {
            if (task.taskGuid === item.taskGuid) {
              // Update specific properties based on value
              task.isUpdated = !(task.automationValue == val);
              task.automationValue = val;
            }
          });
        }
      });
      console.log(this.roleData, 'roleData');
    },

    async getAutomation(task) {
      return (
        Object.keys(this.automationLevels).find((key) => {
          const automationLevel = this.automationLevels[key];
          return task[automationLevel.potential]?.toLowerCase() === 'yes' ? automationLevel.potential : '';
        }) || ''
      );
    },

    async updateAutomation(roleInfo) {
      const isOnetData = this.selectedType === 'onet';
      const payload = {
        companyGuid: isOnetData ? this.onetCompanyGuid : this.company.guid,
        roleName: roleInfo.roleName,
        functionName: roleInfo.functionName,
        sourceType: isOnetData ? constant.public : constant.client,
        ...(!isOnetData && {
          fileGuid: this.file.metadata.fileGuid
        }),
        taskCategorizations: roleInfo.tasks.reduce((acc, task) => {
          if (task.isUpdated) {
            acc.push({
              taskGuid: task.taskGuid,
              automationValue: task.automationValue
            });
          }
          return acc;
        }, [])
      };
      const response = await this.$planAdmin.updateFow(payload);
      if (response) {
        roleInfo.isEditBtnClick = false;
      }
    },

    getEditValue(val) {
      console.log(val);
    },

    handleProgressInput(value) {
      // Triggered during dragging and immediate updates
      console.log('Progress input:', value);
    },
    handleProgressChange(progressInfo) {
      // Triggered when interaction ends (click or drag complete)
      // console.log('Progress changed:', value);
      this.updateVal(progressInfo);
      // Update the progress value in your data
    }
  },

  metaInfo() {
    return {
      title: translate('pageTitles.hr_tools')
    };
  }
};
</script>

<style lang="scss">
.header-font {
  font-family: 'Helvetica Neue';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.4px;
  color: #474747;
}
.body-font {
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  color: #474747;
}

.curr-automation-maturity {
  .v-table__wrapper {
    table {
      thead {
        tr {
          th:last-child {
            padding: 0 14px !important;
          }
          .header-2-sub-header {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            flex-direction: row-reverse; // showing in reverse order so index of element remains same
            gap: 4px;
            border-bottom: 16px solid;
            padding: unset !important;
            border-image: linear-gradient(to right, #141414, #003dab, #4dacf1, #b3dcf9) 1;
            .sub-header-th {
              display: flex;
              justify-content: space-between;
              flex-direction: row-reverse;
              .sub-header-th-1,
              .sub-header-th-2 {
                display: none !important;
              }
              .sub-header-th-0 {
                text-align: right !important;
              }
            }
            .sub-header-span {
              @extend .body-font !optional;
              font-weight: 700;
              font-size: 14px;
            }
            .sub-header-p {
              @extend .body-font !optional;
              font-size: 14px;
              white-space: pre-line;
            }
            th {
              text-align: left;
              padding: unset !important;
              width: 100%;
            }
          }

          .header-1-sub-header {
            display: flex;
            margin-top: 2rem;
            :first-child {
              padding: 12px 12px 12px 0 !important;
            }
            th {
              width: 50%;
              text-align: left;
              padding: 12px !important;
            }
            .sub-header-span-0,
            .sub-header-span-1 {
              @extend .body-font !optional;
              font-weight: 700;
            }
            .sub-header-span-1 {
              padding-left: 12px;
            }
          }

          th {
            height: fit-content;
            border-bottom: none !important;
            &:first-child {
              .custom-header-1 {
                @extend .header-font !optional;
                line-height: 24px;
              }
              .edit-btn,
              .submit-btn {
                margin-top: 1rem;
                border-color: #415385;
                height: 2rem;
                padding: 0 1rem !important;
                .v-btn__content {
                  @extend .body-font !optional;
                  font-weight: 500;
                  color: #415385;
                }
              }
              .submit-btn {
                background-color: #415385;
                .v-btn__content {
                  color: #ffffff;
                }
              }
            }
            &:last-child {
              .custom-header-2 {
                @extend .body-font !optional;
                font-weight: 500;
              }
            }
          }
        }
      }
      tbody {
        tr {
          .task-name {
            max-width: 10rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          :nth-child(n + 3) {
            padding: 0;
          }
          td {
            @extend .body-font !optional;
            padding: 12px;
            .role-name {
              @extend .body-font !optional;
            }
            .no-data {
              .v-selection-control {
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .v-input__details {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.header-font {
  font-family: 'Helvetica Neue';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.4px;
}
.body-font {
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
}

.fow-screen-3 {
  h3 {
    color: #474747;
    @extend .header-font !optional;
  }

  .current-maturity-measures {
    margin: 1.5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    p {
      white-space: pre-line;
      @extend .body-font !optional;
      font-size: 14px;
    }
    h4 {
      @extend .body-font !optional;
      font-size: 14px;
      font-weight: 700;
    }
  }

  .curr-automation-maturity {
    margin-top: 1.5rem;
    border-radius: 8px;
    border: 1px solid #d1d1d1;
    padding: 12px;

    // .progress-container {
    //   position: relative;
    //   padding: 15px 0;
    // }

    // .progress-with-circle {
    //   cursor: pointer;
    // }

    // .circle-indicator {
    //   position: absolute;
    //   width: 16px;
    //   height: 16px;
    //   background-color: #f48fb1; /* matches pink-lighten-1 */
    //   border-radius: 50%;
    //   top: 50%;
    //   transform: translate(-50%, -50%);
    //   // border: 3px solid #f48fb1;
    //   box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    //   pointer-events: none;
    //   z-index: 1;
    //   cursor: pointer;
    // }

    // :deep(.v-progress-linear) {
    //   cursor: pointer;
    //   overflow: visible;
    // }
  }
}
</style>
