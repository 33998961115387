import axios from 'axios';
import EventBus from '@/plugins/eventbus';
import { getMetaData } from '../utils/helper';
import { RecommendTrackContainer } from '../model/recommend-track';

/******
 * Using Interceptors for common headers on every request
 * for AppSpider issues fixing
 */
axios.interceptors.request.use((config) => {
  config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  config.headers['X-APP-NAME'] = 'Plan';
  if (config.url.includes('cloudEnv') || config.url.includes('freefolk') || config.url.includes('autographer')) return config;
  config.headers['Cache-Control'] = 'no-cache';
  config.headers['Pragma'] = 'no-cache';
  config.headers['Expires'] = '0';
  config.headers['responseEncoding'] = 'utf8';
  config.headers['withCredentials'] = true;
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //console.clear()
    if (error.response && error.response.status === 401) {
      if (error.response.request && error.response.request.responseURL) {
        const ignoreAPIs = ['/users/me', '/api/renewToken', '/reports/get-token', '/reports/renew-token'];
        if (!ignoreAPIs.some((v) => error.response.request.responseURL.includes(v))) {
          EventBus.$emit('log-out');
          window.location.href = '/';
        }
      }
    }
    return Promise.reject(error);
  }
);
export default {
  async basicPlanGetJSONRequest(url) {
    const { jsonHeaders } = await getMetaData();
    const response = await axios.get(url, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicPlanMultiPartPostRequest(url, payload) {
    const { formHeaders } = await getMetaData();
    const response = await axios.post(url, payload, { headers: formHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicPlanGetRequest(url) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.get(url, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicPlanPostRequestWithPayload(url, payload) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.post(url, { ...payload }, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicPlanGetRequestWithBlob(url) {
    const { tokenHeader } = await getMetaData();
    const response = await axios({ url: url, method: 'GET', responseType: 'blob', headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return new Blob([response.data]);
  },
  async basicPlanPostJSONRequestWithPayload(url, payload) {
    const { jsonHeaders } = await getMetaData();
    const response = await axios.post(url, payload, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicPlanPostJSONRequest(url, payload) {
    const { jsonHeaders } = await getMetaData();
    const response = await axios.post(url, payload, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    const resultSet = new RecommendTrackContainer(response.data);
    return resultSet.getDataObject();
  },
  async basicPlanPutRequest(url, payload) {
    const { jsonHeaders } = await getMetaData();
    const response = await axios.put(url, payload, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicPlanDeleteRequest(url) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.delete(url, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicPlanDeleteRequestWithReqBody(url, payload) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.delete(url, { data: payload, headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async getUserTenantInfo(url) {
    const { jsonHeaders } = await getMetaData();
    const response = await axios.get(url, { headers: jsonHeaders }, { withCredentials: true }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async hasOrganizationStructure(url) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.head(url, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.status === 200;
  },
  async submitHelpTicket(url, payload) {
    const { formHeaders } = await getMetaData();
    const response = await axios.post(url, payload, { headers: formHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async removeSelectedPeople(url, payload) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.delete(url, { data: { ...payload }, headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async userBroadcast(url) {
    const { jsonHeaders } = await getMetaData();
    const response = await axios.post(url, {}, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async getSkillSummaryReport(url) {
    const { tokenHeader } = await getMetaData();
    const response = await axios({ url: url, method: 'GET', responseType: 'blob', headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return {
      isEmailed: response.status === 302,
      data: response.status === 200 ? new Blob([response.data]) : null
    };
  },
  async downloadBlobTemplate(url) {
    const { tokenHeader } = await getMetaData();
    const response = await axios({ url: url, method: 'GET', responseType: 'blob', headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return new Blob([response.data]);
  }
};
