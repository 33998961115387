<template>
  <div>
    <div class="create-announcements-container" v-if="tableDataReady">
      <div v-if="isShowOptions" class="a-overlay" @click="onCloseActions()"></div>
      <div v-if="liveAnnouncement.length > 0">
        <div class="announcement-header">
          <h2>{{ $t('settings.annoucements.currentlyLive') }}</h2>
        </div>
        <v-data-table-virtual :headers="headers" :items="liveAnnouncement" class="t-cursor-pointer t-mb-8">
          <template v-slot:[`item.startDate`]="{ item }">
            {{ localizedDate12Hour(moment, item.startDate) }}
          </template>
          <template v-slot:[`item.endDate`]="{ item }">
            {{ localizedDate12Hour(moment, item.endDate) }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <button class="action-container" @click="getLiveOption(item)"><svg-icon icon-class="action-dots" /></button>
            <div class="option-container" v-if="item.isShowOptions && showLiveOption">
              <li v-for="(option, index) in actionOptions" :key="index" @click="onClickOption(option, item)">{{ option }}</li>
            </div>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span class="t-flex t-items-center t-gap-2">
              <svg-icon :icon-class="iconTypes[item.status]" />
              <span class="t-capitalize">{{ item.status }}</span>
            </span>
          </template>
        </v-data-table-virtual>
      </div>

      <div class="announcement-header">
        <h2>{{ $t('settings.annoucements.announcementList') }}</h2>
        <button @click="createAnnouncement" class="create-btn">{{ $t('settings.annoucements.createAnnouncement') }}</button>
      </div>
      <v-data-table :headers="headers" :items="announcementList" :items-per-page="pageSize" class="t-cursor-pointer">
        <template v-slot:[`item.action`]="{ item }">
          <button class="action-container" @click="getOptions(item)"><svg-icon icon-class="action-dots" /></button>
          <div class="option-container" v-if="item.isShowOptions && showOption">
            <li v-for="(option, index) in actionOptions" :key="index" @click="onClickOption(option, item)">{{ option }}</li>
          </div>
        </template>

        <template v-slot:[`item.startDate`]="{ item }">
          {{ localizedDate12Hour(moment, item.startDate) }}
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          {{ localizedDate12Hour(moment, item.endDate) }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <span class="t-flex t-items-center t-gap-2">
            <svg-icon :icon-class="iconTypes[item.status]" />
            <span class="t-capitalize">{{ item.status }}</span>
          </span>
        </template>
        <template v-slot:bottom>
          <div class="announcement-pagination-container">
            <paginate
              v-if="totalResults > 8"
              v-model="pageNum"
              :page-count="totalNumOfPages"
              :page-range="pageSize"
              :margin-pages="8"
              :click-handler="clickCallback"
              :container-class="'pagination'"
              :page-class="'pagination-item'"
              :page-link-class="'pagination-item-link'"
              :prev-text="'&lsaquo;'"
              :prev-class="'pagination-nav pagination-tooltip'"
              :prev-link-class="'pagination-nav-link'"
              :next-text="'&rsaquo;'"
              :next-class="'pagination-nav pagination-tooltip'"
              :next-link-class="'pagination-nav-link'"
              :hide-prev-next="true"
            />
          </div>
        </template>
      </v-data-table>
    </div>
    <TrackSaveDialog :trackSaveDialog="deleteConfirmation" :trackPopupDetails="deleteConfirmationMessage" @cancel-navigation="cancelDelete()" @saved-track="deleteAnnouncementItem()" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import { localizedDate12Hour } from '@/utils';
import { translate } from '@/plugins/i18n.js';
import Paginate from 'vuejs-paginate-next';
import TrackSaveDialog from '../../catalog/components/TrackSaveDialog.vue';
const initialState = () => ({
  tableDataReady: false,
  deleteConfirmation: false,
  deleteConfirmationMessage: {
    title: translate('settings.createAnnouncement.deleteMessageTitle'),
    message: translate('settings.createAnnouncement.deleteConfirmationMessage'),
    cancel: 'Cancel',
    ok: 'Yes'
  },
  announcementInFocus: null,
  iconTypes: {
    pending: 'status-icon',
    live: 'green-checkmark',
    expired: 'disable-icon',
    disabled: 'disable-icon'
  },
  totalNumOfPages: 0,
  pageSize: 8,
  currentPage: 1,
  totalResults: 0,
  announcementList: [],
  liveAnnouncement: [],
  announcementOrgList: [],
  allAnnouncementList: [],
  sortBy: [],
  loading: false,
  pageNum: 1,
  isShowOptions: false,
  announcementListProxy: [],
  actionOptions: [],
  showLiveOption: false,
  showOption: false
});

export default {
  name: 'Announcements',
  emits: ['announcementData', 'createAnnouncement'],
  components: {
    paginate: Paginate,
    TrackSaveDialog
  },

  data() {
    return initialState();
  },
  watch: {
    '$i18n.locale'() {
      this.setHeaders();
    }
  },
  computed: {
    ...mapGetters([])
  },
  destroyed() {
    this.announcementList = [];
    this.liveAnnouncement = [];
    this.announcementOrgList = [];
    this.allAnnouncementList = [];
  },
  async mounted() {
    Object.assign(this.$data, initialState());
    this.setHeaders();
    await this.getPaginatedAnnouncementList();
    this.EventBus.on('announcement-created', this.getPaginatedAnnouncementList);
  },

  methods: {
    localizedDate12Hour,
    async getOptions(item) {
      this.showLiveOption = false;
      this.showOption = true;
      this.isShowOptions = !this.isShowOptions;
      item.isShowOptions = !item.isShowOptions;
      this.actionOptions = [];
      switch (item.status) {
        case 'pending':
          this.actionOptions.push(translate('settings.annoucements.dataTable.options[1]') /* , translate('settings.annoucements.dataTable.options[3]') */, translate('settings.annoucements.dataTable.options[4]'));
          break;
        case 'live':
          this.actionOptions.push(translate('settings.annoucements.dataTable.options[2]') /* , translate('settings.annoucements.dataTable.options[3]') */);
          break;
        case 'expired':
          this.actionOptions.push(translate('settings.annoucements.dataTable.options[4]') /* , translate('settings.annoucements.dataTable.options[3]') */);
          break;
        case 'disabled':
          this.actionOptions.push(translate('settings.annoucements.dataTable.options[4]') /* , translate('settings.annoucements.dataTable.options[3]') */);
          break;
        default:
          break;
      }
    },
    async getLiveOption(item) {
      this.showLiveOption = true;
      this.showOption = false;
      this.isShowOptions = !this.isShowOptions;
      item.isShowOptions = !item.isShowOptions;
      this.actionOptions = [];
      if (item.status === 'live') {
        this.actionOptions.push(translate('settings.annoucements.dataTable.options[2]') /* , translate('settings.annoucements.dataTable.options[3]') */);
      }
    },
    onCloseActions() {
      this.isShowOptions = !this.isShowOptions;
      this.announcementList.forEach((item) => {
        item.isShowOptions = false;
      });
      this.liveAnnouncement.forEach((key) => {
        key.isShowOptions = false;
      });
    },
    async onClickOption(option, item) {
      const optionType = [translate('settings.annoucements.dataTable.options[1]') /* , translate('settings.annoucements.dataTable.options[3]') */];
      this.announcementInFocus = {
        ...item
      };
      this.isShowOptions = !this.isShowOptions;
      this.announcementList.forEach((item) => {
        item.isShowOptions = false;
      });
      this.liveAnnouncement.forEach((item) => {
        item.isShowOptions = false;
      });
      if (optionType.includes(option)) {
        this.createAnnouncement(this.announcementInFocus, option);
      } else {
        switch (option) {
          case 'Disable':
            await this.disableAnnouncemetItem(this.announcementInFocus);
            break;
          case 'Delete':
            this.deleteItemConfirmation();
            break;
          default:
            break;
        }
      }
    },
    async deleteItemConfirmation() {
      if (this.announcementInFocus && this.announcementList.length > 0) {
        this.deleteConfirmation = true;
      }
    },
    cancelDelete() {
      this.deleteConfirmation = false;
    },
    async deleteAnnouncementItem() {
      this.deleteConfirmation = false;
      try {
        this.$planAdmin.deleteAnnouncementDetails(this.announcementInFocus?.guid).then((res) => {
          if (res?.error) {
            this.showToast(translate('createDetails.failureSaveDraftToastMessage'), 'error', 3000, false);
          } else if (res) {
            this.getPaginatedAnnouncementList();
            this.showToast(translate('settings.createAnnouncement.deletionSucces'), 'success', 3000, false);
          } else {
            this.showToast(translate('createDetails.failureSaveDraftToastMessage'), 'error', 3000, false);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    async disableAnnouncemetItem(itemVal) {
      try {
        const announcementItem = this.announcementOrgList.filter((item) => item.id === itemVal.id);
        const payload = {
          guid: announcementItem[0].guid,
          clientName: announcementItem[0].clientName,
          title: announcementItem[0].title,
          description: announcementItem[0].description,
          isDisabled: true,
          startDate: moment.utc(announcementItem[0].startDate).valueOf(),
          endDate: moment.utc(announcementItem[0].endDate).valueOf(),
          isPublishNow: announcementItem[0].isPublishNow || false
        };
        this.$planAdmin.updateAnnouncementDetails(payload).then((res) => {
          if (res) this.getPaginatedAnnouncementList();
        });
      } catch (error) {
        console.log(error);
      }
    },
    getDateFormat(utcDateTime) {
      const milliseconds = new Date(utcDateTime).getTime();
      const dateValue = moment(milliseconds).format('DD MMM YYYY');
      return dateValue;
    },
    createAnnouncement(item, option) {
      const datesBetween = [];
      if (this.allAnnouncementList.length > 0) {
        this.allAnnouncementList.map((value) => {
          const startDates = this.getDateFormat(value.startDate);
          const endDates = this.getDateFormat(value.endDate);
          let currentDate = moment(startDates);
          while (currentDate.isSameOrBefore(endDates, 'day')) {
            datesBetween.push(currentDate.format('DD MMM YYYY'));
            currentDate.add(1, 'day');
          }
        });
        this.$emit('createAnnouncement', item, datesBetween, option);
      } else {
        this.$emit('createAnnouncement', item);
      }
    },
    async getPaginatedAnnouncementList() {
      this.loading = true;
      try {
        const payload = {
          pageNum: this.pageNum
        };
        this.$planAdmin.getPaginatedAnnouncementsList(payload).then(async (res) => {
          if (res.totalContent > 0) {
            this.$emit('announcementData', res.totalContent);
            this.totalNumOfPages = res.totalPages;
            this.totalResults = res.totalContent;
            if (this.totalNumOfPages < this.pageNum && this.totalNumOfPages > 0) {
              this.clickCallback(res.totalPages);
            } else {
              this.announcementOrgList = [...res.liveAnnouncement, ...res.content];
              this.announcementList = res.content?.map((data) => this.transformData(data));
              this.liveAnnouncement = res.liveAnnouncement?.map((data) => this.transformData(data));
              this.tableDataReady = true;
              this.announcementListProxy = this.announcementList;
              this.loading = false;
              this.$nextTick(async function () {
                await this.setupPagination(true);
              });
              await this.getAllAnnouncementData();
            }
          } else {
            this.tableDataReady = false;
            this.$emit('createAnnouncement');
          }
        });
      } catch (error) {
        this.$emit('announcementData', []);
        console.log(error);
      }
    },
    async getAllAnnouncementData() {
      try {
        const payload = {
          pageNum: this.pageNum,
          isPaginationDisabled: true
        };
        this.$planAdmin.getAllAnnouncementsList(payload).then((res) => {
          if (res.content.length > 0) {
            this.allAnnouncementList = res.content;
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    transformData(data) {
      let Obj = {};
      Obj.title = data.title || '';
      Obj.status = data.status ? data.status.toLowerCase() : 'live';
      Obj.endDate = this.getTimeFormat(data.endDate) || '';
      Obj.startDate = this.getTimeFormat(data.startDate) || '';
      Obj.guid = data.guid;
      Obj.tenantGuid = data.tenantGuid;
      Obj.id = data.id;
      Obj.isPublishNow = data.isPublishNow;
      return Obj;
    },

    getTimeFormat(utcDateTime) {
      /* const milliseconds = new Date(utcDateTime).getTime();
      const dateTime = moment(milliseconds).format('D MMM YYYY - h:mm A'); */
      const dateTime = new Date(utcDateTime);
      return dateTime;
    },
    async setupPagination() {
      if (this.announcementList) {
        const elements = document.getElementsByClassName('pagination');

        if (elements.length && elements[0].childNodes) {
          elements[0].childNodes.forEach((element) => {
            if (element.childNodes.length && element.className.includes('pagination')) {
              const pageNumber = element.childNodes[0].innerHTML;

              if (element.className.includes('pagination-nav')) {
                if (pageNumber === '‹') {
                  element.setAttribute('data-tooltip-content', `Go to previous page`);
                  element.setAttribute('aria-label', `Go to previous page`);
                } else {
                  element.setAttribute('data-tooltip-content', `Go to next page`);
                  element.setAttribute('aria-label', `Go to next page`);
                }
              }
              if (element.className.includes('pagination-item active')) {
                element.setAttribute('aria-current', `page`);
              } else {
                element.setAttribute('aria-current', `false`);
                if (element.className.includes('pagination-item') && !isNaN(pageNumber)) {
                  element.setAttribute('aria-label', `page ${pageNumber}`);
                }
              }
            }
          });
        }
      }
    },
    setHeaders() {
      this.$data.headers = [
        { title: '', width: '0px', sortable: false, height: '50px' },
        { title: translate('settings.annoucements.dataTable.title'), key: 'title', width: '30%', align: 'start' },
        { title: translate('settings.annoucements.dataTable.startDate'), key: 'startDate', align: 'start' },
        {
          title: translate('settings.annoucements.dataTable.endDate'),
          key: 'endDate',
          align: 'start',
          height: '50px'
        },
        { title: translate('settings.annoucements.dataTable.status'), key: 'status', align: 'start' },
        { title: translate('settings.annoucements.dataTable.action'), key: 'action', align: 'start', sortable: false },
        { title: '', width: '0px', sortable: false, height: '50px' }
      ];
    },
    async clickCallback(pageNum) {
      this.pageNum = pageNum;
      await this.getPaginatedAnnouncementList();
    },
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };
      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });

      this.$toasted.show(msg, options);
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.announcements')
    };
  }
};
</script>

<style lang="scss">
.create-announcements-container {
  .a-overlay {
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }
  padding: 3rem;
  background-color: #ffffff;
  margin: 3rem 0 0 0;
  border-radius: 24px;
  .announcement-header {
    display: flex;
    align-items: center;
    margin: 0 0 2rem 0;
    justify-content: space-between;
    h2 {
      @include header-font-2;
      color: #2d2d2d;
    }
    .create-btn {
      background: #ffb92e;
      padding: 1rem 2rem;
      border-radius: 200px;
      @include body-font-button-text;
      color: #000;
    }
  }

  .v-data-table {
    thead {
      background-color: #f5f5f5;
      tr {
        background-color: #f5f5f5;
        border: none !important;
        th {
          height: 50px;
        }
      }
      span {
        color: #000;
        @include body-font-bold-0-75;
      }
    }
  }

  .v-table--density-default > .v-table__wrapper {
    border-radius: 0 !important;
    tbody {
      tr {
        td {
          border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
          height: 56px;
          @include body-font-1;
          &:nth-child(2) {
            padding-left: 0;
          }
          &:nth-child(7) {
            padding-right: 0;
          }
          .action-container {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            border-radius: 1.5rem;
            border: 1px solid #d6d6d6;
            justify-content: center;
          }
          .option-container {
            position: absolute;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
            border-radius: 0.5rem;
            padding: 0.5rem;
            background: #fff;
            width: 224px;
            z-index: 1;
            li {
              list-style-type: none;
              padding: 0.5rem;
              @include body-font-1;
              &:hover {
                background-color: #f5f5f5;
                cursor: pointer;
              }
            }
            @media screen and (max-width: 1264px) {
              right: 10.5%;
            }
          }
        }
      }
    }
  }

  .v-table--density-default > .v-table__wrapper > table > tbody > tr > th,
  .v-table--density-default > .v-table__wrapper > table > tfoot > tr > th,
  .v-table--density-default > .v-table__wrapper > table > thead > tr > th {
    height: 50px;
    border-bottom: none !important;
    &:nth-child(2) {
      padding-left: 0;
    }
    &:nth-child(7) {
      padding-right: 0;
    }
  }

  v-data-table-footer {
    display: none;
  }

  .announcement-pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    height: 2.5rem;
    margin-top: 2rem;
    z-index: 0;
    .pagination {
      display: flex;
      justify-content: center;
      list-style-type: none;
      padding: 0rem;
      width: 50%;
    }

    .pagination-item {
      margin: 0 0.313rem;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    li.pagination-nav {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .pagination-item-link {
      color: var(--ion-color-post-gray);
      font-size: 0.875rem !important;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include body-font-bold-0-875;
      color: #5c5c5c;
    }

    .pagination-nav-link {
      position: relative;
      color: #5c5c5c;
      font-size: 3rem !important;
      vertical-align: middle;
      font-family: 'Futura PT Book';
      width: inherit;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.9375rem;
    }

    li.disabled {
      border: none;
      cursor: default;
      .pagination-item-link {
        cursor: default;
      }
    }

    li.active {
      border: 0.125rem solid #1dd1b0;
      border-radius: 0.25rem;
      background: white;
      .pagination-item-link {
        color: #000000;
      }
    }

    li:hover {
      background: #e0e1e1;
    }

    li .tooltiptext {
      visibility: visible;
      width: max-content;
      height: 1.875rem;
      background-color: #efefef;
      color: #000000de;
      text-align: center;
      padding: 0.4375rem 0.625rem;
      border-radius: 0.25rem;
      position: absolute;
      z-index: 1;
      bottom: 90%;
      left: 50%;
      margin-left: -1.25rem;
      font-size: 0.75rem;
      font-family: 'Futura PT Demi';
    }

    .li:hover .tooltiptext {
      visibility: visible;
    }

    .pagination-tooltip {
      position: relative;
    }

    .pagination-tooltip::after {
      content: attr(data-tooltip-content);
      padding: 0.5rem;
      border-radius: 0.25rem;
      position: absolute;
      color: white;
      bottom: 135%;
      right: 50%;
      left: 50%;
      width: max-content;
      opacity: 0;
      font-size: 0.8rem;
      visibility: visible;
      transform: translate(-50%, 1.125rem) scale(0.8);
      transition: visibility, opacity, transform 250ms;
      text-align: left;
      font: normal normal normal 0.75rem/1rem Arial;
      letter-spacing: 0rem;
      color: #000000de;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0rem 0.125rem 0.375rem #0000001f;
      border-radius: 0.25rem;
    }

    .pagination-tooltip:hover::after {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}
</style>
