const getters = {
  planLocation: (state) => state.plan.location,
  planCreated: (state) => state.plan.isCreated,
  token: (state) => state.user.token,
  name: (state) => state.user.name,
  username: (state) => state.user.username,
  user_guid: (state) => state.user.user_guid,
  tenant_type: (state) => state.user.tenant_type,
  client_category: (state) => state.user.client_category,
  tenant_settings: (state) => state.user.tenant_settings,
  tenantAccessTags: (state) => state.user.accessTags,
  show_share: (state) => state.user.show_share,
  show_idea: (state) => state.user.show_idea,
  company: (state) => state.user.company,
  logo: (state) => state.user.logo,
  navigationBar: (state) => state.user.navigationBar,
  gcs_token: (state) => state.user.gcs_token,
  is_dashboard_download_on: (state) => state.user.is_dashboard_download_on,
  showModal: (state) => state.user.showModal,
  aboutTimeout: (state) => state.user.aboutTimeout,
  trustedDeviceToken: (state) => state.user.trustedDeviceToken,
  allFeatureFlags: (stage) => stage.flag.features,
  cloud_env: (state) => state.environment.cloud_env,
  config: (state) => state.environment.config,
  enabledFeatureFlags: (state) => state.flag.enabled,
  orgUploaded: (state) => state.plan.orgUploaded,
  csvUploaded: (state) => state.plan.csvUploaded,
  searchedRole: (state) => state.plan.searchedRole,
  selectedFunc: (state) => state.plan.selectedFunc,
  selectedDept: (state) => state.plan.selectedDept,
  clearSearchResult: (state) => state.plan.clearSearchResult,
  selectedProfile: (state) => state.plan.SelectedProfile,
  resetProfile: (state) => state.plan.resetProfile,
  workForcePeopleUpdate: (state) => state.plan.workForcePeopleUpdate,
  getUploadStatus: (state) => state.plan.uploadSuccessful,
  isIdle: (state) => state.idleVue.isIdle
};
export default getters;
