<template>
  <div class="settings">
    <h2>{{ $t('pageTitles.settings') }}</h2>
    <div class="tab-container">
      <router-link data-el="app-plan" class-active="active" :class="selectedIndex === index ? 'active' : ''" v-for="(tab, index) in tabs" :key="index" :to="tab.path" @click.once="updateTabIndex(index)">
        {{ tab.name }}
      </router-link>
    </div>
    <div class="last-modified">{{ $t('settings.lastModified') }}: {{ lastModified.email }} on {{ lastModified.date }}</div>
    <router-view>
      <Announcements v-if="selectedIndex === 0" @announcementData="getAnnouncementList" @createAnnouncement="createAnnouncement" />
      <CreateEditAnnouncement v-if="showCreateForm && selectedIndex === 0" ref="createEditAnnouncement" :dateRange="dateRange" :announcementInFocus="announcementItem" :optionSelected="optionSelected" @announcementData="getAnnouncementList" />
      <CompanyLogoUpload v-if="selectedIndex === 1" />
    </router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import { translate } from '@/plugins/i18n.js';
import Announcements from './components/Announcements.vue';
import CreateEditAnnouncement from './components/CreateEditAnnouncement.vue';
import CompanyLogoUpload from './components/CompanyLogoUpload.vue';

export default {
  name: 'Settings',
  components: { Announcements, CreateEditAnnouncement, CompanyLogoUpload },
  computed: {
    ...mapGetters([])
  },
  beforeRouteLeave(to, from, next) {
    if (this.showCreateForm) {
      this.toPath = to.path;
      const flag = this.$refs.createEditAnnouncement.prepareToExit(to, from, next);
      flag ? next(false) : next(), this.EventBus.off('announcement-created');
    } else {
      this.EventBus.off('announcement-created');
      next();
    }
  },
  data() {
    return {
      tabs: [
        {
          name: translate('settings.annoucements.title'),
          path: '/settings/announcements'
        },
        {
          name: translate('settings.company_logo.title'),
          path: '/settings/company-logo'
        }
      ],
      announcementItem: {},
      dateRange: [],
      showCreateForm: false,
      AnnouncementList: 0,
      lastModified: {
        email: '',
        date: this.moment().format('DD MMM YYYY')
      },
      selectedIndex: 0
    };
  },
  async mounted() {
    this.lastModified.email = await localforage.getItem('my_username');
    this.selectedIndex = this.$route.path.includes('/settings/announcements') ? 0 : this.$route.path.includes('/settings/company-logo') ? 1 : 0;
  },
  methods: {
    getAnnouncementList(totalCount) {
      //if(!totalCount)this.$emit('announcementData');
      this.AnnouncementList = this.AnnouncementList ? this.AnnouncementList : totalCount;
      this.showCreateForm = this.AnnouncementList > 0 ? false : true;
    },
    createAnnouncement(item, dateRange, option) {
      const optionType = [translate('settings.annoucements.dataTable.options[1]'), translate('settings.annoucements.dataTable.options[3]')];
      if (dateRange && dateRange.length > 0) this.dateRange = dateRange;
      if (item) this.announcementItem = item.guid ? item : {};
      if (optionType.includes(option)) this.optionSelected = option;
      //this.EventBus.emit('createAnnouncement',item, dateRange, option);
      this.showCreateForm = true;
    },
    updateTabIndex(index) {
      this.selectedIndex = index;
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.settings')
    };
  }
};
</script>

<style lang="scss" scoped>
.settings {
  max-width: 1700px;
  margin: 3rem 110px;
  @media screen and (max-width: 1264px) {
    margin: 2rem;
  }
  h2 {
    @include header-font-3;
    color: #1f3370;
    margin: 0 0 3rem 0;
  }
  .tab-container {
    border-bottom: 2px solid #adadad;
    display: flex;
    gap: 2rem;
    margin: 0 0 3rem 0;
    a {
      text-decoration: unset;
      padding: 0 0 1rem 0;
      @include body-font-bold-1;
      color: #2d2d2d;
    }
    .active {
      border-bottom: 0.3125rem solid $color-data-1;
    }
  }
  .last-modified {
    @include body-font-1;
    color: #2d2d2d;
    text-align: right;
  }
}
</style>
