import moment from 'moment';
import { translate } from '../plugins/i18n.js';
export class GetTrackDueDates {
  trackItems = [];
  constructor(data) {
    if (data.length)
      data.map((item) => {
        this.trackItems.push(this.transformData(item));
      });
  }
  getDataObject() {
    return this.trackItems;
  }
  transformData(data) {
    const Obj = {};
    /*****
     * we need to get the format name credential from backend, once the api has that,
     * we will remove the following to give formatType Credential by default
     */
    Obj.id = data.id;
    Obj.formatType = data.format && data.format.name ? data.format.name : '';
    Obj.provider = data.provider && data.provider.name ? data.provider.name : '';
    Obj.title = data.title ? data.title : '';
    Obj.synopsis = data.synopsis ? data.synopsis : '';
    Obj.duration = data.duration;
    Obj.durationType = data.duration ? this.getDurationType(data.duration) : '';
    Obj.durationMinutes = data.durationMinutes ? data.durationMinutes : 0;
    Obj.publishedDate = data.publishedAt ? this.formatDate(data.publishedAt) : '';
    Obj.skills = data.emsiSkills ? data.emsiSkills && data.emsiSkills.join(', ') : '';
    Obj.accreditation = data.accreditation ? data.accreditation : [];
    Obj.fieldOfStudy = data.fieldOfStudy ? data.fieldOfStudy.join(', ') : [];
    Obj.subCategory = data.subCategory ? data.subCategory : '';
    Obj.vdiRequired = data.vdiEnabled !== null ? data.vdiEnabled : '';
    Obj.skillBuilder = data.content && data.content.skillBuilders ? data.content.skillBuilders : '';
    Obj.capstone = data.content && data.content.capstone ? data.content.capstone : '';
    Obj.levels = data.levels ? data.levels[0] : '';
    Obj.dueDate = data.dueDate ? data.dueDate : '';
    Obj.hasDueDate = data.hasDueDate ? data.hasDueDate : false;
    return Obj;
  }

  getDurationType(duration) {
    if (duration === 3600) {
      return translate('common.time.hr').toUpperCase();
    } else if (duration > 3600) {
      return translate('common.time.hr').toUpperCase();
    } else if (duration > 59 && duration < 3600) {
      return translate('common.time.min').toUpperCase();
    } else {
      return '';
    }
  }

  formatDate(date) {
    if (!date) {
      return '';
    } else {
      const formattedDate = moment(new Date(date)).format('DD MMM YYYY');
      return formattedDate !== 'Invalid date' ? formattedDate : '';
    }
  }
}
