<template>
  <v-card flat>
    <v-card-title style="padding: 0.5rem 1rem">
      <v-row>
        <v-col cols="7" class="selected-org-name">
          {{ organization }}
        </v-col>
        <v-col cols="5" style="padding: 0 0 0.5rem 0.5rem">
          <div v-if="showMessage" class="message">{{ $t('workforcePlannerPeople.succesfullyInvitedSelectedLearners') }}</div>
          <v-text-field
            v-else
            data-el="searchRole"
            v-model="search"
            class="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            :label="$t('workforcePlannerPeople.search')"
            filled
            rounded
            dense
            clearable
            @keypress.enter="handleSearchInput"
            @click:clear="handleSearchClear"
            solo
            variant="solo"
          />
        </v-col>
      </v-row>
    </v-card-title>

    <!-- @toggle-select-all="selectAllToggle" -->
    <!-- :footer-props="footerProps" -->
    <!-- @update:options="fetchDataOnSort" -->

    <v-data-table-server
      class="elevation-1 workforce-planner-people"
      v-model="isSelected"
      show-select
      :headers="headers"
      :items="filterEmployees"
      :items-length="serverItemsLength"
      :items-per-page="pageSize"
      :items-per-page-options="pageSizeOption"
      @current-items="currentEmployees"
      :page="pageNum"
      :sort-by="sortBy"
      :fixed-header="true"
      :loading="loading"
      return-object
      @update:options="onDataTableChange"
      @update:sortBy="onSortBy"
      @update:page="onPageClick"
      @update:itemsPerPage="onItemsPerPage"
    >
      <template v-slot:[`no-data`]>
        <span class="no-data-available">{{ $t('common.noData10Learners') }}</span>
      </template>

      <template v-slot:[`header.data-table-select`]="{ selectAll }">
        <v-checkbox-btn @click="onSelectAll(selectAll)" />
      </template>

      <template v-slot:[`item.data-table-select`]="{ item }">
        <v-checkbox v-model="item.select" @click="onSelect(item)" :disabled="item.disabled" />
      </template>

      <template v-slot:[`item.employeeName`]="{ item }">
        <div data-el="getProfile" class="employee-name" @click="getProfile(item)" @mouseover="onCheckbox(item.employeeName, item.email)" @mouseleave="offCheckbox(item.employeeName)">
          {{ item.employeeName }}
        </div>
        <span v-if="showEmailTooltip(item.employeeName) && showTooltip" class="tooltiptext">{{ item.email }}</span>
      </template>

      <template v-slot:[`item.license`]="{ item }">
        <div style="max-width: 130px" class="employee-license">
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <span v-bind="props" class="employee-license">{{ item.license }}</span>
            </template>
            <span>{{ item.license }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item }"> {{ getLocalizedEnrollmentStatus(item.status) }} </template>

      <template v-slot:[`item.requiredSkills`]="{ item }">
        <v-chip :color="getColor(item.requiredSkills)" dark>
          {{ item.requiredSkills }}
        </v-chip>
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import { translate } from '@/plugins/i18n.js';
import { getEnrollmentStatus, getLocalizedEnrollmentStatus, encodeDecodeRole, numberWithCommas } from '@/utils';

export default {
  name: 'WorkforcePlannerPeople',
  emits: ['updateTabIndex', 'get-profile', 'invite-count'],
  components: {},
  props: {
    plan: { type: Object, required: false },
    workforcePlannerResponse: { type: Object, required: false }
  },
  data() {
    return {
      headers: [],
      employees: [],
      isSelected: [],
      currentEmployeeGuids: [],
      search: '',
      clientCategory: '',
      broadcasting: false,
      showMessage: false,
      emailTooltip: [],
      showTooltip: false,
      footerProps: { 'items-per-page-options': [25, 50, 100], 'items-per-page-text': translate('workforcePlannerPeople.paginateRowsPerPage') },
      organization: '',
      selectedAll: false,
      encodeDecodeRole,
      totalEmp: 0,
      totalPages: 1,
      pageNum: 1,
      paginationSelectOptions: [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
      pageSize: 25,
      sortBy: [],
      options: { sortBy: [], sortDesc: [], page: 1, itemsPerPage: 25 },
      serverProps: {
        page: 1,
        rowsPerPage: 25,
        sortBy: '',
        sortDesc: false
      },
      serverItemsLength: this.workforcePlannerResponse.totalUsers,
      isAllSelected: false,
      pageSizeOption: [
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' }
      ],
      loading: false,
      isClickedOnSort: false
    };
  },
  computed: {
    ...mapGetters(['client_category', 'company']),
    filterEmployees() {
      return this.employees.map((x) => ({ ...x, isSelectable: !x.disabled }));
    }
  },
  watch: {
    '$i18n.locale'() {
      this.setHeaders();
    }
  },
  async mounted() {
    await this.setup();
    this.EventBus.on('workforce-profile-updated', this.setup);
  },
  methods: {
    numberWithCommas,
    getLocalizedEnrollmentStatus,
    async handlePageSizeChange(size) {
      this.pageSize = size;
      this.pageNum = 1;
      await this.handlePageClick(this.pageNum);
    },
    fetchDataOnSort() {
      if (this.options.sortBy[0]) {
        this.sortBy = this.options.sortBy[0];
        this.sortDesc = this.options.sortDesc[0];
        this.pageNum = 1;
        this.handlePageClick(this.pageNum);
      } else if (this.options.sortDesc.length == 0) {
        this.sortBy = null;
        this.pageNum = 1;
        this.sortDesc = false;
        this.handlePageClick(this.pageNum);
      }
    },
    async handlePageClick() {
      try {
        if (!this.isClickedOnSort) {
          const { page, itemsPerPage } = this.options;
          window.scrollTo({ top: 0, behavior: 'smooth' });
          this.loading = true;
          this.pageNum = page;
          const payload = {
            pageNo: this.pageNum,
            pageSize: itemsPerPage,
            searchText: this.search,
            orgId: this.plan.orgId,
            sortDesc: this.sortDesc,
            totalUsers: this.workforcePlannerResponse.totalUsers,
            totalRoles: this.workforcePlannerResponse.totalRoles
          };
          const workforceResponse = await this.$planAdmin.getOrgEmployeeList(payload);
          this.plan.people = workforceResponse.users;
          this.employees = workforceResponse.users;
          await this.setUpWorkforcePage(workforceResponse);
          await this.setup();
          this.loading = false;
          this.isClickedOnSort = false;
        } else {
          this.loading = true;
          this.options.page = this.pageNum;
          this.loading = false;
        }
      } catch (e) {
        const err = new Error(e);
        throw new Error(err.message);
      }
    },
    async setupPagination() {
      if (this.plan.people) {
        const elements = document.getElementsByClassName('pagination');
        if (elements.length && elements[0].childNodes) {
          elements[0].childNodes.forEach((element) => {
            if (element.childNodes.length && element.className.includes('pagination')) {
              const pageNumber = element.childNodes[0].innerHTML;
              if (element.className.includes('pagination-nav')) {
                if (pageNumber === '‹') {
                  element.setAttribute('data-tooltip-content', `Go to previous page`);
                  element.setAttribute('aria-label', `Go to previous page`);
                } else {
                  element.setAttribute('data-tooltip-content', `Go to next page`);
                  element.setAttribute('aria-label', `Go to next page`);
                }
              }
              if (element.className.includes('pagination-item active')) {
                element.setAttribute('aria-current', `page`);
              } else {
                element.setAttribute('aria-current', `false`);
                if (element.className.includes('pagination-item') && !isNaN(pageNumber)) {
                  element.setAttribute('aria-label', `page ${pageNumber}`);
                }
              }
            }
          });
        }
      }
    },
    async setup() {
      try {
        this.clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
        this.setHeaders();
        this.organization = (await localforage.getItem('my_company')) || this.company;
        this.organization = this.plan.orgName ? `${this.plan.orgName} (${this.workforcePlannerResponse.totalUsers})` : `${this.organization}` + '(' + translate('workforcePlannerPeople.full') + ')';
        this.employees = this.workforcePlannerResponse.users;
        this.plan.people = this.workforcePlannerResponse.users;
        this.totalEmp = this.workforcePlannerResponse.totalUsers;
        this.totalPages = this.workforcePlannerResponse.totalPages;
        this.pageNum = this.workforcePlannerResponse.pageNumber;
        this.pageSize = this.workforcePlannerResponse.pageSize;
        this.search = this.workforcePlannerResponse.searchText;
        this.isSelected = this.workforcePlannerResponse.empSelected || [];
        this.sortBy = this.workforcePlannerResponse.sortBy;
        this.sortDesc = this.workforcePlannerResponse.sortDesc;
        const role = this.workforcePlannerResponse.totalRoles;
        this.headers[0].text = translate('workforcePlannerPeople.employeeName') + ' ' + `(${this.numberWithCommas(this.workforcePlannerResponse.totalUsers)})`;
        this.headers[1].text = translate('workforcePlannerPeople.role') + ' ' + `(${this.numberWithCommas(role)})`;
        await this.getUserProfiles();
        await this.setupPagination();
        this.serverProps = {
          sortBy: this.workforcePlannerResponse.sortBy,
          sortDesc: this.workforcePlannerResponse.sortDesc,
          page: this.workforcePlannerResponse.pageNumber,
          rowsPerPage: this.workforcePlannerResponse.pageSize
        };

        if (this?.workforcePlannerResponse?.sortBy) this.options.sortBy[0] = this?.workforcePlannerResponse?.sortBy;
        if (this?.workforcePlannerResponse?.sortDesc) this.options.sortDesc[0] = this?.workforcePlannerResponse?.sortDesc;

        this.EventBus.on('invite-sent', this.onInviteSent);
        analytics.setVarAndTrack({ c: 'WorkforcePlannerPeople', l: 'WorkforcePlannerPeople', a: 'load' });
        this.$gtag.event('WorkforcePlannerPeople', {
          event_category: 'load',
          event_label: 'WorkforcePlannerPeople',
          value: 1
        });
      } catch (error) {
        console.log(error);
      }
    },
    setHeaders() {
      if (!this.headers.length) {
        this.headers.push({
          title: translate('workforcePlannerPeople.employeeName'),
          key: 'employeeName',
          width: 175,
          align: 'start'
        });
        this.headers.push({
          title: translate('workforcePlannerPeople.role'),
          key: 'role',
          width: 120,
          align: 'start'
        });
        this.headers.push({
          title: translate('workforcePlannerPeople.license'),
          key: 'license',
          width: 75,
          align: 'start'
        });
        this.headers.push({
          title: translate('workforcePlannerPeople.status'),
          key: 'status',
          width: 100
        });
        if (this.clientCategory.toLowerCase() != 'c1') {
          this.headers.push({
            title: translate('workforcePlannerPeople.requiredSkills'),
            key: 'requiredSkills',
            width: 75,
            align: 'center'
          });
          this.headers.push({
            title: translate('workforcePlannerPeople.empSkills'),
            key: 'empSkills',
            width: 75,
            align: 'center'
          });
          this.headers.push({
            title: translate('workforcePlannerPeople.matchedSkills'),
            key: 'matchedSkills',
            width: 50,
            align: 'center'
          });
        }
      }
    },
    capitalize(str) {
      return str[0].toUpperCase() + str.slice(1);
    },
    async handleSearchClear() {
      this.search = '';
      await this.handleSearchInput('');
    },
    async handleSearchInput(search) {
      this.plan.dataReady[0] = false;
      const payload = {
        pageNo: 1,
        pageSize: this.pageSize,
        searchText: this.search,
        orgId: this.plan.orgId
      };

      const workforceResponse = await this.$planAdmin.getOrgEmployeeList(payload);
      this.plan.people = workforceResponse.users;
      this.setUpWorkforcePage(workforceResponse);
      this.plan.dataReady[0] = true;
      if (search.length > 2) {
        analytics.setVarAndTrack({ c: 'WorkforcePlannerProfile', l: search, a: 'search learner status' });
        this.$gtag.event('WorkforcePlannerProfile', {
          event_category: 'search learner status',
          event_label: search,
          value: 1
        });
      }
    },
    setUpWorkforcePage(workforceResponse) {
      this.workforcePlannerResponse.pageNumber = workforceResponse.pageNumber;
      this.workforcePlannerResponse.totalUsers = workforceResponse.totalUsers;
      this.workforcePlannerResponse.totalPages = workforceResponse.totalPages;
      this.workforcePlannerResponse.pageSize = workforceResponse.pageSize;
      this.workforcePlannerResponse.totalRoles = workforceResponse.totalRoles;
      this.workforcePlannerResponse.searchText = this.search;
      this.workforcePlannerResponse.empSelected = this.isSelected || [];
      this.workforcePlannerResponse.sortBy = workforceResponse.sortBy;
      this.workforcePlannerResponse.sortDesc = workforceResponse.sortDesc;

      if (this.isSelected && workforceResponse.users) {
        this.isSelected.forEach((selectedEmp) => {
          const index = workforceResponse.users.findIndex((employee) => employee.guid === selectedEmp.guid);
          if (index > -1) {
            workforceResponse.users[index].select = true;
          }
        });
      }
      this.workforcePlannerResponse.users = workforceResponse.users;
    },
    async getUserProfiles() {
      await this.plan.people.map(async (employee) => {
        employee.employeeName = employee.first_name + ' ' + employee.last_name;
        employee.role = encodeDecodeRole(employee.jobrolename);
        employee.license = employee.accessTags ? employee.accessTags.functionGroupTagNames.toString() : '';
        employee.status = getEnrollmentStatus(employee);
        employee.invitable = employee.license.length > 0 && employee.jobrolename !== null;
        employee.disabled = !employee.invitable || employee.email === '' || !employee.email;
        employee.requiredSkills = this.numberWithCommas(employee.jobrequiredskillcount);
        employee.empSkills = this.numberWithCommas(employee.userresumeskillcount);
        employee.matchedSkills = this.numberWithCommas(employee.userjobskillcount);
        employee.select = employee.select ? employee.select : false;
      });
    },
    getProfile(user) {
      this.$emit('get-profile', user);
    },
    onCheckbox(name, email) {
      if (email === '') {
        return;
      }
      if (this.emailTooltip.length === 0) {
        this.emailTooltip.push(name);
        this.showTooltip = true;
      }
    },
    offCheckbox(name) {
      if (this.showTooltip) {
        setTimeout(() => {
          this.emailTooltip.pop(name), 2000;
        });
      }
      if (!this.showTooltip) {
        this.emailTooltip.pop(name);
        this.showTooltip = false;
      }
    },
    showEmailTooltip(name) {
      return this.emailTooltip[0] === name;
    },
    onSelect(employee) {
      const empIndex = this.isSelected.map((item) => item?.guid).indexOf(employee?.guid);

      if (empIndex > -1) {
        employee.select = false;
        this.isSelected.splice(empIndex, 1);
        this.plan.inviteCount--;
      } else {
        employee.select = true;
        this.isSelected.push(employee);
        this.plan.inviteCount++;
      }

      // force rerender of v-data-table
      this.isSelected = [...this.isSelected];
      // force rerender of v-data-table
      this.$emit('invite-count', this.isSelected);
    },
    async selectAllToggle(event) {
      if (!event.value) this.isSelected = [];
    },
    currentEmployees(employees) {
      this.currentEmployeeGuids = employees.map((employee) => employee.guid);
    },
    getColor(skillCount) {
      if (skillCount > 100) return 'primary';
      else if (skillCount > 50) return 'blue';
      else return 'primary-darker';
    },
    async onInviteSent() {
      this.employees = this.employees.map((emp) => {
        emp.select = false;
        return emp;
      });
      this.isSelected = [];
      this.plan.inviteCount = 0;
    },
    async onPageSizeChange(size) {
      this.pageSize = size;
      this.pageNum = 1;
      this.handlePageClick();
    },
    onDataTableChange() {
      this.isClickedOnSort = false;
    },
    onPageClick(page) {
      this.options.page = page;
      this.handlePageClick();
    },
    onSortBy(sortBy) {
      this.isClickedOnSort = true;
      if (this.sortBy !== sortBy) {
        this.sortBy = sortBy;
        if (sortBy.length) {
          this.employees = this.lodash.orderBy(this.employees, [sortBy[0].key], [sortBy[0].order]);
        } else {
          this.employees = this.workforcePlannerResponse.users || [];
        }
      }
    },
    onItemsPerPage(size) {
      this.options.itemsPerPage = size;
      this.onPageSizeChange(size);
    },
    onSelectAll(selectAll) {
      if (this.isAllSelected) {
        this.filterEmployees.forEach((item) => {
          if (!item.disabled && item.select) {
            item.select = false;
            this.plan.inviteCount--;
          }
        });
        selectAll(false);
        this.isSelected = [];
      } else {
        this.filterEmployees.forEach((item) => {
          if (!item.disabled && !item.select) {
            item.select = true;
            this.plan.inviteCount++;
          }
        });
        selectAll(true);
      }

      this.isSelected = this.isSelected.filter((item) => !item.disabled);
      this.$emit('invite-count', this.isSelected);
      this.isAllSelected = !this.isAllSelected;
    }
  }
};
</script>

<style lang="scss">
.message {
  .search {
    .v-field--variant-solo {
      height: 2.625rem;
    }

    .v-field__field,
    .v-field__prepend-inner {
      height: 2.625rem;
    }

    .v-field__input {
      padding-top: 0rem !important;
    }
  }
}

.workforce-planner-people.v-data-table > .v-table__wrapper > table > thead > tr > th {
  vertical-align: center !important;
  font-size: 1rem !important;
  color: $color-primary !important;
  font-weight: bold;
}

.workforce-planner-people.v-data-table > .v-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
}

.workforce-planner-people table > tbody > tr {
  &:hover {
    background-color: $color-secondary-super-lighter !important;
    font-size: 1rem;
    color: $color-primary;
  }
  .v-input__details {
    display: none;
  }
}

.workforce-planner-people.v-data-table {
  z-index: 3333;

  .no-data-available {
    font-size: 1.5rem;
    font-weight: normal;
    font-family: 'Futura PT Demi';
    text-align: start;
    color: rgba(0, 0, 0, 0.38);
  }
}
.workforce-people-pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #efefef 0% 0% no-repeat padding-box;
  position: relative;
  height: 8.25rem;

  .pagination {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0rem;
    width: 50%;
  }

  .pagination-item {
    margin: 0 0.313rem !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    cursor: pointer !important;
  }

  li.pagination-nav {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .pagination-item-link {
    color: var(--ion-color-post-gray);
    font-size: 0.875rem !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination-nav-link {
    position: relative;
    color: #5c5c5c;
    font-size: 3rem !important;
    vertical-align: middle;
    font-family: 'Futura PT Book';
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.9375rem;
  }

  li.disabled {
    border: none;
    cursor: default;
    .pagination-item-link {
      cursor: default;
    }
  }

  li.active {
    border: 0.125rem solid #1dd1b0;
    border-radius: 0.25rem;
    background: white;
    .pagination-item-link {
      color: #000000;
    }
  }

  li:hover {
    background: #e0e1e1;
  }

  li .tooltiptext {
    visibility: hidden;
    width: max-content;
    height: 1.875rem;
    background-color: #efefef;
    color: #000000de;
    text-align: center;
    padding: 0.4375rem 0.625rem;
    border-radius: 0.25rem;
    position: absolute;
    z-index: 1;
    bottom: 90%;
    left: 50%;
    margin-left: -1.25rem;
    font-size: 0.75rem;
    font-family: 'Futura PT Demi';
  }

  .li:hover .tooltiptext {
    visibility: visible;
  }

  .pagination-tooltip {
    position: relative;
  }

  .pagination-tooltip::after {
    content: attr(data-tooltip-content);
    padding: 0.5rem;
    border-radius: 0.25rem;
    position: absolute;
    color: white;
    bottom: 135%;
    right: 50%;
    left: 50%;
    width: max-content;
    opacity: 0;
    font-size: 0.8rem;
    visibility: hidden;
    transform: translate(-50%, 1.125rem) scale(0.8);
    transition: visibility, opacity, transform 250ms;
    text-align: left;
    font: normal normal normal 0.75rem/1rem Arial;
    letter-spacing: 0rem;
    color: #000000de;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.125rem 0.375rem #0000001f;
    border-radius: 0.25rem;
  }

  .pagination-tooltip:hover::after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
  }
  .workforce-people-size-select-container {
    display: flex;

    .workforce-people-pagination-select {
      .v-input__control {
        &:before,
        &:after {
          display: none !important;
        }
      }
    }

    .v-select.v-input--dense .v-select__selection--comma {
      margin: 0px !important;
    }

    .v-text-field__details {
      display: none;
    }

    .v-select-list {
      padding: 0px !important;
    }
    .v-select__slot,
    select {
      outline: none;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 0.125rem;
      opacity: 1;
      width: 3.625rem;
      height: 1.75rem;
      padding-left: 0.25rem;
      font: normal normal bold 0.875rem/1.875rem Arial;
      letter-spacing: 0rem;
      color: #101a38;
    }

    span {
      font: normal normal normal 0.875rem/1.875rem Arial;
      letter-spacing: 0rem;
      color: #5c5c5c;
      opacity: 1;
      margin-left: 1rem;
    }
  }
}
</style>

<style lang="scss" scoped>
.v-card {
  margin: 1rem 0 !important;
}

.disabled {
  background-color: $color-grey;
  color: $color-white;
}

.message {
  width: 70%;
  margin-top: 0.25rem;
  padding: 0.25rem 1rem 0rem 1rem;
  border-radius: 10px;
  background: $color-data-1-lighter;
  color: $color-success;
}

.employee-name {
  cursor: pointer;
  &:hover {
    font-size: 18px;
    color: $color-data-2;
  }
}

.button {
  position: relative;
  float: right;
  width: 37px;
  margin-right: 1rem;
  text-transform: capitalize;
  background: $button-primary-color-bg !important;
  color: $button-primary-color-text !important;
}

.checkboxWrapper {
  display: grid;
  justify-content: space-around;
}

.tooltiptext {
  position: absolute;
  white-space: nowrap;
  // margin-left: -9rem;
  text-align: center;
  background: rgba(97, 97, 97, 0.9);
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  padding: 5px 16px;
  text-transform: none;
  width: auto;
  opacity: 1;
  pointer-events: none;
  border-radius: 6px;
  z-index: 99;
}

.search {
  padding: 0;
  float: right;
  width: 100%;
  position: relative;
  top: 0.5rem;
}

.selected-org-name {
  color: #1f3370;
  font-size: 18px;
  font-weight: bold;
  padding: auto;
  margin: auto;
}

.employee-license {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
