<template>
  <v-dialog content-class="tracks-dislog" class="save-dialog" :modelValue="trackSaveDialog" @update:modelValue="saveDialog = $event" persistent width="584">
    <v-card class="t-rounded-20-xl t-p-8">
      <div>
        <v-btn icon class="unsaved-close-btn t-float-right" @click="cancelNavigation">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <p class="track-title mb-6">{{ trackPopupDetails.title }}</p>
        <v-card-text class="track-message t-p-0 t-text-dark-grey"> {{ trackPopupDetails.message }} </v-card-text>
        <v-card-actions class="t-pt-8 t-pb-0 t-place-content-end">
          <button v-if="trackPopupDetails.edit" class="re-craete-btn t-rounded-full t-text-primary-color t-mr-6 t-text-center t-py-4 t-px-8 t-flex t-justify-center t-items-center" text @click="editTrack()">
            {{ trackPopupDetails.edit }}
          </button>
          <button v-if="trackPopupDetails.cancel" class="t-text-primary-color cancel-btn t-p-4 t-mr-4 min-w-min t-border-primary-color" text @click="cancelNavigation()">
            {{ $t('button.cancel') }}
          </button>
          <button v-if="trackPopupDetails.okay" class="track-button t-mx-1 t-color-white t-py-4 t-px-8 min-w-min t-rounded-full t-bg-credential t-border-credential hover:t-bg-primary-color hover:t-text-white" text @click="cancelNavigation">
            {{ trackPopupDetails.okay }}
          </button>
          <button v-if="trackPopupDetails.ok" class="track-button t-mx-1 t-color-white t-py-4 t-px-8 min-w-min t-rounded-full t-bg-credential t-border-credential hover:t-bg-primary-color hover:t-text-white" text @click="savedTrack()">
            {{ trackPopupDetails.ok }}
          </button>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    trackSaveDialog: {
      type: Boolean,
      required: true,
      default() {
        return false;
      }
    },
    trackPopupDetails: {
      type: Object,
      require: true,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    cancelNavigation() {
      this.$emit('cancel-navigation');
    },
    editTrack() {
      this.$emit('edit-track');
    },
    savedTrack() {
      this.$emit('saved-track');
    }
  }
};
</script>
<style lang="scss" scoped>
.tracks-dislog {
  .track-title {
    color: $color-black;
    @include header-font-1-5;
  }
  .track-message {
    @include body-font-1;
  }
  .track-button {
    color: $color-black;
    @include body-font-bold-1;
  }
  .re-craete-btn {
    @include body-font-bold-1;
    border: 2px solid var(--Border-Border-Midnight, #1f3370);
  }
  .cancel-btn {
    @include body-font-bold-1;
  }
  button:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
  }
  .unsaved-close-btn {
    box-shadow: unset;
    margin-right: 1.125rem;
    margin-top: -0.125rem;
    .v-icon {
      font-size: 1.875rem;
    }
  }
  .v-card__text {
    color: #5c5c5c;
  }
  &.v-dialog {
    box-shadow: none;
  }
  .v-dialog > .v-overlay__content > .v-card {
    padding: 2rem !important;
  }
}
</style>
