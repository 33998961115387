<template>
  <ul>
    <li v-for="(item, index) in items" :key="index">
      <button class="list-item-button" :class="{ 'list-item-button-in-focus': item?.inFocus }" @click="getPlan(item)" :style="`padding-left: ${row}rem`">
        <span v-if="isFolder(item)" class="list-selector-container" @click="toggle($event, item)">
          <span class="list-selector">
            <i :class="[{ rotateIcon: item?.isOpen }, `${mode !== 0 && item?.isOpen && !item?.children?.length ? listSelector : 'mdi-chevron-down'}`]" class="mdi v-icon notranslate v-theme--light v-icon--size-default"></i>
          </span>
        </span>

        <span :id="`treeview-node-label-${item.id}`" class="dept-name" :class="{ 'dept-name-no-folder': !isFolder(item) }">{{ item.name }}</span>

        <div class="list-item-icon" :class="[{ 'download-icon': item.downloadStatus === 'READY' }]" v-if="withDownload">
          <span class="tooltip">
            <span class="t-cursor-pointer">
              <svg-icon
                v-if="item.downloadStatus == 'READY'"
                icon-class="icon-download"
                class="download-csv t-cursor-pointer t-w-5 t-h-5"
                @click="
                  $event.stopPropagation();
                  downloadCSV(item);
                "
              />
              <span class="tooltiptext">{{ $t('common.titleCase.learningRecord') }}_{{ item.name }}</span>
            </span>
          </span>

          <v-progress-circular v-if="item.downloadStatus == 'LOADING'" indeterminate color="black" :width="3" :size="20"></v-progress-circular>
          <img v-if="item.downloadStatus == 'SUCCESS'" src="@/assets/images/svgs/green_check_icon.svg" />
          <img v-if="item.downloadStatus == 'ERROR'" src="@/assets/images/svgs/error_icon.svg" />
        </div>
      </button>
      <DataTableTreeView
        v-if="isFolder(item)"
        v-show="item?.isOpen"
        :key="index"
        :row="row + 1"
        :items="item.children"
        :mode="mode"
        :withDownload="withDownload"
        @download-csv="downloadCSV"
        @on-get-plan="getPlan"
        @load-children="onLoadChildren"
        :onDownloadCSV="downloadCSV"
        :onGetPlan="getPlan"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DataTableTreeView',
  emits: ['on-get-plan', 'load-children', 'download-csv'],
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    row: {
      type: Number,
      required: true,
      default: () => 0
    },
    mode: {
      type: Number,
      required: false,
      default: () => 0
    },
    withDownload: {
      type: Boolean,
      required: false,
      default: () => false
    },
    onGetPlan: {
      type: Function,
      required: false,
      default: () => {}
    },
    onDownloadCSV: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  data() {
    return { itemInFocus: null, itemSpinning: null, listSelector: 'mdi-chevron-down' };
  },
  mounted() {
    if (![...this.EventBus.all.keys()].includes('ctd-get-plan')) {
      this.EventBus.on('ctd-get-plan', this.onGetPlan);
    }
    if (![...this.EventBus.all.keys()].includes('ctd-download-csv')) {
      this.EventBus.on('ctd-download-csv', this.downloadCSV);
    }
  },
  unmounted() {},
  methods: {
    getIcon(item) {
      if (this.mode) {
        return 'mdi-chevron-down';
      }
      return item?.itemSpinning ? 'mdi-cached' : 'mdi-chevron-down';
    },
    isFolder(item) {
      return this.mode === 0 ? item.children && item.children.length : item.children !== null;
    },
    getPlan(item) {
      if (this.row === 0) {
        if (this.itemInFocus) {
          this.itemInFocus.inFocus = false;
        }
        item.inFocus = true;
        this.itemInFocus = item;
      }

      this.$emit('on-get-plan', item);
      // extend this in the future
      // this.EventBus.emit('ctd-get-plan', item);
    },
    onLoadChildren(item) {
      this.$emit('load-children', item);
    },
    downloadCSV(item) {
      this.$emit('download-csv', item);
      // extend this in the future
      // this.EventBus.emit('ctd-download-csv', item);
    },
    async toggle(e, item) {
      e.stopPropagation();

      if (this.isFolder(item)) {
        if (!item.isOpen) {
          item.isOpen = true;

          if (this.mode !== 0) {
            if (!item.children.length) {
              this.listSelector = 'mdi-cached';
              this.$emit('load-children', item);
              while (item?.children?.length === 0) {
                await new Promise((resolve) => setTimeout(resolve, 100));
                if (item?.children?.length) {
                  this.listSelector = 'mdi-chevron-down';
                  return;
                }
              }
            }
          }
        } else {
          item.isOpen = false;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
ul {
  line-height: 1.5em;
  list-style-type: none;

  li {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .list-item-button {
      cursor: pointer;
      padding: 0.5rem 0rem;
      display: flex;
      align-items: center;

      .list-selector-container {
        display: flex;
        align-items: center;
        padding-left: 0.5em;
        padding-right: 0.5rem;

        .list-selector {
          background: #e4e5e4 0 0 no-repeat padding-box;
          border: 0.0625rem solid #e5e5e5;
          opacity: 1;
          border-radius: 6.25rem;
          height: 1.9375rem;
          width: 1.9375rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          i {
            rotate: -90deg;
          }
          .mdi-cached {
            animation: circle 1s linear infinite;
          }

          @keyframes circle {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          .rotateIcon {
            rotate: 0deg;
          }
        }
      }

      .dept-name {
        flex: 1;
        text-align: left;
        color: #000;
        cursor: pointer;
        font: normal normal normal 1rem/2.5rem Arial;
        letter-spacing: 0;
        opacity: 1;
      }

      .dept-name-no-folder {
        padding-left: 2.5em;
      }

      .list-item-icon {
        padding-right: 1rem;
      }

      .download-icon {
        visibility: hidden;
      }

      &:hover {
        transition: all 2sec;
        .download-icon {
          visibility: visible;
        }
      }
    }

    .list-item-button-in-focus {
      font-weight: bold;
      cursor: pointer;
      background: hsla(0, 0%, 100%, 0.6);
    }

    &::marker {
      display: none;
    }
  }
  .tooltip {
    position: relative;
    display: inline-block;
    display: contents;

    .t-cursor-pointer {
      display: inline-flex;
      align-items: center;
    }
    .tooltiptext {
      visibility: hidden;
      width: max-content;
      padding: 0.5rem 2rem;
      background-color: #d0d0d0;
      color: #000;
      color: rgba(0, 0, 0, 0.87);
      text-align: center;
      border-radius: 0.25rem;
      position: absolute;
      z-index: 1;
      margin-left: 2rem;
      font-weight: 400;
      font-size: 0.875rem;
    }

    &:hover .tooltiptext {
      visibility: visible;
    }
  }
}
</style>
