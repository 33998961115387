import { createI18n } from 'vue-i18n';
import axios from 'axios';
import en_json from '@/locales/en-us.json';

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
export function getSupportedLanguages() {
  const supportedLangs = [
    { countryCode: 'us', languageCode: 'en-us', name: i18n.global.t('languagePicker.en-us') },
    { countryCode: 'uk', languageCode: 'en-gb', name: i18n.global.t('languagePicker.en-gb') },
    { countryCode: 'fr', languageCode: 'fr-fr', name: i18n.global.t('languagePicker.fr-fr') },
    { countryCode: 'ca', languageCode: 'fr-ca', name: i18n.global.t('languagePicker.fr-ca') },
    { countryCode: 'cn', languageCode: 'zh-cn', name: i18n.global.t('languagePicker.zh-cn') },
    { countryCode: 'cn', languageCode: 'zh-tw', name: i18n.global.t('languagePicker.zh-tw') },
    { countryCode: 'de', languageCode: 'de-de', name: i18n.global.t('languagePicker.de-de') },
    { countryCode: 'jp', languageCode: 'ja-jp', name: i18n.global.t('languagePicker.ja-jp') },
    { countryCode: 'kr', languageCode: 'ko-kr', name: i18n.global.t('languagePicker.ko-kr') },
    { countryCode: 'pt', languageCode: 'pt-br', name: i18n.global.t('languagePicker.pt-br') },
    { countryCode: 'mx', languageCode: 'es-mx', name: i18n.global.t('languagePicker.es-mx') },
    { countryCode: 'es', languageCode: 'es-es', name: i18n.global.t('languagePicker.es-es') },
    { countryCode: 'es', languageCode: 'su-br', name: i18n.global.t('languagePicker.su-br') },
    { countryCode: 'es', languageCode: 'su-fr', name: i18n.global.t('languagePicker.su-fr') },
    { countryCode: 'es', languageCode: 'su-de', name: i18n.global.t('languagePicker.su-de') },
    { countryCode: 'es', languageCode: 'su-mx', name: i18n.global.t('languagePicker.su-mx') },
    { countryCode: 'es', languageCode: 'su-tw', name: i18n.global.t('languagePicker.su-tw') },
    { countryCode: 'es', languageCode: 'su-cn', name: i18n.global.t('languagePicker.su-cn') }
  ];
  return supportedLangs;
}

export function getSupportedLanguagesForLanguageDropdown() {
  const supportedLangs = [
    { countryCode: 'us', languageCode: 'en-us', name: i18n.global.t('languagePicker.en-us') },
    { countryCode: 'uk', languageCode: 'en-gb', name: i18n.global.t('languagePicker.en-gb') },
    { countryCode: 'fr', languageCode: 'fr-fr', name: i18n.global.t('languagePicker.fr-fr') },
    { countryCode: 'ca', languageCode: 'fr-ca', name: i18n.global.t('languagePicker.fr-ca') },
    { countryCode: 'cn', languageCode: 'zh-cn', name: i18n.global.t('languagePicker.zh-cn') },
    { countryCode: 'cn', languageCode: 'zh-tw', name: i18n.global.t('languagePicker.zh-tw') },
    { countryCode: 'de', languageCode: 'de-de', name: i18n.global.t('languagePicker.de-de') },
    { countryCode: 'jp', languageCode: 'ja-jp', name: i18n.global.t('languagePicker.ja-jp') },
    { countryCode: 'kr', languageCode: 'ko-kr', name: i18n.global.t('languagePicker.ko-kr') },
    { countryCode: 'pt', languageCode: 'pt-br', name: i18n.global.t('languagePicker.pt-br') },
    { countryCode: 'mx', languageCode: 'es-mx', name: i18n.global.t('languagePicker.es-mx') },
    { countryCode: 'es', languageCode: 'es-es', name: i18n.global.t('languagePicker.es-es') }
  ];
  return supportedLangs;
}

export function getFallbackLanguage(langCode) {
  switch (langCode) {
    case 'es':
      return 'es-es';
    case 'de':
      return 'de-de';
    case 'ja':
      return 'ja-jp';
    case 'ko':
      return 'ko-kr';
    case 'fr':
      return 'fr-fr';
    default:
      return langCode;
  }
}

export async function setNewLocale(localeCode) {
  if (localeCode === 'en-us') {
    i18n.global.locale = 'en-us';
    i18n.global.setLocaleMessage('en-us', en_json);
  } else {
    const supLang = getSupportedLanguages();
    if (supLang.find((e) => e.languageCode === localeCode)) {
      const uninterceptedAxiosInstance = axios.create();
      const url = `https://storage.googleapis.com/proedge-static-plan-assets/fe-locales/${localeCode}.json?timestamp=${new Date().getTime()}`;
      const headers = { 'Content-Type': 'application/json;charset=UTF-8' };
      const response = await uninterceptedAxiosInstance.get(url, { headers: headers }).catch((error) => {
        return { data: error.response.data, error: true };
      });
      if (response.status === 200) {
        i18n.global.locale = localeCode;
        i18n.global.setLocaleMessage(localeCode, response.data);
      } else {
        i18n.global.locale = 'en-us';
        i18n.global.setLocaleMessage('en-us', en_json);
      }
    }
  }
}

export const translate = (key) => {
  if (!key) {
    return '';
  }
  return i18n.global.t(key);
};

export const translateTC = (key, count) => {
  if (!key) {
    return '';
  }
  return i18n.global.tc(key, count);
};

export const i18n = createI18n({
  locale: 'en-us' || process.env.VUE_APP_I18N_LOCALE,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-us',
  messages: loadLocaleMessages(),
  silentTranslationWarn: process.env.NODE_ENV !== 'development',
  globalInjection: true,
  legacy: true
});
