import PlanAdminWeb from '@/api/plan-web';
// eslint-disable-next-line
import { getFeatureFlagStrategy } from '@/utils';
import localforage from 'localforage';

const PlanAdminProvider = new PlanAdminWeb();

export default {
  install(app, options) {
    const { store } = options;
    let currentVersion = '';

    async function checkVersion() {
      if (isNewVersion()) {
        this.EventBus.emit('new-version');
      }
    }

    app.config.globalProperties.$checkVersion = checkVersion;
    app.config.globalProperties.$isNewVersion = isNewVersion;
    app.config.globalProperties.$loadVersion = loadVersion;

    async function isNewVersion() {
      const storeVersion = await localforage.getItem('my_plan_version');
      currentVersion = process.env.VUE_APP_VERSION;
      return storeVersion != currentVersion ? true : false;
    }

    async function loadVersion() {
      await store.dispatch('plan/setPlanVersion', currentVersion);

      const tenant = await PlanAdminProvider.getUserTenantInfo();
      await store.dispatch('user/setClientCategory', tenant.clientCategory);
      await store.dispatch('user/setTenantType', tenant.tenantType);
      await store.dispatch('user/setTenantGuid', tenant.guid);
      await store.dispatch('user/setLastUpdated', tenant.lastUpdate);

      const settings = tenant.settings && tenant.settings !== '' ? JSON.parse(tenant.settings) : null;
      if (settings) {
        await store.dispatch('user/setTenantSettings', settings);
        await store.dispatch('user/setNextReportDate', settings['report-nextgeneration-date']);
        await store.dispatch('user/setLastReportDate', settings['report-lastgenerated-date']);
        await store.dispatch('user/setIsDashboardDownloadOn', settings['plan.dashboard.download']);
        await store.dispatch('user/setShowShare', settings['ui-share-display']);
        await store.dispatch('user/setShowIdea', settings['ui-idea-display']);
        await store.dispatch('user/setTrackingDisabled', {}.hasOwnProperty.call(settings, 'trackingDisabled') ? settings['trackingDisabled'] : false);
      }

      if (!Object.keys(store.state.flag.features).length) {
        const response = await PlanAdminProvider.getBackupFeatureFlags();
        await store.dispatch('flag/setFeatureFlags', { featureFlags: response.features, strategyProviders: getFeatureFlagStrategy() });
      }
    }
  }
};
