// eslint-disable-next-line
import Vue from 'vue';
import localforage from 'localforage';
import axios from 'axios';
import { getMetaData } from '../utils/helper';
const appDetails = require('../../package.json');

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

class Analytics {
  constructor() {
    this.baseUri = process.env.VUE_APP_CLICKSTREAM;
  }

  setVarAndTrack = async (...args) => {
    await sleep(1000);
    const cla = args.length > 0 ? args[0] : { c: '', l: '', a: '' };
    const userIdPromise = await localforage.getItem('my_user_guid');
    const usernamePromise = await localforage.getItem('my_username');

    let clickstreamData = {};
    clickstreamData['app'] = appDetails.name;
    clickstreamData['c'] = cla.c;
    clickstreamData['l'] = cla.l;
    clickstreamData['a'] = cla.a;
    clickstreamData['u'] = userIdPromise ? userIdPromise : '';
    clickstreamData['un'] = usernamePromise ? usernamePromise : '';

    const { contentTypeHeader } = await getMetaData();
    const response = await axios.post(`${this.baseUri}clickstream`, clickstreamData, { headers: contentTypeHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  };
}

export default {
  install(app) {
    const analytics = new Analytics();
    app.config.globalProperties.analytics = analytics;
    window.analytics = analytics;
  }
};
