<template>
  <div class="container">
    <v-card class="terms-card">
      <v-card-title class="terms-title">Terms of Service</v-card-title>
      <v-card-text style="padding: 20px">
        <h4 class="xsmall">Last updated: May 2022</h4>
        <br />
        <p>
          Please read these Terms of Use (the "<b><i>Terms</i></b
          >") carefully. These Terms govern any installation and use of (including access to) the PwC product(s) or service(s) made available to you that are accompanied by these Terms (each, together with any information, data, content or other materials and services provided
          with or available on or through such product or service, successor product(s) or service(s) to such product or service, and any website or mobile software application through which PwC makes such product or service or successor product(s) or service(s) available to you,
          a "<b><i>Solution</i></b
          >"). Your installation or use of (including accessing) a Solution constitutes your consent to these Terms and any applicable Solution Terms, as defined below (collectively, the "<b><i>Agreement</i></b
          >"). IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU MAY NOT INSTALL, ACCESS, OR USE THE APPLICABLE SOLUTION.
        </p>
        <p>
          With respect to a particular Solution, this Agreement is between you and the PwC entity that is the provider of the Solution (one of PwC Product Sales LLC, PricewaterhouseCoopers Advisory Product Sales LLC, PricewaterhouseCoopers Services LLC, PricewaterhouseCoopers
          LLP, or PricewaterhouseCoopers Advisory Services LLC) (such applicable entity, "<b><i>PwC</i></b
          >" or "<b><i>we</i></b
          >" or "<b><i>us</i></b
          >"). PwC will provide the name of the provider of the applicable Solution upon request.
        </p>
        <p>
          Additional terms may apply with respect to particular Solutions (as updated by PwC from time to time, the "<b><i>Solution Terms</i></b
          >"). Solution Terms may be made available on or through the Solutions, or otherwise made available to you by PwC. In the event of any irreconcilable conflict between the Terms and any Solution Terms, the Solution Terms will control to the extent of such conflict, solely
          with respect to the applicable Solution.
        </p>
        <p>BY INSTALLING, ACCESSING OR OTHERWISE USING A SOLUTION, YOU AFFIRM THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS AGREEMENT OR, IF YOU ARE NOT, THAT YOU HAVE OBTAINED PARENTAL OR GUARDIAN CONSENT TO ENTER INTO THIS AGREEMENT.</p>
        <p>
          IF YOU ARE AN INDIVIDUAL INSTALLING, ACCESSING OR OTHERWISE USING A SOLUTION ON BEHALF OF, OR FOR THE BENEFIT OF, ANY CORPORATION, PARTNERSHIP OR OTHER ENTITY WITH WHICH YOU ARE ASSOCIATED (AN "<b><i>ORGANIZATION</i></b
          >"), AND SUCH ORGANIZATION HAS ENTERED INTO ONE OR MORE SEPARATE AGREEMENTS GOVERNING THE USE OF SUCH SOLUTION, THEN NOTHING IN THIS AGREEMENT LIMITS YOUR OR SUCH ORGANIZATION'S OBLIGATIONS OR PWC'S RIGHTS UNDER THOSE AGREEMENTS WITH RESPECT TO SUCH SOLUTION, AND THOSE
          AGREEMENTS WILL GOVERN SUCH ORGANIZATION'S USE OF (INCLUDING ACCESS TO) SUCH SOLUTION. References to "<b><i>you</i></b
          >" and "<b><i>your</i></b
          >" in this Agreement will refer to the individual using the Solution.
        </p>
        <p>
          THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION THAT, AS FURTHER SET FORTH IN SECTION 19 BELOW, REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR ANY OTHER COURT PROCEEDINGS, OR CLASS ACTIONS OF ANY KIND.
        </p>

        <p>
          <b>1. <u>Changes.</u> </b>We may change these Terms and any Solution Terms from time to time by notifying you of such changes by any reasonable means, including by posting revised Terms or Solution Terms through a Solution, through this website at
          <a href="https://productcentral.products.pwc.com/legal/Terms-of-Use">https://productcentral.products.pwc.com/legal/Terms-of-Use</a> or any other applicable PwC website. No such changes will apply to any dispute between you and us arising before we posted the revised
          Terms or Solution Terms that contain such changes, or otherwise notified you of such changes.
        </p>
        <p>
          Your installation, access, or other use of a Solution following any changes to these Terms or any applicable Solution Terms will constitute (a) your acceptance of such changes to these Terms, which will apply with respect to all Solutions; and (b) your acceptance of
          such changes to the Solution Terms, which will apply with respect to the corresponding Solution. The "<i>Last Updated</i>" legend above indicates when these Terms were last changed. We may, at any time and without liability, modify or discontinue all or part of a
          Solution (including access to any Solution via any third-party resources); charge, modify or waive any fees required to install, access or otherwise use any Solution; or offer opportunities to some or all Solution users.
        </p>
        <p>
          <b>2. <u>Information Submitted Through a Solution.</u> </b>Your submission of information through any Solution is governed by the privacy policy applicable to the Solution, as updated by us from time to time (the "<b><i>Privacy Policy</i></b
          >"). Please carefully review the Privacy Policy for information about how we may collect, use, store, or share data from you. You represent and warrant that any information you provide in connection with any Solution is and will remain accurate and complete, and that
          you will maintain and update such information as needed.
        </p>
        <p>
          <b>3. <u>Jurisdictional Issues.</u> </b>The Solutions are controlled or operated (or both) from the United States or, as applicable, available for installation from the United States, and are not intended to subject PwC to any non-U.S. jurisdiction or law. The Solutions
          may not be appropriate or available for installation, access or other use in some non-U.S. jurisdictions. Any installation, access or other use of a Solution is at your own risk, and you must comply with all applicable laws, rules and regulations in doing so. We may
          limit a Solution's availability at any time, in whole or in part, to any person, geographic area or jurisdiction that we choose.
        </p>
        <p>
          <b>4. <u>Rules of Conduct.</u> </b>In connection with any Solution, you must not:
        </p>

        <ul>
          <li>
            Post, transmit or otherwise make available through or in connection with the Solution any information, data, content or other materials that are or may be: (a) threatening, harassing, degrading, hateful or intimidating, or otherwise fail to respect the rights and
            dignity of others; (b) defamatory, libelous, fraudulent or otherwise tortious; (c) obscene, indecent, pornographic or otherwise objectionable; or (d) protected by copyright, trademark, trade secret, right of publicity or privacy or any other proprietary right, without
            the express prior written consent of the applicable owner.
          </li>
          <li>
            Post, transmit or otherwise make available through or in connection with the Solution any virus, worm, Trojan horse, Easter egg, time bomb, spyware or other computer code, file or program that is or is potentially harmful or invasive or intended to damage or hijack
            the operation of, or to monitor the use of, any hardware, software or equipment (each, a "<b><i>Virus</i></b
            >").
          </li>
          <li>
            Use the Solution for any commercial purpose, including using the Solution for or in connection with any facility management, service bureau or time-sharing purposes, services, or arrangements, or otherwise use the Solution for processing information, data, content, or
            other materials on behalf of any third party.
          </li>
          <li>Use the Solution for any purpose that is fraudulent or otherwise tortious or unlawful.</li>
          <li>Harvest or collect information about other users of the Solution.</li>
          <li>
            Interfere with or disrupt the operation of the Solution or the servers or networks used to make any hosted portion of the Solution available, including by hacking or defacing any portion of the Solution; or violate any requirement, procedure or policy of such servers
            or networks.
          </li>

          <li>Interfere with or disrupt the integrity of any information, data, content, or other materials available in or through the Solution.</li>
          <li>Restrict or inhibit any other user from using the Solution.</li>
          <li>Reproduce, modify, adapt, translate, create derivative works of, sell, rent, lease, loan, timeshare, distribute or otherwise exploit any portion of (or any use of) the Solution except as expressly authorized herein, without PwC's express prior written consent.</li>

          <li>Reverse engineer, decompile or disassemble any portion of the Solution, except where such restriction is expressly prohibited by applicable law.</li>

          <li>Remove or alter any copyright, trademark or other proprietary rights notice on the Solution.</li>
          <li>Frame or mirror any portion of the Solution, or otherwise incorporate any portion of the Solution into any product or service or other software, without PwC's express prior written consent.</li>
          <li>Systematically download and store any information, data, content, or other materials available in or through the Solution.</li>
          <li>
            Use any robot, spider, site search/retrieval application or other manual or automatic device to retrieve, index, “scrape,” “data mine” or otherwise gather any information, data, content, or other materials available in or through the Solution, or reproduce or
            circumvent the navigational structure or presentation of the Solution, without PwC's express prior written consent.
          </li>
        </ul>
        <p>You are responsible for obtaining, maintaining and paying for all hardware and all telecommunications and other services needed to use any Solution.</p>
        <p>
          <b>5. <u>SMS Terms.</u> </b>The Solution may include certain text message functionality and related services for mobile devices and use of such functionality is subject to the SMS Terms and Acceptable Use Policy posted at
          <a href="https://productcentral.products.pwc.com/legal/SMS-Terms-and-Acceptable-Use-Policy">https://productcentral.products.pwc.com/legal/SMS-Terms-and-Acceptable-Use-Policy</a>.
        </p>
        <p>
          <b>6. <u>Registration; No Sharing of Username and Password.</u> </b>You may need to register or otherwise establish an account to install, access or otherwise use all or part of a Solution. We may reject, or require that you change, any username, password or other
          information that you provide to us in registering or otherwise establishing an account. You agree that you will provide truthful and accurate information that belongs to you during the registration and account creation process. Your username and password are for your
          personal use only and should be kept confidential. YOU MUST NOT PROVIDE YOUR USERNAME AND PASSWORD TO ANY OTHER PERSON OR ENTITY FOR PURPOSES OF INSTALLING, ACCESSING OR OTHERWISE USING ANY SOLUTION. You, and not PwC, are responsible for any use or misuse of your
          username or password, and you must promptly notify us of any confidentiality breach or unauthorized use of your username or password, or your account.
        </p>
        <p>YOU MUST NOT, DIRECTLY OR INDIRECTLY, ALLOW ANY OTHER PERSON OR ENTITY TO USE YOUR ACCOUNT TO INSTALL, ACCESS OR OTHERWISE USE ANY SOLUTION.</p>
        <p>
          <b>7. <u>Submissions.</u> </b>You and other users of a Solution may have the ability to make certain information, data, content, or other materials (each, a “<u>Submission</u>”) available through or in connection with a Solution (for example, reports generated by using
          a Solution), including through a Solution's interactive services or sharing functionality. PwC has no control over and is not responsible for any use or misuse (including any distribution) by any third party of Submissions. IF YOU CHOOSE TO MAKE ANY OF YOUR PERSONALLY
          IDENTIFIABLE OR OTHER INFORMATION PUBLICLY AVAILABLE THROUGH ANY SOLUTION, YOU DO SO AT YOUR OWN RISK.
        </p>
        <p>
          <b>8. <u>License to Submissions.</u> </b>For purposes of clarity, you retain ownership of your Submissions. For each Submission, you hereby grant to us a worldwide, royalty-free, fully paid-up, non-exclusive, perpetual, irrevocable, transferable and fully sublicensable
          (through multiple tiers) license, without additional consideration to you or any third party, to reproduce, distribute, perform and display (publicly or otherwise), create derivative works of, adapt, modify and otherwise use, analyze, exploit and practice such
          Submission, in any format or media now known or hereafter developed, and for any purpose (including promotional purposes, such as testimonials).
        </p>
        <p>
          In addition, if you provide to us any ideas, proposals, suggestions or other information or materials ("<b><i>Feedback</i></b
          >"), whether related to a Solution or otherwise, such Feedback will be deemed a Submission, and you hereby acknowledge and agree that such Feedback is not confidential, and that your provision of such Feedback is gratuitous, unsolicited and without restriction, and does
          not place PwC under any fiduciary or other obligation.
        </p>
        <p>
          You represent and warrant that you have all rights necessary to grant the licenses granted in this Agreement, including in this Section, and that your Submissions, and your provision thereof through and in connection with any Solution, are complete and accurate, and are
          not fraudulent, tortious or otherwise in violation of any applicable law or any right of any third party. You further irrevocably waive any “moral rights” or other rights with respect to attribution of authorship or integrity of materials regarding each Submission that
          you may have under any applicable law under any legal theory.
        </p>
        <p>
          <b>9. <u>Monitoring.</u> </b>We may (but have no obligation to) monitor, evaluate, alter or remove Submissions before or after they appear on or through any Solution, including to comply with any requirements or prerequisites under the DMCA (as defined below). In
          addition, we may track or analyze your installation, access to or other use of any Solution. We may use and disclose to anyone (including third parties) information regarding your access to and use of any Solution, and the circumstances surrounding such installation,
          access and other use, for any reason or purpose, including in connection with performing services for you, in connection with operating any Solution, or for analytic, statistical, security, quality control, and similar purposes (including in aggregate form, e.g., to
          analyze systems performance).
        </p>
        <p>
          <b>10. <u>Your Limited Rights.</u> </b>None of the Solutions or Third-Party Materials (as defined below) are sold to you. Your only rights in or to any Solutions or Third-Party Materials are those (if any) that are granted to you under an express license in this Section
          or the applicable Solution Terms (and, with respect to Third-Party Materials, under an applicable license between you and the corresponding third-party licensor). With respect to a particular Solution, subject to your compliance with this Agreement, and solely for so
          long as you are permitted by PwC to access and use the Solution: (a) with respect to any component of the Solution made available for installation, we hereby permit you, on a limited, non-exclusive, revocable, non-transferable, non-sublicensable basis, to install and
          use a single copy (or such other number of copies that have been licensed) of such portion of the Solution on equipment that you own or control, solely for your personal, non-commercial use; (b) with respect to any mobile software application component of the Solution
          (each, an "<b><i>App</i></b
          >"), we hereby permit you, on a limited, non-exclusive, revocable, non-transferable, non-sublicensable basis, to install and use the App on a mobile device that you own or control, solely for your personal, non-commercial use; and (c) with respect to any other component
          of the Solution, you may use any such portion of the Solution to which we provide you access under this Agreement, as viewable on any single device, solely for your personal, non-commercial use. If you fail to comply with any of the terms or conditions of this Agreement
          with respect to a particular Solution, you must immediately cease using the Solution and with respect to any portion of the Solution that you have installed on your equipment or any App, remove (that is, uninstall and delete) such portions of the Solution or any App
          from your mobile device or other equipment.
        </p>
        <p>
          <b>11. <u>PwC's Proprietary Rights.</u> </b>We and our suppliers own the Solutions, which are protected by proprietary rights and laws. As between you and us, the Solutions and all non-public information, data, content and other materials (technical, business and
          otherwise) related to any Solution (and the use of or access to it), including Submissions of other Solution users and any reports generated by using any Solution, are our "<b><i>Confidential Information</i></b
          >" and you will not use or disclose to any third party Confidential Information except to the extent necessary to exercise your rights and fulfill your obligations under this Agreement or as required by applicable law. All trade names, trademarks, service marks, logos
          and designs on any Solution not owned by us are the property of their respective owners. You may not use our trade names, trademarks, service marks, logos, or designs (including "PricewaterhouseCoopers" and "PwC" and any associated logos, marks and designs) in any
          manner without our express prior written consent, including in connection with any product or service that is not ours, or in any manner that is likely to cause confusion. Nothing in this Agreement and nothing in or available through any Solution should be construed as
          granting any right to use any trade names, trademarks, service marks, logos or designs without the express prior written consent of the owner.
        </p>
        <p>
          <b>12. <u>CPA Notice; PwC Related Parties.</u> </b>PwC is owned by professionals who hold CPA licenses as well as by professionals who are not licensed CPAs. Depending on the nature of the products and services provided to you under this Agreement (including any
          Solution or through any Solution), non-CPA owners may be involved in providing such products and services. PwC is a firm in the global network of separate and independent PricewaterhouseCoopers firms (exclusive of PwC, the "<b><i>Other PwC Firms</i></b
          >"). PwC may draw on the resources of (and subcontract to) its affiliates, the Other PwC Firms and third-party contractors and subcontractors, within or outside of the United States (each, a "<b><i>PwC Service Provider</i></b
          >") for internal, administrative and regulatory compliance purposes or in connection with providing any Solution. The PwC Service Providers and their and PwC's respective partners, principals, employees and agents (collectively, the "<b><i>PwC Beneficiaries</i></b
          >") will have no liability or obligations arising out of this Agreement, and you agree to bring any claim or other legal proceeding of any nature arising from or related to this Agreement or its subject matter against PwC and not against the PwC Beneficiaries. While PwC
          is entering into this Agreement on its own behalf, this Section 12 also is intended for the benefit of the PwC Beneficiaries.
        </p>
        <p>
          <b>13. <u>Third-Party Materials; Links.</u> </b>Certain Solution functionality may make available access to products, services, information, data, content, and other materials made available by third parties, including Submissions ("<b><i>Third-Party Materials</i></b
          >"), or allow for the routing or transmission of Third-Party Materials, including via links. By using such functionality, you are directing us to access, route and transmit to you the applicable Third-Party Materials. In addition, your installation and other use of
          Third-Party Materials may result in transmission of your information, data, content, or other materials outside of our systems, or otherwise result in third-party access to or use of your information, data, content, or other materials.
        </p>
        <p>
          We neither control nor endorse, nor are we responsible for, any Third-Party Materials, including their accuracy, validity, timeliness, completeness, reliability, integrity, quality, legality, usefulness or safety, or any applicable intellectual property rights. Certain
          Third-Party Materials may, among other things, be inaccurate, misleading or deceptive. Nothing in this Agreement will be deemed to be a representation or warranty by PwC with respect to any Third-Party Materials. We have no obligation to monitor Third-Party Materials,
          and we may block or disable access to any Third-Party Materials (in whole or part) through any Solution at any time. In addition, the availability of any Third-Party Materials through a Solution does not imply our endorsement of, or our affiliation with, any provider of
          such Third-Party Materials, nor does such availability create any legal relationship between you and any such provider.
        </p>
        <p>YOUR USE OF THIRD-PARTY MATERIALS IS AT YOUR OWN RISK AND IS SUBJECT TO ANY ADDITIONAL TERMS, CONDITIONS AND POLICIES APPLICABLE TO SUCH THIRD-PARTY MATERIALS (SUCH AS TERMS OF SERVICE OR PRIVACY POLICIES OF THE PROVIDERS OF SUCH THIRD-PARTY MATERIALS).</p>

        <p>
          <b>14. <u>Open Source.</u> </b>The Solutions may include open source components, which are licensed for use and distributed by us under applicable open source licenses including those posted at
          <a href="https://www.pwc.com/us/en/site/legal/open-source.html">https://www.pwc.com/us/en/site/legal/open-source.html</a> or otherwise identified by PwC. Use of these open source components is governed by and subject to the terms and conditions of the applicable open
          source license.
        </p>
        <p>
          <b>15. <u>DISCLAIMER OF WARRANTIES.</u> </b>The Solutions (including any information and materials made available to you in connection with using any Solution) are not for any third party's use or benefit and are not intended to be relied upon by you or any third party,
          including to operate or promote your business, secure financing or capital in any form, sell or underwrite securities, obtain any regulatory or governmental approvals, or otherwise be used in connection with procuring products or services or other benefits from any
          entity. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) THE SOLUTIONS AND ANY PRODUCTS, SERVICES AND THIRD-PARTY MATERIALS ARE MADE AVAILABLE TO YOU ON AN “AS IS,” “WHERE IS” AND “WHERE AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, WHETHER EXPRESS,
          IMPLIED OR STATUTORY; AND (B) PWC DISCLAIMS ALL WARRANTIES WITH RESPECT TO THE SOLUTION AND ANY PRODUCTS, SERVICES AND THIRD-PARTY MATERIALS, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND TITLE. ALL DISCLAIMERS OF
          ANY KIND (INCLUDING IN THIS SECTION AND ELSEWHERE IN THIS AGREEMENT) ARE MADE FOR THE BENEFIT OF BOTH PWC AND THE PWC BENEFICIARIES, AND ITS AND THEIR RESPECTIVE PARTNERS, PRINCIPALS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS, SUPPLIERS AND
          SERVICE PROVIDERS (COLLECTIVELY, THE "<b><i>AFFILIATED ENTITIES</i></b
          >"), AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS.
        </p>
        <p>
          While we try to maintain the timeliness, integrity and security of the Solutions, we do not guarantee that any Solution is or will remain updated, complete, accurate, correct or secure, or that installation, access to or other use of any Solution will be uninterrupted.
          The Solutions may include inaccuracies, errors and defects. Additionally, third parties may make unauthorized alterations to the Solutions. If you become aware of any such alteration, contact us at us_eula_inquiries@pwc.com with a description of such alteration and its
          location.
        </p>
        <p>
          <b>16. <u>LIMITATION OF LIABILITY.</u> </b>TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW: (A) PWC WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR PUNITIVE DAMAGES OF ANY KIND, UNDER ANY CONTRACT, TORT (INCLUDING NEGLIGENCE),
          STRICT LIABILITY OR OTHER THEORY, INCLUDING DAMAGES FOR LOSS OF PROFITS, USE OR DATA, LOSS OF OTHER INTANGIBLES, LOSS OF SECURITY OF SUBMISSIONS (INCLUDING UNAUTHORIZED INTERCEPTION BY THIRD PARTIES OF ANY SUBMISSIONS), EVEN IF ADVISED IN ADVANCE OF THE POSSIBILITY OF
          SUCH DAMAGES OR LOSSES; (B) WITHOUT LIMITING THE FOREGOING, PWC WILL NOT BE LIABLE FOR DAMAGES OF ANY KIND RESULTING FROM YOUR INSTALLATION, ACCESS OR OTHER USE OF OR INABILITY TO INSTALL, ACCESS OR OTHERWISE USE ANY SOLUTION OR FROM ANY PRODUCTS, SERVICES OR
          THIRD-PARTY MATERIALS, INCLUDING FROM ANY VIRUS THAT MAY BE TRANSMITTED IN CONNECTION THEREWITH; (C) YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH ANY SOLUTION OR ANY PRODUCTS, SERVICES OR THIRD-PARTY MATERIALS IS TO STOP USING THE APPLICABLE SOLUTION; AND (D)
          THE MAXIMUM AGGREGATE LIABILITY OF PWC FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, WILL BE THE GREATER OF ONE HUNDRED DOLLARS ($100) AND THE TOTAL AMOUNT, IF ANY, PAID BY YOU TO PWC TO USE THE APPLICABLE
          SOLUTION TO WHICH THE CLAIM RELATES IN THE TWELVE (12) MONTHS PRECEDING THE DATE WHEN SUCH CLAIM AROSE. ALL LIMITATIONS OF LIABILITY OF ANY KIND (INCLUDING IN THIS SECTION AND ELSEWHERE IN THIS AGREEMENT) ARE MADE FOR THE BENEFIT OF BOTH PWC AND THE AFFILIATED ENTITIES,
          AND ITS AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS.
        </p>
        <p>
          <b>17. <u>Indemnity.</u> </b>To the fullest extent permitted under applicable law, you will defend, indemnify and hold harmless PwC and each of the Affiliated Entities, and its and their respective successors and assigns, from and against all claims, liabilities,
          damages, judgments, awards, losses, costs, expenses and fees (including attorneys’ fees) arising out of or relating to (a) your installation or use of (including accessing), or activities in connection with, the Solutions (including all Submissions); and (b) any
          violation or alleged violation of this Agreement by you.
        </p>
        <p>
          <b>18. <u>Termination.</u> </b>These Terms and all Solution Terms will continue until terminated. PwC may immediately terminate these Terms or any Solution Terms without penalty or liability: (a) if continuing to perform under these Terms or such Solution Terms could,
          in PwC's sole and absolute judgment, result in PwC's noncompliance with any applicable law, rule or regulation, or any regulatory guidance, professional standard, or self-regulatory rule or policy, in each case as in effect from time to time; or (b) upon the occurrence
          of an event that, in PwC's sole and absolute judgment, causes or would be likely to cause PwC or any Other PwC Firm not to be “independent” as required by any law, rule, regulation or professional standard relating to independence. PwC may terminate or suspend (or
          require you to terminate or suspend) your use of any Solution, any portion of any Solution, or all Solutions at any time and without prior notice, for any reason, including if PwC believes that you have violated or acted inconsistently with the letter or spirit of this
          Agreement. Immediately upon any such termination or suspension (or any termination of these Terms or the applicable Solution Terms), your right to use the applicable Solution(s) or portion of such Solution(s) will permanently or temporarily cease (as applicable), and
          PwC may as applicable, without liability to you or any third party, deactivate or delete your username, password and account, and all associated hosted information, data, content or other materials, without any obligation to provide any further access to such materials,
          or repossess any portion of such Solution(s) you have installed on your mobile device or other equipment. If PwC terminates these Terms, then all Solution Terms and your rights with respect to all Solutions will automatically terminate. Sections 2 through 9 and 11
          through 25 of these Terms will survive any termination.
        </p>
        <p>
          <b>19. <u>Governing Law; Arbitration.</u> </b>This Agreement, and any dispute relating to this Agreement or the subject matter of this Agreement, will be governed by and construed, interpreted and enforced in accordance with the domestic laws of the State of New York,
          without giving effect to any provisions that would require the laws of another jurisdiction to apply and regardless of your location. The parties expressly disclaim the applicability of, and waive any rights based on, the Uniform Computer Information Transactions Act,
          the Uniform Commercial Code, or the United Nations Convention on Contracts for the International Sale of Goods, however each may be codified or amended. ALL DISPUTES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE SUBJECT MATTER OF THIS AGREEMENT (INCLUDING A
          SOLUTION AND THE INSTALLATION, ACCESS OR OTHER USE THEREOF) OR ANY ASPECT OF THE RELATIONSHIP BETWEEN YOU AND PWC UNDER THIS AGREEMENT, WHETHER BASED IN CONTRACT, TORT, STATUTE, FRAUD, MISREPRESENTATION OR ANY OTHER LEGAL THEORY, WILL BE RESOLVED THROUGH FINAL AND
          BINDING ARBITRATION BEFORE A NEUTRAL PANEL OF THREE ARBITRATORS INSTEAD OF IN A COURT BY A JUDGE OR JURY AND YOU AGREE THAT PWC AND YOU ARE EACH WAIVING THE RIGHT TO TRIAL BY A JURY. YOU AGREE THAT ANY ARBITRATION UNDER THIS AGREEMENT WILL TAKE PLACE ON AN INDIVIDUAL
          BASIS; CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED AND YOU ARE AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A CLASS ACTION. Notwithstanding the foregoing, nothing in this Agreement will preclude either party from seeking equitable relief in a court
          having jurisdiction. The arbitration will be conducted in accordance with the Rules for Non-Administered Arbitration of the International Institute for Conflict Prevention and Resolution then in effect ("<b><i>CPR Rules</i></b
          >"). The arbitration will be conducted before a panel of three arbitrators selected using the screened process provided for in the CPR Rules. The arbitration panel will have no power to award non-monetary or equitable relief of any sort, or to award damages inconsistent
          with this Agreement (including Section 17). Judgment on any arbitration award may be entered in any court having jurisdiction. All aspects of the arbitration will be treated as confidential. YOU ACKNOWLEDGE AND AGREE THAT ANY DEMAND FOR ARBITRATION MUST BE ISSUED WITHIN
          ONE (1) YEAR FROM THE DATE THAT YOU BECAME AWARE OR SHOULD REASONABLY HAVE BECOME AWARE OF THE FACTS THAT GIVE RISE TO PWC'S ALLEGED LIABILITY AND IN ANY EVENT NO LATER THAN TWO (2) YEARS AFTER THE CAUSE OF ACTION ACCRUED.
        </p>
        <p>
          <b>20. <u>Filtering.</u> </b>We hereby notify you that parental control protections (such as computer hardware, software or filtering services) are commercially available that may assist you in limiting access to material that is harmful to minors. Current providers of
          such protections include Norton Family from Symantec Corporation (at <a href="https://family.norton.com">https://family.norton.com</a>) and Net Nanny (at <a href="https://www.netnanny.com">https://www.netnanny.com</a>), and information identifying current providers of
          such protections is accessible on the Internet (for example, at <a href="https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers">https://en.wikipedia.org/wiki/Comparison_of_content-control_software_and_providers</a>) PwC does not endorse any
          of these products or services, including any that may be listed on the foregoing sites.
        </p>
        <p>
          <b>21. <u>Information or Complaints.</u> </b>If you have a question or complaint regarding any Solution, please send an e-mail to us_eula_inquiries@pwc.com. You may also contact us by writing to 300 Madison Avenue, New York, NY 10017, or by calling us at (646) 471-3000.
          Please note that e-mail communications will not necessarily be secure; accordingly, you should not include credit card information or other sensitive information in your e-mail correspondence with us. California residents may reach the Complaint Assistance Unit of the
          Division of Consumer Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
        </p>
        <p>
          <b>22. <u>Copyright Infringement Claims.</u> </b>If you believe in good faith that materials available on or through any Solution (including any applicable website for a Solution) infringe your copyright, the Digital Millennium Copyright Act of 1998 (the "<b
            ><i>DMCA</i></b
          >") provides a mechanism for you (or your agent) to send to PwC a written notice of such alleged infringement, requesting that PwC remove such material or block access to it. If you believe in good faith that someone has wrongly filed a notice of copyright infringement
          against you, the DMCA permits you to send to PwC a counter-notice. Notices and counter-notices must meet the DMCA's then-current statutory requirements. See <a href="http://www.copyright.gov/">http://www.copyright.gov/</a> for details. Notices and counter-notices must
          be sent in writing to PwC's DMCA agent as follows: By mail to PricewaterhouseCoopers LLP, Attn: DMCA Agent, Office of the General Counsel - IP Counsel, 300 Madison Avenue, New York, NY, 10017; by e-mail to us_dmca@pwc.com; or by fax to the fax number that we will
          provide to you if you request such fax number by contacting PwC's DMCA agent by mail, e-mail or telephone at the addresses or phone number provided in this Section. PwC's DMCA agent's phone number is (347) 901-2121.
        </p>
        <p>You should consult your legal advisor before filing a DMCA notice or counter-notice.</p>
        <p>
          <b>23. <u>Trade Control Laws.</u> </b>The Solutions are subject to applicable export control laws and economic sanctions, including those of the United States and other relevant jurisdictions ("<b><i>Trade Control Laws</i></b
          >"). You are responsible for complying with all applicable Trade Control Laws and any violations of such Trade Control Laws. You represent, warrant and covenant as follows: (a) you will not use any Solution except as authorized by applicable Trade Control Laws; (b) you
          are not located in, or a resident or national of, any country subject to a U.S. government embargo or other restriction, or that has been designated by the U.S. government as a “terrorist supporting” country ("<b><i>Sanctioned Countries</i></b
          >"); (c) you will not access or otherwise use any Solution from or on behalf of any Sanctioned Country; (d) you are not and are not acting on behalf of a person or entity on any U.S. government list of restricted end-users or other applicable restricted party lists; (e)
          you will not use any Solution for any end-uses restricted by the U.S. government; and (f) you will not use any Solution to store or transfer any data subject to the International Traffic in Arms Regulations or subject to the Export Administration Regulations and
          controlled at a level other than EAR99/AT.
        </p>
        <p>
          <b>24. <u>Miscellaneous.</u> </b>This Agreement does not, and will not be construed to, create any partnership, joint venture, employer-employee, agency or franchisor-franchisee relationship between you and PwC. If any provision of this Agreement is found to be
          unlawful, void or for any reason unenforceable, that provision will be deemed severable from this Agreement and will not affect the validity and enforceability of any remaining provision. You may not assign, transfer or sublicense any or all of your rights or
          obligations under this Agreement without our express prior written consent. We may assign, transfer or sublicense any or all of our rights or obligations under this Agreement without restriction. No waiver by either party of any breach or default under this Agreement
          will be deemed to be a waiver of any preceding or subsequent breach or default. Any heading, caption or section title contained herein is for convenience only, and in no way defines or explains any section or provision. All terms defined in the singular will have the
          same meanings when used in the plural, where appropriate and unless otherwise specified. Any use of the term “including” or variations thereof in this Agreement will be construed as if followed by the phrase "without limitation". This Agreement, including any terms and
          conditions incorporated herein, is the entire agreement between you and PwC relating to the subject matter hereof, and supersedes any and all prior or contemporaneous written or oral agreements or understandings between you and PwC relating to such subject matter.
          Notices to you (including notices of changes to this Agreement) may be made via posting on or through a Solution or by e-mail (including in each case via links), or by regular mail. Without limitation, a printed version of this Agreement and of any notice given in
          electronic form will be admissible in judicial or administrative proceedings based upon or relating to this Agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. PwC
          will not be responsible for any failure to fulfill any obligation due to any cause beyond its control.
        </p>
        <p>
          <b>25. <u>Apple-Specific Terms.</u> </b>In addition to your agreement with the foregoing terms and conditions, and notwithstanding anything to the contrary herein, the following provisions apply with respect to your use of any version of any App compatible with the iOS
          operating system of Apple Inc. ("<b><i>Apple</i></b
          >"). Apple is not a party to this Agreement and does not own and is not responsible for any App. Apple is not providing any warranty for any App except, if applicable, to refund the purchase price for it. Apple is not responsible for maintenance or other support
          services for any App and will not be responsible for any other claims, losses, liabilities, damages, costs, or expenses with respect to any App, including any third-party product liability claims, claims that any App fails to conform to any applicable legal or
          regulatory requirement, claims arising under consumer protection or similar legislation, and claims with respect to intellectual property infringement. Any inquiries or complaints relating to the use of any App, including those pertaining to intellectual property
          rights, must be directed to PwC in accordance with the “Information or Complaints” section above. The license you have been granted in this Agreement for any App compatible with Apple's iOS operating system is limited to a non-transferable license to use such App on an
          Apple-branded product that runs Apple's iOS operating system and is owned or controlled by you, or as otherwise permitted by the Usage Rules set forth in Apple's App Store Terms of Service, except that the App may also be accessed and used by other accounts associated
          with you via Apple's Family Sharing or volume purchasing programs. In addition, you must comply with the terms of any third-party agreement applicable to you when using any App, such as your wireless data service agreement. Apple and Apple's subsidiaries are third-party
          beneficiaries of this Agreement and, upon your acceptance of the terms and conditions of this Agreement, will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as a third-party beneficiary thereof; notwithstanding the
          foregoing, PwC's right to enter into, rescind or terminate any variation, waiver or settlement under this Agreement is not subject to the consent of any third party.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'Terms',
  metaInfo() {
    return {
      title: translate('pageTitles.terms')
    };
  }
};
</script>

<style lang="scss">
body {
  background-color: #efefef;
}
.v-application--wrap {
  background-color: #efefef;
}
</style>

<style lang="scss" scoped>
.terms-card {
  margin: 3.5% 0% 1% 0%;
  border-radius: 10px;
  padding: 0.5rem 1rem;
}

.terms-title {
  font-size: 25px;
  margin-top: 15px;
  color: $color-primary !important;
}

.xsmall {
  font-size: 12px;
}
</style>
