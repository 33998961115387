<template>
  <div class="csv-download-container" v-if="peopleListForCSV && peopleListForCSV.length">
    <div class="csv-download-content">
      <p>{{ $t('insights.enrollments.orgEnrollmentSummary') }}</p>

      <json2csv :data="peopleListForCSV" :labels="getLabelsForCSV()" :title="`${$t('insights.enrollments.orgEnrollmentSummary')}_${orgNameInFocus}_${generateCSVTimeFilter(timeFilter)}_${getDateForCSV()}`">
        <v-btn name="download" icon aria-label="download Org Enrollment Summary" title="download Org Enrollment Summary" class="orgEnrollmentFocus"><svg-icon icon-class="CSV_icon" class="t-w-8 t-h-8" /></v-btn>
      </json2csv>
    </div>
  </div>
</template>
<script>
import JSON2CSV from '@/components/molecules/JSON2CSV';
import localforage from 'localforage';
import { getDateForCSV, generateCSVTimeFilter, localizedDateForCSVs } from '@/utils';
import { translate } from '@/plugins/i18n.js';

const initialState = () => ({
  isC1: null,
  labelsForCSV: {},
  peopleListForCSV: [],
  isComponentRefreshed: false
});

export default {
  name: 'EnrollmentsCSVDownload',
  props: {
    orgNameInFocus: {
      type: String,
      default() {
        return '';
      }
    },
    timeFilter: {
      type: String,
      default() {
        return '';
      }
    },
    statusCategory: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    drillDownTableFilters: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    searchInputText: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    orgInFocus: {
      type: Number,
      required: true,
      default() {
        return 0;
      }
    },
    dateRange: {
      type: Object
    }
  },
  components: { json2csv: JSON2CSV },
  data() {
    return initialState();
  },
  watch: {
    timeFilter: function (newVal, oldVal) {
      if (this.timeFilter.length && newVal !== oldVal) {
        this.isComponentRefreshed = true;
        this.getPeopleListForCSV();
      }
    },
    drillDownTableFilters: {
      handler() {
        this.isComponentRefreshed = true;
        this.timeFilter.length && this.getPeopleListForCSV();
      },
      deep: true
    },
    searchInputText: function () {
      this.isComponentRefreshed = true;
      this.timeFilter.length && this.getPeopleListForCSV();
    },
    orgInFocus: function () {
      this.isComponentRefreshed = true;
      this.timeFilter.length && this.getPeopleListForCSV();
    },
    dateRange: {
      handler() {
        if (!this.isComponentRefreshed && this.dateRange.startDate != '' && this.dateRange.endDate != '') {
          this.timeFilter.length && this.getPeopleListForCSV();
        } else {
          this.isComponentRefreshed = false;
        }
      },
      deep: true
    }
  },
  async mounted() {
    Object.assign(this.$data, initialState());
    this.$data.labelsForCSV = {
      orgName: { title: translate('insights.enrollments.drilldownHeaders.orgName') },
      name: { title: translate('insights.enrollments.drilldownHeaders.name') },
      id: { title: translate('insights.enrollments.drilldownHeaders.id') },
      email: { title: translate('insights.enrollments.drilldownHeaders.email') },
      status: { title: translate('insights.enrollments.drilldownHeaders.status') },
      enrollmentStatus: { title: translate('insights.enrollments.drilldownHeaders.enrollmentStatus') },
      enrollmentDate: { title: translate('insights.enrollments.drilldownHeaders.enrollmentDate') },
      title: { title: translate('insights.enrollments.drilldownHeaders.title') },
      role: { title: translate('insights.enrollments.drilldownHeaders.role') },
      license: { title: translate('insights.enrollments.drilldownHeaders.license') },
      firstInviteDate: { title: translate('insights.enrollments.drilldownHeaders.firstInviteDate') },
      inviteDate: { title: translate('insights.enrollments.drilldownHeaders.inviteDate') }
    };
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
  },
  updated() {
    this.isComponentRefreshed = false;
  },
  methods: {
    getDateForCSV,
    generateCSVTimeFilter,
    localizedDateForCSVs,
    getLabelsForCSV() {
      const CSVLabels = Object.assign({}, this.labelsForCSV);
      if (this.timeFilter != 'ALLTIME') {
        if (this.statusCategory == 'invited' || this.statusCategory == 'notinvited') {
          delete CSVLabels['enrollmentStatus'];
          delete CSVLabels['enrollmentDate'];
        } else if (this.statusCategory == 'enrollment' || this.statusCategory == 'notinvited') {
          delete CSVLabels['inviteDate'];
        }
      }
      return CSVLabels;
    },
    async getPeopleListForCSV() {
      const titles = [];
      const roles = [];
      const licenses = [];
      const statusValues = [];

      for (let filter of this.drillDownTableFilters) {
        switch (filter.type) {
          case 'Job Title':
            titles.push(filter.name);
            break;
          case 'Role':
            roles.push(filter.name);
            break;
          case 'License':
            licenses.push(filter.name);
            break;
          case 'Status':
            statusValues.push(filter.name);
            break;
          default:
            break;
        }
      }
      const payload = {
        pageNo: 0,
        pageSize: 0,
        titles: titles || [],
        roles: roles || [],
        licenses: licenses || [],
        statusValues: statusValues || [],
        searchText: this.searchInputText || '',
        statusCategory: this.timeFilter != 'ALLTIME' ? this.statusCategory : '',
        startDate: this.timeFilter === 'CUSTOM' ? this.dateRange.startDate : '',
        endDate: this.timeFilter === 'CUSTOM' ? this.dateRange.endDate : '',
        dateFormat: 'MM-dd-yyyy'
      };
      const apiCall = this.orgInFocus ? this.$planAdmin.getDashboardDetailEmployeesFromOrg(this.orgInFocus, this.timeFilter, payload) : this.$planAdmin.getDashboardDetailAllEmployees(this.timeFilter, payload);
      await apiCall.then((response) => {
        if (this.isC1) {
          this.labelsForCSV = {
            totalLearnerCount: { title: translate('insights.enrollments.drilldownHeaders.totalLearnerCount') },
            enrollmentRate: { title: translate('insights.enrollments.drilldownHeaders.enrollmentRate') },
            totalJobTitles: { title: translate('insights.enrollments.drilldownHeaders.totalJobTitles') },
            totalRoles: { title: translate('insights.enrollments.drilldownHeaders.totalRoles') },
            totalLicenses: { title: translate('insights.enrollments.drilldownHeaders.totalLicenses') }
          };

          const { totalLearnerCount, enrollmentRate, totalJobTitles, totalRoles, totalLicenses } = response;
          this.peopleListForCSV = [{ totalLearnerCount, enrollmentRate, totalJobTitles, totalRoles, totalLicenses }];
        } else {
          const peopleListForCSV = response;
          //check for null or undefined
          peopleListForCSV.employees.forEach((employee) => {
            for (let i in employee) {
              if (employee[i] === undefined || employee[i] === null) {
                employee[i] = '';
              }
            }

            //formatting dates
            employee.enrollmentDate = this.localizedDateForCSVs(this.moment, employee.enrollmentDate);
            employee.inviteDate = this.localizedDateForCSVs(this.moment, employee.inviteDate);
            employee.firstInviteDate = this.localizedDateForCSVs(this.moment, employee.firstInviteDate);
          });
          this.peopleListForCSV = peopleListForCSV.employees || [];
        }
      });
    }
  }
};
</script>

<style lang="scss">
// .v-btn {
//   box-shadow: none;
//   background: transparent;
//   &:hover {
//     box-shadow: none;
//     background: transparent;
//   }
// }

.orgEnrollmentFocus:focus-visible {
  height: 32px;
  width: 32px;
  margin: 2.4px 2px 2px;
}

.csv-download-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  .csv-download-content {
    display: flex;
    align-items: center;
    p {
      margin: 0rem 1rem 0rem 0rem;
    }
  }
}
</style>
