<template>
  <div class="group-admin-container" v-if="dataReady && adminType" :style="adminType === 'global' ? 'display: block;' : ''">
    <div v-if="adminType === 'group' && (contenAccessTags.length > 0 || contenAccessGroupTags.length > 0 || userAccessTags.length > 0)">
      <h3>{{ $t('admin_planner.contant_tags') }}</h3>
      <p>{{ $t('admin_planner.content_tags_sub') }}</p>
      <div v-if="!isAddOn">
        <v-text-field
          id="search-user"
          class="search-user"
          data-el="searchUser"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          ref="searchInput"
          :placeholder="`search`"
          :value="contentQuery"
          flat
          filled
          solo
          rounded
          dense
          clearable
          v-model="contentQuery"
          variant="solo"
        >
        </v-text-field>
      </div>
      <div class="content-access-tags" v-if="!isAddOn">
        <div class="scrollable-content">
          <v-checkbox
            v-for="(contenAccessTag, index) in contenAccessTags"
            :key="index"
            :label="contenAccessTag.name"
            :value="contenAccessTag.id"
            v-model="selectedContentAccessTagsIds"
            :ripple="false"
            color="#1F3370"
            :style="contenAccessTag.style ? contenAccessTag.style : ''"
          ></v-checkbox>
          <v-checkbox
            v-for="(contenAccessGroupTag, index) in contenAccessGroupTags"
            :key="index"
            :label="contenAccessGroupTag.displayName"
            :value="contenAccessGroupTag.id"
            v-model="selectedContentAccessGroupTagsIds"
            :ripple="false"
            color="#1F3370"
            :style="contenAccessGroupTag.style ? contenAccessGroupTag.style : ''"
          ></v-checkbox>
        </div>
      </div>
      <div v-if="isAddOn" class="content-selected-access-tags">
        <v-checkbox v-for="(contenAccessTag, index) in selectedContentAccessTags" :key="index" :label="contenAccessTag.name" :value="contenAccessTag.id" v-model="selectedContentAccessTagsIds" :ripple="false" color="#1F3370"></v-checkbox>
        <v-checkbox v-for="(contenAccessGroupTag, index) in selectedContentAccessGroupTags" :key="index" :label="contenAccessGroupTag.displayName" :value="contenAccessGroupTag.id" v-model="selectedContentAccessGroupTagsIds" :ripple="false" color="#1F3370"></v-checkbox>
      </div>
      <div :class="(isAddOn && selectedContentAccessTagsIds.length + selectedContentAccessGroupTagsIds.length > 5) || viewAllEnabledForContentTags ? 'action-btns' : ''">
        <p v-if="(isAddOn && selectedContentAccessTagsIds.length + selectedContentAccessGroupTagsIds.length > 5) || viewAllEnabledForContentTags" class="view-all" @click="onViewAll('contentTag')">{{ $t('admin_planner.view_all') }}</p>
        <v-btn data-el="addTag" rounded dark color="#FFB92E" class="add action-btn black--text" @click="onAddTag()"
          ><svg-icon v-if="!isAddOn" icon-class="add-icon" style="margin-right: 8px; width: 18px; height: 19px" />{{ isAddOn ? $t('admin_planner.edit') : $t('admin_planner.add') }}</v-btn
        >
      </div>
    </div>
    <div v-if="adminType === 'group' && userAccessTags.length > 0">
      <h3>{{ $t('admin_planner.user_tag') }}</h3>
      <p>{{ $t('admin_planner.user_tag_sub') }}</p>
      <div v-if="!isAddOn">
        <v-text-field
          id="search-user"
          class="search-user"
          data-el="searchUser"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          ref="searchInput"
          :placeholder="`search`"
          :value="userTagQuery"
          flat
          filled
          solo
          rounded
          dense
          clearable
          v-model="userTagQuery"
          variant="solo"
        >
        </v-text-field>
      </div>
      <div class="content-access-tags" v-if="!isAddOn">
        <div class="scrollable-content">
          <v-checkbox
            v-for="(userAccessTag, index) in userAccessTags"
            :key="index"
            :label="getUserTagName(userAccessTag.name)"
            :value="userAccessTag.id"
            v-model="selectedUserAccessTagsIds"
            :ripple="false"
            color="#1F3370"
            :style="userAccessTag.style ? userAccessTag.style : ''"
          ></v-checkbox>
        </div>
      </div>
      <div v-if="isAddOn" class="content-selected-access-tags">
        <v-checkbox v-for="(userAccessTag, index) in selectedUserAccessTags" :key="index" :label="getUserTagName(userAccessTag.name)" :value="userAccessTag.id" v-model="selectedUserAccessTagsIds" :ripple="false" color="#1F3370"></v-checkbox>
      </div>
      <div :class="(isAddOn && selectedUserAccessTagsIds.length > 5) || viewAllEnabledForUserTags ? 'action-btns' : ''">
        <p v-if="(isAddOn && selectedUserAccessTagsIds.length > 5) || viewAllEnabledForUserTags" class="view-all" @click="onViewAll('userTag')">{{ $t('admin_planner.view_all') }}</p>
        <v-btn data-el="addTag" rounded dark color="#FFB92E" class="add action-btn black--text" @click="onAddTag()"
          ><svg-icon v-if="!isAddOn" icon-class="add-icon" style="margin-right: 8px; width: 18px; height: 19px" />{{ isAddOn ? $t('admin_planner.edit') : $t('admin_planner.add') }}</v-btn
        >
      </div>
    </div>
    <div v-if="adminType === 'global'" class="admin-tags">
      <h3>{{ $t('admin_planner.global_admin_tags_title') }}</h3>
      <p>{{ $t('admin_planner.global_admin_tags_title_sub') }}</p>
    </div>
    <div v-if="adminType === 'group' && employee.permissions.length > 0">
      <h3 class="admin-permission-title">{{ $t('admin_planner.admin_permissions') }}</h3>
      <div class="permission-list">
        <li v-for="(permission, index) in employee.permissions" :key="index">{{ permission.name }}</li>
      </div>
      <p class="add-permission-link">
        {{ $t('admin_planner.permission_link_title1') }} <router-link to="/admin-planner/add-permissions">{{ permissions_table_link }}</router-link> {{ $t('admin_planner.permission_link_title2') }}
      </p>
    </div>
    <v-btn data-el="addTag" rounded dark color="#FFB92E" class="action-btn-add black--text" @click="onSave()" :disabled="getDisabled()">{{ $t('admin_planner.save') }}</v-btn>
    <v-dialog activator="parent" v-if="viewAllContentAccessTags" @click:outside="closeDialog()" content-class="tags-dialog">
      <v-card class="view-all-container">
        <div class="view-all-title">
          <h3>{{ $t('admin_planner.view_all_content_tags_title') }}</h3>
          <svg-icon icon-class="close" style="cursor: pointer" @click="closeDialog()" />
        </div>
        <p>{{ $t('admin_planner.view_all_tags_subtitle') }}</p>
        <div class="view-all-tags-list">
          <div class="scrollable-content">
            <v-checkbox v-for="(contentAccessTag, index) in selectedContentAccessTags" :key="index" :label="contentAccessTag.name" :value="contentAccessTag.id" v-model="selectedContentAccessTagsIds" :ripple="false" color="#1F3370"></v-checkbox>
            <v-checkbox v-for="(contentAccessGroupTag, index) in selectedContentAccessGroupTags" :key="index" :label="contentAccessGroupTag.displayName" :value="contentAccessGroupTag.id" v-model="selectedContentAccessGroupTagsIds" :ripple="false" color="#1F3370"></v-checkbox>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog activator="parent" v-if="viewAllUserAccessTags" @click:outside="closeDialog()" content-class="tags-dialog">
      <v-card class="view-all-container">
        <div class="view-all-title">
          <h3>{{ $t('admin_planner.view_all_user_tags_title') }}</h3>
          <svg-icon icon-class="close" style="cursor: pointer" @click="closeDialog()" />
        </div>
        <p>{{ $t('admin_planner.view_all_tags_subtitle') }}</p>
        <div class="view-all-tags-list">
          <div class="scrollable-content">
            <v-checkbox v-for="(userAccessTag, index) in selectedUserAccessTags" :key="index" :label="getUserTagName(userAccessTag.name)" :value="userAccessTag.id" v-model="selectedUserAccessTagsIds" :ripple="false" color="#1F3370"></v-checkbox>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref } from 'vue';
import { translate } from '@/plugins/i18n.js';
import constantData from '@/api/constant.js';

export default {
  props: {
    adminType: {
      type: String,
      default: () => {
        return '';
      }
    },
    employee: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  setup(props) {
    const contenAccessTags = ref([]);
    const contenAccessGroupTags = ref([]);
    const userAccessTags = ref([]);
    const selectedContentAccessTagsIds = ref([]);
    const selectedUserAccessTagsIds = ref([]);
    const selectedContentAccessTags = ref([]);
    const selectedContentAccessGroupTagsIds = ref([]);
    const selectedContentAccessGroupTags = ref([]);
    const selectedUserAccessTags = ref([]);
    const adminPermissions = ref([]);
    const isAddOn = ref(false);
    const viewAllUserAccessTags = ref(false);
    const viewAllContentAccessTags = ref(false);
    const dataReady = ref(false);
    const contentQuery = ref('');
    const userTagQuery = ref('');
    const role = ref('');
    const viewAllEnabledForContentTags = ref(false);
    const viewAllEnabledForUserTags = ref(false);
    const permissions_table_link = ref('');
    return {
      props,
      contenAccessTags,
      contenAccessGroupTags,
      selectedContentAccessTags,
      selectedContentAccessTagsIds,
      selectedContentAccessGroupTagsIds,
      selectedContentAccessGroupTags,
      adminPermissions,
      isAddOn,
      selectedUserAccessTags,
      selectedUserAccessTagsIds,
      userAccessTags,
      viewAllUserAccessTags,
      viewAllContentAccessTags,
      dataReady,
      contentQuery,
      userTagQuery,
      role,
      viewAllEnabledForContentTags,
      viewAllEnabledForUserTags,
      permissions_table_link
    };
  },
  async mounted() {
    await this.setup();
  },
  watch: {
    adminType() {
      this.selectedContentAccessTags = [];
      this.selectedContentAccessTagsIds = [];
      this.selectedUserAccessTags = [];
      this.selectedUserAccessTagsIds = [];
      this.viewAllSelectedTagsList = [];
      this.viewAllSelectedTagsListIds = [];
      this.selectedContentAccessGroupTags = [];
      this.selectedContentAccessGroupTagsIds = [];
      this.isAddOn = false;
      this.viewAllEnabledForContentTags = false;
      this.viewAllEnabledForUserTags = false;
      this.mappingUserData();
    },
    contentQuery() {
      const searchQuery = this.contentQuery ? this.contentQuery.trim().toLocaleLowerCase() : '';
      this.contenAccessTags.map((tag) => {
        const find = tag.name.toLowerCase().indexOf(searchQuery) > -1;
        tag.style = find ? 'display: flex' : 'display: none';
      });
      this.contenAccessGroupTags.map((tag) => {
        const find = tag.name.toLowerCase().indexOf(searchQuery) > -1;
        tag.style = find ? 'display: flex' : 'display: none';
      });
    },
    userTagQuery() {
      const searchQuery = this.userTagQuery ? this.userTagQuery.trim().toLocaleLowerCase() : '';
      this.userAccessTags.map((tag) => {
        const find = tag.name.toLowerCase().indexOf(searchQuery) > -1;
        tag.style = find ? 'display: flex' : 'display: none';
      });
    }
  },
  methods: {
    async setup() {
      const payload = {
        pageNo: 1,
        pageSize: 1000
      };
      const contentTags = await this.$planAdmin.getContentTags(payload);
      const contentGroupTags = await this.$planAdmin.getContentGroupTags(payload);
      this.contenAccessTags = [...contentTags.data];
      this.contenAccessGroupTags = [...contentGroupTags.data];
      this.userAccessTags = await this.$planAdmin.getUserTags(payload);
      this.permissions_table_link = constantData.permissions_table;
      await this.mappingUserData();
      this.dataReady = true;
    },
    async onAddTag() {
      this.isAddOn = !this.isAddOn;
      this.viewAllEnabledForContentTags = this.isAddOn && this.selectedContentAccessTagsIds.length + this.selectedContentAccessGroupTagsIds.length > 5;
      this.viewAllEnabledForUserTags = this.isAddOn && this.selectedUserAccessTagsIds.length > 5;
      await this.tagSelection();
    },
    onViewAll(type) {
      this.viewAllUserAccessTags = type === 'userTag';
      this.viewAllContentAccessTags = type === 'contentTag';
    },
    async closeDialog() {
      this.viewAllUserAccessTags = false;
      this.viewAllContentAccessTags = false;
      this.viewAllEnabledForContentTags = this.isAddOn && this.selectedContentAccessTagsIds.length + this.selectedContentAccessGroupTagsIds.length > 5;
      this.viewAllEnabledForUserTags = this.isAddOn && this.selectedUserAccessTagsIds.length > 5;
      await this.tagSelection();
    },
    async tagSelection() {
      this.selectedContentAccessTags = [];
      this.selectedUserAccessTags = [];
      this.selectedContentAccessGroupTags = [];
      await this.selectedContentAccessTagsIds.forEach((id) => {
        const itemIndex = this.contenAccessTags.findIndex((item) => item.id === id);
        if (!this.selectedContentAccessTags.some((tag) => tag && tag.id === id) && itemIndex >= 0) this.selectedContentAccessTags.push(this.contenAccessTags[itemIndex]);
      });
      await this.selectedUserAccessTagsIds.forEach((id) => {
        const itemIndex = this.userAccessTags.findIndex((item) => item.id === id);
        if (!this.selectedUserAccessTags.some((tag) => tag && tag.id === id) && itemIndex >= 0) this.selectedUserAccessTags.push(this.userAccessTags[itemIndex]);
      });
      await this.selectedContentAccessGroupTagsIds.forEach((id) => {
        const itemIndex = this.contenAccessGroupTags.findIndex((item) => item.id === id);
        if (!this.selectedContentAccessGroupTags.some((tag) => tag && tag.id === id) && itemIndex >= 0) this.selectedContentAccessGroupTags.push(this.contenAccessGroupTags[itemIndex]);
      });
      this.selectedContentAccessTagsIds = this.selectedContentAccessTags.length ? await this.selectedContentAccessTags.map(({ id }) => id) : [];
      this.selectedContentAccessGroupTagsIds = this.selectedContentAccessGroupTags.length ? await this.selectedContentAccessGroupTags.map(({ id }) => id) : [];
      this.selectedUserAccessTagsIds = this.selectedUserAccessTags.length ? await this.selectedUserAccessTags.map(({ id }) => id) : [];
    },
    async onSave() {
      try {
        const role = this.adminType === 'group' ? 'GROUP_ADMIN' : 'GLOBAL_ADMIN';
        const learnerRole = 'LEARNER';
        const roles = [];

        if (this.employee.rolesAsList && Array.isArray(this.employee.rolesAsList)) {
          const employeeRoles = new Set(this.employee.rolesAsList);
          if (employeeRoles.has(learnerRole)) {
            roles.push(learnerRole);
          }
          roles.push(role);
        }

        const payload = {
          functionTagIds: this.employee.functionTags.map(({ id }) => id),
          functionGroupTagIds: this.employee.funtionGroupTags.map(({ id }) => id),
          accessTagIds: this.employee.accessTags.map(({ id }) => id),
          adminFunctionTagIds: role === 'GLOBAL_ADMIN' ? [] : this.selectedContentAccessTagsIds,
          adminFunctionGroupTagIds: role === 'GLOBAL_ADMIN' ? [] : this.selectedContentAccessGroupTagsIds,
          adminAccessTagIds: role === 'GLOBAL_ADMIN' ? [] : this.selectedUserAccessTagsIds,
          roles: roles
        };
        const params = {
          userGuid: this.employee.guid
        };
        const response = await this.$planAdmin.updateUser(params, payload);
        if (response) {
          this.employee.adminAccessTags = response.adminAccessTags;
          this.employee.adminFunctionGroupTags = response.adminFunctionGroupTags;
          this.employee.adminFunctionTags = response.adminFunctionTags;
          this.employee.roles = response.roles;
          this.employee.rolesAsList = response.roles;
          await this.mappingUserData();
          this.showToast(translate('admin_planner.user_update_msg'), 'success', 3000);
        }
      } catch (e) {
        const err = new Error(e);
        throw new Error(err.message);
      }
    },
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });
      this.$toasted.show(msg, options);
    },
    async mappingUserData() {
      this.selectedContentAccessGroupTagsIds = this.adminType ? this.employee.adminFunctionGroupTags.map(({ id }) => id) : [];
      this.selectedContentAccessTagsIds = this.adminType ? this.employee.adminFunctionTags.map(({ id }) => id) : [];
      this.selectedUserAccessTagsIds = this.adminType ? this.employee.adminAccessTags.map(({ id }) => id) : [];
      this.isAddOn = (this.selectedContentAccessGroupTagsIds.length || this.selectedContentAccessTagsIds.length) && this.selectedUserAccessTagsIds.length;
      this.viewAllEnabledForContentTags = this.isAddOn && this.selectedContentAccessTagsIds.length + this.selectedContentAccessGroupTagsIds.length > 5;
      this.viewAllEnabledForUserTags = this.isAddOn && this.selectedUserAccessTagsIds.length > 5;
      if (this.isAddOn) await this.tagSelection();
    },
    getUserTagName(name) {
      if (name && name.length) {
        return name.substring(name.indexOf('__') + 2, name.length);
      }
    },
    getDisabled() {
      if (this.adminType === 'group') {
        return this.adminType === '' || (this.selectedContentAccessGroupTagsIds.length === 0 && this.selectedContentAccessTagsIds.length === 0) || this.selectedUserAccessTagsIds.length === 0;
      } else if (this.adminType === 'global') {
        return this.adminType === '';
      }
    }
  }
};
</script>

<style lang="scss">
.group-admin-container {
  padding: 2rem;
  background-color: #ffffff;
  max-width: 681px;
  border-radius: 24px;
  display: grid;
  gap: 2rem;
  h3 {
    color: #1f3370;
    @include header-font-1-75;
    margin-bottom: 1rem;
  }
  p {
    @include body-font-1-25;
    color: #2d2d2d;
    padding-bottom: 1rem;
    border-bottom: 2px solid #d6d6d6;
    a {
      @include header-font-1-25;
      color: #1f3370;
    }
  }
  .search-user {
    margin: 1rem 0;
    min-height: 2.625rem !important;
    .v-label {
      font-weight: normal;
      font-family: Arial;
      font-size: 0.875rem;
      color: black;
    }
    .v-field--variant-solo {
      background-color: white !important;
      height: 2.3125rem;
      border: 0.125rem solid #e0e1e1;
      display: flex;
      flex-direction: row-reverse;
      padding: 0 1rem;
    }

    .v-field__field,
    .v-field__prepend-inner {
      height: 2.3125rem;
    }
    .v-field__input {
      min-height: unset;
      padding: 0 0 4px;
      height: inherit;
      height: 2.3125rem;

      input {
        margin: 0px;
        &::placeholder {
          @include body-font-bold-0-875;
          letter-spacing: 0.0006rem;
          color: #5c5c5c;
          opacity: 1;
        }
      }
    }
  }
  .search-user .v-input__slot {
    min-height: 2.625rem !important;
    width: 468px;
  }
  .search-user .mdi-magnify {
    font-size: 1.725rem;
  }

  .content-access-tags,
  .content-selected-access-tags {
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid #1f3370;
    height: 248px;
    .v-selection-control__input,
    .v-selection-control__wrapper {
      height: fit-content;
      width: fit-content;
    }
    .v-input__details {
      display: none;
    }
    .v-selection-control .v-label {
      color: #2d2d2d;
      @include body-font-1;
      opacity: 1;
    }
    .v-checkbox .v-selection-control {
      min-height: 2rem !important;
      display: flex;
      gap: 1rem;
      align-items: center;
    }
    .v-selection-control--disabled {
      opacity: 1;
      color: #1f3370;
    }
  }

  .content-selected-access-tags {
    padding: unset;
    border-radius: unset;
    border: unset;
    height: unset;
    display: grid;
    gap: 1rem;
    margin-top: 1rem;
    :nth-child(n + 6) {
      display: none;
    }
    .v-checkbox .v-selection-control {
      min-height: 1.5rem !important;
    }
  }

  .scrollable-content {
    height: -webkit-fill-available;
    overflow-y: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #efefef;
      border: 1px solid #efefef;
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1f3370;
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #1f3370;
    }
  }
  .add {
    .v-btn__content {
      @include body-font-bold-1;
    }
  }
  .add:focus-visible {
    outline: black 2px solid;
    outline-offset: 1px;
  }
  .action-btn {
    box-shadow: none !important;
    padding: 8px 24px;
    margin-top: 8px;
    float: right;
    display: flex;
    align-items: center;
  }
  .admin-permission-title {
    padding-bottom: 1rem;
    border-bottom: 2px solid #d6d6d6;
    margin-bottom: 1rem;
  }
  .permission-list {
    display: grid;
    gap: 1rem;
    margin-bottom: 1rem;
    li {
      @include body-font-1;
      list-style-type: none;
    }
  }
  .admin-tags {
    p {
      border-bottom: unset;
    }
  }
  .action-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .action-btn-add {
    box-shadow: none !important;
    padding: 8px 24px;
    margin-top: 24px;
    float: right;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-left: auto;
    .v-btn__content {
      @include body-font-bold-1;
    }
  }
  .view-all {
    color: #1f3370;
    @include body-font-bold-1;
    border: unset;
    padding: unset;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.v-dialog > .v-overlay__content {
  align-items: center;
}
.v-dialog > .v-overlay__content > .v-card {
  width: 584px;
  padding: 2rem;
  border-radius: 24px;
  .view-all-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
    h3 {
      color: #2d2d2d;
      @include header-font-1-75;
    }
  }
  .view-all-tags-list {
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid #1f3370;
    height: 472px;
    margin: 2rem 0 0;

    .v-selection-control__input,
    .v-selection-control__wrapper {
      height: fit-content;
      width: fit-content;
    }
    .v-input__details {
      display: none;
    }
    .v-selection-control .v-label {
      color: #2d2d2d;
      @include body-font-1;
      opacity: 1;
    }
    .v-checkbox .v-selection-control {
      min-height: 2rem !important;
      display: flex;
      gap: 1rem;
      align-items: flex-start;
    }
    .v-selection-control--disabled {
      opacity: 1;
      color: #1f3370;
    }
  }
  .scrollable-content {
    height: 442px;
    overflow-y: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #efefef;
      border: 1px solid #efefef;
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1f3370;
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #1f3370;
    }
  }
}
</style>
