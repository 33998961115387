import axios from 'axios';
import { getMetaData } from '../utils/helper';

export default {
  async login(url, payload) {
    const headers = payload.trustedDeviceId ? { 'Content-Type': 'application/json', 'X-AUTH-TRUSTED-DEVICE-ID': payload.trustedDeviceId } : { 'Content-Type': 'application/json' };
    const response = await axios.post(url, payload, { headers: headers }, { withCredentials: true }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async getUserInfo(url) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.get(url, { headers: tokenHeader }, { withCredentials: true }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicAuthGetRequest(url, payload) {
    const headers = { Authorization: 'Bearer ' + payload.token };
    const response = await axios.get(url, { headers: headers }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicAuthPostRequest(url, payload) {
    const { contentTypeHeader } = await getMetaData();
    const response = await axios.post(url, payload, { headers: contentTypeHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async activateUser(url, payload) {
    const { contentTypeHeader } = await getMetaData();
    const response = await axios.put(url, payload, { headers: contentTypeHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async verifyToLogin(url, payload) {
    const { contentTypeHeader } = await getMetaData();
    const response = await axios.put(url, payload, { headers: contentTypeHeader }, { withCredentials: true }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async checkSSOByEmail(url) {
    const response = await axios.get(url).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async ssoUserAcceptTerms(url) {
    const { jsonHeaders } = await getMetaData();
    const response = await axios.put(url, {}, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  }
};
