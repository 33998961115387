<template>
  <div>
    <AlertsModal v-if="showAlertsModal" :modalProperties="assetEmptyMessage" @closeAlertsModal="closeModal"> </AlertsModal>
    <div v-if="!dataReady" class="loader t-flex t-justify-center t-items-center t-h-96">
      <img src="@/assets/images/icons/plan-loading.gif" width="150" alt="Loading Dashboard" />
    </div>
    <div v-if="dataReady">
      <div class="header-container t-flex t-items-center t-mt-16 t-mx-16">
        <div class="review-details-container t-text-3xl t-rounded-2xl t-opacity-100">
          <div class="page-info-container t-mb-4 t-flex t-items-center">
            <button :class="!disableBackButton ? 't-cursor-pointer' : ''" class="t-mr-4 t-text-left t-uppercase t-text-sm t-font-bold t-text-demi-gray" @click="goBack()" tabindex="0" aria-label="back button">
              <svg-icon icon-class="icon-arrow-left" class="g-icon--small"></svg-icon>
              {{ $t('button.back') }}
            </button>
            <div class="t-mr-4 t-text-sm t-bg-color-light-orange-red t-px-2 t-rounded-lg t-font-bold t-uppercase">{{ $t('manageReviewDetail.assetReview') }}</div>
            <div class="status-badge t-text-sm t-px-2 t-rounded-lg t-font-bold t-uppercase" :class="getBgvColorClass()">
              {{ editMode || (submittedAsset.isAdminEdited && submittedAsset.reviewStatus.toLowerCase() == 'submitted') ? 'REVIEW' : submittedAsset.reviewStatus.toUpperCase() == reviewStatusObj.InEdit.value ? reviewStatusObj.InEdit.display : submittedAsset.reviewStatus }}
            </div>
          </div>
          <div class="t-mb-4 t-px-6 t-py-0">
            <h1 class="t-text-primary-color t-font-futura-pt-demi t-font-normal t-break-all t-text-5xl">{{ submittedAsset.name }}</h1>
          </div>
          <div class="other-asset-details t-flex t-items-center t-text-xs t-px-6 t-py-0 t-mb-4">
            <div class="asset-id">
              <span class="t-font-bold">{{ $t('manageReviewDetail.id') }} </span>{{ submittedAsset.displayId }}
            </div>
            <div class="divider t-m-1 t-h-3"></div>
            <div class="asset-submitter-name">
              <span class="t-font-bold">{{ $t('manageReviewDetail.submittedBy') }} </span>{{ submittedAsset.submitUser.firstName }} {{ submittedAsset.submitUser.lastName }}
            </div>
            <div class="divider t-m-1 t-h-3"></div>
            <div class="asse-last-updated">
              <span class="t-font-bold">{{ $t('manageReviewDetail.lastUpdated') }} </span>{{ localizedDate24Hour(moment, submittedAsset.updatedAt) }}
            </div>
          </div>
        </div>
        <AssetReviewStatus :status="submittedAsset.reviewStatus" :adminEdited="submittedAsset.isAdminEdited" :editMode="editMode"></AssetReviewStatus>
      </div>
      <div class="main-container t-flex t-mx-16 t-my-4 t-place-items-start">
        <ManageReviewDetailContent v-if="!editMode" :submittedAsset="submittedAsset" :functionsList="functionsList" @downloadInProgress="downloadStatus"></ManageReviewDetailContent>
        <EditSubmissionForm ref="editSubmissionComponent" v-if="editMode" :functionsList="functionsList" :submittedAsset="submittedAsset" @setEditMode="setEditMode" @downloadInProgress="downloadStatus"> </EditSubmissionForm>
        <div class="review-action-container t-p-4 t-text-2xl t-ml-8 t-opacity-100 t-h-auto t-rounded-2xl">
          <ReviewActions v-if="!editMode" :submittedAsset="submittedAsset" @setEditMode="setEditMode" @showAlerts="showAlertsModal = true"></ReviewActions>
          <ActivitiesAndComments v-if="submittedAsset.activities" :activities="submittedAsset.activities" :reviewStatus="submittedAsset.reviewStatus.toLowerCase()"></ActivitiesAndComments>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssetReviewStatus from '@/views/manage-submissions/components/AssetReviewStatus';
import ManageReviewDetailContent from '@/views/manage-submissions/components/ManageReviewDetailContent';
import ReviewActions from '@/views/manage-submissions/components/ReviewActions';
import ActivitiesAndComments from '@/views/manage-submissions/components/ActivitiesAndComments';
import EditSubmissionForm from '@/views/manage-submissions/components/EditSubmissionForm';
import AlertsModal from '@/views/manage-submissions/components/AlertsModal';
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import { localizedDate24Hour } from '@/utils';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'ManageReviewDetail',
  components: { AssetReviewStatus, ManageReviewDetailContent, ReviewActions, ActivitiesAndComments, EditSubmissionForm, AlertsModal },
  data() {
    return {
      disableBackButton: false,
      assetEmptyMessage: {
        header: translate('manageReviewDetail.assetEmptyHeader'),
        message: translate('manageReviewDetail.assetEmptyMessage'),
        btnText: 'Ok',
        buttons: [],
        type: 'error'
      },
      showAlertsModal: false,
      assetId: '',
      submittedAsset: {},
      dataReady: false,
      reviewStatusClass: {
        submitted: 'submitted-bgv',
        resubmitted: 'submitted-bgv',
        returned: 'returned-bgv',
        rejected: 'rejected-bgv',
        approved: 'approved-bgv',
        'in edit': 'submitted-bgv'
      },
      reviewStatusObj: {
        edited: { value: 'EDITED', message: 'Edited' },
        InEdit: { value: 'IN EDIT', display: 'returned' },
        InDraft: { value: 'IN DRAFT', display: 'in draft' },
        returned: { value: 'RETURNED', message: 'Returned' },
        approved: { value: 'APPROVED', message: 'Approved' },
        rejected: { value: 'REJECTED', message: 'Rejected' }
      },
      userGuid: '',
      editMode: false,
      toPath: '',
      navigateNext: false,
      functionsList: []
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.navigateNext) {
      next();
    } else {
      const path = ['/asset-review-details'].some((item) => {
        return to.path.includes(item);
      });
      if (!path && this.editMode) {
        this.toPath = to.path;
        const flag = this.$refs.editSubmissionComponent.prepareToExit(to, from, next);
        flag ? next(false) : next();
      } else {
        next();
      }
    }
  },
  computed: {
    ...mapGetters(['user_guid'])
  },
  async mounted() {
    this.assetId = this.$route.params.id;
    this.functionsList = await this.$planAdmin.getGovernanceFunctions();
    this.getAssetItemById();
    this.userGuid = (await localforage.getItem('my_user_guid')) || this.user_guid;
  },
  methods: {
    localizedDate24Hour,
    goBack() {
      if (!this.disableBackButton) {
        this.$router.push('/manage-submissions');
      }
    },
    downloadStatus(flag) {
      this.disableBackButton = flag;
    },
    closeModal() {
      this.showAlertsModal = false;
    },
    async setEditMode(flag, tabNavigation) {
      await this.getAssetItemById();
      this.navigateNext = false;
      if (flag) {
        this.submittedAsset.toolsList = await this.$planAdmin.getGovernanceTools();
        const inventoryTypeList = await this.$planAdmin.getAssetInventoryTypes();
        this.submittedAsset.inventoryTypeList = [];
        inventoryTypeList.forEach((el) => this.submittedAsset.inventoryTypeList.push(el.name));
        this.editMode = flag;
      } else {
        this.editMode = flag;
        if (tabNavigation) {
          this.navigateNext = tabNavigation;
          typeof tabNavigation === 'boolean' && tabNavigation && this.toPath ? this.$router.push({ path: this.toPath }) : this.$router.push('/manage-submissions');
        }
      }
    },
    async getAssetItemById() {
      this.submittedAsset = await this.$planAdmin.getAssetItemById({ id: this.assetId });
      if ({}.hasOwnProperty.call(this.submittedAsset, 'galleryImages') && this.submittedAsset.galleryImages?.length) {
        this.submittedAsset.galleryImages = await this.submittedAsset.galleryImages.map((image) => {
          return { caption: image.caption, signedUrl: image.signedUrl, type: 'image', size: image.size, pointer: image.image };
        });
      }
      this.submittedAsset.supportingFile = this.submittedAsset.supportingFile && {}.hasOwnProperty.call(this.submittedAsset, 'supportingFile') && Object.keys(this.submittedAsset.supportingFile)?.length > 0 ? this.submittedAsset.supportingFile : '';
      this.submittedAsset.applicationsVM = await this.submittedAsset.applicationsVM.map((application) => {
        return {}.hasOwnProperty.call(application, 'name') ? { name: application?.name, id: application?.id, image: application?.signedUrl } : { name: application, image: null };
      });

      this.submittedAsset.functions = this.submittedAsset.functions && {}.hasOwnProperty.call(this.submittedAsset, 'functions') && this.submittedAsset.functions !== null ? this.submittedAsset.functions : [];

      if ({}.hasOwnProperty.call(this.submittedAsset, 'activities') && this.submittedAsset.activities?.length && this.submittedAsset.reviewStatus.toUpperCase() !== this.reviewStatusObj.InDraft.value) {
        this.submittedAsset.activities = this.submittedAsset.activities.sort(this.compareDate);
        this.submittedAsset.activities = await this.submittedAsset.activities.map((activity, index) => {
          let isEditActivity = false;
          if (
            this.submittedAsset.activities[index + 1] &&
            this.submittedAsset.activities[index + 1].activityType?.toLowerCase() == 'edited' &&
            [this.reviewStatusObj.returned.value, this.reviewStatusObj.approved.value, this.reviewStatusObj.rejected.value].includes(activity.activityType?.toUpperCase()) &&
            this.submittedAsset.isAdminEdited
          ) {
            isEditActivity = true;
          }
          if (activity.userGuid === this.userGuid) {
            return {
              nameWithAction: isEditActivity ? `You ${activity.activityType?.toLowerCase()} the asset with edits` : `You ${activity.activityType?.toLowerCase()} the asset`,
              shortName: activity.userName
                .match(/\b(\w)/g)
                .join('')
                .toUpperCase(),
              createdAt: activity.createdAt,
              comment: activity.text
            };
          } else {
            return {
              userNameWithAction: isEditActivity ? `${activity.userName} ${activity.activityType?.toLowerCase()} the asset with edits` : `${activity.userName}  ${activity.activityType?.toLowerCase()} the asset`,
              shortName: activity.userName
                .match(/\b(\w)/g)
                .join('')
                .toUpperCase(),
              createdAt: activity.createdAt,
              comment: activity.text
            };
          }
        });
      }
      this.dataReady = true;
    },
    compareDate(dateA, dateB) {
      const aStartedAt = new Date(dateA.createdAt);
      const bStartedAt = new Date(dateB.createdAt);
      if (aStartedAt > bStartedAt) return -1;
      if (aStartedAt < bStartedAt) return 1;
      return 0;
    },
    getBgvColorClass() {
      return this.reviewStatusClass[this.submittedAsset.reviewStatus.toUpperCase() == this.reviewStatusObj.InEdit.value ? this.reviewStatusObj.InEdit.display : this.submittedAsset.reviewStatus.toLowerCase()] || 'submitted-bgv';
    }
  },
  deactivated() {
    this.submittedAsset = {};
    this.assetId = '';
    this.dataReady = false;
    this.userGuid = '';
    this.editMode = false;
    this.toPath = '';
    this.navigateNext = false;
  }
};
</script>

<style lang="scss" scoped>
.header-container > .review-details-container {
  width: 66%;

  .page-info-container {
    .status-badge {
      &.submitted-bgv {
        background: #fdb931;
      }

      &.rejected-bgv {
        background: #e47545;
      }

      &.returned-bgv {
        background: #f9dda5;
      }

      &.approved-bgv {
        background: #33dac0;
      }
    }
  }

  .other-asset-details {
    .divider {
      border: 1px solid #e0e1e1;
    }
  }
}

.main-container > .review-action-container {
  width: calc(33% - 2rem);
  background: #f8f8f8;
  border: 1px solid #f8f8f800;

  .v-list {
    background: #f8f8f8;
  }
}
</style>
