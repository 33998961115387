import axios from 'axios';
import { getMetaData } from '../utils/helper';
export default {
  async basicShareGetRequest(url) {
    const { authorizationHeader } = await getMetaData();
    const response = await axios.get(url, { headers: authorizationHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicShareGetRequestWithBlob(url) {
    const { authJsonHeader } = await getMetaData();
    const response = await axios({ url: url, method: 'GET', responseType: 'blob', headers: authJsonHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return new Blob([response.data]);
  },
  async basicSharePostRequest(url, payload) {
    const { authJsonHeader } = await getMetaData();
    const response = await axios.post(url, payload, { headers: authJsonHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicSharePutRequest(url, payload) {
    const { jsonHeaders } = await getMetaData();
    const response = await axios.put(url, payload, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicShareDeleteRequestPayload(url, payload) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.delete(url, { data: payload, headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicSharePostRequestNoPayload(url) {
    const { authJsonHeader } = await getMetaData();
    const response = await axios.post(url, {}, { headers: authJsonHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicShareDeleteRequest(url) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.delete(url, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicShareDeleteRequestNoBearer(url) {
    const { authorizationHeader } = await getMetaData();
    const response = await axios.delete(url, { headers: authorizationHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response;
  },
  async downloadAssetZipFile(url, controller, onDownloadProgress) {
    const { authorizationHeader } = await getMetaData();
    const response = await axios.get(url, { headers: authorizationHeader, responseType: 'blob', signal: controller.signal, onDownloadProgress: onDownloadProgress });
    return response;
  },
  async submitAsset(url, payload) {
    const { authFormHeaders } = await getMetaData();
    return await axios.post(url, payload.body, { headers: authFormHeaders }).catch((error) => {
      return { data: error.response.data };
    });
  },
  async uploadAssets(url, payload, controller, onUploadProgress) {
    const { authFormHeaders } = await getMetaData();
    const response = await axios.post(url, payload.body, { headers: authFormHeaders, signal: controller.signal, onUploadProgress: onUploadProgress });
    return response;
  },
  async uploadAnnouncementFile(url, payload) {
    const { authFormHeaders } = await getMetaData();
    const response = await axios.post(url, payload.body, { headers: authFormHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response;
  },

  async uploadSkillExtractionFile(url, payload) {
    const { formHeaders } = await getMetaData();
    const response = await axios.post(url, payload.body, { headers: formHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },

  async getRequestSkillExtraction(url) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.get(url, { headers: tokenHeader }).catch((error) => {
      return { data: error };
    });
    return response.data;
  },

  async postRequestSkillExtraction(url, payload) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.post(url, payload, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },

  async putRequestSkillExtraction(url, payload) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.put(url, payload, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },

  async postDownloadFile(url, payload) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.post(url, payload, { headers: { ...tokenHeader, accept: '*/*' }, exposedHeaders: ['content-disposition'] }).catch((error) => {
      return { data: error.response.data };
    });
    return response;
  }
};
