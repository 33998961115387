<template>
  <div class="csv-download-container">
    <div class="csv-download-content">
      <p>{{ $t('insights.content.orgConsumptionSummary') }}</p>
      <v-btn name="download" class="ConsumptionFocus" aria-label="download Org Consumption Summary" title="download Org Consumption Summary" @click="getPeopleListForCSV()" icon><svg-icon icon-class="CSV_icon" class="t-w-8 t-h-8" /></v-btn>
    </div>

    <json2csv :data="peopleListForCSV" :labels="labelsForCSV" :title="`${$t('insights.content.orgConsumptionSummary')}_${orgNameInFocus}_${generateCSVTimeFilter(timeFilter)}_${getDateForCSV()}`" v-if="peopleListForCSV && peopleListForCSV.length">
      <button name="download" ref="csvBtn"></button>
    </json2csv>

    <div v-if="!isC1" class="csv-download-content">
      <p>{{ $t('insights.content.orgSkillsReport') }}</p>
      <v-btn
        name="download"
        aria-label="download Skills Summary"
        title="download Skills Summary button"
        class="ConsumptionFocus download-button"
        data-el="planStatusDownloadSkillReportXlsBtn"
        @click="onDownloadSummary('xls')"
        v-track="'planStatus,buttonDownloadSkillReportXls,click'"
        icon
      >
        <svg-icon icon-class="CSV_icon" class="t-w-8 t-h-8" />
      </v-btn>
    </div>
  </div>
</template>
<script>
import JSON2CSV from '@/components/molecules/JSON2CSV';
import localforage from 'localforage';
import { getDateForCSV, generateCSVTimeFilter, convertHourMinuteStringtoDecimal } from '@/utils';
import { translate } from '@/plugins/i18n.js';

const initialState = () => ({
  labelsForCSV: {},
  peopleListForCSV: []
});

export default {
  name: 'ContentCSVDownload',
  components: {
    json2csv: JSON2CSV
  },
  props: {
    orgNameInFocus: {
      type: String,
      default() {
        return '';
      }
    },
    timeFilter: {
      type: String,
      default() {
        return '';
      }
    },
    drillDownTableFilters: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    searchInputText: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    orgInFocus: {
      type: Number,
      required: true,
      default() {
        return 0;
      }
    },
    skillOrgInFocus: {
      type: Number,
      required: false,
      default() {
        return 0;
      }
    },
    isC1: {
      type: Boolean,
      required: true,
      default() {
        return false;
      }
    },
    dateRange: {
      type: Object,
      required: false
    }
  },
  data() {
    return initialState();
  },

  async mounted() {
    Object.assign(this.$data, initialState());
    this.$data.labelsForCSV = {
      organizationName: { title: translate('insights.content.drilldownHeaders.organizationName') },
      employeeId: { title: 'Employee Id' },
      name: { title: translate('insights.content.drilldownHeaders.name') },
      email: { title: translate('insights.content.drilldownHeaders.email') },
      title: { title: translate('insights.content.drilldownHeaders.title') },
      role: { title: translate('insights.content.drilldownHeaders.role') },
      //this might need to be re-instated, so keep it for now
      overallCompletedTimeSpent: { title: translate('insights.content.drilldownHeaders.overallCompletedTimeSpentC2') },
      credentialsInProgress: { title: translate('insights.content.drilldownCSVHeaders.credentialsInProgress') },
      credentialsCompleted: { title: translate('insights.content.drilldownCSVHeaders.credentialsCompleted') },
      coursesInProgress: { title: translate('insights.content.drilldownCSVHeaders.coursesInProgress') },
      coursesCompleted: { title: translate('insights.content.drilldownCSVHeaders.coursesCompleted') },
      audiosInProgress: { title: translate('insights.content.drilldownCSVHeaders.audiosInProgress') },
      audiosCompleted: { title: translate('insights.content.drilldownCSVHeaders.audiosCompleted') },
      videosInProgress: { title: translate('insights.content.drilldownCSVHeaders.videosInProgress') },
      videosCompleted: { title: translate('insights.content.drilldownCSVHeaders.videosCompleted') },
      articlesInProgress: { title: translate('insights.content.drilldownCSVHeaders.articlesInProgress') },
      articlesCompleted: { title: translate('insights.content.drilldownCSVHeaders.articlesCompleted') },
      trackItems: { title: translate('insights.content.drilldownCSVHeaders.trackItems') },
      trackItemsCompleted: { title: translate('insights.content.drilldownCSVHeaders.trackItemsCompleted') },
      completedTimeSpent: { title: translate('insights.content.drilldownCSVHeaders.completedLearningHrs') },
      inProgressTimeSpent: { title: translate('insights.content.drilldownCSVHeaders.inProgressLearningHrs') },
      totalLearning: { title: translate('insights.content.drilldownCSVHeaders.totalLearningHrs') },
      progress: { title: translate('insights.content.drilldownCSVHeaders.progressC2') }
    };
  },
  methods: {
    getDateForCSV,
    generateCSVTimeFilter,
    async onDownloadSummary(type) {
      this.isDownloaded = true;

      const payload = { orgId: this.skillOrgInFocus, type: type };
      const filename = `Skills Report_${this.isC1 ? 'All Orgs_' : ''}${generateCSVTimeFilter(this.timeFilter)}_${getDateForCSV()}.xls`;
      const blob = await this.$planAdmin.getSkillSummaryReport(payload);

      if (!blob.isEmailed) {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob.data);
        a.setAttribute('download', filename);
        a.click();
      } else {
        // May have to create a TOAST MESSAGE
        // this.notification.available = true
        // this.notification.type = 'warning'
        // this.notification.message = 'The report has been emailed to you!'
      }
    },
    async getPeopleListForCSV() {
      const titles = [];
      const roles = [];
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      for (let filter of this.drillDownTableFilters) {
        switch (filter.type) {
          case 'Job Title':
            titles.push(filter.name);
            break;
          case 'Role':
            roles.push(filter.name);
            break;
          default:
            break;
        }
      }

      const reqParams = {
        timeFilter: this.timeFilter || 'ALLTIME',
        tenantGuid: tenantGuid,
        id: this.orgInFocus
      };

      const payload = {
        pageNo: 0,
        pageSize: 0,
        titles: titles || [],
        roles: roles || [],
        searchText: this.searchInputText || '',
        filterValuesRequired: false,
        startDate: this.timeFilter === 'CUSTOM' ? this.dateRange.startDate : '',
        endDate: this.timeFilter === 'CUSTOM' ? this.dateRange.endDate : '',
        dateFormat: 'MM-dd-yyyy'
      };

      if (this.isC1) {
        this.$emit('downloadParentCSV', {});
      } else {
        const apiCall = this.orgInFocus ? this.$planAdmin.getConsumptionDetailAllEmployeesFromOrg(reqParams, payload) : this.$planAdmin.getConsumptionDetailAllEmployees(reqParams, payload);
        await apiCall.then((res) => {
          const peopleListForCSV = res;
          //check for null or undefined
          peopleListForCSV.learnerDetails.forEach(function (employee) {
            for (let i in employee) {
              if (employee[i] === undefined || employee[i] === null) {
                employee[i] = '';
              }
            }
            employee.inProgressTimeSpent = convertHourMinuteStringtoDecimal(employee.inProgressTimeSpent);
            employee.completedTimeSpent = convertHourMinuteStringtoDecimal(employee.completedTimeSpent);
            employee.totalLearning = convertHourMinuteStringtoDecimal(employee.totalLearning);
            employee.overallCompletedTimeSpent = convertHourMinuteStringtoDecimal(employee.overallCompletedTimeSpent);
          });
          this.peopleListForCSV = peopleListForCSV.learnerDetails || [];
          setTimeout(() => (this.$refs.csvBtn ? this.$refs.csvBtn.click() : ''), 200);
        });
      }
    }
  }
};
</script>

<style lang="scss">
.theme--light.v-btn.v-btn--icon:focus-visible {
  outline: black 2px solid;
  border-radius: unset;
  color: white;
}
.ConsumptionFocus:focus-visible {
  height: 32px;
  width: 32px;
  margin: 2px;
}
.consumption-dashboard-container .dashboard-details-table-container .consumption-details-treeview-container .dashboard-details-treeview .v-treeview-node__root .v-icon:focus-visible {
  outline: black 2px solid;
  border-radius: unset;
}
.csv-download-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  align-items: center;
  .csv-download-content {
    display: flex;
    align-items: center;
    p {
      margin: 0rem 1rem 0rem 0rem;
    }
    &:nth-of-type(2) {
      margin-left: 2rem;
    }
  }
}
</style>
