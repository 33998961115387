<template>
  <div v-if="dataReady" class="container-fluid edit-content-container">
    <v-row>
      <SaveDialog :saveDialog="saveDialog" :disableSaveButton="trackData.length === 0" @cancel-and-navigate="cancelAndNavigate()" @cancel-navigation="cancelNavigation()" />
      <RefreshTrackDialog :refreshDialog="refreshDialog" @refresh-confirm="refreshLearningTrack()" @refresh-cancel="refreshDialog = !refreshDialog" />
      <NotificationDialog :notificationDialog="notificationDialog" copy="selectionContinueMessageUpdateContent" @save-navigate="saveAndNavigate" @cancel-navigation="cancelNavigation()" />

      <v-col class="create-learning-track-left-column" cols="9 t-pr-9">
        <h2 class="t-text-4xl t-font-futura-pt-demi t-font-normal t-text-black">{{ $t('track.editContent') }}</h2>
        <v-row>
          <v-col cols="9">
            <p v-if="isSearchAvailable && !trackResultsLoaded">
              <i18n-t class="t-text-base t-font-normal" keypath="track.search">
                <span class="t-text-base t-font-normal search-query">{{ this.searchQuery }}</span>
              </i18n-t>
            </p>

            <p v-else-if="isSearchAvailable && trackResultsLoaded">
              <i18n-t class="t-text-base t-font-normal" keypath="track.results">
                <span class="t-text-base t-font-normal search-query">{{ this.searchQuery }}</span>
              </i18n-t>

              <feature-flag name="plan_show_search_count">
                <p class="t-mb-0 t-text-base">{{ $t('track.resultsCount', { current: this.catalogDisplay.length, total: this.totalResults }) }}</p>
              </feature-flag>
            </p>

            <p class="t-text-black" v-else-if="!isSearchAvailable && catalogDisplay && catalogDisplay.length">
              {{ $t('track.browseRecommendedContent') }}

              <v-tooltip content-class="edit-content-skills-tooltip" bottom>
                <template v-slot:activator="{ props }">
                  <span class="t-underline t-cursor-pointer t-text-primary-color t-text-base t-font-normal" v-bind="props">{{ $t('track.theseSkills') }} </span>
                </template>
                <span>{{ this.skills }}</span>
              </v-tooltip>
            </p>
            <p class="t-text-black" v-else>{{ $t('track.noContent') }}</p>
          </v-col>
        </v-row>
        <div class="t-grid search-filter-container">
          <Search ref="searchComponent" :ccc_items="trackData" @search-applied="onSearchApplied" />
          <SearchFilter @filter-applied="applyFilters" :selectedEditFilters="displaySelectedFilters" :filterResults="filterResults" :catalogDisplay="catalogDisplay?.length" :totalResults="totalResults" @track-filters-interface="getChildInterface" />
        </div>
        <v-row v-if="showFilters">
          <v-col>
            <span v-for="(item, index) in displaySelectedFilters" :key="index + item.name" id="chips-container">
              <v-chip in class="chip t-text-xs ma-2 t-bg-black" closable @click:close="removeFilter(item)"> {{ item.type === 'Language' || item.type === 'Content Type' || item.type === 'Level' || item.type === 'Duration' ? item.display : item.name }} </v-chip>
            </span>
            <span style="cursor: pointer; display: inline-block" @click="clearFilters()">{{ $t('common.titleCase.clearAll') }}</span>
          </v-col>
        </v-row>
        <v-row id="learning-track-container" ref="learning-track-container-ref" class="scrollable t-overflow-y-scroll t-mt-8">
          <CatalogCard :catalogDisplay="catalogDisplay" :trackResultsLoaded="trackResultsLoaded" @add-track-item="addItemToLearningTrack" />
          <div v-if="!moreTrackResultsLoaded" class="t-grid track-catalog-card-container t-justify-center t-w-full" style="width: 100%">
            <Skeleton v-for="i in columnSize" :key="i" />
          </div>
        </v-row>
      </v-col>

      <v-col class="create-learning-track-right-column" cols="3">
        <div class="t-px-8">
          <span v-if="roleGuid" class="t-text-sm mt-4 t-underline t-cursor-pointer t-text-primary-color t-font-normal" id="roleDetails" @click="roleDetails()"
            ><span class="t-font-bold t-text-sm t-text-primary-color">{{ $tc('common.titleCase.role') }}:</span> {{ this.roleName }}</span
          >
          <div class="t-mt-6 t-mb-4 t-pr-10">
            <div>
              <span class="t-text-xs">{{ $t('track.name') }}</span>
            </div>
            <h2 class="t-text-2xl t-leading-7 t-font-futura-pt-demi create-track-name">{{ this.trackName }}</h2>
          </div>
          <div class="organize-container t-flex t-justify-start t-mb-6">
            <button
              id="organize-track-button"
              @click="organizeTrack()"
              :disabled="trackData.length === 0"
              class="t-color-white t-h-8 min-w-min t-px-6 t-rounded-full t-font-bold t-bg-primary-color t-text-white t-text-xs t-border-yellow-400 hover:t-opacity-90 learning-track-button"
              :class="trackData.length ? '' : 't-bg-gray-400'"
            >
              {{ $t('track.organizeTrack') }}
            </button>
          </div>
          <div class="learning-time mt-4" style="display: flex; align-items: center">
            <span>
              <v-icon class="t-text-base t-mr-0.5 t-text-black">{{ 'mdi-content-copy' }}</v-icon>
              {{ numberWithCommas(this.trackData.length) }} {{ this.trackData.length === 1 ? $t('catalog.item') : $t('catalog.items') }}
            </span>
            <span class="ml-8"
              ><v-icon class="t-text-base t-mr-0.5 t-text-black">{{ 'mdi-clock-outline' }}</v-icon
              >{{ $sanitize(formatDuration(this.totalDuration)) }}</span
            >
            <v-menu class="ellipsis-menu" bottom right>
              <template v-slot:activator="{ props }">
                <v-btn class="t-ml-auto t-mr-7" dark icon v-bind="props" variant="text">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-if="roleGuid" class="t-cursor-pointer">
                  <v-list-item-title @click="refreshDialog = !refreshDialog">{{ $t('track.refresh') }}</v-list-item-title>
                </v-list-item>

                <v-list-item class="t-cursor-pointer">
                  <v-list-item-title @click="clearLearningTrack">{{ $t('track.clear') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
        <v-col cols="11" class="scrollable draggable-card-container">
          <draggable :list="trackData" :empty-insert-threshold="200" group="tasks" class="t-w-90" style="min-height: 37.5rem" @change="onItemAddition">
            <v-card v-for="(data, index) in trackData" :key="index" class="col-12 t-mb-8 t-pl-6 t-pt-6 t-pr-4 t-cursor-move">
              <v-card-text>
                <span class="t-capitalize t-rounded-full t-font-bold t-mb-0.5 t-h-6 t-font-arial t-px-3 t-py-1" :class="dataFormatBg[data.formatType.toLocaleLowerCase()]">{{ getLocalizedContentType(data.formatType) }}</span>
                <span class="t-ml-2 ellipsis-text">{{ data.provider }}</span>
                <v-btn :aria-label="`${data.title} remove from track`" class="track-list-remove" @click="removeTrackItem(index)" icon>
                  <svg-icon icon-class="ico-trash" class-name="icon-large" />
                </v-btn>
              </v-card-text>
              <v-card-title> {{ data.title }} </v-card-title>
            </v-card>
          </draggable>
          <p v-if="!trackData.length" class="t-font-futura-pt-demi t--mt-96 t-text-xl t-text-black t-px-10 t-text-center">{{ $t('track.emptyTrack') }}</p>
        </v-col>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <div class="loader t-h-screen t-flex t-justify-center t-items-center t-relative t-bottom-40">
      <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Learning Track" />
    </div>
  </div>
</template>
<script>
import { VueDraggableNext } from 'vue-draggable-next';
import Skeleton from '../../../components/molecules/Skeleton.vue';
import Search from '@/views/catalog/components/Search';
import SearchFilter from '@/views/catalog/components/SearchFilter';
import CatalogCard from './CatalogCard.vue';
import RefreshTrackDialog from './RefreshTrackDialog.vue';
import SaveDialog from './SaveDialog.vue';
import NotificationDialog from './NotificationDialog.vue';
import { dataFormatBg, mapDurations, learningTrackFormat, formatDuration, getLocalizedContentType, getLocalizedDuration, getLocalizedLevel, inverseMapDurations, numberWithCommas } from '@/utils';
import { mapState, mapGetters, mapActions } from 'vuex';
import localforage from 'localforage';
import { reverseConversion } from '../../../model/reverse-conversion';
import en_json from '@/locales/en-us.json';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'EditContent',
  components: {
    draggable: VueDraggableNext,
    Search,
    Skeleton,
    SearchFilter,
    CatalogCard,
    SaveDialog,
    RefreshTrackDialog,
    NotificationDialog
  },
  computed: {
    ...mapGetters(['tenantAccessTags', 'enabledFeatureFlags']),
    ...mapState('track', ['roleNameTrack', 'trackDetails', 'trackName', 'skillsTrack', 'roleGuid', 'trackGuid', 'trackList', 'trackSaveButtonState', 'trackUnsavedChanges', 'totalItemsInTrack', 'trackDescription', 'isRoleBased', 'trackNameUpdated', 'showNotificationDialog'])
  },
  data() {
    return {
      showFilters: false,
      displaySelectedFilters: [],
      columnSize: 9,
      dataFormatBg,
      mapDurations,
      filterResults: {},
      trackData: [],
      catalogDisplay: [],
      catalogFull: [],
      isSearchAvailable: false,
      dataReady: false,
      trackResultsLoaded: false,
      moreTrackResultsLoaded: true,
      searchQuery: '',
      totalPages: '',
      totalResults: 0,
      showSkills: true,
      roleName: '',
      pageNum: 1,
      trackListScroll: '',
      learningTrackDuration: '',
      totalDuration: 0,
      filters: {},
      trackNameEditContent: '',
      saveDialog: false,
      toPath: '',
      refreshDialog: false,
      notificationDialog: false,
      getLocalizedContentType,
      getLocalizedDuration,
      getLocalizedLevel,
      inverseMapDurations,
      componentIsActive: false,
      navigatedOut: false
    };
  },
  created() {
    if (!Object.keys(this.EventBus.all).includes('save-track-edit-content')) this.EventBus.on('save-track-edit-content', this.saveLearningTrack);
    this.resizeColumnSize();
    window.addEventListener('resize', this.resizeColumnSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeColumnSize);
    this.componentIsActive = false;
    this.resetTrackDetails();
  },
  async activated() {
    this.componentIsActive = true;
    this.navigatedOut = false;
    if (!this.trackGuid) {
      this.$router.push('/catalog/landing');
      return;
    }
    this.toPath = '';
    this.trackNameEditContent = this.trackName;
    if (this.catalogDisplay.length) return;
    this.dataReady = false;

    if (this.$route.query?.isFromOrganize) this.trackData = this.trackList;
    else this.trackData = [];

    this.EventBus.on('search-applied', this.onSearchApplied);
    this.EventBus.on('search-cleared', this.onSearchCleared);
    this.EventBus.on('clear-data', this.clearData);
    this.roleName = this.roleNameTrack || (await localforage.getItem('roleNameTrack'));
    await this.getTrackingResults();
    this.skills = this.skills || this.skillsTrack || (await localforage.getItem('skillsTrack'));
    this.dataReady = true;
    this.trackResultsLoaded = true;
    if (!this.trackGuid) {
      this.setSaveButtonState(false);
      this.setUnsavedChangesState(true);
    }
    setTimeout(() => {
      this.initializeInfiniteScroll();
    }, 500);
    const showNotificationDialog = this.trackGuid ? await this.getLearnerData() : false;
    this.setShowNotificationDialog(showNotificationDialog);
  },
  beforeRouteLeave(to, from, next) {
    document.documentElement.style.overflow = 'scroll';
    document.body.scroll = 'yes';
    if (!['/catalog/stepper/organize'].includes(to.path) && (this.trackUnsavedChanges || this.trackNameUpdated)) {
      this.saveDialog = true;
      this.toPath = to.path;
      next(false);
    } else {
      this.onSearchCleared(true);
      if (to.path !== '/catalog/stepper/organize') {
        this.clearData();
        this.showFilters = false;
      }
      if (!['/catalog/stepper/organize', '/catalog/stepper/manage'].some((path) => path === to.path)) {
        this.setSaveButtonState(true);
      }
      // if we're here it's because we cancelled out in the middle of an edit
      this.navigatedOut = true;
      this.resetTrackDetails();
      next();
    }
  },
  watch: {
    '$i18n.locale'() {
      if (this.componentIsActive) {
        this.getTrackingResults(false, false);
      }
    },
    trackNameEditContent(name) {
      this.setTrackName(name);
    },
    totalDuration(val) {
      this.setTotalDuration(val);
    },
    trackData: {
      deep: true,
      handler: async function () {
        if (this.trackData.length) {
          this.setTrackList(this.trackData);
        }
        this.totalDuration = this.trackData.reduce((previous, current) => {
          return previous + current.duration;
        }, 0);
      }
    },
    trackList(newValue) {
      this.trackData = newValue;
    }
  },
  childInterface: {
    clearFilters: () => {},
    removeFilter: () => {}
  },
  methods: {
    numberWithCommas,
    reverseConversion,
    ...mapActions('track', [
      'setTrackGuid',
      'resetTrackDetails',
      'setTrackName',
      'setTotalDuration',
      'setTrackList',
      'resetTrackList',
      'setSaveButtonState',
      'setUnsavedChangesState',
      'setTotalTrackItems',
      'setTrackDescription',
      'setRoleBased',
      'setTrackNameUpdated',
      'setShowNotificationDialog'
    ]),
    ...mapActions('stepper', ['clearStepperState']),
    resizeColumnSize() {
      this.columnSize = window.innerWidth >= 1920 ? 12 : 9;
    },
    removeFilter(item) {
      this.$options.childInterface.removeFilter(item);
    },
    clearFilters() {
      this.$options.childInterface.clearFilters();
    },
    clearData() {
      this.totalDuration = 0;
      this.catalogDisplay.length = 0;
      this.setTrackName('');
      this.resetTrackList();
    },
    async getLearnerData() {
      const trackDetails = await this.$planAdmin.getTrackDetails(this.trackGuid);
      return trackDetails?.data?.learnersCount > 0 ? true : false;
    },
    async initializeInfiniteScroll() {
      this.trackListScroll = document.getElementById('learning-track-container');
      this.trackListScroll && this.trackListScroll.addEventListener('scroll', this.infiniteScroll, true);
    },
    async infiniteScroll() {
      if (Math.ceil(this.trackListScroll.scrollTop + this.trackListScroll.clientHeight) >= this.trackListScroll.scrollHeight) {
        this.moreTrackResultsLoaded = false;
        if (this.isSearchAvailable && this.pageNum < this.totalPages) {
          const response = await this.$refs.searchComponent.handleSearchInput(this.searchQuery, ++this.pageNum, this.filters);
          this.totalPages = response.totalPages;
          response.results.forEach((item) => {
            this.catalogDisplay.push(item);
          });
        } else if (!this.isSearchAvailable && this.pageNum < this.totalPages) {
          const actionType = Object.keys(this.filters).length ? 'filter' : 'recommend';
          let payload = {
            trackGuid: this.trackGuid,
            roleGuid: this.roleGuid,
            action: actionType,
            pageNum: ++this.pageNum,
            ...this.filters
          };
          if (this.trackData.length) {
            let trackItems = reverseConversion(this.trackData.map((item) => ({ ...item })));
            payload = Object.assign({ ...payload, learning_track: { ccc_items: trackItems, name: this.trackName, totalItemsInTrack: this.totalItemsInTrack, description: this.trackDescription, roleBased: this.isRoleBased } });
          }
          const updatedData = this.trackGuid && this.roleGuid ? await this.$planAdmin.editTrack(payload) : await this.$planAdmin.editSkillBasedTrack(payload);
          this.totalPages = updatedData.totalPages;
          updatedData.catalog.forEach((item) => {
            this.catalogDisplay.push(item);
          });
        }
        this.moreTrackResultsLoaded = true;
      }
    },
    learningTrackFormat,
    formatDuration,
    async addRemoveInfiniteScroll() {
      if (this.$refs['learning-track-container-ref'] && this.trackListScroll) {
        await this.trackListScroll.removeEventListener('scroll', this.infiniteScroll, true);
        this.trackListScroll.scrollTop = 0;
        setTimeout(() => {
          this.trackListScroll.addEventListener('scroll', this.infiniteScroll, true);
        }, 500);
      }
    },
    async getTrackingResults(searchResults, isNotRefreshTrack = true) {
      this.trackResultsLoaded = false;
      let payloadData = {};
      //reset the pageNum in case of recEngine re-run
      if (!isNotRefreshTrack) {
        this.pageNum = 1;
      }
      await this.addRemoveInfiniteScroll();
      if (!this.isSearchAvailable) {
        let payload = {
          roleGuid: this.roleGuid,
          trackGuid: this.trackGuid,
          action: isNotRefreshTrack ? 'recommend' : 'refresh',
          pageNum: this.pageNum
        };

        if (this.trackData.length) {
          let trackItems = reverseConversion(this.trackData.map((item) => ({ ...item })));
          payload = Object.assign({ ...payload, learning_track: { ccc_items: trackItems, name: this.trackName, totalItemsInTrack: this.totalItemsInTrack, description: this.trackDescription, roleBased: this.isRoleBased } });
        }

        const trackRespObject = this.trackGuid && this.roleGuid ? await this.$planAdmin.editTrack(payload).catch(() => this.showErrorToast()) : await this.$planAdmin.editSkillBasedTrack(payload).catch(() => this.showErrorToast());
        // reset the state if we hit cancel before the API came back 63e3810dabbf8833ec82a0de
        var trackIdList = [];
        await trackRespObject?.learning_track?.ccc_items?.map((item) => {
          trackIdList.push(item.id);
        });
        if (this.trackSaveButtonState && {}.hasOwnProperty.call(this.trackDetails, 'skills')) {
          payloadData = {
            function_tags: this.tenantAccessTags,
            pageno: this.pageNum,
            pagesize: 18,
            durations: this.trackDetails.durationFilters,
            publishers: this.trackDetails.publishers,
            levels: this.trackDetails.levelFilters,
            content_types: this.trackDetails.contentTypes,
            accreditations: this.trackDetails.accreditations,
            skills: this.trackDetails.skillName,
            tools: this.trackDetails.technologyFilters,
            languages: this.trackDetails.languageFilter,
            contentFeature: this.trackDetails.contentFeature,
            exclude_artifact_ids: trackIdList,
            sortby: 'newest'
          };
          let editTrackFilters = [];
          if (this.enabledFeatureFlags && this.enabledFeatureFlags['plan_create_track_recommended_catalog_api']) {
            editTrackFilters = await this.$planAdmin.searchRecommendedPlan(payloadData);
          } else {
            editTrackFilters = await this.$planAdmin.searchCCCPlan(payloadData);
          }

          if (trackRespObject && editTrackFilters) {
            trackRespObject.filterValuesMap = editTrackFilters.filterValuesMap;
            trackRespObject.totalPages = editTrackFilters.totalPages;
            trackRespObject.catalog = editTrackFilters.catalog;
          }
          this.displaySelectedFilters = this.trackDetails.displaySelectedFilters;
          this.showFilters = this.displaySelectedFilters.length > 0 ? true : false;
        }

        if (this.navigatedOut) {
          this.setUnsavedChangesState(false);
          this.setSaveButtonState(true);
          this.navigatedOut = false;
          return;
        }
        if (trackRespObject) {
          const learning_track = trackRespObject.learning_track;
          this.trackData = (() => {
            if (learning_track && learning_track.ccc_items && learning_track.ccc_items.length) {
              return learning_track.ccc_items;
            }
            return this.trackList && this.trackList.length && isNotRefreshTrack ? this.trackList : [];
          })();
          const idsToExclude = this.trackData.map((item) => item.id);
          const catologFilter = trackRespObject.catalog?.filter((item) => !idsToExclude.includes(item.id));
          this.filterResults = trackRespObject.filterValuesMap;
          this.totalPages = trackRespObject.totalPages;
          this.catalogFull = this.trackList && this.trackList.length && isNotRefreshTrack ? catologFilter : trackRespObject.catalog;
          this.catalogDisplay = this.catalogFull;
          this.skills = trackRespObject.skills && trackRespObject.skills.join(', ');
          this.totalDuration = learning_track?.total_duration || 0;
          this.trackNameEditContent = this.trackName || learning_track.name || this.roleName + ' Track';
          this.totalResults = trackRespObject.totalResults;
          if (!this.roleGuid && this.trackData.length) {
            this.setUnsavedChangesState(true);
            this.setSaveButtonState(false);
          }

          await Promise.all([this.setTotalTrackItems(learning_track.totalItemsInTrack), this.setTrackDescription(learning_track.description), this.setRoleBased(learning_track.roleBased)]);
          if (learning_track.totalItemsInTrack && this.trackGuid) {
            this.EventBus.emit('step-complete', this.$route.path);
          }
        }
      }
      /***
       * setting the tracking results based on the search flag
       */
      if (this.isSearchAvailable) this.catalogDisplay = searchResults;
      else this.catalogDisplay = this.catalogFull;

      setTimeout(() => {
        this.trackResultsLoaded = true;
      }, 500);
    },
    showErrorToast() {
      this.showToast(translate('track.errorLoadingTrack'), 'error', 3000, false);
      this.$router.push({ name: 'CatalogLanding' });
    },
    async getFilterData() {
      try {
        const durations = [];
        const publishers = [];
        const levels = [];
        const accreditations = [];
        const skills = [];
        const content_types = [];
        const tools = [];
        const languages = [];
        const contentFeature = [];
        const assignedAs = [];
        for (let filter of this.displaySelectedFilters) {
          switch (filter.key) {
            case 'durations':
              durations.push(Object.keys(mapDurations).find((key) => mapDurations[key] === filter.name));
              break;
            case 'publishers':
              publishers.push(filter.name);
              break;
            case 'levels':
              levels.push(filter.name);
              break;
            case 'accreditations':
              accreditations.push(filter.name);
              break;
            case 'skills':
              skills.push(filter.name);
              break;
            case 'content_types':
              content_types.push(filter.name);
              break;
            case 'tools':
              tools.push(filter.name);
              break;
            case 'languages':
              languages.push(filter.name);
              break;
            case 'contentFeature':
              contentFeature.push(filter.name);
              break;
            case 'assignedAs':
              assignedAs.push(filter.name);
              break;
            default:
              break;
          }
        }
        const payload = {
          durations,
          publishers,
          levels,
          content_types,
          accreditations,
          skills,
          tools,
          languages,
          contentFeature,
          assignedAs
        };
        return payload;
      } catch (error) {
        console.log(error);
      }
    },
    async applyFilters(data, showFilters, displaySelectedFilters) {
      displaySelectedFilters.forEach((item) => {
        switch (item.key) {
          case 'durations':
            item.display = getLocalizedDuration(inverseMapDurations[item.name]);
            break;
          case 'levels':
            item.display = getLocalizedLevel(item.name);
            break;
          case 'content_types':
            item.display = getLocalizedContentType(item.name);
            break;
          default:
            break;
        }
      });
      this.displaySelectedFilters = displaySelectedFilters.map((filter) => (filter.type === 'Language' ? { ...filter, display: en_json.languagePicker && en_json.languagePicker[filter.name] ? en_json.languagePicker[filter.name] : filter.name } : filter));
      this.showFilters = showFilters;
      this.trackResultsLoaded = false;
      await this.addRemoveInfiniteScroll();
      this.pageNum = 1;
      this.filters = {};
      await data.forEach((item) => {
        if (item.values.length) {
          this.filters[item.key] = item.values;
          if (item.name == 'Duration') {
            let durations = item.values.map((ele) => {
              return Object.keys(this.mapDurations).find((key) => this.mapDurations[key] === ele);
            });
            this.filters['durations'] = durations;
          }
        }
      });
      if (!this.isSearchAvailable) {
        let payload = {
          roleGuid: this.roleGuid,
          action: 'filter',
          ...this.filters,
          trackGuid: this.trackGuid,
          pageNum: this.pageNum
        };
        if (this.trackData.length) {
          let trackItems = reverseConversion(this.trackData.map((item) => ({ ...item })));
          payload = Object.assign({ ...payload }, { learning_track: { ccc_items: trackItems, name: this.trackName, totalItemsInTrack: this.totalItemsInTrack, description: this.trackDescription, roleBased: this.isRoleBased } });
        }
        const trackingDataList = this.trackGuid && this.roleGuid ? await this.$planAdmin.editTrack(payload) : await this.$planAdmin.editSkillBasedTrack(payload);
        let payloadData = {};
        var trackIdList = [];
        await trackingDataList?.learning_track?.ccc_items?.map((item) => {
          trackIdList.push(item.id);
        });
        if (this.trackSaveButtonState && {}.hasOwnProperty.call(this.trackDetails, 'skills')) {
          let filterPayload = await this.getFilterData();
          payloadData = {
            function_tags: this.tenantAccessTags,
            pageno: this.pageNum,
            pagesize: 18,
            durations: filterPayload.durations,
            publishers: filterPayload.publishers,
            levels: filterPayload.levels,
            content_types: filterPayload.content_types,
            accreditations: filterPayload.accreditations,
            skills: filterPayload.skills,
            tools: filterPayload.tools,
            languages: filterPayload.languages,
            contentFeature: filterPayload.contentFeature,
            exclude_artifact_ids: trackIdList,
            sortby: 'newest'
          };
          let editTrackFilters = [];
          if (this.enabledFeatureFlags && this.enabledFeatureFlags['plan_create_track_recommended_catalog_api']) {
            editTrackFilters = await this.$planAdmin.searchRecommendedPlan(payloadData);
          } else {
            if (this.enabledFeatureFlags && this.enabledFeatureFlags['plan_create_track_recommended_catalog_api']) {
              editTrackFilters = await this.$planAdmin.searchRecommendedPlan(payloadData);
            } else {
              editTrackFilters = await this.$planAdmin.searchCCCPlan(payloadData);
            }
          }
          if (trackingDataList && editTrackFilters) {
            trackingDataList.filterValuesMap = editTrackFilters.filterValuesMap;
            trackingDataList.totalResults = editTrackFilters.totalResults;
            trackingDataList.catalog = editTrackFilters.catalog;
          }
        }

        this.catalogDisplay = trackingDataList.catalog;
        this.filterResults = trackingDataList.filterValuesMap;
        this.totalResults = trackingDataList.totalResults;
      }

      if (this.isSearchAvailable) {
        const response = await this.$refs.searchComponent.handleSearchInput(this.searchQuery, this.pageNum, this.filters);
        this.catalogDisplay = response.results;
        this.totalPages = response.totalPages;
        this.totalResults = response.totalResults;
        this.filterResults = response.filters;
      }
      this.trackResultsLoaded = true;
    },
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });

      this.$toasted.show(msg, options);
    },
    showToastMessage(response) {
      this.$toasted.clear();
      if (response.code == 200) {
        this.showToast(translate('track.saveLearningTrackMessage'), 'success', 3000, false);
      } else {
        this.showToast(translate('track.failureLearningTrackMessage'), 'error', 3000, false);
        this.setSaveButtonState(false);
        this.setUnsavedChangesState(true);
      }
    },
    addItemToLearningTrack(index) {
      /***
       * add the recommended learning item to learning track
       */

      this.setUnsavedChangesState(true);
      this.totalDuration += this.catalogDisplay[index].duration;
      this.trackData.push(this.catalogDisplay[index]);
      this.catalogDisplay.splice(index, 1);
      this.setSaveButtonState(false);
    },
    removeTrackItem(index) {
      /***
       * Need to add the item removed from learning track back to recommended learning item
       */
      this.setUnsavedChangesState(true);
      this.totalDuration -= this.trackData[index].duration;
      this.catalogDisplay.unshift(this.trackData[index]);
      this.trackData.splice(index, 1);
      this.setSaveButtonState(false);
    },
    async refreshLearningTrack() {
      this.trackData = [];
      this.totalDuration = 0;
      this.refreshDialog = !this.refreshDialog;
      await this.getTrackingResults(false, false);
      this.skills = this.skills || this.skillsTrack || (await localforage.getItem('skillsTrack'));

      this.setUnsavedChangesState(true);
      this.trackResultsLoaded = true;
      this.setSaveButtonState(false);
    },
    async clearLearningTrack() {
      await this.trackData.map((item) => {
        this.catalogDisplay.push(item);
      });

      this.trackData = [];
      this.totalDuration = 0;

      this.setUnsavedChangesState(true);
    },
    async onSearchApplied(filterValues, results, queryString, totalPages, totalResults) {
      this.$options.childInterface.clearFilters();
      this.isSearchAvailable = true;
      this.filterResults = filterValues;
      this.searchQuery = queryString;
      this.totalPages = totalPages;
      this.totalResults = totalResults;
      this.pageNum = 1;
      await this.getTrackingResults(results);
    },
    async onSearchCleared(navigate) {
      this.isSearchAvailable = false;
      this.pageNum = 1;
      this.totalPages = '';
      this.totalResults = 0;
      navigate ? '' : await this.getTrackingResults();
    },
    async saveLearningTrack() {
      if (this.trackUnsavedChanges && this.showNotificationDialog) this.notificationDialog = true;
      else if (this.trackNameUpdated || this.trackUnsavedChanges) this.saveTrack();
      this.EventBus.off('save-track-edit-content');
    },
    async saveTrack(shouldNotifyLearners = { notifyLearners: false }) {
      this.showToast(translate('track.inProgressLearningTrackMessage'), 'info', null, true);
      this.setSaveButtonState(true);

      this.setUnsavedChangesState(false);
      this.setTrackNameUpdated(false);
      const uniqueData = this.trackData.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id));
      //logic for saving the track and redirecting to track listing
      const ccc_items_to_save = uniqueData.map((item) => {
        return {
          itemId: item.id,
          name: item.title,
          format: item.formatType.toLocaleLowerCase(),
          duration: {
            value: item.duration,
            type: 'seconds'
          },
          description: '',
          languageFilter: item.languageFilter
        };
      });
      const payload = {
        name: this.trackName,
        assignees: {
          roles: [{ guid: this.roleGuid, name: this.roleName }]
        },
        items: ccc_items_to_save,
        trackGuid: this.trackGuid,
        ...shouldNotifyLearners
      };
      if (!this.roleGuid) {
        delete payload.assignees.roles;
      }

      const response = this.trackGuid ? await this.$planAdmin.updateLearningTrack(payload) : await this.$planAdmin.saveLearningTrack(payload);
      this.showToastMessage(response);

      if (response.code == 200) {
        if (!this.trackGuid) this.setTrackGuid(response.data.guid);
        this.$router.push('/catalog/stepper/manage');
        this.EventBus.emit('step-complete', this.$route.path);
        await this.setTotalTrackItems(response.data.size);
      }
    },
    organizeTrack() {
      this.$router.push({ name: 'OrganizeLearningTrack' });
    },
    onItemAddition(eve) {
      this.setUnsavedChangesState(true);
      this.setSaveButtonState(false);
      if (eve.added) {
        this.totalDuration += eve.added.element.duration;
      }
    },
    roleDetails() {
      this.$router.push({ name: 'RoleDetails', params: { guid: this.roleGuid } });
    },
    cancelAndNavigate() {
      this.saveDialog = false;
      this.setSaveButtonState(true);
      this.setUnsavedChangesState(false);
      this.setTrackNameUpdated(false);
      this.$router.push({ path: this.toPath });
    },
    saveAndNavigate(shouldNotifyLearners) {
      this.notificationDialog = false;
      this.saveTrack(shouldNotifyLearners);
    },
    cancelNavigation() {
      this.notificationDialog = false;
      this.saveDialog = false;
      this.toPath = '';
    },
    updateTrackName() {
      this.setSaveButtonState(false);
      this.setTrackNameUpdated(true);
    },
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.editContent')
    };
  }
};
</script>
<style lang="scss">
.edit-content-container {
  margin-bottom: 3rem;

  .search-filter-container {
    grid-template-columns: 5fr 7fr;
    align-items: center;
    margin-bottom: 0.5rem;
  }

  h2,
  h2 > span,
  h2 .search-query {
    font-family: 'Futura PT Demi' !important;
    font-weight: normal;
    font-size: 1.625rem;
    color: $color-black;
  }
  h2 > span {
    color: $color-black;
  }

  .v-card-title {
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: bold;
    color: $color-primary;
    min-height: 6.875rem;
    word-break: break-word;
    padding: 0.625rem 0;
  }
  .v-card-text {
    padding: 0;
    .v-btn {
      box-shadow: none;
      background: transparent;
      &:hover {
        box-shadow: none;
        background: transparent;
      }
    }
  }
  span {
    font-size: 0.75rem;
    font-weight: bold;
    color: $color-black;
  }
  .t-bg-course {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .track-list-remove {
    float: right;
    top: -0.4375rem;
  }
  .v-btn--icon.v-size--default .v-icon {
    font-size: 1.1875rem;
  }

  .create-learning-track-left-column {
    margin-top: -5rem;
    #learning-track-container {
      scroll-behavior: smooth;
      height: 45rem !important;
    }
  }
  .create-learning-track-right-column {
    background: #f8f8f8;
    border-radius: 1.25rem;

    .create-track-name {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .draggable-card-container {
      min-height: 18.75rem;
      height: 46rem;
      overflow-y: scroll;

      .v-card {
        margin: 0.3125rem 0.3125rem 0.75rem 0.3125rem;
        position: relative;
        border-radius: 0.75rem;

        .ellipsis-text {
          max-width: 43%;
          overflow: hidden;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;
        }
        .track-list-remove {
          .icon-large {
            width: 3em;
            height: 3em;
          }
        }
      }
    }
    .organize-container {
      .learning-time {
        .ellipsis-menu {
          .v-list.v-sheet.theme--light {
            padding: 0;
          }
        }
      }
      button:disabled {
        background-color: rgba(0, 0, 0, 0.12) !important;
      }

      .learning-track-button {
        height: 3.125rem !important;
        span {
          font: normal normal normal 1rem/1.375rem 'Futura PT Demi';
          letter-spacing: 0.0013rem;
        }
      }
      #exit-button {
        width: 6.1875rem !important;
      }
      #save-button {
        width: 5.375rem !important;
        &:disabled {
          color: rgba(0, 0, 0, 0.26) !important;
        }
      }
    }
  }
}
.edit-content-skills-tooltip {
  min-width: 31.25rem;
  max-width: 50rem;
  background: $color-grey-light !important;
  box-shadow: 0 0.1875rem 0.0625rem -0.125rem rgb(0 0 0 / 20%), 0 0.125rem 0.125rem 0 rgb(0 0 0 / 14%), 0 0.0625rem 0.3125rem 0 rgb(0 0 0 / 12%);
  opacity: 1 !important;
  padding: 1.5625rem 2.8125rem;
  span {
    color: $color-black;
  }
}
@media screen and (min-width: 101.25rem) {
  .edit-content-container {
    .search-filter-container {
      grid-template-columns: 4fr 8fr;
    }
  }
}
html {
  scroll-behavior: smooth;
}
.scrollable {
  &::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: $color-primary-lightest;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 1.25rem;
    width: 0.25rem;
  }
}
</style>
