export class ShareKpiContainer {
  numDownloads = 0
  numViews = 0
  numPrivateAssets = 0
  constructor(data) {
    this.numDownloads = data.numDownloads
    this.numViews = data.numViews
    this.numPrivateAssets = data.numPrivateAssets
  }
}
