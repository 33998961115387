<template>
  <v-card class="skeleton col-11 t-pb-0 t-h-48"> </v-card>
</template>

<script>
export default {
  name: 'Skeleton'
};
</script>

<style lang="scss" scoped>
.skeleton {
  opacity: 0.8;
  animation: skeleton 1s linear infinite alternate;
  background: linear-gradient(0.25turn, transparent, #fff, transparent), linear-gradient(#eee, #eee), radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%), linear-gradient(#eee, #eee);
  background-repeat: no-repeat;
}

@keyframes skeleton {
  0% {
    //background-color: hsl(215, 20%, 70%);
    background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px;
  }
  100% {
    //background-color: hsl(200, 20%, 90%);
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
</style>
