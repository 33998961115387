<template>
  <div id="usage-active-users">
    <div v-show="dataReady" class="card">
      <div class="chart-actions">
        <h2 class="card-header h2">{{ $t('insights.usage.activeUserChartTitle') }}</h2>
        <!-- coming soon -->
        <!-- <v-btn name="download" icon class="t-w-8 t-h-8" @click="downloadAwaitingChartPNG()"><svg-icon icon-class="PNG_icon" class="t-w-8 t-h-8" /></v-btn> -->
      </div>
      <div class="active-user-donut-chart-container">
        <div v-if="componentIsActive">
          <apexchart id="active-user-donut" ref="activeUserChart" type="radialBar" :height="250" :options="options" :series="[percentage]"></apexchart>
        </div>
        <div class="chart-labels">
          <div class="chart-labels-content">
            <span class="chart-circle-green"></span>
            <span class="chart-labels-text"> {{ $t('insights.usage.activeUserCountLabel') }}</span>
          </div>
        </div>
      </div>
      <div class="chart-footer">
        <span class="chart-blurb">{{ $t('insights.usage.activeUserChartBlurb') }}</span>
        <img alt="" src="@/assets/images/icons/coloredboxes.png" class="colorbox" />
      </div>
    </div>

    <div v-show="!dataReady" class="card loading-card">
      <div class="loader t-flex t-justify-center t-items-center">
        <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Chart Data" />
      </div>
    </div>
  </div>
</template>
<script>
import localforage from 'localforage';
import { numberWithCommas } from '@/utils';
import { getDateForCSV, generateCSVTimeFilter } from '@/utils';
import { translate } from '@/plugins/i18n.js';

const initialState = () => ({
  percentage: 0,
  dataReady: false,
  componentIsActive: false,
  options: {
    chart: {
      height: 300,
      type: 'radialBar',
      toolbar: { show: false }
    },
    plotOptions: {
      ringLabels: ['', '', '', ''],
      radialBar: {
        hollow: {
          size: '60%'
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            show: true,
            offsetY: -4,
            color: '#E47545',
            formatter: function (val, opt) {
              return [`${numberWithCommas(opt.config.plotOptions.ringLabels[0])}`, `${opt.config.plotOptions.ringLabels[1]} ${numberWithCommas(opt.config.plotOptions.ringLabels[2])}`, opt.config.plotOptions.ringLabels[3]];
            }
          }
        }
      }
    },
    legend: {
      show: false,
      position: 'right',
      offsetY: 80,
      offsetX: 100,
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false
      }
    },
    labels: ['Total Active Users'],
    colors: ['#00A78D'],
    stroke: { lineCap: 'round' },
    isComponentRefreshed: false
  }
});

export default {
  props: {
    selectedTimeOptionString: {
      type: String
    },
    dateRange: { type: Object }
  },
  data() {
    return initialState();
  },
  async mounted() {
    Object.assign(this.$data, initialState());
    this.dataReady = false;
    this.componentIsActive = true;
  },
  updated() {
    this.isComponentRefreshed = false;
  },
  deactivated() {
    this.componentIsActive = false;
    this.isComponentRefreshed = false;
  },
  watch: {
    selectedTimeOptionString() {
      if (this.selectedTimeOptionString.length) {
        this.isComponentRefreshed = true;
        this.refreshData();
      }
    },
    dateRange: {
      handler() {
        if (!this.isComponentRefreshed) {
          this.dataReady = false;
          this.refreshData();
        } else {
          this.isComponentRefreshed = false;
        }
      },
      deep: true
    }
  },
  methods: {
    getDateForCSV,
    generateCSVTimeFilter,
    async refreshData() {
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      const time = this.selectedTimeOptionString === 'CUSTOM' && this.dateRange.startDate && this.dateRange.endDate ? `${this.selectedTimeOptionString}?startDate=${this.dateRange.startDate}&endDate=${this.dateRange.endDate}&dateFormat=MM-dd-yyyy` : this.selectedTimeOptionString;
      const payload = { tenantGuid: tenantGuid, timePeriod: time };
      // only update the Awaiting Donut the first time
      this.dataReady = false;
      if (!this.dataReady) {
        this.$planAdmin
          .getActiveUsers(payload)
          .then((response) => {
            let activeUsersCount = 0;
            let enrolledUsersCount = 1;
            if (response) {
              if (response.activeUsersCount) activeUsersCount = response.activeUsersCount;
              if (response.enrolledUsersCount) enrolledUsersCount = response.enrolledUsersCount;
              if (response.invitedPercentage) {
                this.percentage = response.invitedPercentage;
              } else {
                this.percentage = Math.round((100 * activeUsersCount) / enrolledUsersCount);
              }
              this.options = {
                ...this.options,
                plotOptions: {
                  ringLabels: [activeUsersCount, translate('insights.activeOutOf'), enrolledUsersCount, translate('insights.activeEnrolledUsers')]
                }
              };
            }

            this.dataReady = true;

            this.$nextTick(async () => {
              const donut = document.getElementById('active-user-donut');

              if (donut) {
                const g = donut?.getElementsByTagName('tspan');

                for (let tspan of g) {
                  if (tspan.hasAttribute('dy')) {
                    tspan.setAttribute('dy', '24');
                  }
                }
              }
            });
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    },
    downloadAwaitingChartPNG() {
      this.$refs.activeUserChart.dataURI().then(({ imgURI }) => {
        let awaitingImageAnchor = document.createElement('a');
        awaitingImageAnchor.href = imgURI;
        awaitingImageAnchor.setAttribute('download', `Active Users Chart_${generateCSVTimeFilter(this.selectedTimeOptionString)}_${this.getDateForCSV()}`);
        awaitingImageAnchor.click();
      });
    }
  }
};
</script>

<style lang="scss">
#usage-active-users {
  padding: 2rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 1.25rem;
  width: 100%;
  padding: 2rem;
  display: grid;
  margin: 2rem 0rem;
  height: 28.75rem;

  .chart-actions,
  .chart-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .card-header {
    color: #1f3370;
    font-weight: bold;
  }

  .h2 {
    font-family: Arial, sans-serif;
    font-size: 16px;
  }

  .loading-card {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.active-user-donut-chart-container {
  display: flex;
  justify-content: center;
  margin: 2rem 0rem;

  #active-user-donut {
    .apexcharts-text tspan {
      &:nth-child(1) {
        fill: #1f3370;
        margin-bottom: 2rem;
        font-size: 2.625rem;
        font-family: 'Futura PT Demi' !important;
      }
      &:nth-child(2),
      &:nth-child(3) {
        fill: #5c5c5c;
        font-size: 0.875rem;
        font-family: Arial !important;
        transform: translateY(0.625rem);
        font-weight: 500;
      }
    }
  }

  .apexcharts-legend {
    .apexcharts-legend-series {
      display: none;
    }
  }

  .chart-labels {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .chart-labels-content {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      font: normal normal normal 1rem/1.125rem Arial;
      letter-spacing: 0px;
      color: #2d2d2d;

      opacity: 1;
      .chart-circle-green {
        width: 0.75rem;
        height: 0.75rem;
        background: #00a78d 0% 0% no-repeat padding-box;
        opacity: 1;
        color: red;
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.5rem;
      }
      .chart-circle-silver {
        width: 12px;
        height: 12px;
        background: #e0e1e1 0% 0% no-repeat padding-box;
        opacity: 1;
        color: red;
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.5rem;
      }

      .chart-labels-text {
        width: max-content;
        white-space: nowrap;
      }
    }
  }
}
.v-row-container {
  margin: 0.2rem 1rem;
}
.chart-blurb {
  float: left;
}
.colorbox {
  float: right;
  height: 1.875rem;
}
.enrollment-image {
  display: inline;
  margin: 1.5625rem;
}
</style>
