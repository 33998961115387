<template>
  <div class="review-action-container t-mt-8 t-p-4 t-text-3xl t-flex">
    <div class="timeline-title t-font-futura-pt-demi t-font-normal t-text-2xl t-relative t-bottom-10 t-left-14">{{ $t('manageSubmissions.status') }}</div>
    <div class="timeline-item">
      <div class="outer-circle yellow-outer-circle t-rounded-full t-h-12 t-w-12 t-relative">
        <div class="inner-circle yellow-inner-circle t-rounded-full t-h-6 t-w-6 t-text-xs t-absolute t-flex t-items-center t-justify-center">
          <svg-icon class="" icon-class="tick" />
        </div>
      </div>
      <div class="timeline-font">{{ $t('manageSubmissions.reviewStatusOptions.submitted') }}</div>
    </div>
    <div class="progress-line t-w-20 t-top-6 t-relative yellow-line"></div>
    <div class="timeline-item">
      <div class="outer-circle t-rounded-full t-h-12 t-w-12 t-relative yellow-outer-circle">
        <div class="inner-circle yellow-inner-circle t-rounded-full t-h-6 t-w-6 t-text-xs t-absolute t-flex t-items-center t-justify-center yellow-inner-circle">
          <svg-icon v-if="['approved', 'rejected'].includes(status)" icon-class="tick" />
        </div>
      </div>
      <div class="timeline-font">{{ $t('manageSubmissions.reviewStatusOptions.inReview') }}</div>
    </div>
    <div class="progress-line t-w-20 t-top-6 t-relative" :class="['approved', 'rejected'].includes(status) ? 'yellow-line' : 'white-line'"></div>
    <div class="timeline-item" v-if="status != 'rejected'">
      <div class="outer-circle t-h-12 t-w-12 t-rounded-full t-relative" :class="status == 'approved' ? 'blue-outer-circle' : 'white-outer-circle'">
        <div class="inner-circle t-h-6 t-w-6 t-rounded-full t-text-xs t-absolute t-flex t-items-center t-justify-center" :class="status == 'approved' ? 'blue-inner-circle' : 'white-inner-circle'">
          <svg-icon v-if="status == 'approved'" icon-class="tick" />
        </div>
      </div>
      <div class="timeline-font" v-if="status == 'approved'">{{ $t('manageSubmissions.reviewStatusOptions.approved') }}</div>
    </div>
    <div v-else class="timeline-item">
      <div class="outer-circle red-outer-circle t-h-12 t-w-12 t-rounded-full t-relative">
        <div class="inner-circle red-inner-circle t-h-6 t-w-6 t-text-xs t-rounded-full t-absolute t-flex t-items-center t-justify-center">
          <svg-icon icon-class="tick" />
        </div>
      </div>
      <div class="timeline-font">{{ $t('manageSubmissions.reviewStatusOptions.rejected') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AssetReviewStatus',
  props: {
    status: {
      type: String,
      required: true,
      default: ''
    },
    adminEdited: {
      type: Boolean,
      required: false,
      default: false
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
.timeline-font {
  font: normal normal bold 0.5rem/1.75rem Arial;
  text-transform: uppercase;
}

.progress-line {
  height: 0.03rem;

  &.white-line {
    border: 1px solid #fff;
  }

  &.yellow-line {
    border: 1px dashed #fdb931;
  }
}

.review-action-container {
  width: calc(33% - 2rem);
}

.outer-circle {
  &.yellow-outer-circle {
    background: #f3deb5;
  }

  &.blue-outer-circle {
    background: #b3f1e7;
  }

  &.white-outer-circle {
    background: #f8f8f8;
  }

  &.red-outer-circle {
    background: #f5cbb9;
  }
}

.inner-circle {
  top: 24%;
  left: 24%;

  &.yellow-inner-circle {
    background: #fdb931;
  }

  &.blue-inner-circle {
    background: #33dac0;
  }

  &.white-inner-circle {
    background: #e0e1e1;
  }

  &.red-inner-circle {
    background: #e47545;
  }
}
</style>
