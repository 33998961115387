import { createApp } from 'vue';
import { createVuetify } from 'vuetify';
import Vue3Sanitize from 'vue-3-sanitize';

import IdleVue from 'idle-vue';
import TimersPlugin from './plugins/timers.js';
import UnleashPlugin from './plugins/unleash.js';
import { i18n } from './plugins/i18n.js';
import { getFeatureFlagStrategy } from './utils';

import PlanAdminWeb from './api/plan-web';

import Tracker from './directives/Tracker.js';
import AnalyticsPlugin from './plugins/analytics.js';
import VersionPlugin from './plugins/version.js';
import VueGtag from 'vue-gtag';
import Paginate from 'vuejs-paginate-next';

import App from './App.vue';
import router, { getRouter } from './router';
import store from './store';
import _ from 'lodash';
import moment from 'moment';

import mitt from 'mitt';
import VueApexCharts from 'vue3-apexcharts';
import BootstrapToasted from './plugins/toasted.js';
import './icons';
import './styles/index.scss';
import 'vuetify/dist/vuetify.min.css';
import 'vuetify/styles';

import * as components from 'vuetify/components';
import * as labsComponents from 'vuetify/labs/components';
import * as directives from 'vuetify/directives';

import SvgIcon from '@/components/molecules/SvgIcon';

const req = require.context('./icons/svg', false, /\.svg$/);
const requireAll = (requireContext) => requireContext.keys().map(requireContext);
requireAll(req);

const emitter = mitt();
const app = createApp(App);

app.config.globalProperties.$backButtonClicked = false;
app.config.globalProperties.$planAdmin = new PlanAdminWeb();
app.config.globalProperties.$backButtonClicked = false;
app.config.globalProperties.lodash = _;
app.config.globalProperties.moment = moment;
app.config.globalProperties.EventBus = emitter;

app.directive('track', Tracker);
app.component('svg-icon', SvgIcon);
app.use(Paginate);

app.use(BootstrapToasted);
app.use(VueApexCharts);
app.use(store);
app.use(getRouter(app));
app.use(i18n);

app.use(
  createVuetify({
    components: { ...components, ...labsComponents },
    directives,
    theme: {
      themes: {
        dark: false,
        colors: {
          primary: '#1f3370',
          secondary: '#ffb92e',
          accent: '#8c9eff',
          error: '#A62F27'
        }
      }
    }
  })
);

app.use(AnalyticsPlugin);
app.use(VersionPlugin, { store: store });

const idleTime = parseInt(process.env.VUE_APP_IDLE_TIME) > 0 ? parseInt(process.env.VUE_APP_IDLE_TIME) : 60000;
app.use(IdleVue, {
  eventEmitter: {
    $on: (...args) => emitter.on(...args),
    $once: (...args) => emitter.once(...args),
    $off: (...args) => emitter.off(...args),
    $emit: (...args) => emitter.emit(...args)
  },
  store,
  idleTime: idleTime, // one minute of inactivity flags user as idle
  startAtIdle: false,
  events: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll']
});

const renewTokenTime = parseInt(process.env.VUE_APP_RENEW_TOKEN_TIME) > 0 ? parseInt(process.env.VUE_APP_RENEW_TOKEN_TIME) : 300000;
const autoLogoutTime = parseInt(process.env.VUE_APP_AUTO_LOGOUT_TIME) > 0 ? parseInt(process.env.VUE_APP_AUTO_LOGOUT_TIME) : 900000;
const remindLogoutTime = parseInt(process.env.VUE_APP_REMIND_LOGOUT_TIME) > 0 ? parseInt(process.env.VUE_APP_REMIND_LOGOUT_TIME) : 780000;

app.use(TimersPlugin, {
  store: store,
  router: router,
  tokenRenewalTime: renewTokenTime * 2, // 10 minutes
  logoutIdleTime: autoLogoutTime,
  remindIdleTime: remindLogoutTime
});

app.use(UnleashPlugin, {
  host: process.env.VUE_APP_ULFF_HOST,
  store,
  strategyProviders: getFeatureFlagStrategy()
});

app.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GA_TRACKING_ID,
      params: { send_page_view: false, cookie_flags: 'max-age=7200;secure;samesite=none' }
    }
  },
  router
);

app.use(Vue3Sanitize, { allowedTags: ['img'] });

/******
 * Browser Window back and forward button click capture
 */
window.addEventListener('popstate', function () {
  app.config.globalProperties.$browserNavClicked = true;
});

app.mount('#app');
