<template>
  <div class="manage-people-container">
    <NotificationDialog
      :notificationDialog="notificationDialog"
      :header="headerCopyForNotification"
      :copy="copyForNotification"
      :copySecondary="copySecondaryForNotification"
      @save-navigate="onAddRemovePeople"
      @save-navigate-reqd-learning="toEditDueDates"
      @cancel-and-navigate="cancelAndNavigate()"
      @cancel-navigation="cancelNavigation()"
      :requiredLearningSelection="userOperationAndReqdLearning"
    />
    <div class="manage-people-header">
      <div class="header-title t-text-4xl t-font-futura-pt-demi t-font-normal t-text-black t-pl-6">{{ $t('managePeople.managePeople') }}</div>
      <div class="header-sub-title t-mt-2 t-pl-6">{{ tabIndex === 0 ? $t('managePeople.headerSubtitleAllPeople') : $t('managePeople.headerSubtitlePeopleInTrack') }}</div>
      <div class="track-name t-text-black t-mt-8 t-pl-6">{{ contentFormat }}: {{ trackName }}</div>
      <div class="tabs-container">
        <div class="tab-buttons">
          <div v-for="tab in tabs" :key="tab.tidx" class="tab" :class="tabIndex === tab.tidx ? 'selected' : ''" @click="updateTabIndex(tab.tidx)">
            {{ tab.title }}
            <span class="tab-pill" v-if="tab.tidx === 1">{{ numberWithCommas(totalPeopleInTrack) }}</span>
          </div>
        </div>
        <div class="pagination-buttons">
          <div v-if="tabIndex === 0">
            <v-btn v-if="tabIndex === 0" rounded @click="onAddRemoveNotify('add')" class="add-people-button" :disabled="!Array.from(addPeopleSet).length">{{ $t('managePeople.add') }}</v-btn>
          </div>
          <div v-else>
            <v-btn v-if="requiredLearning" rounded @click="toEditDueDates()" class="t-mr-4 t-font-bold t-bg-primary-color t-text-white hover:t-opacity-90 edit-due-dates-button" :disabled="!Array.from(removePeopleSet).length">
              {{ $t('managePeople.editDueDates') }}
            </v-btn>
            <v-btn rounded @click="onAddRemoveNotify('remove')" class="add-people-button" :disabled="!Array.from(removePeopleSet).length">{{ $t('managePeople.remove') }}</v-btn>
          </div>
        </div>
      </div>
      <hr class="section-divider" />
    </div>
    <div class="manage-people-actions">
      <div class="manage-people-search-container" :style="displaySelectedFilters.length && 'padding-bottom: 0rem;'" v-if="this.structureReady">
        <v-text-field
          class="details-search-text-field"
          v-model="searchText"
          @input="checkSearchLength"
          @keypress.enter="searchName"
          @click:clear="handleSearchClear"
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('common.searchByName')"
          filled
          rounded
          solo
          clearable
          single-line
          variant="solo"
        />
        <p class="t-mb-0 t-mr-1 t-pr-6 t-mr-6 people-selected">{{ tabIndex === 0 ? numberWithCommas(addPeopleSet.size) : numberWithCommas(removePeopleSet.size) }} {{ translate('managePeople.selections') }}</p>
        <ManagePeopleFilters @filter-applied="applyFilters" :filterResults="filterResults" @interface="getChildInterface" />
      </div>

      <div class="manage-people-search-chips" v-if="displaySelectedFilters.length">
        <div>
          <span v-for="(item, index) in displaySelectedFilters" :key="index" id="chips-container">
            <v-chip in class="chip t-text-xs ma-2 t-bg-black" closable @click:close="onRemoveFilter(item)"> {{ item.name }} </v-chip>
          </span>
          <span class="clear-all-filters" @click="onClearFilters">{{ $t('common.clearAllFilters') }}</span>
        </div>
      </div>
    </div>
    <hr class="section-divider" />

    <div class="manage-people-table-container" v-if="this.structureReady">
      <div class="manage-people-treeview-container">
        <div class="tree-view-header">{{ $t('managePeople.treeHeader') }}</div>
        <!-- 
        <TreeView v-if="treeViewReady" :items="structure" class="manage-people-treeview" :load-children="onFetchChildren" activatable>
          <template v-slot:label="{ item, active }">
            <div v-if="item.hasOwnProperty('lead')" @click="active ? $event.stopPropagation() : onGetPlan(item)">
              <span :id="`treeview-node-label-${item.id}`" class="dept-name">{{ item.name }}</span>
            </div>
          </template>
        </TreeView> 
        -->

        <DataTableTreeView v-if="treeViewReady" class="manage-people-treeview" :items="structure" :row="0" @on-get-plan="onGetPlan" :mode="1" @load-children="onFetchChildren" :onGetPlan="onGetPlan" />

        <div v-else>
          <div class="loader t-flex t-justify-center t-items-center">
            <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Tree View" />
          </div>
        </div>
      </div>
      <!-- :items-length="totalItems" -->
      <v-data-table-server
        class="manage-people-table"
        v-model="isSelected"
        show-select
        :headers="headers"
        :items="filterEmployees"
        :items-length="people.totalEmployees || 0"
        :items-per-page="pageSize"
        :items-per-page-options="pageSizeOption"
        @current-items="currentEmployees"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        hide-default-footer
        return-object
        @update:options="onDataTableChange"
      >
        <!-- Group Select Changes, will enable it in next phase -->

        <!--    
        <template v-slot:[`body.prepend`]="{ headers }">
          <tr :class="showGroupSelectMsg ? 'group-active-row-selection' : 'group-inactive'">
            <td :colspan="headers.length">
              <div class="group-select-msg t-flex t-items-center t-bg-blue-200">All 17 people on this page are selected. <a href="javascript:void(0);" class="t-font-bold">Select all 100 people from all pages.</a></div>
            </td>
          </tr>
        </template>
        -->
        <!-- 
        <template slot="no-data">
          <div>{{ $t('common.noData') }}</div>
        </template>
        -->

        <template v-slot:[`body`] v-if="!tableDataReady">
          <div class="mange-people-loader">
            <v-card-text class="loader-text">{{ $t('common.titleCase.loading') }}</v-card-text>
            <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
          </div>
        </template>

        <!-- 
        <template v-slot:[`header.data-table-select`]="{ props }">
          <v-checkbox :value="props.value || props.indeterminate" :indeterminate="props.indeterminate" color="primary" v-bind="props" />
        </template>
        -->

        <template v-slot:[`header.data-table-select`]="{ selectAll }">
          <v-checkbox-btn @click="onSelectAll(selectAll)" />
        </template>

        <!-- TABLE BODY BELOW -->
        <template v-slot:[`item.data-table-select`]="{ item }">
          <div class="manage-people-tooltip">
            <v-checkbox v-model="item.select" @click="onSelect(item)" :disabled="item.disabled" />
            <ToolTip v-if="item.disabled" :content="tabIndex === 0 ? $t('managePeople.alreadyInTrack') : $t('managePeople.addedByRole')" :width="tabIndex === 0 ? '28.5rem' : '37rem'" />
          </div>
        </template>

        <template v-slot:[`item.name`]="{ item }">
          <div :class="item.disabled ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.name }}</span>
              </template>
              <span>{{ item.email }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <div :class="item.disabled ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.title }}</span>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.role`]="{ item }">
          <div :class="item.disabled ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.role }}</span>
              </template>
              <span>{{ item.role }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.dueDateProgress`]="{ item }">
          <div :class="item.disabled ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <svg-icon v-if="item.isPastDue" icon-class="past-due" />
            <svg-icon v-else-if="item.progress === 100" icon-class="complete-progress" />
          </div>
        </template>

        <template v-slot:[`item.dueDate`]="{ item }">
          <div :class="item.disabled ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.dueDate }}</span>
              </template>
              <span>{{ item.dueDate }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.license`]="{ item }">
          <div :class="item.disabled ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.license }}</span>
              </template>
              <span>{{ item.license }}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div :class="item.disabled ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <span>{{ getLocalizedEnrollmentStatus(item.status) }}</span>
          </div>
        </template>
        <!--
        <template v-slot:footer>
          <div class="manage-people-pagination-container">
            <paginate
              v-if="totalNumOfPages > 1"
              v-model="pageNum"
              :page-count="totalNumOfPages"
              :page-range="pageRange"
              :margin-pages="1"
              :click-handler="handlePageClick"
              :container-class="'pagination'"
              :page-class="'pagination-item'"
              :page-link-class="'pagination-item-link'"
              :prev-text="'&lsaquo;'"
              :prev-class="'pagination-nav pagination-tooltip'"
              :prev-link-class="'pagination-nav-link'"
              :next-text="'&rsaquo;'"
              :next-class="'pagination-nav pagination-tooltip'"
              :next-link-class="'pagination-nav-link'"
              :hide-prev-next="true"
            />
          </div>
        </template>
        -->
      </v-data-table-server>
    </div>

    <div v-else>
      <div class="loader t-flex t-justify-center t-items-center t-h-96">
        <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Learning Track" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { numberWithCommas, getLocalizedEnrollmentStatus } from '@/utils';
import ManagePeopleFilters from './ManagePeopleFilters';
import ToolTip from '../../../components/molecules/ToolTip.vue';
import NotificationDialog from './NotificationDialog.vue';
import DataTableTreeView from '@/components/molecules/DataTableTreeView';
import { translate } from '@/plugins/i18n.js';
import localforage from 'localforage';

const initialState = () => ({
  treeViewReady: true,
  showGroupSelectMsg: false,
  tableDataReady: false,
  structureReady: false,
  searchText: '',
  tabIndex: 0,
  structure: [],
  people: {},
  selectDepartment: true,
  selectUser: null,
  employees: [],
  isSelected: [],
  selected: [],
  headers: [],
  pageNum: 1,
  pageSize: 25,
  pageSizeOption: [
    { value: 10, title: '10' },
    { value: 25, title: '25' },
    { value: 50, title: '50' },
    { value: 100, title: '100' }
  ],
  titles: [],
  roles: [],
  licenses: [],
  statusValues: [],
  filterValuesRequired: false,
  totalPeopleInTrack: 0,
  emailTooltip: [],
  filterResults: {},
  orgInFocus: null,
  totalNumOfPages: 0,
  pageRange: 6,
  sortBy: [],
  sortDesc: false,
  displaySelectedFilters: [],
  addPeopleSet: new Set(),
  removePeopleSet: new Set(),
  trackName: '',
  clearSearchCross: false,
  notificationDialog: false,
  operation: '',
  copyForNotification: '',
  copySecondaryForNotification: '',
  headerCopyForNotification: '',
  userOperationAndReqdLearning: false,
  tabs: [],
  isAllSelected: false,
  isC1: false
});

export default {
  name: 'ManagePeople',
  components: { ManagePeopleFilters, ToolTip, NotificationDialog, DataTableTreeView },
  props: ['catalogItem'],
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters(['client_category', 'company', 'client_category']),
    ...mapState('track', ['roleNameTrack', 'skillsTrack', 'roleGuid', 'trackGuid', 'totalItemsInTrack', 'contentId', 'contentTitle', 'contentFormat']),
    ...mapState('user', ['requiredLearning']),
    filterEmployees() {
      return this.employees.map((x) => ({
        ...x,
        isSelectable: !x.disabled,
        dueDate: x.progress === 100 ? translate('common.titleCase.completed') : x.dueDate ? this.getUtcDate(x.dueDate) : 'N/A',
        isPastDue: x.progress === 100 ? false : x.dueDate ? this.checkPastDue(x.dueDate) : false
      }));
    }
  },
  async mounted() {
    // if (!this.trackGuid || !this.totalItemsInTrack) {
    //   this.$router.push('/catalog/landing');
    //   return;
    // }
    Object.assign(this.$data, initialState());
    this.$data.tabs = [
      { title: translate('managePeople.allPeople'), tidx: 0 },
      { title: translate('managePeople.addedPeople'), tidx: 1 }
    ];
    // keeping the code below for testingCodeToCopy
    // this.tabIndex = 0
    // this.selectDepartment = true
    // this.pageNum = 1
    // this.totalPeopleInTrack = 0
    // keeping the code above for testingCodeToCopy
    await this.fetchInitialData();
    await this.setupPagination();
    this.refreshStepperState();
  },
  watch: {
    tableDataReady: function (val) {
      this.$nextTick(async () => {
        const container = document.getElementsByClassName('manage-people-table')[0];
        if (container) {
          const wrapper = document.getElementsByClassName('v-table__wrapper')[0];
          wrapper.scrollLeft && (wrapper.scrollLeft = 0);
          const tbody = container.getElementsByTagName('tbody')[0];
          const rows = tbody.getElementsByTagName('tr');
          for (let row of rows) {
            if (val) {
              row.style.display = 'table';
              tbody.style.overflow = 'auto';
            } else {
              row.style.display = 'none';
              tbody.style.overflow = 'hidden';
            }
          }
        }
      });
    },
    totalPeopleInTrack() {
      const step = this.totalPeopleInTrack ? 'step-complete' : 'step-incomplete';
      this.EventBus.emit(step, this.$route.path);
    },
    /***
     * operation has to be overriden on tabIndex update
     */
    tabIndex(newVal) {
      this.operation = newVal === 0 ? 'add' : 'remove';
    },
    '$i18n.locale'() {
      this.refreshStepperState();
      this.setHeaders();
      this.$data.tabs = [
        { title: translate('managePeople.allPeople'), tidx: 0 },
        { title: translate('managePeople.addedPeople'), tidx: 1 }
      ];
    }
  },
  childInterface: {
    onClearFilters: () => {},
    onRemoveFilter: () => {}
  },
  methods: {
    translate,
    numberWithCommas,
    getLocalizedEnrollmentStatus,
    ...mapActions('stepper', ['clearStepperState', 'refreshStepperState']),
    ...mapActions('track', ['setEmployeeGuids', 'setTrackName', 'setSelectedEmployeeName', 'setSelectedEmployeeData']),
    // CHILD INTERFACES
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    async onClearFilters() {
      this.$options.childInterface.clearFilters();
      this.pageNum = 1;
      this.$nextTick(async () => {
        if (this.orgInFocus) {
          await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText, true);
        } else {
          await this.getAllEmployees(this.pageNum, this.searchText, true);
        }
        await this.setup();
        await this.setupPagination();
      });
    },
    onRemoveFilter(item) {
      this.$options.childInterface.removeFilter(item);
    },
    // CHILD INTERFACES
    async onFetchChildren(item) {
      const structure = await this.$planAdmin.getManageChildrenOrgStructure({ orgId: item.id });
      item.children = structure.children;
    },
    // HEADER FUNCTIONS
    async fetchInitialData() {
      this.structureReady = false;
      this.tableDataReady = false;
      await this.getTreeData();
      if ({}.hasOwnProperty.call(this.structure, 'code') && this.structure.code == 401) {
        this.$stopSessionTimer();
      }

      await this.getAllEmployees();
      await this.setup();
      this.trackName = this.contentTitle ? this.contentTitle : this.people.trackOrContentTitle;
      this.structureReady = true;
      this.tableDataReady = true;
    },
    async getTreeData() {
      const data = await this.$planAdmin.getRootOrgManagePeople();
      this.structure = data.children;
    },
    async updateTabIndex(index) {
      this.isSelected = [];
      this.addPeopleSet.clear();
      this.removePeopleSet.clear();
      this.orgInFocus = null;
      this.tabIndex = index;
      this.pageNum = 1;
      this.searchText = '';
      await this.onClearFilters();
      await this.fetchInitialData();
      await this.setupPagination();
    },
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });

      this.$toasted.show(msg, options);
    },
    onAddRemoveNotify(operation) {
      this.operation = operation;

      // Need to uncomment the following code in next phase for required learning
      if (this.requiredLearning) {
        this.copyForNotification = operation === 'add' ? 'selectionContinueMessageAddReqLearning' : 'selectionContinueMessageRemove';
        this.headerCopyForNotification = operation === 'add' ? 'headerAddPeopleReqLearning' : 'headerRemovePeople';
        this.copySecondaryForNotification = operation === 'add' ? 'selectionDoNotNotifyMessageAddReqLearning' : 'selectionDoNotNotifyMessageRemove';
        this.userOperationAndReqdLearning = operation === 'add';
      } else {
        this.copyForNotification = operation === 'add' ? 'selectionContinueMessageAdd' : 'selectionContinueMessageRemove';
        this.headerCopyForNotification = operation === 'add' ? 'header' : 'headerRemovePeople';
        this.copySecondaryForNotification = operation === 'add' ? 'selectionDoNotNotifyMessage' : 'selectionDoNotNotifyMessageRemove';
      }

      /*this.copyForNotification = operation === 'add' ? 'selectionContinueMessageAdd' : 'selectionContinueMessageRemove'
      this.headerCopyForNotification = operation === 'add' ? 'header' : 'headerRemovePeople'
      this.copySecondaryForNotification = operation === 'add' ? 'selectionDoNotNotifyMessage' : 'selectionDoNotNotifyMessageRemove'*/

      this.notificationDialog = true;
    },
    async onAddRemovePeople(shouldNotifyLearners) {
      this.tableDataReady = false;
      this.notificationDialog = false;
      this.copyForNotification = '';
      this.headerCopyForNotification = '';
      this.copySecondaryForNotification = '';
      if (this.operation === 'add') {
        this.showToast(translate('managePeople.inProgressAddPeopleToastMessage'), 'info', null, true);
      } else {
        this.showToast(translate('managePeople.inProgressRemovePeopleToastMessage'), 'info', null, true);
      }

      const trackPayload = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        learners: Array.from(this.addPeopleSet),
        name: this.searchText || '',
        fetchAddedPeopleOnly: this.tabIndex === 1,
        trackGuid: this.trackGuid,
        ...(this.orgInFocus && { orgId: this.orgInFocus.id }),
        ...shouldNotifyLearners
      };
      let payload = {};
      let methods = {};

      const trackMethods = {
        add: this.orgInFocus ? this.$planAdmin.addSelectedPeopleInOrg.bind(this.$planAdmin) : this.$planAdmin.addSelectedPeople.bind(this.$planAdmin),
        remove: this.orgInFocus ? this.$planAdmin.removeSelectedPeopleInOrg.bind(this.$planAdmin) : this.$planAdmin.removeSelectedPeople.bind(this.$planAdmin)
      };
      const contentMethods = {
        add: await this.$planAdmin.saveDueDatesForContent.bind(this.$planAdmin),
        remove: await this.$planAdmin.unAssignDueDatesForContent.bind(this.$planAdmin)
      };

      if (this.contentId) {
        methods = contentMethods;
        if (this.operation === 'add') {
          payload = {
            userGuidsDueDate: Array.from(this.addPeopleSet, (guid) => ({ guid })),
            contentId: this.contentId,
            sendNotifications: shouldNotifyLearners.notifyLearners
          };
        } else {
          payload = {
            contentId: this.contentId,
            data: [...this.removePeopleSet]
          };
        }
      } else {
        methods = trackMethods;
        if (this.operation === 'add') {
          trackPayload.learners = [...this.addPeopleSet];
        } else {
          trackPayload.learners = [...this.removePeopleSet];
        }
        payload = trackPayload;
      }

      const response = await (this.operation === 'add' ? methods.add(payload) : methods.remove(payload));

      if (!this.contentId) {
        if (!response?.employees) {
          const errorMessage = this.operation === 'add' ? 'managePeople.failureToastMessage' : 'managePeople.removeFailureToastMessage';
          this.showToast(translate(errorMessage), 'error', 3000, false);
          return;
        }

        this.$toasted.clear();

        if (response.employees) {
          if (this.operation === 'add') {
            this.addPeopleSet.clear();
            this.showToast(translate('managePeople.successAddPeopleToastMessage'), 'success', 3000, false);
          } else {
            this.removePeopleSet.clear();
            this.showToast(translate('managePeople.successRemovePeopleToastMessage'), 'success', 3000, false);
          }
        } else {
          this.operation === 'add' ? this.showToast(translate('managePeople.failureToastMessage'), 'error', 3000, false) : this.showToast(translate('managePeople.removeFailureToastMessage'), 'error', 3000, false);
        }

        this.people.employees.length = 0;
        this.people.employees = response.employees || [];
        this.totalPeopleInTrack = response.totalAssignedEmployees;
        this.totalNumOfPages = response.totalNumOfPages;

        await this.isSelected.map(async (selectedEmp) => {
          const index = this.employees.findIndex((employee) => employee.guid === selectedEmp.guid);
          if (index > -1) {
            this.employees[index].select = false;
          }
        });
      } else {
        this.$toasted.clear();
        if (response.SUCCESS) {
          if (this.operation === 'add') {
            this.addPeopleSet.clear();
            this.showToast(translate('managePeople.successAddPeopleToastMessage'), 'success', 3000, false);
          } else {
            this.removePeopleSet.clear();
            this.showToast(translate('managePeople.successRemovePeopleToastMessage'), 'success', 3000, false);
          }
        } else {
          this.operation === 'add' ? this.showToast(translate('managePeople.failureToastMessage'), 'error', 3000, false) : this.showToast(translate('managePeople.removeFailureToastMessage'), 'error', 3000, false);
        }
      }

      await this.getUserProfiles();
      this.isSelected = [];
      this.tableDataReady = true;
      this.operation = '';
      this.$router.push('/catalog/landing');
    },
    toEditDueDates() {
      this.notificationDialog = false;
      const empGuids = this.operation === 'add' ? Array.from(this.addPeopleSet) : Array.from(this.removePeopleSet);
      this.setEmployeeGuids(empGuids);
      this.setSelectedEmployeeData(JSON.stringify(this.isSelected));
      if (empGuids.length === 1) {
        const name = this.filterEmployees.filter((employee) => employee.guid === empGuids[0])[0].name;
        this.setSelectedEmployeeName(name.trim());
      }
      this.$router.push('/catalog/stepper/edit-due-dates');
    },
    checkSearchLength(val) {
      !val ? (this.clearSearchCross ? (this.clearSearchCross = false) : this.handleSearchClear()) : void 0;
    },
    async searchName(e) {
      if (!e.target.value.length) return;
      this.pageNum = 1;
      this.orgInFocus = null;
      // this.addPeopleSet.clear();
      this.removePeopleSet.clear();
      this.isSelected = [];
      await this.resetTreeView();
      this.$options.childInterface.clearFilters();
      await this.getAllEmployees(this.pageNum, this.searchText, true);
      await this.setup();
    },
    async resetTreeView() {
      this.treeViewReady = false;
      await this.getTreeData();
      this.treeViewReady = true;
    },
    async handleSearchClear() {
      this.clearSearchCross = true;
      this.pageNum = 1;
      this.searchText = '';
      this.$options.childInterface.clearFilters();
      this.orgInFocus = '';
      await Promise.all([this.resetTreeView(), this.getAllEmployees(this.pageNum, '', true)]);

      await this.setup();
    },
    // HEADER FUNCTIONS
    // TABLE FUNCTIONS
    async onGetPlan(org) {
      this.pageNum = 1;
      this.tableDataReady = false;
      this.people = {};
      this.selectDepartment = true;
      this.addPeopleSet.clear();
      this.removePeopleSet.clear();
      this.isSelected = [];
      if (org.id) {
        await this.getEmployees(org);
      }

      this.sortBy = [];
      this.sortDesc = false;
      /****
       * Keep commented code on next 3 lines
       * to clear the set between node selections
       * uncomment those lines
       */
      //this.isSelected = []
      //this.addPeopleSet.clear()
      //this.removePeopleSet.clear()

      this.$options.childInterface.clearFilters();
      await this.setup();
      await this.setupPagination(true);
      this.tableDataReady = true;
    },
    currentEmployees(employees) {
      this.currentEmployeeGuids = employees.map((employee) => employee.guid);
    },
    onSelectAll(selectAll) {
      if (this.tabIndex === 0) {
        if (this.isAllSelected) {
          this.filterEmployees.forEach((item) => {
            item.select = false;
            this.addPeopleSet.delete(item.guid);
          });
          this.isSelected = [];
          selectAll(false);
        } else {
          this.filterEmployees.forEach((item) => {
            item.select = true;
            if (!item.disabled) this.addPeopleSet.add(item.guid);
          });
          selectAll(true);
        }
      } else {
        if (this.isAllSelected) {
          this.filterEmployees.forEach((item) => {
            item.select = false;
            this.removePeopleSet.delete(item.guid);
          });
          this.isSelected = [];
          selectAll(false);
        } else {
          this.filterEmployees.forEach((item) => {
            item.select = true;
            if (!item.disabled) this.removePeopleSet.add(item.guid);
          });
          selectAll(true);
        }
      }
      this.isAllSelected = !this.isAllSelected;
    },
    onSelect(emp) {
      let empIndex = this.isSelected.map(({ guid }) => guid).indexOf(emp.guid);
      if (empIndex > -1) {
        emp.select = false;
        this.isSelected.splice(empIndex, 1);
      } else {
        emp.select = true;
        this.isSelected.push(emp);
      }
      this.hashSetOperation(emp);
    },
    hashSetOperation(emp) {
      if (this.tabIndex === 0) {
        if (emp.select) {
          this.addPeopleSet.add(emp.guid);
        } else {
          this.addPeopleSet.delete(emp.guid);
        }
      } else {
        if (emp.select) {
          this.removePeopleSet.add(emp.guid);
        } else {
          this.removePeopleSet.delete(emp.guid);
        }
      }
    },
    async applyFilters(filters, selectedFilters) {
      this.displaySelectedFilters = selectedFilters;
      this.tableDataReady = false;
      this.addPeopleSet.clear();
      this.removePeopleSet.clear();
      this.isSelected = [];
      this.pageNum = 1;
      const titles = [...filters[0].values];
      const roles = [...filters[1].values];
      const licenses = [...filters[2].values];
      const statusValues = [...filters[3].values];

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText, true, titles, roles, licenses, statusValues);
      } else {
        await this.getAllEmployees(this.pageNum, this.searchText, true, titles, roles, licenses, statusValues);
      }

      this.totalNumOfPages = this.people.totalNumOfPages;
      await this.setup();
      this.tableDataReady = true;
    },
    // TABLE FUNCTIONS
    async getAllEmployees(pageNumber, searchText, filtersRequired = true, titles, roles, licenses, statusValues) {
      const payload = {
        pageNum: pageNumber || this.pageNum,
        pageSize: this.pageSize,
        name: searchText || '',
        titles: titles || [],
        roles: roles || [],
        licenses: licenses || [],
        statusValues: statusValues || [],
        filterValuesRequired: filtersRequired,
        fetchAddedPeopleOnly: this.tabIndex === 1
      };
      if (this.contentId) {
        payload.contentId = this.contentId;
        this.people = await this.$planAdmin.getManagePeopleRootOrgEmpListForContent(payload);
      } else if (this.trackGuid) {
        payload.trackGuid = this.trackGuid;
        this.people = await this.$planAdmin.getManagePeopleRootOrgEmpList(payload);
      }

      // temporary solution
      // we may have to call getManagePeopleRootOrgEmpList from the
      // Catalog Page to see if we should show the Manage People Link
      // temporary solution
      if (this.people.status === 404) {
        this.showToast(translate('insights.content.apiError'), 'error', 3000, false);
        this.$router.push({ path: '/catalog/stepper/edit' });
        return;
      }
      // temporary solition
      // we may have to call getManagePeopleRootOrgEmpList from the
      // Catalog Page to see if we should show the Manage People Link
      // temporary solition

      this.totalNumOfPages = this.people.totalNumOfPages;

      if (filtersRequired) {
        this.titles = this.people.filterValues['titles'] || [];
        this.roles = this.people.filterValues['roles'] || [];
        this.licenses = [];
        this.statusValues = this.people.filterValues['statusValues'] || [];
      }

      this.totalPeopleInTrack = this.people.totalAssignedEmployees;
      this.totalNumOfPages = this.people.totalNumOfPages;
      await this.setTrackName(this.people.trackOrContentTitle);
    },
    async getEmployees(org, pageNumber, searchText, filterValues = true, titles, roles, licenses, statusValues) {
      this.orgInFocus = org;

      const payload = {
        orgId: org.id,
        pageNum: pageNumber || this.pageNum,
        pageSize: this.pageSize,
        name: searchText || '',
        titles: titles || [],
        roles: roles || [],
        licenses: licenses || [],
        statusValues: statusValues || [],
        filterValuesRequired: filterValues,
        trackGuid: this.trackGuid,
        fetchAddedPeopleOnly: this.tabIndex === 1
      };

      this.people = await this.$planAdmin.getManagePeopleEmpListByOrgId(payload);
      this.totalNumOfPages = this.people.totalNumOfPages;
    },
    async setup() {
      this.setHeaders();
      await this.getUserProfiles();
      this.employees = this.people.employees || [];
      this.generateFilterData();
    },
    generateFilterData() {
      this.filterResults = {
        jobTitleFilterValues: [],
        roleFilterValues: [],
        licenseFilterValues: [],
        statusFilterValues: []
      };
      const keys = ['titles', 'roles', 'licenses', 'statusValues'];

      for (const property of keys) {
        if (this.people.filterValues) {
          const payloadObj = this.people.filterValues[property];

          switch (property) {
            case 'titles':
              for (let name of payloadObj) {
                this.filterResults.jobTitleFilterValues.push({ name });
              }
              break;
            case 'roles':
              for (let name of payloadObj) {
                this.filterResults.roleFilterValues.push({ name });
              }
              break;
            case 'licenses':
              for (let name of payloadObj) {
                this.filterResults.licenseFilterValues.push({ name });
              }
              break;
            case 'statusValues':
              for (let name of payloadObj) {
                this.filterResults.statusFilterValues.push({ name });
              }
              break;
            default:
              break;
          }
        }
      }
    },
    async setHeaders() {
      const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
      this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
      this.headers = [];
      this.headers.push({
        title: translate('common.titleCase.name'),
        key: 'name',
        align: 'start'
      });
      this.headers.push({
        title: translate('common.filter.jobTitle'),
        key: 'title',
        align: 'start'
      });
      this.headers.push({
        title: translate('common.filter.role'),
        key: 'role',
        align: 'start'
      });
      if (!this.isC1 && this.tabIndex !== 0) {
        this.headers.push({
          title: '',
          key: 'dueDateProgress',
          align: 'start',
          width: 54
        });
        this.headers.push({
          title: translate('common.filter.dueDate'),
          key: 'dueDate',
          align: 'start'
        });
      }
      this.headers.push({
        title: translate('common.filter.license'),
        key: 'license',
        align: 'start'
      });
      this.headers.push({
        title: translate('common.filter.status'),
        key: 'status'
      });
    },
    async getUserProfiles() {
      this.people.employees &&
        (await this.people.employees.map(async (employee) => {
          employee.disabled = this.tabIndex === 0 ? employee.alreadyAssigned || employee.addedByRole : !employee.alreadyAssigned || employee.addedByRole;
          employee.select = this.tabIndex === 0 ? Array.from(this.addPeopleSet).some((item) => item === employee.guid) : Array.from(this.removePeopleSet).some((item) => item === employee.guid);
        }));
    },
    async handlePageClick(pageNumber) {
      this.tableDataReady = false;
      this.pageNum = pageNumber;
      const titles = [];
      const roles = [];
      const licenses = [];
      const statusValues = [];

      for (let filter of this.displaySelectedFilters) {
        switch (filter.type) {
          case 'Job Title':
            titles.push(filter.name);
            break;
          case 'Role':
            roles.push(filter.name);
            break;
          case 'License':
            licenses.push(filter.name);
            break;
          case 'Status':
            statusValues.push(filter.name);
            break;
          default:
            break;
        }
      }

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText, true, titles, roles, licenses, statusValues);
      } else {
        await this.getAllEmployees(this.pageNum, this.searchText, true, titles, roles, licenses, statusValues);
      }

      this.sortBy = [];
      this.sortDesc = false;
      //this.isSelected = []
      await this.setup();
      await this.setupPagination();
      this.tableDataReady = true;
    },
    async setupPagination() {
      if (this.employees) {
        const elements = document.getElementsByClassName('pagination');

        if (elements.length && elements[0].childNodes) {
          elements[0].childNodes.forEach((element) => {
            if (element.childNodes.length && element.className.includes('pagination')) {
              const pageNumber = element.childNodes[0].innerHTML;

              if (element.className.includes('pagination-nav')) {
                if (pageNumber === '‹') {
                  element.setAttribute('data-tooltip-content', `Go to previous page`);
                  element.setAttribute('aria-label', `Go to previous page`);
                } else {
                  element.setAttribute('data-tooltip-content', `Go to next page`);
                  element.setAttribute('aria-label', `Go to next page`);
                }
              }
              if (element.className.includes('pagination-item active')) {
                element.setAttribute('aria-current', `page`);
              } else {
                element.setAttribute('aria-current', `false`);
                if (element.className.includes('pagination-item') && !isNaN(pageNumber)) {
                  element.setAttribute('aria-label', `page ${pageNumber}`);
                }
              }
            }
          });
        }
      }
    },
    onExit() {
      this.$router.push('/catalog/landing');
    },
    cancelNavigation() {
      this.notificationDialog = false;
    },
    async onPageSizeChange(size) {
      this.pageSize = size;
      this.pageNum = 1;
      this.handlePageClick(this.pageNum);
    },
    onDataTableChange(tableData) {
      const { page, itemsPerPage, sortBy } = tableData;

      if (this.pageSize !== itemsPerPage) {
        this.onPageSizeChange(itemsPerPage);
      }

      if (this.pageNum !== page) {
        this.handlePageClick(page);
      }

      if (this.sortBy !== sortBy) {
        this.sortBy = sortBy;
        if (sortBy.length) {
          this.employees = this.lodash.orderBy(this.employees, [sortBy[0].key], [sortBy[0].order]);
        } else {
          this.employees = this.people.employees || [];
        }
      }
    },
    checkPastDue(dueDate) {
      dueDate = this.moment(dueDate);
      let offset = dueDate.format('Z');
      offset = offset.includes('-') ? offset.replace('-', '+') : offset.replace('+', '-');
      dueDate.add(offset, 'hours');
      const date = new Date();
      var cEstTime = date.toLocaleString('en-US', {
        timeZone: 'America/New_York'
      });
      const formattedToday = dueDate.format('D MMM YYYY');
      return !this.moment(formattedToday).endOf('day').isAfter(cEstTime);
    },
    getUtcDate(dueDate) {
      dueDate = this.moment(dueDate);
      let offset = dueDate.format('Z');
      offset = offset.includes('-') ? offset.replace('-', '+') : offset.replace('+', '-');
      dueDate.add(offset, 'hours');
      return this.moment(new Date(dueDate)).format('DD MMM YYYY');
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.managePeople')
    };
  }
};
</script>
<style lang="scss">
@mixin scrollable {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 1.25rem;
    width: 0.25rem;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.manage-people-container {
  .manage-people-header {
    margin-top: -5rem;
    .header-title {
      text-align: left;
      font: normal normal normal 2.625rem/3.75rem 'Futura PT Demi';
      letter-spacing: 0rem;
      color: #1f3370;
    }
    .header-sub-title {
      text-align: left;
      font: normal normal normal 1rem/1.5rem Arial;
      letter-spacing: 0rem;
      color: #000000;
    }

    .tabs-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 2rem;

      .tab-buttons {
        display: inline-block;
        position: relative;
        padding: 0rem 2rem;

        .tab {
          color: var(--ion-color-post-gray);
          cursor: pointer;
          font-weight: bold;
          padding-bottom: 0.725rem;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font: normal normal bold 1rem/1.375rem Arial;
          letter-spacing: 0.02rem;

          .tab-pill {
            border-radius: 1.438 rem;
            padding: 0.32rem 0.5rem;
            height: 1.25rem;
            width: fit-content;
            font: normal normal bold 0.875rem/1.875rem Arial;
            letter-spacing: 0.009rem;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0rem 0.5rem;
          }

          .tab-pill {
            background: #e3e3e3;
            color: #000;
          }

          &:first-of-type {
            margin-right: 1.5rem;
          }
          &.selected {
            color: var(--ion-color-black);
            border-bottom: 0.3125rem solid #00d1b0;
            .tab-pill {
              background: #000;
              color: #fff;
            }
          }

          span {
            margin-left: 0.3rem;
            padding: 0rem 0.7rem;
            border-radius: 1rem;
            background: var(--ion-color-light-role-grey);
            font-size: 0.875rem;
            font-family: Arial;
            color: var(--ion-color-black);
          }
        }
      }
      .pagination-buttons {
        position: relative;
        margin-bottom: 0.75rem;

        .v-btn--is-elevated {
          border: none !important;
          box-shadow: none !important;
          border-radius: 1.3125rem;
          backdrop-filter: blur(1.25rem);
          -webkit-backdrop-filter: blur(1.25rem);
        }

        .add-people-button {
          height: 2.9375rem;
          border-radius: 1.375rem;
          background: #ffb92e 0% 0% no-repeat padding-box;

          span {
            font: normal normal normal 1rem/1.375rem 'Futura PT Demi';
            letter-spacing: 0.0013rem;
          }

          &:disabled {
            background-color: rgba(255, 185, 46, 0.5) !important;
            span {
              color: inherit;
            }
          }
        }
        .edit-due-dates-button {
          height: 2.9375rem !important;
          border-radius: 1.375rem;
          span {
            font: normal normal normal 1rem/1.375rem 'Futura PT Demi';
            letter-spacing: 0.0013rem;
          }
          &:disabled {
            background-color: rgba(31, 51, 112, 0.5) !important;
            color: rgba(255, 255, 255, 0.26) !important;
            span {
              color: inherit;
            }
          }
        }
      }
    }
  }

  .section-divider {
    background: #9f9f9f 0% 0% no-repeat padding-box;
    opacity: 0.38;
    width: 100%;
    height: 0.063rem;
    margin-bottom: -0.1875rem;
  }
  .manage-people-actions {
    .manage-people-search-container {
      padding: 1.5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .details-search-text-field .v-input__control {
        min-height: 2.625rem !important;
        width: 27.875rem;
        min-width: 27.875rem;
      }
      .details-search-text-field .mdi-magnify {
        font-size: 1.725rem;
        margin-right: 0.725rem;
      }
      .details-search-text-field {
        .v-input__control,
        .v-btn--is-elevated {
          border: none !important;
          box-shadow: none !important;
          border-radius: 1.3125rem;
          backdrop-filter: blur(1.25rem);
          -webkit-backdrop-filter: blur(1.25rem);
        }
        .v-field--variant-solo {
          background-color: white !important;
          height: 2.625rem;
        }

        .v-field__field,
        .v-field__prepend-inner {
          height: 2.625rem;
        }

        .v-field__input {
          padding-top: 0rem !important;
        }
      }
    }
    .people-selected {
      border-right: 1px solid;
    }
    .manage-people-search-chips {
      padding: 0rem 0rem 1.5rem 0rem;
      display: flex;
      align-items: center;
      flex-direction: row;

      .clear-all-filters {
        text-align: left;
        font: normal normal bold 0.6875rem/1.875rem Arial;
        letter-spacing: -0.0069rem;
        color: #1f3370;
        opacity: 1;
        cursor: pointer;
        display: inline-block;
      }
    }
  }

  .manage-people-table-container {
    display: grid;
    grid-template-columns: 19.6875rem 9fr;
    margin-bottom: 5rem;

    .manage-people-treeview-container {
      .tree-view-header {
        height: 4.6875rem;
        display: flex;
        align-items: center;
        padding: 2rem;
        font: normal normal bold 1rem/1.125rem Arial;
        letter-spacing: 0rem;
        color: #000000;
        border-bottom-left-radius: 0.3125rem;
        border-top-left-radius: 0.3125rem;
        background-color: #d0d0d0;
      }
      .manage-people-treeview {
        margin-right: 2rem;
        overflow: scroll;
        @include scrollable;
        height: 31.25rem;

        .v-treeview-node__label {
          overflow: initial;
          text-overflow: initial;
        }

        .v-treeview-node__root {
          cursor: pointer;
          .v-icon {
            background: #e4e5e4 0% 0% no-repeat padding-box;
            border: 0.0625rem solid #e5e5e5;
            opacity: 1;
            border-radius: 6.25rem;
            height: 1.9375rem;
            width: 1.9375rem;
          }

          .dept-name {
            font: normal normal normal 1rem/2.5rem Arial;
            letter-spacing: 0rem;
            color: #000000;
            opacity: 1;
            cursor: pointer;
          }
        }
        .v-treeview-node__children {
          .v-treeview-node__root {
            .dept-name {
              font: normal normal normal 1rem/2.5rem Arial;
              letter-spacing: 0rem;
              color: #000000;
              opacity: 1;
            }
          }
        }

        .mdi-menu-down::before {
          content: '\F035D';
          content: '\F0140';
        }
        .v-treeview-node--active {
          background: rgba(255, 255, 255, 0.6);
          color: $color-white !important;
          min-width: fit-content;

          .dept-name {
            font-weight: bold !important;
          }
        }
      }
    }
    .v-data-table--fixed-header {
      border: none !important;
    }

    .manage-people-table {
      background: inherit;
      display: grid;
      .svg-icon {
        width: 22px !important;
        height: 23px !important;
        vertical-align: -0.5rem !important;
      }
      .v-table__wrapper {
        @include scrollable;
        height: 35rem !important;
        overflow: auto;
        position: relative;

        &::-webkit-scrollbar-track {
          background: #fff;
          border-radius: 1.25rem;
          margin-right: 7rem;
          margin-top: 4.6875rem;
        }

        &::-webkit-scrollbar:vertical {
          border-radius: 0rem;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #d0d0d0 56%);
          margin-bottom: 1.25rem;
        }
        table {
          tbody {
            overflow: auto;
            @include scrollable;

            .v-data-table-rows-no-data {
              height: 35vh;
              font-size: 1.5rem;
              font-weight: normal;
              font-family: 'Futura PT Demi';
              color: rgba(0, 0, 0, 0.38);
            }
          }

          thead,
          tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed;
          }

          thead th,
          tbody td {
            width: 8.75rem;
          }

          thead th:nth-child(1),
          tbody td:nth-child(1) {
            width: 2rem;
          }

          thead {
            position: sticky;
            z-index: 6;
            top: 0;
            border-bottom-right-radius: 0.3125rem;
            border-top-right-radius: 0.3125rem;
            height: 4.6875rem;
            border-radius: 0.3125rem;
            background: rgba(0, 0, 0, 0.12);
            background: #d0d0d0;
            .v-data-table__td {
              background-color: #d0d0d0;
            }
            span {
              text-align: left;
              font: normal normal bold 1rem/1.125rem Arial;
              letter-spacing: 0rem;
              color: #000000;
              opacity: 1;
            }

            tr th {
              i {
                color: #1f3370;
              }
              .v-ripple__animation {
                color: rgba(0, 0, 0, 0.25);
              }
            }
          }

          tbody {
            tr:hover:not(.v-table__expanded__content) {
              &:nth-child(even) {
                background: #fff;
              }
            }

            .v-input {
              margin: 0rem;
              display: flex;
              align-items: center;
              height: 2.75rem;
              justify-content: center;
              padding-top: 1.375rem;
            }

            .employee-data-field {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 8.125rem;
            }
            .half-opacity {
              opacity: 0.5;
            }

            tr {
              td {
                height: 2.75rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 15rem;
              }

              i {
                color: #1f3370;
              }

              .v-input {
                &:not(.v-input--is-disabled) {
                  .mdi-checkbox-blank-outline {
                    border-radius: 0.25rem;
                    color: #1f3370;
                  }
                }
              }
            }

            tr:nth-child(even) {
              td {
                background: #fff;
              }
            }
            tr:nth-child(odd) {
              td {
                background: #efefef 0% 0% no-repeat padding-box;
              }
            }
            tr.group-active-row-selection > td {
              transition: all 0.7s ease-in-out;
            }
            tr.group-active-row-selection > td > div {
              padding: 1.25rem;
              height: 2.5rem;
            }
            @keyframes anim {
              0% {
                transform: scale(1, 0);
              }
              100% {
                transform: scale(1, 1);
              }
            }
            tr.group-inactive > td {
              opacity: 0;
              line-height: 0;
              padding: 0 0.625rem;
              height: 0 !important;
            }
          }
          .v-data-table__td {
            border: none !important;
          }
        }
      }
      .v-data-table-footer {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        padding: 0 0.5rem;
        justify-content: center;
        margin-top: 24px;
      }
    }

    .mange-people-loader {
      margin: 3.125rem;
      text-align: center;
      width: 56rem;

      .v-input {
        display: contents !important;
        .v-field__overlay {
          display: none;
        }
      }

      .loader-text {
        font-size: 1.875rem;
        color: $color-primary;
      }

      .loader-bar {
        position: relative;
        top: -1.875rem;
      }
    }
  }

  .manage-people-pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #efefef 0% 0% no-repeat padding-box;
    position: relative;
    height: 8.25rem;

    .pagination {
      display: flex;
      justify-content: center;
      list-style-type: none;
      padding: 0rem;
      width: 50%;
    }

    .pagination-item {
      margin: 0 0.313rem;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    li.pagination-nav {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .pagination-item-link {
      color: var(--ion-color-post-gray);
      font-size: 0.875rem !important;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .pagination-nav-link {
      position: relative;
      color: #5c5c5c;
      font-size: 3rem !important;
      vertical-align: middle;
      font-family: 'Futura PT Book';
      width: inherit;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.9375rem;
    }

    li.disabled {
      border: none;
      cursor: default;
      .pagination-item-link {
        cursor: default;
      }
    }

    li.active {
      border: 0.125rem solid #1dd1b0;
      border-radius: 0.25rem;
      background: white;
      .pagination-item-link {
        color: #000000;
      }
    }

    li:hover {
      background: #e0e1e1;
    }

    li .tooltiptext {
      visibility: hidden;
      width: max-content;
      height: 1.875rem;
      background-color: #efefef;
      color: #000000de;
      text-align: center;
      padding: 0.4375rem 0.625rem;
      border-radius: 0.25rem;
      position: absolute;
      z-index: 1;
      bottom: 90%;
      left: 50%;
      margin-left: -1.25rem;
      font-size: 0.75rem;
      font-family: 'Futura PT Demi';
    }

    .li:hover .tooltiptext {
      visibility: visible;
    }

    .pagination-tooltip {
      position: relative;
    }

    .pagination-tooltip::after {
      content: attr(data-tooltip-content);
      padding: 0.5rem;
      border-radius: 0.25rem;
      position: absolute;
      color: white;
      bottom: 135%;
      right: 50%;
      left: 50%;
      width: max-content;
      opacity: 0;
      font-size: 0.8rem;
      visibility: hidden;
      transform: translate(-50%, 1.125rem) scale(0.8);
      transition: visibility, opacity, transform 250ms;
      text-align: left;
      font: normal normal normal 0.75rem/1rem Arial;
      letter-spacing: 0rem;
      color: #000000de;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0rem 0.125rem 0.375rem #0000001f;
      border-radius: 0.25rem;
    }

    .pagination-tooltip:hover::after {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}

.manage-people-tooltip {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  width: fit-content;

  &:hover .tooltip-text {
    visibility: visible;
  }

  transition: all 0.5s ease-in-out;

  .v-input {
    padding: 0rem !important;
  }
}

.manage-people-tooltip {
  .tooltip-text {
    width: 28.5rem;
    position: absolute;
    z-index: 1;
    background: #ddd 0 0 no-repeat padding-box;
    border-radius: 0.25rem;
    font: normal normal normal 1rem/1.375rem Arial;
    letter-spacing: 0;
    color: #5c5c5c;
    padding: 0.725rem 2rem !important;
    top: 2.5rem;
    left: -1rem;
    &:hover {
      visibility: visible;
    }
  }
}
</style>
