<template>
  <div>
    <v-card>
      <div class="header">
        <v-tooltip bottom>
          <template v-slot:activator="{ props }">
            <v-btn data-el="addNewRole" v-if="!notAddRole" rounded dark color="#1f3370" class="add-role action-btn white--text redesign-btn" @click="onCreateRole" v-bind="props">{{ $t('roleExplorer.createNewRole') }}</v-btn>
          </template>
          <span>{{ $t('roleExplorer.addNewRole') }}</span>
        </v-tooltip>
        <h2 class="t-pb-2 t-font-futura-pt-demi t-text-xl t-text-primary-color h2">{{ $t('roleExplorer.roleList') }}</h2>
      </div>
      <v-text-field
        variant="solo"
        data-el="searchRole"
        v-model="searchRole"
        class="search"
        hide-details
        prepend-inner-icon="mdi-magnify"
        single-line
        :label="$t('roleExplorer.searchPlaceholder')"
        :aria-label="ariaLabel"
        filled
        rounded
        clearable
        @keyup.enter="onSearchRole()"
        @click:clear="onClearSearchRole"
        density="compact"
      >
      </v-text-field>
      <v-row dense style="margin-top: 0.5rem">
        <v-col md="6" sm="12">
          <v-select ref="displayFunctionRef" :menu-props="{ bottom: true, offsetY: true }" v-model="selectedFunction" :items="displayFunctions" :label="$t('common.filter.function')" @update:modelValue="handleFunctionChange" dense outlined variant="outlined" density="compact">
            <template v-slot:prepend-item>
              <div>
                <!-- this resolves an issue with: ResizeObserver loop completed with undelivered notifications. -->
              </div>
            </template>
          </v-select>
        </v-col>
        <v-col md="6" sm="12">
          <SelectView
            ref="displayDepartmentRef"
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="selectedDepartment"
            :items="displayDepartments"
            :label="$t('common.filter.department')"
            @update:modelValue="handleDepartmentChange"
            dense
            outlined
            variant="outlined"
            density="compact"
          />
        </v-col>
      </v-row>
      <div class="roles scrollable" :key="displayRoles.length">
        <div
          data-el="selectRole"
          v-for="(role, index) in displayRoles"
          :key="index"
          class="role"
          @click="onSelectRole(role)"
          tabindex="0"
          @keyup.enter="onSelectRole(role)"
          :aria-label="`${encodeDecodeRole(role.name)}`"
          :aria-selected="$route.params.guid === role.guid ? 'true' : 'false'"
          role="button"
          :class="$route.params.guid === role.guid ? $t('common.select') : ''"
        >
          {{ encodeDecodeRole(role.name) }}
        </div>
        <div v-if="!displayRoles.length" style="padding: 1rem">{{ $t('roleExplorer.thereIsNoRole') }} {{ $sanitize(searchRole) }}</div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { handleRoleFunctionChange, handleRoleDepartmentChange, encodeDecodeRole } from '@/utils';
import SelectView from '@/components/molecules/SelectView';

export default {
  name: 'RoleStructure',
  components: { SelectView },
  props: {
    roles: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    functions: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    departments: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    notAddRole: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      displayRoles: [],
      selectRole: null,
      searchRole: '',
      ariaLabel: '',
      displayDepartments: this.departments,
      displayFunctions: this.functions,
      selectedFunction: '',
      selectedDepartment: '',
      functionFlag: false,
      departmentFlag: false,
      encodeDecodeRole
    };
  },
  computed: {
    ...mapGetters(['searchedRole', 'selectedFunc', 'selectedDept', 'clearSearchResult', 'enabledFeatureFlags'])
  },
  watch: {
    /********
     * watch the state getters for changes and clear the variables in the component
     * on forceful/tab navigation changes
     */
    async clearSearchResult(value) {
      if (value) {
        this.onClearSearchRole();
        await this.setClearSearchResult(false);
      }
    },

    async $route(to, from) {
      if (to.name != 'RoleDetails' && from.name != 'RoleExplorer') {
        this.selectedFunction = '';
        await this.handleFunctionChange(this.selectedFunction);
        this.selectedDepartment = '';
        await this.handleDepartmentChange(this.selectedDepartment);
        this.displayRoles = this.roles;
        this.displayDepartments = this.departments;
        this.displayFunctions = this.functions;
      }
    }
  },
  async mounted() {
    this.searchRole = this.searchedRole;
    this.onSearchRole();
    this.selectedFunction = this.selectedFunc;
    this.selectedDepartment = this.selectedDept;
    if (this.selectedFunction) this.handleFunctionChange(this.selectedFunction);
    if (this.selectedDepartment) this.handleDepartmentChange(this.selectedDepartment);
    this.displayRoles = this.roles;
    analytics.setVarAndTrack({ c: 'RoleStructure', l: 'RoleList', a: 'load' });
    this.$gtag.event('RoleStructure', {
      event_category: 'load',
      event_label: 'RoleList',
      value: 1
    });
  },
  methods: {
    ...mapActions('plan', ['setSearchedRole', 'setClearSearchResult']),
    onSelectRole(role) {
      this.selectRole = role;
      this.$router.push({ name: 'RoleDetails', params: { guid: role.guid }, props: { role: role } }).catch(() => {});
      analytics.setVarAndTrack({ c: 'RoleStructure', l: encodeURIComponent(this.selectRole.name), a: 'select searched role' });
      this.$gtag.event('RoleStructure', {
        event_category: 'select searched role',
        event_label: encodeURIComponent(this.selectRole.name),
        value: 1
      });
    },
    onCreateRole() {
      this.$router.push({ path: '/role-explorer/create-role' });

      analytics.setVarAndTrack({ c: 'RoleStructure', l: 'Create new role', a: 'add new role' });
      this.$gtag.event('RoleStructure', {
        event_category: 'add new role',
        event_label: 'Create new role',
        value: 1
      });
    },
    async onSearchRole() {
      if (this.searchRole && this.searchRole.length > 2) {
        this.setSearchedRole(this.searchRole);
        this.displayRoles = await this.roles.filter((role) => role.name.toLowerCase().includes(this.searchRole.toLowerCase()));
        analytics.setVarAndTrack({ c: 'RoleStructure', l: this.searchRole, a: 'search exisiting role' });
        this.$gtag.event('RoleStructure', {
          event_category: 'search existing role',
          event_label: this.searchRole,
          value: 1
        });
      } else {
        this.displayRoles = this.roles;
      }
      if (this.displayRoles.length == 0) {
        this.ariaLabel = `There is no role ${this.searchRole}`;
      } else if (this.searchRole.length == 0) {
        this.ariaLabel = 'search';
      } else {
        this.ariaLabel = `${this.displayRoles.length} result found`;
      }
    },
    async handleFunctionChange(val) {
      await handleRoleFunctionChange(this, val);
    },
    async handleDepartmentChange(val) {
      await handleRoleDepartmentChange(this, val);
    },
    onClearSearchRole() {
      this.setSearchedRole('');
      this.selectRole = '';
      this.searchRole = '';
      this.displayRoles = this.roles;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-overlay--absolute {
  * {
    min-width: 22rem;
  }
}

.v-card {
  border-radius: 0.625rem;
  padding: 1.25rem 1.25rem;
  min-height: 54.0625rem;
}

.header {
  border-bottom: 0.125rem solid $color-grey-light;
  margin-bottom: 0.5rem;
  .icon {
    float: left;
    border-radius: 50%;
    background: #3b4572;
    width: 1.5625rem;
    height: 1.5625rem;
    .role-icon {
      // fill: white;
      display: inline-block;
      margin: auto;
      padding: auto;
    }
  }
  .h2 {
    font-weight: normal;
  }
  .search {
    padding: 0;
    float: right;
    width: 50%;
    position: relative;
    top: -0.5rem;
  }
  .add-role {
    width: 1.875rem;
    height: 1.875rem;
    float: right;
    position: relative;
    top: -0.1rem;
  }
  .add-role.redesign-btn {
    min-width: 1.875rem;
    width: fit-content;
  }
  .add-role:focus-visible {
    outline: black 0.125rem solid;
    outline-offset: 0.0625rem;
  }
}

.structure {
  padding-bottom: 0.625rem;
  font-weight: bold;
  color: $color-primary;
  font-size: 1.125rem;
}

.roles {
  padding: 0rem 0rem 1rem 0rem;
  width: 100%;
  height: 45.625rem;
  overflow: hidden;
  overflow-y: auto;
  font-size: 1rem;
  .role {
    margin: 0.5rem 0;
    cursor: pointer;
    padding-left: 1rem;
  }
  .select {
    background: $color-focus;
    color: $color-link-hover;
    font-weight: bold;
    border-radius: 1.25rem;
    padding: 0.5rem 1rem;
  }
}

.footer {
  margin-top: 1rem;
  border-top: 0.125rem solid $color-grey-light;
}

.v-btn {
  width: 9.375rem;
  text-transform: none;
  color: #fff !important;
}

.create-button {
  background: $button-primary-color-bg !important;
  color: $button-primary-color-text !important;
}

.scrollable {
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: $color-grey-lighter;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 1.25rem;
    width: 0.25rem;
  }
}
</style>
