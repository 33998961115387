<template>
  <div class="workforce-planner-tab-container">
    <div v-if="showHeader" class="tab-container">
      <div v-for="(tab, index) in tabs" :key="index" class="tab" :class="tabIndex === index ? 'selected' : null" @click="setTabIndex(index)">{{ tab.title }}</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'WorkforcePlannerTab',
  emits: ['updateTabIndex', 'get-profile', 'invite-count'],
  props: {
    showHeader: { type: Boolean, default: true },
    tabs: { type: Array, required: true },
    selected: {
      type: Number,
      required: false,
      default: () => {
        return 0;
      }
    }
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  created() {
    this.tabIndex = this.selected;
  },
  mounted() {
    this.EventBus.on('reset-tab-index', this.setTabIndex);
  },
  methods: {
    setTabIndex(index) {
      index = index == undefined ? 0 : index;
      this.tabIndex = index;
      this.$emit('updateTabIndex', index);
    }
  }
};
</script>

<style lang="scss" scoped>
.workforce-planner-tab-container {
  margin: 0px 0px 0px 10px;
  @media screen and (max-width: 1280px) {
    margin: 0px 0px 0px 0px;
  }
}

.tab-container {
  border-bottom: 2px solid $color-grey-light;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  width: 100%;

  .tab {
    font-size: 18px;
    margin-right: 25px;
    color: $color-grey;
    padding-bottom: 10px;

    &.selected {
      color: $color-primary;
      border-bottom: 4px solid $color-primary;
      margin-bottom: -2px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .search {
    // float: right;
    position: absolute;
    right: 5%;
    font-size: 14px;
  }
}

.icon {
  margin: 10px 50px;
  float: right;
  .v-icon {
    margin-right: 10px;
  }
}
</style>
