<template>
  <div role="tooltip" :id="lodash.uniqueId()" class="tooltip-text" :style="{ width: width }">
    <span class="tooltip-text-header">Tip</span>
    <p class="tooltip-text-content">{{ content }}</p>
  </div>
</template>
<script>
export default {
  name: 'ToolTip',
  props: {
    content: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '28.5rem'
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip-text {
  visibility: hidden;
  width: 28.5rem;
  position: absolute;
  z-index: 1;
  background: #dddddd 0% 0% no-repeat padding-box;
  border-radius: 0.25rem;
  font: normal normal normal 1rem/1.375rem Arial;
  letter-spacing: 0rem;
  color: #5c5c5c;
  padding: 0.725rem 2rem !important;
  top: 1.875rem;
  left: 0;

  .tooltip-text-header {
    margin-bottom: 0.5rem;
    font: normal normal bold 0.875rem/1.375rem Arial;
    letter-spacing: 0.0013rem;
    color: #1f3370;

    &:after {
      position: absolute;
      width: 0;
      height: 0;
      border-left: 0.375rem solid transparent;
      border-right: 0.375rem solid transparent;
      border-bottom: 0.375rem solid $color-primary;
      content: '';
      left: 3.75rem;
      top: 1.3125rem;
    }
  }
  .tooltip-text-content {
    font: normal normal normal 1rem/1.375rem Arial;
    color: #5c5c5c;
  }
}
</style>
