<template>
  <v-dialog :model-value="dialog" @input="closeDialog" @click:outside="closeDialog()" content-class="vdialog-new" retain-focus>
    <v-card v-if="catalogItem" style="margin: 0; border-radius: 0; min-height: 450px; padding: 3rem; width: 97%">
      <v-row justify="end">
        <v-btn class="float-right" width="36" :aria-label="`${catalogItem.title} add to track`" variant="text" @click="addItemToLearningTrack(index)" icon>
          <v-icon class="plus-icon t-text-black">{{ 'mdi-plus' }}</v-icon>
        </v-btn>
        <v-btn class="float-right t-text-black" width="36" icon :aria-label="`${catalogItem.title} close more info`" variant="text" @click="closeDialog()">
          <v-icon class="collapse-icon">{{ 'mdi-arrow-collapse' }}</v-icon>
        </v-btn>
      </v-row>
      <v-row style="margin: 0px 0px 24px 0">
        <v-col cols="8" style="padding: unset !important">
          <div style="margin-bottom: 28px">
            <span class="t-uppercase t-rounded-full t-font-bold t-text-xs t-mb-0.5 t-h-6 t-font-arial t-px-3 t-py-1 t-mr-3" :class="dataFormatBg[catalogFormatTypeLowercase]">{{
              catalogItem.formatType ? getLocalizedContentType(catalogItem.formatType) : getLocalizedContentType('Credential')
            }}</span>
            <span>
              {{ catalogItem.provider && catalogItem.provider }}
            </span>
          </div>
          <div class="t-font-futura-pt-demi t-text-xl" style="text-align: left; line-height: 20px; color: #1f3370">
            {{ catalogItem.title }}
          </div>
          <div v-if="catalogItem.contentFeature && catalogItem.contentFeature.length" class="content-feature">
            <!-- <div v-for="(contentFeature, index) in catalogItem.contentFeature" :key="index"> -->
            {{ getContentFeature(catalogItem.contentFeature) }}
            <!-- </div> -->
          </div>
        </v-col>
        <v-col cols="1" align-self="end" v-if="catalogItem.isRetiring">
          <div class="retier">
            <span>
              <svg-icon icon-class="clock-grey" />
              {{ $t('catalog.retirementText') }}
            </span>
            <span class="retier-tooltip-text">{{ $t('catalog.retirementMessage') }}{{ getLocalDateFormat(catalogItem.retirementDate) }}</span>
          </div>
        </v-col>

        <v-col :cols="catalogItem.isRetiring ? 1 : 2" align-self="end" class="publish-duration t-flex t-justify-start t-font-bold" style="text-align: center; font-size: 12px">
          <p v-if="catalogItem.duration" class="duration">
            <span class="duration-text">
              <span>{{ convertDuration(catalogItem.duration) }}</span>
              {{ catalogItem.durationType }}
            </span>
          </p>
        </v-col>
        <v-col cols="2" align-self="end" class="t-flex t-font-bold t-justify-start t-m-0" style="text-align: center; font-size: 12px; padding-bottom: 18px">
          <p v-if="catalogItem.levels" class="t-m-0">
            <span>
              <span>{{ $t('catalog.dialogLevelAllCaps') }}</span>
              {{ getLocalizedLevel(catalogItem.levels) }}
            </span>
          </p>
        </v-col>
      </v-row>

      <CourseCard :item="catalogItem" />
    </v-card>
  </v-dialog>
</template>
<script>
import CourseCard from '@/views/catalog/components/CourseCard';
import { dataFormatBg, getLocalizedContentType, getLocalizedLevel, convertDuration, getLocalDateFormat } from '@/utils';
export default {
  data() {
    return {
      dataFormatBg,
      getLocalizedContentType,
      getLocalizedLevel
    };
  },
  props: {
    catalogItem: {
      type: Object,
      require: true,
      default: () => {
        return {};
      }
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      }
    },
    dialog: {
      Boolean,
      default: () => {
        return false;
      }
    }
  },
  methods: {
    convertDuration,
    getLocalDateFormat,
    closeDialog() {
      this.$emit('close-dialog');
      document.documentElement.style.overflow = 'scroll';
      document.body.scroll = 'yes';
      document.getElementById('learning-track-container').classList.add('t-overflow-y-scroll');
    },
    addItemToLearningTrack(index) {
      this.closeDialog();
      this.$emit('add-track-item', index);
    },
    getContentFeature(contentFeature) {
      if (contentFeature) return contentFeature.join(', ');
    }
  },
  components: {
    CourseCard
  },
  computed: {
    catalogFormatTypeLowercase() {
      return this.catalogItem?.formatType?.toLocaleLowerCase();
    }
  }
};
</script>
<style lang="scss">
.vdialog-new {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  max-height: 550px !important;
  max-width: 80%;
  margin-bottom: 50px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
<style lang="scss" scoped>
.duration {
  margin: 0px !important;
  padding-top: 2px;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid $color-grey-lighter;
}
.plus-icon,
.collapse-icon {
  border: 1px solid $color-grey-light;
  border-radius: 50%;
  padding: 1px 0px 0px 1px;
  background: #ffb92e;
}
.plus-icon {
  &:hover {
    background-color: #1f3370;
    color: #fff !important;
  }
}
.collapse-icon {
  background-color: white;
  &:hover {
    background-color: #1f3370;
    color: #fff;
  }
}
.duration-text {
  font-size: 8px;
  font-family: 'Arial';
  font-weight: bold;
  max-width: 4ch;
  text-align: center;
  margin: 2px 0px 0px 0px;
  span {
    font-size: 8px;
    display: block;
  }
}
.publish-duration {
  padding-left: 56px;
  p {
    line-height: 8px;
  }
}
.retier {
  @include body-font-bold-0-875;
  color: #2d2d2d;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 2px solid var(--Text-Body-DarkGrey, #2d2d2d);
  position: relative;
  .retier-tooltip-text {
    white-space: nowrap;
    visibility: hidden;
    text-align: center;
    padding: 8px 16px;
    position: absolute;
    z-index: 1;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1215686275);
    border-radius: 4px;
    opacity: 1;
    @include body-font-0-75;
    top: -45px;
    left: 0;
    color: rgba(0, 0, 0, 0.8705882353);
  }
}
.retier {
  background: none;
}
.retier:hover .retier-tooltip-text {
  visibility: visible;
}
.content-feature {
  display: flex;
  gap: 2rem;
  color: #2d2d2d;
  font-size: 14px;
}

@media screen and (max-width: 1600px) {
  .publish-duration {
    padding-left: 48px;
  }
}

@media screen and (max-width: 1440px) {
  .publish-duration {
    padding-left: 68px;
  }
}
</style>
