<template>
  <div class="page-404-container">
    <h1>404</h1>
  </div>
</template>
<script>
export default {
  name: '404'
};
</script>
<!-- this page needs a redesign  -->
<style lang="scss">
.page-404-container {
  display: grid;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
