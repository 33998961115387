<template>
  <div class="content-consumption-container">
    <div id="chart" v-if="dataReady">
      <div class="chart-actions">
        <v-btn name="download" icon aria-label="download Content consumption Trends" title="download Content consumption Trends" class="consumptionTrendsFocus" @click="downloadPNG()"><svg-icon icon-class="PNG_icon" class="t-w-8 t-h-8" /></v-btn>
      </div>
      <apexchart ref="ConsumptionTrendsChart" type="bar" height="350" :options="getChartOptions()" :series="trendsChartData.chartData"></apexchart>
    </div>
    <div v-else class="loader t-flex t-justify-center t-items-center">
      <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Learning Track" />
    </div>
  </div>
</template>

<script>
import localforage from 'localforage';
import { getDateForCSV, generateCSVTimeFilter, numberWithCommas, localizedMonthYR, localizedMonthName, localizedWeekName } from '@/utils';
import { translateTC, translate } from '@/plugins/i18n.js';

export default {
  name: 'ConsumptionTrends',
  props: {
    timeFilter: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    drillDownTableFilters: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    searchInputText: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    statusCategory: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    dateRange: {
      type: Object
    }
  },
  async mounted() {
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
  },
  updated() {
    this.isComponentRefreshed = false;
  },
  watch: {
    timeFilter: function (newVal, oldVal) {
      if (this.timeFilter.length && newVal !== oldVal) {
        this.getConsumptionTrends();
        this.trendsChartData = {};
        this.dataReady = false;
        this.isComponentRefreshed = true;
      }
    },
    statusCategory: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getConsumptionTrends();
        this.trendsChartData = {};
        this.dataReady = false;
        this.isComponentRefreshed = true;
      }
    },
    dateRange: {
      handler() {
        if (!this.isComponentRefreshed && this.timeFilter) {
          this.getConsumptionTrends();
          this.trendsChartData = {};
          this.dataReady = false;
          this.isComponentRefreshed = true;
        } else {
          this.isComponentRefreshed = false;
        }
      },
      deep: true
    }
  },
  data() {
    return {
      isC1: null,
      trendsChartData: {},
      dataReady: false,
      isComponentRefreshed: false
    };
  },
  methods: {
    getDateForCSV,
    generateCSVTimeFilter,
    localizedMonthYR,
    localizedMonthName,
    localizedWeekName,
    async getConsumptionTrends() {
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      const payload = {
        tenantGuid: tenantGuid,
        timePeriod: this.timeFilter,
        statusCategory: this.statusCategory,
        startDate: this.timeFilter === 'CUSTOM' ? this.dateRange.startDate : '',
        endDate: this.timeFilter === 'CUSTOM' ? this.dateRange.endDate : '',
        dateFormat: 'MM-dd-yyyy'
      };
      await this.$planAdmin
        .getConsumptionTrends(payload)
        .then((response) => {
          if (response) {
            this.trendsChartData = response;
            let allArray = [];
            if (payload.statusCategory == 'ALL') {
              //add the InProgress and Completed values into a single array
              for (const contentType of ['COURSE', 'CREDENTIAL', 'VIDEO', 'AUDIO', 'ARTICLE']) {
                const arrayNameInProgress = contentType + '_IN_PROGRESS';
                const arrayNameCompleted = contentType + '_COMPLETED';
                const inProgressArray = response.chartData.find((obj) => obj['name'] == arrayNameInProgress).data;
                const completedArray = response.chartData.find((obj) => obj['name'] == arrayNameCompleted).data;
                let sum = inProgressArray.map(function (num, idx) {
                  return num + completedArray[idx];
                });
                allArray.push({ name: arrayNameInProgress, data: sum });
              }
              this.trendsChartData.chartData = allArray;
            }
            this.dataReady = true;
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    getChartOptions() {
      return {
        colors: [
          function ({ seriesIndex, w }) {
            switch (w.config.series[seriesIndex]?.name) {
              case 'COURSE_COMPLETED':
                return '#2D2D2D';
              case 'COURSE_IN_PROGRESS':
                return '#2D2D2D';
              case 'CREDENTIAL_COMPLETED':
                return '#00A78D';
              case 'CREDENTIAL_IN_PROGRESS':
                return '#00A78D';
              case 'VIDEO_COMPLETED':
                return '#5C5C5C';
              case 'VIDEO_IN_PROGRESS':
                return '#5C5C5C';
              case 'AUDIO_COMPLETED':
                return '#FFC758';
              case 'AUDIO_IN_PROGRESS':
                return '#FFC758';
              case 'ARTICLE_COMPLETED':
                return '#E47545';
              case 'ARTICLE_IN_PROGRESS':
                return '#E47545';
              default:
                break;
            }
          }
        ],
        chart: {
          toolbar: {
            show: false
          },
          type: 'bar',
          height: 350,
          stacked: true,
          zoom: {
            enabled: true
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
                markers: {
                  radius: 20
                }
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            columnWidth: 30
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories:
            this.timeFilter == 'ALLTIME'
              ? this.formatMonthYR(this.trendsChartData.axisReferenceData)
              : this.timeFilter == 'YEAR'
              ? this.formatMonthYR(this.trendsChartData.axisReferenceData)
              : this.timeFilter == 'DAYS90'
              ? this.formatWeekName(this.trendsChartData.axisData)
              : this.trendsChartData.axisData
        },
        yaxis: {
          title: {
            text: translateTC('common.titleCase.count'),
            rotate: 360,
            offsetX: -8,
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica',
              color: '#5C5C5C'
            }
          },
          labels: {
            formatter: function (val) {
              return numberWithCommas(val.toFixed(0));
            }
          }
        },
        tooltip: {
          y: {
            title: {
              formatter: function (val) {
                return val == 'COURSE_COMPLETED'
                  ? 'Course'
                  : val == 'COURSE_IN_PROGRESS'
                  ? 'Course'
                  : val == 'CREDENTIAL_COMPLETED'
                  ? 'Credential'
                  : val == 'CREDENTIAL_IN_PROGRESS'
                  ? 'Credential'
                  : val == 'VIDEO_COMPLETED'
                  ? 'Video'
                  : val == 'VIDEO_IN_PROGRESS'
                  ? 'Video'
                  : val == 'AUDIO_COMPLETED'
                  ? 'Audio'
                  : val == 'AUDIO_IN_PROGRESS'
                  ? 'Audio'
                  : val == 'ARTICLE_COMPLETED'
                  ? 'Article'
                  : val == 'ARTICLE_IN_PROGRESS'
                  ? 'Article'
                  : '';
              }
            }
          }
        },
        legend: {
          position: 'right',
          offsetY: 80,
          offsetX: -20,
          inverseOrder: true,
          horizontalAlign: 'center',
          formatter: (val) => {
            return val == 'COURSE_COMPLETED'
              ? translate('insights.content.course')
              : val == 'COURSE_IN_PROGRESS'
              ? translate('insights.content.course')
              : val == 'CREDENTIAL_COMPLETED'
              ? translate('insights.content.credential')
              : val == 'CREDENTIAL_IN_PROGRESS'
              ? translate('insights.content.credential')
              : val == 'VIDEO_COMPLETED'
              ? translate('insights.content.video')
              : val == 'VIDEO_IN_PROGRESS'
              ? translate('insights.content.video')
              : val == 'AUDIO_COMPLETED'
              ? translate('insights.content.audio')
              : val == 'AUDIO_IN_PROGRESS'
              ? translate('insights.content.audio')
              : val == 'ARTICLE_COMPLETED'
              ? translate('insights.content.article')
              : val == 'ARTICLE_IN_PROGRESS'
              ? translate('insights.content.article')
              : '';
          },
          markers: {
            radius: '50%',
            offsetX: -5
          },
          itemMargin: {
            horizontal: 5,
            vertical: 8
          }
        },
        fill: {
          opacity: 1
        }
      };
    },
    formatMonthYR(xaxisArray) {
      return xaxisArray.map((x) => {
        return localizedMonthYR(x);
      });
    },
    formatMonthName(xaxisArray) {
      return xaxisArray.map((x) => {
        return localizedMonthName(x);
      });
    },
    formatWeekName(xaxisArray) {
      return xaxisArray.map((x) => {
        return localizedWeekName(x);
      });
    },
    downloadPNG() {
      this.$refs.ConsumptionTrendsChart.dataURI().then(({ imgURI }) => {
        let a = document.createElement('a');
        a.setAttribute('id', 'download_png-anchor');
        a.href = imgURI;
        a.setAttribute('download', `Org Consumption Trend Chart_${this.generateCSVTimeFilter(this.timeFilter)}_${this.getDateForCSV()}`);
        a.click();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.theme--light.v-btn.v-btn--icon:focus-visible {
  outline: black 2px solid;
  border-radius: unset;
  color: white;
}
.consumptionTrendsFocus:focus-visible {
  height: 32px;
  width: 32px;
  margin-top: 2.4px;
  margin-right: 2px;
  margin-bottom: 2px;
}
.content-consumption-container {
  height: 100%;
  .chart-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
  }
  .loader {
    text-align: center;
    height: 100%;

    .loader-text {
      font-size: 1.875rem;
      color: $color-primary;
    }

    .loader-bar {
      position: relative;
      top: -1.875rem;
    }
  }
}
</style>
