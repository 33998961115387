import axios from 'axios';
import { RecommendTrackContainer } from '../model/recommend-track';
import { GetTrackDueDates } from '../model/get-track-due-dates';
import { getMetaData } from '../utils/helper';

export default {
  async basicLearningTrackPostRequest(url, payload) {
    const { tokenHeader } = await getMetaData();
    const response = await axios.post(url, payload, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });

    const resultSet = new RecommendTrackContainer(response.data);
    return resultSet.getDataObject();
  },
  async getTracksForRole(url) {
    const { tokenHeader } = await getMetaData();

    const response = await axios.get(url, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });

    return response.data;
  },
  async getTracksForUser(url) {
    const { tokenHeader } = await getMetaData();

    const response = await axios.get(url, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });

    return response.data;
  },
  async createNewTrack(url, payload) {
    const { jsonHeaders } = await getMetaData();

    const response = await axios.post(url, payload, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async editCreatedTrack(url, payload) {
    const { jsonHeaders } = await getMetaData();

    const response = payload.body
      ? await axios.put(url, payload.body, { headers: jsonHeaders }).catch((error) => {
          return { data: error.response?.data };
        })
      : await axios.put(url, payload, { headers: jsonHeaders }).catch((error) => {
          return { data: error.response?.data };
        });
    return response.data;
  },
  async deleteCreatedTrack(url) {
    const { jsonHeaders } = await getMetaData();

    const response = await axios.delete(url, { headers: jsonHeaders }).catch((error) => {
      return { data: error };
    });
    console.log(response, 'api res');
    return response.data;
  },
  async editSkillBasedTrack(url, payload) {
    const { jsonHeaders } = await getMetaData();

    const response = await axios.post(url, payload, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    const resultSet = new RecommendTrackContainer(response.data);
    return resultSet.getDataObject();
  },
  async getEditTrackDetails(url) {
    const { jsonHeaders } = await getMetaData();

    const response = await axios.get(url, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async getTrackDetails(url) {
    const { tokenHeader } = await getMetaData();

    const response = await axios.get(url, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async getTrackItemsList(url, payload) {
    const { tokenHeader } = await getMetaData();

    const response = await axios.post(url, payload.employeeGuids, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    const resultSet = new GetTrackDueDates(response.data);
    return resultSet.getDataObject();
  },
  async saveDueDates(url, payload) {
    const { tokenHeader } = await getMetaData();

    const response = await axios.post(url, payload, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async learningTrackPostRequest(url, payload) {
    const { tokenHeader } = await getMetaData();

    const response = await axios.post(url, payload, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async learningTrackGetRequest(url) {
    const { tokenHeader } = await getMetaData();

    const response = await axios.get(url, { headers: tokenHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  }
};
