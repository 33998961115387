<template>
  <v-list v-if="!(submittedAsset.reviewStatus == 'rejected' || submittedAsset.reviewStatus == 'approved' || submittedAsset.reviewStatus == 'In Edit')">
    <SaveDialog v-if="showDialog" :saveDialog="showDialog" :status="status" @confirm="onConfirm($event)" @cancel="onCancel()" :adminEdited="isAdminEdited" />
    <v-list-item>
      <h2 style="font-size: 1.5rem" class="t-mb-8 t-font-futura-pt-demi t-font-normal">Review actions</h2>
      <div class="t-flex t-mb-4 t-items-center t-p-0" v-if="submittedAsset.reviewStatus == 'submitted' || submittedAsset.reviewStatus == 'resubmitted'">
        <div class="t-mr-4">
          <svg-icon class="action-icon t-cursor-pointer t-px-2 t-py-1 t-rounded-full t-h-8 t-w-8 t-bg-white" @click="onReviewSubmit('returned')" icon-class="return-up-back" />
        </div>
        <div>
          <div class="t-text-primary-color t-font-futura-pt-demi t-text-base">Return to submitter</div>
        </div>
      </div>
      <div class="t-flex t-mb-4 t-items-center t-p-0 t-relative" v-else-if="submittedAsset.reviewStatus == 'returned'">
        <svg-icon icon-class="icon-alert" class="t-h-5 t-w-5 t-absolute t-top-0 t-left-0" />
        <p class="t-text-sm t-m-0 t-pl-6">This asset was sent back to the submitter and hasn’t been resubmitted yet. You may want to hold off approving or rejecting it.</p>
      </div>
      <div class="t-flex t-mb-4 t-items-center t-p-0" v-if="submittedAsset.reviewStatus != 'approved'">
        <div class="t-mr-4">
          <svg-icon class="action-icon t-cursor-pointer t-px-2 t-py-1 t-rounded-full t-h-8 t-w-8 t-bg-white" @click="onReviewSubmit('approved')" icon-class="thumbs-up-outline" />
        </div>
        <div>
          <div class="t-text-primary-color t-font-futura-pt-demi t-text-base">Approve</div>
        </div>
      </div>
      <div class="t-flex t-mb-4 t-items-center t-p-0">
        <div class="t-mr-4">
          <svg-icon class="action-icon rejected-icon t-cursor-pointer t-px-2 t-py-1 t-rounded-full t-h-8 t-w-8 t-bg-white" @click="onReviewSubmit('rejected')" icon-class="thumbs-down-outline" />
        </div>
        <div>
          <div class="t-text-primary-color t-font-futura-pt-demi t-text-base">Reject</div>
        </div>
      </div>
      <div class="t-flex t-mb-4 t-items-center t-p-0" v-if="submittedAsset.reviewStatus == 'submitted' || submittedAsset.reviewStatus == 'resubmitted'">
        <div class="t-mr-4">
          <svg-icon class="action-icon t-cursor-pointer t-px-2 t-py-1 t-rounded-full t-h-8 t-w-8 t-bg-white" @click="setEditModeFlag()" icon-class="Icon_edit" />
        </div>
        <div>
          <div class="t-text-primary-color t-font-futura-pt-demi t-text-base">Edit</div>
        </div>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
import SaveDialog from '@/views/manage-submissions/components/SaveDialog';
export default {
  name: 'ReviewActions',
  props: {
    submittedAsset: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    }
  },
  components: { SaveDialog },
  data() {
    return {
      isAdminEdited: false,
      showDialog: false,
      status: this.submittedAsset.reviewStatus,
      toastMessagesList: {
        approved: {
          message: 'The asset is approved and will be available in ProEdge Learn within 3-5 business days.',
          type: 'asset-review-success'
        },
        rejected: {
          message: 'The submission is rejected. A notification will go to the submitter.',
          type: 'asset-review-success'
        },
        returned: {
          message: 'Submission successfully returned to the submitter.',
          type: 'asset-review-success'
        }
      }
    };
  },
  created() {
    this.isAdminEdited = this.submittedAsset.isAdminEdited || false;
  },
  methods: {
    setEditModeFlag() {
      this.$emit('setEditMode', true);
    },
    async onReviewSubmit(status) {
      if (this.submittedAsset.externalAssetLink.length === 0 && this.submittedAsset.assetReference === null) {
        this.$emit('showAlerts', true);
      } else {
        this.status = status;
        this.showDialog = true;
        const action = status === 'approved' ? 'Approve' : status === 'rejected' ? 'Reject' : 'Return to submitter';
        const event = {
          name: 'Asset',
          category: 'Admin Asset Detail',
          payload: {
            action: `${action} button clicked`,
            asset: {
              id: this.submittedAsset.id,
              name: this.submittedAsset.name,
              author: this.submittedAsset.submitUser,
              applications: this.submittedAsset.applicationsVM,
              type: ''
            }
          }
        };
        this.GAEventTrack(event);
      }
    },
    showToast(msg, className) {
      let toastDuration = 5000;
      this.$toasted.show(msg, {
        containerClass: 'toast-container',
        className: className,
        position: 'bottom-right',
        iconPack: 'icon-container',
        duration: toastDuration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      });
    },
    async onConfirm(comment) {
      const payload = {
        itemId: this.submittedAsset.id,
        action: this.status,
        comment: comment
      };
      await this.$planAdmin.onReviewSubmit(payload).then((res) => {
        if (res.status == 200) {
          this.showDialog = false;
          const toastObj = this.toastMessagesList[this.status];
          this.showToast(toastObj.message, toastObj.type);
          this.$router.push({ name: 'ManageSubmissions' });
        }
      });
    },
    onCancel() {
      this.showDialog = false;
      this.status = this.submittedAsset.reviewStatus;
    },
    editClicked() {
      this.$router.push({ name: 'AssetEditForm', params: { id: this.submittedAsset.id } });
    },
    GAEventTrack(event) {
      this.$gtag.event(event.name, {
        event_category: event.category,
        event_label: JSON.stringify(event.payload),
        value: JSON.stringify(event.payload)
      });
    }
  }
};
</script>
<style lang="scss">
.toast-container {
  bottom: 0 !important;
  right: 0 !important;

  .toasted {
    color: #000000 !important;
    padding-left: 4rem !important;
    width: 20rem !important;
    height: 5rem !important;
    font-size: 0.75rem !important;
    font-family: Arial !important;

    &.asset-review-success {
      background: #d9f8f3 !important;
      background-image: url('~@/assets/images/svgs/icon-success.svg') !important;
      background-repeat: no-repeat !important;
      background-position: left !important;
      background-position-x: 1.375rem !important;
      background-size: 1.25rem !important;
      border-bottom: 0.4rem solid #28dabd !important;
    }

    &.asset-review-info {
      background: #fff4df !important;
      background-image: url('~@/assets/images/svgs/icon-info.svg') !important;
      background-repeat: no-repeat !important;
      background-position: left !important;
      background-position-x: 1.375rem !important;
      background-size: 1.25rem !important;
      border-bottom: 0.4rem solid #ffc758 !important;
    }

    &.asset-review-error {
      background: #fae5dc !important;
      background-image: url('~@/assets/images/svgs/icon-alert.svg') !important;
      background-repeat: no-repeat !important;
      background-position: left !important;
      background-position-x: 1.375rem !important;
      background-size: 1.25rem !important;
      border-bottom: 0.4rem solid #d04910 !important;
    }

    .action {
      background-image: url('~@/assets/images/svgs/icon-cancel.svg') !important;
      background-position: center !important;
      background-size: contain !important;
      position: absolute !important;
      right: 11px !important;
      top: 0 !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.action-icon {
  border: 1px solid #e0e1e1;
  width: 3rem;
  height: 3rem;

  &.rejected-icon {
    transform: scaleX(-1);
  }
}
</style>
