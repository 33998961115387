<template>
  <div>
    <div v-if="!dataReady" class="loader t-flex t-justify-center t-items-center t-h-96">
      <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Dashboard" />
    </div>
    <div class="table-container" v-if="dataReady">
      <h2 class="t-mb-10 t-font-normal t-text-primary-color t-font-futura-pt-demi t-text-5xl">{{ $t('manageSubmissions.title') }}</h2>

      <div class="t-flex t-justify-between t-font-arial t-text-sm items-baseline">
        <div class="t-mb-6 t-flex t-items-center">
          <label class="t-mr-4 t-text-lg t-font-futura-pt-demi t-text-black">{{ $t('manageSubmissions.filter') }}</label>
          <div class="t-flex">
            <SelectView
              class="t-w-36 t-mr-4 manage-submissions-dropdown"
              v-model="status"
              :items="getStatus()"
              :menu-props="menuPosition"
              persistent-placeholder
              :placeholder="$t('manageSubmissions.status')"
              append-inner-icon="mdi mdi-chevron-down"
              multiple
              dense
              outlined
              hide-details
              single-line
              height="36"
              rounded
              variant="solo"
            >
              <template v-slot:selection="{ index }">
                <span class="t-text-sm t-font-bold" v-if="index == 0">{{ $t('manageSubmissions.status') }}</span>
              </template>
            </SelectView>
            <SelectView
              class="t-w-40 manage-submissions-dropdown"
              v-model="submitter"
              :items="getSubmitter()"
              :menu-props="menuPosition"
              persistent-placeholder
              :placeholder="$t('manageSubmissions.submitter')"
              append-inner-icon="mdi mdi-chevron-down"
              multiple
              dense
              outlined
              hide-details
              single-line
              height="36"
              rounded
              variant="solo"
            >
              <template v-slot:selection="{ index }">
                <span class="t-text-sm t-font-bold" v-if="index == 0">{{ $t('manageSubmissions.submitter') }}</span>
              </template>
            </SelectView>
          </div>
        </div>
        <div class="audit-log-container">
          <a @click="downloadHistoryLog()" class="t-font-arial t-font-sm">
            <button tabindex="0">{{ $t('manageSubmissions.downloadHistoryLog') }}</button>
          </a>
        </div>
      </div>
      <div class="t-mb-6">
        <div class="t-ml-14 t-flex t-items-baseline t-flex-wrap" v-if="status.length || submitter.length">
          <v-chip :key="item" class="t-mr-2 t-mb-4" v-for="item in getFilters()" color="#000000" text-color="#ffffff" closable @click:close="removeFilter(item)" close-icon="mdi-close">
            {{ item }}
          </v-chip>
          <a @click="clearFilters()">
            <button tabindex="0">{{ $t('common.clearAllFilters') }}</button>
          </a>
        </div>
      </div>
      <v-data-table :headers="headers" :items="getTableList()" :items-per-page="6" :items-per-page-options="pageSizeOption" @click:row="manageAssetTrackRow" class="t-cursor-pointer">
        <template v-slot:[`item.assetName`]="{ item }">
          <p class="t-m-0 t-font-futura-pt-demi t-text-xl t-break-all t-text-primary-color">{{ item.name }}</p>
          <span class="t-text-xs t-font-arial" v-if="item.displayId">{{ item.displayId }} | </span>
          <span class="t-text-xs t-font-arial" v-if="item.inventoryType">{{ item.inventoryType }} | </span>
          <span class="t-text-xs t-font-arial" v-for="(technology, index) in item.applications" :key="technology">
            <i v-if="index > 0 && index != item.applications.length"> | </i>
            {{ technology }}
          </span>
        </template>
        <template v-slot:[`item.functions`]="{ item }">
          <v-tooltip top color="white" max-width="25rem" content-class="function-tooltip">
            <template v-slot:activator="{ props }">
              <span class="t-text-sm t-font-arial" v-bind="props">{{ getFuncText(item.functions) }}</span>
            </template>
            <div class="tooltip-container" v-if="item.functions.join(',').length >= functionTruncateLength">
              <span class="t-text-xs t-font-arial" v-for="(func, i) in item.functions" :key="func"><i v-if="i > 0 && i != item.functions.length">, </i>{{ getLocalizedFunctions(func) }}</span>
            </div>
          </v-tooltip>
        </template>
        <template v-slot:[`item.dateSubmitted`]="{ item }">
          {{ localizedDate(moment, item.dateSubmitted) }}
        </template>
        <template v-slot:[`item.lastUpdated`]="{ item }">
          {{ localizedDate24Hour(moment, item.lastUpdated) }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <span class="t-font-bold">{{ item.status }}</span>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item class="t-cursor-pointer">
                <v-list-item-title @click="manageAssetTrack(item.id)">{{ $t('manageSubmissions.manageAsset') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { localizedDate, localizedDate24Hour, getLocalizedFunctions } from '@/utils';
import SelectView from '@/components/molecules/SelectView';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'ManageSubmissionsList',
  components: { SelectView },
  data() {
    return {
      submissionsListData: [],
      functionsList: [],
      status: [],
      submitter: [],
      dataReady: false,
      menuPosition: {
        bottom: true,
        offsetY: true
      },
      reviewStatusObj: { InEdit: { value: 'IN EDIT', display: 'RETURNED' } },
      functionTruncateLength: 16,
      pageSizeOption: [
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' }
      ]
    };
  },
  watch: {
    '$i18n.locale'() {
      this.setFunctionsList();
    }
  },
  computed: {
    headers() {
      return [
        { title: translate('manageSubmissions.headers.assetName'), key: 'assetName', width: 220, align: 'start' },
        { title: translate('manageSubmissions.headers.submitter'), key: 'submitter', width: 160, align: 'start' },
        { title: translate('manageSubmissions.headers.functions'), key: 'functions', width: 160, align: 'start' },
        { title: translate('manageSubmissions.headers.dateSubmitted'), key: 'dateSubmitted', width: 180, align: 'start' },
        { title: translate('manageSubmissions.headers.lastUpdated'), key: 'lastUpdated', width: 180, align: 'start' },
        { title: translate('manageSubmissions.headers.status'), key: 'status', width: 100, align: 'start' },
        { title: translate('manageSubmissions.headers.action'), key: 'action', width: 100, align: 'start', sortable: false }
      ];
    }
  },
  async mounted() {
    this.initialize();
    this.setFunctionsList();
  },
  methods: {
    localizedDate,
    localizedDate24Hour,
    getLocalizedFunctions,
    async initialize() {
      let submissionsListData = await this.$planAdmin.getManageSubmissionsList();
      this.submissionsListData = submissionsListData.content.map((item) => {
        item.assetName = item.name;
        item.submitter = `${item.submitUser.firstName} ${item.submitUser.lastName}`;
        item.functions = item.functions === null ? [] : this.getLocalizedFunctions(item.functions);
        item.dateSubmitted = new Date(item.createdAt);
        item.lastUpdated = new Date(item.updatedAt);
        item.status = item.reviewStatus.toUpperCase() == this.reviewStatusObj.InEdit.value ? this.reviewStatusObj.InEdit.display : item.reviewStatus.toUpperCase();
      });
      this.submissionsListData = submissionsListData.content;
      this.dataReady = true;
      const event = {
        name: 'Asset',
        category: 'Manage Submissions',
        payload: {
          action: 'Manage submissions page loaded'
        }
      };
      this.GAEventTrack(event);
    },
    async setFunctionsList() {
      this.functionsList = [];
      const functions = await this.$planAdmin.getGovernanceFunctions();
      functions.map((func) => {
        this.functionsList.push(getLocalizedFunctions(func.name));
      });
    },
    async downloadHistoryLog() {
      const formattedDate = this.getNewTimeStamp();
      const blob = await this.$planAdmin.downloadHistoryLog();
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.setAttribute('download', `proedge_asset_history_log_${formattedDate}.csv`);
      a.click();
    },
    getNewTimeStamp() {
      const d = new Date();
      return [d.getMonth() + 1, d.getDate(), d.getFullYear().toString().substring(2)].join('.');
    },
    manageAssetTrackRow(e, { item }) {
      // because of linting, we have to use -> e
      if (e) this.manageAssetTrack(item.id);
    },
    manageAssetTrack(assetId) {
      this.$router.push({ name: 'AssetReviewDetail', params: { id: assetId } });
      const event = {
        name: 'Asset',
        category: 'Manage Submissions',
        payload: {
          action: 'Asset submission clicked',
          id: assetId
        }
      };
      this.GAEventTrack(event);
    },
    getFuncText(functions) {
      if (this.functionsList.length === functions.length) {
        return translate('manageReviewDetailContent.allFunctions');
      }
      if (functions != null && functions.length) {
        let i = 1;
        let funcStr = this.getLocalizedFunctions(functions[0]);
        while (i < functions.length) {
          funcStr = funcStr + ', ' + this.getLocalizedFunctions(functions[i]);
          i++;
        }
        return funcStr.length > this.functionTruncateLength ? funcStr.slice(0, this.functionTruncateLength - 1) + '....' : funcStr;
      } else {
        return '-';
      }
    },
    getStatus() {
      return this.submissionsListData
        .filter((value) => {
          return this.submitter.length > 0 ? this.submitter.includes(value.submitter) : true;
        })
        .map((item) => item.status)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
    },
    getSubmitter() {
      return this.submissionsListData
        .filter((value) => {
          return this.status.length > 0 ? this.status.includes(value.status) : true;
        })
        .map((item) => item.submitter)
        .filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
    },
    getFilters() {
      const event = {
        name: 'Asset',
        category: 'Manage Submissions',
        payload: {
          action: 'Filter clicked',
          filter: {
            status: this.status,
            submitter: this.submitter
          }
        }
      };
      this.GAEventTrack(event);
      return [...this.status, ...this.submitter];
    },
    removeFilter(item) {
      this.status.includes(item) ? this.status.splice(this.status.indexOf(item), 1) : this.submitter.splice(this.submitter.indexOf(item), 1);
    },
    clearFilters() {
      this.status = [];
      this.submitter = [];
    },
    getTableList() {
      if (this.status.length > 0 || this.submitter.length > 0) {
        const filteredTableData = this.submissionsListData.filter((itm) => {
          if (this.status.length && this.submitter.length) return this.status.indexOf(itm.status) > -1 && this.submitter.indexOf(itm.submitter) > -1;
          else if (this.status.length) return this.status.indexOf(itm.status) > -1;
          else return this.submitter.indexOf(itm.submitter) > -1;
        });
        if (filteredTableData.length > 0) return filteredTableData;
        else this.clearFilters();
      }
      return this.submissionsListData;
    },
    GAEventTrack(event) {
      this.$gtag.event(event.name, {
        event_category: event.category,
        event_label: JSON.stringify(event.payload),
        value: JSON.stringify(event.payload)
      });
    }
  },
  deactivated() {
    this.submissionsListData = [];
    this.dataReady = false;
  }
};
</script>
<style lang="scss">
.table-container {
  .v-btn {
    box-shadow: none;
    background: transparent;
    &:hover {
      box-shadow: none;
      background: transparent;
    }
  }
  .manage-submissions-dropdown {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    padding: 0;

    .v-input__append {
      display: none;
    }
    .v-input__control {
      color: rgba(0, 0, 0, 0.87);

      .mdi-menu-down {
        display: none;
      }

      .v-field {
        height: 2.5rem;
        padding: 0 24px;
        display: flex;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.35);
        background: transparent !important;

        .v-field__input {
          padding-left: 0rem;
          padding-right: 0rem;

          input {
            &::placeholder {
              opacity: 1;
              color: #000;
            }
          }
        }

        &[aria-expanded='true'] {
          i {
            rotate: 180deg;
          }
        }
      }
    }
  }
  .v-data-table {
    border-radius: 1.25rem;
    padding: 1rem;

    tr:nth-of-type(odd) {
      td {
        background-color: #efefef80;
      }
    }
  }
  .v-text-field input::placeholder {
    font-size: 0.875rem;
    color: #000000;
    font-weight: bold;
  }

  .v-icon {
    color: #000000;
  }

  .v-table__wrapper {
    border-radius: 0rem;
  }

  .v-data-table > .v-table__wrapper > table {
    width: 100%;
    border-spacing: 0;
  }
  .v-data-table > .v-table__wrapper > table > thead > tr:last-child > th {
    font-weight: bold;
    font-size: 1rem !important;
    color: #000000 !important;
  }
  .v-data-table > .v-table__wrapper > table > tbody > tr > td {
    font-size: 0.875rem !important;
    padding: 1rem;
  }

  .v-data-table-footer {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 0 0.5rem;
    margin-top: 1.5rem;
  }
}
.function-tooltip {
  &.v-tooltip__content {
    letter-spacing: 0.14px !important;
    color: #000000 !important;
    opacity: 1 !important;
    padding: 0;
    background: transparent !important;
  }
  .tooltip-container {
    padding: 0.5rem 0.75rem;
    background: #fff;
    border-radius: 4px;
  }
}
</style>
