<template>
  <div class="fow-container">
    <v-dialog v-model="dialog" width="584" max-width="584" class="dialog-container">
      <v-card max-width="584" :text="dialogSubTitle" :title="translate('hr_tools.skill_extraction.dialog_title')">
        <template v-slot:append>
          <v-btn variant="plain" icon="mdi-close" @click="dialog = false"></v-btn>
        </template>
        <template v-slot:actions>
          <v-btn class="ms-auto cancel-btn" variant="outlined" :text="translate('button.cancel')" @click="dialog = false"></v-btn>
          <v-btn data-el="addTag" class="yes-btn" variant="outlined" @click="proceedUpload()">{{ translate('button.yes') }}</v-btn>
        </template>
      </v-card>
    </v-dialog>
    <div class="fow-header-container">
      <div>
        <h3>{{ translate('hr_tools.fow.header') }}</h3>
        <p>{{ translate('hr_tools.fow.sub_header') }}</p>
      </div>
      <img class="" src="@/assets/images/pngs/assigned_to_workspace.png" />
    </div>
    <div class="options">
      <v-radio-group v-model="selectedValue">
        <v-radio value="onet" color="#1F3370" :label="translate('hr_tools.fow.option_label1')"></v-radio>
        <v-radio value="company" color="#1F3370" :label="translate('hr_tools.fow.option_label2')"></v-radio>
      </v-radio-group>
    </div>
    <p class="option-desc" v-if="selectedValue === 'onet'">
      {{ translate('hr_tools.fow.option_desc') }}
    </p>
    <div v-else class="company-overview">
      <h4>{{ translate('hr_tools.fow.company_overview_title') }}</h4>
      <div class="t-flex t-gap-12 overview-input">
        <v-text-field v-model="addCompanyName" placeholder="" variant="outlined" density="compact" :label="translate('hr_tools.fow.add_company_label_name')"></v-text-field>
        <SelectView
          v-model="selectedCompanyName"
          :items="companyList"
          item-title="companyName"
          item-value="companyName"
          variant="outlined"
          :clearable="selectedCompanyName != ''"
          :label="translate('hr_tools.fow.select_company_placeholder')"
          return-object
          :disabled="companyList.length <= 0"
        >
        </SelectView>
      </div>
      <v-btn data-el="submit" variant="outlined" class="submit-btn" @click="onSubmitCompanyName" :disabled="isDisabledSubmit">{{ translate('hr_tools.fow.submit') }}</v-btn>
    </div>
    <div v-if="selectedValue === 'company'" class="manual-ingestion-section">
      <h4>{{ translate('hr_tools.fow.manual_ingestion_title') }}</h4>
      <div class="file-upload-section">
        <div class="upload-file t-flex t-gap-4">
          <p>{{ translate('hr_tools.fow.upload_title') }}</p>
          <v-btn data-el="upload-file" variant="outlined" class="upload-btn" @click="onClickUploadBtn">
            <template v-slot:prepend>
              <svg-icon icon-class="upload_plus" class="svg-section" />
            </template>
            {{ translate('hr_tools.fow.upload_btn_title') }}</v-btn
          >
          <input id="fowFile" data-cy="asset-file-zip" ref="fowFile" class="upload-input" type="file" accept=".csv, .xls, .xlsx, .zip" @change="handleUpload" style="display: none" :key="Date.now()" />
        </div>
        <div class="download-template t-flex t-gap-4">
          <p>{{ translate('hr_tools.fow.download_template_title') }}</p>
          <v-btn data-el="upload-file" variant="outlined" class="download-template-btn" @click="onDownloadTemplate">
            <template v-slot:prepend>
              <svg-icon icon-class="download_plus" class="svg-section" />
            </template>
            {{ translate('hr_tools.fow.download_template_btn_title') }}</v-btn
          >
        </div>
      </div>
      <SelectView
        v-model="selectedFileName"
        :items="dropdownFileList"
        item-title="metadata.fileName"
        item-value="metadata.fileName"
        return-object
        variant="outlined"
        :label="translate('hr_tools.fow.file_list_title')"
        :clearable="selectedFileName != ''"
        class="t-mt-4"
        :disabled="dropdownFileList.length <= 0"
      ></SelectView>
      <!-- Hide below as per requirement - https://dev.azure.com/pwc-us-it-etedomain/ProEdge%20Upskilling/_workitems/edit/584440 -->
      <!-- <div class="t-flex t-justify-between t-mt-8">
        <v-text-field v-model="company_url" placeholder="" variant="outlined" density="compact" :label="translate('hr_tools.fow.company_url_title')" :disabled="!selectedFileName"></v-text-field>
        <v-text-field v-model="company_industry" placeholder="" variant="outlined" density="compact" :label="translate('hr_tools.fow.company_industry_title')" :disabled="!selectedFileName"></v-text-field>
        <v-text-field v-model="company_employeeTotal" placeholder="" variant="outlined" density="compact" :label="translate('hr_tools.fow.company_employee_title')" :disabled="!selectedFileName"></v-text-field>
      </div> -->
      <div class="file-list-container t-w-full" v-if="fileList && fileList.length > 0">
        <div class="file-meta-data" v-for="(file, index) in fileList" :key="index">
          <div class="t-flex t-items-center t-gap-8">
            <p v-if="file.metadata && file.metadata.fileName" :title="file.metadata.fileName">{{ file.metadata.fileName }}</p>
            <div class="t-flex t-items-center t-gap-2">
              <svg-icon v-if="file.status === 'completed'" icon-class="circle-checkmark" style="width: 24px; height: 24px" />
              <svg-icon v-else-if="file.status === 'failed'" icon-class="failed" style="width: 24px; height: 24px" />
              <svg-icon v-else icon-class="loading_spinner" style="width: 24px; height: 24px" />
              <span :class="file.status === 'failed' ? 'loading-label failed' : 'loading-label'" @click="onClickFailureMsg(file)">{{ file.status }}</span>
            </div>
          </div>
          <v-btn data-el="addTag" variant="outlined" class="download-btn" @click="onDownloadCSV(file)" :disabled="file.isDownloadDisable"
            ><template v-slot:prepend> <svg-icon icon-class="download_plus" class="svg-section" /> </template>{{ translate('hr_tools.skill_extraction.download') }}</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage';
import { translate } from '@/plugins/i18n.js';
import SelectView from '@/components/molecules/SelectView';
import UploadFile from './UploadFile';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'FowScreen1',
  emits: [],
  components: { SelectView, UploadFile },
  props: {
    currentPage: Number,
    prevPage: Number
  },
  computed: {
    isDisabledSubmit() {
      return !this.addCompanyName;
    },
    dialogSubTitle() {
      return `${translate('hr_tools.fow.dialog_sub_title1')} ${this.uploadFileData.name} ${translate('hr_tools.fow.dialog_sub_title2')}`;
    },
    ...mapState('workforce', ['storeCompanyList', 'storeSelectedCompanyName', 'storeFileList', 'storeSelectedFileName', 'storeSelectedOption', 'storeOnetCompanyGuid', 'storeFunctionList']),
    dropdownFileList() {
      return this.fileList.filter((file) => file.status === 'completed');
    }
  },
  watch: {
    selectedCompanyName: {
      handler: async function (val) {
        if (val) {
          await this.setSelectedCompanyName(JSON.stringify(val));
          const payload = {
            size: 3,
            jobType: 'future-of-work-ingestion',
            companyGuid: val.guid
          };
          this.fileList = await this.$planAdmin.getCompanyFiles(payload);
          await this.fileList.forEach((file) => {
            file.isDownloadDisable = file.status !== 'completed';
          });
          if (this.fileList) await this.setFileList(JSON.stringify(this.fileList));
        }
      }
    },

    selectedValue: {
      handler: async function (val) {
        this.$emit('on-selected-option', val);
        this.setSelectedOption(val);
        if (val === 'onet') {
          this.companyList = [];
          this.selectedCompanyName = '';
          this.fileList = [];
          this.selectedFileName = '';
          this.setCompanyList(JSON.stringify(this.companyList));
          this.setSelectedCompanyName(JSON.stringify(this.selectedCompanyName));
          this.setFileList(JSON.stringify(this.fileList));
          this.setSelectedFileName(JSON.stringify(this.selectedFileName));
        } else {
          await this.setup();
        }
      }
    },

    selectedFileName: {
      handler: async function (val) {
        if (val) {
          this.$emit('on-selected-file-name', val);
          this.setSelectedFileName(JSON.stringify(val));
        }
      }
    }
  },
  data() {
    return {
      addCompanyName: '',
      selectedCompanyName: '',
      companyList: [],
      selectedFileName: '',
      fileUploadList: [],
      company_url: '',
      company_industry: '',
      company_employeeTotal: '',
      dialog: false,
      fileList: [],
      uploadFileData: {},
      selectedValue: 'onet',
      onetCompanyGuid: ''
    };
  },

  async mounted() {
    if (this.prevPage === 2) {
      // Fetch data in parallel using Promise.all
      const [companyData, fileData, companyList, fileList, selectedOption, onetCompanyGuid, storeFunctionList] = await Promise.all([
        this.storeSelectedCompanyName || localforage.getItem('storeSelectedCompanyName'),
        this.storeSelectedFileName || localforage.getItem('storeSelectedFileName'),
        this.storeCompanyList || localforage.getItem('storeCompanyList'),
        this.storeFileList || localforage.getItem('storeFileList'),
        this.storeSelectedOption || localforage.getItem('storeSelectedOption'),
        this.storeOnetCompanyGuid || localforage.getItem('storeOnetCompanyGuid'),
        this.storeFunctionList || localforage.getItem('storeFunctionList')
      ]);

      // Parse JSON safely with error handling
      try {
        this.selectedCompanyName = typeof companyData === 'string' ? JSON.parse(companyData) : companyData;
        this.selectedFileName = typeof fileData === 'string' ? JSON.parse(fileData) : fileData;
        this.companyList = typeof companyList === 'string' ? JSON.parse(companyList) : companyList;
        this.fileList = typeof fileList === 'string' ? JSON.parse(fileList) : fileList;
        this.selectedValue = selectedOption;
        this.onetCompanyGuid = onetCompanyGuid;
        const functionList = typeof storeFunctionList === 'string' ? JSON.parse(storeFunctionList) : storeFunctionList;
        if (functionList && functionList.length === 0) {
          this.showToast(translate('hr_tools.fow.Data_not_found'), 'success', 5000);
        }
      } catch (parseError) {
        console.error('Error parsing JSON:', parseError);
        return;
      }
    } else {
      this.selectedValue = (await localforage.getItem('storeSelectedOption')) || 'onet';
      this.onetCompanyGuid = this.storeOnetCompanyGuid || (await localforage.getItem('storeOnetCompanyGuid'));
      this.setSelectedOption(this.selectedValue);
      this.$emit('on-selected-option', this.selectedValue);
      if (!this.onetCompanyGuid) {
        const response = await this.$planAdmin.fetchCompanyGuid({ companyName: 'onet' });
        this.onetCompanyGuid = response ? response.guid : '';
        this.setOnetCompanyGuid(this.onetCompanyGuid);
      }
      // await this.setup();
    }
  },

  async unMounted() {},

  deactivated() {},

  methods: {
    translate,
    ...mapActions('workforce', ['setCompanyList', 'setSelectedCompanyName', 'setFileList', 'setSelectedFileName', 'setIsUpdate', 'setSelectedOption', 'setOnetCompanyGuid']),

    async setup() {
      const response = await this.$planAdmin.getCompanyList();
      if (response && Array.isArray(response)) {
        this.companyList = response;
        this.setCompanyList(JSON.stringify(this.companyList));
      }
    },

    async onSubmitCompanyName() {
      const payload = {
        companyName: this.addCompanyName
      };
      const response = await this.$planAdmin.addCompany(payload);
      if (response && response.guid) {
        this.companyList = [...new Set([...this.companyList, response])];
        this.selectedCompanyName = response;
        this.addCompanyName = '';
      }
    },

    onClickUploadBtn() {
      this.$refs.fowFile.click();
    },

    async handleUpload(event) {
      const files = event.target.files;
      if (!files.length) return;
      this.uploadFileData = files;
      this.dialog = true;
    },

    // Handling file upload functionality
    async proceedUpload() {
      try {
        this.dialog = false;
        const formData = new FormData();
        formData.append('files', this.uploadFileData[0]);
        formData.append('companyGuid', this.selectedCompanyName.guid);
        const payload = {
          body: formData
        };
        const response = await this.$planAdmin.manualIngestionFileUpload(payload);
        if (response) {
          this.showToast(translate('hr_tools.skill_extraction.file_upload_success'), 'success', 5000);
          await response.forEach((file) => {
            file.isDownloadDisable = file.status !== 'completed';
          });
          this.fileList = [...this.fileList, ...response];
        } else {
          this.showToast(translate('hr_tools.create_skill_profile.upload_error'), 'error', 10000);
        }
      } catch (e) {
        console.log('error', e);
        this.showToast(translate('hr_tools.create_skill_profile.upload_error'), 'error', 5000);
      }
    },

    async onDownloadTemplate() {
      try {
        const payload = {
          isJobDescription: true,
          isSkillExtractor: false,
          extension: 'xlsx',
          hrToolsType: 'FOW_INGESTION'
        };
        const res = await this.$planAdmin.downloadTemplate(payload);
        if (res) {
          await this.downloadFile(res);
          this.downloadDialog = false;
          this.selectedDownloadOption = '';
        } else {
          this.showToast(translate('hr_tools.skill_extraction.file_process_error'), 'error', 5000);
        }
      } catch (error) {
        console.log(error);
        this.showToast(translate('hr_tools.skill_extraction.file_process_error'), 'error', 5000);
      }
    },

    downloadFile(relativeUri) {
      try {
        if (relativeUri) {
          this.downloadPercentage = 0;
          let filename = 'impact_assessment_upload_template.xlsx';
          let a = document.createElement('a');
          a.href = relativeUri;
          a.setAttribute('download', filename);
          a.click();
        }
      } catch (error) {
        console.log(error);
      }
    },

    // Function to handling toast
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });
      return this.$toasted.show(msg, options);
    }
  },

  metaInfo() {
    return {
      title: translate('pageTitles.hr_tools')
    };
  }
};
</script>
<style lang="scss">
.task-options,
.client-description {
  display: flex;
  gap: 2rem;
  align-items: center;
  .v-selection-control__input,
  .v-selection-control__wrapper {
    height: fit-content;
    width: fit-content;
  }
  .v-input__details {
    display: none !important;
  }
  .v-selection-control .v-label {
    color: #2d2d2d;
    @include body-font-1;
    opacity: 1;
  }
  .v-input {
    flex: unset;
    .v-input__control {
      padding-right: 0px;
    }
  }
  .v-checkbox .v-selection-control {
    min-height: 2rem !important;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .v-selection-control--disabled {
    opacity: 1;
    color: #1f3370;
  }
  .v-input--disabled {
    opacity: 0.5 !important;
  }
}

.client-description {
  gap: unset;
  .v-selection-control .v-label {
    color: #2d2d2d;
    @include body-font-1;
    opacity: 1;
  }
}

.company-overview,
.manual-ingestion-section {
  .v-input__control {
    width: 304px !important;
  }
  .v-input__details {
    display: none !important;
  }
  .v-input {
    width: 304px !important;
  }
  .v-field--center-affix .v-field__append-inner {
    height: 48px !important;
    text-transform: capitalize;
  }
  .v-field__field {
    height: 48px !important;
  }
  .v-select .v-field.v-field {
    height: 48px !important;
  }
  .v-field__input {
    height: 48px !important;
    text-transform: capitalize;
  }
  .v-textarea .v-field {
    padding: 1rem !important;
  }
  .v-textarea .v-field--active textarea,
  .v-textarea .v-field--no-label textarea {
    padding: unset !important;
  }
  .v-textarea .v-field__field {
    max-width: 304px;
  }
  .v-label {
    font-family: 'Helvetica Neue';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.4px;
    color: #696969;
  }
}

.submit-btn,
.download-btn,
.upload-btn,
.download-template-btn,
.next-btn,
.cancel-btn,
.yes-btn {
  .v-btn__content {
    font-family: 'Helvetica Neue';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
    color: #415385;
  }
}

.upload-btn,
.next-btn,
.yes-btn {
  .v-btn__content {
    color: #fff;
  }
}

.file-view-container {
  .v-input {
    width: 585px !important;
  }
  .v-input__details {
    display: none !important;
  }
  .v-select .v-field.v-field {
    height: 56px !important;
  }
  .v-field--center-affix .v-field__append-inner {
    height: 56px !important;
  }
  .v-field__input {
    padding: 1rem !important;
    height: 56px !important;
  }
}

.dialog-container {
  .v-card {
    padding: 2rem !important;
    .v-card-item {
      padding: unset !important;
      .v-card-title {
        font-family: 'Helvetica Neue';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        color: #252525;
        line-height: 24px; /* 120% */
        letter-spacing: -0.4px;
        margin-bottom: 1.5rem;
      }
    }
    .v-card-text {
      padding: unset !important;
      font-family: 'Helvetica Neue';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.4px;
      margin-bottom: 2rem;
      color: #474747;
    }
    .v-card-item__append {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
    }
  }
  .cancel-btn {
    border-color: #415385;
    padding-left: 24px;
    padding-right: 24px;
  }
  .yes-btn {
    background-color: #415385;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.options {
  .v-selection-control-group {
    flex-direction: row;
    width: 40%;
  }

  .v-selection-control {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .v-label {
      width: max-content;
      font-family: 'Helvetica Neue';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 125% */
      letter-spacing: -0.4px;
      color: #474747;
    }
  }
  .v-input__details {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.fow-container {
  .fow-header-container {
    display: flex;
    padding: 1rem;
    border-radius: 0.5rem;
    gap: 8px;
    background: #415385;
    height: 274px;
    align-items: center;
    h3 {
      font-family: 'Helvetica Neue';
      font-size: 36px;
      font-style: normal;
      font-weight: 500;
      line-height: 42px; /* 116.667% */
      letter-spacing: -0.4px;
      color: #fff;
      margin-bottom: 1rem;
    }
    p {
      font-family: 'Helvetica Neue';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.4px;
      color: #fff;
    }
  }

  .company-overview {
    margin-top: 2rem;
    h4 {
      font-family: 'Helvetica Neue';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
      letter-spacing: -0.4px;
      color: #252525;
      margin-bottom: 2rem;
    }
    .overview-input {
      width: fit-content;
    }
    .submit-btn {
      width: 99px;
      height: 40px;
      border-color: #415385;
      margin-top: 8px;
    }
  }

  .manual-ingestion-section {
    margin-top: 2rem;
    h4 {
      font-family: 'Helvetica Neue';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 120% */
      letter-spacing: -0.4px;
      color: #252525;
      margin-bottom: 2rem;
    }
    .file-upload-section {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .upload-file,
      .download-template {
        width: 47%;
        p {
          font-family: 'Helvetica Neue';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: -0.4px;
          color: #000;
        }
        .upload-btn,
        .download-template-btn {
          width: 156px;
          height: 40px;
          border-color: #415385;
          background-color: #415385;
          margin-top: 8px;
          color: #fff;
        }
        .download-template-btn {
          width: 218px;
          background-color: #fff;
        }
        .svg-section {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .file-list-container {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .file-meta-data {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #f3f3f3;
        padding: 1rem;
        border-radius: 8px;
        p {
          max-width: 335px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 335px;
        }
        .download-btn {
          width: 156px;
          height: 40px;
          border-color: #415385;
          margin-top: 8px;
          color: #fff;
        }
        .svg-section {
          width: 1rem;
          height: 1rem;
        }
        .loading-label {
          font-family: 'Helvetica Neue';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: -0.4px;
          color: #415385;
          cursor: pointer;
        }
        .failed {
          color: #ff0000;
        }
      }
    }
  }

  .options {
    margin-top: 2rem;
  }

  .option-desc {
    margin-top: 2rem;
    color: #000;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.2px;
  }
}
</style>
