<template>
  <div class="banner">
    <div class="left-item">
      <span v-if="logo" class="company-logo">
        <img :src="logo" />
      </span>
      <span :class="[logo ? 'move-up' : '']">{{ company }}</span>
    </div>
    <div class="center-item">{{ title }}</div>
    <div class="right-item">
      <button data-el="bannerLeftBtn" v-if="leftBtn.text" :class="leftBtnIsCancel ? 'button-cancel' : ''" @click="leftBtn.action" v-track="'banner,' + leftBtn.text + '-button,click'" :disabled="updatePeopleBtnDisabled">{{ leftBtn.text }}</button>
      <button data-el="bannerRightBtn" v-if="rightBtn.text" @click="rightBtn.action" v-track="'banner,' + rightBtn.text + '-button,click'" :disabled="uploadBtnDisabled">{{ rightBtn.text }}</button>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage';
export default {
  name: 'Banner',
  props: {
    company: { type: String, required: false, default: '' },
    logo: { type: String, required: false, default: '' },
    title: { type: String, required: false, default: '' },
    leftBtnIsCancel: { type: Boolean, required: false, default: true },
    leftBtn: {
      type: Object,
      required: false,
      default: () => {
        return {
          text: '',
          action: () => {}
        };
      }
    },
    rightBtn: {
      type: Object,
      required: false,
      default: () => {
        return {
          text: '',
          action: () => {}
        };
      }
    }
  },
  data() {
    return {
      uploadBtnDisabled: false,
      updatePeopleBtnDisabled: false
    };
  },
  async mounted() {
    const roles = await localforage.getItem('my_roles');
    this.updatePeopleBtnDisabled = roles && !roles.includes('GLOBAL_ADMIN');
    this.uploadBtnDisabled = roles && !roles.includes('GLOBAL_ADMIN');
  }
};
</script>

<style lang="scss" scoped>
.banner {
  display: flex;
  background: #444f80 !important;
  height: 80px;
  padding: 20px;
  margin: 0px 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  justify-content: center;

  .left-item {
    display: inline-block;
    font-size: 20px;
    color: $color-white;
    font-weight: bold;
    margin: auto;
    padding: auto;
  }

  .center-item {
    flex-grow: 4;
    font-size: 25px;
    text-align: center;
    color: $color-white;
  }
}

.company-logo {
  margin-right: 10px;
  img {
    width: 120px;
    height: 35px;
    filter: brightness(0) invert(1);
  }
}

.move-up {
  top: -10px;
}

button {
  border: 1px solid $button-primary-color-bg;
  border-radius: 20px;
  background: $button-primary-color-bg;
  color: $button-primary-color-text;
  padding: 0px 10px;
  width: 160px;
  margin-right: 10px;
  height: 35px;

  &:disabled {
    background: $color-grey-lighter;
  }

  // &:hover {
  //   background: $button-primary-color-bg-hover;
  //   color: $button-primary-color-text-hover;
  // }

  &.button-cancel {
    border: 1px solid $color-grey-light;
    background: $color-grey-light;
    color: $color-grey-dark;

    &:hover {
      background: $color-grey-lighter;
      color: $color-grey;
    }
  }
}
</style>
