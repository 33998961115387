import localforage from 'localforage';
import fflag from '@/api/fflag';
import auth from '@/api/auth';
import plan from '@/api/plan';
import learn from '@/api/learn';
import share from '@/api/share';
import apply from '@/api/apply';
import search from '@/api/search';
import track from '@/api/learning-track';
import reporting from '@/api/reporting';
import autographer from '@/api/autographer';
import cloud from '@/api/cloud';
import store from '@/store';
import constant from '@/api/constant';

export default class PlanAdminWeb {
  constructor() {
    this.baseUri = ''; //process.env.VUE_APP_PLAN_API_BASE_URI
    this.learnUri = ''; //process.env.VUE_APP_LEARN_API_BASE_URI
    this.shareUri = ''; //process.env.VUE_APP_SHARE_API_BASE_URI
    this.applyUri = ''; //process.env.VUE_APP_APPLY_API_BASE_URI
    this.searchUri = ''; //process.env.VUE_APP_SEARCH_API_BASE_URI
    this.trackUri = ''; //process.env.VUE_APP_TRACK_API_BASE_URI
    this.reportingUri = ''; //process.env.VUE_APP_REPORTING_API_BASE_URI
    this.skillmsUri = ''; //process.env.VUE_APP_SKILLMS_BASE_URI
    this.ffUri = process.env.VUE_APP_FF_API_BASE_URI;
    this.agUri = process.env.VUE_APP_AUTOGRAPHER_API_BASE_URI;
    this.updateEnvFromLocalForage();
  }

  getBaseUri() {
    return this.baseUri;
  }

  /*
   * Cloud Enviroment
   */
  async updateUrlsFromEnv() {
    this.baseUri = process.env.VUE_APP_PLAN_API_BASE_URI;
    this.learnUri = process.env.VUE_APP_LEARN_API_BASE_URI;
    this.cmsUri = process.env.VUE_APP_CMS_API_BASE_URI;
    this.shareUri = process.env.VUE_APP_SHARE_API_BASE_URI;
    this.trackUri = process.env.VUE_APP_TRACK_API_BASE_URI;
    this.applyUri = process.env.VUE_APP_APPLY_API_BASE_URI;
    this.searchUri = process.env.VUE_APP_SEARCH_API_BASE_URI;
    this.reportingUri = process.env.VUE_APP_REPORTING_API_BASE_URI;
    this.vdiUri = process.env.VUE_APP_VDI_API;
    this.agUri = process.env.VUE_APP_AUTOGRAPHER_API_BASE_URI;
    this.skillmsUri = process.env.VUE_APP_SKILLMS_BASE_URI;
  }

  async updateUrlsFromCloudEnv(cloudEnv) {
    this.baseUri = cloudEnv.baseApis.planApiBaseUri;
    this.learnUri = cloudEnv.baseApis.learnApiBaseUri;
    this.cmsUri = cloudEnv.baseApis.cmsApiBaseUri;
    this.shareUri = cloudEnv.baseApis.shareApiBaseUri;
    this.applyUri = cloudEnv.baseApis.applyApiBaseUri;
    this.searchUri = process.env.VUE_APP_SEARCH_API_BASE_URI;
    this.trackUri = process.env.VUE_APP_TRACK_API_BASE_URI;
    this.reportingUri = process.env.VUE_APP_REPORTING_API_BASE_URI;
    this.vdiUri = cloudEnv.baseApis.vdiApiBaseUri;
    this.agUri = process.env.VUE_APP_AUTOGRAPHER_API_BASE_URI;
    this.skillmsUri = process.env.VUE_APP_SKILLMS_BASE_URI;
  }

  async updateEnvFromLocalForage() {
    if (!store.state.flag.enabled['plan_enable_cloud_env']) {
      this.updateUrlsFromEnv();
      return;
    }
    let cloudEnv = await localforage.getItem(`${constant.cloud_env}`);
    if (!cloudEnv) {
      return;
    }
    this.updateUrlsFromCloudEnv(cloudEnv);
  }

  async getCloudEnvFromDefaultEnv(email) {
    const response = await this.getCloudEnvByUserEmailAddress(email);
    response.data.cloudEnv.baseApis.learnApiBaseUri = process.env.VUE_APP_LEARN_API_BASE_URI;
    response.data.cloudEnv.baseApis.planApiBaseUri = process.env.VUE_APP_PLAN_API_BASE_URI;
    response.data.cloudEnv.baseApis.shareApiBaseUri = process.env.VUE_APP_SHARE_API_BASE_URI;
    response.data.cloudEnv.baseApis.searchApiBaseUri = process.env.VUE_APP_SEARCH_API_BASE_URI;
    response.data.cloudEnv.baseApis.reportingApiBaseUri = process.env.VUE_APP_REPORTING_API_BASE_URI;
    this.baseUri = process.env.VUE_APP_PLAN_API_BASE_URI;
    this.learnUri = process.env.VUE_APP_LEARN_API_BASE_URI;
    this.searchUri = process.env.VUE_APP_SEARCH_API_BASE_URI;
    this.shareUri = process.env.VUE_APP_SHARE_API_BASE_URI;
    this.trackUri = process.env.VUE_APP_TRACK_API_BASE_URI;
    this.reportingUri = process.env.VUE_APP_REPORTING_API_BASE_URI;
    this.applyUri = process.env.VUE_APP_APPLY_API_BASE_URI;
    this.agUri = process.env.VUE_APP_AUTOGRAPHER_API_BASE_URI;
    this.skillmsUri = process.env.VUE_APP_SKILLMS_BASE_URI;
    return response;
  }

  async getCloudEnvByUserEmailAddress(email) {
    return await cloud.getCloudEnvByEmail(`${this.ffUri}${constant.user}/${email}/${constant.cloud_env}`);
  }

  /*
   * Authentication
   */

  async login(payload) {
    return await auth.login(`${this.baseUri}${constant.login}`, payload);
  }

  async getUserInfo() {
    console.log(constant);
    return await auth.getUserInfo(`${this.baseUri}${constant.me}`);
  }

  async resetPassword(payload) {
    console.log(constant.reset_password);
    return await auth.basicAuthPostRequest(`${this.baseUri}${constant.reset_password}`, { email: payload.email, isSendMail: true });
  }

  async setPassword(payload) {
    return await auth.basicAuthPostRequest(`${this.baseUri}${constant.set_password}`, payload);
  }

  async registerUser(payload) {
    return await auth.basicAuthPostRequest(`${this.baseUri}${constant.register}`, payload);
  }

  async activateUser(payload) {
    return await auth.activateUser(`${this.baseUri}${constant.activate}`, payload);
  }

  async verifyToLogin(payload) {
    return await auth.verifyToLogin(`${this.baseUri}${constant.verify_and_login}`, payload);
  }

  async logout(payload) {
    return await auth.basicAuthGetRequest(`${this.baseUri}${constant.log_me_out}`, payload);
  }

  async checkSSOByEmail(payload) {
    return await auth.checkSSOByEmail(`${this.baseUri}${constant.check_sso_login}/${payload.email}`);
  }

  async ssoUserAcceptTerms() {
    return await auth.ssoUserAcceptTerms(`${this.baseUri}${constant.accept_terms}`);
  }

  async renewToken(payload) {
    return await auth.basicAuthGetRequest(`${this.baseUri}${constant.renew_token}`, payload);
  }

  /*
   * Autographer API
   */
  async getSignedUri(payload) {
    return await autographer.getSignedUri(`${this.agUri}`, payload);
  }

  /*
   * Plan
   */

  async getUserTenantInfo() {
    return await plan.getUserTenantInfo(`${this.baseUri}${constant.users}/${constant.me}/${constant.tenant}`);
  }
  async updateTenantLogo(payload) {
    return await plan.basicPlanPutRequest(`${this.baseUri}tenants/${payload.guid}`, payload);
  }

  async getUploadOrganizationStatus() {
    return await plan.basicPlanGetJSONRequest(`${this.baseUri}${constant.orgs}/${constant.upload_status}`);
  }

  async deleteUploadOrganizationStatus() {
    return await plan.basicPlanDeleteRequest(`${this.baseUri}${constant.orgs}/${constant.upload_status}`);
  }

  async uploadOrganizationStructure(payload, uploadType) {
    return await plan.basicPlanMultiPartPostRequest(`${this.baseUri}${constant.orgs}/tree2?uploadType=${uploadType}`, payload.form);
  }
  async uploadRolesAndSkills(payload) {
    return await plan.basicPlanMultiPartPostRequest(`${this.baseUri}${constant.jobs}/skillmap/${constant.v2}`, payload.form);
  }

  async downloadRoleSkillUploadTemplate() {
    return await plan.basicPlanGetRequestWithBlob(`${this.baseUri}${constant.jobs}/${constant.download_role_skill_template}`);
  }
  async downloadCredentialStructureTemplate() {
    return await plan.basicPlanGetRequestWithBlob(`${this.baseUri}${constant.jobs}/${constant.download_role_credential_template}`);
  }
  async downloadCourseStructureTemplate() {
    return await plan.basicPlanGetRequestWithBlob(`${this.baseUri}${constant.jobs}/${constant.download_role_course_template}`);
  }
  async downloadContentStructureTemplate() {
    return await plan.basicPlanGetRequestWithBlob(`${this.baseUri}${constant.jobs}/${constant.download_role_content_template}`);
  }
  async getCCCUploadState() {
    return await plan.basicPlanGetJSONRequest(`${this.baseUri}${constant.jobs}/uploadstate`);
  }

  async hasOrganizationStructure() {
    return await plan.hasOrganizationStructure(`${this.baseUri}${constant.orgs}/${constant.tree}/${constant.top_level}`);
  }

  async getOrganizationStructure() {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.orgs}/${constant.tree}/${constant.top_level}`);
  }

  async getRootOrganizationStructure() {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.orgs}/${constant.root}`);
  }
  async getRootOrgManagePeople() {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.orgs}/${constant.root}/${constant.children_orgs}`);
  }
  async getRootOrgManagePeopleReporting() {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${tenantGuid}/${constant.orgs}/${constant.root}/orgTrees`);
  }
  async getManageChildrenOrgStructure(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.orgs}/${payload.orgId}/${constant.children_orgs}`);
  }
  async getOrgEmployeeList(payload) {
    return await plan.basicPlanPostRequestWithPayload(`${this.baseUri}${constant.orgs}/${payload.orgId}/${constant.employee_list}`, payload);
  }
  async getManagePeopleRootOrgEmpList(payload) {
    return await plan.basicPlanPostRequestWithPayload(`${this.baseUri}${constant.orgs}/${constant.root}/${constant.tracks}/${payload.trackGuid}/${constant.employee_Lists}`, payload);
  }
  async getManagePeopleRootOrgEmpListForContent(payload) {
    return await plan.basicPlanPostRequestWithPayload(`${this.baseUri}${constant.orgs}/${constant.root}/${constant.contents}/${payload.contentId}/${constant.employee_Lists}`, payload);
  }
  async addSelectedPeople(payload) {
    return await plan.basicPlanPostRequestWithPayload(`${this.baseUri}${constant.orgs}/${constant.root}/${constant.tracks}/${payload.trackGuid}/${constant.learners}?${constant.send_notifications}=${payload.notifyLearners}`, payload);
  }
  async addSelectedPeopleInOrg(payload) {
    return await plan.basicPlanPostRequestWithPayload(`${this.baseUri}${constant.orgs}/${payload.orgId}/${constant.tracks}/${payload.trackGuid}/${constant.learners}?${constant.send_notifications}=${payload.notifyLearners}`, payload);
  }
  async removeSelectedPeople(payload) {
    return await plan.removeSelectedPeople(`${this.baseUri}${constant.orgs}/${constant.root}/${constant.tracks}/${payload.trackGuid}/${constant.learners}?${constant.send_notifications}=${payload.notifyLearners}`, payload);
  }
  async removeSelectedPeopleInOrg(payload) {
    return await plan.removeSelectedPeople(`${this.baseUri}${constant.orgs}/${payload.orgId}/${constant.tracks}/${payload.trackGuid}/${constant.learners}?${constant.send_notifications}=${payload.notifyLearners}`, payload);
  }
  async addSelectedPeopleForContent(payload) {
    return await plan.basicPlanPostRequestWithPayload(`${this.baseUri}${constant.orgs}/${constant.root}/${constant.contents}/${payload.contentId}/${constant.learners}?${constant.send_notifications}=${payload.notifyLearners}`, payload);
  }
  async addSelectedPeopleInOrgForContent(payload) {
    return await plan.basicPlanPostRequestWithPayload(`${this.baseUri}${constant.orgs}/${payload.orgId}/${constant.contents}/${payload.contentId}/${constant.learners}?${constant.send_notifications}=${payload.notifyLearners}`, payload);
  }
  async getChildrenOrganizationStructure(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.orgs}/${payload.orgId}/children`);
  }
  async getReportGenerationDate() {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${tenantGuid}/report-generation-dates/`);
  }
  async getDashboardDetailAllEmployees(time, payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    const statusCategory = time === 'ALLTIME' ? '' : `/${payload.statusCategory}`;
    const queryParams = time === 'CUSTOM' ? `?startDate=${payload.startDate}&endDate=${payload.endDate}&dateFormat=${payload.dateFormat}` : '';
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.enrollment}/${constant.employee_list}/${tenantGuid}/${constant.root}/${time}${statusCategory}${queryParams}`, payload);
  }

  async getDashboardDetailEmployeesFromOrg(id, time, payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    const statusCategory = time === 'ALLTIME' ? '' : `/${payload.statusCategory}`;
    const queryParams = time === 'CUSTOM' ? `?startDate=${payload.startDate}&endDate=${payload.endDate}&dateFormat=${payload.dateFormat}` : '';
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.enrollment}/${constant.employee_list}/${tenantGuid}/${id}/${time}${statusCategory}${queryParams}`, payload);
  }

  async getUsageDetailAllEmployees(time, payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    const queryParams = time === 'CUSTOM' ? `?startDate=${payload.startDate}&endDate=${payload.endDate}&dateFormat=${payload.dateFormat}` : '';
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.active_users}/${constant.learners_grid}/${tenantGuid}/${constant.root}/${time}${queryParams}`, payload);
  }

  async getUsageDetailEmployeesFromOrg(id, time, payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    const queryParams = time === 'CUSTOM' ? `?startDate=${payload.startDate}&endDate=${payload.endDate}&dateFormat=${payload.dateFormat}` : '';
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.active_users}/${constant.learners_grid}/${tenantGuid}/${id}/${time}${queryParams}`, payload);
  }

  async getDashboardConsumptionInsights(id, time) {
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.consumption}/${id}/${time}`);
  }

  async getDashboardContentTrendingInsights(payload) {
    const queryParams = payload.duration === 'CUSTOM' ? `?startDate=${payload.startDate}&endDate=${payload.endDate}&dateFormat=${payload.dateFormat}` : '';
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/contenttrend${queryParams}`, payload);
  }

  async downloadOrganizationStructure() {
    return await plan.basicPlanGetRequestWithBlob(`${this.baseUri}${constant.orgs}/download-org`);
  }

  async downloadOrganizationStructureTemplate() {
    return await plan.downloadBlobTemplate(`${this.baseUri}${constant.orgs}/download-template`);
  }

  async getSkillSummaryReport(payload) {
    return await plan.getSkillSummaryReport(`${this.baseUri}${constant.reports}/${payload.orgId}/${constant.skills}-${payload.type}`);
  }

  async getRoleByGuid(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.jobs}/${payload.roleGuid}`);
  }

  async getCloneRoleByGuid(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}ytc/${constant.jobs}/${payload.roleGuid}`);
  }

  async searchRole(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.jobs}/${constant.search}/?q=${payload.role}`);
  }

  async createRole(payload) {
    return await plan.basicPlanPostJSONRequestWithPayload(`${this.baseUri}match-jobs`, payload.role);
  }

  async addRole(payload) {
    return await plan.basicPlanPostJSONRequestWithPayload(`${this.baseUri}${constant.jobs}`, payload.role);
  }

  async cloneRole(payload) {
    return await plan.basicPlanPostJSONRequestWithPayload(`${this.baseUri}${constant.jobs}/${payload.roleGuid}/clone`, payload.role);
  }

  async editRole(payload) {
    return await plan.basicPlanPutRequest(`${this.baseUri}${constant.jobs}/${payload.roleGuid}`, payload.role);
  }

  async deleteRole(payload) {
    return await plan.basicPlanDeleteRequest(`${this.baseUri}${constant.jobs}/${payload.roleGuid}`);
  }

  async getRoleSkills(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.jobs}/${payload.guid}/${constant.skills}`);
  }

  async getSkillDescription(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}skillnames/${constant.search}/?q=${payload.skill}`);
  }

  async addSkill(payload) {
    return await plan.basicPlanPostJSONRequestWithPayload(`${this.baseUri}${constant.jobs}/${payload.roleGuid}/${constant.skills}`, payload.skill);
  }

  async removeSkill(payload) {
    return await plan.basicPlanDeleteRequest(`${this.baseUri}${constant.jobs}/${payload.roleGuid}/${constant.skills}/${payload.skillGuid}`);
  }

  async searchSkillByName(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.skills}/${constant.search}?term=${payload.skill}`);
  }

  async createNewTrack(payload) {
    return await track.createNewTrack(`${this.baseUri}${constant.tracks}`, payload);
  }
  async getRolesAndSkills(payload) {
    return await plan.basicPlanPostJSONRequestWithPayload(`${this.baseUri}${constant.jobs}/selection/skillgap`, payload);
  }

  async getManagePeopleEmpListByOrgId(payload) {
    return await plan.basicPlanPostRequestWithPayload(`${this.baseUri}${constant.orgs}/${payload.orgId}/${constant.tracks}/${payload.trackGuid}/${constant.employee_Lists}`, payload);
  }

  async getAnalyticRoles() {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.c2c_analytics}/${constant.roles}`);
  }

  async getAnalyticRolesTargetCompany(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.c2c_analytics}/${constant.roles}/${payload.role}/${constant.target_companies}`);
  }

  async getAnalyticRolesComparison(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.c2c_analytics}/${constant.roles}/${payload.role}/${constant.target_companies}/${payload.company}`);
  }

  async uploadResume(payload) {
    return await plan.basicPlanMultiPartPostRequest(`${this.baseUri}${constant.users}/${payload.eid}/upload-resume`, payload.form);
  }

  async getUserProfile(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.users}/${payload.id}/${constant.profile}`);
  }

  async updateUserInfo(payload) {
    return await plan.basicPlanPutRequest(`${this.baseUri}${constant.users}/${payload.eid}`, payload.info);
  }

  async userBroadcast(payload) {
    return await plan.userBroadcast(`${this.baseUri}${constant.users}/${payload.guid}/broadcast`);
  }

  async batchUsersBroadcast(payload) {
    return await plan.basicPlanPostJSONRequestWithPayload(`${this.baseUri}${constant.users}/broadcast-users`, payload.guids);
  }

  async getAllJobs() {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.jobs}`);
  }

  async getAllSkillsForRole(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.jobs}/${payload.guid}/${constant.skills}`);
  }

  async getUserSkillMatchingScore(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.users}/role-match?userid=${payload.eid}&jobGuid=${payload.rguid}`);
  }

  async submitHelpTicket(payload) {
    return await plan.submitHelpTicket(`${this.baseUri}help/${constant.me}`, payload.form);
  }

  async saveLearningTrack(payload) {
    return await plan.basicPlanPostJSONRequestWithPayload(`${this.baseUri}${constant.tracks}`, payload);
  }

  async updateLearningTrack(payload) {
    return await plan.basicPlanPutRequest(`${this.baseUri}${constant.tracks}/${payload.trackGuid}?${constant.send_notifications}=${payload.notifyLearners}`, payload);
  }

  async updateUserLanguage(payload) {
    return await plan.basicPlanPutRequest(`${this.baseUri}${constant.users}/${constant.me}/language`, payload);
  }
  /*
   * Learn
   */

  async getUserAcquiredSkillsByGuid(payload) {
    return await learn.basicLearnGetRequest(`${this.learnUri}${constant.users}/${payload.guid}/acquiredSkills`);
  }

  async getCredentialsByIds(payload) {
    return await learn.basicLearnGetRequest(`${this.learnUri}credentials/ids/${payload.ids}`);
  }

  async getUserActivitySummary(payload) {
    return await learn.basicLearnGetRequest(`${this.learnUri}${constant.users}/${payload.guid}/${constant.activity_summary}`);
  }

  async getCourseraStatus(payload) {
    return await learn.basicLearnGetRequest(`${this.learnUri}${constant.users}/${payload.guid}/course-status`);
  }

  async getCapstoneSummary() {
    return await learn.basicLearnGetRequest(`${this.learnUri}${constant.capstones}/summary`);
  }

  async getAllCapstones_Paged(payload) {
    return await learn.basicLearnGetRequest(`${this.learnUri}${constant.capstones}?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}`);
  }

  async getCapstoneById(payload) {
    return await learn.basicLearnGetRequest(`${this.learnUri}${constant.capstones}/${payload.id}`);
  }

  async startCapstoneReview(payload) {
    return await learn.startCapstoneReview(`${this.learnUri}${constant.capstones}/${payload.id}/start-review`);
  }

  async reviewCapstone(payload) {
    return await learn.reviewCapstone(`${this.learnUri}${constant.capstones}/${payload.id}/${constant.review}`, payload.reviewRequest);
  }

  async downloadCapstoneFile(payload) {
    return await learn.basicLearnGetRequestWithBlob(`${this.learnUri}${constant.capstones}/${payload.id}/download`);
  }

  async getCapstoneCreatorProfile(payload) {
    return await learn.basicLearnGetRequestWithBlob(`${this.learnUri}${constant.users}/${payload.guid}/${constant.profile}`);
  }

  /*
   * Search
   */

  async searchCCC(payload) {
    return await search.searchCCC(`${this.baseUri}${constant.catalog}/?action=${payload.action}&pageNum=${payload.pageNum}&pageSize=${payload.pageSize}`, payload);
  }
  async searchCCCTPlan(payload) {
    return await plan.basicPlanPostJSONRequestWithPayload(`${this.baseUri}${constant.catalog}/ccct?pageNum=${payload.pageno}&pageSize=${payload.pagesize}&sortby=${payload.sortby}`, payload);
  }
  async searchAutocomplete(payload) {
    return await search.searchAutocomplete(`${this.searchUri}${constant.search}/plan/autocomplete?q=${payload.query}`, payload);
  }
  async searchCCCPlan(payload) {
    return await plan.basicPlanPostJSONRequest(`${this.baseUri}${constant.catalog}?action=filter&pageNum=${payload.pageno}&pageSize=${payload.pagesize}`, payload);
  }
  async searchRecommendedPlan(payload) {
    return await plan.basicPlanPostJSONRequest(`${this.baseUri}${constant.catalog}/${constant.recommended_catalog}?action=filter&pageNum=${payload.pageno}&pageSize=${payload.pagesize}`, payload);
  }
  /****
   * Learning Track calls
   */
  async recommendLearningTrack(payload) {
    return await plan.basicPlanPostJSONRequestWithPayload(`${this.baseUri}${constant.tracks}/recommended-items?action=filter`, payload);
  }
  async createTrack(payload) {
    return await plan.basicPlanMultiPartPostRequest(`${this.trackUri}${constant.v2}/${constant.tracks}`, payload);
  }
  async editCreatedTrack(payload) {
    return await track.editCreatedTrack(`${this.trackUri}${constant.v1}/${constant.tracks}/${payload.trackGuid}/?assignedByApp=PLAN`, payload);
  }

  async deleteCreatedTrack(id) {
    return await track.deleteCreatedTrack(`${this.trackUri}${constant.v1}/${constant.tracks}/${id}`);
  }
  async recommendNewTrack(payload) {
    return await track.basicLearningTrackPostRequest(`${this.baseUri}${constant.jobs}/${payload.roleGuid}/${constant.tracks}/new/${constant.recommended_catalog}?action=${payload.action}&pageNum=${payload.pageNum}&pageSize=18`, payload);
  }
  async editTrack(payload) {
    return await track.basicLearningTrackPostRequest(`${this.baseUri}${constant.jobs}/${payload.roleGuid}/${constant.tracks}/${payload.trackGuid}/${constant.recommended_catalog}?action=${payload.action}&pageNum=${payload.pageNum}&pageSize=18`, payload);
  }
  async getTrackData() {
    return await track.getTrackData(`${this.trackUri}${constant.v1}/${constant.tracks}`);
  }
  async getTracksForRole(payload) {
    return await track.getTracksForRole(`${this.trackUri}${constant.v1}/${constant.roles}/${payload.roleGuid}/${constant.tracks}`);
  }
  async getTracksForUser(payload) {
    return await track.getTracksForUser(`${this.baseUri}${constant.users}/${payload.userId}/${constant.tracks}`);
  }
  async editSkillBasedTrack(payload) {
    return await track.editSkillBasedTrack(`${this.baseUri}${constant.tracks}/${payload.trackGuid}/${constant.recommended_catalog}?action=${payload.action}&pageNum=${payload.pageNum}&pageSize=18`, payload);
  }
  async getEditTrackDetails(payload) {
    return await track.getEditTrackDetails(`${this.baseUri}${constant.tracks}/${payload.trackGuid}/basic`);
  }
  async getTrackDetails(trackGuid) {
    return await track.getTrackDetails(`${this.trackUri}${constant.v1}/${constant.tracks}/${trackGuid}`);
  }
  async getTrackItemsList(payload) {
    return await track.getTrackItemsList(`${this.baseUri}${constant.tracks}/${payload.trackGuid}/${constant.items}?version=2`, payload);
  }
  async saveDueDates(payload) {
    return await track.saveDueDates(`${this.trackUri}${constant.v1}/${constant.tracks}/${payload.trackGuid}/requiredLearning?${constant.send_notifications}=${payload.notifyLearners}&assignedByApp=plan`, payload);
  }
  async getGuidForDueDatesCsvDownload(payload) {
    return await track.learningTrackPostRequest(`${this.baseUri}${constant.tracks}/${payload.trackGuid}/learnersCSV`, payload.employeeGuids);
  }
  async saveDueDatesForContent(payload) {
    return await track.saveDueDates(`${this.learnUri}learning-plan/contents/${payload.contentId}/assign`, payload);
  }
  async unAssignDueDatesForContent(payload) {
    return await plan.basicPlanDeleteRequestWithReqBody(`${this.learnUri}learning-plan/contents/${payload.contentId}/unassign`, payload.data);
  }
  async getCsvForDueDates(reqId) {
    return await track.learningTrackGetRequest(`${this.baseUri}csv-generation/status/${reqId}`);
  }
  async getGovernanceFunctions() {
    return await plan.basicPlanGetJSONRequest(`${this.baseUri}${constant.jobs}/functions`);
  }

  /****
   * Reporting calls
   * Note: they all need to get a reporting token from plan-api, then attach it when callng reporting-api
   */
  async getEmbedUrl(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.reports}/get-signed-dashboard-url?type=${payload.type}`);
  }

  // async getReportingToken() {
  //   return await reporting.getReportingToken(`${this.baseUri}reports/get-token`);
  // }

  async getInvitedRate(payload) {
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/invitation/${payload.tenantGuid}/${payload.timePeriod}`);
  }

  async getActiveUsers(payload) {
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.active_users}/${payload.tenantGuid}/${payload.timePeriod}`);
  }

  async getEnrolledRate(payload) {
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.enrollment}/${payload.tenantGuid}/${payload.timePeriod}`);
  }

  async getEnrolledTrends(payload) {
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/enrollmenttrend/${payload.tenantGuid}/${payload.timePeriod}`);
  }

  async getUsageTrends(payload) {
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/usage-trend/${payload.tenantGuid}/${payload.timePeriod}`);
  }

  async getConsumptionTrends(payload) {
    const queryParams = payload.timePeriod === 'CUSTOM' ? `?startDate=${payload.startDate}&endDate=${payload.endDate}&dateFormat=${payload.dateFormat}` : '';
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/consumptiontrend/${payload.tenantGuid}/${payload.timePeriod}/${payload.statusCategory}${queryParams}`);
  }

  async getConsumptionDetailAllEmployees(reqParams, payload) {
    const queryParams = reqParams.timeFilter === 'CUSTOM' ? `?startDate=${payload.startDate}&endDate=${payload.endDate}&dateFormat=${payload.dateFormat}` : '';
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.consumption}/${constant.learners}/${reqParams.tenantGuid}/${constant.root}/${reqParams.timeFilter}${queryParams}`, payload);
  }

  async consumptionDownloadPerUser(tenantId, payload) {
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.consumption}/learners-record/${tenantId}/${constant.root}/ALLTIME`, payload);
  }

  async getRequiredLearningCompletionRateChartData(payload) {
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.consumption}/required-learning/content-completion-rate/${payload.tenantGuid}`);
  }

  async getRequiredLearningOverdueRateChartData(payload) {
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.consumption}/required-learning/over-due-users/${payload.tenantGuid}`);
  }

  async consumptionDownloadPerORGgetRequestID(payload) {
    const queryParams = payload.timePeriod === 'CUSTOM' ? `?startDate=${payload.startDate}&endDate=${payload.endDate}&dateFormat=${payload.dateFormat}` : '';
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.consumption}/learners-record-csv-request-id/${payload.tenantGuid}/${payload.orgId}/${payload.timePeriod}${queryParams}`);
  }

  async getORGConsumptionSummaryByRequestID(payload) {
    return await reporting.basicReportingGetRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.consumption}/org-consumption-summary-csv-request-id/${payload.tenantGuid}/${payload.orgId}/${payload.timeFilter}`);
  }

  async getORGUsageSummaryByRequestID(orgDetails, payload) {
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/usage/org-usage-summary-csv-request-id/${orgDetails.tenantGuid}/${orgDetails.orgId}/${orgDetails.timeFilter}`, payload);
  }

  async getUserLearningRecord(payload) {
    const queryParams = payload.timePeriod === 'CUSTOM' ? `?startDate=${payload.startDate}&endDate=${payload.endDate}&dateFormat=${payload.dateFormat}` : '';
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.consumption}/learners-record-csv-request-id/${payload.tenantGuid}/${payload.orgId}/${payload.timePeriod}${queryParams}`, payload);
  }

  async requiredLearningDownloadPerORGgetRequestID(payload) {
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.consumption}/required-learning/learners-record-csv-request-id/${payload.tenantGuid}/${payload.orgId}/ALLTIME`, payload);
  }

  async consumptionDownloadPerORGgetUpdate(requestId) {
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.consumption}/learners-record-generated-csv/${requestId}`);
  }
  async usageDownloadPerORGgetUpdate(requestId) {
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/csv-generation-status/${requestId}`);
  }
  async getConsumptionDetailAllEmployeesFromOrg(reqParams, payload) {
    const queryParams = reqParams.timeFilter === 'CUSTOM' ? `?startDate=${payload.startDate}&endDate=${payload.endDate}&dateFormat=${payload.dateFormat}` : '';
    return await reporting.basicReportingPostRequest(`${this.reportingUri}${constant.reporting}/${constant.plan_reports}/${constant.consumption}/${constant.learners}/${reqParams.tenantGuid}/${reqParams.id}/${reqParams.timeFilter}${queryParams}`, payload);
  }

  /*
   * Apply
   */

  async getAssetKpi() {
    return await apply.getAssetKpi(`${this.applyUri}automations/tenant-metrics`);
  }

  async getManageSubmissionsList() {
    const payload = {
      pageNo: 1,
      pageSize: 0
    };
    return await apply.basicApplyGetRequest(`${this.applyUri}${constant.governance}/tenant-items?pageNo=${payload.pageNo}&pageSize=${payload.pageSize}`);
  }

  async getAssetItemById(payload) {
    return await apply.basicApplyGetRequest(`${this.applyUri}${constant.governance}/${constant.items}/${payload.id}`);
  }

  async onReviewSubmit(payload) {
    return await apply.onReviewSubmit(`${this.applyUri}${constant.governance}/${constant.items}/${payload.itemId}/${constant.review}`, payload);
  }

  async downloadHistoryLog() {
    return await apply.downloadHistoryLog(`${this.applyUri}${constant.governance}/download-audit-log`);
  }

  async getGovernanceTools() {
    return await apply.basicApplyGetRequest(`${this.applyUri}${constant.generic}/tools`);
  }

  async getAssetInventoryTypes() {
    return await apply.basicApplyGetRequest(`${this.applyUri}${constant.generic}/inventory-types`);
  }
  /*
   * Share
   */

  async getIdeaAutomationActivitySummary(payload) {
    return await share.basicShareGetRequest(`${this.shareUri}${constant.users}/${payload.guid}/${constant.activity_summary}`);
  }

  async downloadAssetZipFile(payload, controller, onDownloadProgress) {
    return await share.downloadAssetZipFile(`${this.applyUri}${constant.governance}/${constant.items}/${payload.id}/download-assetFile`, controller, onDownloadProgress);
  }
  async deleteImageFromCreateAsset(payload) {
    return await share.basicShareDeleteRequestNoBearer(`${this.applyUri}${constant.governance}/${constant.items}/${payload.id}/images/${payload.imagePointer}`, payload);
  }

  async deletePdfFromCreateAsset(payload) {
    return await share.basicShareDeleteRequestNoBearer(`${this.applyUri}${constant.governance}/${constant.items}/${payload.id}/fileRefs/${payload.pdfPointer}`, payload);
  }

  async deleteAssetFile(payload) {
    return await share.basicShareDeleteRequestNoBearer(`${this.applyUri}${constant.governance}/${constant.items}/${payload.id}/assetFile`, payload);
  }

  async submitAsset(payload) {
    return await share.submitAsset(`${this.applyUri}${constant.governance}/${constant.items}`, payload);
  }

  async uploadAssets(payload, controller, onUploadProgress) {
    return await share.uploadAssets(`${this.applyUri}${constant.governance}/${constant.items}/uploadAsset`, payload, controller, onUploadProgress);
  }

  /*
   *announcement api
   */
  async uploadAnnouncementFile(payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await share.uploadAnnouncementFile(`${this.learnUri}upload-media?tenantGuid=${tenantGuid}`, payload);
  }
  async getUploadAnnouncementDetails(id) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await share.basicShareGetRequest(`${this.learnUri}upload-media/${id}/status?tenantGuid=${tenantGuid}`);
  }
  async submitAnnouncement(payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await share.basicSharePostRequest(`${this.learnUri}${constant.announcement}?tenantGuid=${tenantGuid}&&isPublishNow=${payload.isPublishNow}`, payload);
  }
  async deleteAnnoncementFile(payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await share.basicShareDeleteRequestPayload(`${this.learnUri}upload-media?tenantGuid=${tenantGuid}`, payload);
  }
  async getPaginatedAnnouncementsList(payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await share.basicShareGetRequest(`${this.learnUri}${constant.announcement}/all?tenantGuid=${tenantGuid}&pageNumber=${payload.pageNum}&pageSize=8`, payload);
  }
  async getAllAnnouncementsList(payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await share.basicShareGetRequest(`${this.learnUri}${constant.announcement}/all?tenantGuid=${tenantGuid}&pageNumber=${payload.pageNum}&pageSize=8&&isPaginationDisabled=${payload.isPaginationDisabled}`, payload);
  }
  async getAnnouncementDetails(payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await share.basicShareGetRequest(`${this.learnUri}${constant.announcement}/${payload.guid}?tenantGuid=${tenantGuid}`, payload);
  }
  async updateAnnouncementDetails(payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await share.basicSharePutRequest(`${this.learnUri}${constant.announcement}?tenantGuid=${tenantGuid}&&isPublishNow=${payload.isPublishNow}`, payload);
  }
  async deleteAnnouncementDetails(guid) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await share.basicShareDeleteRequest(`${this.learnUri}${constant.announcement}/${guid}?tenantGuid=${tenantGuid}`);
  }
  /*
   * Feature Flags
   */

  async getFeatureFlags() {
    return await fflag.getFeatureFlags();
  }

  async getBackupFeatureFlags() {
    return await fflag.getBackupFeatureFlags();
  }

  /*
   * users
   */

  async getAllUsers(payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.tenants}/${tenantGuid}/${constant.users}?pageNumber=${payload.pageNo}&pageSize=${payload.pageSize}&query=${payload.query}&roles=${payload.userType}`);
  }

  async getAllPermissions() {
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.Permissions}`);
  }

  async getContentTags(payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.tenants}/${tenantGuid}/tags?pageno=${payload.pageNo}&pagesize=${payload.pageSize}`);
  }

  async getContentGroupTags(payload) {
    const tenantGuid = await localforage.getItem('my_tenant_guid');
    return await plan.basicPlanGetRequest(`${this.baseUri}${constant.tenants}/${tenantGuid}/grouptags?pageno=${payload.pageNo}&pagesize=${payload.pageSize}`);
  }

  async getUserTags(payload) {
    return await plan.basicPlanGetRequest(`${this.baseUri}accesstags?pageno=${payload.pageNo}&pagesize=${payload.pageSize}`);
  }

  async updateUser(params, payload) {
    return await plan.basicPlanPutRequest(`${this.baseUri}${constant.users}/guids/${params.userGuid}/`, payload);
  }

  /*
   * Hr tools
   */
  async uploadSkillExtractionFile(payload) {
    return await share.uploadSkillExtractionFile(`${this.skillmsUri}${constant.talent_architecture}/${constant.parser}`, payload);
  }

  async uploadTalentGPTFile(payload) {
    return await share.uploadSkillExtractionFile(`${this.skillmsUri}${constant.talent_architecture}/${constant.upload_file}`, payload);
  }

  async uploadRoleFile(payload) {
    return await share.uploadSkillExtractionFile(`${this.skillmsUri}skill-profile/upload`, payload);
  }

  async getSkillExtractionFiles(payload) {
    const fileSize = payload.size ? `&size=${payload.size}` : '';
    return await share.getRequestSkillExtraction(`${this.skillmsUri}${constant.talent_architecture}/${constant.list_files}?jobType=${payload.jobType}${fileSize}&companyGuid=''`, payload);
  }

  async getSkillProfile(payload) {
    return await share.postRequestSkillExtraction(`${this.skillmsUri}skill-profile/fetch-details?architecture=${payload.architecture}&roleName=${payload.role}`, {});
  }

  async getSkillExtractionFileSignedRelativeUri(payload) {
    return await share.postRequestSkillExtraction(`${this.baseUri}${constant.hr_tools}/${constant.skills}/${constant.get_download_url}`, payload);
  }

  async downloadSkillProfile(payload) {
    return await share.postDownloadFile(`${this.skillmsUri}skill-profile/download?downloadFileName=${payload.downloadFileName}`, payload.rolesPayload);
  }

  async fetchRoles(payload) {
    return await share.getRequestSkillExtraction(`${this.skillmsUri}skill-profile/job-architecture/fetch-roles?architecture=${payload.architecture}`, {});
  }

  async fetchFileRoles() {
    return await share.getRequestSkillExtraction(`${this.skillmsUri}skill-profile/uploadFile/fetch-roles`, {});
  }

  async downloadTemplate(payload) {
    return await share.postRequestSkillExtraction(`${this.baseUri}hr-tools/download-template`, payload);
  }

  // Future of work apis
  async addCompany(payload) {
    return await share.postRequestSkillExtraction(`${this.skillmsUri}future-of-work/add-company?companyName=${payload.companyName}`, payload);
  }

  async getCompanyList() {
    return await share.getRequestSkillExtraction(`${this.skillmsUri}future-of-work/fetch-companies`);
  }

  async getCompanyFiles(payload) {
    const fileSize = payload.size ? `&size=${payload.size}` : '';
    return await share.getRequestSkillExtraction(`${this.skillmsUri}${constant.talent_architecture}/${constant.list_files}?jobType=${payload.jobType}${fileSize}&companyGuid=${payload.companyGuid}`, payload);
  }

  async getFunctionList(payload) {
    return await share.postRequestSkillExtraction(`${this.skillmsUri}future-of-work/fetch-functionNames`, payload);
  }

  async getRoleList(payload) {
    return await share.postRequestSkillExtraction(`${this.skillmsUri}future-of-work/fetch-roleNames`, payload);
  }

  async getFow(payload) {
    return await share.postRequestSkillExtraction(`${this.skillmsUri}future-of-work/fetch-fow`, payload);
  }

  async updateFow(payload) {
    return await share.postRequestSkillExtraction(`${this.skillmsUri}future-of-work/update-fow`, payload);
  }

  async fetchCompanyGuid(payload) {
    return await share.postRequestSkillExtraction(`${this.skillmsUri}future-of-work/fetch-companyGuid`, payload);
  }

  async manualIngestionFileUpload(payload) {
    return await share.uploadSkillExtractionFile(`${this.skillmsUri}future-of-work/upload-ingestion-file`, payload);
  }
}
