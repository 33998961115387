<template>
  <div :class="isTimeout ? 'timeout' : ''">
    <div class="home">
      <div class="dots">
        <img alt="" class="dots-1" src="@/assets/images/svgs/FPO_dot_pattern-1.svg" />
        <img alt="" class="dots-2" src="@/assets/images/svgs/FPO_dot_pattern-2.svg" />
      </div>

      <section class="home-header">
        <h1 class="home-header-text">{{ $t('landing.header') }}</h1>
      </section>

      <div v-if="isTimeout" class="timeout-panel">
        <v-card>
          <h4>{{ $t('timeout.message') }}</h4>
          <p>{{ $t('timeout.details') }}</p>
          <v-row no-gutters>
            <v-btn class="ok-button" rounded @click="onOk">Ok</v-btn>
          </v-row>
        </v-card>
      </div>

      <div class="home-body">
        <v-row tag="section" class="home-section">
          <v-col md="5" sm="12" class="home-section-text">
            <h4>{{ $t('landing.section[0].header') }}</h4>
            <p>{{ $t('landing.section[0].sub') }}</p>
          </v-col>
          <v-col md="4" sm="12" class="home-section-img">
            <img alt="" src="@/assets/images/svgs/FPO_4.svg" />
          </v-col>
        </v-row>

        <v-row tag="section" class="home-section">
          <v-col md="4" sm="12" class="home-section-img">
            <img alt="" class="thin-img" src="@/assets/images/svgs/FPO_5.svg" />
          </v-col>
          <v-col md="5" sm="12" class="home-section-text left-space">
            <h4>{{ $t('landing.section[1].header') }}</h4>
            <p>{{ $t('landing.section[1].sub') }}</p>
          </v-col>
        </v-row>

        <v-row tag="section" class="home-section">
          <v-col md="5" sm="12" class="home-section-text">
            <h4>{{ $t('landing.section[2].header') }}</h4>
            <p>{{ $t('landing.section[2].sub') }}</p>
          </v-col>
          <v-col md="4" sm="12" class="home-section-img">
            <img alt="" src="@/assets/images/svgs/FPO_6.svg" />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { setNewLocale, getFallbackLanguage } from '../../plugins/i18n.js';
import { mapGetters } from 'vuex';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'Welcome',
  data() {
    return {
      isTimeout: false
    };
  },
  computed: {
    ...mapGetters(['enabledFeatureFlags'])
  },
  async mounted() {
    // only switch if the flag is on
    if (this.enabledFeatureFlags && this.enabledFeatureFlags['plan_i18n_enable-language-picker']) {
      const browserLangCode = navigator.language.toLocaleLowerCase();
      const ourLanguageCode = getFallbackLanguage(browserLangCode);
      setNewLocale(ourLanguageCode);
    }
    this.isTimeout = this.$route.query && this.$route.query.timeout;
  },
  methods: {
    onOk() {
      this.isTimeout = false;
      this.$router.push('/').catch(() => {});
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.welcome')
    };
  }
};
</script>

<style lang="scss" scoped>
.home {
  z-index: 5;
  display: block;
  margin: auto;
  padding: auto;
}

.home-header {
  display: block;
  padding: auto;
  margin: auto;
  text-align: center;
  z-index: 5;

  .home-header-text {
    position: relative;
    margin-top: 3rem;
    z-index: 5;
    font-size: 65px;
    color: #182755;
  }
}

.home-body {
  position: relative;
  z-index: 5;
  display: block;
  margin: auto;
  padding: 0rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .home-section {
    display: flex;
    .home-section-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin: 0 2rem;
      h4 {
        font-size: 34px;
        line-height: 3rem;
        margin: 2rem 0rem;
      }
      p {
        font-size: 18px;
        line-height: 2rem;
      }
    }
    .left-space {
      margin-left: 10rem;
    }

    .home-section-img {
      margin: 0 2rem;
      img {
        max-width: 35rem;
      }
    }
  }

  @media screen and (max-width: 432px) {
    padding: 0;
  }
}

.dots-1 {
  position: absolute;
  top: 0%;
  right: 23%;
  z-index: 0;
}
.dots-2 {
  position: absolute;
  bottom: 15%;
  left: 20%;
  z-index: 0;
}

.timeout-panel {
  position: fixed;
  top: 35%;
  left: 35vw;
  width: 450px;
  z-index: 3040;
  .v-card {
    height: 160px;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid $button-primary-color-bg;
    h4,
    p {
      font-size: 16px;
    }
    h4 {
      margin: 0 0 20px;
    }
  }
  .no-gutters {
    justify-content: flex-end;
    align-items: center;
    button {
      margin-right: 20px;
    }
  }
}

.ok-button {
  width: 100px;
  background: $button-primary-color-bg !important;
  color: $button-primary-color-text !important;
}

.timeout {
  background: $color-grey-light;
}
</style>
