<template>
  <v-select
    v-bind="$attrs"
    @blur="handleBlur"
    @change="handleChange"
    @click="handleClick"
    @click:clear="handleClickClear"
    @click:prepend="handleClickPrepend"
    @click:prepend-inner="handleClickPrependInner"
    @focus="handleFocus"
    ref="selectDropdownRef"
    @input="handleInput"
    @keydown="handleKeydown"
    @mousedown="handleMousedown"
    @mouseup="handleMouseup"
    @update:error="handleUpdateError"
    @update:list-index="handleUpdateListIndex"
    @update:search-input="handleUpdateSearchInput"
  >
    <template v-for="(_, scopedSlotName) in $slots" v-slot:[scopedSlotName]="data">
      <slot :name="scopedSlotName" v-bind="data" />
    </template>
  </v-select>
</template>

<script>
export default {
  emits: ['change', 'focus', 'blur', 'click', 'input', 'keydown', 'mousedown', 'mouseup'],
  mounted() {
    const inputElement = this?.$refs?.selectDropdownRef?.$el.querySelector('[type="text"]');
    const buttonElement = this?.$refs?.selectDropdownRef?.$el.querySelector('[role="button"]');
    buttonElement && buttonElement.removeAttribute('aria-haspopup');
    inputElement.removeAttribute('type');
    inputElement.removeAttribute('aria-readonly');
  },
  methods: {
    handleBlur(e) {
      this.$emit('blur', e);
    },
    handleChange(e) {
      this.$emit('change', e);

      this.$nextTick(() => {
        this.updateAriaValues();
      });
    },
    handleClick(e) {
      this.$emit('click', e);

      this.$nextTick(() => {
        this.updateAriaValues();
      });
    },
    handleClickClear(e) {
      this.$emit('click:clear', e);
    },
    handleClickPrepend(e) {
      this.$emit('click:prepend', e);
    },
    handleClickPrependInner(e) {
      this.$emit('click:prepend-inner', e);
    },
    handleFocus(e) {
      this.$emit('focus', e);
    },
    handleInput(e) {
      this.$emit('input', e);
    },
    handleKeydown(e) {
      this.$emit('keydown', e);

      this.$nextTick(() => {
        const { code } = e;
        if (code === 'Enter' || code === 'Space') {
          this.updateAriaValues();
        }
      });
    },
    handleMousedown(e) {
      this.$emit('mousedown', e);
    },
    handleMouseup(e) {
      this.$emit('mouseup', e);
    },
    handleUpdateError(e) {
      this.$emit('update:error', e);
    },
    handleUpdateListIndex(e) {
      this.$emit('update:list-index', e);
    },
    handleUpdateSearchInput(e) {
      this.$emit('update:search-input', e);
    },
    // eslint-disable-next-line no-undef
    updateAriaValues: _.debounce(function () {
      if (!this.multiple) return;
      this.$nextTick(() => {
        const selectOptions = this.$refs.parent.$el.querySelectorAll('[role="option"]');

        for (const option of selectOptions) {
          const optionContent = option.querySelector('[class*="v-list-item__content"]');
          const elementToUpdate = optionContent.querySelector('[class*="v-list-item__title"]') || optionContent;

          elementToUpdate.setAttribute('role', 'checkbox');
          elementToUpdate.setAttribute('aria-checked', option.getAttribute('aria-selected'));

          if (elementToUpdate.childElementCount !== 0) {
            elementToUpdate.setAttribute('aria-label', elementToUpdate.innerText);
          }
        }
      });
    }, 200)
  }
};
</script>
