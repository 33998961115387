<template>
  <div class="dashboard-details-table-filters">
    <div class="drilldown-search-filter-container">
      <div class="drilldown-search-filter" v-for="(filter, i) in filterValues.slice(0, 5)" :class="filter.hide ? 'filter-hide' : ''" :key="filter.name" :style="filter.showExpandedFilter ? 'zIndex:10' : 'zIndex:2'">
        <div class="backdrop" v-if="filter.showExpandedFilter" @click="onBackdrop(i)"></div>
        <v-col class="searchFilter" role="combobox" tabindex="0" @click="openDropdown(filter, i)" @keydown.enter="openDropdown(filter, i)">
          <div class="filter">
            <div :style="filter.disabled || filter.values.length == 0 ? 'pointer-events: none' : ''" :class="filter.showExpandedFilter ? 't-border-black' : ''" class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black">
              <span class="t-font-arial t-text-sm" :style="filter.values.length == 0 || filter.disabled ? 'color:rgb(0, 0, 0, 0.2)' : ''">{{ $t(filter.translationKey) }}</span>
              <v-icon :style="filter.values.length == 0 || filter.disabled ? 'opacity:0.3' : ''">mdi-chevron-down</v-icon>
            </div>
          </div>
        </v-col>

        <div class="insights-filter-dropdown" v-if="filter.showExpandedFilter && filter.name == 'Status' && comingFromUsage">
          <ul class="list-with-no-filter">
            <li>
              <v-checkbox
                :key="filter.values[0].name"
                :disabled="{}.hasOwnProperty.call(filter.values[0], 'disable') && !filter.values[0].disable"
                :label="$t('insights.usage.activeLabel')"
                dense
                :value="filter.values[0].name"
                v-model="selectedFilters[i].values"
                style="display: inline-block"
              />
            </li>
            <li>
              <v-checkbox
                :key="filter.values[1].name"
                :disabled="{}.hasOwnProperty.call(filter.values[1], 'disable') && !filter.values[1].disable"
                :label="$t('insights.usage.notActiveLabel')"
                dense
                :value="filter.values[1].name"
                v-model="selectedFilters[i].values"
                style="display: inline-block"
              />
            </li>
          </ul>
          <div class="filter-option-footer">
            <div class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</div>
            <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
          </div>
        </div>

        <div class="insights-filter-dropdown" v-if="filter.showExpandedFilter && filter.name == 'Status' && !comingFromUsage">
          <div v-if="timeFilter != 'ALLTIME'">
            <div class="radio-filter"><input type="radio" v-model="statusCategory" value="enrollment" @click="onStatusCategoryChange" />{{ $t('insights.enrollments.enrolledLabel') }}</div>
            <v-checkbox class="t-ml-12" :disabled="statusCategory != 'enrollment'" :label="$t('insights.enrollments.onboarded')" dense style="display: flex" value="Onboarded" v-model="selectedFilters[i].values"></v-checkbox>
            <v-checkbox class="t-ml-12" :disabled="statusCategory != 'enrollment'" :label="$t('insights.enrollments.registered')" dense style="display: flex" value="Registered" v-model="selectedFilters[i].values"></v-checkbox>
            <div class="radio-filter"><input type="radio" v-model="statusCategory" value="invited" @click="onStatusCategoryChange" />{{ $t('insights.enrollments.invitedLabel') }}</div>
            <v-checkbox class="t-ml-12" :disabled="statusCategory != 'invited'" :label="$t('insights.enrollments.inviteSent')" dense style="display: flex" value="Invite Sent" v-model="selectedFilters[i].values"></v-checkbox>
            <v-checkbox class="t-ml-12" :disabled="statusCategory != 'invited'" :label="$t('insights.enrollments.inviteResent')" dense style="display: flex" value="Invite Resent" v-model="selectedFilters[i].values"></v-checkbox>
            <div class="radio-filter"><input type="radio" v-model="statusCategory" value="notinvited" @click="onStatusCategoryChange" />{{ $t('insights.enrollments.notInvitedLabel') }}</div>
            <v-checkbox class="t-ml-12" :disabled="statusCategory != 'notinvited'" :label="$t('insights.enrollments.invitePending')" dense style="display: flex" value="Invite Pending" v-model="selectedFilters[i].values"></v-checkbox>
            <v-checkbox class="t-ml-12" :disabled="statusCategory != 'notinvited'" :label="$t('insights.enrollments.emailNotSent')" dense style="display: flex" value="Email Not Set" v-model="selectedFilters[i].values"></v-checkbox>
          </div>
          <div v-if="timeFilter == 'ALLTIME'" class="t-flex t-flex-col drilldown-status-filter">
            <label class="t-ml-8">{{ $t('insights.enrollments.enrolledLabel') }}</label>
            <v-checkbox class="t-ml-12" :label="$t('insights.enrollments.onboarded')" dense style="display: inline-block" value="Onboarded" v-model="selectedFilters[i].values"></v-checkbox>
            <v-checkbox class="t-ml-12 odd-item" :label="$t('insights.enrollments.registered')" dense style="display: inline-block" value="Registered" v-model="selectedFilters[i].values"></v-checkbox>
            <label class="t-ml-8">{{ $t('insights.enrollments.invitedLabel') }}</label>
            <v-checkbox class="t-ml-12" :label="$t('insights.enrollments.inviteSent')" dense style="display: inline-block" value="Invite Sent" v-model="selectedFilters[i].values"></v-checkbox>
            <v-checkbox class="t-ml-12 odd-item" :label="$t('insights.enrollments.inviteResent')" dense style="display: inline-block" value="Invite Resent" v-model="selectedFilters[i].values"></v-checkbox>
            <label class="t-ml-8">{{ $t('insights.enrollments.notInvitedLabel') }}</label>
            <v-checkbox class="t-ml-12" :label="$t('insights.enrollments.invitePending')" dense style="display: inline-block" value="Invite Pending" v-model="selectedFilters[i].values"></v-checkbox>
            <v-checkbox class="t-ml-12 odd-item" :label="$t('insights.enrollments.emailNotSet')" dense style="display: inline-block" value="Email Not Set" v-model="selectedFilters[i].values"></v-checkbox>
          </div>

          <div class="filter-option-footer">
            <div class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</div>
            <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
          </div>
        </div>
        <div class="insights-filter-dropdown" v-if="filter.showExpandedFilter && filter.values.length <= 5 && filter.name != 'Status'">
          <ul class="list-with-no-filter">
            <li v-for="(item, index) in filter.values" :key="index">
              <v-checkbox :key="item.name" :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable" :label="item.name" dense :value="item.name" v-model="selectedFilters[i].values" style="display: inline-block" />
            </li>
          </ul>
          <div class="filter-option-footer">
            <div class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</div>
            <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
          </div>
        </div>
        <div class="insights-filter-dropdown" v-if="filter.showExpandedFilter && filter.values.length > 5 && filter.name != 'Status'">
          <v-text-field
            class="insights-text-input"
            prepend-inner-icon="mdi-magnify"
            :label="$t('common.filter.searchPrompt') + $t(filter.translationKey)"
            v-model="queryFilter"
            @input="filterOptions($event, i)"
            rounded
            clearable
            single-line
            hide-details
            filled
            dense
            solo
            variant="solo"
          ></v-text-field>

          <div class="t-text-center" v-if="filteredOptions.length == 0">{{ $t('common.noMatchesFound') }}</div>
          <ul :class="filteredOptions.length > 5 ? 'scrollable' : ''">
            <li v-for="(item, index) in filteredOptions" :key="index">
              <v-checkbox :key="item.name" :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable" :label="item.name" dense :value="item.name" v-model="selectedFilters[i].values" style="display: inline-block" />
            </li>
          </ul>
          <div class="filter-option-footer">
            <div class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</div>
            <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapDurations, numberWithCommas, sentenceCase, getLocalizedUsageStatus, getLocalizedEnrollmentStatus } from '@/utils';
import localforage from 'localforage';

const initialState = () => ({
  isC1: null,
  comingFromUsage: null,
  mapDurations,
  queryFilter: '',
  filteredOptions: [],
  showExpandedFilter: false,
  showFilters: false,
  values: [],
  displaySelectedFilters: [],
  selectedFilters: [],
  filterValues: [],
  statusCategory: 'enrollment',
  initialLoad: true
});

export default {
  name: 'InsightsDrillDowntableFilters',
  emits: ['status-category-changed', 'filter-applied', 'interface'],
  props: {
    filterResults: {
      type: Object,
      require: true,
      default: () => {
        return {};
      }
    },
    timeFilter: {
      type: String,
      default() {
        return '';
      }
    }
  },
  data() {
    return initialState();
  },
  async mounted() {
    Object.assign(this.$data, initialState());
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
    this.comingFromUsage = this.$parent.$options.name === 'Usage';
    this.selectedFilters = [
      { name: 'Status', translationKey: 'common.filter.status', key: 'status', values: [] },
      { name: 'Job Title', translationKey: 'common.filter.jobTitle', key: 'job_title', values: [] },
      { name: 'Role', translationKey: 'common.filter.role', key: 'skills', values: [] },
      { name: 'License', translationKey: 'common.filter.license', key: 'publishers', values: [] }
    ];
    this.filterValues = [
      {
        name: 'Status',
        translationKey: 'common.filter.status',
        values: [],
        showExpandedFilter: false,
        showAccordion: true,
        disabled: false,
        hide: false
      },
      {
        name: 'Job Title',
        translationKey: 'common.filter.jobTitle',
        values: [],
        showExpandedFilter: false,
        showAccordion: false,
        disabled: false,
        hide: false
      },
      { name: 'Role', translationKey: 'common.filter.role', values: [], showExpandedFilter: false, showAccordion: false, disabled: false, hide: false },
      { name: 'License', translationKey: 'common.filter.license', values: [], showExpandedFilter: false, showAccordion: false, disabled: false, hide: false }
    ];
    if (this.isC1 && this.$parent.$options.name === 'Enrollments') {
      this.filterValues = [
        {
          name: 'Status',
          translationKey: 'common.filter.status',
          values: [],
          showExpandedFilter: false,
          showAccordion: true,
          disabled: false,
          hide: false
        }
      ];
    }
    if (this.isC1 && this.$parent.$options.name === 'Usage') {
      this.filterValues = [
        {
          name: 'Status',
          translationKey: 'common.filter.status',
          values: [],
          showExpandedFilter: false,
          showAccordion: true,
          disabled: false,
          hide: false
        }
      ];
    }
    if (this.isC1 && this.$parent.$options.name === 'ConsumptionDrillDownTable') {
      this.filterValues = [
        {
          name: 'Job Title',
          translationKey: 'common.filter.jobTitle',
          values: [],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false,
          hide: false
        }
      ];
    }
    this.initialLoad = false;

    this.setFilterValues();
    this.setInitialData();
    this.emitInterface();
  },
  watch: {
    filterResults(before, after) {
      this.setFilterValues();
      if (Object.keys(before).length !== Object.keys(after).length) {
        this.setInitialData();
        this.applyFilters(0, false);
      }
    },
    'displaySelectedFilters.length'() {
      if (!this.initialLoad) this.$emit('filter-applied', this.selectedFilters, this.displaySelectedFilters);
    },
    timeFilter: async function () {
      if (this.timeFilter.length) await this.setInitialData();
      if (this.$parent.$options.name === 'Enrollments') this.applyFilters(0, false);
    }
  },
  methods: {
    sentenceCase,
    numberWithCommas,
    getLocalizedUsageStatus,
    getLocalizedEnrollmentStatus,
    async setInitialData() {
      if (this.timeFilter.length && this.timeFilter !== 'ALLTIME' && this.$parent.$options.name === 'Enrollments') {
        this.statusCategory = 'enrollment';
        this.onStatusCategoryChange(this.statusCategory);
      }
    },
    onCancel(index) {
      this.filterValues[index].showExpandedFilter = !this.filterValues[index].showExpandedFilter;
      this.resetDropDownValues(index);
    },
    onStatusCategoryChange(e) {
      this.statusCategory = e.target && e.target.value ? e.target.value : this.statusCategory;
      this.$emit('status-category-changed', this.statusCategory);
    },
    onShow(index, toggle = true) {
      this.applyFilters(index, toggle);
    },
    onBackdrop(index) {
      this.queryFilter = '';
      this.filterValues[index].showExpandedFilter = false;
      this.resetDropDownValues(index);
    },
    resetDropDownValues(index) {
      /****
       * remove values from filter if clicked outside, but keep the ones already applied from displaySelected array
       */
      if (this.displaySelectedFilters.length) {
        const doesKeyExistSelectedFilters = this.displaySelectedFilters.some((item) => {
          return item.key === this.selectedFilters[index].key;
        });
        if (doesKeyExistSelectedFilters) {
          const selectedArrayItems = [];
          this.displaySelectedFilters.filter((item) => {
            if (this.selectedFilters[index].key === item.key) {
              if (!this.selectedFilters[index].values.includes(item.name)) {
                this.selectedFilters[index].values.push(item.name);
                selectedArrayItems.push(item.name);
              } else {
                selectedArrayItems.push(item.name);
              }
            }
          });
          this.selectedFilters[index].values = selectedArrayItems;
        } else {
          this.selectedFilters[index].values = [];
        }
      }
    },
    async openDropdown(filterItem, i) {
      this.queryFilter = '';
      this.filteredOptions = this.filterValues[i].values.slice(0, 200);
      this.filterValues.map((filter, index) => {
        // if previously any filter was open, then reset its value
        if (filter.showExpandedFilter) {
          this.resetDropDownValues(index);
        }
        if (filterItem.name === filter.name) {
          filter.showExpandedFilter = true;
        } else {
          filter.showExpandedFilter = false;
        }
      });
    },
    filterOptions(event, i) {
      if (this.queryFilter.length == 0) this.filteredOptions = this.filterValues[i].values.slice(0, 200);
      else {
        const filteredOptions = this.filterValues[i].values.filter((ele) => {
          return ele.name.toLowerCase().includes(event.toLowerCase());
        });
        this.filteredOptions = filteredOptions.slice(0, 200);
      }
    },
    applyFilters(i, toggle = true) {
      if (!Object.keys(this.filterResults).length) return;
      if (i >= 0) {
        if (toggle) {
          this.filterValues[i].showExpandedFilter = !this.filterValues[i].showExpandedFilter;
        }

        /****
         * add value to displaySelectedFilter if new value has been checked from filter dropdown
         */
        let key = '';
        this.selectedFilters.map(async (item) => {
          if (item.name === this.filterValues[i].name) {
            key = item.key;
            item.values.forEach((filterItem) => {
              const obj = {};
              obj.key = key;
              obj.type = item.name;
              obj.name = filterItem;
              if (obj.type == 'Status') {
                if (this.comingFromUsage) {
                  obj.display = this.getLocalizedUsageStatus(filterItem);
                } else {
                  obj.display = this.getLocalizedEnrollmentStatus(filterItem);
                }
              }
              if (!this.displaySelectedFilters.some((selectedItem) => selectedItem.key === obj.key && selectedItem.type === obj.type && selectedItem.name === obj.name)) {
                this.displaySelectedFilters.push(obj);
              }
            });
            /***
             * find item which has been deselected from dropdown and remove it from displaySelectedFilter
             */
            const itemToBeRemoved = await this.displaySelectedFilters.filter((changeItem) => {
              if (changeItem.key === key) {
                if (!item.values.includes(changeItem.name)) {
                  return changeItem;
                }
              }
            });

            if (itemToBeRemoved.length) {
              this.displaySelectedFilters = this.displaySelectedFilters.filter(function (itemInDisplay) {
                return !itemToBeRemoved.find(function (removeItem) {
                  return itemInDisplay.name === removeItem.name && itemInDisplay.key === removeItem.key;
                });
              });
            }

            /****
             * clickstream update
             */

            const appliedFilters = {
              filters: item.values,
              count: item.values.length
            };
            analytics.setVarAndTrack({ c: item.name + ' filter', a: 'Apply filter', l: appliedFilters });
          }
        });
      }
      this.showFilters = this.selectedFilters.some((item) => {
        return item.values.length;
      });
    },
    setFilterValues() {
      this.filterValues.forEach((ele) => {
        if (ele.name == 'Job Title') {
          const index = this.filterValues.findIndex((x) => x.name === 'Job Title');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'jobTitleFilterValues') ? this.filterResults.jobTitleFilterValues : [];
          this.filterValues[index].hide = {}.hasOwnProperty.call(this.filterResults, 'jobTitleFilterValues') ? false : true;
        }
        if (ele.name == 'Role') {
          const index = this.filterValues.findIndex((x) => x.name === 'Role');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'roleFilterValues') ? this.filterResults.roleFilterValues : [];
          this.filterValues[index].hide = {}.hasOwnProperty.call(this.filterResults, 'roleFilterValues') ? false : true;
        }
        if (ele.name == 'License') {
          const index = this.filterValues.findIndex((x) => x.name === 'License');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'licenseFilterValues') ? this.filterResults.licenseFilterValues : [];
          this.filterValues[index].hide = {}.hasOwnProperty.call(this.filterResults, 'licenseFilterValues') ? false : true;
        }
        if (ele.name == 'Status') {
          const index = this.filterValues.findIndex((x) => x.name === 'Status');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'statusFilterValues') ? this.filterResults.statusFilterValues : [];
          this.filterValues[index].hide = {}.hasOwnProperty.call(this.filterResults, 'statusFilterValues') ? false : true;
        }
      });
    },
    removeFilter(item) {
      this.selectedFilters.forEach((filterItem) => {
        if (filterItem.name == item.type) {
          const index = filterItem.values.indexOf(item.name);
          filterItem.values.splice(index, 1);
        }
      });
      if (this.displaySelectedFilters.includes(item)) {
        const index = this.displaySelectedFilters.findIndex((selectedFilter) => selectedFilter.name === item.name && selectedFilter.type === item.type);
        /**
         * clickstream update
         */
        analytics.setVarAndTrack({ c: 'Clear single filter', a: 'click', l: item.name });
        this.displaySelectedFilters.splice(index, 1);
      }
      if (this.timeFilter != 'ALLTIME' && this.selectedFilters[0].values.length == 0) {
        this.$emit('status-category-changed', this.statusCategory);
      }
      this.applyFilters();
    },
    clearFilters(method) {
      if (this.showFilters) {
        this.selectedFilters.forEach((item) => {
          item.values = [];
          this.showFilters = false;
        });
      }
      /**
       * clickstream update
       */
      const tempArry = [];
      this.displaySelectedFilters.forEach((item) => {
        tempArry.push(item.name);
      });
      analytics.setVarAndTrack({ c: 'Clear all filters', a: 'click', l: { clearedValues: tempArry } });
      this.displaySelectedFilters = this.displaySelectedFilters.length ? [] : this.displaySelectedFilters;

      if (method === 'ON_APPLY') this.setInitialData();
      if (method === 'GET_PLAN_FROM_ENROLLMENT') {
        this.setInitialData();
        this.applyFilters(0, false);
      }
    },
    emitInterface() {
      this.$emit('interface', {
        clearFilters: (method) => this.clearFilters(method),
        removeFilter: (item) => this.removeFilter(item)
      });
    }
  }
};
</script>

<style lang="scss">
.insights-text-input {
  padding: 1rem 1rem;

  .v-label {
    font-weight: normal;
    font-family: Arial;
    font-size: 0.875rem;
    color: black;
  }

  .v-field--variant-solo {
    background-color: white !important;
    height: 2.3125rem;
    border: 0.125rem solid #c4c4c4;

    input[type='text'] {
      padding-left: 1rem;
    }
  }

  .v-field__field,
  .v-field__prepend-inner {
    height: 2.3125rem;
  }

  .v-field__input {
    min-height: unset;
    padding: 0rem;
    height: inherit;
    height: 2.3125rem;
    input {
      margin: 0px;
    }
  }
}
.dashboard-details-table-filters {
  .v-text-field {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.v-application .primary--text {
  color: #1f3370 !important;
  caret-color: #1f3370 !important;
}
.searchFilter {
  border-radius: 9999px !important;
  padding: 0;
}

#chips-container .v-chip .v-chip__content {
  color: white;
}

#chips-container .v-chip .v-chip__close {
  color: white;
}

.filter-hide {
  display: none;
}

.v-selection-control .v-label {
  height: auto;
  color: red;
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  text-rendering: optimizeLegibility;
  list-style: none;
  text-align: left;
  background-repeat: no-repeat;
  box-sizing: inherit;
  padding: 0;
  margin: 0;
  min-height: 8px;
  font-family: Arial, sans-serif;
  line-height: 20px;
  letter-spacing: normal;
  cursor: pointer;
  user-select: none;
  font-size: 0.875rem;
  color: #000;
  left: 0px;
  right: auto;
  position: relative;
}

.insights-filter-dropdown {
  .v-input--horizontal {
    margin: 0rem;
  }
  .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    &:first-of-type {
      padding-top: 1.875rem;
    }

    margin-bottom: 0rem;
  }

  .drilldown-status-filter {
    &:first-of-type {
      padding-top: 1.875rem;
    }
    .v-selection-control--density-default {
      --v-selection-control-size: unset;
    }

    .v-input--horizontal {
      display: inline-flex !important;
      flex-direction: column;
      justify-content: center;
      height: 2rem;

      label {
        margin-left: 0.5rem;
        color: #000;
      }

      .v-input__details {
        display: none;
      }
    }
    .odd-item {
      margin-bottom: 0.5rem;
    }
  }
  .radio-filter {
    display: flex;
    align-items: center;
    margin-left: 2rem;
    margin-top: 1rem;
    gap: 1rem;
  }
  input[type='radio' i] {
    width: 22px;
    height: 22px;
    accent-color: #1f3370;
    cursor: pointer;
  }
}

.insights-filter-dropdown {
  .mdi-checkbox-marked {
    color: #1f3370;
    font-size: 1.375rem !important;
  }
  .mdi-checkbox-marked:focus:after {
    opacity: 0 !important;
  }
}
</style>
<style lang="scss" scoped>
.drilldown-search-filter-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.9375rem;
  position: relative;

  .scrollable {
    padding-right: 1.875rem;
    max-height: 12.5rem;
    overflow-y: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 0.3125rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #d8d8d8;
      border: 0.0625rem solid #d8d8d8;
      border-radius: 0.25rem;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #4c5c8d;
      border-radius: 0.25rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #4c5c8d;
    }
  }
  .drilldown-search-filter {
    @media screen and (max-width: 800px) {
      margin-top: 0.5rem;
    }
    @media screen and (min-width: 800px) {
      &:nth-of-type(1) {
        .filter .filter-pill {
          width: 10rem;
        }
      }
      &:nth-of-type(2) {
        .filter .filter-pill {
          width: 12.5rem;
        }
      }
      &:nth-of-type(3) {
        .filter .filter-pill {
          width: 8.125rem;
        }
      }
      &:nth-of-type(4) {
        .filter .filter-pill {
          width: 10rem;
        }
      }
    }

    .filter .filter-pill {
      width: auto;
    }

    .filter-pill {
      display: flex;
      align-items: center;
      border: 0.125rem solid rgba(0, 0, 0, 0.2);
      border-radius: 1.25rem;
      font-family: Arial;
      font-weight: 700;
      font-size: 0.875rem;
      color: #000;
      padding: 0.3125rem 0.9375rem;
      width: 10rem;
      justify-content: space-between;
    }
    .filter-pill:hover {
      border: 0.125rem solid rgb(0, 0, 0);
    }

    // .mdi-chevron-down::before {
    //   color: $color-black;
    // }

    .insights-filter-dropdown {
      width: max-content;
      background-color: $color-skill-table !important;
      border-radius: 1.25rem;
      box-shadow: 0.125rem 0.1875rem 0.0625rem -0.125rem rgb(0 0 0 / 20%), 0 0.125rem 0.125rem 0 rgb(0 0 0 / 14%), 0 0.3125rem 0.3125rem 0 rgb(0 0 0 / 12%);
      position: absolute;
      z-index: 999 !important;
      li {
        max-height: 1.875rem;
        display: flex;
        align-items: center;
      }
    }

    .insights-filter-dropdown ul {
      padding: 1rem;
      list-style: none;
    }

    .list-with-no-filter {
      padding-top: 2rem !important;
    }

    .insights-filter-dropdown ul.scrollable {
      margin-top: 0.625rem;
      margin-right: 1.25rem;
    }
    .backdrop {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0rem;
      left: 0rem;
      background-color: none;
      z-index: 1;
      margin-top: 5rem;
    }
    .filter-option-footer {
      display: flex;
      gap: 3rem;
      padding: 0.825rem 2rem 0.875rem 2rem;
      align-items: center;
      border-top: 0.0625rem solid #e0e1e1;
    }
    .filter-option-footer-cancel {
      font-family: 'Futura PT Demi';
      font-size: 1rem;
      color: #1f3370;
      cursor: pointer;
    }
    .filter-option-footer-btn-apply {
      cursor: pointer;
      text-transform: none;
      width: 5.75rem;
      box-shadow: none;
    }
  }
}
</style>
