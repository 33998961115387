<template>
  <!--   <div class="custom-toast custom-toast-loading download-progress" v-if="showDownloadProgress">
    <div class="toast-progress-top">
      <v-progress-linear color="teal" indeterminate></v-progress-linear>
    </div>
    <div>
      <div class="download-status">Download is in progress {{ downloadPercentage }}%</div>
    </div>
    <div>
      <v-btn class="cancel-btn-text download-cancel-btn" rounded color="primary" small @click="cancelDownload()"> Cancel Download</v-btn>
    </div>
    <div class="toast-footer"></div>
  </div> -->
  <div @drop="handleDrop($event)" @dragover="handleDragover" @dragenter="handleDragover">
    <label for="announcement-description" class="label-view">{{ translate('settings.createAnnouncement.uploadImageStatic') }}</label>
    <div class="file-upload-container t-mt-2" :style="uploadError && 'border: 1px dashed #e0301e'">
      <div class="upload-btn-content">
        <svg-icon icon-class="upload-outline" class="g-icon--large upload-icon"></svg-icon>
        <div class="supported-format t-dark-grey">
          <span class="t-font-bold">{{ translate('settings.createAnnouncement.uploadText') }}</span
          >{{ translate('settings.createAnnouncement.uploadFormat') }}
        </div>
      </div>
      <label class="file-upload-wrapper t-opacity-50 hover:t-opacity-100" :class="{ 't-opacity-100': fileUploadStatus == 'U' || fileUploadStatus == 'S' }" tabindex="0">
        <div class="file-size-text t-dark-grey" v-text="translate('settings.createAnnouncement.fileSize')"></div>
        <span class="choose-file t-primary-color">
          <input
            id="announcementMediaFile"
            data-cy="asset-file-zip"
            ref="announcementMediaFile"
            class="upload-input"
            type="file"
            accept="image/jpeg,image/png,image/gif,video/mp4,video/mov,.mkv,video/mkv,video/quicktime,video/x-msvideo,'video/x-matroska'"
            multiple
            @change="handleUpload"
          />
          {{ translate('settings.createAnnouncement.chooseFile') }}</span
        >
      </label>

      <div class="t-w-full" v-if="announcementUploadReference.fileName">
        <div class="file-meta-data">
          <div class="t-flex t-items-center">
            <svg-icon icon-class="zip-icon" class="icon-medium t-mr-2"></svg-icon>
            <span>{{ announcementUploadReference.fileName }}</span>
          </div>
          <div class="section-meta-info">
            <div v-if="announcementUploadReference.signedRelativeUri || announcementUploadReference.uri">
              <svg-icon icon-class="icon-download" @click="downloadFile()" class="icon-medium t-cursor-pointer" />
            </div>
            <div>
              <svg-icon icon-class="trash-bold" @click="showDeletePopup(announcementUploadReference)" class="icon-medium t-cursor-pointer" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-if="uploadError" class="t-mt-2 upload-error-message">{{ translate('settings.createAnnouncement.uploadErrorMsg') }}</p>

    <div class="custom-toast custom-toast-info" v-if="fileUploadStatus === 'U'">
      <div class="t-mt-4">
        <v-progress-linear color="teal" indeterminate></v-progress-linear>
      </div>
      <div class="status-message">
        <span class="status-message-normal">File upload in progress</span>
      </div>
      <div class="cancel-btn">
        <v-btn class="cancel-btn-text" rounded color="primary" small @click="cancelUploadAnnouncement()"> Cancel</v-btn>
      </div>
      <div class="toast-footer"></div>
    </div>

    <div class="custom-toast custom-toast-success" v-else-if="fileUploadStatus === 'C'">
      <div class="toast-close-icon">
        <svg-icon icon-class="close" class="g-icon--small" @click="fileUploadStatus = ''"></svg-icon>
      </div>
      <div class="toast-success-icon">
        <svg-icon icon-class="icon-success"></svg-icon>
      </div>
      <div class="status-message"><span class="status-message-normal">Success! Your file has been uploaded.</span></div>
      <div class="toast-footer"></div>
    </div>

    <div class="custom-toast custom-toast-failed" v-else-if="fileUploadStatus === 'F'">
      <div class="toast-close-icon">
        <svg-icon icon-class="close" class="g-icon--small" @click="fileUploadStatus = ''"></svg-icon>
      </div>
      <div class="toast-failed-icon">
        <svg-icon icon-class="icon-alert-black"></svg-icon>
      </div>
      <div class="status-message"><span class="status-message-normal">Your file did not upload successfully</span></div>
      <div class="cancel-btn">
        <v-btn class="cancel-btn-text" rounded color="primary" small @click="tryAgain()">Try again</v-btn>
      </div>
      <div class="toast-footer"></div>
    </div>

    <div class="custom-toast custom-toast-success" v-if="fileDeleteStatus === 'S'">
      <div class="toast-close-icon">
        <svg-icon icon-class="close" class="g-icon--small" @click="fileDeleteStatus = ''"></svg-icon>
      </div>
      <div class="toast-success-icon">
        <svg-icon icon-class="icon-success"></svg-icon>
      </div>
      <div class="status-message">
        <span class="status-message-normal">{{ translate('settings.createAnnouncement.deleteUploadedFile') }}</span>
      </div>
      <div class="toast-footer"></div>
    </div>
    <AlertsModal v-if="showAlertsModal && modalMessageObj.header" :modalProperties="modalMessageObj" @closeAlertsModal="closeShowModal"></AlertsModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import { translate } from '@/plugins/i18n.js';

import AlertsModal from '../../manage-submissions/components/AlertsModal.vue';

export default {
  name: 'UploadAnnouncementFile',
  props: {
    uploadDetails: {
      require: true,
      default: () => {
        return {};
      }
    }
  },
  emits: ['uploadMedia', 'uploadMediaError'],
  components: { AlertsModal },
  computed: {
    ...mapGetters([])
  },
  data() {
    return {
      editMode: false,
      fileUploadStatus: '',
      fileDeleteStatus: '',
      axiosController: null,
      announcementUploadReference: {},
      showAlertsModal: false,
      modalMessageObj: {},
      deleteObj: {},
      showDownloadProgress: false,
      downloadPercentage: 0,
      pdfFileExceedLimitMsg: {
        header: 'Exceeded file limit',
        message: 'You can only upload one file.',
        btnText: 'Ok',
        buttons: [],
        type: 'error'
      },
      uploadError: false,
      deleteFilesObj: {
        closeIcon: true,
        header: 'Delete Files ?',
        message: 'Are you sure want to delete the files ?',
        buttons: [
          {
            text: 'No',
            type: 'btn',
            class: 't-bg-yellow-400 btn-text-font',
            click: () => {
              this.showAlertsModal = false;
              this.modalMessageObj = {};
            }
          },
          {
            text: 'Yes',
            type: 'btn',
            class: 't-bg-primary-color t-text-white btn-text-font btn-margin',
            click: () => {
              this.deleteFiles(this.deleteObj.id);
            }
          }
        ],
        type: 'warn'
      },
      file: {
        name: '',
        file: null,
        fileExt: '',
        acceptedFormats: '.png,.jpeg,.gif,.mp4,.mov,.mkv,.quicktime,.x-msvideo,.x-matroska'
      }
    };
  },
  watch: {
    uploadDetails(newItems) {
      if (newItems) this.loadEditAnnouncementData();
      else return;
    }
  },
  async mounted() {
    if (this.uploadDetails) await this.loadEditAnnouncementData();
  },
  deactivated() {},
  methods: {
    translate,
    async loadEditAnnouncementData() {
      this.editMode = true;
      this.announcementUploadReference = this.uploadDetails;
    },

    async downloadFile() {
      const relativeUri = ({}.hasOwnProperty.call(this.announcementUploadReference, 'signedRelativeUri') && this.announcementUploadReference.signedRelativeUri) || this.announcementUploadReference?.uri;
      try {
        if (!this.showDownloadProgress && relativeUri) {
          this.showDownloadProgress = true;
          this.downloadPercentage = 0;
          let filename = this.announcementUploadReference.fileName;
          let a = document.createElement('a');
          a.href = relativeUri;
          a.setAttribute('download', filename);
          a.click();
          this.showDownloadProgress = false;
        } else {
          this.showDownloadProgress = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    handleUpload(event) {
      const files = event.target.files;
      const rawFile = files[0];
      if (!files.length) return;
      if (!this.isRightSuffix(rawFile)) {
        const errorString = 'File type not supported. Please upload a file with extension: ' + this.file.acceptedFormats;
        this.EventBus.emit('upload-error', { err: errorString });
        return false;
      }
      this.upload(rawFile);
    },
    handleDragover(event) {
      event.stopPropagation();
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
    },
    handleDrop(event) {
      event.stopPropagation();
      event.preventDefault();
      const files = event.dataTransfer.files;
      const rawFile = files[0];
      if (!files.length) return;
      if (!this.isRightSuffix(rawFile)) {
        const errorString = 'File type not supported. Please upload a file with extension: ' + this.file.acceptedFormats;
        this.EventBus.emit('upload-error', { err: errorString });
        return false;
      }
      this.upload(rawFile);
      event.stopPropagation();
      event.preventDefault();
    },
    isRightSuffix(file) {
      const fileExt = '.' + file.name.split('.').pop().toLowerCase();
      this.file.fileExt = fileExt;
      const allowedExtArray = this.file.acceptedFormats.split(',');
      return allowedExtArray.includes(fileExt);
    },
    showDeletePopup(announcementUploadRefrence) {
      this.deleteObj = {
        id: announcementUploadRefrence.mediaId
      };
      this.showAlertsModal = true;
      this.modalMessageObj = this.deleteFilesObj;
    },
    deleteFiles(id) {
      this.deleteObj = {};
      this.showAlertsModal = false;
      this.modalMessageObj = {};
      this.announcementUploadReference = {};
      this.fileDeleteStatus = 'S';
      this.hideToastMessage();
      this.$emit('uploadMedia', 'deleteAttachment', id);
    },
    async uploadAnnouncement(file) {
      try {
        this.fileUploadStatus = 'U';
        const formData = new FormData();
        formData.set('type', 'ANNOUNCEMENT');
        const token = await localforage.getItem('my_access_token');
        formData.set('token', token);
        formData.append('file', file);
        const payload = {
          body: formData
        };
        this.$planAdmin
          .uploadAnnouncementFile(payload)
          .then((res) => {
            if (res.data.code == 200) {
              this.announcementUploadReference = res.data?.data || {};
              this.fileUploadStatus = 'U';
              this.updateStatus(this.announcementUploadReference?.mediaId);
            } else {
              this.fileUploadStatus = 'F';
              this.hideToastMessage();
              this.announcementUploadReference = {};
            }
          })
          .catch(() => {
            this.fileUploadStatus = 'F';
            this.hideToastMessage();
            this.announcementUploadReference = {};
          });
      } catch (error) {
        console.log(error);
      }
    },
    async updateStatus(id) {
      const uploadStatus = await this.$planAdmin.getUploadAnnouncementDetails(id);
      const status = uploadStatus.status.toLowerCase();
      if (status.toLowerCase().includes('inprogress')) {
        setTimeout(() => {
          this.updateStatus(uploadStatus?.mediaId);
        }, 10000);
      } else if (status.toLowerCase().includes('completed')) {
        this.$emit('uploadMedia', 'uploadAttachment', this.announcementUploadReference?.mediaId);
        this.fileUploadStatus = 'C';
        this.hideToastMessage();
      }
    },
    async upload(rawFile) {
      let fileSize = Math.floor(rawFile.size / (1024 * 1024), 2);
      if (Object.keys(this.announcementUploadReference).length > 0) {
        this.showAlertsModal = true;
        this.modalMessageObj = this.pdfFileExceedLimitMsg;
      } else if (fileSize > 350) {
        this.showAlertsModal = false;
        this.uploadError = true;
        this.$emit('uploadMediaError', this.uploadError);
      } else {
        this.uploadError = false;
        this.$emit('uploadMediaError', this.uploadError);
        this.announcementUploadReference = rawFile;
        this.uploadAnnouncement(rawFile);
      }
    },
    hideToastMessage() {
      setTimeout(() => {
        this.fileUploadStatus = '';
        this.fileDeleteStatus = '';
      }, 5000);
    },
    tryAgain() {
      this.fileUploadStatus = '';
    },
    cancelUploadAnnouncement() {
      this.fileUploadStatus = 'F';
      if (this.announcementUploadReference?.mediaId) {
        this.$emit('uploadMedia', 'deleteAttachment', this.announcementUploadReference?.mediaId);
      }
      this.announcementUploadReference = {};
    },

    closeShowModal() {
      this.showAlertsModal = false;
    }
  },

  metaInfo() {
    return {
      title: translate('pageTitles.settings')
    };
  }
};
</script>
<style lang="scss" scoped>
.upload-error-message {
  color: var(--PE-Status-Colors-Warning-Status, #e0301e);
  @include body-font-0-75;
}
.icon-medium {
  height: 1.5rem;
  width: 1.5rem;
}
.file-upload-container {
  display: flex;
  //height: 183px;
  padding: 32px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border: 1px dashed var(--Colors-Grayscale-Grey-Lighter1, #adadad);
  background: var(--Colors-Primary-White, #fff);
  .file-meta-data {
    border-radius: var(--Corner-Radius-Radius-XS, 4px);
    background: var(--Fill-Gainsboro, #f5f5f5);
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    .section-meta-info {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-end;
      gap: 8px;
    }
  }
  .upload-btn-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .file-details {
      width: 100%;
    }
    .upload-icon {
      color: #2d2d2d;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .supported-format {
      @include body-font-0-75;
    }
  }
  .file-upload-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .file-size-text {
      @include body-font-0-875;
    }
    .choose-file {
      @include body-font-bold-1;
      .upload-input {
        display: none;
      }
    }
  }
}
.label-view {
  @include header-font-0-875;
  color: var(--Text-Body-DarkGrey, #2d2d2d);
}
#announcement-description {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e1e1;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  padding: 1rem;
  resize: none;
  color: var(--Text-Body-DarkGrey, #2d2d2d);
  @include body-font-1;
}
#announcement-description {
  height: 80px;
}

.custom-toast-success {
  background: #d9f8f3 0% 0% no-repeat padding-box;
  width: 26.25rem;
  height: 6.5rem;

  .toast-footer {
    background: #00d1b0 0% 0% no-repeat padding-box;
  }

  .toast-success-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 1.9rem;
    float: left;
    margin-left: 2.5rem;

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .status-message {
    margin-top: 2rem !important;
    margin-left: 5.43rem !important;
    text-align: left;

    .status-message-bold {
      font: normal normal bold 16px/22px Arial !important;
      color: #000000;
    }

    .status-message-normal {
      font: normal normal normal 16px/22px Arial !important;
      color: #000000;
    }
  }
}

.custom-toast-info {
  background: #fff4df 0% 0% no-repeat padding-box;

  .toast-footer {
    background: #ffb92e 0% 0% no-repeat padding-box;
  }
}

.custom-toast {
  width: 26.25rem;
  height: 8.5rem;
  box-shadow: 0px 5px 10px #0000003d;
  opacity: 1;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 100;

  .toast-footer {
    height: 0.5rem;
    /* UI Properties */
    box-shadow: 0px 0.3125rem 0.625rem #0000003d;
    width: 100%;
    position: fixed;
    bottom: 0px;
    width: 26.25rem;
  }

  .cancel-btn {
    border: 1p solid red;
    width: 3.625rem;
    height: 1.68rem;
    margin-left: 5.43rem;
    margin-top: 1.05rem;

    .cancel-btn-text {
      font: normal normal normal 0.75rem Futura PT Demi !important;
      text-transform: none !important;
    }
  }

  .status-message {
    margin-top: 1.8rem !important;
    margin-left: 5.43rem !important;
    text-align: left;

    .status-message-bold {
      font: normal normal bold 16px/22px Arial !important;
      color: #000000;
    }

    .status-message-normal {
      font: normal normal normal 16px/22px Arial !important;
      color: #000000;
    }
  }

  .toast-close-icon {
    height: 1.5rem;
    width: 1.5rem;
    float: right;
    margin: 1rem 1rem 0 0;
    cursor: pointer;

    svg {
      width: 1rem !important;
      height: 1rem !important;
    }
  }
}
.custom-toast-failed {
  background: #fae5dc 0% 0% no-repeat padding-box;
  box-shadow: #0000003d;

  .toast-footer {
    background: #d04910 0% 0% no-repeat padding-box;
  }

  .toast-failed-icon {
    width: 2rem;
    height: 2rem;
    margin-top: 1.9rem;
    float: left;
    margin-left: 2.5rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}
</style>
