<template>
  <v-dialog class="save-dialog" :modelValue="saveDialog" @update:modelValue="saveDialog = $event" persistent width="420">
    <div class="t-rounded-20-xl t-bg-white t-relative">
      <v-btn class="cancel-btn t-absolute t-top-3 t-right-3" icon @click="cancel()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div class="t-text-2xl t-tracking-normal t-font-futura-pt-demi t-text-black t-pt-8 t-pb-4 t-px-6">{{ headerText }}</div>
      <div class="t-px-6 t-pb-4" v-if="status === 'approved'">{{ approveSubTitle }}</div>
      <div class="t-text-sm t-px-6 t-pb-3 t-font-bold t-text-black">{{ commentLabel }}<span v-if="status != 'approved'"> *</span></div>
      <v-textarea v-model="comment" maxlength="500" ref="comment" counter :rules="status === 'approved' ? [rules[1]] : rules" solo class="comment t-px-5" :placeholder="placeholderText"> </v-textarea>
      <v-card-actions class="t-pb-6">
        <button
          :disabled="(status != 'approved' && !comment.length) || (status != 'approved' && !comment.trim())"
          class="t-font-futura-pt-book t-ml-2 t-mr-2 t-color-white t-py-3 min-w-min t-px-6 t-rounded-full t-font-bold t-text-base t-bg-yellow-400 t-border-yellow-400 hover:t-bg-primary-color hover:t-text-white save-button"
          text
          @click="confirm()"
        >
          {{ buttonText }}
        </button>
        <a class="t-font-futura-pt-book t-ml-2 t-color-white t-py-3 min-w-min t-px-6 t-h-12 t-font-bold t-text-base" text @click="cancel()">{{ $t('button.cancel') }}</a>
      </v-card-actions>
    </div>
  </v-dialog>
</template>
<script>
export default {
  name: 'SaveDialog',
  props: {
    saveDialog: {
      type: Boolean,
      required: true,
      default() {
        return false;
      }
    },
    status: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    getComment: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    adminEdited: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      comment: '',
      headerText: '',
      placeholderText: '',
      buttonText: '',
      commentLabel: 'Add a comment for the submitter',
      approveSubTitle: 'Clicking "Approve" will route the asset to our ops team to make it available in ProEdge Learn within 3-5 business days for colleagues in your company to use and share.',
      rules: [(value) => !!value || 'Comment is required.', (value) => value.length < 500 || 'Max 500 characters allowed', (value) => !!value.trim() || 'Comment cannot be blank']
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.comment = this.getComment;
      this.headerText = this.status === 'returned' ? 'Return asset to submitter' : this.status === 'approved' ? 'Approve the asset' : 'Reject the asset';
      this.headerText = this.adminEdited ? this.headerText + ' with edits' : this.headerText;
      this.placeholderText =
        this.status === 'returned'
          ? 'Explain why you are sending the asset back for changes. Be as specific as possible.'
          : this.status === 'approved'
          ? `Any notes that you'd like to share with the asset creator (optional)`
          : 'Explain why you are rejecting the asset. Be as specific as possible.';
      this.buttonText = this.status === 'returned' ? 'Return' : this.status === 'approved' ? 'Approve' : 'Reject submission';
    },
    confirm() {
      this.$emit('confirm', this.comment);
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>
<style lang="scss">
.save-dialog {
  &.v-dialog {
    box-shadow: none;
  }

  .save-button {
    &:disabled {
      opacity: 0.5 !important;
    }
  }
}
</style>
