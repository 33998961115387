<template>
  <v-navigation-drawer class="navigation-drawer" v-model="drawer" absolute temporary>
    <v-row class="t-mt-7 t-ml-4">
      <button @click="toggleSideMenu" class="drawer-close" aria-label="close drawer menu">
        <svg-icon icon-class="close-icon" class-name="close-button-icon" />
      </button>
      <a href="javascript:void(0)" @click="redirectToHome()" class="logo-link">
        <svg-icon icon-class="plan-logo" class-name="logo--size" />
      </a>
    </v-row>
    <div class="navigation-container">
      <ul class="main-navigation" ref="menuList">
        <li v-for="menuItem in navBarMenu" :key="menuItem.name" class="navigation-items" :class="[$route.path.includes(menuItem.link) ? 'selected-link' : '', isInsightsFocused ? 'selected-sub-link' : '']">
          <div>
            <template v-if="menuItem.submenu">
              <router-link role="button" :to="''">{{ menuItem.display }}</router-link>
              <v-icon class="chevron" color="white">mdi-chevron-down</v-icon>
            </template>
            <template v-else>
              <router-link @click.native="toggleSideMenu" :to="menuItem.link">{{ menuItem.display }}</router-link>
            </template>
            <div id="sidenav-submenu" v-if="menuItem.submenu" class="navigation-submenu">
              <ul>
                <li v-for="submenuItem in menuItem.submenu" :key="submenuItem.name" class="sub-navigation-item" :class="$route.path.includes(submenuItem.link) && submenuItem.link !== '' ? 'selected-sub-link' : ''">
                  <div v-if="submenuItem.link == ''">
                    <router-link class="normal-weight t-pointer-events-none" to="#">{{ submenuItem.display }}</router-link>
                  </div>
                  <div v-else>
                    <router-link class="normal-weight" @click.native="toggleSideMenu" :to="submenuItem.link">{{ submenuItem.display }}</router-link>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>

      <div class="help t-ml-12 t-mb-6">
        <a href="javascript:void(0)" class="help-text t-no-underline t-text-base" :class="[$route.path.includes('help-center') ? 'selected-link' : '']" @click="onGetHelp">{{ $t('nav.help') }}</a>
      </div>
      <div class="logout t-ml-12">
        <a href="javascript:void(0)" class="logout-text t-no-underline t-text-base" @click="onLogout">{{ $t('nav.logout') }}</a>
      </div>
    </div>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: 'NavBarSideMenu',
  props: {
    navBarMenu: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    isSideMenuActive: {
      type: Boolean,
      required: true,
      default: () => {
        return false;
      }
    }
  },
  computed: {
    drawer: {
      get() {
        return this.$props.isSideMenuActive;
      },
      set(val) {
        this.$emit('toggle-side-menu-drawer', val);
      }
    }
  },
  data() {
    return {
      isInsightsFocused: false
    };
  },
  watch: {
    $route() {
      this.isInsights();
    },
    navBarMenu() {
      this.isInsights();
    }
  },
  methods: {
    isInsights() {
      this.isInsightsFocused = this.navBarMenu.find((data) => data.name === 'Insights')?.submenu.find((route) => route.link === this.$route.path);
    },
    onLogout() {
      this.toggleSideMenu();
      this.$emit('log-out');
    },
    onGetHelp() {
      this.toggleSideMenu();
      this.$router.push('/help-center');
    },
    toggleSideMenu() {
      this.$emit('toggle-side-menu');
    },
    redirectToHome() {
      this.toggleSideMenu();
      this.$router.push('/home');
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation-drawer {
  width: 21.25rem !important;
  height: 100vh !important;
  background-color: $color-primary;
  transition: all 0.5s ease !important;

  .drawer-close {
    color: unset;
    text-decoration: unset;
    background-color: unset;
    margin: 0.325rem 1.5rem 0 1.5rem;
    .close-button-icon {
      height: 1.5rem !important;
      width: 1.5rem !important;
    }
  }

  .help,
  .logout {
    &:hover {
      &::before {
        content: '';
        position: absolute;
        left: 0;
        z-index: 100;
        width: 0.375rem;
        height: 1.5rem;
        background: #00d1b0;
      }
    }

    .selected-link {
      font-weight: bold !important;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        z-index: 100;
        width: 0.375rem;
        height: 1.5rem;
        background: #00d1b0;
      }
    }
  }
  .navigation-container {
    .main-navigation {
      padding: 4rem 0 !important;

      .navigation-items {
        display: block !important;
        line-height: 1.19rem !important;
        position: relative !important;
        list-style: none !important;
        padding: 0 1.5rem 0 2.5rem !important;
        font-size: 1.5rem !important;
        margin: 1.25rem 0 0 0 !important;

        &:has(.selected-sub-link) {
          &.navigation-items {
            a {
              font-weight: bold !important;
            }
            &:first-of-type {
              border-left: none;
              &::before {
                content: '';
                position: absolute;
                left: 0;
                z-index: 100;
                width: 0.375rem;
                height: 1.5rem;
                background: #00d1b0;
              }
            }
          }
        }
        &:hover,
        &:focus-within {
          .chevron {
            transform: rotate(180deg);
          }
        }

        &.selected-sub-link {
          .v-icon.v-icon {
            color: #00d1b0 !important;
          }
        }

        &:hover {
          &::before {
            content: '';
            position: absolute;
            left: 0;
            z-index: 100;
            width: 0.375rem;
            height: 1.5rem;
            background: #00d1b0;
          }
          &:first-of-type {
            border-left: none;
          }
          a {
            color: $color-white !important;
          }
        }

        &.selected-link {
          &::before {
            content: '';
            position: absolute;
            left: 0;
            z-index: 100;
            width: 0.375rem;
            height: 1.5rem;
            background: #00d1b0;
          }
          &:first-of-type {
            border-left: none;
          }
          a {
            font-weight: bold !important;
            color: $color-white !important;
          }
        }

        a {
          font-size: 1rem;
          line-height: 1.5rem;
          text-decoration: none;
        }

        &:hover,
        &:focus-within {
          .navigation-submenu {
            display: inline-block;
          }
        }

        .navigation-submenu {
          display: none;
          padding: 0.5rem 3rem 0 0rem !important;

          .sub-navigation-item {
            padding: 0 !important;
            display: inline-flex !important;
            width: 100% !important;
            flex-direction: column;
            margin: 0.5rem 0rem !important;
            .normal-weight {
              font-weight: normal !important;
              padding-left: 2rem;
              color: #fff;
            }

            &:hover {
              border-left: 0.375rem solid #1dd1b0;
              .normal-weight {
                color: #fff !important;
                padding-left: calc(2rem - 0.375rem) !important;
              }
            }
          }

          .selected-sub-link {
            border-left: 0.375rem solid #1dd1b0;
            box-sizing: border-box;
            .normal-weight {
              padding-left: calc(2rem - 0.375rem) !important;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
#nav .v-overlay.v-overlay--absolute.v-overlay--active.theme--dark {
  height: 100vh;
}
</style>
