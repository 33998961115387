<template>
  <div class="layout-container">
    <component :is="displayedLayout" />
  </div>
</template>

<script>
import LayoutContent from '@/layouts/LayoutContent';
import { mapGetters } from 'vuex';
export default {
  ...mapGetters(['enabledFeatureFlags']),
  name: 'Layout',
  components: { LayoutContent },
  computed: {
    displayedLayout() {
      return 'LayoutContent';
    }
  }
};
</script>

<style lang="scss"></style>
