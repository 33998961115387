<template>
  <v-dialog content-class="override-dialog-tracks t-rounded-20-xl" class="save-dialog" :modelValue="notificationDialog" @update:modelValue="notificationDialog = $event" persistent width="584">
    <!-- <v-dialog content-class="notification-dialog-tracks t-rounded-20-xl" class="save-dialog" v-model="notificationDialog" persistent width="367" height="261"> -->
    <v-card class="t-rounded-20-xl t-p-8 notification-dialog-card">
      <div class="t-mb-6">
        <v-btn icon class="notification-close-btn t-float-right" @click="cancelNavigation" variant="text">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="header-content">
          <svg-icon icon-class="icon-warning" />
          <p class="header">{{ $t(`confirmation.notification.${header}`) }}</p>
        </div>
      </div>
      <div v-if="subHeader1" class="sub-header1 t-mb-8">
        {{ $t(`confirmation.notification.${subHeader1}`) }} <b>{{ $t(`confirmation.notification.${subHeader2}`) }} </b> {{ $t(`confirmation.notification.${subHeader3}`) }}
        <ul class="t-mt-6 t-ml-6">
          <li>{{ $t(`confirmation.notification.${subHeader4}`) }}</li>
          <li>{{ $t(`confirmation.notification.${subHeader5}`) }}</li>
        </ul>
      </div>
      <v-card-actions class="t-place-content-end">
        <button class="button-text button-text-cancel t-mx-1 t-color-white t-py-2 min-w-min t-px-6 t-font-bold t-text-base" text @click="cancelNavigation()">{{ $t('button.cancel') }}</button>
        <button class="button-text t-mx-1 t-color-white t-py-2 min-w-min t-px-11 t-rounded-full t-text-base t-bg-credential t-border-credential" text @click="saveAndNavigate()">
          {{ $t('button.proceed') }}
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  components: {},
  name: 'OverrideDueDateDialog',
  emits: ['proceed-due-date', 'cancel-due-date'],
  props: {
    notificationDialog: {
      type: Boolean,
      required: true,
      default() {
        return false;
      }
    },
    header: {
      type: String,
      required: false,
      default() {
        return 'header';
      }
    },
    subHeader1: {
      type: String,
      required: false,
      default() {
        return 'subHeader1';
      }
    },
    subHeader2: {
      type: String,
      required: false,
      default() {
        return 'subHeader2';
      }
    },
    subHeader3: {
      type: String,
      required: false,
      default() {
        return 'subHeader3';
      }
    },
    subHeader4: {
      type: String,
      required: false,
      default() {
        return 'subHeader4';
      }
    },
    subHeader5: {
      type: String,
      required: false,
      default() {
        return 'subHeader5';
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    userSelection(e) {
      const value = e.target.value;
      this.notifyLearners = value === 1;
      this.userSelectionValue = value;
      this.disableConfirmButton = false;
    },
    saveAndNavigate() {
      this.$emit('proceed-due-date');
    },
    cancelNavigation() {
      this.$emit('cancel-due-date');
    }
  }
};
</script>
<style lang="scss" scoped>
.v-dialog > .v-overlay__content > .v-card {
  padding: 2rem;
}
.override-dialog-tracks {
  button:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .v-input {
    &:not(.v-input--is-disabled) {
      .mdi-checkbox-blank-outline {
        border-radius: 0.25rem;
        color: #1f3370;
      }
    }
  }
  .notification-close-btn {
    width: 24px;
    height: 24px;
    margin-right: 0rem;
    margin-top: 0rem;
    .v-icon {
      font-size: 1.875rem;
    }
  }
  .notify-user-selection {
    .v-label {
      color: rgba(0, 0, 0, 0.87) !important;
      font-size: 0.875rem;
    }
    .v-input--selection-controls .v-radio > .v-label {
      margin-top: 1rem;
    }
    .v-input--selection-controls__input .v-icon {
      font-size: 18px;
      color: #dedfdf;
    }
  }
  .header-content {
    display: flex;
    align-items: center;
    gap: 8px;
    .header {
      font-family: 'Source Serif';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      color: #2d2d2d;
    }
    .svg-icon {
      height: 24px;
      width: 24px;
    }
  }

  .sub-header1 {
    font-family: 'Source Sans';
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    color: #2d2d2d;
  }

  .button-text {
    font-family: 'Source Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    color: #000000;
    height: 52px;
  }

  .button-text-cancel {
    color: #1f3370;
  }

  .v-card-actions {
    padding: 0;
  }
}
</style>
