<!-- https://www.geeksforgeeks.org/how-to-create-and-download-csv-file-in-javascript/ -->
<template>
  <div @click="get">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'JSON2CSV',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    labels: {
      type: Object,
      required: true,
      default: () => {}
    },
    title: {
      type: String,
      required: false,
      default: () => ''
    }
  },
  methods: {
    async get() {
      const csvdata = this.csvMaker(this.labels, this.data);
      this.download(csvdata);
    },
    csvMaker(labels, data) {
      const keys = Object.keys(labels);
      const headers = keys.map((key) => labels[key].title);

      const csvRows = [headers.join(',')];

      const rowsData = data.map((obj) =>
        keys
          .map((key) => {
            const value = obj[key];
            return typeof value === 'string' && value.includes(',') ? `"${value}"` : value;
          })
          .join(',')
      );

      return csvRows.concat(rowsData).join('\n');
    },
    download(data) {
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', this.title);
      a.click();
    }
  }
};
</script>
