<template>
  <div v-if="publicEmbedUrl" class="looker-dashboard-container"><looker-embed :embedUrl="publicEmbedUrl"></looker-embed></div>
</template>
<script>
import LookerEmbed from '../LookerEmbed.vue';
import { ref } from 'vue';
export default {
  components: { LookerEmbed },
  setup() {
    const publicEmbedUrl = ref('');
    return {
      publicEmbedUrl
    };
  },

  async mounted() {
    const payload = {
      type: 'test'
    };
    const response = await this.$planAdmin.getEmbedUrl(payload);
    this.publicEmbedUrl = response.signedUrl ? decodeURI(response.signedUrl) : '';
  }
};
</script>
<style scoped>
.looker-dashboard-container {
  width: 100%;
  height: 100%;
}
</style>
