import localforage from 'localforage';
import store from '@/store';

const state = {
  storeSelectedCompanyName: '',
  storeCompanyList: '',
  storeFileList: '',
  storeSelectedFileName: '',
  storeFunctionList: '',
  storeSelectedFunctions: '',
  storeRoleList: '',
  storeSelectedRoles: '',
  storeSelectedOption: '',
  storeOnetCompanyGuid: ''
};

const mutations = {
  SET_SELECTED_COMPANY_NAME: (state, selectedCompanyName) => {
    state.storeSelectedCompanyName = selectedCompanyName;
  },
  SET_COMPANY_LIST: (state, companyList) => {
    state.storeCompanyList = companyList;
  },
  SET_FILE_LIST: (state, fileList) => {
    state.storeFileList = fileList;
  },
  SET_SELECTED_FILE_NAME: (state, selectedFileName) => {
    state.storeSelectedFileName = selectedFileName;
  },
  SET_FUNCTION_LIST: (state, functionList) => {
    state.storeFunctionList = functionList;
  },
  SET_SELECTED_FUNCTIONS: (state, selectedFunctions) => {
    state.storeSelectedFunctions = selectedFunctions;
  },
  SET_ROLE_LIST: (state, roleList) => {
    state.storeRoleList = roleList;
  },
  SET_SELECTED_ROLES: (state, selectedRoles) => {
    state.storeSelectedRoles = selectedRoles;
  },
  SET_SELECTED_OPTION: (state, selectedOption) => {
    state.storeSelectedOption = selectedOption;
  },
  SET_ONET_COMPANY_GUID: (state, onetCompanyGuid) => {
    state.storeOnetCompanyGuid = onetCompanyGuid;
  },
  RESET_STATE: (state) => {
    state.storeSelectedCompanyName = '';
    state.storeCompanyList = '';
    state.storeFileList = '';
    state.storeSelectedFileName = '';
    state.storeFunctionList = '';
    state.storeSelectedFunctions = '';
    state.storeRoleList = '';
    state.storeSelectedRoles = '';
    state.storeOnetCompanyGuid = '';
  }
};

const actions = {
  async setCompanyList({ commit }, companyList) {
    commit('SET_COMPANY_LIST', companyList);
    await localforage.setItem('storeCompanyList', companyList);
  },

  async setSelectedCompanyName({ commit }, selectedCompanyName) {
    commit('SET_SELECTED_COMPANY_NAME', selectedCompanyName);
    await localforage.setItem('storeSelectedCompanyName', selectedCompanyName);
  },

  async setFileList({ commit }, fileList) {
    commit('SET_FILE_LIST', fileList);
    await localforage.setItem('storeFileList', fileList);
  },

  async setSelectedFileName({ commit }, selectedFileName) {
    commit('SET_SELECTED_FILE_NAME', selectedFileName);
    await localforage.setItem('storeSelectedFileName', selectedFileName);
  },

  async setFunctionList({ commit }, functionList) {
    commit('SET_FUNCTION_LIST', functionList);
    await localforage.setItem('storeFunctionList', functionList);
  },

  async setSelectedFunctions({ commit }, selectedFunctions) {
    commit('SET_SELECTED_FUNCTIONS', selectedFunctions);
    await localforage.setItem('storeSelectedFunctions', selectedFunctions);
  },

  async setRoleList({ commit }, roleList) {
    commit('SET_ROLE_LIST', roleList);
    await localforage.setItem('storeRoleList', roleList);
  },

  async setSelectedRoles({ commit }, selectedRoles) {
    commit('SET_SELECTED_ROLES', selectedRoles);
    await localforage.setItem('storeSelectedRoles', selectedRoles);
  },

  async setSelectedOption({ commit }, selectedOption) {
    commit('SET_SELECTED_OPTION', selectedOption);
    await localforage.setItem('storeSelectedOption', selectedOption);
  },

  async setOnetCompanyGuid({ commit }, onetCompanyGuid) {
    commit('SET_ONET_COMPANY_GUID', onetCompanyGuid);
    await localforage.setItem('storeOnetCompanyGuid', onetCompanyGuid);
  },

  async resetWorkforceStore({ commit }) {
    commit('RESET_STATE');
    await localforage.removeItem('storeSelectedCompanyName');
    await localforage.removeItem('storeCompanyList');
    await localforage.removeItem('storeFileList');
    await localforage.removeItem('storeSelectedFileName');
    await localforage.removeItem('storeFunctionList');
    await localforage.removeItem('storeSelectedFunctions');
    await localforage.removeItem('storeRoleList');
    await localforage.removeItem('storeSelectedRoles');
    await localforage.removeItem('storeOnetCompanyGuid');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
