<template>
  <div>
    <AllFiltersSideBar
      :filterValues="filterValues"
      :selectedFilters="selectedFilters"
      :isFilterSideMenuActive="isFilterSideMenuActive"
      :filteredOptions="filteredOptions"
      @toggle-side-menu-overlay="toggleSideMenuOverlay"
      @all-filters-toggle-side-menu="isFilterSideMenuActive = !isFilterSideMenuActive"
      @clear-side-filter="clearSideFilter"
      :totalResults="totalResults"
      :displaySelectedFilters="displaySelectedFilters"
    />

    <v-col :class="$route.path === '/catalog/stepper/create' ? 't-p-0' : ''" class="search-filter-child-container t-flex">
      <!-- we'll remove the v-if when the language filter is added back to the Catalog page -->
      <!-- .filter((data) => data.name !== 'Language') -->
      <!-- we'll remove the v-if when the language filter is added back to the Catalog page -->

      <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
      <div :class="$route.path === '/catalog/stepper/create' ? 't-relative t-flex-1 filter-item-container' : 't-relative t-flex-1 item-display'" v-for="(filter, i) in filterValues" :key="filter.name">
        <!-- <div class="t-relative t-flex-1" v-for="(filter, i) in filterValues.filter((data) => data.name !== 'Language' && data.name !== 'Content feature')" :key="filter.name"> -->

        <v-col v-if="getFilterName(filter)" class="filter-cols" :style="filter.showExpandedFilter ? 'zIndex:1' : 'zIndex:2'">
          <div class="backdrop" v-if="filter.showExpandedFilter" @click="onBackdrop(i)"></div>
          <div class="filter">
            <div
              :class="filter.showExpandedFilter ? 't-border-black' : ''"
              class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black"
              :style="filter.disabled || filter.values.length == 0 ? 'pointer-events: none' : ''"
              @click="openDropdown(filter, i)"
            >
              <span class="t-font-arial t-text-sm" :style="filter.values.length == 0 || filter.disabled ? 'color:rgb(0, 0, 0, 0.2)' : ''">{{ sentenceCase($t(filter.translationKey)) }}</span>
              <v-icon :style="filter.values.length == 0 || filter.disabled ? 'opacity:0.3' : ''">mdi-chevron-down</v-icon>
            </div>
          </div>

          <div class="filter-dropdown" v-if="filter.showExpandedFilter && (filter.values.length <= 5 || filter.name == 'Duration')">
            <ul>
              <li v-for="(item, index) in filter.values" :key="index" style="max-height: 1.875rem; display: flex; align-items: center" class="catalog-list-item">
                <v-checkbox
                  v-if="filter.name == 'Provider' || filter.name == 'Assigned as' || filter.name == 'Skill' || filter.name == 'Accreditation' || filter.name == 'Technology' || filter.name == 'Language' || filter.name == 'Content feature'"
                  :key="item.name"
                  :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable"
                  :label="filter.name == 'Language' ? item.display : item.name"
                  dense
                  :value="item.name"
                  v-model="selectedFilters[i].values"
                  style="display: inline-block"
                />

                <v-checkbox
                  v-if="!(filter.name == 'Provider' || filter.name == 'Assigned as' || filter.name == 'Skill' || filter.name == 'Accreditation' || filter.name == 'Technology' || filter.name == 'Language' || filter.name == 'Content feature')"
                  :key="item.name"
                  :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable"
                  :label="$t(item.translationKey)"
                  dense
                  :value="item.name"
                  v-model="selectedFilters[i].values"
                  style="display: inline-block"
                />
                <span class="filter-value-count" :class="item.count > 0 ? '' : 'disabled-count'">({{ numberWithCommas(item.count) }})</span>
              </li>
            </ul>
            <div class="filter-option-footer">
              <div class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</div>
              <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
            </div>
          </div>

          <div class="filter-dropdown" v-if="filter.showExpandedFilter && filter.values.length > 5 && (filter.name == 'Content Type' || filter.name == 'Level')">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              :label="$t('common.filter.searchPrompt') + $t(filter.translationKey).toLocaleLowerCase()"
              v-model="queryFilter"
              @keyup="filterOptions($event, i)"
              rounded
              clearable
              single-line
              hide-details
              filled
              dense
              solo
              variant="solo"
            />
            <div class="t-text-center" v-if="filteredOptions.length == 0">{{ $t('catalog.noMatchesFound') }}</div>
            <ul :class="filteredOptions.length > 5 ? 'scrollable' : ''">
              <li v-for="(item, index) in filteredOptions" :key="index" style="max-height: 1.875rem; display: flex; align-items: center" class="catalog-list-item">
                <v-checkbox :key="item.name" :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable" :label="$t(item.translationKey)" dense :value="item.name" v-model="selectedFilters[i].values" style="display: inline-block" />
                <span :class="item.count > 0 ? '' : 'disabled-count'" :disabled="{}.hasOwnProperty.call(item, 'disable')" style="margin-left: 0.375rem; margin-top: -1.5rem; font-weight: normal; font-size: 0.875rem">({{ numberWithCommas(item.count) }})</span>
              </li>
            </ul>
            <div class="filter-option-footer">
              <div class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</div>
              <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
            </div>
          </div>

          <div class="filter-dropdown" v-if="!filter.filterType && filter.showExpandedFilter && filter.values.length > 5 && filter.name != 'Duration' && filter.name != 'Level' && filter.name != 'Content Type'">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              :label="$t('common.filter.searchPrompt') + $t(filter.translationKey).toLocaleLowerCase()"
              v-model="queryFilter"
              @keyup="filterOptions($event, i)"
              rounded
              clearable
              single-line
              hide-details
              filled
              dense
              solo
              variant="solo"
            />

            <div class="t-text-center" v-if="filteredOptions.length == 0">{{ $t('catalog.noMatchesFound') }}</div>
            <ul :class="filteredOptions.length > 5 ? 'scrollable' : ''">
              <li v-for="(item, index) in filteredOptions" :key="index" style="max-height: 1.875rem; display: flex; align-items: center" class="catalog-list-item">
                <v-checkbox :key="item.name" :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable" :label="filter.name == 'Language' ? item.display : item.name" dense :value="item.name" v-model="selectedFilters[i].values" style="display: inline-block" />
                <span :class="item.count > 0 ? '' : 'disabled-count'" :disabled="{}.hasOwnProperty.call(item, 'disable')" style="margin-left: 0.375rem; margin-top: -1.5rem; font-weight: normal; font-size: 0.875rem">({{ numberWithCommas(item.count) }})</span>
              </li>
            </ul>
            <div class="filter-option-footer">
              <div class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</div>
              <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
            </div>
          </div>

          <div class="filter-dropdown" v-if="filter.filterType === 2 && filter.showExpandedFilter && filter.values.length > 5 && filter.name != 'Duration' && filter.name != 'Level' && filter.name != 'Content Type'">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              :label="$t('common.filter.searchPrompt') + $t(filter.translationKey).toLocaleLowerCase()"
              v-model="queryFilter"
              @keyup="filterOptions($event, i)"
              rounded
              clearable
              single-line
              hide-details
              filled
              dense
              solo
              variant="solo"
            />
            <div class="t-text-center" v-if="filteredOptions.length == 0">{{ $t('catalog.noMatchesFound') }}</div>

            <ul :class="filteredOptions.length > 5 ? 'scrollable' : ''" class="custom-list-item">
              <li v-for="(item, index) in filteredOptions" :key="index" class="catalog-list-item">
                <div class="checkbox-container">
                  <!-- we may need this for the future - delete on 06/01/23  -->
                  <!-- <i v-if="!selectedFilters[i].values.includes(item.name)" aria-hidden="true" class="v-icon notranslate v-icon--dense mdi mdi-checkbox-blank-outline theme--light"></i> -->
                  <!-- <i v-else aria-hidden="true" class="v-icon notranslate v-icon--dense mdi mdi-checkbox-marked theme--light primary--text"></i> -->
                  <!-- we may need this for the future - delete on 06/01/23  -->
                  <input class="mdi-checkbox-blank-outline" type="checkbox" :id="`input-${index}`" :value="item.name" v-model="selectedFilters[i].values" :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable" />
                  <label :for="`input-${index}`" :value="item.name">{{ item.name }}</label>
                  <span :class="item.count > 0 ? '' : 'disabled-count'" :disabled="{}.hasOwnProperty.call(item, 'disable')" style="margin-left: 0.375rem; font-weight: normal; font-size: 0.875rem">({{ numberWithCommas(item.count) }})</span>
                </div>
              </li>
            </ul>
            <div class="filter-option-footer">
              <div class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</div>
              <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
            </div>
          </div>
        </v-col>
      </div>
      <v-col class="filter-cols all-filters" style="z-index: 1">
        <div class="filter">
          <div :style="Object.keys(filterResults).length === 0 ? 'pointer-events: none' : ''" :class="isFilterSideMenuActive ? 't-border-black' : ''" class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black" @click="toggleSideMenu">
            <span class="t-font-arial t-text-sm" :style="Object.keys(filterResults).length === 0 ? 'color:rgb(0, 0, 0, 0.2)' : ''">{{ $t('common.titleCase.all') }}</span>
            <svg-icon :style="Object.keys(filterResults).length === 0 ? 'opacity:0.3' : ''" icon-class="filters-icon" class-name="all-filters-icon" />
          </div>
        </div>
      </v-col>
    </v-col>
  </div>
</template>

<script>
import { mapDurations, numberWithCommas, sentenceCase } from '@/utils';
import en_json from '@/locales/en-us.json';
import AllFiltersSideBar from './AllFiltersSideBar';
import { translate } from '@/plugins/i18n.js';
import { mapGetters } from 'vuex';
import localforage from 'localforage';

export default {
  components: { AllFiltersSideBar },
  name: 'CatalogSearchFilter',
  emits: ['catalog-filter-applied', 'catalog-track-filters-interface'],
  props: {
    selectedEditFilters: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    filterResults: {
      type: Object,
      require: true,
      default: () => {
        return {};
      }
    },
    totalResults: {
      type: Number,
      required: true,
      default: () => {
        return 0;
      }
    }
  },
  data() {
    return {
      mapDurations,
      queryFilter: '',
      filteredOptions: [],
      showExpandedFilter: false,
      values: [],
      showFilters: false,
      selectedFilters: [
        { name: 'Content Type', translationKey: 'common.filter.contentType', key: 'content_types', values: [] },
        { name: 'Skill', translationKey: 'common.filter.skill', key: 'skills', values: [] },
        { name: 'Provider', translationKey: 'common.filter.provider', key: 'publishers', values: [] },
        { name: 'Duration', translationKey: 'common.filter.duration', key: 'durations', values: [] },
        { name: 'Level', translationKey: 'common.filter.level', key: 'levels', values: [] },
        { name: 'Technology', translationKey: 'common.filter.technology', key: 'tools', values: [] },
        { name: 'Accreditation', translationKey: 'common.filter.accreditation', key: 'accreditations', values: [] },
        { name: 'Language', translationKey: 'common.filter.language', key: 'languages', values: [] },
        { name: 'Content feature', translationKey: 'common.filter.contentFeature', key: 'contentFeature', values: [] }
      ],
      displaySelectedFilters: [],
      filterValues: [
        {
          name: 'Content Type',
          translationKey: 'common.filter.contentType',
          values: [
            { name: 'Track', translationKey: 'common.filter.contentTypeOptions.track', disable: true },
            { name: 'Course', translationKey: 'common.filter.contentTypeOptions.course', disable: true },
            { name: 'Credential', translationKey: 'common.filter.contentTypeOptions.credential', disable: true },
            { name: 'Video', translationKey: 'common.filter.contentTypeOptions.video', disable: true },
            { name: 'Audio', translationKey: 'common.filter.contentTypeOptions.audio', disable: true },
            { name: 'Article', translationKey: 'common.filter.contentTypeOptions.article', disable: true }
          ],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false
        },
        {
          name: 'Skill',
          translationKey: 'common.filter.skill',
          values: [],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false,
          filterType: 2
        },
        {
          name: 'Provider',
          translationKey: 'common.filter.provider',
          values: [],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false
        },
        {
          name: 'Duration',
          translationKey: 'common.filter.duration',
          values: [
            { name: '< 15 min', translationKey: 'common.filter.durationOptions.duration1', disable: true },
            { name: '15 - 60 min', translationKey: 'common.filter.durationOptions.duration2', disable: true },
            { name: '1 - 3 hr', translationKey: 'common.filter.durationOptions.duration3', disable: true },
            { name: '3 - 5 hr', translationKey: 'common.filter.durationOptions.duration4', disable: true },
            { name: '5 - 10 hr', translationKey: 'common.filter.durationOptions.duration5', disable: true },
            { name: '10+ hr', translationKey: 'common.filter.durationOptions.duration6', disable: true }
          ],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false
        },
        {
          name: 'Level',
          translationKey: 'common.filter.level',
          values: [
            { name: 'Beginner', translationKey: 'common.filter.levelOptions.beginner', disable: true },
            { name: 'Intermediate', translationKey: 'common.filter.levelOptions.intermediate', disable: true },
            { name: 'Advanced', translationKey: 'common.filter.levelOptions.advanced', disable: true }
          ],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false
        },
        { name: 'Technology', translationKey: 'common.filter.technology', values: [], showExpandedFilter: false, showAccordion: false, disabled: false },
        { name: 'Accreditation', translationKey: 'common.filter.accreditation', values: [], showExpandedFilter: false, showAccordion: false, disabled: false },
        { name: 'Language', translationKey: 'common.filter.language', values: [], showExpandedFilter: false, showAccordion: false, disabled: false },
        {
          name: 'Content feature',
          translationKey: 'common.filter.contentFeature',
          values: [],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false
        }
      ],
      isFilterSideMenuActive: false
    };
  },
  computed: { ...mapGetters(['client_category']) },
  async mounted() {
    // CatalogLanding events
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
    if (!this.isC1 && this.$route.path !== '/catalog/stepper/create') {
      this.selectedFilters.splice(1, 0, { name: 'Assigned as', translationKey: 'common.filter.assignedAs', key: 'assignedAs', values: [] });
      this.filterValues.splice(1, 0, {
        name: 'Assigned as',
        translationKey: 'common.filter.assignedAs',
        values: [],
        showExpandedFilter: false,
        showAccordion: false,
        disabled: false
      });
    }
    this.EventBus.on('catalog-search-cleared', this.clearFilters);
    this.EventBus.on('catalog-remove-filter', this.removeFilter);
    // AllFiltersSideBar events
    this.EventBus.on('show-filters', this.onShowFromAllFilters);
    this.EventBus.on('all-filters-cleared', this.clearFilters);
    this.setSelectedFilter();
    this.setFilterValues();
    this.emitInterface();
  },
  watch: {
    filterResults() {
      this.setFilterValues();
    },
    isFilterSideMenuActive() {
      if (this.isFilterSideMenuActive) {
        document.documentElement.style.overflow = 'hidden';
        document.getElementsByClassName('all-filters-container')[0].style['overflow-y'] = 'scroll';
      } else {
        document.documentElement.style.overflow = 'scroll';
      }
    }
  },
  methods: {
    sentenceCase,
    numberWithCommas,
    toggleSideMenu() {
      this.filterValues.forEach((item) => {
        item.showExpandedFilter = false;
      });
      this.isFilterSideMenuActive = !this.isFilterSideMenuActive;
      if (this.isFilterSideMenuActive) {
        document.documentElement.style.overflow = 'hidden';
        document.getElementsByClassName('all-filters-container')[0].style['overflow-y'] = 'scroll';
      } else {
        document.documentElement.style.overflow = 'scroll';
      }
    },
    setSelectedFilter() {
      this.displaySelectedFilters = this.selectedEditFilters;
      this.selectedFilters.map(async (item, index) => {
        this.selectedEditFilters.some((selectedItem) => {
          let filterData = selectedItem.key == 'technologies' ? 'tools' : selectedItem.key;
          if (item.key == filterData) {
            this.selectedFilters[index].values.push(selectedItem.name);
          }
        });
      });
    },
    getFilterName(filter) {
      if (this.$route.path === '/catalog/stepper/create') {
        return !['Content feature', 'Skill', 'Assigned as', 'Accreditation'].includes(filter?.name);
      } else {
        return !['Language', 'Content feature'].includes(filter?.name);
      }
    },
    toggleSideMenuOverlay(val) {
      if (val) {
        document.documentElement.style.overflow = 'hidden';
        document.getElementsByClassName('all-filters-container')[0].style['overflow-y'] = 'scroll';
      } else {
        document.documentElement.style.overflow = 'scroll';
      }
      this.isFilterSideMenuActive = val;
    },
    onCancel(index) {
      this.filterValues[index].showExpandedFilter = !this.filterValues[index].showExpandedFilter;
      this.resetDropDownValues(index);
    },
    async onShow(index, toggle = true) {
      await this.applyFilters(index, toggle);
      this.$emit('catalog-filter-applied', this.displaySelectedFilters);
    },
    async onShowFromAllFilters(index, toggle = false) {
      await this.applyFilters(index, toggle);
      this.$emit('catalog-filter-applied', this.displaySelectedFilters);
    },
    onBackdrop(index) {
      this.queryFilter = '';
      this.filterValues[index].showExpandedFilter = false;
      this.resetDropDownValues(index);
    },
    resetDropDownValues(index) {
      /****
       * remove values from filter if clicked outside, but keep the ones already applied from displaySelected array
       */
      if (!this.displaySelectedFilters.length) {
        this.selectedFilters[index].values = [];
      } else {
        const doesKeyExistSelectedFilters = this.displaySelectedFilters.some((item) => {
          return item.key === this.selectedFilters[index].key;
        });
        if (doesKeyExistSelectedFilters) {
          const selectedArrayItems = [];
          this.displaySelectedFilters.filter((item) => {
            if (this.selectedFilters[index].key === item.key) {
              if (!this.selectedFilters[index].values.includes(item.name)) {
                this.selectedFilters[index].values.push(item.name);
                selectedArrayItems.push(item.name);
              } else {
                selectedArrayItems.push(item.name);
              }
            }
          });
          this.selectedFilters[index].values = selectedArrayItems;
        } else {
          this.selectedFilters[index].values = [];
        }
      }
    },
    async openDropdown(filterItem, i) {
      this.filteredOptions = this.filterValues[i].values;
      this.filterValues.map((filter, index) => {
        // if previously any filter was open, then reset its value
        if (filter.showExpandedFilter) {
          this.resetDropDownValues(index);
        }
        if (filterItem.name === filter.name) {
          analytics.setVarAndTrack({ c: 'Filter type', a: 'click', l: filter.name });
          filter.showExpandedFilter = true;
        } else {
          filter.showExpandedFilter = false;
        }
      });
    },
    filterOptions(event, i) {
      if (this.queryFilter.length == 0) this.filteredOptions = this.filterValues[i].values;
      else
        this.filteredOptions = this.filterValues[i].values.filter((ele) => {
          return ele.name.toLowerCase().includes(event.target.value.toLowerCase());
        });
    },
    async applyFilters(i, toggle = true) {
      if (i >= 0) {
        if (toggle) {
          this.filterValues[i].showExpandedFilter = !this.filterValues[i].showExpandedFilter;
        }

        /****
         * add value to displaySelectedFilter if new value has been checked from filter dropdown
         */
        let key = '';
        this.selectedFilters.map(async (item) => {
          if (item.name === this.filterValues[i].name) {
            key = item.key;
            item.values.forEach((filterItem) => {
              const obj = {};
              obj.key = key;
              obj.type = item.name;
              obj.name = filterItem;
              if (!this.displaySelectedFilters.some((selectedItem) => selectedItem.key == obj.key && selectedItem.type === obj.type && selectedItem.name === obj.name)) {
                const filterVals = this.filterValues.find((filterType) => filterType.name === obj.type);
                const translatedKeyObj = filterVals.values.find((val) => val.name === obj.name);
                if (translatedKeyObj && {}.hasOwnProperty.call(translatedKeyObj, 'translationKey')) {
                  obj.display = translate(translatedKeyObj.translationKey);
                }
                this.displaySelectedFilters.push(obj);
              }
            });
            /***
             * find item which has been deselected from dropdown and remove it from displaySelectedFilter
             */
            const itemToBeRemoved = await this.displaySelectedFilters.filter((changeItem) => {
              if (changeItem.key === key) {
                if (!item.values.includes(changeItem.name)) {
                  return changeItem;
                }
              }
            });
            if (itemToBeRemoved.length) {
              this.displaySelectedFilters = this.displaySelectedFilters.filter(function (itemInDisplay) {
                return !itemToBeRemoved.find(function (removeItem) {
                  return itemInDisplay.name === removeItem.name && itemInDisplay.key === removeItem.key;
                });
              });
            }
            /****
             * clickstream update
             */
            const appliedFilters = {
              filters: item.values,
              count: item.values.length
            };
            analytics.setVarAndTrack({ c: item.name + ' filter', a: 'Apply filter', l: appliedFilters });
          }
        });
      }
      this.showFilters = this.selectedFilters.some((item) => {
        return item.values.length;
      });
    },
    async clearSideFilter(index) {
      this.selectedFilters[index].values = [];
      await this.applyFilters(index, false);
      this.$emit('catalog-filter-applied', this.displaySelectedFilters);
    },
    async clearFilters() {
      this.selectedFilters.forEach((item) => {
        item.values = [];
        this.showFilters = false;
      });
      /**
       * clickstream update
       */
      const tempArry = [];
      this.displaySelectedFilters.forEach((item) => {
        tempArry.push(item.name);
      });
      analytics.setVarAndTrack({ c: 'Clear all filters', a: 'click', l: { clearedValues: tempArry } });
      this.displaySelectedFilters = [];
      this.$emit('catalog-filter-applied', this.displaySelectedFilters);
    },
    /****
     * removeFilter() -> Remove value from both selectedFilters and displaySelectedFilters pill
     */
    async removeFilter(item) {
      this.selectedFilters.forEach((filterItem) => {
        if (filterItem.name == item.type) {
          const index = filterItem.values.indexOf(item.name);
          filterItem.values.splice(index, 1);
        }
      });

      if (this.displaySelectedFilters.some((filter) => filter.name === item.name)) {
        const index = this.displaySelectedFilters.findIndex((selectedFilter) => selectedFilter.name === item.name && selectedFilter.type === item.type);
        /**
         * clickstream update
         */
        analytics.setVarAndTrack({ c: 'Clear single filter', a: 'click', l: item.name });
        this.displaySelectedFilters.splice(index, 1);
      }
      await this.applyFilters();
      this.$emit('catalog-filter-applied', this.displaySelectedFilters);
    },
    setFilterValues() {
      this.filterValues.forEach((ele) => {
        if (ele.name == 'Content Type') {
          ele.values.map((item) => {
            if ({}.hasOwnProperty.call(this.filterResults, 'typeFilterValues')) {
              item.disable = this.filterResults.typeFilterValues.some((i) => i.name.toLowerCase() == item.name.toLowerCase());
              item.count = this.filterResults.typeFilterValues.filter((filterItem) => item.name === filterItem.name)[0]?.count || 0;
            }
          });
          ele.disabled = Object.keys(this.filterResults).length === 0 || this.filterResults?.typeFilterValues?.length === 0;
        }
        if (ele.name == 'Duration') {
          ele.values.map((item) => {
            if ({}.hasOwnProperty.call(this.filterResults, 'durationFilterValues')) {
              item.disable = this.filterResults.durationFilterValues.some((i) => this.mapDurations[i.name.toLowerCase()] == item.name.toLowerCase());
              item.count = this.filterResults.durationFilterValues.filter((filterItem) => item.name.toLowerCase() === this.mapDurations[filterItem.name.toLowerCase()])[0]?.count || 0;
            }
          });
          ele.disabled = Object.keys(this.filterResults).length === 0 || this.filterResults?.durationFilterValues?.length === 0;
        }
        if (ele.name == 'Level') {
          ele.values.map((item) => {
            if ({}.hasOwnProperty.call(this.filterResults, 'levelFilterValues')) {
              item.disable = this.filterResults.levelFilterValues.some((i) => i.name.toLowerCase() == item.name.toLowerCase());
              item.disable = true;
              item.count = this.filterResults.levelFilterValues.filter((filterItem) => item.name === filterItem.name)[0]?.count || 0;
            }
          });

          ele.disabled = Object.keys(this.filterResults).length === 0 || this.filterResults?.levelFilterValues?.length === 0;
        }
        if (ele.name == 'Provider') {
          const index = this.filterValues.findIndex((x) => x.name === 'Provider');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'providerFilterValues') ? this.filterResults.providerFilterValues : [];
        }
        if (ele.name == 'Skill') {
          const index = this.filterValues.findIndex((x) => x.name === 'Skill');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'skillFilterValues') ? this.filterResults.skillFilterValues : [];
        }
        if (ele.name == 'Accreditation') {
          const index = this.filterValues.findIndex((x) => x.name === 'Accreditation');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'accreditationFilterValues') ? this.filterResults.accreditationFilterValues : [];
        }
        if (ele.name == 'Technology') {
          const index = this.filterValues.findIndex((x) => x.name === 'Technology');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'toolFilterValues') ? this.filterResults.toolFilterValues : [];
        }
        if (ele.name == 'Content feature') {
          ele.values = {}.hasOwnProperty.call(this.filterResults, 'contentFeatureFilterValues') ? this.filterResults.contentFeatureFilterValues : [];
        }
        if (ele.name == 'Assigned as') {
          ele.values = {}.hasOwnProperty.call(this.filterResults, 'assignedAsFilterValues') ? this.filterResults.assignedAsFilterValues : [];
        }
        if (ele.name == 'Language') {
          const index = this.filterValues.findIndex((x) => x.name === 'Language');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'languageFilterValues') ? this.filterResults.languageFilterValues : [];

          for (const language of this.filterValues[index].values) {
            language.display = en_json.languagePicker && en_json.languagePicker[language.name] ? en_json.languagePicker[language.name] : language.name;
          }
          this.filterValues[index].values.sort(function (a, b) {
            return b.count - a.count || a.display.localeCompare(b.display);
          });
        }
      });
    },
    emitInterface() {
      this.$emit('catalog-track-filters-interface', {
        clearFilters: () => this.clearFilters(),
        removeFilter: (item) => this.removeFilter(item)
      });
    }
  }
};
</script>

<style lang="scss">
#chips-container .v-chip .v-chip__content {
  color: white;
}
#chips-container .v-chip .v-chip__close {
  color: white;
}
.filter {
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    border: 0.125rem solid black;
    background: none;
    margin-bottom: 0;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
  }
}

.search-filter-child-container {
  .filter-dropdown {
    .v-text-field {
      margin-top: 1.5rem;
    }
  }
}

.filter-dropdown {
  .catalog-list-item {
    .v-input {
      flex: unset;
      .v-input__control {
        padding-right: 0px;
      }
    }
    label {
      opacity: 1;
    }
  }
  .checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.4375rem;

    span,
    label {
      margin-top: 0.25rem;
      font-size: 0.875rem;
    }

    input[type='checkbox'] {
      margin: 0rem 0.625rem 0rem 0rem;
      position: relative;
      width: 1rem;
      height: 1rem;
      color: black;
      border: 0.125rem solid #bcc2d4;
      border-radius: 0.125rem;
      appearance: none;
      outline: 0;
      cursor: pointer;
      transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

      &::before {
        position: absolute;
        content: '';
        display: none;
        left: 0.25rem;
        width: 0.3125rem;
        height: 0.6875rem;
        border-style: solid;
        border-color: white;
        border-width: 0 0.125rem 0.125rem 0;
        transform: rotate(45deg);
      }

      &:checked {
        color: #fff;
        border-color: #1f3370;
        background: #1f3370;

        &::before {
          display: block;
        }
      }
    }
  }
  .v-input--selection-controls .v-input__slot > .v-label,
  .v-input--selection-controls .v-radio > .v-label {
    font-size: 0.875rem;
  }
  .v-input--selection-controls .v-input__slot > .v-label {
    color: $color-black;
  }
  .v-input--selection-controls .v-input__slot > .v-label.v-label--is-disabled {
    color: rgba(0, 0, 0, 0.2);
  }
  .v-input__control {
    padding: 0rem 1rem;
    .v-label {
      font-weight: normal;
      font-family: Arial;
      font-size: 0.875rem;
      color: black;
    }

    .v-field--variant-solo {
      background-color: white !important;
      height: 2.3125rem;
      border: 0.125rem solid #c4c4c4;

      input[type='text'] {
        padding-left: 1rem;
      }
    }

    .v-field__field,
    .v-field__prepend-inner {
      height: 2.3125rem;
    }

    .v-field__input {
      min-height: unset;
      padding: 0rem;
      height: inherit;
      height: 2.3125rem;
      input {
        margin: 0px;
      }
    }
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none;
  }
}

.filter-dropdown .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: transparent;
  transform: none;
  transition: none;
}
.filter-dropdown .v-input--selection-controls__input .v-ripple__container > span {
  opacity: 0 !important;
}
.filter-dropdown .mdi-checkbox-blank-outline {
  color: #bcc2d4;
  font-size: 1.375rem !important;
}
.filter-dropdown .mdi-checkbox-blank-outline.theme--light.v-icon:focus:after {
  opacity: 0 !important;
}
.filter-dropdown .v-input--is-disabled .v-input--selection-controls__input {
  opacity: 0.3;
}
.filter-option-footer-btn-apply {
  span {
    font-family: 'Futura PT Demi';
    font-weight: 500 !important;
    font-size: 0.875rem !important;
  }
}
</style>
<style lang="scss" scoped>
.scrollable {
  padding-right: 1.875rem;
  max-height: 12.5rem;
  overflow-y: scroll;
  /* width */
  &::-webkit-scrollbar {
    width: 0.3125rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #d8d8d8;
    border: 0.0625rem solid #d8d8d8;
    border-radius: 0.25rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4c5c8d;
    border-radius: 0.25rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #4c5c8d;
  }
}
.filter-pill {
  padding: 0.3125rem 0.875rem;
  height: 2.25rem;
  white-space: nowrap;
  align-items: center;
  background: #efefef 0% 0% no-repeat padding-box;
  border: 0.125rem solid #c4c4c4;
  border-radius: 1.4375rem;

  span {
    text-align: left;
    font: normal normal bold 0.875rem/1.875rem Arial;
    letter-spacing: -0.0088rem;
    color: #000000;
    opacity: 1;
    padding: 0rem 0.5rem;
  }
}
.filter-pill:hover {
  border: 0.125rem solid rgb(0, 0, 0);
}
.mdi-chevron-down::before {
  color: $color-black;
}

.filter-dropdown {
  width: max-content;
  background-color: $color-skill-table !important;
  border-radius: 1.25rem;
  box-shadow: 0.125rem 0.1875rem 0.0625rem -0.125rem rgb(0 0 0 / 20%), 0 0.125rem 0.125rem 0 rgb(0 0 0 / 14%), 0 0.3125rem 0.3125rem 0 rgb(0 0 0 / 12%);
  position: absolute;
  z-index: 2 !important;
}

.custom-list-item {
  li {
    max-height: 1.875rem;
    padding-left: 2rem;
    display: flex;
    align-items: center;
  }
}

.filter-dropdown ul {
  padding: 1.875rem 1.875rem 0.3125rem 0rem;
  list-style: none;
}
.filter-dropdown ul.scrollable {
  margin-top: 1rem;
  margin-right: 1.25rem;
}
.backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0rem;
  left: 0rem;
  background-color: none;
  z-index: 1;
  margin-top: 5rem;
}
.filter-option-footer {
  display: flex;
  gap: 3rem;
  padding: 0.825rem 2rem 0.875rem 2rem;
  align-items: center;
  border-top: 0.0625rem solid #e0e1e1;
}
.filter-option-footer-cancel {
  font-family: 'Futura PT Demi';
  font-size: 1rem;
  color: #1f3370;
  cursor: pointer;
}
.filter-option-footer-btn-apply {
  cursor: pointer;
  text-transform: none;
  width: 5.75rem;
  box-shadow: none;
}

.filter-cols {
  padding-right: 0.9375rem;
  padding-left: 0;
}
.search-filter-child-container {
  padding: 0 0.575rem;
  .item-display {
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8),
    &:nth-child(9) {
      display: none;
    }
    @media screen and (max-width: 1280px) {
      &:nth-child(5) {
        display: none;
      }
    }
    @media screen and (max-width: 1024px) {
      &:nth-child(3),
      &:nth-child(4) {
        display: none;
      }
    }
  }
  .filter-item-container {
    @media screen and (max-width: 1000px) {
      &:nth-child(9),
      &:nth-child(7) {
        display: none;
      }
    }
    @media screen and (min-width: 1001px) and (max-width: 1150px) {
      &:nth-child(9) {
        display: none;
      }
    }
  }
  .filter-value-count {
    margin-left: 0.375rem;
    margin-top: -1.5rem;
    font-weight: normal;
    font-size: 0.875rem;
  }
  .disabled-count {
    color: rgba(0, 0, 0, 0.38);
  }
}

.all-filters-icon {
  margin: 0.15rem 0.5rem 0rem 0rem;
}

@media screen and (max-width: 77.5rem) {
  .search-filter-child-container .filter-cols.col {
    display: none;
  }
  .search-filter-child-container .filter-cols.col:nth-last-child(1) {
    display: block;
    .filter {
      width: 10rem;
    }
  }
}
</style>
