import localforage from 'localforage';

const state = {
  trackList: [],
  isDuplicate: '',
  isRoleBased: false,
  trackName: '',
  reCreateTrack: false,
  trackDetails: {},
  saveExitButton: false,
  trackDescription: '',
  roleGuid: '',
  trackGuid: '',
  roleNameTrack: '',
  skillsTrack: '',
  people: '',
  totalDuration: 0,
  trackSaveButtonState: true,
  trackUnsavedChanges: false,
  trackNameUpdated: false,
  totalItemsInTrack: 0,
  showNotificationDialog: false,
  employeeGuids: []
};

const mutations = {
  SET_TRACK_LIST: (state, trackList) => {
    state.trackList = trackList;
  },
  SET_TRACK_DETAILS: (state, trackDetails) => {
    state.trackDetails = trackDetails;
  },
  SET_SAVE_EXIT_BUTTON: (state, saveExitButton) => {
    state.saveExitButton = saveExitButton;
  },
  SET_SAVE_BUTTON: (state, enabled) => {
    state.trackSaveButtonState = enabled;
  },
  SET_UNSAVED_CHANGES: (state, unsaved) => {
    state.trackUnsavedChanges = unsaved;
  },
  SET_TRACK_GUID: (state, trackRoleGuid) => {
    state.trackGuid = trackRoleGuid;
  },
  SET_TRACK_NAME: (state, trackName) => {
    state.trackName = trackName;
  },
  SET_CONTENT_ID: (state, item) => {
    state.contentId = item;
  },
  SET_CONTENT_TITLE: (state, item) => {
    state.contentTitle = item;
  },
  SET_CONTENT_FORMAT: (state, item) => {
    state.contentFormat = item;
  },
  SET_RECREATE_TRACK: (state, reCreateTrack) => {
    state.reCreateTrack = reCreateTrack;
  },
  SET_TOTAL_DURATION: (state, duration) => {
    state.totalDuration = duration;
  },
  SET_TRACK_DESC: (state, trackDesc) => {
    state.trackDescription = trackDesc;
  },
  SET_TRACK_ROLE_GUID: (state, roleGuid) => {
    state.roleGuid = roleGuid;
  },
  SET_ROLE_NAME_TRACK: (state, roleNameTrack) => {
    state.roleNameTrack = roleNameTrack;
  },
  SET_SKILLS_TRACK: (state, skillsTrack) => {
    state.skillsTrack = skillsTrack;
  },
  SET_PEOPLE: (state, people) => {
    state.people = people;
  },
  SET_IS_ROLE_BASED: (state, isRoleBased) => {
    state.isRoleBased = isRoleBased;
  },
  SET_IS_DUPLICATE: (state, isDuplicate) => {
    state.isDuplicate = isDuplicate;
  },
  SET_TOTAL_TRACK_ITEMS: (state, count) => {
    state.totalItemsInTrack = count;
  },
  SET_TRACK_NAME_UPDATE: (state, trackNameUpdated) => {
    state.trackNameUpdated = trackNameUpdated;
  },
  SET_SHOW_NOTIFICATION_DIALOG: (state, showNotificationDialog) => {
    state.showNotificationDialog = showNotificationDialog;
  },
  SET_EMPLOYEE_GUIDS: (state, empGuids) => {
    state.employeeGuids = [...empGuids];
  },
  SET_SELECTED_EMPLOYEE_NAME: (state, empName) => {
    state.slectedEmployeeName = empName;
  },
  SET_SELECTED_EMPLOYEE_DATA: (state, empData) => {
    state.selectedEmployeeData = empData;
  }
};

const actions = {
  async setRoleNameTrack({ commit }, roleNameTrack) {
    await commit('SET_ROLE_NAME_TRACK', roleNameTrack);
    await localforage.setItem('roleNameTrack', roleNameTrack);
  },
  async setSkillsTrack({ commit }, skillsTrack) {
    await commit('SET_SKILLS_TRACK', skillsTrack);
    await localforage.setItem('skillsTrack', skillsTrack);
  },
  async setRoleGuid({ commit }, roleGuid) {
    await commit('SET_TRACK_ROLE_GUID', roleGuid);
    await localforage.setItem('roleGuid', roleGuid);
  },
  async setTrackGuid({ commit }, trackGuid) {
    await commit('SET_TRACK_GUID', trackGuid);
    await localforage.setItem('trackGuid', trackGuid);
  },
  async setTrackList({ commit }, trackList) {
    await commit('SET_TRACK_LIST', trackList);
    await localforage.setItem('trackList', JSON.parse(JSON.stringify(trackList))).then(() => {});
  },
  async setTrackDetails({ commit }, trackDetails) {
    await commit('SET_TRACK_DETAILS', trackDetails);
    await localforage.setItem('trackDetails', JSON.parse(JSON.stringify(trackDetails))).then(() => {});
  },
  async setSaveExitButton({ commit }, saveExitButton) {
    await commit('SET_SAVE_EXIT_BUTTON', saveExitButton);
    await localforage.setItem('saveExitButton', saveExitButton).then(() => {});
  },
  async resetTrackList({ commit }) {
    await commit('SET_TRACK_LIST', []);
    await localforage.setItem('trackList', []).then(() => {});
  },
  async resetTrackDetails({ commit }) {
    await commit('SET_TRACK_DETAILS', []);
    await localforage.setItem('trackDetails', {}).then(() => {});
  },
  async setTrackName({ commit }, trackName) {
    await commit('SET_TRACK_NAME', trackName);
    await localforage.setItem('trackName', trackName).then(() => {});
  },
  async setContentId({ commit }, item) {
    await commit('SET_CONTENT_ID', item);
    await localforage.setItem('contentId', item).then(() => {});
  },
  async setContentTitle({ commit }, item) {
    await commit('SET_CONTENT_TITLE', item);
    await localforage.setItem('contentTitle', item).then(() => {});
  },
  async setContentFormat({ commit }, item) {
    await commit('SET_CONTENT_FORMAT', item);
    await localforage.setItem('contentFormat', item).then(() => {});
  },
  async setReCreateTrack({ commit }, reCreateTrack) {
    await commit('SET_RECREATE_TRACK', reCreateTrack);
    await localforage.setItem('reCreateTrack', reCreateTrack).then(() => {});
  },
  async setTotalDuration({ commit }, totalDuration) {
    await commit('SET_TOTAL_DURATION', totalDuration);
    await localforage.setItem('totalDuration', totalDuration).then(() => {});
  },
  async setTrackDescription({ commit }, trackDesc) {
    await commit('SET_TRACK_DESC', trackDesc);
    await localforage.setItem('trackDescription', trackDesc).then(() => {});
  },
  async setTotalTrackItems({ commit }, count) {
    await commit('SET_TOTAL_TRACK_ITEMS', count);
    await localforage.setItem('totalItemsInTrack', count).then(() => {});
  },
  async setRoleBased({ commit }, isRoleBased) {
    await commit('SET_IS_ROLE_BASED', isRoleBased);
    await localforage.setItem('isRoleBased', isRoleBased).then(() => {});
  },
  async setSaveButtonState({ commit }, enabled) {
    await commit('SET_SAVE_BUTTON', enabled);
    await localforage.setItem('trackSaveButtonState', enabled).then(() => {});
  },
  async setUnsavedChangesState({ commit }, unsaved) {
    await commit('SET_UNSAVED_CHANGES', unsaved);
    await localforage.setItem('trackUnsavedChanges', unsaved).then(() => {});
  },
  async setTrackNameUpdated({ commit }, nameUpdated) {
    await commit('SET_TRACK_NAME_UPDATE', nameUpdated);
    await localforage.setItem('trackNameUpdated', nameUpdated).then(() => {});
  },
  async setShowNotificationDialog({ commit }, showNotificationDialog) {
    await commit('SET_SHOW_NOTIFICATION_DIALOG', showNotificationDialog);
    await localforage.setItem('showNotificationDialog', showNotificationDialog).then(() => {});
  },

  async setEmployeeGuids({ commit }, empGuids) {
    await commit('SET_EMPLOYEE_GUIDS', empGuids), await localforage.setItem('setEmployeeGuids', empGuids).then(() => {});
  },

  async setSelectedEmployeeName({ commit }, empName) {
    await commit('SET_SELECTED_EMPLOYEE_NAME', empName), await localforage.setItem('setSelectedEmployeeName', empName).then(() => {});
  },

  async setSelectedEmployeeData({ commit }, empData) {
    await commit('SET_SELECTED_EMPLOYEE_DATA', empData), await localforage.setItem('setSelectedEmployeeData', empData).then(() => {});
  },

  async setTrackData({ commit }, trackObj) {
    const { isDuplicate, isRoleBased, trackName, reCreateTrack, trackDescription, roleGuid, trackGuid, people } = trackObj;
    await Promise.allSettled([
      localforage.setItem('isDuplicate', isDuplicate),
      localforage.setItem('isRoleBased', isRoleBased),
      localforage.setItem('trackName', trackName),
      localforage.setItem('reCreateTrack', reCreateTrack),
      localforage.setItem('trackDescription', trackDescription),
      localforage.setItem('roleGuid', roleGuid),
      localforage.setItem('trackGuid', trackGuid),
      localforage.setItem('people', people),
      commit('SET_IS_DUPLICATE', isDuplicate),
      commit('SET_IS_ROLE_BASED', isRoleBased),
      commit('SET_TRACK_NAME', trackName),
      commit('SET_RECREATE_TRACK', reCreateTrack),
      commit('SET_TRACK_DESC', trackDescription),
      commit('SET_TRACK_ROLE_GUID', roleGuid),
      commit('SET_TRACK_GUID', trackGuid),
      commit('SET_PEOPLE', people)
    ]);
  },
  async clearTrackData({ commit }) {
    await Promise.allSettled([
      localforage.setItem('isDuplicate', ''),
      localforage.setItem('isRoleBased', false),
      localforage.setItem('trackName', ''),
      localforage.setItem('reCreateTrack', false),
      localforage.setItem('trackDescription', ''),
      localforage.setItem('roleGuid', ''),
      localforage.setItem('trackGuid', ''),
      localforage.setItem('people', ''),
      localforage.setItem('trackUnsavedCahnges', false),
      localforage.setItem('trackSaveButtonState', true),
      localforage.setItem('trackNameUpdated', false),
      localforage.setItem('showNotificationDialog', false),
      localforage.setItem('roleNameTrack', ''),
      localforage.setItem('skillsTrack', ''),
      localforage.setItem('totalItemsInTrack', 0),
      localforage.setItem('totalDuration', 0),
      localforage.setItem('setEmployeeGuids', []),
      localforage.setItem('setSelectedEmployeeName', []),
      localforage.setItem('setSelectedEmployeeData', ''),
      commit('SET_IS_DUPLICATE', ''),
      commit('SET_IS_ROLE_BASED', false),
      commit('SET_TRACK_NAME', ''),
      commit('SET_CONTENT_ID', ''),
      commit('SET_CONTENT_TITLE', ''),
      commit('SET_CONTENT_FORMAT', ''),
      commit('SET_RECREATE_TRACK', false),
      commit('SET_ROLE_NAME_TRACK', ''),
      commit('SET_SKILLS_TRACK', ''),
      commit('SET_TRACK_DESC', ''),
      commit('SET_TRACK_ROLE_GUID', ''),
      commit('SET_TRACK_GUID', ''),
      commit('SET_PEOPLE', ''),
      commit('SET_SAVE_BUTTON', true),
      commit('SET_UNSAVED_CHANGES', false),
      commit('SET_TOTAL_TRACK_ITEMS', 0),
      commit('SET_TOTAL_DURATION', 0),
      commit('SET_TRACK_NAME_UPDATE', false),
      commit('SET_SHOW_NOTIFICATION_DIALOG', false),
      commit('SET_EMPLOYEE_GUIDS', []),
      commit('SET_SELECTED_EMPLOYEE_NAME', ''),
      commit('SET_SELECTED_EMPLOYEE_DATA', '')
    ]);
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};
