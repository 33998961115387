<template>
  <div class="create-skill-container">
    <v-dialog v-model="dialog" width="584" max-width="584" class="download-dialog-container">
      <v-card max-width="584" :text="$t('hr_tools.create_skill_profile.download_sub_title')" :title="$t('hr_tools.create_skill_profile.title')">
        <template v-slot:append>
          <v-btn variant="plain" icon="mdi-close" @click="dialog = false"></v-btn>
        </template>
        <v-card-text class="">
          <v-radio-group v-model="selectedDownloadOption">
            <v-radio :label="`${$t('hr_tools.create_skill_profile.download_option_1')} ${fileData.name} ${$t('hr_tools.create_skill_profile.download_option_1_sub')}`" value="1" color="#1F3370"></v-radio>
            <v-radio :label="$t('hr_tools.create_skill_profile.download_option_2')" value="2" color="#1F3370" :disabled="!tmpRole"></v-radio>
          </v-radio-group>
        </v-card-text>
        <template v-slot:actions>
          <v-btn data-el="addTag" rounded dark color="#000" class="action black--text ok-btn" @click="onProceedDownload">{{ $t('hr_tools.create_skill_profile.btn_text') }}</v-btn>
        </template>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="failedDialog" width="85%" max-width="85%" class="failure-dialog-container">
        <v-card :title="$t('hr_tools.skill_extraction.failed_dialog_title')">
          <template v-slot:append>
            <v-btn variant="plain" icon="mdi-close" @click="failedDialog = false"></v-btn>
          </template>
          <v-card-text class="">
            <div class="t-flex t-gap-8">
              <div>
                <h4>{{ $t('hr_tools.skill_extraction.failure_msg') }}</h4>
                <p>{{ fileFailure.msg }}</p>
              </div>
              <div>
                <h4>{{ $t('hr_tools.skill_extraction.date_range') }}</h4>
                <p>{{ fileFailure.createdDate }}</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <h3>{{ $t('hr_tools.create_skill_profile.title') }}</h3>
    <div class="create-skill-functions">
      <div class="t-flex t-gap-8 section-1">
        <div class="skill-architecture-header">
          <h6>{{ $t('hr_tools.create_skill_profile.architecture_label') }}</h6>
          <div class="t-flex t-gap-8" style="width: fit-content">
            <SelectView v-model="selectedArchitecture" :items="architectureOptions" item-title="name" item-value="value" return-object variant="outlined" placeholder=""></SelectView>
          </div>
        </div>
        <div class="enter-role">
          <h6>{{ $t('hr_tools.create_skill_profile.enter_role') }}</h6>
          <div>
            <SelectViewAutoComplete
              v-model="selectedRole"
              :items="roleList"
              item-title="roleName"
              item-value="roleName"
              return-object
              variant="outlined"
              @update:focused="onFocusInput"
              @update:modelValue="onUpdateVal"
              @update:search="onUpdateSearchVal"
              density="comfortable"
              :menu-props="menuProps"
              :clearable="selectedRole?.roleName !== '' && selectedRole?.roleName !== $t('hr_tools.create_skill_profile.role_placeholder')"
            ></SelectViewAutoComplete>
          </div>
        </div>
      </div>
      <div class="t-flex t-gap-4 t-items-center section-2">
        <div class="upload-file">
          <v-btn data-el="uploadFile" rounded dark color="#fff" class="action-btn" @click="triggerFileInput()">{{ $t('hr_tools.create_skill_profile.btn_name') }}</v-btn>
          <input id="rolesFile" data-cy="asset-file-zip" ref="rolesFile" class="upload-input" type="file" accept=".csv" @change="handleUpload" style="display: none" :key="Date.now()" />
        </div>
        <p>{{ $t('hr_tools.create_skill_profile.upload_text') }}</p>
        <div v-if="roleListFromFile.length" class="skill-role-header">
          <h6>{{ $t('hr_tools.create_skill_profile.role_label') }}</h6>
          <div class="t-flex t-gap-8" style="width: fit-content">
            <SelectViewAutoComplete
              v-model="selectedRoleFromFile"
              :items="roleListFromFile"
              item-title="roleName"
              item-value="roleName"
              return-object
              variant="outlined"
              @update:focused="onFocusInputCustom"
              @update:modelValue="onUpdateValCustom"
              @update:search="onUpdateSearchValCustom"
              density="comfortable"
              :menu-props="menuProps"
              :clearable="selectedRoleFromFile?.roleName !== '' && selectedRoleFromFile?.roleName !== $t('hr_tools.create_skill_profile.role_placeholder')"
            ></SelectViewAutoComplete>
          </div>
        </div>
      </div>
    </div>
    <div v-if="fileData.name" class="file-info">
      <p>{{ fileData.name }}</p>
      <div class="t-flex t-gap-2">
        <svg-icon v-if="fileData.status.toLowerCase() === 'completed'" icon-class="complete-progress" style="width: 22px; height: 22px" />
        <svg-icon v-else-if="fileData.status.toLowerCase() === 'failed'" icon-class="failed" style="width: 22px; height: 22px" />
        <svg-icon v-else icon-class="loader" style="width: 22px; height: 22px" />
        <p>{{ fileData.status }}</p>
      </div>
    </div>
    <div v-if="skillDataReady" class="role-container">
      <div v-if="roleDetails && skillDataList.length" class="role-info">
        <div class="t-flex">
          <h6>{{ $t('hr_tools.create_skill_profile.role_definition') }}</h6>
          <p>: ({{ $t('hr_tools.create_skill_profile.role_definition_sub') }})</p>
        </div>
        <div class="t-mt-2 role-definition">
          <h4>{{ getDisplayRoleName() }}</h4>
          <p>{{ roleDetails.role_definition }}</p>
        </div>
        <div v-if="roleDetails && roleDetails.skills.length" class="skill-container">
          <div class="skill-info-classification">
            <div v-for="(skill, skillIndex) in skillDataList" :key="skill + skillIndex" class="skill-info">
              <div class="header">
                <h6>
                  {{ skill.title }} <span v-if="skill.subTitle">- {{ skill.subTitle }}</span>
                </h6>
              </div>
              <div :class="skill.items.length > 8 ? 'scrollable-content-skills' : ''">
                <div v-for="(item, index) in skill.items" :key="item + index" class="content">
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="no-role">{{ $t('common.noDataIsAvailable') }}</div>
      </div>
      <div v-else-if="tmpRole" class="no-role">{{ $t('common.noDataIsAvailable') }}</div>
    </div>
    <div v-else-if="tmpRole" class="no-role">
      <svg-icon icon-class="loader" style="width: 22px; height: 22px" /><span class="t-self-center">{{ $t('hr_tools.create_skill_profile.loading') }}</span>
    </div>
    <div v-if="(roleDetails && roleDetails.skills?.length && skillDataList.length) || roleListFromFile.length" class="t-mt-8 t-flex t-justify-end">
      <v-btn data-el="downloadProfile" rounded dark color="#FFB92E" class="download-btn" @click="onClickDownload()">{{ $t('hr_tools.create_skill_profile.download_btn_name') }}</v-btn>
    </div>
    <div v-if="downloadFileList.length > 0" class="t-mt-8 download-files">
      <h6>{{ $t('hr_tools.create_skill_profile.download_files_list') }}</h6>
      <div class="t-w-full">
        <div class="file-meta-data" v-for="(file, index) in downloadFileList" :key="index">
          <div class="t-flex t-items-center t-gap-8">
            <p v-if="file.metadata && file.metadata.fileName" :title="file.metadata.fileName">{{ file.metadata.fileName }}</p>
            <div class="t-flex t-items-center t-gap-2">
              <svg-icon v-if="file.status === 'completed'" icon-class="complete-progress" style="width: 22px; height: 22px" />
              <svg-icon v-else-if="file.status === 'failed'" icon-class="failed" style="width: 22px; height: 22px" />
              <svg-icon v-else icon-class="loader" style="width: 22px; height: 22px" />
              <span :class="file.status === 'failed' ? 'loading-label failed' : 'loading-label'" @click="onClickFailureMsg(file)">{{ file.status }}</span>
            </div>
          </div>
          <v-btn data-el="addTag" rounded dark color="#FFB92E" class="add action-btn black--text" @click="onDownloadCSV(file)" :disabled="file.isDownloadDisable"
            ><svg-icon icon-class="icon-download" class="icon-medium t-cursor-pointer" />{{ $t('hr_tools.skill_extraction.download') }}</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { translate } from '@/plugins/i18n.js';
import SelectView from '@/components/molecules/SelectView';
import SelectViewAutoComplete from '@/components/molecules/SelectViewAutoComplete';
import constantData from '@/api/constant';

export default {
  name: 'CreateSkillProfile',
  emits: [],
  components: { SelectView, SelectViewAutoComplete },
  data() {
    return {
      architectureOptions: [
        {
          name: constantData.soc_name,
          value: constantData.soc_value
        }
      ],
      roleList: [],
      roleListFromFile: [],
      selectedRoleFromFile: {
        roleName: translate('hr_tools.create_skill_profile.role_placeholder')
      },
      selectedRole: {
        roleName: translate('hr_tools.create_skill_profile.role_placeholder')
      },
      selectedArchitecture: {
        name: constantData.soc_name,
        value: constantData.soc_value
      },
      fileData: {
        name: '',
        status: ''
      },
      dialog: false,
      skillDataList: [],
      roleDetails: {},
      selectedDownloadOption: '',
      originalRoleListFromFile: [],
      originalRoleList: [],
      tmpRole: '',
      menuProps: {
        maxHeight: '255px',
        width: '400px',
        contentClass: 'select-menu-content'
      },
      skillDataReady: false,
      downloadFileList: [],
      failedDialog: false,
      fileFailure: {
        msg: '',
        createdDate: ''
      }
    };
  },

  async mounted() {
    await this.fetchRoles();
    await this.fetchFileRoles();
    await this.fetchDownloadFiles();
  },

  async unMounted() {
    this.fileData.name = '';
    this.fileData.status = '';
    this.tmpRole = '';
  },

  deactivated() {},

  methods: {
    triggerFileInput() {
      this.$refs.rolesFile.click();
    },

    async fetchRoles() {
      const payload = {
        architecture: this.selectedArchitecture.value
      };
      const res = await this.$planAdmin.fetchRoles(payload);
      const response = res ? res : [];
      this.roleList = await response.map((item) => {
        return {
          roleName: item
        };
      });
    },

    async fetchFileRoles() {
      const response = await this.$planAdmin.fetchFileRoles();
      if (response) {
        this.roleListFromFile = response.roleList.reduce((acc, role) => {
          if (role && typeof role.roleName === 'string' && role.roleName.trim().length > 0 && !acc.find((r) => r.roleName.toLowerCase() === role.roleName.toLowerCase())) {
            acc.push(role);
          }
          return acc;
        }, []);
        this.fileData.name = response.fileName ? response.fileName : '';
        this.fileData.status = 'completed';
      }
    },

    async fetchDownloadFiles() {
      const payload = {
        jobType: constantData.skill_profile,
        size: 3
      };
      this.downloadFileList = (await this.$planAdmin.getSkillExtractionFiles(payload)) || [];
      await this.downloadFileList.forEach((file) => {
        file.isDownloadDisable = file.status !== 'completed';
      });
    },

    async handleUpload(event) {
      try {
        const files = event.target.files;
        if (!files.length) return;
        const formData = new FormData();
        formData.append('file', files[0]);
        this.fileData.name = files[0].name;
        this.fileData.status = 'In progress';
        const payload = {
          body: formData
        };
        const response = await this.$planAdmin.uploadRoleFile(payload);
        if (response && Array.isArray(response)) {
          this.fileData.status = 'Completed';
          this.roleListFromFile = response.reduce((acc, role) => {
            if (role && typeof role.roleName === 'string' && role.roleName.trim().length > 0 && !acc.find((r) => r.roleName.toLowerCase() === role.roleName.toLowerCase())) {
              acc.push(role);
            }
            return acc;
          }, []);
          this.originalRoleListFromFile = [...this.roleListFromFile];
          const placeholder = translate('hr_tools.create_skill_profile.role_placeholder');
          if (this.selectedRole.roleName === placeholder) {
            this.roleDetails = {};
            this.skillDataList = [];
            this.selectedRoleFromFile.roleName = placeholder;
          }
        } else {
          this.fileData.status = 'Failed';
          this.roleListFromFile = [];
          this.roleDetails = {};
          this.skillDataList = [];
          this.showToast(translate('hr_tools.create_skill_profile.upload_error'), 'error', 10000);
        }
      } catch (e) {
        this.showToast(translate('hr_tools.create_skill_profile.upload_error'), 'error', 5000);
      }
    },

    async getRoleDetails(roleName) {
      if (roleName) {
        this.skillDataReady = false;
        this.roleDetails = {};
        this.skillDataList = [];
        const payload = {
          architecture: this.selectedArchitecture.value,
          role: roleName
        };
        const response = await this.$planAdmin.getSkillProfile(payload);
        if (response && response.result) {
          this.roleDetails = response.result;
          this.skillDataList = this.extractSkillData();
        }
        this.skillDataReady = true;
      }
    },

    extractSkillData() {
      // Define the structure
      const skillCategories = [
        {
          title: 'Skill Name',
          subTitle: '',
          items: this.roleDetails.skills
        },
        {
          title: 'Today',
          subTitle: 'Technical, Occupational, Domain',
          items: this.roleDetails.classsification?.today
        },
        {
          title: 'Tomorrow',
          subTitle: 'Emerging, Technology, Digital',
          items: this.roleDetails.classsification?.tomorrow
        },
        {
          title: 'Career Development',
          subTitle: 'Soft, Human, Abilities',
          items: this.roleDetails.classsification?.career_development
        }
      ];
      return skillCategories;
    },

    onClickDownload() {
      const placeholder = translate('hr_tools.create_skill_profile.role_placeholder');
      if (this.selectedRole.roleName === placeholder) {
        this.selectedDownloadOption = '1';
        this.dialog = true;
      } else {
        this.selectedDownloadOption = '';
        this.onProceedDownload();
      }
    },

    async onProceedDownload() {
      try {
        const selectedOption = this.selectedDownloadOption ? this.selectedDownloadOption : '2';
        const selected = !this.dialog ? this.selectedRole : this.selectedRoleFromFile;
        const payload = {
          rolesPayload: selectedOption === '2' ? [selected] : this.roleListFromFile,
          downloadFileName: this.getDownloadFileName(selectedOption, selected)
        };
        this.dialog = false;
        const response = await this.$planAdmin.downloadSkillProfile(payload);
        if (response && response.status === 200 && response.data) {
          const responseFileData = response.data;
          this.showToast(translate('hr_tools.create_skill_profile.download_msg'), 'success', 10000);
          responseFileData.isDownloadDisable = responseFileData.status !== 'completed';
          this.downloadFileList.unshift(responseFileData);
          if (this.downloadFileList.length > 3) {
            this.downloadFileList.pop();
          }
        }
      } catch (e) {
        console.log(e);
        this.showToast(translate('hr_tools.create_skill_profile.file_download_fail'), 'error', 5000);
      }
    },

    getDownloadFileName(selectedOption, selected) {
      if (selectedOption === '2') {
        return selected.roleName;
      } else {
        return this.fileData.name.replace(/\.[^/.]+$/, '');
      }
    },

    getDisplayRoleName() {
      if (this.tmpRole && this.tmpRole.roleName.toLowerCase() != this.roleDetails.role_name.toLowerCase()) return `${this.tmpRole.roleName} (${this.roleDetails.role_name})`;
      return this.tmpRole.roleName;
    },

    onFocusInput(focus) {
      this.focusInput(focus, 'system');
    },

    onUpdateVal(val) {
      this.updateVal(val, 'system');
    },

    onUpdateSearchVal(val) {
      this.updateSearchVal(val, 'system');
    },

    onFocusInputCustom(focus) {
      this.focusInput(focus, 'custom');
    },

    onUpdateValCustom(val) {
      this.updateVal(val, 'custom');
    },

    onUpdateSearchValCustom(val) {
      this.updateSearchVal(val, 'custom');
    },

    focusInput(focus, type) {
      const typeObj = {
        system: 'selectedRole',
        custom: 'selectedRoleFromFile'
      };
      const placeholder = translate('hr_tools.create_skill_profile.role_placeholder');
      if (focus && this[typeObj[type]]?.roleName === placeholder) {
        this[typeObj[type]].roleName = '';
      } else if (!focus && !this[typeObj[type]]?.roleName) {
        if (this.roleDetails && this.roleDetails.role_name && this.tmpRole && this[typeObj[type]]?.roleName !== placeholder && this[typeObj[type]]?.roleName !== '') {
          this[typeObj[type]] = this.tmpRole;
          return;
        }
        this[typeObj[type]] = { roleName: placeholder };
      }
    },

    updateVal(val, type) {
      const typeObj = {
        system: { selected: this.selectedRole, list: this.roleList, original: this.originalRoleList },
        custom: { selected: this.selectedRoleFromFile, list: this.roleListFromFile, original: this.originalRoleListFromFile }
      };
      if ((!val || val?.roleName?.trim()) === '' && typeObj[type].selected?.roleName !== translate('hr_tools.create_skill_profile.role_placeholder')) {
        typeObj[type].list = [...typeObj[type].original]; // Create a new array reference
        return;
      }
      // If value exists and not empty, get role details
      if (typeObj[type].selected?.roleName) {
        const placeholder = translate('hr_tools.create_skill_profile.role_placeholder');
        const newRole = { roleName: placeholder };
        if (type === 'system') {
          this.selectedRoleFromFile = newRole;
        } else if (type === 'custom') {
          this.selectedRole = newRole;
        }
        this.tmpRole = val;
        this.getRoleDetails(typeObj[type].selected?.roleName);
      }
    },

    updateSearchVal(val, type) {
      const typeObj = {
        system: { list: this.roleList, original: this.originalRoleList },
        custom: { list: this.roleListFromFile, original: this.originalRoleListFromFile }
      };
      if (!val) {
        typeObj[type].list = typeObj[type].original;
        return;
      }
    },

    onClickFailureMsg(file) {
      if (file.status === 'failed') {
        this.failedDialog = true;
        this.fileFailure.msg = file.message;
        this.fileFailure.createdDate = file.createdAt;
      }
    },

    async onDownloadCSV(file) {
      const payload = {
        userEmail: file.userEmail,
        relativeUrl: file.metadata.outputFileRelativeUri
      };
      this.downloadFileReference = file;
      const response = await this.$planAdmin.getSkillExtractionFileSignedRelativeUri(payload);
      if (response) {
        this.downloadFile(response);
      } else {
        this.showToast(translate('hr_tools.skill_extraction.file_process_error'), 'error', 5000);
      }
    },

    async downloadFile(relativeUri) {
      try {
        if (relativeUri) {
          this.downloadPercentage = 0;
          let filename = this.downloadFileReference.fileName;
          let a = document.createElement('a');
          a.href = relativeUri;
          a.setAttribute('download', filename);
          a.click();
        }
      } catch (error) {
        console.log(error);
      }
    },

    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });
      return this.$toasted.show(msg, options);
    }
  },

  metaInfo() {
    return {
      title: translate('pageTitles.hr_tools')
    };
  }
};
</script>
<style lang="scss">
.v-list-item-title {
  text-transform: capitalize !important;
}
.create-skill-container {
  .create-skill-functions {
    .skill-architecture-header,
    .skill-role-header,
    .enter-role {
      .v-select .v-field.v-field {
        height: 40px !important;
        width: 286px !important;
      }
      .v-autocomplete .v-field.v-field {
        height: 40px !important;
        width: 286px !important;
      }
      .v-field--center-affix .v-field__append-inner {
        height: 40px !important;
      }
      .v-field__input {
        padding: 8px !important;
        height: 40px !important;
      }
      .v-input__details {
        display: none !important;
      }
      .v-select__selection,
      .v-autocomplete .v-field .v-field__input > input,
      .v-autocomplete__selection {
        align-self: baseline;
        text-transform: capitalize;
      }
      .v-field--center-affix .v-label.v-field-label {
        top: 38% !important;
      }
      .v-menu > .v-overlay__content {
        max-height: 255px !important;
        width: 286px !important;
      }
      .v-field__clearable {
        margin-bottom: 6px;
      }
    }
    .enter-role {
      .v-list-item {
        width: 300px;
      }
    }
    .section-2 {
      .v-btn__content {
        color: #1f3370 !important;
      }
    }
  }

  .scrollable-content-skills {
    height: 462px;
    overflow-y: scroll;
    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #efefef;
      border: 1px solid #efefef;
      border-radius: 4px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1f3370;
      border-radius: 4px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #1f3370;
    }
  }
}
.download-dialog-container {
  .v-card {
    padding: 2rem !important;
    .v-card-item {
      padding: unset !important;
      .v-card-title {
        @include header-font-1-5;
        color: #2d2d2d;
        margin-bottom: 6px;
      }
    }
    .v-card-text {
      padding: unset !important;
      @include body-font-1;
      margin-bottom: 2rem;
    }
    .v-card-item__append {
      font-size: 1.5rem;
    }
  }
  .ok-btn {
    background-color: #ffb92e;
    height: 52px;
    align-items: center;
    @include body-font-bold-1;
  }
  .v-card-actions {
    justify-content: end;
  }
  .v-radio-group .v-input__details {
    display: none !important;
  }
  .v-selection-control .v-label {
    color: #2d2d2d;
    @include body-font-1;
    opacity: 1;
  }
}
</style>
<style lang="scss" scoped>
.select-menu-content {
  contain: content;
  text-transform: capitalize;
}
.create-skill-container {
  background: #fff;
  padding: 3rem;
  border-radius: 1.5rem;
  h3 {
    @include header-font-2;
    color: #2d2d2d;
    margin-bottom: 2rem;
  }
  .create-skill-functions {
    display: flex;
    align-items: center;
    row-gap: 1.5rem;
    @media screen and (max-width: 1366px) {
      flex-wrap: wrap;
    }
    .skill-architecture-header,
    .skill-role-header,
    .enter-role {
      h6 {
        margin-bottom: 8px;
        @include body-font-bold-0-875;
      }
    }
    .skill-role-header {
      margin-left: 1rem;
    }
    .section-1 {
      padding-right: 96px;
      border-right: 1px dotted #eaeaea;
      margin-right: 96px;
      @media screen and (max-width: 1366px) {
        border: unset;
      }
    }
    .section-2 {
      .action-btn {
        box-shadow: none !important;
        padding: 8px 24px;
        float: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border: 2px solid #1f3370;
        color: #1f3370;
        @include body-font-bold-1;
        height: 52px;
        width: 141px;
      }
      p {
        @include body-font-0-875;
        color: #000;
        width: 266px;
      }
    }
  }
  .file-info {
    margin-top: 2rem;
    display: flex;
    padding: 16px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    background: var(--Fill-Gainsboro, #f5f5f5);
    p {
      @include body-font-0-875;
      color: #2d2d2d;
    }
  }
  .no-role {
    @include body-font-1;
    color: #000;
    margin: 2rem auto;
    .svg-icon {
      margin-right: 1rem;
    }
  }
  .role-info {
    margin-top: 2rem;
    h6 {
      @include body-font-bold-0-875;
      color: #2d2d2d;
    }
    p {
      @include body-font-0-875;
      color: #2d2d2d;
    }
    .role-definition {
      h4 {
        @include header-font-1-25;
        color: #2d2d2d;
        text-transform: capitalize;
      }
      p {
        @include body-font-1;
        color: #000;
      }
    }
    .skill-container {
      display: flex;
      margin-top: 2rem;
      column-gap: 3rem;
      .skill-info-classification {
        column-gap: 3rem;
        row-gap: 2rem;
        display: flex;
        width: 100%;
        height: fit-content;
        flex-wrap: wrap;
      }
      .skill-info {
        border: 2px solid var(--Border-Border-Grey-Lighter2, #d6d6d6);
        border-radius: 8px;
        width: 312px;
        height: fit-content;
        h6 {
          @include body-font-bold-0-75;
          color: #2d2d2d;
          span {
            @include body-font-0-75;
          }
        }
        .header {
          padding: 1rem 1rem 1rem 2rem;
          background: #f5f5f5;
        }
        .content {
          padding: 1rem 1rem 1rem 2rem;
          @include body-font-1;
          border-bottom: 2px solid var(--Border-Border-Grey-Lighter2, #d6d6d6);
          text-transform: capitalize;
          &:last-child {
            border: unset;
          }
        }
      }
    }
  }
  .download-btn {
    box-shadow: none !important;
    padding: 8px 24px;
    @include body-font-bold-1;
  }
  .action {
    box-shadow: none !important;
    padding: 8px 24px;
    float: right;
    display: flex;
    align-items: center;
    @include body-font-bold-1;
    background-color: #ffb92e;
  }
  .download-files {
    h6 {
      @include body-font-bold-0-875;
      color: #2d2d2d;
    }
    .file-meta-data {
      border-radius: var(--Corner-Radius-Radius-XS, 8px);
      background: var(--Fill-Gainsboro, #f5f5f5);
      display: flex;
      justify-content: space-between;
      padding: 1rem 2rem;
      align-items: center;
      align-self: stretch;
      margin: 1rem 0;
      p {
        max-width: 335px;
        width: 335px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .action-btn {
        box-shadow: none !important;
        padding: 8px 24px;
        float: right;
        display: flex;
        align-items: center;
        @include body-font-bold-1;
        background-color: #ffb92e;
      }
    }
    .loading-label {
      @include body-font-0-875;
      color: #2d2d2d;
    }

    .failed {
      color: #e0301e;
      text-decoration: underline;
      cursor: pointer;
    }

    .icon-medium {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}
</style>
