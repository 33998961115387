<template>
  <div class="edit-details-container">
    <div class="edit-details-form">
      <div>
        <div style="font-size: 40px" class="t-font-normal t-font-bold t-text-dark-grey">{{ trackGuid ? translate('createDetails.headerEditTitle') : translate('createDetails.headerTitle') }}</div>
        <div class="t-text-base t-font-normal t-text-black">{{ translate('createDetails.requiredInformation') }}</div>
      </div>
      <div>
        <div class="t-text-xl t-font-normal t-font-bold t-text-black">{{ translate('createDetails.thumbnailTitle') }}</div>
        <div class="t-text-base t-font-normal t-text-dark-grey">{{ translate('createDetails.thumbnailSubtitle') }}</div>
      </div>

      <div class="t-flex t-flex-row t-gap-4">
        <div class="t-p-0">
          <div class="t-text-dark-grey t-text-sm t-font-bold t-mb-1.5">{{ translate('createDetails.defaultThunbnailImage') }}</div>
          <div class="default-image default-track t-p-1.5">
            <button @click.stop="selectImage()" aria-label="select default track image">
              <img id="defaultImg" ref="defaultImg" class="image-border" src="@/assets/images/icons/thumbnail-default-track.png" alt="default" />
            </button>
          </div>
        </div>
        <div class="t-flex t-flex-col t-justify-center t-p-0 t-items-center t-mb-10"><span class="t-text-base t-font-normal t-font-bold t-text-primary-color">OR</span></div>
        <div class="t-p-0">
          <div class="t-text-dark-grey t-text-sm t-font-bold t-mb-1.5">{{ translate('createDetails.customThunbnailImage') }}</div>
          <div class="default-image" @drop="handleDrop($event)" @dragover="handleDragover" @dragenter="handleDragover">
            <label class="file-upload-wrapper">
              <input id="fileinput" data-cy="asset-file" ref="thumbnailUpload" :key="fileUploadIndex" class="t-hidden" type="file" accept="image/png" multiple @change="handleClick($event)" />
              <button @click.stop="openImageUploadPopup" aria-label="upload custom track image">
                <div class="imgBg t-p-2">
                  <img v-show="showPreviewImg" id="previewImage" ref="previewImage" :src="customImgSrc" :class="imageSelected == 'user' ? 'image-border' : ''" style="width: 148px; height: 148px; cursor: pointer; border-radius: 25px" alt="user" />
                </div>
              </button>
            </label>
            <div @click.stop="openImageUploadPopup" class="t-text-base t-font-normal t-text-dark-grey">
              {{ translate('createDetails.dragImage') }}<span class="t-text-primary-color t-underline t-cursor-pointer">{{ translate('createDetails.uploadFile') }}</span>
            </div>
          </div>
          <div class="file-error" v-show="fileError != ''">{{ fileError }}</div>
        </div>
      </div>

      <div>
        <label for="edit-details-track-name" class="t-text-sm t-font-bold t-text-dark-grey">{{ translate('createDetails.trackName') }}</label>
        <input id="edit-details-track-name" class="t-mt-1.5" type="text" :placeholder="translate('createDetails.trackNamePlaceholder')" v-model="trackName" maxlength="255" :style="msg.trackName && 'border: 2px solid #E0301E'" />

        <div class="t-gap-4 t-flex t-items-start t-text-xs t-mt-1.5">
          <span class="edit-details-error-messsage" :style="{ visibility: msg.trackName ? 'visible' : 'hidden' }">{{ msg.trackName ? msg.trackName : '' }}</span>
          <span class="t-flex-1 t-text-right">{{ translate('createDetails.trackCharacterCount') }}</span>
        </div>
      </div>

      <div>
        <label for="edit-details-track-description" class="t-text-sm t-font-bold t-text-dark-grey">{{ translate('createDetails.trackDescription') }}</label>
        <textarea id="edit-details-track-description" type="text" class="t-mt-1.5" :placeholder="translate('createDetails.trackDescriptionPlaceholder')" v-model="trackDescription" :style="msg.trackDescription && 'border: 2px solid #E0301E'" required> </textarea>

        <div :style="{ visibility: msg.trackDescription ? 'visible' : 'hidden' }" class="t-text-xs edit-details-error-messsage">
          {{ msg.trackDescription ? msg.trackDescription : '' }}
        </div>
      </div>

      <div id="userSelectionDisplay" style="display: block">
        <div class="t-flex t-flex-col t-gap-2">
          <p class="t-text-base t-font-normal t-text-dark-grey">{{ $t(`createDetails.trackBasdedCriteria`) }}</p>
          <v-radio-group v-model="specifiedCriteria" @change="userSelection" class="create-user-notify">
            <v-radio :ripple="false" :label="$t('createDetails.trackCriteriaOption1')" :value="1"></v-radio>
            <v-radio :ripple="false" :label="$t('createDetails.trackCriteriaOption2')" :value="2"></v-radio>
          </v-radio-group>
        </div>
      </div>

      <div id="skillOptionDisplay" style="display: none">
        <label for="edit-details-track-skills" class="t-font-bold t-text-dark-grey t-text-sm">
          {{ translate('createDetails.skillsOptional') }}
        </label>
        <div class="edit-details-search-text-container">
          <v-text-field
            :disabled="getSkillLimit()"
            v-if="!trackGuid"
            id="edit-details-track-skills"
            class="edit-details-search-text-field"
            data-el="searchSkill"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            ref="searchInput"
            :placeholder="translate('createDetails.addSkillPlaceholder')"
            :value="query"
            @keyup="handleAutocomplete"
            @click:clear="handleSearchClear"
            flat
            filled
            solo
            rounded
            dense
            clearable
            v-model="query"
            v-click-outside="onClickOutside"
            variant="solo"
          >
          </v-text-field>

          <v-card class="t-max-w-md t-mt-0 t-ml-2 t-rounded-xl t-absolute t-z-10 t-top-10 edit-details-search-results" v-if="showAutocomplete" id="list">
            <ul class="t-list-none t-px-0" :class="autoCompleteList.length ? 'scrollable-menu' : ''">
              <li @click="handleSearchInput(item.queryText)" class="t-px-6 t-py-2 t-cursor-pointer hover:t-text-primary-color hover:t-bg-indigo-100" v-for="(item, index) in autoCompleteList" :tabindex="index + 1" :key="index">
                <div style="padding-left: 2em; text-indent: -2em">
                  <span class="t-text-base t-ml-2 t-font-arial" v-html="item.text"></span>
                  <b>
                    <span class="t-text-base t-ml-2 t-font-arial" v-html="item.title"></span>
                  </b>
                </div>
              </li>
            </ul>
          </v-card>

          <div v-if="chosenSkills.length" class="edit-details-search-result-container">
            <span v-for="(skill, index) in chosenSkills" :key="index" class="edit-details-search-result">
              <span @click="removeSkill(skill)">
                <span class="edit-details-search-pill"> {{ skill.title }} <span class="edit-details-search-x"></span> </span>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div id="skillDisplay" style="display: none">
        <label for="edit-details-track-skills" class="t-font-bold t-text-dark-grey t-text-sm"> {{ translate('createDetails.skillsText') }} </label>
        <div class="edit-details-search-text-container">
          <v-text-field
            :disabled="getSkillLimit()"
            v-if="!trackGuid"
            id="edit-details-track-skills"
            class="edit-details-search-text-field"
            data-el="searchSkill"
            hide-details
            prepend-inner-icon="mdi-magnify"
            single-line
            ref="searchInput"
            :placeholder="translate('createDetails.addSkillPlaceholder')"
            :value="query"
            @keyup="handleAutocomplete"
            @click:clear="handleSearchClear"
            flat
            filled
            solo
            rounded
            dense
            clearable
            v-model="query"
            v-click-outside="onClickOutside"
            variant="solo"
          >
          </v-text-field>

          <v-card class="t-max-w-md t-mt-0 t-ml-2 t-rounded-xl t-absolute t-z-10 t-top-10 edit-details-search-results" v-if="showAutocomplete" id="list">
            <ul class="t-list-none t-px-0" :class="autoCompleteList.length ? 'scrollable-menu' : ''">
              <li @click="handleSearchInput(item.queryText)" class="t-px-6 t-py-2 t-cursor-pointer hover:t-text-primary-color hover:t-bg-indigo-100" v-for="(item, index) in autoCompleteList" :tabindex="index + 1" :key="index">
                <div style="padding-left: 2em; text-indent: -2em">
                  <span class="t-text-base t-ml-2 t-font-arial" v-html="item.text"></span>
                  <b>
                    <span class="t-text-base t-ml-2 t-font-arial" v-html="item.title"></span>
                  </b>
                </div>
              </li>
            </ul>
          </v-card>

          <div v-if="chosenSkills.length" class="edit-details-search-result-container">
            <span v-for="(skill, index) in chosenSkills" :key="index" class="edit-details-search-result">
              <span @click="removeSkill(skill)">
                <span class="edit-details-search-pill"> {{ skill.title }} <span class="edit-details-search-x"></span> </span>
              </span>
            </span>
          </div>
          <div :style="{ visibility: msg.query && !chosenSkills.length ? 'visible' : 'hidden' }" class="t-text-xs edit-details-error-messsage">
            {{ msg.query && !chosenSkills.length ? msg.query : '' }}
          </div>
        </div>
      </div>
      <div v-if="!hideLoading" class="loading-container">
        <svg-icon icon-class="loader" style="width: 22px; height: 22px" /><span class="loading-label">{{ $t('catalog.loading') }}</span>
      </div>
      <div id="trackContentDisplay" style="display: none">
        <div>
          <p class="t-mb-2.5 t-text-sm t-font-bold t-text-dark-grey">
            {{ $t('createDetails.trackDuration') }}
            <span class="typeahead-tooltip">
              <img src="@/assets/images/svgs/icon-info.svg" class="info-icon" alt="Info" />
              <span class="typeahead-tooltiptext">{{ $t('createDetails.durationToolTip') }}</span>
            </span>
          </p>
          <div class="t-font-normal t-text-dark-grey t-text-base t-flex t-flex-row t-items-center t-w-full t-gap-2 t-mb-2">
            <input
              @keydown="preventNonNumeric"
              ref="durationHour"
              name="durationHour"
              class="duration-input form-input"
              type="number"
              :min="getMinDuration('durationHour')"
              :max="getMaxDuration('durationHour')"
              :value="duration.hour"
              :aria-label="$t('createDetails.trackHour')"
              @input="onHourUpdate"
            />
            {{ $t('createDetails.trackHour') }}
            <input
              @keydown="preventNonNumeric"
              ref="durationMinute"
              name="durationMinute"
              class="duration-input form-input"
              type="number"
              :min="getMinDuration('durationMinute')"
              :max="getMaxDuration('durationMinute')"
              :value="duration.minute"
              :aria-label="$t('createDetails.trackMinutes')"
              @input="onMinuteUpdate"
            />
            {{ $t('createDetails.trackMinutes') }}
          </div>
          <div :style="{ visibility: msg.durations ? 'visible' : 'hidden' }" class="t-text-xs edit-details-error-messsage">
            {{ msg.durations ? msg.durations : '' }}
          </div>
        </div>
        <div class="t-mt-8" v-if="dataReady">
          <p class="t-text-sm t-font-bold t-text-dark-grey mb-1">
            {{ $t('createDetails.contentText') }}
            <span class="typeahead-tooltip">
              <img src="@/assets/images/svgs/icon-info.svg" class="info-icon" alt="Info" />
              <span class="typeahead-tooltiptext">{{ $t('createDetails.contentToolTip') }}</span>
            </span>
          </p>
          <div class="catalog-search-filter-container">
            <CatalogSearchFilter @catalog-filter-applied="applyFilters" :selectedEditFilters="displaySelectedFilters" :totalResults="totalResults" :filterResults="filterResults" />
          </div>
        </div>
        <div class="create-catalog-chips" v-if="displaySelectedFilters.length || totalResults">
          <!-- <div class="catalog-details-search-total-results">{{ numberWithCommas(totalResults) }} {{ $tc('common.result', totalResults) }}</div> -->
          <div v-if="displaySelectedFilters.length">
            <span v-for="(item, index) in displaySelectedFilters" :key="index + item.name" id="chips-container">
              <v-chip in class="chip t-text-xs ma-1 t-bg-black" closable @click:close="onRemoveFilter(item)"> {{ item.type === 'Language' || item.type === 'Content Type' || item.type === 'Level' || item.type === 'Duration' ? item.display : item.name }} </v-chip>
            </span>
            <span class="clear-all-filters" @click="onClearFilters">{{ $t('common.clearAllFilters') }}</span>
          </div>
        </div>
      </div>
      <div class="t-p-4 t-rounded" style="background: var(--Colors-Primary-Gainsboro, #f5f5f5)">
        <p class="t-mb-0 t-text-dark-grey">
          {{ $t('createDetails.trackFooterText') }} <a href="javascript:void(0);" @click="goToTerms()">{{ $t('createDetails.trackFooterProedge') }}</a> {{ $t('createDetails.trackFooterText2') }}
        </p>
      </div>
    </div>
    <TrackSaveDialog :trackSaveDialog="trackSaveDialog" :trackPopupDetails="trackCreationFail" @cancel-navigation="cancelNavigation()" @saved-track="tryAgain()" />
    <SaveDialog :saveDialog="showSaveDialog" :disableSaveButton="showSaveButton" @cancel-and-navigate="cancelAndNavigate()" @cancel-navigation="cancelNavigation()" />
  </div>
</template>

<script>
import SaveDialog from './SaveDialog.vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import en_json from '@/locales/en-us.json';
import { mapDurations } from '@/utils';
import { translate } from '@/plugins/i18n.js';
import TrackSaveDialog from './TrackSaveDialog.vue';
import CatalogSearchFilter from '@/views/catalog/components/CatalogSearchFilter';
const initialState = () => ({
  dataReady: false,
  hideLoading: true,
  trackName: '',
  trackDescription: '',
  query: '',
  foundSkills: [],
  chosenSkills: [],
  autoCompleteList: [],
  initialSkills: [],
  msg: [],
  showAutocomplete: true,
  showSaveDialog: false,
  showSaveButton: false,
  isActivated: false,
  skillActivated: false,
  trackSkills: '',
  isEdited: false,
  toPath: '',
  filterResults: {},
  totalResults: 0,
  displaySelectedFilters: [],
  searchText: '',
  currentPage: 1,
  fileUploadIndex: 0,
  editImageSrc: '',
  showPreviewImg: false,
  imageSelected: '',
  image: '',
  fileError: '',
  specifiedCriteria: '',
  duration: {
    hour: 10,
    minute: '00'
  },
  learningTrackItems: [],
  trackCreated: {
    save: false,
    cancel: false
  },
  trackCreationFail: {
    title: translate('confirmation.failTrackPreview.message'),
    message: translate('confirmation.failTrackPreview.details'),
    okay: 'Okay'
  },
  trackSaveDialog: false,
  totalDuration: 0
});

export default {
  name: 'CreateEditTrack',
  emits: ['catalog-search-cleared'],
  components: {
    SaveDialog,
    CatalogSearchFilter,
    TrackSaveDialog
  },
  data() {
    return initialState();
  },
  mounted() {
    Object.assign(this.$data, initialState());
    this.$nextTick(async () => {
      this.isActivated = true;
    });
    this.trackGuid || this.reCreateTrack ? '' : this.selectImage();
    if (this.trackGuid) this.getEditTrack();
    else if (this.reCreateTrack) this.getReCreatedTrackDetails();
    this.EventBus.on('clear-data', this.orgClearData);
    this.refreshStepperState();
    if (!Object.keys(this.EventBus.all).includes('save-track-edit-details')) this.EventBus.on('save-track-edit-details', this.createTrack);
  },
  created() {
    this.setSaveButtonState(false);
    window.addEventListener('resize', this.setTooltip);
  },
  destroyed() {
    window.addEventListener('resize', this.setTooltip);
  },
  watch: {
    query() {
      if (this.autoCompleteList.length) {
        const wrapper = document.getElementsByClassName('scrollable-menu')[0];
        wrapper?.scrollTop && wrapper.scrollTo({ top: 0, behavior: 'smooth' });
      }
      if (this.skillActivated) {
        this.validateFormInput('skills', this.chosenSkills);
      }
    },
    trackName(value) {
      if (this.isActivated) {
        this.validateFormInput('name', value);
        this.setTrackName(value);
      }
    },
    trackDescription(value) {
      if (this.isActivated) {
        this.validateFormInput('description', value);
        this.setTrackDescription(value);
      }
    },
    '$i18n.locale'() {
      this.refreshStepperState();
    }
  },
  computed: {
    ...mapGetters(['tenantAccessTags', 'enabledFeatureFlags']),
    ...mapState('track', ['reCreateTrack', 'roleNameTrack', 'roleGuid', 'trackGuid', 'trackList', 'trackDetails', 'trackSaveButtonState', 'trackUnsavedChanges']),
    ...mapState('stepper', ['tabs']),
    customImgSrc() {
      return this.editImageSrc?.name === 'default-thumbnail' ? require('@/assets/images/icons/thumbnail-default-track.png') : this.editImageSrc;
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.validateFields() && !this.isEdited) {
      this.showSaveDialog = true;
      this.toPath = to.path;
      next(false);
    } else {
      this.EventBus.off('save-track-edit-details');
      if (to.path !== '/catalog/stepper/organize') {
        this.orgClearData();
      }
      this.clearData();
      next();
    }
  },

  methods: {
    translate,
    ...mapActions('track', ['setTotalDuration', 'setTotalTrackItems', 'setTrackList', 'setTrackDetails', 'setTrackGuid', 'setTrackName', 'setSaveExitButton', 'setTrackDescription', 'resetTrackList', 'resetTrackDetails', 'setSaveButtonState', 'setUnsavedChangesState']),
    ...mapActions('stepper', ['setStepperState', 'resetStepperState', 'refreshStepperState']),
    showResponseMessage(response) {
      this.$toasted.clear();
      if (response.code == 200 || response.length > 0) {
        this.trackSaveDialog = false;
        this.showToast(translate('createDetails.successSaveDraftToastMessage'), 'success', 3000, false);
      } else if (response?.length == 0) {
        this.trackSaveDialog = true;
      } else {
        this.trackSaveDialog = false;
        this.showToast(translate('createDetails.failureSaveDraftToastMessage'), 'error', 3000, false);
        this.setSaveButtonState(false);
        this.setUnsavedChangesState(true);
      }
    },
    getSkillLimit() {
      return this.chosenSkills.length > 2 ? true : false;
    },
    async getFilterData() {
      try {
        this.hideLoading = false;
        let payload = await this.getPayloadData();
        const payloadData = {
          function_tags: payload.accessTags,
          pageno: payload.pageno,
          pagesize: payload.pagesize,
          query: payload.query,
          durations: payload.durations,
          publishers: payload.publishers,
          levels: payload.levels,
          content_types: payload.content_types,
          accreditations: payload.accreditations,
          skills: payload.skills,
          tools: payload.tools,
          languages: payload.languages,
          contentFeature: payload.contentFeature,
          sortby: 'newest'
          // sortby: 'bestmatch'
        };
        let response = [];
        if (this.enabledFeatureFlags && this.enabledFeatureFlags['plan_create_track_recommended_catalog_api']) {
          response = await this.$planAdmin.searchRecommendedPlan(payloadData);
        } else {
          response = await this.$planAdmin.searchCCCPlan(payloadData);
        }
        if (response) {
          document.getElementById('trackContentDisplay').style.display = 'block';
          if ({}.hasOwnProperty.call(response.filterValuesMap, 'skillFilterValues')) response.filterValuesMap.skillFilterValues = [];
          this.filterResults = response.filterValuesMap;
          this.totalResults = response.totalResults;
          this.dataReady = true;
        }
        this.hideLoading = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getPayloadData() {
      try {
        const durations = [];
        const publishers = [];
        const levels = [];
        const accreditations = [];
        const skills = this.chosenSkills.map((skill) => skill.title);
        const skillName = [];
        const content_types = [];
        const tools = [];
        const languages = [];
        const contentFeature = [];

        for (let filter of this.displaySelectedFilters) {
          switch (filter.key) {
            case 'durations':
              durations.push(Object.keys(mapDurations).find((key) => mapDurations[key] === filter.name));
              break;
            case 'publishers':
              publishers.push(filter.name);
              break;
            case 'levels':
              levels.push(filter.name);
              break;
            case 'accreditations':
              accreditations.push(filter.name);
              break;
            case 'skills':
              skills.push(filter.name);
              skillName.push(filter.name);
              break;
            case 'content_types':
              content_types.push(filter.name);
              break;
            case 'tools':
              tools.push(filter.name);
              break;
            case 'languages':
              languages.push(filter.name);
              break;
            case 'contentFeature':
              contentFeature.push(filter.name);
              break;
            default:
              break;
          }
        }
        const payload = {
          accessTags: this.tenantAccessTags,
          pageno: this.currentPage,
          pagesize: 10,
          query: encodeURIComponent(this.searchText),
          durations,
          publishers,
          levels,
          content_types,
          accreditations,
          skills,
          skillName,
          tools,
          languages,
          contentFeature,
          sortby: 'newest'
          // sortby: 'bestmatch'
        };
        return payload;
      } catch (error) {
        console.log(error);
      }
    },
    formatDuration(duration) {
      let durationInMinutes = 0;
      let durationInHours = 0;
      if (duration < 3600) durationInMinutes = Math.floor(duration / 60);
      else durationInHours = Math.floor(duration / 3600);
      durationInMinutes = Math.floor((duration - Math.floor(duration / 3600) * 3600) / 60);
      return {
        durationInHours,
        durationInMinutes
      };
    },
    async getTrackDetailsData() {
      let payload = await this.getPayloadData();
      const learning_Items_save = this.learningTrackItems.map((item) => {
        return {
          itemId: item?.id,
          name: item?.title,
          format: item?.format?.name.toUpperCase(),
          duration: { value: item.duration, In: item.durationMinutes }
        };
      });
      let Obj = {};
      let payoadImgName = this.image?.name?.replace(/\s+/g, '');
      Obj.image = this.image;
      Obj.editImageSrc = this.editImageSrc;
      Obj.payoadImgName = payoadImgName;
      Obj.description = this.trackDescription;
      Obj.name = this.trackName;
      Obj.requestedDuration = parseInt(this.duration?.hour * 60) + parseInt(this.duration?.minute || 0);
      Obj.languageFilter = payload.languages;
      Obj.durationFilters = payload.durations;
      Obj.technologyFilters = payload.tools;
      Obj.providerFilters = payload.publishers;
      Obj.levelFilters = payload.levels;
      Obj.contentTypes = payload.content_types;
      Obj.accreditations = payload.accreditations;
      Obj.requestedSkills = payload.skillName;
      Obj.contentFeature = payload.contentFeature;
      Obj.publishers = payload.publishers;
      Obj.skills = this.chosenSkills.map((a) => a.id);
      Obj.skillName = payload.skills;
      Obj.items = learning_Items_save;
      Obj.displaySelectedFilters = this.displaySelectedFilters;
      Obj.hour = parseInt(this.duration?.hour || 0);
      Obj.mins = parseInt(this.duration?.minute || 0);
      Obj.skillIdTitle = this.chosenSkills;
      return Obj;
    },
    async getEditTrack() {
      this.hideLoading = true;
      document.getElementById('userSelectionDisplay').style.display = 'none';
      this.setSaveButtonState(true);
      let response = await this.$planAdmin.getTrackDetails(this.trackGuid);
      response = response.data;
      this.trackName = response?.name;
      this.trackDescription = response?.description;
      if (response.requestedDuration > 0) {
        document.getElementById('skillOptionDisplay').style.display = 'none';
        document.getElementById('skillDisplay').style.display = 'block';
        this.specifiedCriteria = 1;
      } else if (response.requestedDuration < 1 && response.skills.length > 0) {
        document.getElementById('skillDisplay').style.display = 'none';
        document.getElementById('skillOptionDisplay').style.display = 'block';
        this.specifiedCriteria = 2;
      } else {
        document.getElementById('skillDisplay').style.display = 'none';
        document.getElementById('skillOptionDisplay').style.display = 'none';
        this.specifiedCriteria = 2;
      }
      this.editImageSrc = response?.image?.signedRelativeUri;
      if (this.editImageSrc) {
        this.showPreviewImg = true;
        this.imageSelected = 'user';
      }
      if (response.skills.length > 0) {
        const editTrackPayload = {
          trackGuid: this.trackGuid
        };
        const skillResponse = await this.$planAdmin.getEditTrackDetails(editTrackPayload);
        response.skills = skillResponse.skills;
        if (this.roleGuid) {
          const skills = await this.$planAdmin.getAllSkillsForRole({ guid: this.roleGuid });
          this.trackSkills = skills.map(({ name }) => name).join(', ');
        } else {
          response.skills?.forEach((skill) => {
            this.chosenSkills.push({ title: skill.name, id: skill.guid });
          });
          this.initialSkills = [...this.chosenSkills];
          this.validateFormInput('skills', this.chosenSkills);
        }
      }
    },
    async getReCreatedTrackDetails() {
      this.specifiedCriteria = 1;
      this.setSaveButtonState(true);
      this.isEdited = false;
      this.hideLoading = true;
      this.dataReady = true;
      document.getElementById('skillOptionDisplay').style.display = 'none';
      document.getElementById('skillDisplay').style.display = 'block';
      if ({}.hasOwnProperty.call(this.trackDetails, 'skills')) {
        this.trackName = this.trackDetails.name;
        this.trackDescription = this.trackDetails.description;
        this.image = this.trackDetails.image;
        this.editImageSrc = this.trackDetails.editImageSrc || this.trackDetails.image;
        if (this.editImageSrc) {
          this.showPreviewImg = true;
          this.imageSelected = 'user';
        }
        this.trackDetails?.skillIdTitle?.forEach((skill) => {
          this.chosenSkills.push({ title: skill.title, id: skill.id });
        });

        this.initialSkills = [...this.chosenSkills];
        this.duration.hour = parseInt(this.trackDetails?.hour || 0);
        this.duration.minute = parseInt(this.trackDetails?.mins || 0);
        this.displaySelectedFilters = this.trackDetails?.displaySelectedFilters;
        await this.getFilterData();
        document.getElementById('trackContentDisplay').style.display = 'block';
      }
    },
    async createTrack() {
      if (!this.trackCreated.cancel) this.showToast(translate('createDetails.inProgressSaveDraftToastMessage'), 'info', null, true);
      try {
        let response;
        this.trackCreated.cancel = true;
        let navigationDirection = false;
        if (!this.trackCreated.save) {
          if (this.specifiedCriteria == 2 || this.trackGuid) {
            navigationDirection = this.trackGuid ? true : false;
            const formData = new FormData();
            if (this.image) {
              let payoadImgName = this.image?.name?.replace(/\s+/g, '');
              formData.append('image', this.image, payoadImgName);
            }
            formData.set('description', this.trackDescription);
            formData.set('name', this.trackName);
            formData.set(
              'skills',
              this.chosenSkills.map((skill) => skill.id)
            );
            const editTrackPayload = {
              trackGuid: this.trackGuid,
              body: formData
            };
            response = this.trackGuid ? await this.$planAdmin.editCreatedTrack(editTrackPayload) : await this.$planAdmin.createTrack(formData);
            await Promise.all([this.setTrackGuid(response?.data?.guid), this.setSaveExitButton(false), this.setTotalTrackItems(response.data?.size)]);
          } else {
            this.learningTrackItems = [];
            let payload = await this.getPayloadData();
            const payloadData = {
              durations: payload.durations,
              publishers: payload.publishers,
              levels: payload.levels,
              content_types: payload.content_types,
              accreditations: payload.accreditations,
              skills: payload.skills,
              technologies: payload.tools,
              languages: payload.languages,
              contentFeature: payload.contentFeature,
              sortby: 'newest',
              total_duration: parseInt(this.duration.hour * 60) + parseInt(this.duration.minute || 0)
            };
            response = await this.$planAdmin.recommendLearningTrack(payloadData);
            this.learningTrackItems = response.length > 0 ? response : [];
            this.totalDuration = this.learningTrackItems?.reduce((previous, current) => {
              return previous + current.duration;
            }, 0);
            response = this.learningTrackItems?.map((data) => this.transformData(data));
            const trackDetailsData = await this.getTrackDetailsData();
            await Promise.all([this.setTotalDuration(this.totalDuration), this.setTrackList(response), this.setTrackDetails(trackDetailsData), this.setSaveExitButton(true)]);
          }
          this.showResponseMessage(response);
          if (response.length > 0 || response.code === 200) {
            this.trackCreated.save = true;
            await Promise.all([this.setTrackName(this.trackName)]);
            this.completeStep();
            this.isEdited = true;
            this.initialSkills = this.chosenSkills;
            if (!navigationDirection) {
              this.specifiedCriteria == 2 ? this.$router.push('/catalog/stepper/edit') : this.$router.push({ name: 'OrganizeLearningTrack', query: { createTrack: true } });
            } else {
              this.$router.push('/catalog/stepper/edit');
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async removeSkill(skill) {
      const index = this.chosenSkills.findIndex((obj) => obj.title === skill.title);
      if (skill.autoComplete) {
        this.chosenSkills.splice(index, 1);
      } else {
        this.foundSkills.push(this.chosenSkills[index]);
        this.chosenSkills.splice(index, 1);
      }
      this.skillActivated = true;
      this.validateFormInput('skills', this.chosenSkills);
      if (!this.trackGuid && this.specifiedCriteria === 1) await this.getFilterData();
    },
    transformData(data) {
      let Obj = {};
      Obj.id = data.id;
      Obj.formatType = data.format && data.format.name ? data.format.name : 'credential';
      Obj.title = data?.name || data?.title || '';
      Obj.synopsis = data.synopsis ? data.synopsis : '';
      Obj.duration = data.duration;
      Obj.durationMinutes = data.durationMinutes ? data.durationMinutes : 0;
      Obj.publishedAt = data.publishedAt;
      Obj.skills = data.emsiSkills ? data.emsiSkills && data.emsiSkills.join(', ') : '';
      Obj.accreditation = data.accreditation ? data.accreditation : [];
      Obj.fieldOfStudy = data.fieldOfStudy ? data.fieldOfStudy.join(', ') : [];
      Obj.subCategory = data.subCategory ? data.subCategory : '';
      Obj.vdiRequired = data.vdiEnabled !== null ? data.vdiEnabled : '';
      Obj.skillBuilder = data.content && data.content.skillBuilders ? data.content.skillBuilders : '';
      Obj.capstone = data.content && data.content.capstone ? data.content.capstone : '';
      Obj.provider = data?.provider?.name || 'N/A';
      Obj.levels = data?.levels && data?.levels?.length && data?.levels[0]?.length ? data?.levels[0] : 'N/A';
      Obj.rating = data?.ratingReviews?.avgRating || 0;
      Obj.numOfRatings = data?.ratingReviews?.numOfRatings || 0;
      Obj.trackGuid = data.guid || '';
      Obj.totalItemsInTrack = data.totalItemsInTrack || 0;
      Obj.roleGuid = data.roleGuid;
      Obj.roleName = data.roleName;
      Obj.contentFeature = data.contentFeature ? data.contentFeature : [];
      return Obj;
    },
    validateFormInput(type, value) {
      if (type === 'name') {
        if (value.length) {
          this.msg['trackName'] = '';
        } else {
          this.msg['trackName'] = translate('createDetails.trackNameErrorMessage');
        }
      }
      if (type === 'description') {
        if (value.length) {
          this.msg['trackDescription'] = '';
        } else {
          this.msg['trackDescription'] = translate('createDetails.trackDescriptionErrorMessage');
        }
      }
      if (type === 'durations' || this.duration?.hour || this.duration?.minute) {
        const totalDuration =
          parseInt(this.duration?.hour == '' ? 0 : this.duration?.hour * 60) + parseInt(this.duration?.minute == '' ? 0 : this.duration?.minute) >= 30 &&
          parseInt(this.duration?.hour == '' ? 0 : this.duration?.hour * 60) + parseInt(this.duration?.minute == '' ? 0 : this.duration?.minute) <= 2400
            ? true
            : false;
        if (totalDuration) {
          this.msg['durations'] = '';
        } else {
          this.msg['durations'] = translate('createDetails.errorDuration');
        }
      }
      if (type === 'skills') {
        if (value.length) {
          this.msg['query'] = '';
        } else {
          this.msg['query'] = translate('createDetails.errorSkill');
        }
      }
      this.validateFields() ? this.setSaveButtonState(true) : this.setSaveButtonState(false);
    },
    validateFields() {
      if (this.specifiedCriteria == 2) {
        return !!this.trackName && !!this.trackDescription;
      } else if (this.specifiedCriteria == 1) {
        return (
          (this.msg['durations'] === '' || parseInt(this.duration.hour) || parseInt(this.duration.minute)) &&
          (this.msg['query'] === '' || this.chosenSkills.length > 0) &&
          (this.msg['trackDescription'] === '' || this.trackDescription) &&
          (this.msg['trackName'] === '' || this.trackName)
        );
      } else {
        return false;
      }
    },
    cancelAndNavigate() {
      this.showSaveDialog = false;
      this.isEdited = true;
      this.chosenSkills = this.initialSkills;
      this.setUnsavedChangesState(false);
      this.$router.push({ path: this.toPath });
    },
    cancelNavigation() {
      this.showSaveDialog = false;
      this.trackSaveDialog = false;
      this.toPath = '';
    },
    tryAgain() {
      this.showSaveDialog = false;
      this.trackSaveDialog = false;
      this.toPath = '';
    },

    completeStep() {
      const step = this.trackName && this.trackDescription ? 'step-complete' : 'step-incomplete';
      this.EventBus.emit(step, this.$route.path);
    },
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };
      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });

      this.$toasted.show(msg, options);
    },
    onClickOutside() {
      if (this.showAutocomplete) {
        this.showAutocomplete = false;
      }
    },
    onRemoveFilter(item) {
      this.EventBus.emit('catalog-remove-filter', item);
    },
    goToTerms() {
      window.open('https://productcentral.products.pwc.com/legal/Terms-of-Use', '_blank');
    },
    clearData() {
      this.totalDuration = 0;
    },
    orgClearData() {
      this.totalDuration = 0;
      this.setTrackName('');
      this.resetTrackList();
      this.resetTrackDetails();
    },
    async onClearFilters() {
      this.displaySelectedFilters = [];
      const trackDetailsData = await this.getTrackDetailsData();
      await Promise.all([this.setTrackDetails(trackDetailsData)]);
      this.EventBus.emit('catalog-search-cleared');
    },
    userSelection(e) {
      const value = parseInt(e.target.value);
      this.query = '';
      this.foundSkills = [];
      this.chosenSkills = [];
      this.autoCompleteList = [];
      if (value === 1) {
        document.getElementById('skillOptionDisplay').style.display = 'none';
        document.getElementById('skillDisplay').style.display = 'block';
      } else {
        document.getElementById('skillDisplay').style.display = 'none';
        document.getElementById('trackContentDisplay').style.display = 'none';
        document.getElementById('skillOptionDisplay').style.display = 'block';
      }
      this.specifiedCriteria = value;
      this.validateFormInput();
    },
    getMaxDuration(name) {
      if (name === 'durationHour') {
        return 40 - Math.ceil(this.duration.minute / 60);
      } else if (name === 'durationMinute') {
        return 2400 - this.duration.hour * 60;
      }
    },
    getMinDuration(name) {
      if (name === 'durationHour') {
        return this.duration.minute == 0 ? 1 : this.duration.minute >= 30 ? 0 : 1;
      } else if (name === 'durationMinute') {
        return this.duration.hour >= 1 ? 0 : this.duration.hour == 0 ? 30 : 0;
      }
    },
    preventNonNumeric(event) {
      const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
      if (!allowedKeys.includes(event.key) && !/[0-9]/.test(event.key)) {
        event.preventDefault();
      }
    },
    onHourUpdate($event) {
      const value = $event.target.value;
      this.duration.hour = value.replace(/[^0-9]/g, '');
      this.validateFormInput('durations');
    },
    onMinuteUpdate($event) {
      const value = $event.target.value;
      this.duration.minute = value.replace(/[^0-9]/g, '');
      this.validateFormInput('durations');
    },
    openImageUploadPopup() {
      document.getElementById('fileinput').click();
    },
    async selectImage() {
      this.fileUploadIndex++;
      this.editImageSrc = '';
      this.showPreviewImg = false;
      this.imageSelected = 'default';
      this.image = '';
      this.fileError = '';
      this.image = await this.getFileFromUrl(this.$refs.defaultImg.src, 'default-thumbnail');
    },
    async getFileFromUrl(url, name, defaultType = 'image/jpeg') {
      const response = await fetch(url);
      const data = await response.blob();
      return new File([data], name, {
        type: data.type || defaultType
      });
    },
    handleClick(event) {
      const files = event.target.files;
      if (!files.length) return;
      this.uploadFile(files[0]);
    },
    async uploadFile(fileObj) {
      const fileSize = Math.floor(fileObj.size / (1024 * 1024), 2);
      if (fileSize > this.maxFileSize) {
        this.fileError = 'Error: File cannot exceed 10MB';
        this.fileUploadIndex++;
        this.editImageSrc = '';
        this.showPreviewImg = false;
      } else if (['image/png'].includes(fileObj.type)) {
        this.showPreviewImg = true;
        this.fileError = '';
        this.image = fileObj;
        var reader = new FileReader();
        reader.onload = () => {
          var output = this.$refs.previewImage;
          output.src = reader.result;
          this.editImageSrc = output.src;
        };
        reader.readAsDataURL(fileObj);
        this.imageSelected = 'user';
      }
    },
    handleDrop(event) {
      event.stopPropagation();
      event.preventDefault();
      const files = event.dataTransfer.files;
      if (!files.length) return;
      this.uploadFile(files[0]);
      event.stopPropagation();
      event.preventDefault();
    },
    handleDragover(event) {
      event.stopPropagation();
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
    },
    setTooltip() {
      setTimeout(() => {
        const left = document.getElementsByClassName('edit-details-search-text-label')[0]?.offsetLeft;
        const tooltip = document.getElementsByClassName('skills-tooltip')[0];
        tooltip && tooltip.style && left ? (tooltip.style.left = left + 'px') : void 0;
      }, 50);
    },

    async applyFilters(displaySelectedFilters) {
      this.displaySelectedFilters = displaySelectedFilters.map((filter) => (filter.type === 'Language' ? { ...filter, display: en_json.languagePicker && en_json.languagePicker[filter.name] ? en_json.languagePicker[filter.name] : filter.name } : filter));
      this.currentPage = 1;
      await this.getFilterData();
    },
    async handleAutocomplete(event) {
      if (event.key === 'Enter') {
        return;
      }
      this.showAutocomplete = true;
      const query = event.target && event.target.value ? event.target.value : '';
      const response = await this.$planAdmin.searchSkillByName({ skill: encodeURIComponent(query) });

      for (let skill of this.chosenSkills) {
        for (let index in response) {
          const item = response[index];
          item.id === skill.id && response.splice(index, 1);
        }
      }

      const regex = new RegExp(this.query?.replaceAll('\\', '\\\\').replaceAll('*', '\\*').replaceAll('(', '\\(').replaceAll(')', '\\)').replaceAll('[', '\\[').replaceAll('+', '\\+'), 'gi');
      if (Array.isArray(response))
        response.forEach((item) => {
          item.queryText = item.title;
          item.title = item.title.replace(regex, (str) => {
            return '<span style="font-size:1rem;font-weight:normal; font-family: Arial; color: #5C5C5C;">' + str + '</span>';
          });
        });

      this.scrollList();
      this.autoCompleteList = response;
    },
    async handleSearchInput(searchTerm) {
      this.showAutocomplete = false;
      this.autoCompleteList = [];
      const response = await this.$planAdmin.getSkillDescription({ skill: encodeURIComponent(searchTerm) });
      this.query = '';
      if (response.name && response.guid) {
        this.chosenSkills.push({ title: response.name, id: response.guid, autoComplete: true });
        if (this.specifiedCriteria === 1) await this.getFilterData();
        this.skillActivated = false;
        this.validateFormInput('skills', this.chosenSkills);
        this.getSkillLimit();
      }
    },
    scrollList() {
      const first = document.getElementById('list')?.firstChild;
      const maininput = document.getElementById('input');

      document.onkeydown = (e) => {
        switch (e.key) {
          case 'Enter': {
            document.activeElement?.click();
            break;
          }
          case 'ArrowUp':
            if (document.activeElement == (maininput || first)) {
              break;
            } else {
              maininput.value = document.activeElement?.previousSibling?.textContent ? document.activeElement?.previousSibling?.textContent : document.activeElement.textContent;
              document?.activeElement?.previousSibling?.focus();
            }
            break;
          case 'ArrowDown': {
            e.preventDefault();
            if (document.activeElement == maininput) {
              first.firstChild.focus();
            } else {
              maininput.value = document.activeElement?.nextSibling?.textContent ? document.activeElement?.nextSibling?.textContent : document.activeElement.textContent;
              document.activeElement?.nextSibling?.focus();
            }
            break;
          }
        }
      };
    },
    handleSearchClear() {
      this.autoCompleteList = [];
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.createDetails')
    };
  }
};
</script>
<style lang="scss">
.create-user-notify {
  height: 80px;
  .v-label {
    opacity: 1 !important;
    color: #2d2d2d !important;
    font-size: 0.875rem;
  }
  .v-input--selection-controls .v-radio > .v-label {
    margin-top: 1rem;
  }
  .v-selection-control__wrapper {
    margin-right: 15px;
    width: 20px;
  }
  .v-input--selection-controls__input .v-icon {
    font-size: 18px;
    color: #dedfdf;
  }
}
</style>
<style lang="scss" scoped>
.catalog-search-filter-container {
  display: grid;
  grid-template-columns: 10fr 2fr;
}
.form-input {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e0e1e1;
  border-radius: 4px;
  padding: 0.5em;
}
.duration-input {
  height: 41px;
  width: 64px;
  margin-right: 0.3em;

  &:nth-child(2) {
    margin-left: 1em;
  }
}
.info-icon {
  width: 1.2em;
  height: 1.2em;
  vertical-align: text-top;
  cursor: pointer;
}
.typeahead-tooltip {
  position: relative;
  display: inline-block;
}

.typeahead-tooltip .typeahead-tooltiptext {
  text-align: center;
  color: #2d2d2d;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000001f;
  border-radius: 4px;
  visibility: hidden;
  position: absolute;
  z-index: 1;
  opacity: 0;
  white-space: nowrap;
  padding: 8px 16px;
  font-size: 12px;
  font-weight: 400;
}

.typeahead-tooltip:hover .typeahead-tooltiptext {
  visibility: visible;
  opacity: 1;
}
.row {
  margin: unset;
}
.imgBg {
  background-repeat: no-repeat;
  width: 164px;
  height: 164px;
  cursor: pointer;
  background-image: url('~@/assets/images/icons/thumbnail-track.png');
  background-size: contain;
  text-align: center;
}
.scrollable-menu {
  padding-right: 30px;
  max-height: 200px;
  overflow-y: scroll;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    margin-top: 16px;
    margin-bottom: 32px;
    background: #d8d8d8;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4c5c8d;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #4c5c8d;
  }
}

.skills-tooltip {
  min-width: 31.25rem;
  max-width: 50rem;
  background: $color-grey-light !important;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  opacity: 1 !important;
  padding: 1.5625rem 2.8125rem;
  span {
    color: $color-black;
  }
}
.loading-container {
  display: flex;
  gap: 8px;
  svg-icon {
    width: 22px !important;
    height: 22px !important;
  }
  .loading-label {
    @include body-font-0-875;
    color: #2d2d2d;
  }
}
.edit-details-container {
  margin-bottom: 3rem;
  .edit-details-form {
    border-radius: 24px;
    padding: 48px;
    gap: 32px;
    background: #fff 0 0 no-repeat padding-box;
    max-width: 1118px;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;

    margin: 0.5rem auto 0;
    @media screen and (max-width: 1264px) {
      padding: 32px;
      gap: 24px;
    }

    a {
      color: #1f3370;
      text-decoration: none;
    }

    .default-image {
      width: 164px;
      align-items: center;
      border-radius: var(--corner-radius-radius-lg, 24px);
      &.default-track {
        border: 2px solid var(--border-border-dark-grey, #2d2d2d);
        display: flex;
      }
      .image-border {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--corner-radius-radius-md, 16px);
        border: 2px solid var(--border-border-gainsboro, #f5f5f5);
        cursor: pointer;
        width: 148px;
        height: 148px;
      }
    }
    #edit-details-track-name,
    #edit-details-track-description {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e0e1e1;
      border-radius: 4px;
      width: 100%;
      height: 47px;
      padding: 1rem;
      resize: none;
    }
    #edit-details-track-description {
      height: 130px;
    }
    input::placeholder,
    textarea::placeholder {
      font: normal normal normal 1rem/1.375rem Arial;
      letter-spacing: 0.0006rem;
      color: #9f9f9f;
    }
    .v-text-field--filled.v-input--dense.v-text-field--single-line .v-label,
    .v-text-field--full-width.v-input--dense.v-text-field--single-line .v-label {
      top: unset;
    }
    .edit-details-error-messsage {
      color: #e0301e;
    }

    .edit-details-search-text-label {
      text-align: left;
      font: normal normal normal 28px/24px 'Futura PT Demi';
      letter-spacing: 0.01px;
      color: #000000;
      margin: 2rem 0rem;
      span {
        text-align: left;
        font: normal normal normal 28px/24px 'Futura PT Demi';
        letter-spacing: 0.01px;
        color: #000000;
        margin: 2rem 0rem;
      }
      .skills {
        font-size: 0;
      }
    }

    .edit-details-search-text-container {
      position: relative;
      margin-top: 10px;
      .edit-details-search-text-field {
        margin-bottom: 0.5rem;
        min-height: 2.625rem !important;
        width: 468px;

        .v-label {
          font-weight: normal;
          font-family: Arial;
          font-size: 0.875rem;
          color: black;
        }
        .v-field--variant-solo {
          background-color: white !important;
          height: 2.3125rem;
          border: 0.125rem solid #e0e1e1;
        }

        .v-field__field,
        .v-field__prepend-inner {
          height: 2.3125rem;
        }
        .v-field__input {
          min-height: unset;
          padding: 0rem;
          height: inherit;
          height: 2.3125rem;

          input {
            margin: 0px;
            &::placeholder {
              font: normal normal normal 1rem/1.375rem Arial;
              letter-spacing: 0.0006rem;
              color: #9f9f9f;
              opacity: 1;
            }
          }
        }
      }
      .edit-details-search-text-field .v-input__slot {
        min-height: 2.625rem !important;
        width: 468px;
      }
      .edit-details-search-text-field .mdi-magnify {
        font-size: 1.725rem;
        margin-right: 0.725rem;
      }

      .edit-details-search-results {
        height: 200px;
        overflow: hidden;

        ul.scrollable-menu {
          padding-top: 0.625rem;
          margin-right: 1.25rem;
        }
      }

      .edit-details-search-result-container {
        margin: 0;

        .edit-details-search-result {
          padding: 0.5rem 1rem 0 0;
          display: inline-flex;

          .edit-details-search-x::before {
            content: '\d7';
            font-size: 1.5rem;
            display: flex;
            margin-left: 0.5rem;
          }
          .edit-details-search-pill {
            background: #bcc2d4 0% 0% no-repeat padding-box;
            border-radius: 15px;
            padding: 0.5rem 1rem;
            text-align: left;
            font: normal normal bold 11px/12px Arial;
            letter-spacing: 0px;
            color: #1f3370;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .v-input__slot,
      .v-btn--is-elevated {
        box-shadow: none !important;
        background: #fdfdfd 0% 0% no-repeat padding-box;
        border: 2px solid #e0e1e1;
        border-radius: 21px;
        backdrop-filter: blur(20px);
        -webkit-backdrop-filter: blur(20px);
      }
    }
  }
  .v-card.stepper-edit-details {
    height: 248px;
    max-height: 248px;
    overflow: auto;
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 13px !important;
    opacity: 1;
  }
  .create-catalog-chips {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 4px;
    gap: 13px;
    .clear-all-filters {
      text-align: left;
      font: normal normal normal 1rem/1.875rem Arial;
      letter-spacing: -0.0069rem;
      color: #1f3370;
      opacity: 1;
      cursor: pointer;
      display: inline-block;
    }
  }
}
</style>
