<template>
  <div class="usage-container">
    <ReportLastUpdatedHeader />

    <div class="usage-header-container">
      <div class="usage-header-title">
        <h1>{{ $t('insights.usage.usagePageHeader') }}</h1>
        <span>{{ $t('insights.usage.usagePageSubHeader') }}</span>
      </div>
      <div>
        <RadioFilter v-show="structureReady" :filter="timeFilter" :selectedOption="selectedTimeOption" :openDropdown="openDropdown" :onCancel="onCancel" :onApply="onApply" @onFilterChange="onTimeChange" />
        <div v-if="selectedTimeOptionString === 'CUSTOM'" class="dashboard-details-date-chips">
          <div id="chips-container">
            <v-chip in class="chip t-text-xs mt-4 t-bg-black" closable @click:close="onRemoveDateRange()"> {{ showSelectedFilter() }} </v-chip>
          </div>
        </div>
      </div>
    </div>

    <ActiveUserDonutChart :selectedTimeOptionString="selectedTimeOptionString" :dateRange="dateRange" />

    <div class="usage-trends-header-container">
      <h2>{{ $t('insights.usage.usageTrendsChartHeader') }}</h2>
      <span>{{ $t('insights.usage.usageTrendsChartSubHeader') }}</span>
    </div>

    <div class="usage-trends-chart-container">
      <UsageTrends :timeFilter="selectedTimeOptionString" :drillDownTableFilters="displaySelectedFilters" :searchInputText="searchTextForCSV" :dateRange="dateRange" />
    </div>

    <div class="usage-details-header-container">
      <div>
        <h2>{{ $t('insights.detailsTableHeader') }}</h2>
      </div>
      <UsageCSVDownload
        @downloadUsageParentCSV="downloadCSVPerUsageORG"
        :orgNameInFocus="orgNameInFocus"
        :orgInFocus="orgInFocus ? orgInFocus.id : 0"
        :timeFilter="selectedTimeOptionString"
        :drillDownTableFilters="displaySelectedFilters"
        :searchInputText="searchTextForCSV"
        :dateRange="dateRange"
      />
    </div>

    <div class="usage-details-table-header-actions" :style="[displaySelectedFilters.length && { 'padding-bottom': '0rem' }, isC1 && { 'margin-bottom': '1rem' }]" v-show="structureReady">
      <div v-if="isC1" class="usage-details-header-container">
        <h5>
          <!-- {{ $t('insights.usageDetailsTableSubHeader') }} <a href="javascript:void(0)" @click="$router.push('/workforce-planner')">{{ $t('insights.detailsTableSubHeaderAnchor') }}.</a> -->
        </h5>
      </div>

      <div v-else class="dashboard-details-table-search">
        <v-text-field
          class="details-search-text-field"
          v-model="searchText"
          @input="checkSearchLength"
          @keypress.enter="searchName"
          @click:clear="handleSearchClear"
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('common.searchByName')"
          filled
          rounded
          solo
          clearable
          single-line
          variant="solo"
        />
      </div>

      <InsightsDrillDowntableFilters :timeFilter="selectedTimeOptionString" @filter-applied="applyFilters" :filterResults="filterResults" @interface="getChildInterface" />
    </div>

    <div class="usage-details-search-chips" :style="displaySelectedFilters.length && 'margin-top: -1.5625rem'" v-show="displaySelectedFilters.length && structureReady">
      <div>
        <span v-for="(item, index) in displaySelectedFilters" :key="index" id="chips-container">
          <v-chip in class="chip t-text-xs ma-2 t-bg-black" closable @click:close="onRemoveFilter(item)"> {{ item.type === 'Status' ? item.display : item.name }} </v-chip>
        </span>
        <span class="clear-all-filters" @click="onClearFilters">{{ $t('common.clearAllFilters') }}</span>
      </div>
    </div>

    <div v-if="isC1">
      <UsageDrilldownTableC1
        :structureReady="structureReady"
        :treeViewReady="treeViewReady"
        :headers="headers"
        :structure="structure"
        :onGetPlan="onGetPlan"
        :currentEmployees="currentEmployees"
        :tableDataReady="tableDataReady"
        :totalPages="totalPages"
        :pageRange="pageRange"
        :handlePageClick="handlePageClick"
        :pageNum="pageNum"
        :employees="employees"
        @onPageChange="onPageChange"
        @downloadUsageParentCSV="downloadCSVPerUsageORG"
      />
    </div>
    <div v-else>
      <InsightsDrilldownTable
        :structureReady="structureReady"
        :treeViewReady="treeViewReady"
        :headers="headers"
        :structure="structure"
        :onGetPlan="onGetPlan"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        :currentEmployees="currentEmployees"
        :tableDataReady="tableDataReady"
        :totalPages="totalPages"
        :pageRange="pageRange"
        :handlePageClick="handlePageClick"
        :pageNum="pageNum"
        :employees="employees"
        :pageSize="pageSize"
        :pageNumberChangeEvent="pageNumberChangeEvent"
        :pageSizeChangeEvent="pageSizeChangeEvent"
        caller="usage"
        :totalItems="people.totalLearners"
        @on-data-table-change="onDataTableChange"
      />
    </div>
    <div class="usage-details-footer-container">
      <div class="usage-details-footer-text">{{ $t('insights.usage.footerText') }}</div>
      <div class="usage-details-footer-text">{{ $t('insights.usage.footerSubText') }}</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ReportLastUpdatedHeader from './components/ReportLastUpdatedHeader';
import RadioFilter from './components/RadioFilter.vue';
import localforage from 'localforage';
import UsageCSVDownload from './components/UsageCSVDownload.vue';
import InsightsDrillDowntableFilters from './components/InsightsDrillDowntableFilters.vue';
import InsightsDrilldownTable from './components/InsightsDrilldownTable.vue';
import UsageDrilldownTableC1 from './components/UsageDrilldownTableC1.vue';
import { translate } from '@/plugins/i18n.js';
import UsageTrends from './components/UsageTrends';
import ActiveUserDonutChart from './components/ActiveUserDonutChart';
import { getElementWidth, getDateForCSV, generateCSVTimeFilter } from '@/utils';

const initialState = () => ({
  isC1: null,
  timeFilter: { showExpandedFilter: false },
  selectedTimeOption: '',
  selectedTimeOptionString: '',
  displaySelectedFilters: [],
  structureReady: false,
  tableDataReady: false,
  searchText: '',
  clearSearchCross: false,
  pageNum: 1,
  orgInFocus: null,
  orgNameInFocus: 'All Orgs',
  searchTextForCSV: '',
  treeViewReady: true,
  structure: [],
  pageSize: 25,
  titles: [],
  roles: [],
  licenses: [],
  statusValues: [],
  headers: [],
  employees: [],
  filterResults: {},
  people: {},
  pageRange: 6,
  sortBy: [],
  sortDesc: false,
  totalPages: 0,
  dateRange: {
    startDate: '',
    endDate: ''
  },
  pageSizeChangeEvent: 'usage-page-size-change',
  pageNumberChangeEvent: 'usage-page-number-change',
  initialEmployees: []
});

export default {
  name: 'Usage',
  components: {
    ReportLastUpdatedHeader,
    RadioFilter,
    InsightsDrillDowntableFilters,
    InsightsDrilldownTable,
    UsageDrilldownTableC1,
    UsageTrends,
    ActiveUserDonutChart,
    UsageCSVDownload
  },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters(['enabledFeatureFlags'])
  },
  created() {
    this.EventBus.on(this.pageSizeChangeEvent, this.onPageSizeChange);
    this.EventBus.on(this.pageNumberChangeEvent, this.onPageNumberChange);
  },
  async mounted() {
    Object.assign(this.$data, initialState());
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
    if (this.isC1) {
      this.timeFilter = {
        options: [
          { id: 1, translationKey: 'insights.time.30Days', value: 'DAYS30' },
          { id: 2, translationKey: 'insights.time.90Days', value: 'DAYS90' },
          { id: 3, translationKey: 'insights.time.12Months', value: 'YEAR' }
        ],
        showExpandedFilter: false
      };
      this.selectedTimeOption = translate('insights.time.12Months');
      this.selectedTimeOptionString = 'YEAR';
    } else {
      this.timeFilter = {
        options: [
          { id: 1, translationKey: 'insights.time.allTime', value: 'ALLTIME' },
          { id: 2, translationKey: 'insights.time.30Days', value: 'DAYS30' },
          { id: 3, translationKey: 'insights.time.90Days', value: 'DAYS90' },
          { id: 4, translationKey: 'insights.time.12Months', value: 'YEAR' },
          { id: 5, translationKey: 'Date range', value: 'CUSTOM', startDate: '', endDate: '' }
        ],
        showExpandedFilter: false
      };
      this.selectedTimeOption = translate('insights.time.allTime');
      this.selectedTimeOptionString = 'ALLTIME';
    }
    document.addEventListener('click', this.closePopup);
    await this.fetchInitialData();
    await this.setupPagination();
  },
  async beforeMount() {
    document.addEventListener('click', this.closePopup);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closePopup);
  },
  watch: {
    searchText() {
      if (this.searchText) this.searchText = this.searchText.trim();
    },
    orgInFocus() {
      this.orgNameInFocus = this.orgInFocus?.name;
    },
    '$i18n.locale'() {
      this.setHeaders();
    }
  },
  childInterface: {
    onClearFilters: () => {},
    onRemoveFilter: () => {}
  },
  methods: {
    getElementWidth,
    getDateForCSV,
    generateCSVTimeFilter,
    async openDropdown() {
      this.timeFilter.showExpandedFilter = true;
    },
    closePopup(e) {
      if (this.timeFilter.showExpandedFilter && document.getElementById('time-filter-dropdown') && !document.getElementById('time-filter-dropdown').contains(e.target) && !e.target.parentNode.className.includes('mx') && !e.target.parentNode.className.includes('cell')) {
        this.onCancel();
      }
    },
    onCancel() {
      const selectedTimeOptionObject = this.timeFilter.options.find((o) => o.value === this.selectedTimeOptionString);
      this.selectedTimeOption = selectedTimeOptionObject.translationKey;
      this.timeFilter.showExpandedFilter = false;
    },
    async onApply() {
      const selectedTimeOptionObject = this.timeFilter.options.find((o) => translate(o.translationKey) === this.selectedTimeOption);
      this.selectedTimeOptionString = selectedTimeOptionObject.value;
      this.dateRange.startDate = this.selectedTimeOptionString === 'CUSTOM' ? this.moment(selectedTimeOptionObject.startDate).format('MM-DD-YYYY') : '';
      this.dateRange.endDate = this.selectedTimeOptionString === 'CUSTOM' ? this.moment(selectedTimeOptionObject.endDate).format('MM-DD-YYYY') : '';
      this.timeFilter.showExpandedFilter = false;

      this.structureReady = false;
      this.tableDataReady = false;

      this.clearSearchCross = true;
      this.searchText = '';
      this.orgInFocus = '';
      this.searchTextForCSV = '';

      await this.onClearFilters();
      this.structureReady = true;
      this.tableDataReady = true;
    },
    onRemoveDateRange() {
      this.dateRange.startDate = '';
      this.dateRange.endDate = '';
      this.selectedTimeOption = this.$t('insights.time.allTime');
      this.onApply();
    },
    showSelectedFilter() {
      const from = this.dateRange.startDate ? this.moment(this.dateRange.startDate).format('D MMM YYYY') : '';
      const to = this.dateRange.endDate ? this.moment(this.dateRange.endDate).format('D MMM YYYY') : '';
      return to && from ? `${from} - ${to}` : '';
    },
    onTimeChange(time) {
      this.selectedTimeOption = time;
    },
    checkSearchLength(val) {
      !val ? (this.clearSearchCross ? (this.clearSearchCross = false) : this.handleSearchClear()) : void 0;
    },
    async searchName(e) {
      if (!e.target.value.length) return;
      this.tableDataReady = false;
      this.pageNum = 1;
      this.orgInFocus = null;
      await this.resetTreeView();
      this.$options.childInterface.clearFilters();
      this.searchTextForCSV = this.searchText;
      await this.getAllEmployees(this.pageNum, this.searchText, true);
      await this.setup();
      this.tableDataReady = true;
    },
    async handleSearchClear() {
      this.clearSearchCross = true;
      this.pageNum = 1;
      this.searchText = '';
      this.$options.childInterface.clearFilters();
      this.orgInFocus = '';
      this.searchTextForCSV = '';
      await Promise.all([this.resetTreeView(), this.getAllEmployees(this.pageNum, '', true)]);
      await this.setup();
    },
    async resetTreeView() {
      this.treeViewReady = false;
      await this.getTreeData();
      this.treeViewReady = true;
    },
    async getTreeData() {
      const data = await this.$planAdmin.getRootOrgManagePeopleReporting();
      let startingStruct = data || [];
      this.orgData = {
        id: startingStruct[0]?.id || '',
        name: startingStruct[0]?.name || ''
      };
      this.addStatus(startingStruct, 1);
      this.structure = startingStruct;
    },
    addStatus(arr) {
      arr.forEach((i) => {
        i.downloadStatus = 'READY';
        if (i.children && i.children.length) {
          this.addStatus(i.children || []);
        }
      });
    },
    async downloadCSVPerUsageORG(orgTemp) {
      let org = Object.keys(orgTemp).length === 0 ? this.orgData : orgTemp;
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      org.downloadStatus = 'LOADING';
      const titles = [];
      const roles = [];
      const licenses = [];
      const statusValues = [];
      for (let filter of this.displaySelectedFilters) {
        switch (filter.type) {
          case 'Job Title':
            titles.push(filter.name);
            break;
          case 'Role':
            roles.push(filter.name);
            break;
          case 'License':
            licenses.push(filter.name);
            break;
          case 'Status':
            statusValues.push(filter.name);
            break;
          default:
            break;
        }
      }
      const payload = {
        pageNo: 0,
        pageSize: 0,
        titles: titles || [],
        roles: roles || [],
        licenses: licenses || [],
        statusValues: statusValues || [],
        searchText: this.searchInputText || '',
        statusCategory: this.timeFilter != 'ALLTIME' ? this.statusCategory : '',
        startDate: this.timeFilter === 'CUSTOM' ? this.dateRange.startDate : '',
        endDate: this.timeFilter === 'CUSTOM' ? this.dateRange.endDate : '',
        dateFormat: 'MM-dd-yyyy'
      };
      const orgDetails = { orgId: org.id || 'root', tenantGuid: tenantGuid, timeFilter: this.selectedTimeOptionString || 'YEAR' };
      const reqId = await this.$planAdmin.getORGUsageSummaryByRequestID(orgDetails, payload);
      let loadingStatus = 'IN_PROGRESS';
      let res = {};
      let counter = 0;
      while (loadingStatus == 'IN_PROGRESS' && counter < 200) {
        // keep checking every 3 seconds
        res = await this.$planAdmin.usageDownloadPerORGgetUpdate(reqId);
        loadingStatus = res.status;
        await new Promise((resolve) => setTimeout(resolve, 3000));
        counter++;
      }
      if (loadingStatus == 'FINISHED') {
        let filename = this.$t('common.titleCase.learningRecord') + `_${org?.name || 'Org'}_${generateCSVTimeFilter('YEAR')}_${this.getDateForCSV()}`;
        let a = document.createElement('a');
        a.href = res.generatedUri;
        a.setAttribute('download', filename);
        a.click();
        org.downloadStatus = Object.keys(orgTemp).length === 0 ? 'READY' : 'SUCCESS';
      } else {
        // this can also mean we timed out/counter went to 200(10 minutes)
        org.downloadStatus = Object.keys(orgTemp).length === 0 ? 'READY' : 'ERROR';
        if (loadingStatus == 'IN_PROGRESS') {
          // this means we timed out while trying to download
          console.log('Retrieving Learner Records is taking too long');
        } else {
          console.log(res.message);
        }
      }
    },
    async fetchInitialData() {
      this.structureReady = false;
      this.tableDataReady = false;
      await this.getTreeData();

      if ({}.hasOwnProperty.call(this.structure, 'code') && this.structure.code == 401) {
        this.$stopSessionTimer();
      }

      await this.getAllEmployees();
      await this.setup();
      this.structureReady = true;
      this.tableDataReady = true;
      await this.setupPagination();
    },
    async getEmployees(org, pageNumber, searchText, titles, roles, licenses, statusValues) {
      this.orgInFocus = org;
      const payload = {
        pageNo: pageNumber || this.pageNum,
        pageSize: this.pageSize,
        titles: titles || [],
        roles: roles || [],
        licenses: licenses || [],
        statusValues: statusValues || [],
        searchText: searchText || '',
        startDate: this.selectedTimeOptionString === 'CUSTOM' ? this.dateRange.startDate : '',
        endDate: this.selectedTimeOptionString === 'CUSTOM' ? this.dateRange.endDate : '',
        dateFormat: 'MM-dd-yyyy'
      };
      this.people = await this.$planAdmin.getUsageDetailEmployeesFromOrg(org.id, this.selectedTimeOptionString, payload);
      this.initialEmployees = this.people;
      this.totalPages = this.people.totalPages;

      if (this.isC1) {
        const { totalLearners, titlesCount, rolesCount, licensesCount } = this.people;
        if (this.people.isEnabled) {
          this.employees = [
            {
              learners: totalLearners || 0,
              title: titlesCount || 0,
              role: rolesCount || 0,
              license: licensesCount || 0
            }
          ];
          this.totalPages = 1;
        } else {
          this.employees = [];
        }
      }
    },
    async getAllEmployees(pageNumber, searchText, filtersRequired = true, titles, roles, licenses, statusValues) {
      const payload = {
        pageNo: pageNumber || this.pageNum,
        pageSize: this.pageSize,
        titles: titles || [],
        roles: roles || [],
        licenses: licenses || [],
        statusValues: statusValues || [],
        searchText: searchText || '',
        startDate: this.selectedTimeOptionString === 'CUSTOM' ? this.dateRange.startDate : '',
        endDate: this.selectedTimeOptionString === 'CUSTOM' ? this.dateRange.endDate : '',
        dateFormat: 'MM-dd-yyyy'
      };

      await this.$planAdmin.getUsageDetailAllEmployees(this.selectedTimeOptionString, payload).then((res) => {
        this.people = Object.assign({}, res);
        this.initialEmployees = this.people;
        this.totalPages = this.people.totalPages;
        if (this.isC1) {
          const { totalLearners, titlesCount, rolesCount, licensesCount } = this.people;
          if (this.people.isEnabled) {
            this.employees = [
              {
                learners: totalLearners || 0,
                title: titlesCount || 0,
                role: rolesCount || 0,
                license: licensesCount || 0
              }
            ];
            this.totalPages = 1;
          } else {
            this.employees = [];
          }
        }

        if (filtersRequired) {
          this.titles = this.people['titles'] || [];
          this.roles = this.people['roles'] || [];
          this.licenses = this.people['licenses'] || [];
          this.statusValues = this.people['statusValues'] || [];
        }
      });
    },
    async setup() {
      this.setHeaders();
      if (!this.isC1) {
        this.employees = this.people.learners || [];
      }
      this.generateFilterData();
    },
    setHeaders() {
      this.headers = [];
      if (!this.headers.length) {
        if (this.isC1) {
          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.learners'),
            key: 'learners'
          });

          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.title'),
            key: 'title'
          });
          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.role'),
            key: 'role'
          });
          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.license'),
            key: 'license'
          });
        } else {
          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.name') + ` (${this.people.totalLearners || 0})`,
            key: 'empName',
            width: this.getElementWidth(translate('insights.usage.drilldownHeaders.name') + ` (${this.people.totalLearners || 0})`)
          });
          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.email'),
            key: 'email',
            width: this.getElementWidth(translate('insights.usage.drilldownHeaders.email'))
          });
          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.status'),
            key: 'status',
            width: this.getElementWidth(translate('insights.usage.drilldownHeaders.status'))
          });
          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.enrollmentDate'),
            key: 'enrollmentDate',
            width: this.getElementWidth(translate('insights.usage.drilldownHeaders.enrollmentDate'))
          });
          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.title'),
            key: 'title',
            width: this.getElementWidth(translate('insights.usage.drilldownHeaders.title'))
          });
          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.role'),
            key: 'role',
            width: this.getElementWidth(translate('insights.usage.drilldownHeaders.role'))
          });
          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.license'),
            key: 'license',
            width: this.getElementWidth(translate('insights.usage.drilldownHeaders.license'))
          });
          this.headers.push({
            title: translate('insights.usage.drilldownHeaders.lastAccessed'),
            key: 'lastAccessed',
            width: this.getElementWidth(translate('insights.usage.drilldownHeaders.lastAccessed'))
          });
        }
      }
    },
    generateFilterData() {
      this.filterResults = {
        jobTitleFilterValues: [],
        roleFilterValues: [],
        licenseFilterValues: [],
        statusFilterValues: []
      };
      const keys = ['titles', 'roles', 'licenses', 'statusValues'];

      for (const property of keys) {
        if (this.people.filterValues) {
          const payloadObj = this.people.filterValues[property];

          switch (property) {
            case 'titles':
              for (let name of payloadObj) {
                this.filterResults.jobTitleFilterValues.push({ name });
              }
              break;
            case 'roles':
              for (let name of payloadObj) {
                this.filterResults.roleFilterValues.push({ name });
              }
              break;
            case 'licenses':
              for (let name of payloadObj) {
                this.filterResults.licenseFilterValues.push({ name });
              }
              break;
            case 'statusValues':
              for (let name of payloadObj) {
                this.filterResults.statusFilterValues.push({ name });
              }
              break;
            default:
              break;
          }
        }
      }
    },
    onRemoveFilter(item) {
      this.$options.childInterface.removeFilter(item);
    },
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    async applyFilters(filters, selectedFilters) {
      if (!Object.keys(this.people).length) return;
      this.displaySelectedFilters = selectedFilters;
      this.tableDataReady = false;
      this.pageNum = 1;
      const statusValues = [...filters[0].values];
      const titles = [...filters[1].values];
      const roles = [...filters[2].values];
      const licenses = [...filters[3].values];

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText, titles, roles, licenses, statusValues);
      } else {
        await this.getAllEmployees(this.pageNum, this.searchText, true, titles, roles, licenses, statusValues);
      }

      this.totalPages = this.people.totalPages;
      await this.setup();
      this.tableDataReady = true;
    },
    async onClearFilters() {
      this.$nextTick(async () => {
        this.$options.childInterface.clearFilters();
        this.pageNum = 1;
        //  if there are selected filters - allow 'applyFilters' to reload data
        if (!this.displaySelectedFilters.length) {
          if (this.orgInFocus) {
            await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText);
          } else {
            await this.getAllEmployees(this.pageNum, this.searchText, true);
          }
          await this.setup();
          await this.setupPagination();
        }
      });
    },
    async onGetPlan(org) {
      this.pageNum = 1;
      this.tableDataReady = false;
      this.people = {};
      this.selectDepartment = true;

      if (org.id) {
        await this.getEmployees(org);
      }

      this.sortBy = [];
      this.sortDesc = false;

      this.$options.childInterface.clearFilters();
      await this.setup();
      await this.setupPagination(true);
      this.tableDataReady = true;
    },
    currentEmployees(employees) {
      this.currentEmployeeGuids = employees.map((employee) => employee.guid);
    },
    async handlePageClick(pageNumber) {
      this.tableDataReady = false;
      this.pageNum = pageNumber;
      const titles = [];
      const roles = [];
      const licenses = [];
      const statusValues = [];

      for (let filter of this.displaySelectedFilters) {
        switch (filter.type) {
          case 'Job Title':
            titles.push(filter.name);
            break;
          case 'Role':
            roles.push(filter.name);
            break;
          case 'License':
            licenses.push(filter.name);
            break;
          case 'Status':
            statusValues.push(filter.name);
            break;
          default:
            break;
        }
      }

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText, titles, roles, licenses, statusValues);
      } else {
        await this.getAllEmployees(this.pageNum, this.searchText, true, titles, roles, licenses, statusValues);
      }

      this.sortBy = [];
      this.sortDesc = false;
      this.tableDataReady = true;
      await this.setup();
      await this.setupPagination();
    },
    onPageChange(page) {
      this.pageNum = page;
    },
    async setupPagination() {
      if (this.employees) {
        const elements = document.getElementsByClassName('pagination');
        if (elements.length && elements[0].childNodes) {
          elements[0].childNodes.forEach((element) => {
            if (element.childNodes.length && element.className.includes('pagination')) {
              const pageNumber = element.childNodes[0].innerHTML;
              if (element.className.includes('pagination-nav')) {
                if (pageNumber === '‹') {
                  element.setAttribute('data-tooltip-content', `Go to previous page`);
                  element.setAttribute('aria-label', `Go to previous page`);
                } else {
                  element.setAttribute('data-tooltip-content', `Go to next page`);
                  element.setAttribute('aria-label', `Go to next page`);
                }
              }
              if (element.className.includes('pagination-item active')) {
                element.setAttribute('aria-current', `page`);
              } else {
                element.setAttribute('aria-current', `false`);
                if (element.className.includes('pagination-item') && !isNaN(pageNumber)) {
                  element.setAttribute('aria-label', `page ${pageNumber}`);
                }
              }
            }
          });
        }
      }
    },
    onPageNumberChange(page) {
      this.pageNum = page;
    },
    async onPageSizeChange(size) {
      this.pageSize = size;
      this.pageNum = 1;
      this.handlePageClick(this.pageNum);
    },
    onDataTableChange(tableData) {
      const { page, itemsPerPage, sortBy } = tableData;

      if (this.pageSize !== itemsPerPage) {
        this.onPageSizeChange(itemsPerPage);
      }

      if (this.pageNum !== page) {
        this.handlePageClick(page);
      }

      if (this.sortBy !== sortBy) {
        this.sortBy = sortBy;
        if (sortBy.length) {
          this.employees = this.lodash.orderBy(this.employees, [sortBy[0].key], [sortBy[0].order]);
        } else {
          this.employees = this.initialEmployees.learners;
        }
      }
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.usage')
    };
  }
};
</script>

<style lang="scss">
.usage-container {
  max-width: 90rem;
  padding: 0 3rem;
  margin: 6.5rem auto 0;

  .v-btn {
    box-shadow: none;
    background: transparent;
    &:hover {
      box-shadow: none;
      background: transparent;
    }
  }

  .usage-header-container {
    display: grid;
    grid-template-columns: 6fr 6fr;

    .usage-header-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h1 {
        font: normal normal normal 2.625rem/3.375rem 'Futura PT Demi';
        letter-spacing: 0rem;
        color: #1f3370;
      }
      span {
        font: normal normal normal 1rem/1.125rem Arial;
        letter-spacing: 0rem;
        color: #2d2d2d;
      }
    }
  }

  .usage-active-charts-container {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-gap: 2rem;
    margin: 2rem 0rem;

    .card-header {
      margin-top: 0.625rem;
      padding: 0.8rem;
      color: #1f3370;
      font-weight: bold;
    }

    .loading-card {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 28.125rem;
    }
  }

  .usage-trends-header-container {
    margin: 2rem 0rem;

    h2 {
      font: normal normal normal 1.5rem/1.9375rem 'Futura PT Demi';
      letter-spacing: 0rem;
      color: #000000;
      opacity: 1;
    }
    span {
      font: normal normal normal 0.875rem/1.25rem Arial;
      letter-spacing: 0rem;
      color: #5c5c5c;
      display: block;
    }
  }

  .usage-active-charts-container,
  .usage-trends-chart-container {
    .card {
      padding: 0.3125rem 0.625rem;
      border-radius: 1.25rem;
      min-width: 15.625rem;
      box-shadow: none !important;

      .card-number {
        display: flex;
        font-size: 1.875rem;
        color: $color-primary;
        font-weight: bold;
      }
      .card-subject {
        position: relative;
        color: $color-primary;
        font-size: 1.125rem;
        margin-bottom: 0.9375rem;
      }
    }
  }
  .usage-details-header-container {
    display: grid;
    grid-template-columns: 6fr 6fr;
    margin: 2rem 0rem;

    h2 {
      font: normal normal normal 2.125rem/2.6875rem 'Futura PT Demi';
      letter-spacing: 0rem;
      color: #1f3370;
    }
    h5 {
      font: normal normal normal 0.875rem/1.25rem Arial;
      letter-spacing: 0rem;
      color: #5c5c5c;
    }
  }

  .usage-details-table-header-actions {
    display: grid;
    grid-template-columns: 6fr 6fr;
    margin-top: -2rem;

    .dashboard-details-table-search {
      padding: 1.5rem 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .v-input__control {
        box-shadow: none !important;
        min-height: 2.625rem !important;
        width: 32.25rem;
        min-width: 32.25rem;
      }

      .v-field--variant-solo {
        background-color: white !important;
        height: 2.625rem;
      }

      .v-field__field,
      .v-field__prepend-inner {
        height: 2.625rem;
      }

      .v-field__input {
        padding-top: 0rem !important;
      }

      .mdi-magnify {
        font-size: 1.725rem;
        margin-right: 0.725rem;
      }

      .v-input__control,
      .v-btn--is-elevated {
        border: none !important;
        box-shadow: none !important;
        border-radius: 1.3125rem;
        backdrop-filter: blur(1.25rem);
        -webkit-backdrop-filter: blur(1.25rem);
      }
    }

    .dashboard-details-table-filters {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .usage-details-search-chips {
    padding: 0 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;

    .clear-all-filters {
      text-align: left;
      font: normal normal bold 0.6875rem/1.875rem Arial;
      letter-spacing: -0.0069rem;
      color: #1f3370;
      opacity: 1;
      cursor: pointer;
      display: inline-block;
    }
  }

  .usage-details-footer-container {
    margin: 2rem 0rem;
    .usage-details-footer-text {
      font: normal normal normal 0.75rem/0.875rem Arial;
      letter-spacing: 0rem;
      color: #2d2d2d;
    }
  }
  .dashboard-details-date-chips {
    float: right;
  }
}
</style>
