// eslint-disable-next-line
import Timer from '@/utils/timer';

export default {
  install(app, options) {
    async function stopTimers(timeout) {
      await t.logoutFromAPI();
      await t.stopTimers();
      await t.performLogoff();
      await t.redirectAfterLogoff(timeout);
    }

    async function resetTimers() {
      if (t.hasUser()) {
        await t.restartTimers();
      }
    }

    const spyTime = 30000;
    const t = new Timer(options.logoutIdleTime, options.remindIdleTime, options.tokenRenewalTime, spyTime);

    app.config.globalProperties.$getTimer = () => t;
    app.config.globalProperties.$getTokenRenewalTime = t.defaults['renew'];
    app.config.globalProperties.$getRemindIdleTime = t.defaults['idle'];
    app.config.globalProperties.$getLogoutIdleTime = t.defaults['logout'];
    app.config.globalProperties.$startSessionTimer = async (param) => await t.startTimers(param);
    app.config.globalProperties.$stopSessionTimer = stopTimers;
    app.config.globalProperties.$resetSessionTimers = resetTimers;
  }
};
