<template>
  <div class="permission-container">
    <div class="header-container">
      <h2>{{ $t('admin_planner.admin_permissions') }}</h2>
      <div>
        <v-text-field
          id="search-user"
          class="search-user"
          data-el="searchUser"
          hide-details
          prepend-inner-icon="mdi-magnify"
          single-line
          ref="searchInput"
          :placeholder="$t('admin_planner.search_placeholder')"
          :value="query"
          flat
          filled
          solo
          rounded
          dense
          clearable
          v-model="query"
          variant="solo"
          @keypress.enter="searchEmployee"
          @click:clear="clearSearch"
        >
        </v-text-field>
      </div>
    </div>
    <p>{{ $t('admin_planner.admin_permissions_subtitle') }}</p>
    <div class="permission-table" v-if="dataReady">
      <v-data-table class="dashboard-details-table" :headers="headers" :items="employeeList" :items-per-page="employeeList.length">
        <template v-for="(permission, index) in permissions" :key="index" v-slot:[`item.${permission.name}`]="{ item }">
          <v-checkbox :model-value="hasPermission(item, permission.id)" :ripple="false" color="#1F3370" @update:model-value="togglePermission(item, permission)" :disabled="isDisabled(item.rolesAsList)"></v-checkbox>
        </template>
      </v-data-table>
    </div>
    <div v-else>
      <div class="loader t-flex t-justify-center t-items-center">
        <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" :alt="$t('common.titleCase.loadingLearningTrack')" />
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { translate } from '@/plugins/i18n.js';
import constantData from '@/api/constant.js';

export default {
  setup() {
    const employeeList = ref([]);
    const pageNo = ref(1);
    const query = ref('');
    const headers = ref([]);
    const dataReady = ref(false);
    const roles = ref(['GROUP_ADMIN, GLOBAL_ADMIN']);
    const nextEmployeeListIsEmpty = false;
    const permissions = ref([]);
    const prevItem = ref({});
    return {
      employeeList,
      pageNo,
      query,
      headers,
      roles,
      dataReady,
      nextEmployeeListIsEmpty,
      permissions,
      prevItem
    };
  },

  computed: {},

  async mounted() {
    await this.setHeaders();
    this.employeeList = await this.getEmployeeList(this.pageNo, this.query, this.roles);
    await this.setPermissionHeaders();
    this.saveInterval = setInterval(this.saveData, 5000); // Save every 5 seconds
    this.nextEmployeeListIsEmpty = this.employeeList.length === 0;
    this.dataReady = true;
  },

  async updated() {
    const scrollQuery = document.querySelector('.v-table__wrapper');
    if (scrollQuery) scrollQuery.addEventListener('scroll', this.scrollNext);
  },

  beforeUnmount() {
    this.saveData();
    clearInterval(this.saveInterval);
  },

  methods: {
    setHeaders() {
      this.headers = [
        {
          title: translate('admin_planner.headers.name'),
          key: 'name',
          sortable: false
        },
        {
          title: translate('admin_planner.headers.email'),
          key: 'email',
          sortable: false
        },
        {
          title: translate('admin_planner.headers.admin_type'),
          key: 'adminType',
          sortable: false
        }
      ];
    },

    async getEmployeeList(pageNo, query, roles) {
      try {
        const payload = {
          pageSize: 20,
          pageNo: pageNo,
          query: query,
          userType: roles
        };
        const employeeList = await this.$planAdmin.getAllUsers(payload);
        await employeeList.forEach(async (employee) => {
          employee.adminType = await this.getAdminType(employee.rolesAsList);
        });
        return employeeList;
      } catch (e) {
        const err = new Error(e);
        throw new Error(err.message);
      }
    },

    async scrollNext() {
      try {
        const scrollEle = document.getElementsByClassName('v-table__wrapper')[0];
        const bottomOfWindow = scrollEle.clientHeight + scrollEle.scrollTop >= scrollEle.scrollHeight - 1;
        if (bottomOfWindow) {
          this.pageNo = this.pageNo + 1;
          const nextEmployeeList = !this.nextEmployeeListIsEmpty ? await this.getEmployeeList(this.pageNo, this.query, this.roles) : [];
          this.nextEmployeeListIsEmpty = nextEmployeeList.length === 0;
          this.employeeList = [...this.employeeList, ...nextEmployeeList];
        }
      } catch (e) {
        const err = new Error(e);
        throw new Error(err.message);
      }
    },

    async searchEmployee() {
      this.dataReady = false;
      this.pageNo = 1;
      this.query = this.query ? this.query.trim() : '';
      this.employeeList = await this.getEmployeeList(this.pageNo, this.query, this.roles);
      this.nextEmployeeListIsEmpty = this.employeeList.length === 0;
      this.dataReady = true;
    },

    async clearSearch() {
      this.dataReady = false;
      this.pageNo = 1;
      this.query = '';
      this.employeeList = await this.getEmployeeList(this.pageNo, this.query, this.roles);
      this.nextEmployeeListIsEmpty = this.employeeList.length === 0;
      this.dataReady = true;
    },

    async setPermissionHeaders() {
      const permissions = await this.$planAdmin.getAllPermissions();
      this.permissions = permissions;
      this.headers = [
        ...this.headers,
        ...permissions.map(({ name }) => ({
          key: name,
          title: name,
          sortable: false
        }))
      ];
    },

    hasPermission(item, permissionId) {
      return item.permissions.some((ele) => ele.id === permissionId) || item.rolesAsList.includes(constantData.global_admin);
    },

    togglePermission(item, permission) {
      const index = item.permissions.findIndex((ele) => ele.id === permission.id);
      if (index === -1) {
        item.permissions.push(permission);
      } else {
        item.permissions.splice(index, 1);
      }
      if (Object.keys(this.prevItem).length > 0 && this.prevItem.guid != item.guid) {
        this.onCheck(this.prevItem);
        this.onCheck(item); // Call the original onCheck method
      } else {
        this.prevItem = item;
      }
    },

    async onCheck(item) {
      const payload = {
        permissionIds: item.permissions.map(({ id }) => id)
      };
      const params = {
        userGuid: item.guid
      };
      this.$planAdmin.updateUser(params, payload);
      if (Object.keys(this.prevItem).length > 0) this.prevItem = {};
    },

    getAdminType(roles) {
      if (!roles) return '';
      if (roles.includes(constantData.global_admin)) return translate('admin_planner.admin_type_options.shortOption2');
      if (roles.includes(constantData.group_admin)) return translate('admin_planner.admin_type_options.shortOption1');
      return '';
    },

    isDisabled(roles) {
      return roles.includes(constantData.global_admin);
    },

    saveData() {
      if (Object.keys(this.prevItem).length > 0) this.onCheck(this.prevItem);
    }
  }
};
</script>

<style lang="scss">
.permission-container {
  background: #fff;
  border-radius: 24px;
  padding: 2rem;
  .header-container {
    display: flex;
    justify-content: space-between;
  }
  h2 {
    @include header-font-1-5;
    color: #1f3370;
  }
  p {
    margin-top: 1rem;
    padding-bottom: 1rem;
    @include body-font-1-25;
    color: #2d2d2d;
    border-bottom: 2px solid #d6d6d6;
  }
  .search-user {
    .v-label {
      font-weight: normal;
      font-family: Arial;
      font-size: 0.875rem;
      color: black;
    }
    .v-field--variant-solo {
      background-color: white !important;
      height: 2.3125rem;
      border: 0.125rem solid #e0e1e1;
      display: flex;
      flex-direction: row-reverse;
      padding: 0 1rem;
      width: 400px;
    }

    .v-field__field,
    .v-field__prepend-inner {
      height: 2.3125rem;
    }
    .v-field__input {
      min-height: unset;
      padding: 0rem;
      height: inherit;
      height: 2.3125rem;

      input {
        margin: 0px;
        &::placeholder {
          @include body-font-bold-0-875;
          letter-spacing: 0.0006rem;
          color: #5c5c5c;
          opacity: 1;
        }
      }
    }
  }
  .search-user .v-input__slot {
    min-height: 2.625rem !important;
    width: 468px;
  }
  .search-user .mdi-magnify {
    font-size: 1.725rem;
  }
  .dashboard-details-table {
    background: inherit;
    display: grid;
    height: 35rem !important;
    .caption {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }

    .dashboard-details-table-loader {
      margin: 10rem 3.125rem 3.125rem;
      text-align: center;
      width: 56rem;

      .v-input {
        display: contents !important;
        .v-field__overlay {
          display: none;
        }
      }

      .loader-text {
        font-size: 1.875rem;
        color: $color-primary;
      }

      .loader-bar {
        position: relative;
        top: -1.875rem;
      }
    }

    .v-table__wrapper {
      overflow: auto;
      position: relative;
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 0.5rem;
        height: 0.5rem;
        border-radius: 1.25rem;
      }

      &::-webkit-scrollbar-track {
        background: #bcc2d4;
        border-radius: 1.25rem;
        margin-bottom: 1.25rem;
        margin-right: 1.25rem;
        margin-top: 3.5rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-primary;
        border-radius: 1.25rem;
        width: 0.25rem;
      }
      &::-webkit-scrollbar-corner {
        display: none;
      }

      table {
        thead {
          border-bottom-right-radius: 0.3125rem !important;
          border-top-right-radius: 0.3125rem !important;
          height: 3.6875rem;
          border-radius: 0.3125rem;
          background: #fff;
          position: sticky;
          top: 0;
          z-index: 1;
          .enrollment-header {
            margin-right: 0.375rem;
          }
          .v-data-table__td {
            background-color: #fff;
          }
          span {
            text-align: center;
            @include body-font-bold-1;
            color: #000000;
            opacity: 1;
            width: max-content;
          }
          th {
            .v-data-table-header__content {
              justify-content: center;
            }
          }
        }

        tbody {
          tr:hover:not(.v-table__expanded__content) {
            &:nth-child(even) {
              background: #fff;
            }
          }

          .v-input {
            margin: 0rem;
            display: flex;
            align-items: center;
            height: 2.75rem;
            justify-content: center;
          }

          .employee-data-field {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 15rem;
          }
          .half-opacity {
            opacity: 0.5;
          }

          tr {
            td {
              height: 2.75rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 15rem;
              @include body-font-1;
            }

            .v-input {
              &:not(.v-input--is-disabled) {
                .mdi-checkbox-blank-outline {
                  border-radius: 0.25rem;
                  color: #1f3370;
                }
              }
            }
          }

          tr:nth-child(even) {
            td {
              background: #fff;
            }
          }
          tr:nth-child(odd) {
            td {
              background: #f5f5f5 0% 0% no-repeat padding-box;
            }
          }
          tr.group-active-row-selection > td {
            transition: all 0.7s ease-in-out;
          }
          tr.group-active-row-selection > td > div {
            padding: 1.25rem;
            height: 2.5rem;
          }
          @keyframes anim {
            0% {
              transform: scale(1, 0);
            }
            100% {
              transform: scale(1, 1);
            }
          }
          tr.group-inactive > td {
            opacity: 0;
            line-height: 0;
            padding: 0 0.625rem;
            height: 0 !important;
          }
        }
        .v-data-table__td {
          border: none !important;
        }
      }
    }
    .v-data-table-footer {
      display: none;
    }
  }
  .loader {
    height: 37.5rem;
    margin-left: 0;
  }
}
</style>
