<template>
  <div>
    <v-card v-show="dataReady" class="card">
      <v-btn name="download" aria-label="download awaiting invites" title="download awaiting invites" icon class="t-absolute t-right-0 t-w-8 t-h-8 enrollment-image" @click="downloadAwaitingChartPNG()"><svg-icon icon-class="PNG_icon" class="t-w-8 t-h-8" /></v-btn>
      <h2 class="card-header h2">{{ $t('insights.awaitingChartTitle') }}</h2>
      <div class="awaiting-donut-chart-container" ref="awaitingChartContainer">
        <div v-if="componentIsActive">
          <apexchart ref="awaitingDonut" id="awaiting-donut" type="radialBar" :height="250" :options="options" :series="[percentage]"></apexchart>
        </div>
        <div class="chart-labels">
          <div class="chart-labels-content">
            <span class="chart-circle-orange"></span>
            <span class="chart-labels-text">{{ $t('insights.enrollments.notInvitedLabel') }}</span>
          </div>
          <div class="chart-labels-content">
            <span class="chart-circle-silver"></span>
            <span class="chart-labels-text">{{ $t('insights.enrollments.invitedLabel') }}</span>
          </div>
        </div>
      </div>
      <v-container>
        <v-row class="v-row-container">
          <v-col cols="8">
            <span class="chart-blurb">{{ $t('insights.awaitingChartBlurb1') }}</span>
            <span class="chart-blurb">{{ isC1 ? $t('insights.awaitingChartBlurb3') : $t('insights.awaitingChartBlurb2') }}</span>
          </v-col>
          <v-col cols="4">
            <img alt="" src="@/assets/images/icons/coloredboxes.png" class="colorbox" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card v-show="!dataReady" class="card loading-card">
      <div class="loader t-flex t-justify-center t-items-center">
        <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Chart Data" />
      </div>
    </v-card>
  </div>
</template>
<script>
import localforage from 'localforage';
import { numberWithCommas, printThis } from '@/utils';
import { getDateForCSV, generateCSVTimeFilter } from '@/utils';
import { translate } from '@/plugins/i18n.js';

const initialState = () => ({
  percentage: 0,
  awaitingInvites: 0,
  totalLicenses: 1,
  dataReady: false,
  componentIsActive: false,
  isComponentRefreshed: false,
  options: {
    chart: {
      height: 300,
      type: 'radialBar',
      toolbar: { show: false }
    },
    plotOptions: {
      ringLabels: ['', '', ''],
      radialBar: {
        hollow: {
          size: '60%'
        },
        dataLabels: {
          name: {
            show: false
          },
          value: {
            show: true,
            offsetY: -4,
            color: '#E47545',
            formatter: function (val, opt) {
              return [`${numberWithCommas(opt.config.plotOptions.ringLabels[0] || '0')}`, opt.config.plotOptions.ringLabels[1], `${numberWithCommas(opt.config.plotOptions.ringLabels[2] || '0')}`];
            }
          }
        }
      }
    },
    legend: {
      show: false,
      position: 'right',
      offsetY: 80,
      offsetX: 100,
      onItemClick: {
        toggleDataSeries: false
      },
      onItemHover: {
        highlightDataSeries: false
      }
    },
    labels: ['Not Invited', 'Invited'],
    colors: ['#E47545', '#e1e1e1'],
    stroke: {
      lineCap: 'round'
    }
  }
});

export default {
  props: {
    selectedTimeOptionString: { type: String },
    isC1: { type: Boolean },
    dateRange: { type: Object }
  },
  data() {
    return initialState();
  },
  async mounted() {
    Object.assign(this.$data, initialState());
    this.dataReady = false;
    this.componentIsActive = true;
  },
  updated() {
    this.isComponentRefreshed = false;
  },
  deactivated() {
    this.componentIsActive = false;
    this.isComponentRefreshed = false;
  },
  watch: {
    selectedTimeOptionString() {
      if (this.selectedTimeOptionString.length) {
        this.isComponentRefreshed = true;
        this.refreshData();
      }
    },
    '$i18n.locale'() {
      this.options = {
        ...this.options,
        plotOptions: {
          ringLabels: [this.awaitingInvites, translate('insights.awaitingOutOf'), this.totalLicenses]
        }
      };
      if (this.componentIsActive) {
        this.$refs.awaitingDonut.updateOptions(this.options);
      }
    },
    dateRange: {
      handler() {
        if (!this.isComponentRefreshed) {
          this.dataReady = false;
          this.refreshData();
        } else {
          this.isComponentRefreshed = false;
        }
      },
      deep: true
    }
  },
  methods: {
    getDateForCSV,
    generateCSVTimeFilter,
    printThis,
    async refreshData() {
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      const time = this.selectedTimeOptionString === 'CUSTOM' && this.dateRange.startDate && this.dateRange.endDate ? `${this.selectedTimeOptionString}?startDate=${this.dateRange.startDate}&endDate=${this.dateRange.endDate}&dateFormat=MM-dd-yyyy` : this.selectedTimeOptionString;
      const payload = { tenantGuid: tenantGuid, timePeriod: time };

      // only update the Awaiting Donut the first time
      if (!this.dataReady) {
        this.$planAdmin
          .getInvitedRate(payload)
          .then((response) => {
            if (response) {
              if (response.user_not_invited) this.awaitingInvites = response.user_not_invited;
              if (response.license_count) this.totalLicenses = response.license_count;
              if (response.invitedPercentage) {
                this.percentage = response.invitedPercentage;
              } else {
                this.percentage = Math.round((100 * this.awaitingInvites) / this.totalLicenses);
              }
              this.options = {
                ...this.options,
                plotOptions: {
                  ringLabels: [this.awaitingInvites, translate('insights.awaitingOutOf'), this.totalLicenses]
                }
              };
            }

            this.dataReady = true;

            this.$nextTick(async () => {
              const donut = document.getElementById('awaiting-donut');

              if (donut) {
                const g = donut.getElementsByTagName('tspan');

                for (let tspan of g) {
                  if (tspan.hasAttribute('dy')) {
                    tspan.setAttribute('dy', '24');
                  }
                }
              }
            });
          })
          .catch((error) => {
            throw new Error(error);
          });
      }
    },
    downloadAwaitingChartPNG() {
      this.printThis(this.$refs.awaitingChartContainer, `Org Enrollment Awaiting Invites Chart_${generateCSVTimeFilter(this.selectedTimeOptionString)}_${this.getDateForCSV()}.png`);
    }
  }
};
</script>

<style lang="scss">
.awaiting-donut-chart-container {
  display: grid;
  grid-template-columns: 8fr 4fr;

  #awaiting-donut {
    .apexcharts-text tspan {
      &:nth-child(1) {
        fill: #e47545;
        margin-bottom: 2rem;
        font-size: 2.625rem;
        font-family: 'Futura PT Demi' !important;
      }
      &:nth-child(2) {
        fill: #5c5c5c;
        font-size: 0.875rem;
        font-family: Arial !important;
        transform: translateY(10px);
        font-weight: 500;
      }
    }
  }

  .apexcharts-legend {
    .apexcharts-legend-series {
      display: none;
    }
  }

  .chart-labels {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .chart-labels-content {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      font: normal normal normal 1rem/1.125rem Arial;
      letter-spacing: 0px;
      color: #2d2d2d;

      opacity: 1;
      .chart-circle-orange {
        width: 12px;
        height: 12px;
        background: #e47545 0% 0% no-repeat padding-box;
        opacity: 1;
        color: red;
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.5rem;
      }
      .chart-circle-silver {
        width: 12px;
        height: 12px;
        background: #e0e1e1 0% 0% no-repeat padding-box;
        opacity: 1;
        color: red;
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.5rem;
      }

      .chart-labels-text {
        width: max-content;
        white-space: nowrap;
      }
    }
  }
}
.v-row-container {
  margin: 0.2rem 1rem;
}
.chart-blurb {
  float: left;
}
.colorbox {
  float: right;
  height: 1.875rem;
}
.enrollment-image {
  display: inline;
  margin: 1.5625rem;
}
</style>
