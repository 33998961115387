<template>
  <div id="trending-content-chart" v-if="dataReady" class="trending-content-chart">
    <div>
      <div class="apex-header">
        <h2>{{ $t('insights.trending.mostPopularContent') }}</h2>
        <div class="apex-header-labels">
          <div><span class="chart-circle-blue"></span> {{ $t('common.titleCase.complete') }}</div>
          <div><span class="chart-circle-silver"></span> {{ $t('common.titleCase.inProgress') }}</div>
        </div>
        <div class="trending-chat-actions">
          <v-btn name="download" icon class="trendingFocus" aria-label="download Most popular content" title="download Most popular content" @click="downloadPNG()" :disabled="!!noChartData"
            ><svg-icon :icon-class="noChartData ? 'PNG_icon_disabled' : 'PNG_icon'" class="t-w-8 t-h-8" />
          </v-btn>
        </div>
      </div>
      <apexchart ref="horizontalTrendingChart" type="bar" :height="height" :options="options" :series="series" />
    </div>
  </div>
  <div v-else class="loader t-flex t-justify-center t-items-center">
    <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Learning Track" />
  </div>
</template>

<script>
import localforage from 'localforage';
import { getDateForCSV, generateCSVTimeFilter, numberWithCommas, getLocalizedContentType } from '@/utils';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'TrendingChart',

  props: {
    height: {
      type: Number,
      default: 650
    },
    orgId: {
      type: String
    },
    orgName: {
      type: String
    },
    duration: {
      type: String,
      default() {
        return 'ALLTIME';
      }
    },
    progress: {
      type: String,
      default() {
        return 'ALL';
      }
    },
    type: {
      type: String,
      default() {
        return 'ALL';
      }
    },
    dateRange: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      dataReady: false,
      series: [],
      noChartData: null,
      isComponentRefreshed: false,
      options: {
        legend: {
          position: 'top',
          markers: {
            radius: 10,
            strokeWidth: 10
          },
          show: false
        },
        grid: {
          show: false
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          }
        },

        colors: ['#1d2857', '#ccc', '#fff'],
        plotOptions: {
          barLabels: [],
          rawlabels: [],
          labelNames: [translate('common.titleCase.complete'), translate('common.titleCase.inProgress')],
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'bottom',
              total: {
                enabled: true,
                offsetX: 20,
                offsetY: 20,
                style: {
                  fontSize: '12px',
                  fontWeight: 900,
                  fontFamily: 'Arial'
                },
                formatter: function (val) {
                  return !isNaN(val) ? val : '';
                }
              }
            },
            barHeight: 22
          }
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          offsetX: -8,
          textAnchor: 'start',
          formatter: function (_val, opt) {
            if (opt.seriesIndex % 2 === 0) {
              return opt.w.config.plotOptions.barLabels[opt.dataPointIndex];
            } else {
              if (!opt.config.series[opt.seriesIndex - 1].data[opt.dataPointIndex]) {
                return opt.w.config.plotOptions.barLabels[opt.dataPointIndex];
              }
            }
          },
          style: {
            colors: ['#000']
          }
        },
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: {
            show: false
          }
        },
        yaxis: {
          show: false,
          labels: {
            show: false
          },
          axisBorder: {
            show: false
          }
        },
        xaxis: {
          categories: [],
          title: {
            text: translate('insights.trending.countOfLearners'),
            style: {
              fontSize: '12px',
              fontFamily: 'Arial',
              color: '#000'
            }
          },
          show: false,
          tickAmount: 1,
          labels: {
            show: true,
            formatter: function (val) {
              return numberWithCommas(val.toFixed(0));
            }
          },
          axisBorder: {
            show: false
          }
        },
        tooltip: {
          custom: function ({ series, seriesIndex, dataPointIndex, ctx }) {
            const title = ctx.w.config.plotOptions.rawlabels[dataPointIndex];
            const complete = ctx.w.config.plotOptions.labelNames[0];
            const inProgress = ctx.w.config.plotOptions.labelNames[1];
            return `
              <div class="arrow_box">
                ${title.length >= 95 ? `<p class="title">${title}</p>` : ''}
                <span>
                  ${series[seriesIndex][dataPointIndex]} ${seriesIndex % 2 === 0 ? `${complete}` : `${inProgress}`}
                </span>
              </div>
              `;
          }
        },
        fill: {
          opacity: 1
        }
      }
    };
  },
  watch: {
    orgId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isComponentRefreshed = true;
        this.refreshChart();
      }
    },
    duration: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isComponentRefreshed = true;
        this.refreshChart();
      }
    },
    progress: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isComponentRefreshed = true;
        this.refreshChart();
      }
    },
    type: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isComponentRefreshed = true;
        this.refreshChart();
      }
    },
    '$i18n.locale'() {
      this.isComponentRefreshed = true;
      this.refreshChart();
    },
    dateRange: {
      handler(val) {
        console.log(val, this.isComponentRefreshed);
        if (!this.isComponentRefreshed && this.duration) {
          this.refreshChart();
        } else {
          this.isComponentRefreshed = false;
        }
      },
      deep: true
    }
  },
  mounted() {
    this.getTrendingData();
    this.isComponentRefreshed = true;
  },
  updated() {
    this.isComponentRefreshed = false;
  },
  methods: {
    getDateForCSV,
    generateCSVTimeFilter,
    getLocalizedContentType,
    downloadPNG() {
      if (!this.noChartData) {
        this.$refs.horizontalTrendingChart.dataURI().then(({ imgURI }) => {
          let a = document.createElement('a');
          a.setAttribute('id', 'download_png-anchor');
          a.href = imgURI;
          a.setAttribute('download', `Org Trending Popular Content Chart_${generateCSVTimeFilter(this.duration)}_${this.getDateForCSV()}`);
          a.click();
        });
      }
    },
    refreshChart() {
      this.getTrendingData();
      this.dataReady = false;
    },
    async getTrendingData() {
      const tenantGuid = await localforage.getItem('my_tenant_guid');

      const payload = {
        tenantGuid: tenantGuid,
        orgId: this.orgId,
        duration: this.duration,
        progress: this.progress,
        type: this.type,
        startDate: this.duration === 'CUSTOM' ? this.moment(this.dateRange.startDate).format('MM-DD-YYYY') : '',
        endDate: this.duration === 'CUSTOM' ? this.moment(this.dateRange.endDate).format('MM-DD-YYYY') : '',
        dateFormat: 'MM-dd-yyyy'
      };
      await this.$planAdmin
        .getDashboardContentTrendingInsights(payload)
        .then((response) => {
          this.dataReady = true;
          if (response && response.contentTitleCountList) {
            if (!response.contentTitleCountList.length) {
              this.series = [];
              this.noChartData = true;
              this.options.noData = {
                text: !response.isEnabled ? translate('common.noData10Learners') : translate('common.noDataIsAvailable'),
                style: {
                  fontSize: '1.75rem',
                  fontFamily: 'Futura PT Demi',
                  color: '#e0e1e1',
                  align: 'center',
                  verticalAlign: 'middle',
                  offsetX: 0,
                  offsetY: -60
                }
              };
            } else {
              this.noChartData = false;
              const completed = new Array(10).fill('');
              const inProgress = new Array(10).fill('');
              const labels = new Array(10).fill('');
              const rawLabels = new Array(10).fill('');

              for (let index in new Array(10).fill(0)) {
                if (response.contentTitleCountList[index]) {
                  const content = response.contentTitleCountList[index];
                  completed[index] = content.completeCount;
                  inProgress[index] = content.progressCount;
                  const MAX_CHARS = 95;
                  rawLabels[index] = `${+index + 1} ${content.title}`;
                  let title = rawLabels[index];
                  title = title.length >= MAX_CHARS ? `${title.slice(0, MAX_CHARS)}...` : title;
                  const translatedType = getLocalizedContentType(content.type.charAt(0) + content.type.slice(1).toLowerCase());
                  labels[index] = `${title} (${translatedType})`;
                }
              }
              this.series = [
                {
                  name: translate('common.titleCase.complete'),
                  data: completed
                },
                {
                  name: translate('common.titleCase.inProgress'),
                  data: inProgress
                }
              ];

              this.options.plotOptions.barLabels = labels;
              this.options.plotOptions.rawlabels = rawLabels;
            }

            this.dataReady = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
.theme--light.v-btn.v-btn--icon:focus-visible {
  outline: black 2px solid;
  border-radius: unset;
  color: white;
}
.trendingFocus:focus-visible {
  height: 32px;
  width: 32px;
  margin: 2px;
}
.trending-container .trending-chart-container .dashboard-trending-treeview-container .dashboard-trending-treeview .v-treeview-node__root .v-icon:focus-visible {
  outline: black 2px solid;
  border-radius: unset;
}
#trending-content-chart {
  height: 100%;
  .apexcharts-datalabel {
    font-size: 1.0625rem;
  }
  .apexcharts-xaxis-label {
    font-weight: 900;
  }
  .arrow_box {
    padding: 0.25rem;

    .title {
      padding: 0.25rem;
      width: 17rem;
      font-weight: 400;
      font-family: Arial;
      font-size: 0.875rem;
    }
    span {
      font-family: Arial;
      font-size: 0.875rem;
      font-weight: bold;
    }
  }

  .apex-header {
    display: grid;
    grid-template-columns: 1fr auto 3rem;
    padding: 1.5rem 1.5rem 0;

    h2 {
      text-align: left;
      font: normal normal normal 1.75rem/2.1875rem 'Futura PT Demi';
      letter-spacing: 0rem;
      color: #000000;
      opacity: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .trending-chat-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      span {
        cursor: pointer;
      }

      button {
        margin-left: 1rem;
      }
    }

    .apex-header-labels {
      text-align: left;
      font: normal normal normal 0.75rem/0.875rem Arial;
      letter-spacing: 0rem;
      color: #2d2d2d;
      text-transform: capitalize;
      opacity: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 1rem;

      div {
        display: flex;
        align-items: center;
        width: max-content;
        &:first-child {
          margin-bottom: 0.5rem;
        }
      }

      span {
        margin-right: 1rem;
      }

      .chart-circle-blue {
        width: 0.75rem;
        height: 0.75rem;
        background: #19295a 0% 0% no-repeat padding-box;
        opacity: 1;
        color: red;
        border-radius: 50%;
        display: inline-block;
        margin-right: 1rem;
      }

      .chart-circle-silver {
        width: 0.75rem;
        height: 0.75rem;
        background: #e0e1e1 0% 0% no-repeat padding-box;
        opacity: 1;
        color: red;
        border-radius: 50%;
        display: inline-block;
        margin-right: 1rem;
      }
    }
  }
  .apexcharts-tooltip {
    transform: translateX(-3.125rem) translateY(-1.25rem);
    overflow: visible !important;
    white-space: normal !important;
  }

  .apexcharts-tooltip span {
    padding: 0.3125rem 0.625rem;
    display: inline-block;
  }

  .no-chart-data {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    font: normal normal normal 1.75rem/2.1875rem 'Futura PT Demi';
    letter-spacing: 0rem;
    color: #000000;
  }
}

.loader {
  height: 100%;
  width: 100%;
}
</style>
