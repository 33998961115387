<template>
  <div class="company-logo-upload-container">
    <h2>{{ $t('settings.company_logo.header') }}</h2>
    <p>{{ $t('settings.company_logo.sub_header') }}</p>
    <img alt="" src="@/assets/images/pngs/company_logo_layout.png" />
    <div class="image-upload-section">
      <div class="latest" v-if="imageDefault">
        <h4>{{ $t('settings.company_logo.current_logo') }}</h4>
        <div class="image-container">
          <img id="previewImageLogo" ref="previewImageLogo" :src="imageDefault" style="width: 148px; height: 148px; cursor: pointer; border-radius: 25px" alt="user" />
        </div>
      </div>
      <h3 v-if="imageDefault">{{ $t('settings.company_logo.or') }}</h3>
      <div class="new">
        <h4>{{ $t('settings.company_logo.upload_logo') }}</h4>
        <div>
          <div class="default-image" @drop="handleDrop($event)" @dragover="handleDragover" @dragenter="handleDragover">
            <label class="file-upload-wrapper">
              <input id="fileinput" data-cy="asset-file" ref="thumbnailUpload" class="t-hidden" type="file" accept="image/png" @change="handleClick($event)" />
              <button @click.stop="openImageUploadPopup" aria-label="upload custom track image">
                <div class="imgBg t-p-2" :style="showPreviewImg ? 'background: #fff' : ''">
                  <img v-if="showPreviewImg" id="previewImageLogo" ref="previewImageLogo" :src="editImage" :class="imageSelected == 'user' ? 'image-border' : ''" style="width: 148px; height: 148px; cursor: pointer; border-radius: 25px" alt="user" />
                </div>
              </button>
            </label>
            <div @click.stop="openImageUploadPopup" class="image-text t-text-dark-grey">
              {{ $t('createDetails.dragImage') }}<span class="t-text-primary-color t-underline t-cursor-pointer">{{ $t('createDetails.uploadFile') }}</span>
            </div>
          </div>
          <div class="file-error" v-show="fileError != ''">{{ fileError }}</div>
        </div>
      </div>
    </div>
    <div class="custom-toast" v-if="fileUploadStatus === 'F'">
      <div class="toast-close-icon">
        <svg-icon icon-class="close" class="g-icon--small" @click="fileUploadStatus = ''"></svg-icon>
      </div>
      <div class="message-style">
        <svg-icon class="toast-failed-icon" icon-class="icon-alert-black"></svg-icon>
        <span class="status-message t-text-dark-grey">{{ $t('settings.company_logo.failure') }}</span>
      </div>
      <div class="cancel-btn">
        <v-btn class="cancel-btn-text" rounded small @click="tryAgain()">Try again</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import localforage from 'localforage';
import { translate } from '@/plugins/i18n.js';
export default {
  name: 'CompanyLogoUpload',
  data: function () {
    return {
      imageDefault: null,
      editImage: null,
      imageSelected: '',
      image: '',
      showPreviewImg: false,
      tenant: null,
      fileUploadStatus: ''
    };
  },
  async created() {
    await store.dispatch('user/setCompanyLogoSaveBtnState', false);
    if (!Object.keys(this.EventBus.all).includes('upload-company-logo')) this.EventBus.on('upload-company-logo', this.uploadCompanyLogo);
    if (!Object.keys(this.EventBus.all).includes('cancel-company-logo')) this.EventBus.on('cancel-company-logo', this.cancelCompanyLogo);
  },
  async mounted() {
    await this.getCurrentLogo();
  },
  async unmounted() {
    this.EventBus.off('cancel-company-logo');
    this.EventBus.off('upload-company-logo');
  },
  methods: {
    async cancelCompanyLogo() {
      await store.dispatch('user/setCompanyLogoSaveBtnState', false);
      this.editImage = null;
      this.imageSelected = '';
      this.image = '';
      this.showPreviewImg = false;
      this.fileUploadStatus = '';
    },
    async getCurrentLogo() {
      this.tenant = await this.$planAdmin.getUserTenantInfo();
      if (this.tenant && this.tenant.logo && this.tenant.logoUrl) {
        this.imageDefault = this.tenant.logoUrl || '';
      }
    },
    handleClick(event) {
      const files = event.target.files;
      if (!files.length) return;
      this.uploadFile(files[0]);
    },
    async uploadFile(fileObj) {
      const fileSize = Math.floor(fileObj.size / (1024 * 1024), 2);
      if (fileSize > this.maxFileSize) {
        this.fileError = 'Error: File cannot exceed 10MB';
        this.editImage = '';
        this.showPreviewImg = false;
      } else if (['image/png'].includes(fileObj.type)) {
        this.showPreviewImg = true;
        this.fileError = '';
        this.image = fileObj;
        var reader = new FileReader();
        await store.dispatch('user/setCompanyLogoSaveBtnState', true);
        reader.onload = () => {
          var output = this.$refs.previewImageLogo;
          output.src = reader.result;
          this.editImage = output.src;
        };
        reader.readAsDataURL(fileObj);
        this.imageSelected = 'user';
      }
    },
    openImageUploadPopup() {
      document.getElementById('fileinput').click();
    },
    tryAgain() {
      this.fileUploadStatus = '';
    },
    handleDragover(event) {
      event.stopPropagation();
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
    },
    handleDrop(event) {
      event.stopPropagation();
      event.preventDefault();
      const files = event.dataTransfer.files;
      const rawFile = files[0];
      if (!files.length) return;
      this.uploadFile(rawFile);
      event.stopPropagation();
      event.preventDefault();
    },
    async uploadCompanyLogo() {
      const formData = new FormData();
      if (this.image) {
        let payloadImgName = this.image?.name?.replace(/\s+/g, '');
        const token = await localforage.getItem('my_access_token');
        formData.set('type', 'COMPANY_LOGO');
        formData.set('token', token);
        formData.append('file', this.image, payloadImgName);
        const payload = {
          body: formData
        };
        this.$planAdmin
          .uploadAnnouncementFile(payload)
          .then(async (res) => {
            if (res.data.code == 200) {
              this.fileUploadStatus = '';
              this.showPreviewImg = false;
              this.updateStatus(res.data?.data?.mediaId);
            } else {
              this.fileUploadStatus = 'F';
            }
            await store.dispatch('user/setCompanyLogoSaveBtnState', false);
          })
          .catch(async () => {
            this.fileUploadStatus = 'F';
            await store.dispatch('user/setCompanyLogoSaveBtnState', false);
          });
      }
    },
    async updateStatus(logoId) {
      const uploadStatus = await this.$planAdmin.getUploadAnnouncementDetails(logoId);
      const status = uploadStatus.status.toLowerCase();
      if (status.toLowerCase().includes('inprogress')) {
        setTimeout(() => {
          this.updateStatus(uploadStatus?.mediaId);
        }, 10000);
      } else if (status.toLowerCase().includes('completed')) {
        const payload = {
          guid: this.tenant.guid,
          logo: logoId
        };
        await this.$planAdmin.updateTenantLogo(payload).then(async (res) => {
          if (res.logo) {
            this.fileUploadStatus = '';
            this.showToast(translate('settings.company_logo.success'), 'success', 3000, false);
            await this.getCurrentLogo();
          } else {
            this.fileUploadStatus = 'F';
          }
        });
      } else {
        this.fileUploadStatus = 'F';
      }
    },
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };
      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });

      this.$toasted.show(msg, options);
    }
  }
};
</script>

<style lang="scss" scoped>
.company-logo-upload-container {
  padding: 3rem;
  background-color: #ffffff;
  margin: 3rem 0 0 0;
  border-radius: 24px;
  h2 {
    @include header-font-2;
    color: #2d2d2d;
  }
  P {
    @include body-font-1-25;
    color: #2d2d2d;
    margin-top: 0.5rem;
  }
  img {
    width: -webkit-fill-available;
    margin: 2rem 0;
  }
  .image-upload-section {
    display: flex;
    gap: 1rem;
    justify-content: flex-start;
    .latest,
    .new {
      h4 {
        @include header-font-0-875;
        color: #2d2d2d;
        margin-bottom: 0.5rem;
      }
      .image-container {
        width: 164px;
        height: 164px;
        padding: 8px;
        border-radius: 1.5rem;
        border: 2px solid #2d2d2d;
        img {
          margin: unset;
        }
      }
      .default-image {
        width: 164px;
        align-items: center;
        border-radius: var(--corner-radius-radius-lg, 24px);
        &.default-track {
          border: 2px solid var(--border-border-dark-grey, #2d2d2d);
          display: flex;
        }
        .image-text {
          @include body-font-1;
        }
        .image-border {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: var(--corner-radius-radius-md, 16px);
          border: 2px solid var(--border-border-gainsboro, #f5f5f5);
          cursor: pointer;
          width: 148px;
          height: 148px;
        }
        .imgBg {
          background-repeat: no-repeat;
          width: 164px;
          height: 164px;
          cursor: pointer;
          background-image: url('~@/assets/images/icons/thumbnail-track.png');
          background-size: contain;
          text-align: center;
        }
        img {
          margin: unset;
        }
      }
    }
    h3 {
      @include header-font-1;
      color: #1f3370;
      display: flex;
      align-items: center;
    }
  }
  .custom-toast {
    background: #fae5dc 0% 0% no-repeat padding-box;
    display: block;
    padding: 32px;
    width: 36.5rem;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
    opacity: 1;
    position: fixed;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    .toast-failed-icon {
      width: 2rem;
      height: 2rem;
      float: left;
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
    .message-style {
      display: flex;
      gap: 16px;
      flex-direction: row;
      align-items: flex-start;
      gap: 16px;
    }
    .cancel-btn {
      border: 1p solid red;
      margin-top: 1rem;
      .cancel-btn-text {
        @include body-font-bold-1;
        border-radius: var(--corner-radius-radius-100, 200px);
        display: flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: white;
        background-color: var(--Fill-Midnight, #1f3370);
      }
    }
    .status-message {
      text-align: left;
      @include body-font-1;
    }
    .toast-close-icon {
      height: 1.5rem;
      width: 1.5rem;
      float: right;
      margin: -1rem -1rem 0 0;
      cursor: pointer;
      svg {
        width: 1rem !important;
        height: 1rem !important;
      }
    }
  }
}
</style>
