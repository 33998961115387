<template>
  <div class="enrollments-container">
    <ReportLastUpdatedHeader />

    <div class="dashboard-header-container">
      <div class="dashboard-header-title">
        <h1>{{ $t('insights.enrollmentChartHeader') }}</h1>
        <span>{{ $t('insights.enrollmentChartSubHeader') }}</span>
      </div>
      <div>
        <RadioFilter v-show="structureReady" :filter="timeFilter" :selectedOption="selectedTimeOption" :openDropdown="openDropdown" :onCancel="onCancel" :onApply="onApply" @onFilterChange="onTimeChange" />
        <div v-if="selectedTimeOptionString === 'CUSTOM'" class="dashboard-details-date-chips">
          <div id="chips-container">
            <v-chip in class="chip t-text-xs mt-4 t-bg-black" closable @click:close="onRemoveDateRange()"> {{ showSelectedFilter() }} </v-chip>
          </div>
        </div>
      </div>
    </div>

    <div class="dashboard-enrollment-charts-container">
      <EnrollmentDonutChart :selectedTimeOptionString="selectedTimeOptionString" :dateRange="dateRange" />
      <AwaitingDonutChart :selectedTimeOptionString="selectedTimeOptionString" :isC1="isC1" :dateRange="dateRange" />
    </div>

    <div class="dashboard-trends-header-container">
      <h2>{{ $t('insights.enrollmentTrendsChartHeader') }}</h2>
      <span>{{ $t('insights.enrollmentTrendsHeaderChartSubhHeader') }}</span>
    </div>

    <div class="dashboard-trends-chart-container">
      <EnrollmentTrends :orgInFocus="orgInFocus ? orgInFocus.id : 0" :timeFilter="selectedTimeOptionString" :statusCategory="statusCategory" :drillDownTableFilters="displaySelectedFilters" :searchInputText="searchTextForCSV" :dateRange="dateRange" />
    </div>

    <div class="dashboard-details-header-container" :style="structureReady && (isC1 ? 'margin-bottom: -1rem;' : 'margin-bottom: 0rem;')">
      <div>
        <h2>{{ $t('insights.detailsTableHeader') }}</h2>

        <span v-if="!isC1">
          {{ $t('insights.detailsTableSubHeader') }} <a href="javascript:void(0)" @click="$router.push('/workforce-planner')">{{ $t('insights.detailsTableSubHeaderAnchor') }}.</a>
        </span>
      </div>

      <EnrollmentsCSVDownload
        :orgNameInFocus="orgNameInFocus"
        :orgInFocus="orgInFocus ? orgInFocus.id : 0"
        :timeFilter="selectedTimeOptionString"
        :statusCategory="statusCategory"
        :drillDownTableFilters="displaySelectedFilters"
        :searchInputText="searchTextForCSV"
        :dateRange="dateRange"
      />
    </div>

    <div class="dashboard-details-table-header-actions" :style="displaySelectedFilters.length && 'padding-bottom: 0rem;'" v-show="structureReady">
      <div v-if="isC1" class="dashboard-details-header-container">
        <span>
          {{ $t('insights.detailsTableSubHeader') }} <a href="javascript:void(0)" @click="$router.push('/workforce-planner')">{{ $t('insights.detailsTableSubHeaderAnchor') }}.</a>
        </span>
      </div>
      <div v-else class="dashboard-details-table-search">
        <v-text-field
          class="details-search-text-field"
          v-model="searchText"
          @input="checkSearchLength"
          @keypress.enter="searchName"
          @click:clear="handleSearchClear"
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="$t('common.searchByName')"
          filled
          rounded
          solo
          clearable
          single-line
          variant="solo"
        />
      </div>

      <InsightsDrillDowntableFilters :timeFilter="selectedTimeOptionString" @filter-applied="applyFilters" @status-category-changed="onStatusCategoryChanged" :filterResults="filterResults" @interface="getChildInterface" />
    </div>

    <div class="dashboard-details-search-chips" :style="displaySelectedFilters.length && 'margin-top: -1.5625rem'" v-show="displaySelectedFilters.length && structureReady">
      <div>
        <span v-for="(item, index) in displaySelectedFilters" :key="index" id="chips-container">
          <v-chip in class="chip t-text-xs ma-2 t-bg-black" closable @click:close="onRemoveFilter(item)"> {{ item.type === 'Status' ? item.display : item.name }} </v-chip>
        </span>
        <span class="clear-all-filters" @click="onClearFilters('ON_APPLY')">{{ $t('common.clearAllFilters') }}</span>
      </div>
    </div>

    <div v-if="isC1">
      <EnrollmentDrilldownTableC1
        :structureReady="structureReady"
        :treeViewReady="treeViewReady"
        :headers="headers"
        :structure="structure"
        :onGetPlan="onGetPlan"
        :currentEmployees="currentEmployees"
        :tableDataReady="tableDataReady"
        :totalPages="totalPages"
        :pageRange="pageRange"
        :handlePageClick="handlePageClick"
        :pageNum="pageNum"
        :employees="employees"
      />
    </div>
    <div v-else>
      <InsightsDrilldownTable
        :structureReady="structureReady"
        :treeViewReady="treeViewReady"
        :headers="headers"
        :structure="structure"
        :onGetPlan="onGetPlan"
        :sortBy="sortBy"
        :sortDesc="sortDesc"
        :currentEmployees="currentEmployees"
        :tableDataReady="tableDataReady"
        :totalPages="totalPages"
        :pageRange="pageRange"
        :handlePageClick="handlePageClick"
        :pageNum="pageNum"
        :employees="employees"
        :pageSize="pageSize"
        :pageNumberChangeEvent="pageNumberChangeEvent"
        :pageSizeChangeEvent="pageSizeChangeEvent"
        :totalItems="people.totalLearnerCount"
        @on-data-table-change="onDataTableChange"
        caller="enrollments"
      />
    </div>

    <div class="dashboard-details-footer-container">
      <div class="dashboard-details-footer-text">{{ $t('insights.enrolledFooterText') }}</div>
    </div>
  </div>
</template>

<script>
import ReportLastUpdatedHeader from './components/ReportLastUpdatedHeader';
import RadioFilter from './components/RadioFilter.vue';
import EnrollmentDonutChart from './components/EnrollmentDonutChart';
import AwaitingDonutChart from './components/AwaitingDonutChart';
import EnrollmentTrends from './components/EnrollmentTrends';
import EnrollmentsCSVDownload from './components/EnrollmentsCSVDownload.vue';
import InsightsDrillDowntableFilters from './components/InsightsDrillDowntableFilters.vue';
import InsightsDrilldownTable from './components/InsightsDrilldownTable.vue';
import EnrollmentDrilldownTableC1 from './components/EnrollmentDrilldownTableC1.vue';
import localforage from 'localforage';
import { mapGetters } from 'vuex';
import { numberWithCommas, getElementWidth } from '@/utils';
import { translate, translateTC } from '@/plugins/i18n.js';

const initialState = () => ({
  isC1: null,
  timeFilter: {},
  selectedTimeOption: '',
  selectedTimeOptionString: '',
  searchTextForCSV: '',
  invitedPercentage: 0,
  statusCategory: 'enrollment',
  displaySelectedFilters: [],
  searchText: '',
  structureReady: false,
  structure: [],
  tableDataReady: false,
  treeViewReady: true,
  orgInFocus: null,
  orgNameInFocus: 'All Orgs',
  pageSize: 25,
  pageNum: 1,
  pageRange: 6,
  sortBy: [],
  sortDesc: false,
  filterResults: {},
  headers: [],
  employees: [],
  titles: [],
  roles: [],
  licenses: [],
  statusValues: [],
  people: {},
  totalPages: 0,
  clearSearchCross: false,
  pageSizeChangeEvent: 'enrollment-page-size-change',
  pageNumberChangeEvent: 'enrollment-page-number-change',
  labelsForCSV: {
    orgName: { title: 'Organization Name' },
    name: { title: 'Name' },
    id: { title: 'Employee Id' },
    email: { title: 'Email' },
    status: { title: 'Status' },
    enrollmentStatus: { title: 'Enrollment' },
    enrollmentDate: { title: 'Enrollment Date' },
    title: { title: 'Job Title' },
    role: { title: 'Role Title' },
    license: { title: 'License' },
    firstInviteDate: { title: 'First Invite Date' },
    inviteDate: { title: 'Last Notified' }
  },
  peopleListForCSV: [],
  dateRange: {
    startDate: '',
    endDate: ''
  }
});

export default {
  name: 'Enrollments',
  components: { InsightsDrillDowntableFilters, EnrollmentTrends, RadioFilter, ReportLastUpdatedHeader, EnrollmentDonutChart, AwaitingDonutChart, InsightsDrilldownTable, EnrollmentDrilldownTableC1, EnrollmentsCSVDownload },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters(['enabledFeatureFlags']),
    filterEmployees() {
      return this.employees.map((x) => ({ ...x, isSelectable: !x.disabled }));
    }
  },
  async mounted() {
    Object.assign(this.$data, initialState());
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';

    this.timeFilter = {
      options: [
        { id: 1, translationKey: 'insights.time.allTime', value: 'ALLTIME' },
        { id: 2, translationKey: 'insights.time.30Days', value: 'DAYS30' },
        { id: 3, translationKey: 'insights.time.90Days', value: 'DAYS90' },
        { id: 4, translationKey: 'insights.time.12Months', value: 'YEAR' }
      ],
      showExpandedFilter: false
    };
    if (clientCategory && clientCategory.toLowerCase() === 'c2') this.timeFilter.options.push({ id: 5, translationKey: 'Date range', value: 'CUSTOM', startDate: '', endDate: '' });
    this.selectedTimeOption = translate('insights.time.allTime');
    this.selectedTimeOptionString = 'ALLTIME';

    await this.fetchInitialData();
    await this.setupPagination();
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closePopup);
  },
  created() {
    this.EventBus.on(this.pageSizeChangeEvent, this.onPageSizeChange);
    this.EventBus.on(this.pageNumberChangeEvent, this.onPageNumberChange);
  },
  async beforeMount() {
    document.addEventListener('click', this.closePopup);
  },
  watch: {
    '$i18n.locale'() {
      this.setHeaders();
    },
    searchText() {
      if (this.searchText) this.searchText = this.searchText.trim();
    },
    orgInFocus() {
      this.orgNameInFocus = this.orgInFocus?.name;
    }
  },
  childInterface: {
    onClearFilters: () => {},
    onRemoveFilter: () => {}
  },
  filters: {
    formatLastUpdated(date) {
      const d = new Date(date);
      const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
      let datePart = d.toLocaleDateString('en-US', options);
      return datePart;
    }
  },
  methods: {
    getElementWidth,
    numberWithCommas,
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    async openDropdown() {
      this.timeFilter.showExpandedFilter = true;
    },
    onStatusCategoryChanged(e) {
      this.statusCategory = e;
    },
    closePopup(e) {
      if (this.timeFilter.showExpandedFilter && document.getElementById('time-filter-dropdown') && !document.getElementById('time-filter-dropdown').contains(e.target) && !e.target.parentNode.className.includes('mx') && !e.target.parentNode.className.includes('cell')) {
        this.onCancel();
      }
    },
    onCancel() {
      const selectedTimeOptionObject = this.timeFilter.options.find((o) => o.value === this.selectedTimeOptionString);
      this.selectedTimeOption = selectedTimeOptionObject.translationKey;
      this.timeFilter.showExpandedFilter = false;
    },
    async onApply() {
      const selectedTimeOptionObject = this.timeFilter.options.find((o) => translate(o.translationKey) === this.selectedTimeOption);
      this.selectedTimeOptionString = selectedTimeOptionObject.value;
      this.dateRange.startDate = this.selectedTimeOptionString === 'CUSTOM' ? this.moment(selectedTimeOptionObject.startDate).format('MM-DD-YYYY') : '';
      this.dateRange.endDate = this.selectedTimeOptionString === 'CUSTOM' ? this.moment(selectedTimeOptionObject.endDate).format('MM-DD-YYYY') : '';
      this.timeFilter.showExpandedFilter = false;
      this.structureReady = false;
      this.tableDataReady = false;

      this.clearSearchCross = true;
      this.searchText = '';
      this.searchTextForCSV = '';

      await this.onClearFilters('ON_APPLY');
      this.structureReady = true;
      this.tableDataReady = true;
    },
    async fetchInitialData() {
      this.structureReady = false;
      this.tableDataReady = false;
      await this.getTreeData();

      if ({}.hasOwnProperty.call(this.structure, 'code') && this.structure.code == 401) {
        this.$stopSessionTimer();
      }

      await this.getAllEmployees();
      await this.setup();
      this.structureReady = true;
      this.tableDataReady = true;
      await this.setupPagination();
    },
    async getAllEmployees(pageNumber, searchText, filtersRequired = true, titles, roles, licenses, statusValues) {
      const payload = {
        pageNo: pageNumber || this.pageNum,
        pageSize: this.pageSize,
        titles: titles || [],
        roles: roles || [],
        licenses: licenses || [],
        statusValues: statusValues || [],
        searchText: searchText || '',
        statusCategory: this.selectedTimeOptionString == 'ALLTIME' ? '' : this.statusCategory || 'enrollment',
        startDate: this.selectedTimeOptionString === 'CUSTOM' ? this.dateRange.startDate : '',
        endDate: this.selectedTimeOptionString === 'CUSTOM' ? this.dateRange.endDate : '',
        dateFormat: 'MM-dd-yyyy'
      };
      await this.$planAdmin.getDashboardDetailAllEmployees(this.selectedTimeOptionString, payload).then((res) => {
        this.people = Object.assign({}, res);
        this.totalPages = this.people.totalPages;

        if (this.isC1) {
          const { totalLearnerCount, enrollmentRate, totalJobTitles, totalRoles, totalLicenses } = this.people;
          if (this.people.isEnabled) {
            this.people.employees = [
              {
                learners: totalLearnerCount || 0,
                enrollmentRate: `${enrollmentRate || 0}%`,
                title: totalJobTitles || 0,
                role: totalRoles || 0,
                license: totalLicenses || 0
              }
            ];
            this.totalPages = 1;
          } else {
            this.people.employees = [];
          }
        }
        if (filtersRequired) {
          this.titles = this.people.filterValues['titles'] || [];
          this.roles = this.people.filterValues['roles'] || [];
          this.licenses = this.people.filterValues['licenses'] || [];
          this.statusValues = this.people.filterValues['statusValues'] || [];
        }
      });
    },
    async getEmployees(org, pageNumber, searchText, titles, roles, licenses, statusValues) {
      this.orgInFocus = org;
      const payload = {
        pageNo: pageNumber || this.pageNum,
        pageSize: this.pageSize,
        titles: titles || [],
        roles: roles || [],
        licenses: licenses || [],
        statusValues: statusValues || [],
        searchText: searchText || '',
        statusCategory: this.statusCategory || '',
        startDate: this.selectedTimeOptionString === 'CUSTOM' ? this.dateRange.startDate : '',
        endDate: this.selectedTimeOptionString === 'CUSTOM' ? this.dateRange.endDate : '',
        dateFormat: 'MM-dd-yyyy'
      };
      this.people = await this.$planAdmin.getDashboardDetailEmployeesFromOrg(org.id, this.selectedTimeOptionString, payload);
      this.totalPages = this.people.totalPages;

      if (this.isC1) {
        const { totalLearnerCount, enrollmentRate, totalJobTitles, totalRoles, totalLicenses } = this.people;
        if (this.people.isEnabled) {
          this.people.employees = [
            {
              learners: totalLearnerCount || 0,
              enrollmentRate: `${enrollmentRate || 0}%`,
              title: totalJobTitles || 0,
              role: totalRoles || 0,
              license: totalLicenses || 0
            }
          ];
          this.totalPages = 1;
        } else {
          this.people.employees = [];
        }
      }
    },
    async setup() {
      this.setHeaders();
      this.employees = this.people.employees || [];
      this.generateFilterData();
    },
    async getTreeData() {
      const data = await this.$planAdmin.getRootOrgManagePeopleReporting();
      this.structure = data;
    },
    currentEmployees(employees) {
      this.currentEmployeeGuids = employees.map((employee) => employee.guid);
    },
    async applyFilters(filters, selectedFilters) {
      this.displaySelectedFilters = selectedFilters;
      this.tableDataReady = false;
      this.pageNum = 1;
      const statusValues = [...filters[0].values];
      const titles = [...filters[1].values];
      const roles = [...filters[2].values];
      const licenses = [...filters[3].values];

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText, titles, roles, licenses, statusValues);
      } else {
        await this.getAllEmployees(this.pageNum, this.searchText, true, titles, roles, licenses, statusValues);
      }

      this.totalPages = this.people.totalPages;
      await this.setup();
      this.tableDataReady = true;
    },
    async onGetPlan(org) {
      this.tableDataReady = false;
      this.orgInFocus = org;
      this.pageNum = 1;
      this.people = {};
      this.selectDepartment = true;

      if (this.displaySelectedFilters.length) {
        this.$options.childInterface.clearFilters('GET_PLAN_FROM_ENROLLMENT');

        while (!this.tableDataReady) {
          await this.sleep(100);
        }
      } else {
        if (org.id) {
          await this.getEmployees(org);
        }
      }

      this.sortBy = [];
      this.sortDesc = false;

      await this.setup();
      await this.setupPagination(true);
      this.tableDataReady = true;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    setHeaders() {
      this.headers = [];
      if (!this.headers.length) {
        if (this.isC1) {
          this.headers.push({
            title: translateTC('common.titleCase.learner', 2),
            key: 'learners'
          });
          this.headers.push({
            title: translate('common.titleCase.enrollmentRate'),
            key: 'enrollmentRate'
          });
          this.headers.push({
            title: translateTC('common.titleCase.title', 1),
            key: 'title'
          });
          this.headers.push({
            title: translateTC('common.titleCase.role', 1),
            key: 'role'
          });
          this.headers.push({
            title: translateTC('common.titleCase.license'),
            key: 'license'
          });
        } else {
          this.headers.push({
            title: translate('common.titleCase.name') + ` (${this.numberWithCommas(this.people.totalLearnerCount || '0')})`,
            key: 'name',
            width: this.getElementWidth(translate('common.titleCase.name') + ` (${this.numberWithCommas(this.people.totalLearnerCount || '0')})`),
            minWidth: this.getElementWidth(translate('common.titleCase.name') + ` (${this.numberWithCommas(this.people.totalLearnerCount || '0')})`)
          });
          this.headers.push({
            title: translate('common.titleCase.email'),
            key: 'email',
            width: this.getElementWidth(translate('common.titleCase.email')),
            minWidth: this.getElementWidth(translate('common.titleCase.email'))
          });
          this.headers.push({
            title: translate('common.titleCase.status'),
            key: 'status',
            width: this.getElementWidth(translate('common.titleCase.status')),
            minWidth: this.getElementWidth(translate('common.titleCase.status'))
          });
          if (this.selectedTimeOptionString == 'ALLTIME' || (this.statusCategory != 'invited' && this.statusCategory != 'notinvited')) {
            this.headers.push({
              title: translate('common.titleCase.enrollmentRate') + ` (${this.people.enrollmentRate || 0}%)`,
              key: 'enrollmentStatus',
              width: this.getElementWidth(translate('common.titleCase.enrollmentRate') + ` (${this.people.enrollmentRate || 0}%)`),
              minWidth: this.getElementWidth(translate('common.titleCase.enrollmentRate') + ` (${this.people.enrollmentRate || 0}%)`)
            });
            this.headers.push({
              title: translate('common.titleCase.enrollmentDate'),
              key: 'enrollmentDate',
              width: this.getElementWidth(translate('common.titleCase.enrollmentDate')),
              minWidth: this.getElementWidth(translate('common.titleCase.enrollmentDate'))
            });
          }
          this.headers.push({
            title: translateTC('common.titleCase.title', 1),
            key: 'title',
            width: this.getElementWidth(translateTC('common.titleCase.title', 1)),
            minWidth: this.getElementWidth(translateTC('common.titleCase.title', 1))
          });
          this.headers.push({
            title: translateTC('common.titleCase.role', 1),
            key: 'role',
            width: this.getElementWidth(translateTC('common.titleCase.role', 1)),
            minWidth: this.getElementWidth(translateTC('common.titleCase.role', 1))
          });

          this.headers.push({
            title: translateTC('common.titleCase.license'),
            key: 'license',
            width: this.getElementWidth(translateTC('common.titleCase.license')),
            minWidth: this.getElementWidth(translateTC('common.titleCase.license'))
          });

          this.headers.push({
            title: translateTC('common.titleCase.firstInviteDate'),
            key: 'firstInviteDate',
            width: this.getElementWidth(translateTC('common.titleCase.firstInviteDate')),
            minWidth: this.getElementWidth(translateTC('common.titleCase.firstInviteDate'))
          });

          if (this.selectedTimeOptionString == 'ALLTIME' || (this.statusCategory != 'enrollment' && this.statusCategory != 'notinvited')) {
            this.headers.push({
              title: translate('common.titleCase.lastNotified'),
              key: 'inviteDate',
              width: this.getElementWidth(translate('common.titleCase.lastNotified')),
              minWidth: this.getElementWidth(translate('common.titleCase.lastNotified'))
            });
          }
        }
      }
    },
    checkSearchLength(val) {
      !val ? (this.clearSearchCross ? (this.clearSearchCross = false) : this.handleSearchClear()) : void 0;
    },
    async resetTreeView() {
      this.treeViewReady = false;
      await this.getTreeData();
      this.treeViewReady = true;
    },
    async searchName(e) {
      if (!e.target.value.length) return;
      this.tableDataReady = false;
      this.pageNum = 1;
      this.orgInFocus = null;
      await this.resetTreeView();
      this.$options.childInterface.clearFilters();
      this.searchTextForCSV = this.searchText;
      await this.getAllEmployees(this.pageNum, this.searchText, true);
      await this.setup();
      this.tableDataReady = true;
    },
    async handleSearchClear() {
      this.clearSearchCross = true;
      this.pageNum = 1;
      this.searchText = '';
      this.$options.childInterface.clearFilters();
      this.orgInFocus = '';
      this.searchTextForCSV = '';
      await Promise.all([this.resetTreeView(), this.getAllEmployees(this.pageNum, '', true)]);
      await this.setup();
    },
    async setupPagination() {
      if (this.employees) {
        const elements = document.getElementsByClassName('pagination');
        if (elements.length && elements[0].childNodes) {
          elements[0].childNodes.forEach((element) => {
            if (element.childNodes.length && element.className.includes('pagination')) {
              const pageNumber = element.childNodes[0].innerHTML;
              if (element.className.includes('pagination-nav')) {
                if (pageNumber === '‹') {
                  element.setAttribute('data-tooltip-content', `Go to previous page`);
                  element.setAttribute('aria-label', `Go to previous page`);
                } else {
                  element.setAttribute('data-tooltip-content', `Go to next page`);
                  element.setAttribute('aria-label', `Go to next page`);
                }
              }
              if (element.className.includes('pagination-item active')) {
                element.setAttribute('aria-current', `page`);
              } else {
                element.setAttribute('aria-current', `false`);
                if (element.className.includes('pagination-item') && !isNaN(pageNumber)) {
                  element.setAttribute('aria-label', `page ${pageNumber}`);
                }
              }
            }
          });
        }
      }
    },
    async handlePageClick(pageNumber) {
      this.tableDataReady = false;
      this.pageNum = pageNumber;
      const titles = [];
      const roles = [];
      const licenses = [];
      const statusValues = [];

      for (let filter of this.displaySelectedFilters) {
        switch (filter.type) {
          case 'Job Title':
            titles.push(filter.name);
            break;
          case 'Role':
            roles.push(filter.name);
            break;
          case 'License':
            licenses.push(filter.name);
            break;
          case 'Status':
            statusValues.push(filter.name);
            break;
          default:
            break;
        }
      }

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText, titles, roles, licenses, statusValues);
      } else {
        await this.getAllEmployees(this.pageNum, this.searchText, true, titles, roles, licenses, statusValues);
      }

      this.sortBy = [];
      this.sortDesc = false;
      this.tableDataReady = true;
      await this.setup();
      await this.setupPagination();
    },
    generateFilterData() {
      this.filterResults = {
        jobTitleFilterValues: [],
        roleFilterValues: [],
        licenseFilterValues: [],
        statusFilterValues: []
      };
      const keys = ['titles', 'roles', 'licenses', 'statusValues'];

      for (const property of keys) {
        if (this.people.filterValues) {
          const payloadObj = this.people.filterValues[property];

          switch (property) {
            case 'titles':
              for (let name of payloadObj) {
                this.filterResults.jobTitleFilterValues.push({ name });
              }
              break;
            case 'roles':
              for (let name of payloadObj) {
                this.filterResults.roleFilterValues.push({ name });
              }
              break;
            case 'licenses':
              for (let name of payloadObj) {
                this.filterResults.licenseFilterValues.push({ name });
              }
              break;
            case 'statusValues':
              for (let name of payloadObj) {
                this.filterResults.statusFilterValues.push({ name });
              }
              break;
            default:
              break;
          }
        }
      }
    },
    onRemoveFilter(item) {
      this.$options.childInterface.removeFilter(item);
    },
    async onClearFilters(method) {
      this.selectedTimeOptionString !== 'ALLTIME' ? (this.statusCategory = 'enrollment') : '';

      this.$nextTick(async () => {
        this.$options.childInterface.clearFilters(method);
        this.pageNum = 1;
        //  if there are selected filters - allow 'applyFilters' to reload data
        if (!this.displaySelectedFilters.length) {
          if (this.orgInFocus) {
            await this.getEmployees(this.orgInFocus, this.pageNum, this.searchText);
          } else {
            await this.getAllEmployees(this.pageNum, this.searchText, true);
          }
          await this.setup();
          await this.setupPagination();
        }
      });
    },
    onTimeChange(time) {
      this.selectedTimeOption = time;
    },
    onPageNumberChange(page) {
      this.pageNum = page;
    },
    async onPageSizeChange(size) {
      this.pageSize = size;
      this.pageNum = 1;
      this.handlePageClick(this.pageNum);
    },
    onDataTableChange(tableData) {
      const { page, itemsPerPage, sortBy } = tableData;

      if (this.pageSize !== itemsPerPage) {
        this.onPageSizeChange(itemsPerPage);
      }

      if (this.pageNum !== page) {
        this.handlePageClick(page);
      }

      if (this.sortBy !== sortBy) {
        this.sortBy = sortBy;
        if (sortBy.length) {
          this.employees = this.lodash.orderBy(this.employees, [sortBy[0].key], [sortBy[0].order]);
        } else {
          this.employees = this.people.employees || [];
        }
      }
    },
    showSelectedFilter() {
      const from = this.dateRange.startDate ? this.moment(this.dateRange.startDate).format('D MMM YYYY') : '';
      const to = this.dateRange.endDate ? this.moment(this.dateRange.endDate).format('D MMM YYYY') : '';
      return to && from ? `${from} - ${to}` : '';
    },
    onRemoveDateRange() {
      this.dateRange.startDate = '';
      this.dateRange.endDate = '';
      this.selectedTimeOption = this.$t('insights.time.allTime');
      this.onApply();
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.enrollments')
    };
  }
};
</script>

<style lang="scss">
.enrollments-container {
  max-width: 90rem;
  padding: 0 3rem;
  margin: 6.5rem auto 0;

  .v-btn {
    box-shadow: none;
    background: transparent;
    &:hover {
      box-shadow: none;
      background: transparent;
    }
  }

  .dashboard-header-container {
    display: grid;
    grid-template-columns: 10fr 2fr;
    height: 5rem;

    .dashboard-header-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h1 {
        font: normal normal normal 2.625rem/3.375rem 'Futura PT Demi';
        letter-spacing: 0rem;
        color: #1f3370;
      }
      span {
        font: normal normal normal 1rem/1.125rem Arial;
        letter-spacing: 0rem;
        color: #2d2d2d;
      }
    }
  }

  .dashboard-enrollment-charts-container {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-gap: 2rem;
    margin: 2rem 0rem;

    @media screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .card-header {
      margin-top: 0.625rem;
      padding: 0.8rem;
      color: #1f3370;
      font-weight: bold;
    }

    .h2 {
      font-family: Arial, sans-serif;
      font-size: 16px;
    }

    .loading-card {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 28.125rem;
    }

    .card {
      padding: 0.3125rem 0.625rem;
      border-radius: 1.25rem;
      min-width: 15.625rem;
      box-shadow: none !important;
      height: 100%;

      .card-number {
        display: flex;
        font-size: 1.875rem;
        color: $color-primary;
        font-weight: bold;
      }

      .card-subject {
        position: relative;
        color: $color-primary;
        font-size: 1.125rem;
        margin-bottom: 0.9375rem;
      }
    }
  }

  .dashboard-trends-header-container {
    margin: 2rem 0rem;

    h2 {
      font: normal normal normal 2.125rem/2.6875rem 'Futura PT Demi';
      letter-spacing: 0rem;
      color: #1f3370;
    }
    span {
      font: normal normal normal 0.875rem/1.25rem Arial;
      letter-spacing: 0rem;
      color: #5c5c5c;
      display: block;
    }
  }

  .dashboard-trends-chart-container {
    .dashboard-trends-chart {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 1.25rem;
      width: 100%;
      padding: 2rem;
      display: flex;
    }
  }

  .dashboard-details-header-container {
    display: grid;
    grid-template-columns: 6fr 6fr;
    margin: 2rem 0rem;

    h2 {
      font: normal normal normal 2.125rem/2.6875rem 'Futura PT Demi';
      letter-spacing: 0rem;
      color: #1f3370;
    }
    span {
      font: normal normal normal 0.875rem/1.25rem Arial;
      letter-spacing: 0rem;
      color: #5c5c5c;
      display: block;
    }
  }

  .dashboard-details-table-header-actions {
    display: grid;
    grid-template-columns: 6fr 6fr;
    padding: 1.5rem 0rem;

    @media screen and (max-width: 1290px) {
      grid-template-columns: 1fr;
    }

    @media screen and (max-width: 960px) {
      padding: 1rem 0rem;
    }

    .dashboard-details-table-search {
      padding: 1.5rem 0rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      .v-input__control {
        box-shadow: none !important;
        min-height: 2.625rem !important;
        width: 32.25rem;
        min-width: 32.25rem;

        @media screen and (max-width: 600px) {
          width: unset;
          min-width: unset;
        }
      }

      .v-field--variant-solo {
        background-color: white !important;
        height: 2.625rem;
      }

      .v-field__field,
      .v-field__prepend-inner {
        height: 2.625rem;
      }

      .v-field__input {
        padding-top: 0rem !important;
      }
      .mdi-magnify {
        font-size: 1.725rem;
        margin-right: 0.725rem;
      }

      .v-input__control,
      .v-btn--is-elevated {
        border: none !important;
        box-shadow: none !important;
        border-radius: 1.3125rem;
        backdrop-filter: blur(1.25rem);
        -webkit-backdrop-filter: blur(1.25rem);
      }
    }

    .dashboard-details-table-filters {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media screen and (max-width: 960px) {
        display: initial;
      }
      @media screen and (max-width: 800px) {
        .drilldown-search-filter-container {
          display: initial;
        }
      }
    }
  }

  .dashboard-details-search-chips {
    padding: 0 0.5rem 1.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;

    .clear-all-filters {
      text-align: left;
      font: normal normal bold 0.6875rem/1.875rem Arial;
      letter-spacing: -0.0069rem;
      color: #1f3370;
      opacity: 1;
      cursor: pointer;
      display: inline-block;
    }
  }

  .dashboard-details-footer-container {
    margin: 2rem 0rem;
    .dashboard-details-footer-text {
      font: normal normal normal 0.75rem/0.875rem Arial;
      letter-spacing: 0rem;
      color: #2d2d2d;
    }
  }

  .loader {
    margin: 3.125rem;
    margin-left: 2.1875rem;
    text-align: center;

    .loader-text {
      font-size: 1.875rem;
      color: $color-primary;
    }

    .loader-bar {
      position: relative;
      top: -1.875rem;
    }
  }

  .enrollment-status {
    position: relative;
    &:after {
      content: '\24D8';
      font-size: 0.625rem;
      color: #000;
    }
    &:hover {
      .tooltip-text {
        z-index: 999;
        visibility: visible;
        left: 0rem;
        top: 1rem;
        .tooltip-text-content {
          margin: 1rem 0rem !important;
        }
      }
    }
  }

  .status {
    font: normal normal normal 0.875rem/1rem Arial;
    letter-spacing: 0rem;
    color: #5c5c5c;
    opacity: 1;
    padding-right: 0.625rem;

    .status-info {
      position: relative;
      &:after {
        content: '\24D8';
        font-size: 0.625rem;
        color: #000;
      }
      &:hover .tooltip-text {
        visibility: visible;
        left: -6.9063rem;
        top: -0.625rem;
      }
    }
  }

  .dashboard-details-date-chips {
    float: right;
  }
}
</style>
