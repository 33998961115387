<template>
  <v-container style="margin: 20px 60px">
    <UploadExcel :on-success="handleSuccess" :before-upload="beforeUpload" :upload-type="uploadType"></UploadExcel>
  </v-container>
</template>
<script>
import UploadExcel from '@/views/plan/components/UploadExcel.vue';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'PlanImportOrg',
  components: {
    UploadExcel
  },
  data() {
    return {
      uploadType: ''
    };
  },
  mounted() {
    this.uploadType = this.$route.query?.uploadType;
  },
  methods: {
    // eslint-disable-next-line
    async beforeUpload(file) {},
    // eslint-disable-next-line
    handleSuccess({ results, header }) {
      this.$router.push('/plan-org-details');
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.planImportOrg')
    };
  }
};
</script>

<style lang="scss" scoped>
.integrate {
  background-color: green;
}

.browse {
  background-color: #ffb92e;
  color: #1f3370;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02px;
  font-weight: 600;
  font-family: 'Futura';
  margin: 0;
  border: none;
  padding: 0 30px;
  height: 40px;
  border-radius: 20px;
  line-height: 40px;
  margin: 28px 0px 0px 0px;
}
.banner {
  background: $color-primary-dark;
  height: 80px;
  width: 100vw;
  text-align: center;

  .right-item {
    color: #1f3370;
    float: right;
  }
}
.button {
  background-color: #ffb92e;
  color: #1f3370;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02px;
  font-weight: 600;
  font-family: 'Futura';
  margin: 0;
  border: none;
  padding: 0 30px;
  height: 40px;
  border-radius: 20px;
  line-height: 40px;
  margin: 28px 0px 0px 0px;
}
</style>
