<template>
  <div class="app-container t-px-10">
    <v-container fluid>
      <Notification v-if="notification.message !== ''" :viz="notification.available" :type="notification.type" :msg="notification.message" cta="reuploadOrg" @on-close-notification="onCloseNotification" />
      <h1 class="t-pb-10 t-pt-4 t-text-heading1 t-font-futura-pt-demi t-text-primary-color t-font-normal">{{ $t('workForce.title') }}</h1>
      <v-row>
        <v-col cols="4">
          <v-card v-if="!structureReady" class="structure-panel">
            <div class="loader">
              <v-card-text class="loader-text">{{ $t('workForce.loading') }}</v-card-text>
              <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
            </div>
          </v-card>
          <OrgStructure v-else :structure="structure" @get-plan="getPlan" @get-profile="getProfile" />
        </v-col>
        <v-col cols="8" v-if="selectDepartment">
          <div class="tab-invite">
            <v-tooltip v-if="selectedTabIndex === 0" top>
              <template v-slot:activator="{ props }">
                <v-btn data-el="invitePeople" class="button" rounded v-bind="props" @click="onInvite(plan.inviteCount)" :class="plan.inviteCount === 0 || broadcasting ? 'disabled' : ''" :disabled="plan.inviteCount === 0 || broadcasting">
                  <span v-if="plan.inviteCount === 1">{{ $t('workForce.send') }} {{ numberWithCommas(plan.inviteCount) }} {{ $t('workForce.invitation') }}</span>
                  <span v-else>{{ $t('workForce.send') }} {{ numberWithCommas(plan.inviteCount) }} {{ $t('workForce.invitations') }}</span>
                </v-btn>
              </template>
              <span>{{ $t('workForce.inviteSelectedEmployees') }}</span>
            </v-tooltip>
            <WorkforcePlannerTab v-if="isTabsReady" :tabs="tabs" @updateTabIndex="updatingTab" :selected="selectedTabIndex">
              <component
                v-if="plan.dataReady[selectedTabIndex]"
                :is="currentTab"
                slot="workforce-planner-component"
                class="workforce-planner-component"
                :plan="plan"
                :workforcePlannerResponse="workforcePlannerResponse"
                @get-profile="getProfile"
                @invite-count="handleInviteCountUpdate"
              />
            </WorkforcePlannerTab>
          </div>
          <div v-if="noData" class="loader">
            <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Workforce Planner" />
          </div>
          <div v-if="!plan.dataReady[selectedTabIndex] && !noData" class="loader">
            <v-card-text class="loader-text">{{ $t('common.titleCase.loading') }}</v-card-text>
            <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
          </div>
        </v-col>
        <v-col cols="8" v-else style="padding-top: 0">
          <WorkforcePlannerProfile :profileId="selectUser.id" :profileGuid="selectUser.guid" :key="JSON.stringify(selectUser)" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import localforage from 'localforage';
import OrgStructure from '@/views/plan/components/OrgStructure';
import WorkforcePlannerTab from '@/views/plan/components/WorkforcePlanner/WorkforcePlannerTab';
import WorkforcePlannerSummary from '@/views/plan/components/WorkforcePlanner/WorkforcePlannerSummary';
import WorkforcePlannerPeople from '@/views/plan/components/WorkforcePlanner/WorkforcePlannerPeople';
import WorkforcePlannerProfile from '@/views/plan/components/WorkforcePlanner/WorkforcePlannerProfile';
import Notification from '@/components/molecules/Notification';
import { translate } from '@/plugins/i18n.js';
import FeatureFlag from '../../../../components/molecules/FeatureFlag.vue';
import { numberWithCommas } from '@/utils';

const initialState = () => ({
  dataReady: false,
  structureReady: false,
  structure: [],
  noData: true,
  token: '',
  selectedEmployees: [],
  plan: {
    orgId: 0,
    orgName: '',
    futureSkills: [],
    currentSkills: [],
    people: [],
    prompt: '',
    dataReady: {
      0: false,
      1: false
    },
    inviteCount: 0
  },
  workforcePlannerResponse: {},
  notification: { available: false, type: '', message: '' },
  currentTab: 'People',
  selectedTabIndex: 0,
  tabs: [],
  isTabsReady: false,
  selectDepartment: true,
  selectUser: null,
  clientCategory: '',
  broadcasting: false
});
export default {
  name: 'WorkforcePlanner',
  components: { Notification, OrgStructure, WorkforcePlannerTab, WorkforcePlannerSummary, WorkforcePlannerPeople, WorkforcePlannerProfile, FeatureFlag },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters(['planLocation', 'company', 'logo', 'gcs_token', 'client_category', 'resetProfile', 'workForcePeopleUpdate'])
  },
  watch: {
    /******
     * the following state getters need to update the respective values in component for forceful/tab click navigation
     * and fire respective actions
     */
    async resetProfile(newValue) {
      if (newValue) {
        this.selectDepartment = true;
        this.selectUser = '';
        await this.resetSelectedProfile(false);
      }
    },
    async workForcePeopleUpdate(newValue) {
      if (newValue) {
        await this.getAllEmployees();
        await this.updateWorkForcePeople(false);
        this.EventBus.emit('workforce-profile-updated');
      }
    },
    '$i18n.locale'() {
      this.setTabs();
    }
  },
  async beforeMount() {
    this.clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
  },
  async mounted() {
    Object.assign(this.$data, initialState());
    this.plan.prompt = translate('workForce.selectDeptPrompt');
    const uploadOrgStatus = await this.$planAdmin.getUploadOrganizationStatus();
    const status = uploadOrgStatus.status.toLowerCase();
    this.notification.type = status.includes('running') ? 'warning' : status.includes('error') ? 'error' : '';
    this.notification.message = status.includes('running') ? translate('workForce.uploadRunningMessage') : status.includes('error') ? uploadOrgStatus.message : '';
    this.notification.available = status.includes('running') || status.includes('error');
    this.setTabs();
    this.updatingTab(this.selectedTabIndex);
    this.EventBus.emit('reset-tab-index');
    this.selectDepartment = true;
    this.plan.dataReady[0] = false;
    this.structureReady = false;
    this.clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.structure = await this.$planAdmin.getRootOrganizationStructure();
    if ({}.hasOwnProperty.call(this.structure, 'code') && this.structure.code == 401) {
      this.$stopSessionTimer();
    }
    await this.getAllEmployees();
    await this.getSkills(this.structure.length ? this.structure[0] : null);
    this.structureReady = true;
  },
  methods: {
    numberWithCommas,
    ...mapActions('plan', ['setSelectedProfile', 'resetSelectedProfile', 'updateWorkForcePeople']),
    setTabs() {
      this.tabs = [];
      this.tabs.push({ title: translate('workForce.people'), component: 'WorkforcePlannerPeople' });
      if (this.clientCategory.toLowerCase() != 'c1') {
        this.tabs.push({ title: translate('workForce.summary'), component: 'WorkforcePlannerSummary' });
      }
      this.isTabsReady = true;
    },
    updatingTab(index) {
      this.selectedTabIndex = index;
      this.currentTab = this.tabs[index || 0].component;
    },
    async getPlan(org) {
      this.plan = {
        orgId: org.id,
        orgName: org.name,
        futureSkills: [],
        currentSkills: [],
        people: [],
        prompt: '',
        dataReady: {
          0: false,
          1: false
        },
        inviteCount: 0
      };
      this.noData = false;
      this.dataReady = false;
      this.selectDepartment = true;
      if (org.id) {
        await this.getEmployees(org);
        await this.getSkills(org);
      } else {
        this.plan.prompt = translate('workForce.selectDeptPrompt');
        this.plan.dataReady = {
          0: false,
          1: false
        };
      }
    },
    async getAllEmployees() {
      const payload = {
        pageNo: 1,
        pageSize: 25,
        searchText: '',
        orgId: 'root'
      };

      const workforceResponse = await this.$planAdmin.getOrgEmployeeList(payload);
      this.plan.people = workforceResponse.users;
      this.workforcePlannerResponse = workforceResponse;
      this.plan.dataReady[0] = true;
      this.plan.orgId = 0;
      this.plan.orgName = '';
      this.noData = false;
    },
    async getEmployees(org) {
      const payload = {
        pageNo: 1,
        pageSize: 25,
        searchText: '',
        orgId: org.id
      };
      const workforceResponse = await this.$planAdmin.getOrgEmployeeList(payload);
      this.plan.people = workforceResponse.users;
      this.workforcePlannerResponse = workforceResponse;
      this.plan.dataReady[0] = true;
    },
    async getSkills(org) {
      const skills = org && org.id ? await this.$planAdmin.getRolesAndSkills({ orgIds: [org.id], userIds: [] }) : { futureSkills: [], skills: [] };
      if ({}.hasOwnProperty.call(skills, 'code') && skills.code == 401) {
        this.$stopSessionTimer();
      }
      this.plan.futureSkills = skills.futureSkills;
      this.plan.currentSkills = skills.skills;
      this.plan.dataReady[1] = true;
    },
    async getProfile(user) {
      this.selectDepartment = false;
      this.selectUser = user;
      await this.setSelectedProfile(true);
    },
    async onCloseNotification() {
      await this.$planAdmin.deleteUploadOrganizationStatus();
      this.notification.available = false;
    },
    handleInviteCountUpdate(selEmps) {
      this.selectedEmployees = selEmps;
    },
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });

      this.$toasted.show(msg, options);
    },
    async onInvite(inviteCount) {
      const guids = this.selectedEmployees.filter((emp) => emp.select && emp.email_address !== '').map((emp) => emp.guid);
      this.broadcasting = true;
      await this.broadcast(guids);
      this.plan.inviteCount = inviteCount;
      analytics.setVarAndTrack({ c: 'WorkforcePlanner', l: { name: 'InvitePeople', inviteCount: this.plan.inviteCount }, a: 'click' });
      this.$gtag.event('InviteEmployees', {
        event_category: 'click',
        event_label: this.plan.inviteCount > 1 ? `${this.plan.inviteCount}  employees invited` : `${this.plan.inviteCount}  employee invited`,
        value: 1
      });
      this.plan.inviteCount = 0;
      this.broadcasting = false;
      this.EventBus.emit('invite-sent');
      setTimeout(() => {
        this.notification.available = false;
      }, 5000);
    },
    async broadcast(guids) {
      await this.$planAdmin.batchUsersBroadcast({ guids: guids }).then((repsonse) => {
        if (repsonse && repsonse.error) {
          this.showToast(translate('workForce.failureInvitationsMessage'), 'error', 3000, true);
        } else {
          const message = translate('workForce.successfullyInvited') + this.plan.inviteCount + translate('workForce.selectedLearners');
          this.showToast(message, 'success', 3000, true);
        }
      });
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.morkforcePlanner')
    };
  }
};
</script>

<style lang="scss" scoped>
.app-container {
  background: $color-grey-lighter;
  height: 100%;
  width: 99%;
}

.loader {
  margin: 50px;
  text-align: center;

  .loader-text {
    font-size: 30px;
    color: $color-primary;
  }

  .loader-bar {
    position: relative;
    top: -30px;
  }
}

.select-column {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  .edit-button {
    align-self: center;
    width: 75px;
    margin: 0 0 1rem;
  }
}

.detail-column {
  background: #d8dce8;
}

.no-data {
  margin: 120px 100px;
  text-align: center;
  font-size: 25px;
  color: $color-data-2;
}

.error,
.warning,
.success {
  margin: 0px 10px;
  font-size: 25px;
  text-align: center;
  color: $color-white;
  border-radius: 15px;
}

.workforce-planner-component {
  margin: 0 50px;
  padding: 0 0;
}

.close-btn {
  position: relative;
  top: 0rem;
  float: right;
  width: 25px;
  height: 25px;
  background: $color-white !important;
  color: $color-data-2 !important;
}

.close-btn-success {
  position: relative;
  top: 0rem;
  float: right;
  width: 25px;
  height: 25px;
  background: $color-white !important;
  color: $color-success !important;
}

.button {
  position: absolute;
  right: 3.7rem;
  margin-top: -5px;
  text-transform: capitalize;
  background: $button-primary-color-bg !important;
  color: $button-primary-color-text !important;
  font-size: 1rem;
}
.disabled {
  background-color: $color-grey;
  color: $color-white;
}
</style>
