<template>
  <div v-if="employee" class="employee-details-flex">
    <div class="employee-details-container">
      <h3>{{ $t('admin_planner.basic_info') }}</h3>
      <div class="user-details">
        <div class="profile-logo">{{ employee.shortName }}</div>
        <div class="employee-meta">
          <h4>{{ employee.name }}</h4>
          <div v-if="employee.title">
            <h5>{{ $t('admin_planner.title') }}</h5>
            <p>{{ employee.title }}</p>
          </div>
          <div v-if="employee.jobName">
            <h5>{{ $t('admin_planner.role') }}</h5>
            <p>{{ employee.jobName }}</p>
          </div>
          <div v-if="employee.email">
            <h5>{{ $t('admin_planner.email') }}</h5>
            <p style="overflow-wrap: anywhere">{{ employee.email }}</p>
          </div>
        </div>
      </div>
      <div class="admin-type-section">
        <h3>{{ $t('admin_planner.admin_type') }}</h3>
        <p>{{ $t('admin_planner.admin_sub_text') }}</p>
      </div>
      <div class="admin-type-options">
        <v-radio-group v-model="adminSelect">
          <v-radio :label="$t('admin_planner.admin_type_options.option1')" value="group" color="#1F3370" :disabled="employee.rolesAsList.includes('GLOBAL_ADMIN')"></v-radio>
          <v-radio :label="$t('admin_planner.admin_type_options.option2')" value="global" color="#1F3370"></v-radio>
        </v-radio-group>
      </div>
    </div>
    <Tags :adminType="adminSelect" :employee="employee" :key="employee.id" ref="child" />
  </div>
</template>

<script>
import { ref } from 'vue';
import Tags from './Tags.vue';
export default {
  props: {
    employee: {
      type: Object,
      required: false,
      default: () => {
        return {};
      }
    }
  },
  components: { Tags },
  setup(props) {
    const adminSelect = ref('');
    const child = ref(null);
    return {
      props,
      adminSelect,
      child
    };
  },
  mounted() {
    this.adminSelect = Array.isArray(this.employee.rolesAsList) && this.employee.rolesAsList.length === 0 ? '' : this.employee.rolesAsList.includes('GROUP_ADMIN') ? 'group' : this.employee.rolesAsList.includes('GLOBAL_ADMIN') ? 'global' : '';
  },
  computed: {
    shortName() {
      return `${this.employee.firstName[0]}${this.employee.lastName[0]}`;
    }
  },
  watch: {
    employee() {
      this.adminSelect = Array.isArray(this.employee.rolesAsList) && this.employee.rolesAsList.length === 0 ? '' : this.employee.rolesAsList.includes('GROUP_ADMIN') ? 'group' : this.employee.rolesAsList.includes('GLOBAL_ADMIN') ? 'global' : '';
    }
  },
  methods: {
    onSave() {
      this.$refs.child.userUpdate(this.employee.guid);
    }
  }
};
</script>

<style lang="scss">
.employee-details-flex {
  display: flex;
  gap: 3rem;
}
.employee-details-container {
  padding: 2rem;
  background-color: #ffffff;
  max-width: 469px;
  border-radius: 24px;
  h3 {
    color: #1f3370;
    @include header-font-1-75;
    margin-bottom: 2rem;
  }
  .user-details {
    display: flex;
    gap: 2rem;
    padding-bottom: 2rem;
    border-bottom: 2px solid #d6d6d6;
    .profile-logo {
      width: 176px;
      height: 176px;
      min-width: 176px;
      border-radius: 200px;
      background: #1f3370;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      @include header-font-4;
      text-transform: uppercase;
    }
    .employee-meta {
      h4 {
        @include header-font-1-5;
        color: #2d2d2d;
        margin-bottom: 1rem;
      }
      h5 {
        @include body-font-bold-1;
        color: #2d2d2d;
      }
      p {
        @include body-font-1;
        color: #2d2d2d;
        margin-bottom: 1rem;
      }
    }
  }
  .admin-type-section {
    display: grid;
    gap: 1rem;
    padding-bottom: 1rem;
    margin-top: 2rem;
    border-bottom: 2px solid #d6d6d6;
    h3 {
      color: #1f3370;
      @include header-font-1-75;
      margin: unset;
    }
    p {
      @include body-font-1-25;
      color: #2d2d2d;
    }
  }
  .admin-type-options {
    display: grid;
    gap: 1rem;
    margin-top: 1rem;
  }
  .v-selection-control__input,
  .v-selection-control__wrapper {
    height: fit-content;
    width: fit-content;
  }
  .v-input__details {
    display: none;
  }
  .v-selection-control .v-label {
    color: #2d2d2d;
    @include body-font-1;
    opacity: 1;
  }
  .v-checkbox .v-selection-control {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
  }
  .v-radio {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
