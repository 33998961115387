<template>
  <div class="home">
    <v-row>
      <v-col cols="7" class="home-section-text">
        <h1>{{ $t('maintenance.maintenanceInProgress') }}</h1>
        <h1>{{ $t('maintenance.thankYouForPatience') }}</h1>
        <div style="margin-top: 2rem">{{ $t('maintenance.ifNeedSupport') }} <a href="mailto:support@proedge.pwc.com">support@proedge.pwc.com</a></div>
      </v-col>
      <v-col cols="5">
        <img alt="" width="500px" src="@/assets/images/pngs/maintenance.png" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'Maintenance',
  data() {
    return {};
  },
  metaInfo() {
    return {
      title: translate('pageTitles.maintenance')
    };
  }
};
</script>

<style lang="scss" scoped>
.home {
  display: block;
  padding: 3rem;
  max-width: 90rem;
  padding: 0rem 3rem;
  margin: 8rem auto 0;

  .home-section-text {
    margin: auto;
    padding: 3rem;
  }
}
</style>
