<template>
  <div>
    <div class="c1-dashboard-details-table-container" v-show="structureReady">
      <div class="c1-dashboard-details-treeview-container">
        <div class="tree-view-header">{{ $t('orgStructure.orgChart') }}</div>
        <!-- 
        <TreeView v-if="treeViewReady" class="c1-dashboard-details-treeview" :items="structure" activatable>
          <template v-slot:label="{ item, active }">
            <div v-if="item.hasOwnProperty('lead')" @click="active ? $event.stopPropagation() : onGetPlan(item)">
              <span :id="`treeview-node-label-${item.id}`" class="dept-name">{{ item.name }}</span>
            </div>
          </template>
        </TreeView> 
        -->

        <DataTableTreeView v-if="treeViewReady" class="c1-dashboard-details-treeview" :items="structure" :row="0" @on-get-plan="onGetPlan" />

        <div v-else>
          <div class="loader t-flex t-justify-center t-items-center">
            <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Tree View" />
          </div>
        </div>
      </div>

      <v-data-table class="c1-dashboard-details-table" :headers="headers" :items="filterEmployees" :items-per-page="25" :items-per-page-options="pageSizeOption" @current-items="currentEmployees" :header-props="{ sortIcon: null }" hide-default-footer>
        <!-- headers -->

        <template v-slot:[`no-data`]>
          <span class="no-data-available">{{ $t('common.noData10Learners') }}</span>
        </template>

        <template v-slot:[`header.status`]="{ header }">
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <span v-bind="props">{{ header.text }}</span>
            </template>
            <span>{{ $t('insights.statusToolTip') }}</span>
          </v-tooltip>
        </template>

        <template v-slot:[`body`] v-if="!tableDataReady">
          <div class="c1-dashboard-details-table-loader">
            <v-card-text class="loader-text">{{ $t('common.titleCase.loading') }}</v-card-text>
            <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
          </div>
        </template>
        <!-- body -->

        <template v-slot:[`item.learners`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props"> {{ item.learners }}</span>
              </template>
              <span class="capitalize">{{ item.learners }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.enrollmentRate`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props"> {{ item.enrollmentRate }}</span>
              </template>
              <span class="capitalize">{{ item.enrollmentRate }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.title }}</span>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.role`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.role }}</span>
              </template>
              <span>{{ item.role }}</span>
            </v-tooltip>
          </span>
        </template>

        <template v-slot:[`item.license`]="{ item }">
          <span :class="!item.isSelectable ? 'employee-data-field half-opacity' : 'employee-data-field'">
            <v-tooltip bottom>
              <template v-slot:activator="{ props }">
                <span v-bind="props">{{ item.license }}</span>
              </template>
              <span>{{ item.license }}</span>
            </v-tooltip>
          </span>
        </template>
      </v-data-table>
    </div>
    <div v-show="!structureReady">
      <div class="loader drilldown-loader t-flex t-justify-center t-items-center card">
        <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Drilldown Table" />
      </div>
    </div>
  </div>
</template>

<script>
import DataTableTreeView from '@/components/molecules/DataTableTreeView';
import { localizedDate } from '@/utils';

export default {
  props: {
    structureReady: { type: Boolean },
    treeViewReady: { type: Boolean },
    structure: { type: Array, default: () => [] },
    headers: { type: Array, default: () => [] },
    onGetPlan: { type: Function },
    currentEmployees: { type: Function },
    tableDataReady: { type: Boolean },
    totalPages: { type: Number },
    pageRange: { type: Number },
    handlePageClick: { type: Function },
    pageNum: { type: Number },
    employees: { type: Array }
  },
  components: { DataTableTreeView },
  data() {
    return {
      pageNumber: this.pageNum,
      pageSizeOption: [
        { value: 10, title: '10' },
        { value: 25, title: '25' },
        { value: 50, title: '50' },
        { value: 100, title: '100' }
      ]
    };
  },
  watch: {
    tableDataReady: function (val) {
      this.$nextTick(async () => {
        const container = document.getElementsByClassName('c1-dashboard-details-table')[0];

        if (container) {
          const wrapper = document.getElementsByClassName('v-table__wrapper')[0];
          wrapper.scrollLeft && (wrapper.scrollLeft = 0);
          const thead = container.getElementsByTagName('thead')[0];
          const tbody = container.getElementsByTagName('tbody')[0];

          if (val) {
            thead.style.display = 'table';
            tbody.style.overflow = 'auto';
          } else {
            thead.style.display = 'inline-table';
            tbody.style.overflow = 'hidden';
            tbody.style.width = `${container.clientWidth / 16}rem`;
          }
        }
      });
    }
  },
  computed: {
    filterEmployees() {
      return this.employees.map((x) => ({ ...x, isSelectable: !x.disabled }));
    }
  },
  methods: {
    localizedDate
  }
};
</script>

<style lang="scss">
@mixin scrollable {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 1.25rem;
    width: 0.25rem;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.c1-dashboard-details-table-container {
  display: grid;
  grid-template-columns: 6fr 6fr;
  margin-bottom: 5rem;

  .c1-dashboard-details-treeview-container {
    .tree-view-header {
      height: 4.6875rem;
      display: flex;
      align-items: center;
      padding: 2rem;
      font: normal normal bold 1rem/1.125rem Arial;
      letter-spacing: 0rem;
      color: #000000;
      border-bottom-left-radius: 0.3125rem;
      border-top-left-radius: 0.3125rem;
      background-color: #d0d0d0;
    }
    .c1-dashboard-details-treeview {
      margin-right: 2rem;
      overflow: scroll;
      @include scrollable;
      height: 30.25rem;

      .v-treeview-node__label {
        overflow: initial;
        text-overflow: initial;
      }

      .v-treeview-node__root {
        cursor: pointer;
        .v-icon {
          background: #e4e5e4 0% 0% no-repeat padding-box;
          border: 0.0625rem solid #e5e5e5;
          opacity: 1;
          border-radius: 6.25rem;
          height: 1.9375rem;
          width: 1.9375rem;
        }

        .dept-name {
          font: normal normal normal 1rem/2.5rem Arial;
          letter-spacing: 0rem;
          color: #000000;
          opacity: 1;
          cursor: pointer;
        }
      }
      .v-treeview-node__children {
        .v-treeview-node__root {
          .dept-name {
            font: normal normal normal 1rem/2.5rem Arial;
            letter-spacing: 0rem;
            color: #000000;
            opacity: 1;
          }
        }
      }

      .mdi-menu-down::before {
        content: '\F035D';
        content: '\F0140';
      }
      .v-treeview-node--active {
        background: rgba(255, 255, 255, 0.6);
        color: $color-white !important;
        min-width: fit-content;

        .dept-name {
          font-weight: bold !important;
        }
      }
    }
  }
  .v-data-table--fixed-header {
    border: none !important;
  }
  .c1-dashboard-details-table {
    background: inherit;
    display: grid;

    .c1-dashboard-details-table-loader {
      margin: 10rem 3.125rem 3.125rem;
      text-align: center;

      .v-input {
        display: contents !important;
        .v-field__overlay {
          display: none;
        }
      }

      .loader-text {
        font-size: 1.875rem;
        color: $color-primary;
      }

      .loader-bar {
        position: relative;
        top: -1.875rem;
      }
    }

    .no-data-available {
      font-size: 1.5rem;
      font-weight: normal;
      font-family: 'Futura PT Demi';
      text-align: start;
      padding: 12.5rem 2rem 2rem;
      color: rgba(0, 0, 0, 0.38);
    }

    .v-table__wrapper {
      @include scrollable;

      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 1.25rem;
        margin-bottom: 1.25rem;
        margin-right: 7rem;
      }
    }
    .v-table__wrapper {
      table {
        tbody {
          display: block;
          height: 29.75rem;
          overflow: auto;
          @include scrollable;
          overflow-x: hidden;

          .v-data-table-rows-no-data {
            height: 35vh;
            text-align: left;
          }
        }
        thead {
          border-bottom-right-radius: 0.3125rem !important;
          border-top-right-radius: 0.3125rem !important;
          height: 4.6875rem;
          border-radius: 0.3125rem;
          background: #d0d0d0;
        }

        thead,
        tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }

        thead th:nth-child(1),
        tbody td:nth-child(1) {
          width: 6rem;
        }

        thead th:nth-child(2),
        tbody td:nth-child(2) {
          width: 12rem;
        }

        thead th:nth-child(3),
        tbody td:nth-child(3) {
          width: 9rem;
        }

        thead th:nth-child(4),
        tbody td:nth-child(4) {
          width: 7rem;
        }

        thead th:nth-child(5),
        tbody td:nth-child(5) {
          width: 8rem;
        }

        thead {
          border-bottom-right-radius: 0.3125rem;
          border-top-right-radius: 0.3125rem;
          height: 4.6875rem;
          border-radius: 0.3125rem;
          background: rgba(0, 0, 0, 0.12);
          background: #d0d0d0;
          .v-data-table__td {
            background-color: #d0d0d0;
            height: 100%;
          }
          span {
            text-align: left;
            font: normal normal bold 1rem/1.125rem Arial;
            letter-spacing: 0rem;
            color: #000000;
            opacity: 1;
          }
        }

        tbody {
          tr:hover:not(.v-table__expanded__content) {
            &:nth-child(even) {
              background: #fff;
            }
          }

          .v-input {
            margin: 0rem;
            display: flex;
            align-items: center;
            height: 2.75rem;
            justify-content: center;
            padding-top: 1.375rem;
          }

          .employee-data-field {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 8.125rem;
          }
          .half-opacity {
            opacity: 0.5;
          }

          tr {
            td {
              height: 2.75rem;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 15rem;
            }
            .v-input {
              &:not(.v-input--is-disabled) {
                .mdi-checkbox-blank-outline {
                  border-radius: 0.25rem;
                  color: #1f3370;
                }
              }
            }
          }

          tr:nth-child(even) {
            td {
              background: #fff;
            }
          }
          tr:nth-child(odd) {
            td {
              background: #efefef 0% 0% no-repeat padding-box;
            }
          }
          tr.group-active-row-selection > td {
            transition: all 0.7s ease-in-out;
          }
          tr.group-active-row-selection > td > div {
            padding: 1.25rem;
            height: 2.5rem;
          }
          @keyframes anim {
            0% {
              transform: scale(1, 0);
            }
            100% {
              transform: scale(1, 1);
            }
          }
          tr.group-inactive > td {
            opacity: 0;
            line-height: 0;
            padding: 0 0.625rem;
            height: 0 !important;
          }
        }
        .v-data-table__td {
          border: none !important;
          font-weight: bold;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 1.125rem;
        }
      }
    }

    .v-data-table-footer {
      display: none;
    }
  }
}
.c1-dashboard-details-pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #efefef 0% 0% no-repeat padding-box;
  position: relative;
  height: 8.25rem;

  .pagination {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0rem;
    width: 50%;
  }

  .pagination-item {
    margin: 0 0.313rem;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  li.pagination-nav {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .pagination-item-link {
    color: var(--ion-color-post-gray);
    font-size: 0.875rem !important;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pagination-nav-link {
    position: relative;
    color: #5c5c5c;
    font-size: 3rem !important;
    vertical-align: middle;
    font-family: 'Futura PT Book';
    width: inherit;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 0.9375rem;
  }

  li.disabled {
    border: none;
    cursor: default;
    .pagination-item-link {
      cursor: default;
    }
  }

  li.active {
    border: 0.125rem solid #1dd1b0;
    border-radius: 0.25rem;
    background: white;
    .pagination-item-link {
      color: #000000;
    }
  }

  li:hover {
    background: #e0e1e1;
  }

  li .tooltiptext {
    visibility: hidden;
    width: max-content;
    height: 1.875rem;
    background-color: #efefef;
    color: #000000de;
    text-align: center;
    padding: 0.4375rem 0.625rem;
    border-radius: 0.25rem;
    position: absolute;
    z-index: 1;
    bottom: 90%;
    left: 50%;
    margin-left: -1.25rem;
    font-size: 0.75rem;
    font-family: 'Futura PT Demi';
  }

  .li:hover .tooltiptext {
    visibility: visible;
  }

  .pagination-tooltip {
    position: relative;
  }

  .pagination-tooltip::after {
    content: attr(data-tooltip-content);
    padding: 0.5rem;
    border-radius: 0.25rem;
    position: absolute;
    color: white;
    bottom: 135%;
    right: 50%;
    left: 50%;
    width: max-content;
    opacity: 0;
    font-size: 0.8rem;
    visibility: hidden;
    transform: translate(-50%, 1.125rem) scale(0.8);
    transition: visibility, opacity, transform 250ms;
    text-align: left;
    font: normal normal normal 0.75rem/1rem Arial;
    letter-spacing: 0rem;
    color: #000000de;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.125rem 0.375rem #0000001f;
    border-radius: 0.25rem;
  }

  .pagination-tooltip:hover::after {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0);
  }

  .pagination-buttons {
    position: absolute;
    right: 0;

    .v-btn--is-elevated {
      border: none !important;
      box-shadow: none !important;
      border-radius: 1.3125rem;
      backdrop-filter: blur(1.25rem);
      -webkit-backdrop-filter: blur(1.25rem);
    }

    .add-people-button {
      width: 8rem;
      height: 2.9375rem;
      background: #1f3370 0% 0% no-repeat padding-box;
      background: #ffb92e 0% 0% no-repeat padding-box;

      span {
        font: normal normal normal 1rem/1.375rem 'Futura PT Demi';
        letter-spacing: 0.0013rem;
      }

      &:disabled {
        border: 0.125rem solid #c4c4c4;
        span {
          color: inherit;
        }
      }
    }
  }
}

.drilldown-loader {
  height: 49.1875rem;
  background: #fff;
  margin: 0rem !important;
  border-radius: 1.25rem;
}
</style>
