import localforage from 'localforage'
import PlanAdminWeb from '@/api/plan-web'
import store from '@/store'
import router from '@/router'

// import router from '../../../src/router'

class Timer {
  defaults
  timers

  PlanAdminProvider

  constructor(logout, idle, renew, spy) {
    if (Timer._instance) {
      return Timer._instance
    }
    Timer._instance = this
    this.timerInitialized = false
    this.defaults = { logout: logout, idle: idle, renew: renew, spy: spy }
    this.timers = {
      logout: {
        id: null,
        period: logout,
        isRunning: false,
        startedAt: null,
        expireAt: null,
        remaining: null
      },
      idle: {
        id: null,
        period: idle,
        isRunning: false,
        startedAt: null,
        expireAt: null,
        remaining: null
      },
      renew: {
        id: null,
        period: renew,
        isRunning: false,
        startedAt: null,
        expireAt: null,
        remaining: null,
        currentToken: null,
        previousToken: null
      },
      spy: { id: null, period: spy }
    }

    this.PlanAdminProvider = new PlanAdminWeb()
  }

  updateTimes(logout, idle, renew, spy) {
    clearInterval(this.timers['spy'].id)
    this.timers['logout'].period = logout
    this.timers['idle'].period = idle
    this.timers['renew'].period = renew
    this.timers['spy'].period = spy
  }

  restoreDefaults() {
    clearInterval(this.timers['spy'].id)
    this.timers['logout'].period = this.defaults['logout']
    this.timers['idle'].period = this.defaults['idle']
    this.timers['renew'].period = this.defaults['renew']
    this.timers['renew'].currentToken = null
    this.timers['spy'].period = this.defaults['spy']
  }

  getTimeLeft() {
    let l = this.timers['logout']
    let i = this.timers['idle']
    let r = this.timers['renew']
    let s = this.timers['spy']

    if (l.remaining >= s.period) {
      l.remaining -= s.period
    } else {
      l.remaining = 0
    }

    if (i.remaining >= s.period) {
      i.remaining -= s.period
    } else {
      i.remaining = 0
    }

    if (r.remaining >= s.period) {
      r.remaining -= s.period
    } else {
      r.remaining = 0
    }

    return [l.remaining, i.remaining, r.remaining]
  }

  async startTimers(param) {
    /********
     * Added renew timer call if user refreshes the page at any point of time during session
     */
    if (param) {
      this.renewTimer()
    }
    this.timerInitialized = true
    let l = this.timers['logout']
    let i = this.timers['idle']
    let r = this.timers['renew']
    let s = this.timers['spy']

    let now = Date.now()

    l.id = setTimeout(this.logoutTimer.bind(this), l.period)
    i.id = setInterval(this.idleTimer.bind(this), i.period)
    r.id = setInterval(this.renewTimer.bind(this), r.period)
    s.id = setInterval(this.getTimeLeft.bind(this), s.period)

    l.startedAt = now
    i.startedAt = now
    r.startedAt = now

    l.isRunning = true
    i.isRunning = true
    r.isRunning = true

    l.remaining = l.period
    i.remaining = i.period
    r.remaining = r.period

    l.expireAt = l.startedAt + l.period
    i.expireAt = i.startedAt + i.period
    r.expireAt = r.startedAt + r.period

    await this.getAccessToken()
  }

  async restartTimers() {
    await this.resetTimers()
  }

  async resetTimers() {
    let l = this.timers['logout']
    let i = this.timers['idle']
    let s = this.timers['spy']

    clearInterval(l.id)
    clearInterval(i.id)
    clearInterval(s.id)

    let now = Date.now()

    l.id = setTimeout(this.logoutTimer.bind(this), l.period)
    i.id = setInterval(this.idleTimer.bind(this), i.period)
    s.id = setInterval(this.getTimeLeft.bind(this), s.period)

    l.startedAt = now
    i.startedAt = now

    l.isRunning = true
    i.isRunning = true

    l.remaining = l.period
    i.remaining = i.period

    l.expireAt = l.startedAt + l.period
    i.expireAt = i.startedAt + i.period
  }
  async stopTimers() {
    let l = this.timers['logout']
    let i = this.timers['idle']
    let r = this.timers['renew']
    let s = this.timers['spy']
    clearInterval(l.id)
    clearInterval(i.id)
    clearInterval(r.id)
    clearInterval(s.id)
  }

  async logoutTimer() {
    const isIdle = await this.isIdle()
    if (isIdle) {
      await this.logoutFromAPI()
      await this.stopTimers()
      await this.performLogoff()
      await this.redirectAfterLogoff(true)
    }
  }

  async idleTimer() {
    const isIdle = await this.isIdle()
    if (isIdle) {
      await this.showReminderPopup()
    }
  }

  async renewTimer() {
    const currentToken = await this.getAccessToken()
    if (currentToken) {
      const newToken = await this.renewToken(currentToken)
      await this.saveToken(newToken)
    }
  }

  async getAccessToken() {
    let r = this.timers['renew']
    const token = await localforage.getItem('my_access_token')

    if (r.currentToken != null) {
      r.previousToken = r.currentToken
    }

    r.currentToken = token
    return token
  }

  async renewToken(token) {
    const response = await this.PlanAdminProvider.renewToken({ token: token })
    return response.accessToken
  }

  async saveToken(token) {
    await store.dispatch('user/setAccessToken', token)
  }

  isIdle() {
    return store.state.idleVue.isIdle
  }

  async hasUser() {
    const user = await localforage.getItem('my_username')
    if (!user || user === '' || user === null) return false
    return !!user
  }

  async showReminderPopup() {
    await store.dispatch('user/setAboutTimeout', true)
  }

  async performLogoff() {
    await store.dispatch('user/logout')
  }

  async logoutFromAPI() {
    const accessToken = await localforage.getItem('my_access_token')
    let payload = { token: accessToken }
    await this.PlanAdminProvider.logout(payload)
  }

  async redirectAfterLogoff(timeout) {
    const route = timeout ? '/?timeout=true' : '/'
    router.push(route).catch(() => {})
  }
}

export default Timer
