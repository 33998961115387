import store from '@/store';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { translate } from '../plugins/i18n.js';
import localforage from 'localforage';

export function getLocalizedTag(inputString) {
  return translate(inputString);
}

export function getLocalizedContentType(inputString) {
  const contentTypeTag = 'common.filter.contentTypeOptions.' + inputString.toLowerCase();
  return translate(contentTypeTag);
}

export function getLocalizedLevel(inputString) {
  const levelTag = 'common.filter.levelOptions.' + inputString.toLowerCase();
  return translate(levelTag);
}

export function getLocalizedUsageStatus(inputString) {
  if (inputString === 'Active') {
    return translate('insights.usage.activeLabel');
  } else {
    return translate('insights.usage.notActiveLabel');
  }
}

export function getLocalizedEnrollmentRateStatus(inputString) {
  if (inputString === 'Enrolled') {
    return translate('insights.enrollments.enrolledLabel');
  } else {
    return translate('insights.enrollments.notEnrolledLabel');
  }
}

export function getLocalizedEnrollmentStatus(inputString) {
  switch (inputString) {
    case 'Onboarded':
      return translate('insights.enrollments.onboarded');
    case 'Registered':
      return translate('insights.enrollments.registered');
    case 'Invite Sent':
      return translate('insights.enrollments.inviteSent');
    case 'Invite Resent':
      return translate('insights.enrollments.inviteResent');
    case 'Invite Pending':
      return translate('insights.enrollments.invitePending');
    case 'Email Not Set':
      return translate('common.filter.statusOptions.emailNotSet');
    case 'Email Not Sent':
      return translate('insights.enrollments.emailNotSent');
    case 'No License':
      return translate('common.filter.statusOptions.noLicense');
    case 'N/A':
      return translate('common.notApplicable');
    default:
      return '';
  }
}

export function getLocalizedFunctions(inputString) {
  switch (inputString) {
    case 'Administration':
      return translate('common.filter.functionOptions.administration');
    case 'Actuarial':
      return translate('common.filter.functionOptions.actuarial');
    case 'Board of Directors':
      return translate('common.filter.functionOptions.boardOfDirectors');
    case 'Customer Service and Success':
      return translate('common.filter.functionOptions.customerServiceAndSuccess');
    case 'Cybersecurity':
      return translate('common.filter.functionOptions.cybersecurity');
    case 'Executive Office':
      return translate('common.filter.functionOptions.executiveOffice');
    case 'Finance':
      return translate('common.filter.functionOptions.finance');
    case 'Human Resources':
      return translate('common.filter.functionOptions.humanResources');
    case 'Information Technology':
      return translate('common.filter.functionOptions.informationTechnology');
    case 'Legal':
      return translate('common.filter.functionOptions.legal');
    case 'Manufacturing':
      return translate('common.filter.functionOptions.manufacturing');
    case 'Marketing':
      return translate('common.filter.functionOptions.marketing');
    case 'Maintenance':
      return translate('common.filter.functionOptions.maintenance');
    case 'Procurement':
      return translate('common.filter.functionOptions.procurement');
    case 'Research and Development':
      return translate('common.filter.functionOptions.researchAndDevelopment');
    case 'Risk':
      return translate('common.filter.functionOptions.risk');
    case 'Compliance and Internal Audit':
      return translate('common.filter.functionOptions.complianceAndInternalAudit');
    case 'Risk, Compliance and Internal Audit':
      return translate('common.filter.functionOptions.riskComplianceAndInternalAudit');
    case 'Sales':
      return translate('common.filter.functionOptions.sales');
    case 'Service Delivery':
      return translate('common.filter.functionOptions.serviceDelivery');
    case 'Supply Chain':
      return translate('common.filter.functionOptions.supplyChain');
    case 'Tax':
      return translate('common.filter.functionOptions.tax');
    default:
      return inputString;
  }
}

export function getLocalizedDuration(inputString) {
  const durationTag = 'common.filter.durationOptions.' + inputString;
  return translate(durationTag);
}

export function encodeDecodeRole(name) {
  if (!name) return;
  const mapEntitiesObj = {
    '&amp;': '&',
    '**percent**': '%',
    '&gt;': '>',
    '&lt;': '<'
  };
  const mapEntities = new Map(Object.entries(mapEntitiesObj));
  name = name.toString().replace(/%/g, '**percent**');
  name = decodeURIComponent(name);
  for (let [key, value] of mapEntities) {
    name = name.replaceAll(key, value);
  }
  return name;
}

export function getEnrollmentStatus(userProfile) {
  // Combines license, userState, and inviteStatus into a single value
  // Returns: N/A, Email Not Set, Invite Pending, Invite Sent, Invite Resent, Registered, Onboarded
  // Learner does not have license = N/A
  // Learner has license but invite has not been sent =  Invite Pending
  // Learner is invited the first time but has not registered= Invite Sent
  // Learner is invited more than once but has not registered = Invite Resent
  // Learner is registered = Onboarded
  // Learner email is updated in Plan = Invite Pending (follow same steps as new email)

  let u = userProfile.userInfo ? userProfile.userInfo : userProfile;
  if (userProfile.userInfo) {
    u.accessTags = userProfile.accessTags;
  }

  const l = u.accessTags ? u.accessTags.functionGroupTagNames.toString() : '';
  const s = u.userState; // null, enrolled, onboarded
  const i = u.inviteStatus; // null, Not Sent, Sent, Re-sent
  const e = u.email && u.email !== '' ? u.email : u.email_address && u.email_address !== '' ? u.email_address : null; // null, emailAddress

  if (l === null || l === '') return 'N/A';
  if (e === null) return 'Email Not Set';
  if (s === 'onboarded') return 'Onboarded';
  if (s === 'enrolled') return 'Registered';
  if ((s === null || s === 'None') && (i === null || i === 'Not Sent')) return 'Invite Pending';
  if (i === 'Sent') return 'Invite Sent';
  if (i === 'Re-sent') return 'Invite Resent';
  return 'N/A';
}

export function getFeatureFlagStrategy() {
  return {
    userWithId: (params) => {
      const { userIds } = params;
      return userIds.split(',').includes(store.state.user.username);
    }
  };
}

export async function handleRoleFunctionChange(component, value) {
  store.dispatch('plan/setSelectedFunc', value);
  const componentName = component.$options._componentTag;
  component.selectedFunction = componentName === 'RoleAddition' ? component.role.function : componentName === 'RoleDetails' ? component.editRoleFunction : component.selectedFunction;
  component.selectedDepartment = componentName === 'RoleAddition' ? component.role.department : componentName === 'RoleDetails' ? component.editRoleDepartment : component.selectedDepartment;

  if (component.selectedFunction === translate('common.titleCase.all') && component.functionFlag) {
    store.dispatch('plan/setSelectedFunc', null);
    component.functionFlag = false;
    component.selectedFunction = 0;
    component.selectedDepartment = 0;
    component.editRoleFunction = 0;
    component.editRoleDepartment = 0;
    component.displayRoles = component.roles;
    component.displayDepartments = component.$props.departments;
    component.$refs.displayFunctionRef?.$el?.blur();
  } else if (component.selectedFunction === translate('common.titleCase.all') && component.departmentFlag) {
    store.dispatch('plan/setSelectedFunc', null);
    component.selectedFunction = 0;
    component.editRoleFunction = 0;
    component.displayRoles = await component.roles.filter((role) => role.department && role.department.toLowerCase().includes(component.selectedDepartment.toLowerCase()));
    component.displayFunctions = component.displayRoles
      .filter((displayRole) => displayRole.function !== '')
      .map((displayRole) => displayRole.function)
      .sort();
    component.$refs.displayFunctionRef?.$el?.blur();
  } else if (component.selectedFunction === translate('common.titleCase.all')) {
    store.dispatch('plan/setSelectedFunc', null);
    component.displayRoles = component.roles;
  } else {
    if (!component.functionFlag && !component.departmentFlag) component.functionFlag = true;
    component.displayRoles = await component?.roles?.filter((role) => role.function && role.function.toLowerCase().includes(value && value.toLowerCase()));
    if (component.functionFlag) {
      store.dispatch('plan/setSelectedDept', null);
      component.displayDepartments = component.displayRoles
        .filter((displayRole) => displayRole.department !== '' && displayRole.department !== null)
        .map((displayRole) => displayRole.department)
        .sort();
    } else {
      component.displayFunctions.unshift(translate('common.titleCase.all'));
      component.displayRoles = await component.roles.filter((role) => role.function && role.function.toLowerCase().includes(value && value.toLowerCase()) && role.department && role.department.toLowerCase().includes(component.selectedDepartment.toLowerCase()));
    }
  }

  if (componentName === 'RoleAddition') {
    component.role.function = component.selectedFunction;
    component.role.department = component.selectedDepartment;
  }
}

export async function handleRoleDepartmentChange(component, value) {
  store.dispatch('plan/setSelectedDept', value);
  const componentName = component.$options._componentTag;
  component.selectedFunction = componentName === 'RoleAddition' ? component.role.function : componentName === 'RoleDetails' ? component.editRoleFunction : component.selectedFunction;
  component.selectedDepartment = componentName === 'RoleAddition' ? component.role.department : componentName === 'RoleDetails' ? component.editRoleDepartment : component.selectedDepartment;

  if (component.selectedDepartment === translate('common.titleCase.all') && component.departmentFlag) {
    store.dispatch('plan/setSelectedDept', null);
    component.departmentFlag = false;
    component.selectedFunction = 0;
    component.selectedDepartment = 0;
    component.editRoleFunction = 0;
    component.editRoleDepartment = 0;
    component.displayRoles = component.roles;
    component.displayFunctions = component.$props.functions;
    component.$refs.displayDepartmentRef?.$el?.blur();
  } else if (component.selectedDepartment === translate('common.titleCase.all') && component.functionFlag) {
    store.dispatch('plan/setSelectedDept', null);
    component.selectedDepartment = 0;
    component.editRoleDepartment = 0;
    component.displayRoles = await component.roles?.filter((role) => role.function && role.function.toLowerCase().includes(component.selectedFunction && component.selectedFunction.toLowerCase()));
    component.displayDepartments = component.displayRoles
      .filter((displayRole) => displayRole.department !== '')
      .map((displayRole) => displayRole.department)
      .sort();
    component.$refs.displayDepartmentRef?.$el?.blur();
  } else if (component.selectedDepartment === translate('common.titleCase.all')) {
    store.dispatch('plan/setSelectedDept', null);
    component.displayRoles = component.roles;
  } else {
    if (!component.functionFlag && !component.departmentFlag) component.departmentFlag = true;
    component.displayRoles = await component?.roles.filter((role) => role.department && role.department.toLowerCase().includes(value && value.toLowerCase()));
    if (component.departmentFlag) {
      component.displayFunctions = component.displayRoles
        .filter((displayRole) => displayRole.function !== '' && displayRole.function !== null)
        .map((displayRole) => displayRole.function)
        .sort();
    } else {
      component.displayDepartments.unshift(translate('common.titleCase.all'));
      component.displayRoles = await component.roles.filter(
        (role) => role.department && role.department.toLowerCase().includes(value && value.toLowerCase()) && role.function && role.function.toLowerCase().includes(component.selectedFunction && component.selectedFunction.toLowerCase())
      );
    }
  }

  if (componentName === 'RoleAddition') {
    component.role.function = component.selectedFunction;
    component.role.department = component.selectedDepartment;
  }
}
/*****
 * Util function to return the feature flag plan_enable_cloud_env,
 * This may be removed in future.
 */
export async function getCloudEnvFeatureFlag() {
  return store?.state?.flag?.enabled['plan_enable_cloud_env'];
}

/*****
 * Util function to redirect to maintenance page when the feature flag is on
 */
export function checkMaintenanceFlag() {
  if (store?.getters?.enabledFeatureFlags['plan_maintenance-page']) {
    window.location.href = '/maintenance';
    return true;
  }
  return false;
}

/****
 * Generate a new UUID
 */
export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getDuration(duration) {
  return duration < 60 ? `${duration}` : duration > 60 ? `${Math.round(duration / 60)}` : '';
}

export function getDurationType(duration) {
  return duration < 60 ? translate('common.time.min').toUpperCase() : duration > 60 ? translate('common.time.hr').toUpperCase() : '';
}

export function formatDuration(duration) {
  let durString = '';
  let durationSeconds = Number(duration);
  const durHours = Math.floor(durationSeconds / 3600);
  const durMinutes = Math.round((durationSeconds % 3600) / 60);
  const hDisplay = durHours > 0 ? durHours + ' ' + translate('common.time.hr') : '';
  const mDisplay = durHours > 0 ? (durMinutes > 0 ? durMinutes + ' ' + translate('common.time.min') : '') : durMinutes + ' ' + translate('common.time.min');
  durString = hDisplay + ' ' + mDisplay;

  return numberWithCommas(durString);
}

export function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberWithCommasRoundedToOnePrecision(x) {
  const roundedX = parseFloat(x).toFixed(1);
  return roundedX?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function convertDuration(duration) {
  if (duration >= 3600) {
    return parseFloat((duration / 3600).toFixed(1));
  } else if (duration > 59 && duration < 3600) {
    return Math.round(duration / 60);
  } else {
    return '';
  }
}

export function convertHourMinuteStringtoDecimal(hrminstring) {
  let hrs = 0;
  const hrs_pos = hrminstring.indexOf('h');
  if (hrs_pos > -1) hrs = hrminstring.substring(0, hrs_pos);

  const min_pos = hrminstring.indexOf('m', hrs_pos + 1);
  const mins = hrminstring.substring(hrs_pos + 1, min_pos);

  const dec = parseFloat(hrs) + parseFloat(mins) / 60;
  return dec.toFixed(1);
}

export function convertHourMinuteStringtoStringWithCommas(hrminstring) {
  let hrs = 0;
  const hrs_pos = hrminstring.indexOf('h');
  if (hrs_pos > -1) hrs = hrminstring.substring(0, hrs_pos);
  const min_pos = hrminstring.indexOf('m', hrs_pos + 1);
  const mins = hrminstring.substring(hrs_pos + 1, min_pos);

  const hrsWitCommasString = numberWithCommas(hrs);
  if (hrs > 0) return hrsWitCommasString + ' ' + translate('common.time.hrs') + ' ' + mins + ' ' + translate('common.time.min');
  else return mins + ' ' + translate('common.time.min');
}

export async function convertCME(cme) {
  cme = cme && cme.length ? cme.split(',').join('') : '';
  let cmeData = [];
  for (let i = 0; i < cme.length; i++) {
    if (cme[i] === 'C') {
      cmeData.push('Citizen');
    }
    if (cme[i] === 'M') {
      cmeData.push('Manager');
    }
    if (cme[i] === 'E') {
      cmeData.push('Executive');
    }
  }
  return cmeData;
}
/****
 * convert the formatType for saveLearningTrack api call
 */
export function learningTrackFormat(type) {
  return type.toLocaleLowerCase() === 'article' ? 'read' : type.toLocaleLowerCase() === 'audio' ? 'listen' : type.toLocaleLowerCase() === 'video' ? 'watch' : type;
}
export const dataFormatBg = {
  article: 't-bg-article',
  video: 't-bg-media',
  audio: 't-bg-media',
  course: 't-bg-course',
  credential: 't-bg-credential',
  track: 't-bg-course'
};

export const mapDurations = {
  duration1: '< 15 min',
  duration2: '15 - 60 min',
  duration3: '1 - 3 hr',
  duration4: '3 - 5 hr',
  duration5: '5 - 10 hr',
  duration6: '10+ hr'
};

export const inverseMapDurations = {
  '< 15 min': 'duration1',
  '15 - 60 min': 'duration2',
  '1 - 3 hr': 'duration3',
  '3 - 5 hr': 'duration4',
  '5 - 10 hr': 'duration5',
  '10+ hr': 'duration6'
};

export function sentenceCase(string) {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
}

export const getDateForCSV = () => {
  const now = new moment();
  return now.format('YYYY-MM-DDThh:mmZ');
};
export const generateCSVTimeFilter = (time) => {
  switch (time) {
    case 'ALLTIME':
      return 'AllTime';
    case 'DAYS30':
      return '30Days';
    case 'DAYS90':
      return '90Days';
    case 'YEAR':
      return '12Months';
    default:
      return '';
  }
};

export const getLearningTranscriptCSVLabels = () => {
  const labels = {
    orgName: { title: translate('insights.content.drilldownHeaders.orgName') },
    name: { title: translate('insights.content.drilldownHeaders.name') },
    email: { title: translate('insights.content.drilldownHeaders.email') },
    title: { title: translate('insights.content.drilldownHeaders.title') },
    role: { title: translate('insights.content.drilldownHeaders.roleTitle') },
    contentTile: { title: translate('insights.content.drilldownHeaders.contentTitle') },
    contentType: { title: translate('insights.content.drilldownHeaders.contentType') },
    provider: { title: translate('insights.content.drilldownHeaders.provider') },
    duration: { title: translate('insights.content.drilldownHeaders.contentDurationHrs') },
    progress: { title: 'Progress' },
    accreditation: { title: translate('insights.content.drilldownHeaders.accreditation') },
    trackTitle: { title: translate('insights.content.drilldownHeaders.trackTitle') },
    learningStatus: { title: translate('insights.content.drilldownHeaders.learningStatus') },
    activityDate: { title: translate('insights.content.drilldownHeaders.activityDate') },
    dueDate: { title: translate('insights.content.drilldownHeaders.dueDate') }
  };
  return labels;
};

export const printThis = async (el, fileName) => {
  const options = { type: 'dataURL', logging: false };
  const printCanvas = await html2canvas(el, options);
  const link = document.createElement('a');
  link.setAttribute('download', fileName || 'download.png');
  link.setAttribute('href', printCanvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'));
  link.click();
};

export const localizedDate = (moment, date, fromReportLastUpdated = false) => {
  if (!date) {
    return '';
  } else {
    const format = 'D MMM YYYY';
    let formattedDate;

    if (fromReportLastUpdated) {
      formattedDate = moment.utc(new Date(date)).local().format(format);
    } else {
      formattedDate = moment.utc(new Date(date)).format(format);
    }

    return moment(formattedDate, format, true).isValid() ? formattedDate : '';
  }
};

export const localizedDate24Hour = (moment, date) => {
  if (!date) {
    return '';
  } else {
    const format = 'D MMM YYYY HH:mm';
    const formattedDate = moment.utc(new Date(date)).local().format(format);
    return moment(formattedDate, format, true).isValid() ? formattedDate : '';
  }
};

export function getLocalDateFormat(dateTo) {
  return moment(dateTo).format('D MMM YYYY');
}

export const localizedDate12Hour = (moment, date) => {
  if (!date) {
    return '';
  } else {
    const format = 'D MMM YYYY h:mm A';
    const formattedDate = moment.utc(new Date(date)).local().format(format);
    return moment(formattedDate, format, true).isValid() ? formattedDate : '';
  }
};

export const localizedMonthYR = (dateString) => {
  if (!dateString) {
    return '';
  } else {
    const inputFormat = 'MM-YYYY';
    const englishDate = moment(dateString, inputFormat);
    const outputFormat = 'MMM-YY';
    const localizedDate = moment.utc(new Date(englishDate)).local().format(outputFormat);
    return moment(localizedDate, outputFormat, true).isValid() ? localizedDate : '';
  }
};

export const localizedMonthName = (monthNumber) => {
  if (!monthNumber) {
    return '';
  } else {
    const localizedDate = moment()
      .month(monthNumber - 1)
      .local()
      .format('MMMM');
    return moment(localizedDate, 'MMMM', true).isValid() ? localizedDate : '';
  }
};

export const localizedMonthYRName = (monthYRString) => {
  if (!monthYRString) {
    return '';
  } else {
    const monthNumber = monthYRString.substring(0, 2) - 1;
    const localizedDate = moment().month(monthNumber).local().format('MMMM');
    return moment(localizedDate, 'MMMM', true).isValid() ? localizedDate : '';
  }
};

export const localizedWeekName = (weekString) => {
  if (!weekString) {
    return '';
  } else {
    const parts = weekString.split(' ');
    var arr = [];
    arr.push(translate('common.weekOf'), parts[parts.length - 1]);
    return arr;
  }
};

export const localizedDateForCSVs = (moment, date) => {
  if (!date) {
    return '';
  } else {
    const format = 'YYYY-MM-DDTHH:MM:SS';
    const formattedDate = moment.utc(new Date(date)).format(format);
    return moment(formattedDate, format, true).isValid() ? formattedDate : '';
  }
};

export const getElementWidth = (text) => {
  const REM = 16;
  const minimum = 10;

  const proxyElement = document.createElement('div');
  const proxyContent = document.createTextNode(text);

  proxyElement.appendChild(proxyContent);
  proxyElement.style.position = 'absolute';
  proxyElement.style.zIndex = -10000;
  proxyElement.style.padding = '3rem';
  document.body.append(proxyElement);
  const width = proxyElement.offsetWidth;
  document.body.removeChild(proxyElement);

  return `${width / REM > minimum ? width / REM : minimum}rem`;
};

export const loginFlow = async ({ planAdmin, token, trustedDeviceId }) => {
  try {
    if (trustedDeviceId) await store.dispatch('user/setTrustedDevice', trustedDeviceId);

    await store.dispatch('user/setAccessToken', token);

    const tenant = await planAdmin.getUserTenantInfo();

    await store.dispatch('user/setClientCategory', tenant.clientCategory);
    await store.dispatch('user/setTenantType', tenant.tenantType);
    await store.dispatch('user/setTenantGuid', tenant.guid);
    await store.dispatch('user/setLastUpdated', tenant.lastUpdate);
    await store.dispatch('user/setTenantAccessTags', tenant.functionTags);

    const settings = tenant.settings && tenant.settings !== '' ? JSON.parse(tenant.settings) : null;

    if (settings) {
      await store.dispatch('user/setTenantSettings', settings);
      await store.dispatch('user/setNextReportDate', settings['report-nextgeneration-date']);
      await store.dispatch('user/setLastReportDate', settings['report-lastgeneration-date']);
      await store.dispatch('user/setIsDashboardDownloadOn', settings['plan.dashboard.download']);
      await store.dispatch('user/setShowShare', settings['ui-share-display']);
      await store.dispatch('user/setShowIdea', settings['ui-idea-display']);
      await store.dispatch('user/setTrackingDisabled', {}.hasOwnProperty.call(settings, 'trackingDisabled') ? settings['trackingDisabled'] : false);
      await store.dispatch('user/setRequiredLearning', {}.hasOwnProperty.call(settings, 'required-learning') ? settings['required-learning'] : false);
    }

    await store.dispatch('plan/setPlanCreated', await planAdmin.hasOrganizationStructure());

    const user = await planAdmin.getUserInfo();
    const userInfo = {
      firstName: user.firstName,
      email: user.email,
      company: user.company,
      guid: user.guid,
      logo: '',
      roles: user.roles ? user.roles : []
    };

    await store.dispatch('user/setUserInfo', userInfo);

    await store.dispatch('user/setRole', userInfo);

    if (!Object.keys(store.state.flag.features).length) {
      const response = await planAdmin.getBackupFeatureFlags();
      await store.dispatch('flag/setFeatureFlags', { featureFlags: response.features, strategyProviders: getFeatureFlagStrategy() });
    }

    return user;
  } catch (error) {
    console.error(error);
  }
};

export function updateNavigatonAnnouncerDebounced() {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      const pageTitle = document.head.querySelector('title');
      const navAnnouncer = document.querySelector('#navigation-announcer');
      const main = document.querySelector('#app');
      main?.setAttribute('tabindex', 0);
      main?.focus();

      if (!pageTitle || !navAnnouncer) return;

      navAnnouncer.textContent = pageTitle.innerText;
      setTimeout(() => {
        navAnnouncer.innerText = '';
      }, 10000);
    }, 1000);
  };
}
export async function getMetaData() {
  const token = await localforage.getItem('my_access_token');
  const jsonHeaders = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + token };
  const formHeaders = { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + token };
  const tokenHeader = { Authorization: 'Bearer ' + token };
  const jsonCharHeader = {
    Authorization: token,
    'Content-Type': 'application/json;charset=UTF-8'
  };
  const authFormHeaders = { Authorization: token, 'Content-Type': 'multipart/form-data' };
  const authorizationHeader = { Authorization: token };
  const authJsonHeader = { 'Content-Type': 'application/json', Authorization: token };
  const contentTypeHeader = { 'Content-Type': 'application/json' };

  return { token, jsonHeaders, formHeaders, tokenHeader, jsonCharHeader, authFormHeaders, authorizationHeader, authJsonHeader, contentTypeHeader };
}
