<template>
  <div class="role-container redesign t-text-sm mt-4 t-text-primary-color t-font-normal t-mb-10" :key="$route.params.guid">
    <div v-if="!dataReady" class="loader">
      <v-card-text class="loader-text">{{ $t('common.titleCase.loading') }}</v-card-text>
      <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
    </div>

    <v-container v-if="dataReady" style="padding: 0; margin: 0; max-width: 100% !important">
      <!-- Role Details -->
      <v-row>
        <v-col style="padding: 0; margin: 0 0.8rem">
          <v-row v-if="!editRole" flat class="card v-card v-card--flat v-theme--light v-card--density-default v-card--variant-elevated" :key="key" style="display: flex; align-items: center; justify-content: space-between">
            <div class="card-title"><span v-text="$sanitize(encodeDecodeRole(role.name))"></span></div>
            <v-btn data-el="editRole" rounded dark color="#1f3370" class="edit-role action-btn white--text" @click="onEditRole"> {{ $t('roleDetails.editRole') }} </v-btn>
          </v-row>
          <v-card v-else flat class="card" :key="key">
            <v-btn data-el="removeEditRole" class="remove-button" rounded :disabled="isEditing" @click="onRemoveEditRole">{{ $t('roleDetails.remove') }}</v-btn>
            <div class="edit-role-title">{{ $t('roleDetails.titleOfRole') }}</div>
            <v-text-field class="role-details-text-field" data-el="editRoleName" v-model="editRoleName" single-line :label="$t('roleDetails.namePlaceholder')" filled dense style="width: 60%"></v-text-field>
            <div class="edit-role-title">{{ $t('roleDetails.descriptionOfRole') }}</div>
            <v-textarea data-el="editRoleDesc" v-model="editRoleDescription" single-line :label="$t('roleDetails.descPlaceholder')" filled dense style="width: 98%"></v-textarea>
            <v-row dense>
              <v-col cols="6">
                <SelectView
                  data-el="editFunctionDropdown"
                  ref="displayFunctionRef"
                  :menu-props="{ bottom: true, offsetY: true }"
                  v-model="editRoleFunction"
                  :items="displayFunctions"
                  :label="$t('roleDetails.functionPlaceholder')"
                  @update:modelValue="handleFunctionChange"
                  style="width: 68%"
                  dense
                  outlined
                  variant="outlined"
                  density="compact"
                ></SelectView>
              </v-col>
              <v-col cols="4">
                <SelectView
                  data-el="editDepartmentDropdown"
                  ref="displayDepartmentRef"
                  :menu-props="{ bottom: true, offsetY: true }"
                  v-model="editRoleDepartment"
                  :items="displayDepartments"
                  :label="$t('roleDetails.deptPlaceholder')"
                  @update:modelValue="handleDepartmentChange"
                  dense
                  outlined
                  variant="outlined"
                  density="compact"
                >
                </SelectView>
              </v-col>
              <v-col cols="2">
                <v-dialog v-model="departmentDialog" max-width="500px">
                  <template v-slot:activator="{ props }">
                    <v-btn icon color="#1f3370" class="circle-add-btn" v-bind="props" variant="text">
                      <v-icon dark style="font-size: 35px"> mdi-plus-circle </v-icon>
                    </v-btn>
                  </template>
                  <v-card style="padding: 1rem">
                    <v-card-title class="dialog-title">{{ $t('roleDetails.createNewDept') }}</v-card-title>
                    <div class="dialog-content">{{ $t('roleDetails.inputInfo') }}</div>
                    <v-alert v-model="alert.isAlert" type="error" border="start" close-text="Close Alert" role="alert" dark dismissible>{{ alert.message }}</v-alert>
                    <v-text-field data-el="addNewDepartment" v-model="editRoleDepartment" outlined :placeholder="$t('roleAddition.newDeptPlaceholder')"></v-text-field>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn data-el="addNewDepartmentConfirm" rounded class="save-button" @click="onConfirmEditDepartment" text>{{ $t('button.confirm') }}</v-btn>
                      <v-btn data-el="addNewDepartmentCancel" rounded class="cancel-button" @click="onCancelEditDepartment" text>{{ $t('button.cancel') }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-checkbox v-model="editRoleReprocess" :label="$t('roleDetails.editRoleReprocess')" style="margin-top: 0; padding-top: 0" />
            <div v-if="errorMsg.length" role="alert" class="error-msg">* {{ errorMsg }}</div>
            <div v-if="isEditing" class="loader" style="margin: 0 50px; padding: 0">
              <v-card-text class="loader-text">{{ $t('roleDetails.processing') }}</v-card-text>
              <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
            </div>
            <v-row style="width: 30%; margin: 0 35%" no-gutters>
              <v-col data-el="cancelEditRole" style="text-align: center; padding: 0"
                ><v-btn class="cancel-button" rounded color="primary" :disabled="isEditing" @click="onCancelEditRole">{{ $t('button.cancel') }}</v-btn></v-col
              >
              <v-col data-el="saveEditRole" style="text-align: center; padding: 0"
                ><v-btn class="save-button" rounded :disabled="isEditing" @click="onSaveEditRole">{{ $t('button.save') }}</v-btn></v-col
              >
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!-- Learning track details -->
      <v-row v-if="tracks">
        <v-col class="track-detail">
          <keep-alive>
            <LearningTrackDetail :roleName="role.name" :roleGuid="role.guid" :roleSkills="role.skills"></LearningTrackDetail>
          </keep-alive>
        </v-col>
      </v-row>

      <!-- Skill Details -->
      <v-row>
        <v-col cols="12" style="margin-bottom: 1.25rem">
          <v-card flat class="card" :key="key">
            <v-row no-gutters>
              <v-col cols="7" style="padding: 0"
                ><div class="card-title">{{ $t('roleDetails.roleSkillGroup') }}</div></v-col
              >

              <v-col cols="5" style="padding: 0">
                <v-text-field
                  data-el="searchSkill"
                  v-model="searchSkill"
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  single-line
                  :placeholder="$t('roleDetails.addSkillPlaceholder')"
                  :aria-label="ariaLabel"
                  filled
                  rounded
                  dense
                  clearable
                  variant="solo"
                  @click:clear="onClearSearchSkill"
                  @keyup="onSearchSkill"
                  v-click-outside="onClickOutside"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" style="padding: 0">
                <div class="skill-panel-header">
                  <div class="skill-importance">{{ $t('roleDetails.importance') }}</div>
                  <div class="skill-label">{{ $t('roleDetails.skillsAndPriorityDigitalSkills') }}</div>
                </div>
                <div class="skill-panel-container scrollable" ref="skillGroup">
                  <div data-el="selectSkill" v-for="(skill, index) in role.skills" :key="index" class="progress-bar" @click="onSelectSkill(index)" :class="index === selectSkillIndex ? 'select' : ''">
                    <v-progress-linear :model-value="skill.importance" height="10" reverse rounded class="progress" color="#1f3370"></v-progress-linear>
                    <span class="progress-text" :class="skill.isFutureSkill ? 'future-skill' : 'none-skill'">{{ skill.name }}</span>
                  </div>
                  <div v-if="!role.skills.length" style="padding: 1rem">{{ $t('roleDetails.noneAvailable') }}</div>
                </div>
              </v-col>
              <v-col v-if="selectSkill && !searchCompleted && !editSkill" cols="6" class="skill-detail">
                <v-btn data-el="editSkill" icon color="#ffb92e" style="float: right; position: relative; top: -15px; right: 0px; width: 50px; height: 50px" @click="onEditSkill">
                  <v-icon dark> mdi-pencil </v-icon>
                </v-btn>
                <div class="skill-detail-title">{{ selectSkill.name }}</div>
                <div class="skill-detail-text">{{ selectSkill.descriptionText }}</div>
                <div v-if="selectSkill.isFutureSkill" class="skill-detail-title" style="margin: 1rem 0rem">{{ $t('roleDetails.priorityDigSkill') }}</div>
                <div class="skill-detail-title" style="margin-top: 1rem">{{ $t('roleDetails.importance') }}</div>
                <div class="skill-detail-importance">{{ selectSkill.importance }} / 100</div>
              </v-col>
              <v-col v-if="selectSkill && !searchCompleted && editSkill">
                <div class="edit-skill-panel">
                  <v-btn data-el="removeEditSkill" class="remove-button" rounded @click="onRemoveEditSkill">{{ $t('roleDetails.remove') }}</v-btn>
                  <div class="skill-edit-title">{{ selectSkill.name }}</div>
                  <div class="skill-detail-title">Description</div>
                  <div class="skill-detail-text">{{ selectSkill.descriptionText }}</div>
                  <div class="skill-detail-title" style="margin: 1rem 0rem">{{ $t('roleDetails.priorityDigSkill') }}<v-checkbox v-model="editIsFutureSkill" color="#00A38A" style="float: right; position: relative; top: -1.2rem"></v-checkbox></div>
                  <div class="skill-detail-title" style="margin-top: 1rem; margin-bottom: 1rem">{{ $t('roleDetails.importance') }}</div>
                  <v-row>
                    <v-col cols="3" style="padding: 0"><v-text-field v-model="editImportance" :maxlength="3" single-line hide-details filled dense rounded class="score" style="width: 5rem" /></v-col>
                    <v-col align-self="center" style="padding: 0" class="skill-detail-importance">/100</v-col>
                  </v-row>
                  <v-row v-if="errorMsg.length" class="important-error">
                    <v-col role="alert">* {{ errorMsg }}</v-col>
                  </v-row>
                  <v-row style="margin-top: 1rem">
                    <v-col data-el="cancelEditSkill" style="text-align: center; padding: 0"
                      ><v-btn class="cancel-button" rounded color="primary" @click="onCancelEditSkill">{{ $t('button.cancel') }}</v-btn></v-col
                    >
                    <v-col data-el="saveEditSkill" style="text-align: center; padding: 0"
                      ><v-btn class="save-button" rounded @click="onSaveEditSkill">{{ $t('button.save') }}</v-btn></v-col
                    >
                  </v-row>
                </div>
              </v-col>
              <v-col v-if="searchSkill && searchCompleted" class="search-skill-panel scrollable">
                <v-row v-for="(skill, index) in foundSkills" :key="index">
                  <v-col cols="8" style="padding: 0 0 5px 0">{{ skill.title }}</v-col>
                  <v-col cols="4" style="padding: 0px; text-align: right">
                    <v-btn class="add-button" :aria-label="'add ' + skill.title" rounded :disabled="skill.isAdded" @click="onAddSkill(skill)">{{ $t('button.add') }}</v-btn>
                  </v-col>
                </v-row>
                <div v-if="!foundSkills.length" style="padding: 0rem 2rem">{{ $t('roleDetails.noSkill') }} {{ $sanitize(searchSkill) }}</div>
              </v-col>
              <v-col v-if="!searchSkill && !searchCompleted && addSkill">
                <div class="edit-skill-panel">
                  <div class="skill-edit-title">{{ $sanitize(addSkill.name) }}</div>
                  <div class="skill-detail-title">{{ $t('roleDetails.description') }}</div>
                  <div class="skill-detail-text">{{ $sanitize(addSkill.description) }}</div>
                  <div class="skill-detail-title" style="margin: 1rem 0rem">{{ $t('roleDetails.priorityDigSkill') }}<v-checkbox v-model="addSkill.isFutureSkill" color="#00A38A" style="float: right; position: relative; top: -1.2rem"></v-checkbox></div>
                  <div class="skill-detail-title" style="margin-top: 1rem; margin-bottom: 1rem">{{ $t('roleDetails.importance') }}</div>
                  <v-row>
                    <v-col cols="3" style="padding: 0"><v-text-field v-model="addSkill.importance" :maxlength="3" single-line hide-details filled dense rounded class="score" style="width: 5rem" /></v-col>
                    <v-col align-self="center" style="padding: 0" class="skill-detail-importance">/100</v-col>
                  </v-row>
                  <v-row v-if="errorMsg.length" class="important-error">
                    <v-col role="alert">* {{ errorMsg }}</v-col>
                  </v-row>
                  <v-row style="margin-top: 1rem">
                    <v-col data-el="cancelAddSkill" style="text-align: center; padding: 0"
                      ><v-btn class="cancel-button" rounded color="primary" @click="onCancelAddSkill">{{ $t('button.cancel') }}</v-btn></v-col
                    >
                    <v-col data-el="saveAddSkill" style="text-align: center; padding: 0"
                      ><v-btn class="save-button" rounded @click="onSaveAddSkill">{{ $t('button.save') }}</v-btn></v-col
                    >
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { handleRoleFunctionChange, handleRoleDepartmentChange, encodeDecodeRole } from '@/utils';
import { translate } from '@/plugins/i18n.js';
import LearningTrackDetail from '@/views/catalog/components/LearningTrackDetail';
import SelectView from '@/components/molecules/SelectView';

export default {
  name: 'RoleDetails',
  components: { LearningTrackDetail, SelectView },
  data() {
    return {
      key: 1,
      token: '',
      selectSkillIndex: -1,
      selectSkill: null,
      searchSkill: '',
      ariaLabel: '',
      searchCompleted: false,
      foundSkills: [],
      isEditing: false,
      editRole: false,
      editRoleName: '',
      editRoleDescription: '',
      editRoleFunction: '',
      editRoleDepartment: '',
      editRoleReprocess: false,
      editSkill: false,
      editImportance: 0,
      editIsFutureSkill: false,
      dataReady: false,
      errorMsg: '',
      functionDialog: false,
      departmentDialog: false,
      alert: {
        isAlert: false,
        message: ''
      },

      displayRoles: [],
      displayDepartments: [],
      displayFunctions: [],
      selectedFunction: '',
      selectedDepartment: '',
      functionFlag: false,
      departmentFlag: false,
      scrollPosition: 0,
      addSkill: null,
      encodeDecodeRole,
      tracks: null
    };
  },
  computed: {
    ...mapState('plan', ['allFunctions', 'allDepartments', 'selectedRole']),
    ...mapGetters(['company', 'showModal', 'enabledFeatureFlags'])
  },
  async updated() {
    if (this.$refs.skillGroup) {
      this.$refs.skillGroup.scrollTop = this.scrollPosition;
    }
  },
  async mounted() {
    await this.onSelectRole();
    this.displayFunctions = this.allFunctions;
    this.displayDepartments = this.allDepartments;
    this.SET_CURRENT_ROLE(this.role.guid);
  },
  methods: {
    ...mapMutations('user', ['SET_SHOW_MODAL', 'SET_ROLE_NAME_TRACK', 'SET_SKILLS_TRACK', 'SET_ROLE_GUID', 'SET_TRACK_GUID']),
    ...mapMutations('plan', ['SET_ALL_DEPARTMENTS', 'SET_ALL_FUNCTIONS', 'SET_CURRENT_ROLE']),
    async onSelectRole() {
      this.role = await this.$planAdmin.getRoleByGuid({ roleGuid: this.$route.params.guid });
      this.editRoleFunction = this.role.function ? this.role.function : '';
      this.editRoleDepartment = this.role.department ? this.role.department : '';
      const skills = await this.$planAdmin.getAllSkillsForRole({ guid: this.role.guid });
      if (skills.code === 401) {
        const removeCookies = () => {
          var res = document.cookie;
          var multiple = res.split(';');
          for (var i = 0; i < multiple.length; i++) {
            var key = multiple[i].split('=');
            document.cookie = key[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
          }
        };
        removeCookies();
        this.$stopSessionTimer();
        return;
      }
      this.role.skills = skills;
      this.tracks = true;
      this.dataReady = true;
    },
    async onSelectSkill(index) {
      if (index != -1) {
        this.scrollPosition = this.$refs.skillGroup.scrollTop;
        this.selectSkillIndex = index;
        console.log(this.role.skills, index);
        const detail = await this.$planAdmin.getSkillDescription({ skill: encodeURIComponent(this.role.skills[index].name) });
        this.role.skills[index].descriptionText = detail.description;
        this.selectSkill = this.role.skills[index];
        this.editImportance = this.selectSkill.importance;
        this.editIsFutureSkill = this.selectSkill.isFutureSkill;
        this.onClearSearchSkill();
      }
    },
    async onEditRole() {
      this.editRoleName = encodeDecodeRole(this.role.name);
      this.editRoleDescription = this.role.description;
      this.editRoleFunction = this.role.function ? this.role.function : '';
      this.editRoleDepartment = this.role.department ? this.role.department : '';
      this.editRole = true;
      analytics.setVarAndTrack({ c: 'RoleDetails', l: this.role.name, a: 'edit' });
      this.$gtag.event('RoleDetails', {
        event_category: 'edit',
        event_label: this.role.name,
        value: 1
      });
    },
    onCancelEditRole() {
      this.errorMsg = '';
      this.editRoleReprocess = false;
      this.editRole = false;
      analytics.setVarAndTrack({ c: 'RoleDetails', l: this.role.name, a: 'cancel' });
      this.$gtag.event('RoleDetails', {
        event_category: 'cancel',
        event_label: this.role.name,
        value: 1
      });
    },
    async onSaveEditRole() {
      if (!this.editRoleFunction.length || !this.displayFunctions.includes(this.editRoleFunction)) {
        this.errorMsg = 'Role function cannot be empty';
        return;
      }
      if (!this.editRoleDepartment) {
        this.errorMsg = 'Role department cannot be empty';
        return;
      }
      this.isEditing = true;
      const payload = {
        roleGuid: this.role.guid,
        role: { name: this.editRoleName, description: this.editRoleDescription, reProcessDescription: this.editRoleReprocess, function: this.editRoleFunction, department: this.editRoleDepartment }
      };
      const response = await this.$planAdmin.editRole(payload);
      if (response.error) {
        this.errorMsg = response.message;
        return;
      }
      // only refresh the Role List if the name was changed
      if (this.editRoleName != this.role.name) {
        this.EventBus.emit('role-name-change');
      }
      const role = await this.$planAdmin.getRoleByGuid({ roleGuid: this.role.guid });
      const skills = await this.$planAdmin.getAllSkillsForRole({ guid: this.role.guid });
      this.role.name = encodeDecodeRole(role.name);
      this.role.description = role.description;
      this.role.function = role.function ? role.function : '';
      this.role.department = role.department ? role.department : '';
      this.role.skills = skills;
      this.errorMsg = '';
      this.isEditing = false;
      this.editRole = false;
      this.key = Math.random();
      analytics.setVarAndTrack({ c: 'RoleDetails', l: this.role.name, a: 'save' });
      this.$gtag.event('RoleDetails', {
        event_category: 'save',
        event_label: this.role.name,
        value: 1
      });
    },
    async onRemoveEditRole() {
      this.isEditing = true;
      const response = await this.$planAdmin.deleteRole({ roleGuid: this.role.guid });
      if (response.error) {
        this.errorMsg = response.message;
        return;
      }
      this.errorMsg = '';
      this.isEditing = false;
      this.editRole = false;
      this.EventBus.emit('on-delete-role');
      analytics.setVarAndTrack({ c: 'RoleDetails', l: this.role.name, a: 'remove' });
      this.$gtag.event('RoleDetails', {
        event_category: 'remove',
        event_label: this.role.name,
        value: 1
      });
    },
    onClickOutside() {
      if (this.searchCompleted) {
        this.searchCompleted = false;
      }
    },
    onClearSearchSkill() {
      this.errorMsg = '';
      this.searchSkill = '';
      this.foundSkills = [];
      this.searchCompleted = false;
    },
    async onSearchSkill() {
      if (this.searchSkill.length > 2) {
        this.foundSkills = await this.$planAdmin.searchSkillByName({ skill: encodeURIComponent(this.searchSkill) });
        await this.foundSkills.map((found) => {
          found.isAdded = this.role.skills.findIndex((skills) => found.id === skills.guid) > -1;
        });
        this.searchCompleted = true;
        analytics.setVarAndTrack({ c: 'RoleDetails', l: this.searchSkill, a: 'search skill for adding to role' });
        this.$gtag.event('RoleDetails', {
          event_category: 'search',
          event_label: `skill for role: ${this.searchSkill}`,
          value: 1
        });
      } else if (this.searchSkill.length == 0 || this.searchSkill == null) {
        this.searchCompleted = false;
        this.onSelectSkill(this.selectSkillIndex);
      }

      if (this.foundSkills.length == 0) {
        this.ariaLabel = `Cannot find skill ${this.searchSkill}`;
      } else {
        this.ariaLabel = `you got ${this.foundSkills.length} result`;
      }
    },
    async onAddSkill(skill) {
      this.onClearSearchSkill();
      this.selectSkill = null;
      this.selectSkillIndex = -1;
      this.addSkill = await this.$planAdmin.getSkillDescription({ skill: encodeURIComponent(skill.title) });
      this.addSkill.importance = 0;
      analytics.setVarAndTrack({ c: 'RoleDetails', l: encodeURIComponent(skill.title), a: 'add searched skill to role' });
      this.$gtag.event('RoleDetails', {
        event_category: 'add',
        event_label: `searched skill for role: ${encodeURIComponent(skill.title)}`,
        value: 1
      });
    },
    async onSaveAddSkill() {
      if (this.addSkill.importance < 0 || this.addSkill.importance > 100) {
        this.errorMsg = 'Skill importance must be between 0 and 100';
      } else {
        this.errorMsg = '';
        await this.$planAdmin.addSkill({ roleGuid: this.role.guid, skill: { skillGuid: this.addSkill.guid, importance: this.addSkill.importance } });
        await this.onSelectRole();
        this.key = Math.random();
        analytics.setVarAndTrack({ c: 'RoleDetails', l: this.addSkill.guid, a: 'add' });
        this.$gtag.event('RoleDetails', {
          event_category: 'add',
          event_label: this.addSkill.guid,
          value: 1
        });
        this.addSkill = null;
      }
    },
    async onCancelAddSkill() {
      this.addSkill = null;
      analytics.setVarAndTrack({ c: 'RoleDetails', l: this.selectSkill.guid, a: 'cancel' });
      this.$gtag.event('RoleDetails', {
        event_category: 'cancel',
        event_label: this.selectSkill.guid,
        value: 1
      });
    },
    async onEditSkill() {
      this.onClearSearchSkill();
      this.editImportance = this.selectSkill.importance;
      this.editIsFutureSkill = this.selectSkill.isFutureSkill;
      this.editSkill = true;
      analytics.setVarAndTrack({ c: 'RoleDetails', l: this.selectSkill.name, a: 'edit' });
      this.$gtag.event('RoleDetails', {
        event_category: 'edit',
        event_label: this.selectSkill.name,
        value: 1
      });
    },
    onCancelEditSkill() {
      this.editSkill = false;
      analytics.setVarAndTrack({ c: 'RoleDetails', l: this.selectSkill.name, a: 'cancel' });
      this.$gtag.event('RoleDetails', {
        event_category: 'cancel',
        event_label: this.selectSkill.name,
        value: 1
      });
    },
    async onSaveEditSkill() {
      if (this.editImportance < 0 || this.editImportance > 100) {
        this.errorMsg = 'Skill importance must be between 0 and 100';
      } else {
        this.errorMsg = '';
        const response = await this.$planAdmin.addSkill({ roleGuid: this.role.guid, skill: { skillGuid: this.selectSkill.guid, importance: this.editImportance, isFutureSkill: this.editIsFutureSkill } });
        if (response.error && response.message) {
          this.errorMsg = response.message;
          return;
        }
        this.selectSkill = '';
        this.editSkill = false;
        this.selectSkillIndex = -1;
        await this.onSelectRole();
        this.key = Math.random();
        analytics.setVarAndTrack({ c: 'RoleDetails', l: this.selectSkill.name, a: 'edit' });
        this.$gtag.event('RoleDetails', {
          event_category: 'edit',
          event_label: this.selectSkill.name,
          value: 1
        });
      }
    },
    async onRemoveEditSkill() {
      await this.$planAdmin.removeSkill({ roleGuid: this.role.guid, skillGuid: this.selectSkill.guid });
      this.editSkill = false;
      this.selectSkill = '';
      this.selectSkillIndex = -1;
      await this.onSelectRole();
      this.key = Math.random();
    },
    onCancelEditDepartment() {
      this.departmentDialog = false;
      this.editRoleDepartment = this.role.department ? this.role.department : '';
      this.alert.isAlert = false;
    },
    onConfirmEditDepartment() {
      if (this.editRoleDepartment.length && !this.displayDepartments.includes(this.editRoleDepartment)) {
        this.displayDepartments.push(this.editRoleDepartment);
        this.SET_ALL_DEPARTMENTS(this.displayDepartments);
        this.departmentDialog = false;
        this.alert.isAlert = false;
      } else if (!this.editRoleDepartment.length) {
        this.alert.isAlert = true;
        this.alert.message = 'This Department Cannot Be Empty';
      } else {
        this.alert.isAlert = true;
        this.alert.message = 'This Department Already Exists';
      }
    },
    async handleFunctionChange(value) {
      await handleRoleFunctionChange(this, value);
    },
    async handleDepartmentChange(value) {
      await handleRoleDepartmentChange(this, value);
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.roleDetails')
    };
  }
};
</script>

<style lang="scss">
.role-container {
  .role-details-text-field {
    position: relative;
    .v-field--variant-solo {
      height: 2.625rem !important;
    }
    .v-field__field,
    .v-field__prepend-inner {
      height: 2.625rem !important;
    }

    .v-field__input {
      padding-top: 0rem !important;
    }
  }
}

.redesign table {
  tbody {
    td {
      border: none !important;
    }
  }
}

.edit-role .v-btn__content {
  font-family: 'Futura PT Md';
}
.redesign .theme--light.v-data-table > .v-table__wrapper > table > thead > tr:last-child > th {
  color: $color-grey;
}
.redesign .card-title {
  font-weight: bold;
  color: $color-primary !important;
  text-transform: none;
  font-size: 1.25rem !important;
}
</style>
<style lang="scss" scoped>
.track-list {
  display: flex;
  justify-content: center;
}

.track-detail {
  margin-bottom: -12px;
}
.header {
  border-bottom: 2px solid $color-grey-light;
  border-radius: 0px !important;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem !important;
}
.create-track {
  min-width: 1.875rem;
  height: 1.875rem;
  float: right;
  position: relative;
  top: -0.1rem;
}

.edit-role {
  min-width: 1.875rem;
  height: 1.875rem;
  float: right;
  position: relative;
  top: -0.1rem;
}

.edit-role:focus-visible {
  outline: black 2px solid;
  outline-offset: 1px;
}
.score.v-text-field--rounded {
  ::v-deep .v-input__slot {
    padding: 0 !important;
  }

  ::v-deep .v-text-field__slot input {
    text-align: center !important;
  }
}

.loader {
  margin: 50px;
  text-align: center;

  .loader-text {
    font-size: 30px;
    color: $color-primary;
  }

  .loader-bar {
    position: relative;
    top: -30px;
  }
}

.card {
  margin: 0px;
  padding: 1rem;
  border-radius: 10px;

  .v-btn {
    color: #fff;
  }

  .role-description {
    margin-top: 0.25rem;
    // margin-right: 0px !important;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    // this should work globally

    /* hide text if it more than N lines  */
    overflow: hidden;
    /* for set '...' in absolute position */
    position: relative;
    /* use this value to count block height */
    line-height: 1.2em;
    /* max-height = line-height (1.2) * lines max number (3) */
    max-height: 112px;
    /* fix problem when last visible word doesn't adjoin right side  */
    text-align: justify;
    /* place for '...' */
    margin-right: -1em;
    padding-right: 1em;
  }
  /* create the ... */
  .role-description:before {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }
  /* hide ... if we have text, which is less than or equal to max lines */
  .role-description:after {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    /* set width and height */
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    /* bg color = bg color under block */
    background: white;
  }
}

.card-title {
  display: inline-block;
  font-weight: bold;
  color: $color-primary;
  text-transform: none;
  font-size: 1.125rem;
}

.v-btn {
  text-transform: none;
}

.add-button {
  width: 50px;
  height: 25px !important;
  background: $button-primary-color-bg !important;
  color: $button-primary-color-text !important;
}

.cancel-button {
  width: 100px;
  background: $button-secondary-color-bg !important;
  color: $button-secondary-color-text !important;
}

.save-button {
  width: 100px;
  background: $button-primary-color-bg !important;
  color: $button-primary-color-text !important;
}

.remove-button {
  float: right;
  width: 100px;
  position: relative;
  top: -5px;
  background: $color-data-2 !important;
  color: $button-secondary-color-text !important;
}

.remove-button:focus-visible {
  outline: black 2px solid;
  outline-offset: 1px;
}

.edit {
  float: right;
  margin-right: 10px;
}

.skill-panel-header {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  text-align: center;
  padding: 0.25rem 0rem;
  border-bottom: 1px solid $color-grey-light;

  .skill-importance {
    width: 100px;
    margin-right: 5px;
    border-radius: 30px;
    @media screen and (max-width: 1280px) {
      width: 80px;
    }
  }
}

.skill-panel-container {
  height: 34.375rem;
  overflow: hidden;
  overflow-y: auto;
}

.progress-bar {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 16px !important;

  .progress {
    width: 30%;
    margin-right: 10px;
    left: unset !important;
    transform: unset !important;
  }

  .progress-text {
    width: 70%;
    text-align: left;
    cursor: pointer;
  }
}

.select {
  background: $color-focus;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.skill-detail {
  padding: 30px 5px 0px 20px !important;
  color: $color-grey;
}
.skill-detail-title {
  font-weight: bold;
  font-size: 1.1rem;
}
.skill-detail-text {
  font-size: 0.9rem;
}
.skill-detail-importance {
  font-size: 1.5rem;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.future-skill {
  font-weight: bold;
}

.none-skill {
  color: #5c5c5c;
}

.bottom-card {
  margin: 0;
  padding: 1rem 4rem;
  height: 100%;
}

.search-skill-panel {
  margin: 1rem 0rem 0rem 2rem !important;
  border: 1px solid $color-primary;
  border-radius: 10px;
  padding: 1rem 1.5rem !important;
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;

  .v-row {
    padding: 0.25rem 0rem;
  }
}

.edit-skill-panel {
  margin: 2rem 0.5rem;
  padding: 1rem;
  color: $color-grey;
  border: 2px solid $color-blue;
  border-radius: 10px;
}

.skill-edit-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: $color-blue;
  padding-bottom: 1rem;
  border-bottom: 1px solid $color-grey-light;
  margin-bottom: 0.5rem;
}

.edit-role-title {
  font-size: 1rem;
  font-weight: bold;
  color: $color-grey;
}

.scrollable {
  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-track {
    background: $color-grey-lighter;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 20px;
    width: 4px;
  }
}

.learning-journey-edit-panel {
  position: absolute;
  bottom: 0rem;
  width: 95%;
  background: $color-white;
  padding: 0.95rem 0;
  .v-btn {
    margin-right: 10px;
  }
  .v-btn:first-child {
    margin-right: 40px;
    min-width: 194px;
    padding-right: 21px;
  }
}

.error-msg {
  margin-bottom: 1rem;
  color: $color-error;
}

.recommend-button.v-btn.v-btn--contained {
  padding: 0 100px;
}

.confirmation-recommend {
  position: fixed;
  top: 20%;
  left: 35vw;
  width: 400px;
  z-index: 3040;
  .v-card {
    height: 300px;
    padding: 20px;
    h4,
    p {
      font-size: 16px;
    }
    h4 {
      margin: 0 0 20px;
    }
  }
  .no-gutters {
    justify-content: flex-end;
    align-items: center;
    button {
      margin-right: 20px;
    }
  }
}

.save-button.ml {
  margin-left: 40px;
}

.circle-add-btn {
  width: 30px;
  height: 30px;
  position: relative;
  top: 0.2rem;
}

.dialog-title {
  font-size: 1rem !important;
  font-weight: bold !important;
  padding: 0rem !important;
}

.dialog-content {
  padding: 0.2rem 0rem 0.5rem 0rem;
}

.important-error {
  padding-top: 1rem;
  color: $color-data-2;
}
</style>
