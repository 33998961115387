import axios from 'axios';
import { getMetaData } from '../utils/helper';

export default {
  async basicLearnGetRequest(url) {
    const { jsonHeaders } = await getMetaData();
    const response = await axios.get(url, { headers: jsonHeaders }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async basicLearnGetRequestWithBlob(url) {
    const { authJsonHeader } = await getMetaData();
    const response = await axios({ url: url, method: 'GET', responseType: 'blob', headers: authJsonHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return new Blob([response.data]);
  },
  async startCapstoneReview(url) {
    const { authJsonHeader } = await getMetaData();
    const response = await axios.post(url, {}, { headers: authJsonHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async reviewCapstone(url, payload) {
    const { authJsonHeader } = await getMetaData();
    const response = await axios.post(url, payload, { headers: authJsonHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  }
};
