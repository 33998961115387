<template>
  <router-view v-slot="{ Component }">
    <keep-alive :key="$route.fullPath">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'Catalog',
  metaInfo() {
    return {
      title: translate('pageTitles.catalog')
    };
  }
};
</script>
<style lang="scss"></style>
