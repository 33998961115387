<template>
  <v-dialog v-model="mediaDialog" class="media-dialog" width="800" persistent>
    <v-card class="t-relative t-bg-transparent t-flex t-flex-col">
      <img v-if="item.type === 'image'" :src="item.signedUrl" alt="media-img" class="media" />
      <video v-if="item.type === 'video'" autoplay controls preload="auto" class="media">
        <source :src="item.signedUrl" type="video/mp4" />
      </video>
      <div class="media-description-container t-bg-primary-color t-py-2 t-px-4 t-text-base t-text-left t-text-white">
        <div class="t-flex t-justify-between">
          <div>{{ item.caption }}</div>
          <div class="t-flex t-justify-end t-items-center">
            <svg-icon v-if="media.length > 0 && mediaCurrent > 1" @click="handleItemClick(-1)" icon-class="icon-arrow-left-white" class="t-cursor-pointer"></svg-icon>
            <span class="t-px-4"> {{ mediaCurrent }} of {{ media.length }} </span>
            <svg-icon v-if="mediaCurrent < media.length" @click="handleItemClick(1)" icon-class="icon-arrow-right-white" class="t-cursor-pointer"></svg-icon>
          </div>
        </div>
      </div>
      <v-btn color="white" class="t-absolute t-top-0 t-right-0 t-text-2xl" icon @click="onCancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'AssetMediaDialog',
  props: {
    media: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    selectedMedia: {
      type: Number,
      required: false,
      default: () => {
        return 1;
      }
    }
  },
  data() {
    return {
      item: null,
      mediaCurrent: 1,
      mediaDialog: true
    };
  },
  created() {
    this.mediaCurrent = this.selectedMedia;
    this.item = this.media.length ? this.media[this.mediaCurrent - 1] : null;
  },
  methods: {
    onCancel() {
      this.$emit('close-media-dialog');
    },
    handleItemClick(jump) {
      this.mediaCurrent += jump;
      this.item = this.media[this.mediaCurrent - 1];
    }
  }
};
</script>
<style lang="scss" scoped>
.media {
  width: calc(100% - 3rem) !important;
  height: 500px !important;
}
.media-description-container {
  width: calc(100% - 3rem) !important;
}
</style>
