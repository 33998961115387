<template>
  <div class="usage-trends-chart">
    <div class="usage-trends-container">
      <div id="usageTrends" v-if="dataReady">
        <div class="chart-actions">
          <v-btn name="download" icon aria-label="download Usage over time Trends" title="download Usage over time Trends" class="usageTrendsFocus" @click="downloadPNG()">
            <svg-icon icon-class="PNG_icon" class="t-w-8 t-h-8" />
          </v-btn>
        </div>
        <apexchart ref="UsageTrendsChart" height="350" :series="series" type="bar" :options="options"></apexchart>
        <!-- <apexchart ref="UsageTrendsChartRef" height="350" :series="series" :options="proxyOptions" /> -->
      </div>
      <div v-else class="loader t-flex t-justify-center t-items-center">
        <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Learning Track" />
      </div>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage';
import { getDateForCSV, generateCSVTimeFilter, numberWithCommas, localizedMonthYR, localizedMonthYRName, localizedWeekName } from '@/utils';
import { translateTC, translate } from '@/plugins/i18n.js';

const setLegendTooltip = (chartData, blurb1, blurb2) => {
  chartData.forEach(function (cd, i) {
    const idx = i + 1;
    const id = '.apexcharts-legend-series[rel="' + idx + '"]';
    const blurb = [blurb1, blurb2];

    const tooltipHtml = `
      ${
        i < 2
          ? `
          <span class="usage-trends-info-content">
            <span class="usage-tooltip-text">
              <span class="usage-tooltip-text-header">Tip</span>
              <p class="usage-tooltip-text-content">${blurb[i]}</p>
            </span>
          </span>`
          : ''
      }
    `;
    let legendHTML = document.querySelector(id);
    const childNode = legendHTML.querySelector('.usage-trends-info-content');
    if (childNode) legendHTML.removeChild(childNode);
    if (legendHTML) legendHTML.insertAdjacentHTML('beforeend', tooltipHtml);
  });
};

export default {
  name: 'UsageTrends',

  props: {
    timeFilter: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    drillDownTableFilters: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    searchInputText: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    dateRange: {
      type: Object
    }
  },
  data() {
    return {
      isC1: null,
      dataReady: false,
      labelsForCSV: {
        orgName: { title: 'Organization Name' },
        empName: { title: 'Name' },
        employeeId: { title: 'Employee Id' },
        email: { title: 'Email' },
        status: { title: 'Status' },
        enrollmentDate: { title: 'Enrollment Date' },
        title: { title: 'Job Title' },
        role: { title: 'Role Title' },
        license: { title: 'License' },
        lastAccessed: { title: 'Last Accessed' }
      },
      series: [
        {
          data: [],
          name: translate('insights.usage.activeLabel'),
          type: 'bar'
        },
        {
          data: [],
          name: translate('insights.usage.notActiveLabel'),
          type: 'bar'
        },
        {
          data: [],
          name: translate('insights.usage.trendingActivityLabel'),
          type: 'line'
        }
      ],
      proxyOptions: {},
      options: {
        chart: {
          toolbar: {
            show: false
          },
          type: 'line',
          stacked: true,
          zoom: {
            enabled: true
          },
          events: {
            updated: (chartContext) => {
              setLegendTooltip(chartContext.ctx.w.config.series, translate('insights.usage.activeUserChartBlurb'), translate('insights.usage.notActiveUserChartBlurb'));
            },
            mounted: (chartContext) => {
              setLegendTooltip(chartContext.ctx.w.config.series, translate('insights.usage.activeUserChartBlurb'), translate('insights.usage.notActiveUserChartBlurb'));
            },
            legendClick: (chartContext, seriesIndex, config) => {
              const clone = config.config;
              clone.chart.events = {};
              this.$data.proxyOptions = clone;
            }
          }
        },
        markers: {
          size: 5,
          colors: ['#fff'],
          strokeColors: '#2D2D2D',
          strokeWidth: 3
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              states: {
                hover: {
                  filter: {
                    type: 'none'
                  }
                }
              },
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
                markers: {
                  radius: 20
                }
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            columnWidth: 30
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight',
          width: 3
        },
        xaxis: {
          tickPlacement: 'between',
          type: 'datetime',
          categories: []
        },
        fill: {
          type: 'solid',
          opacity: 1
        },
        yaxis: [
          {
            labels: {
              formatter: function (val) {
                return val ? numberWithCommas(val) : val;
              }
            },
            title: {
              text: translateTC('common.titleCase.user', 2),
              rotate: 360,
              offsetX: -8,
              style: {
                fontSize: '0.75rem',
                fontFamily: 'Arial',
                color: '#5C5C5C'
              }
            }
          }
        ],
        legend: {
          position: 'right',
          showForSingleSeries: true,
          offsetY: 80,
          offsetX: -20,
          inverseOrder: true,
          horizontalAlign: 'center',
          formatter: function (val) {
            return val;
          },
          markers: {
            radius: '50%',
            offsetX: -5
          },
          itemMargin: {
            horizontal: 5,
            vertical: 8
          }
        },
        labels: {
          show: true,
          minWidth: 40
        },
        colors: ['#00A78D', '#E0E1E1', '#2D2D2D']
      },
      isComponentRefreshed: false
    };
  },
  async mounted() {
    this.dataReady = false;
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
  },
  updated() {
    this.isComponentRefreshed = false;
  },
  watch: {
    timeFilter: function (newVal, oldVal) {
      if (this.timeFilter.length && newVal !== oldVal) {
        this.getUsageTrends();
        this.trendsChartData = {};
        this.dataReady = false;
        this.isComponentRefreshed = true;
      }
    },
    dateRange: {
      handler() {
        if (!this.isComponentRefreshed) {
          this.getUsageTrends();
          this.trendsChartData = {};
          this.dataReady = false;
        } else {
          this.isComponentRefreshed = false;
        }
      },
      deep: true
    }
    // dataReady() {
    //   this.$nextTick(async () => {
    //     if (this.$refs.UsageTrendsChart) {
    //       this.$refs.UsageTrendsChart.$el.style.position = 'fixed';
    //       this.$refs.UsageTrendsChart.$el.style.zIndex = '-10000';
    //       this.$refs.UsageTrendsChart.$el.style.width = `${this.$refs.UsageTrendsChart.$el.offsetWidth}px`;
    //     }
    //   });
    // }
  },
  methods: {
    getDateForCSV,
    generateCSVTimeFilter,
    localizedMonthYR,
    localizedMonthYRName,
    localizedWeekName,
    async getUsageTrends() {
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      const time = this.timeFilter === 'CUSTOM' && this.dateRange.startDate && this.dateRange.endDate ? `${this.timeFilter}?startDate=${this.dateRange.startDate}&endDate=${this.dateRange.endDate}&dateFormat=MM-dd-yyyy` : this.timeFilter;
      const payload = { tenantGuid: tenantGuid, timePeriod: time };

      await this.$planAdmin
        .getUsageTrends(payload)
        .then((response) => {
          if (response) {
            this.trendsChartData = response;
            this.series[0].data = response.chartData[0].data;
            this.series[1].data = response.chartData[2].data;
            this.series[2].data = response.chartData[1].data;
            this.options = {
              ...this.options,
              xaxis: {
                categories:
                  this.timeFilter == 'ALLTIME'
                    ? this.formatMonthYR(this.trendsChartData.axisReferenceData)
                    : this.timeFilter == 'YEAR'
                    ? this.formatMonthName(this.trendsChartData.axisReferenceData)
                    : this.timeFilter == 'DAYS90'
                    ? this.formatWeekName(this.trendsChartData.axisData)
                    : this.trendsChartData.axisData
              }
            };
            this.proxyOptions = { ...JSON.parse(JSON.stringify(this.options)), events: {} };
            this.dataReady = true;
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    formatMonthYR(xaxisArray) {
      return xaxisArray.map((x) => {
        return localizedMonthYR(x);
      });
    },
    formatMonthName(xaxisArray) {
      return xaxisArray.map((x) => {
        return localizedMonthYRName(x);
      });
    },
    formatWeekName(xaxisArray) {
      return xaxisArray.map((x) => {
        return localizedWeekName(x);
      });
    },
    downloadPNG() {
      this.$refs.UsageTrendsChart.dataURI().then(({ imgURI }) => {
        let a = document.createElement('a');
        a.setAttribute('id', 'download_png-anchor');
        a.href = imgURI;
        a.setAttribute('download', `Org Usage Trend Chart_${this.generateCSVTimeFilter(this.timeFilter)}_${this.getDateForCSV()}`);
        a.click();
      });
    }
  }
};
</script>

<style lang="scss">
.theme--light.v-btn.v-btn--icon:focus-visible {
  outline: black 2px solid;
  border-radius: unset;
  color: white;
}
.usageTrendsFocus:focus-visible {
  height: 32px;
  width: 32px;
  margin-top: 2.4px;
  margin-right: 2px;
  margin-bottom: 2px;
}
.usage-trends-chart {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 1.25rem;
  width: 100%;
  .usage-trends-container {
    height: 29.0625rem;

    #usageTrends {
      padding: 2rem;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 1.25rem;
      width: 100%;
      padding: 2rem;

      .chart-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 0.5rem;
      }

      .apexcharts-legend {
        overflow: unset !important;
      }

      .apexcharts-legend-series {
        .usage-trends-info-content {
          &:after {
            content: '\24D8';
            font-size: 0.625rem;
            color: #000;
          }

          &:hover {
            .usage-tooltip-text {
              z-index: 999999999 !important;
              visibility: visible !important;
              .usage-tooltip-text-content {
                margin: 1rem 0rem !important;
              }
            }
          }
        }
      }
    }

    .loader {
      margin: 0rem !important;
      height: 100%;
      text-align: center;

      .loader-text {
        font-size: 1.875rem;
        color: $color-primary;
      }

      .loader-bar {
        position: relative;
        top: -1.875rem;
      }
    }
  }
}

.usage-tooltip-text {
  visibility: hidden;
  position: absolute;
  left: 0px;
  background: #dddddd 0% 0% no-repeat padding-box;
  border-radius: 0.25rem;
  letter-spacing: 0rem;
  color: #5c5c5c;
  padding: 0.75rem !important;

  .usage-tooltip-text-header {
    margin-bottom: 0rem;
    font: normal normal bold 0.875rem/1.375rem Arial;
    letter-spacing: 0.0013rem;
    color: #1f3370;

    &:after {
      position: absolute;
      width: 0;
      height: 0;
      border-left: 0.375rem solid transparent;
      border-right: 0.375rem solid transparent;
      border-bottom: 0.375rem solid $color-primary;
      content: '';
      left: 3.75rem;
      top: 1rem;
    }
  }

  .usage-tooltip-text-content {
    font: normal normal normal 0.875rem/1rem Arial;
    color: #5c5c5c;
  }
}
</style>
