<template>
  <div class="session-debug-menu" v-if="name">
    <h1 class="header">Session Debug Menu</h1>
    <div class="content">
      <div><b>idleTime:</b> {{ generateTime(idleTimeInSeconds) }} minutes</div>
      <div><b>autoLogout:</b> {{ generateTime(autoLogoutTimeInSeconds) }} minutes</div>
      <div><b>remindLogout:</b> {{ generateTime(remindLogoutTimeInSeconds) }} minutes</div>
      <br />
      <div><b>autoLogout %:</b> {{ calcPer((this.time / (autoLogoutTimeInSeconds + idleTimeInSeconds)) * 100) }}%</div>
      <div><b>remindLogout %:</b> {{ calcPer((this.time / (remindLogoutTimeInSeconds + idleTimeInSeconds)) * 100) }}%</div>
      <br />
      <div><b>Move Time:</b> {{ displayTime }}</div>
      <div><b>Session Time:</b> {{ displaySessionTime }}</div>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage'

export default {
  name: 'LoginTimer',
  data() {
    return {
      sessionTime: 0,
      time: 0,
      displayTime: '',
      name: '',
      idleTime: '',
      autoLogoutTime: '',
      remindLogoutTime: '',
      actions: ['mousemove', 'keydown', 'mousedown', 'touchstart', 'scroll']
    }
  },
  methods: {
    calcPer(value) {
      return (value < 100 ? value : 100).toFixed(2)
    },
    generateMinutes(t) {
      return parseInt(t / 60, 10)
    },
    generateSeconds(t) {
      const seconds = parseInt(t % 60, 10)
      return seconds < 10 ? `0${seconds}` : seconds
    },
    generateTime(time) {
      return `${this.generateMinutes(time)}:${this.generateSeconds(time)}`
    },
    async countdown() {
      this.name = this.name ? this.name : await localforage.getItem('my_name')

      if (this.name) {
        this.time++
        this.sessionTime++
        this.displayTime = this.generateTime(this.time)
        this.displaySessionTime = this.generateTime(this.sessionTime)
      }
    },
    onKeyPress() {
      this.time = 0
    }
  },
  mounted() {
    this.timer = setInterval(this.countdown, 1000)

    this.idleTime = parseInt(process.env.VUE_APP_IDLE_TIME) > 0 ? parseInt(process.env.VUE_APP_IDLE_TIME) : 60000
    this.autoLogoutTime = parseInt(process.env.VUE_APP_AUTO_LOGOUT_TIME) > 0 ? parseInt(process.env.VUE_APP_AUTO_LOGOUT_TIME) : 900000
    this.remindLogoutTime = parseInt(process.env.VUE_APP_REMIND_LOGOUT_TIME) > 0 ? parseInt(process.env.VUE_APP_REMIND_LOGOUT_TIME) : 780000

    this.idleTimeInSeconds = this.idleTime / 1000
    this.autoLogoutTimeInSeconds = this.autoLogoutTime / 1000
    this.remindLogoutTimeInSeconds = this.remindLogoutTime / 1000
  },
  created() {
    this.actions.forEach((e) => {
      window.addEventListener(e, this.onKeyPress)
    })
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.actions.forEach((e) => {
      window.removeEventListener(e, this.onKeyPress)
    })
  }
}
</script>

<style lang="scss">
.session-debug-menu {
  position: fixed;
  z-index: 99999999;
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 400px;
  bottom: 25px;
  left: 25px;
  background: rgba(31, 51, 112, 0.9);
  color: white;
  border-radius: 10px;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 25px;
  }
  .content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
