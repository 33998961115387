<template>
  <div class="required-learning-container">
    <ReportLastUpdatedHeader />
    <div class="required-learning-header-container">
      <div class="required-learning-header-title">
        <h1>{{ $t('insights.requiredLearning.pageHeader') }}</h1>
        <span>{{ $t('insights.requiredLearning.pageSubHeader') }}</span>
      </div>
    </div>

    <div class="required-learning-charts-container">
      <CompletionRateDonutChart :selectedTimeOptionString="selectedTimeOptionString" />
      <OverdueDonutChart :selectedTimeOptionString="selectedTimeOptionString" :isC1="isC1" />
    </div>

    <div class="required-learning-transcript-container">
      <div class="required-learning-transcript-header">
        <h2>{{ $t('insights.requiredLearning.downloadTranscriptCTA') }}</h2>
        <button @click="downloadLearningRecord()" :disabled="isRLButtonDisabled">
          <span>{{ $t('insights.requiredLearning.downloadTranscript') }}</span>
        </button>
      </div>
      <div class="required-learning-transcript-content">
        <div>
          <h3>{{ $t('insights.requiredLearning.createReportsHeader') }}</h3>
          <p>{{ $t('insights.requiredLearning.createReportsContent') }}</p>
        </div>
        <div>
          <h3>{{ $t('insights.requiredLearning.trackProgressHeader') }}</h3>
          <p>{{ $t('insights.requiredLearning.trackProgressContent') }}</p>
        </div>
        <div>
          <h3>{{ $t('insights.requiredLearning.trackGroupsHeader') }}</h3>
          <p>{{ $t('insights.requiredLearning.trackGroupsContent') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import localforage from 'localforage';
import CompletionRateDonutChart from './components/CompletionRateDonutChart.vue';
import OverdueDonutChart from './components/OverdueDonutChart.vue';
import ReportLastUpdatedHeader from './components/ReportLastUpdatedHeader.vue';
import { getDateForCSV, generateCSVTimeFilter } from '@/utils';
import { translate } from '@/plugins/i18n.js';

const initialState = () => ({
  isC1: null,
  timeFilter: {},
  selectedTimeOption: '',
  selectedTimeOptionString: '',
  searchTextForCSV: '',
  invitedPercentage: 0,
  statusCategory: 'enrollment',
  displaySelectedFilters: [],
  searchText: '',
  structureReady: false,
  structure: [],
  tableDataReady: false,
  treeViewReady: true,
  orgInFocus: null,
  pageSize: 25,
  pageNum: 1,
  pageRange: 6,
  sortBy: '',
  sortDesc: false,
  filterResults: {},
  headers: [],
  employees: [],
  titles: [],
  roles: [],
  licenses: [],
  statusValues: [],
  people: {},
  totalPages: 0,
  clearSearchCross: false,
  pageSizeChangeEvent: 'enrollment-page-size-change',
  pageNumberChangeEvent: 'enrollment-page-number-change',
  labelsForCSV: {
    orgName: { title: 'Organization Name' },
    name: { title: 'Name' },
    id: { title: 'Employee Id' },
    email: { title: 'Email' },
    status: { title: 'Status' },
    enrollmentStatus: { title: 'Enrollment' },
    enrollmentDate: { title: 'Enrollment Date' },
    title: { title: 'Job Title' },
    role: { title: 'Role Title' },
    license: { title: 'License' },
    inviteDate: { title: 'Last Notified' }
  },
  peopleListForCSV: [],
  disabledDownloadButtonObj: {},
  isRLButtonDisabled: true
});

export default {
  name: 'RequiredLearning',
  components: { ReportLastUpdatedHeader, CompletionRateDonutChart, OverdueDonutChart },
  data() {
    return initialState();
  },
  async mounted() {
    Object.assign(this.$data, initialState());
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
    this.selectedTimeOption = translate('insights.time.12months');
    this.selectedTimeOptionString = 'YEAR';
  },
  created() {
    if (!Object.keys(this.EventBus.all).includes('update-required-learning-record-boolean')) this.EventBus.on('update-required-learning-record-boolean', this.updateRLButton);
  },
  methods: {
    getDateForCSV,
    generateCSVTimeFilter,
    updateRLButton(data) {
      this.disabledDownloadButtonObj[data.source] = data.percentage;
      this.isRLButtonDisabled = this.checkButtonStatus();
    },
    checkButtonStatus() {
      if (!('COMPLETION' in this.disabledDownloadButtonObj) || !('OVERDUE' in this.disabledDownloadButtonObj)) {
        return true;
      }

      if (!this.disabledDownloadButtonObj.COMPLETION && !this.disabledDownloadButtonObj.OVERDUE) {
        return true;
      }

      return false;
    },
    async downloadLearningRecord() {
      try {
        const tenantGuid = await localforage.getItem('my_tenant_guid');
        const payload = { orgId: 'root', tenantGuid: tenantGuid };
        const reqId = await this.$planAdmin.requiredLearningDownloadPerORGgetRequestID(payload);

        let loadingStatus = 'IN_PROGRESS';
        let res = {};
        let counter = 0;

        while (loadingStatus == 'IN_PROGRESS' && counter < 200) {
          // keep checking every 3 seconds
          res = await this.$planAdmin.consumptionDownloadPerORGgetUpdate(reqId);
          loadingStatus = res.status;
          await new Promise((resolve) => setTimeout(resolve, 3000));
          counter++;
        }
        if (loadingStatus == 'FINISHED') {
          let filename = `Required Learning_${generateCSVTimeFilter('ALLTIME')}_${this.getDateForCSV()}`;
          let a = document.createElement('a');
          a.href = res.generatedUri;
          a.setAttribute('download', filename);
          a.click();
        } else {
          if (loadingStatus == 'IN_PROGRESS') {
            // this means we timed out while trying to download
            console.log('Retrieving Learner Records is taking too long');
          } else {
            console.log(res.message);
          }
        }
      } catch (error) {
        console.log('there has been an error');
        console.log(error);
      }
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.requiredLearning')
    };
  }
};
</script>

<style lang="scss">
.required-learning-container {
  max-width: 90rem;
  padding: 0 3rem;
  margin: 6.5rem auto 0;

  .required-learning-header-container {
    display: grid;
    grid-template-columns: 10fr 2fr;
    height: 5rem;

    .required-learning-header-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h1 {
        font: normal normal normal 2.625rem/3.375rem 'Futura PT Demi';
        letter-spacing: 0rem;
        color: #1f3370;
      }
      span {
        font: normal normal normal 1rem/1.125rem Arial;
        letter-spacing: 0rem;
        color: #2d2d2d;
      }
    }
  }

  .required-learning-charts-container {
    display: grid;
    grid-template-columns: 6fr 6fr;
    grid-gap: 2rem;
    margin: 2rem 0rem;

    .card-header {
      font: normal normal normal 1.5rem/2.25rem 'Futura PT Demi';
      margin-top: 0.625rem;
      padding: 0.8rem 0.8rem 0rem;
      color: #1f3370;
    }
    .card-blurb {
      padding: 0rem 0.8rem 0.8rem;
      text-align: left;
      font: normal normal normal 1rem/1.5rem Arial;
      letter-spacing: 0rem;
      color: #5c5c5c;
      opacity: 1;
    }

    .loading-card {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 28.125rem;
    }

    .card {
      padding: 0.3125rem 0.625rem;
      border-radius: 1.25rem;
      min-width: 15.625rem;
      box-shadow: none !important;

      .card-number {
        display: flex;
        font-size: 1.875rem;
        color: $color-primary;
        font-weight: bold;
      }

      .card-subject {
        position: relative;
        color: $color-primary;
        font-size: 1.125rem;
        margin-bottom: 0.9375rem;
      }
    }
  }

  .required-learning-transcript-container {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 1.25rem;
    opacity: 1;
    padding: 4rem;

    .required-learning-transcript-header {
      display: grid;
      grid-template-columns: 10fr 2fr;
      margin-bottom: 3rem;

      h2 {
        font: normal normal normal 2rem/2.5rem 'Futura PT Demi';
        letter-spacing: 0rem;
        color: #142a5d;
        opacity: 1;
      }
      button {
        background: #ffb92e 0% 0% no-repeat padding-box;
        border-radius: 25px;
        opacity: 1;
        width: 10.5rem;
        height: 2.9375rem;

        &:disabled {
          opacity: 0.5;
          pointer-events: none;
        }

        span {
          text-align: center;
          font: normal normal normal 1rem/1.375rem 'Futura PT Demi';
          letter-spacing: 0.0013rem;
          color: #050101;
          opacity: 1;
        }
      }
    }

    .required-learning-transcript-content {
      display: grid;
      grid-template-columns: 4fr 4fr 4fr;
      div {
        max-width: 21.25rem;
      }
      h3 {
        text-align: left;
        font: normal normal normal 1.375rem/1.5625rem 'Futura PT Demi';
        letter-spacing: 0rem;
        color: #142a5d;
        opacity: 1;
      }
      p {
        text-align: left;
        font: normal normal normal 1rem/1.5rem Arial;
        letter-spacing: 0rem;
        color: #2d2d2d;
        opacity: 1;
      }
    }
  }
}
</style>
