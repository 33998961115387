<template>
  <div class="container">
    <v-card class="cookie-notice-card">
      <v-card-title class="cookie-notice-title">Cookie information</v-card-title>
      <v-card-text style="padding: 20px">
        <p>This cookie information is relevant for visitors to ProEdge. For more information about how we process your personal information collected through ProEdge, please visit our ProEdge <a href="./privacy" target="_blank">Privacy statement</a>.</p>
        <p>
          Cookies are small text files that are placed on your device by the websites and applications that you visit. They are widely used in order to make websites and apps work, or work more efficiently, as well as to provide information to the owners of the site or app. The
          use of cookies and similar technologies is now standard for most websites and apps.
        </p>

        <h3>Managing cookies on your device</h3>
        <p>
          You can control and manage cookies using your browser settings as well as certain opt-out tools (see below). Please note that if you are accessing this application using multiple browsers or devices, you will need to manage cookies on each browser and device, as
          applicable. Please also note that removing or blocking cookies can impact your user experience and some functionality may no longer be available.
        </p>

        <h3>Using your browser to control cookies</h3>
        <p>
          Most browsers allow you to view, manage, delete and block cookies for a website or application. Be aware that if you delete all cookies then any preferences you have set will be lost, including the ability to opt-out from cookies as this function itself requires
          placement of an opt out cookie on your device. Guidance on how to control cookies for common browsers is linked below.
        </p>
        <ul class="no-bullet">
          <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en" target="_blank">Google Chrome</a></li>
          <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">Mozilla Firefox</a></li>
          <li><a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac" target="_blank">MacOS Safari</a></li>
          <li><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Microsoft Internet Explorer</a></li>
          <li><a href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies" target="_blank">Microsoft Edge</a></li>
        </ul>
        <br />

        <h3>Opt-out tools to control cookies</h3>
        <p>
          Some of the third parties that collect information from or about you on PwC sites or apps may participate in the Digital Advertising Alliance’s (“DAA”) self-regulatory program. To make choices about the use of information from or about you for online behavioral or
          interest-based advertising on websites (desktop and mobile browsers), please visit the <a href="https://optout.aboutads.info/" target="_blank">DAA</a>, and within mobile applications, you can download the DAA’s AppChoices application from your mobile device at
          <a href="http://www.aboutads.info/appchoices" target="_blank">www.aboutads.info/appchoices</a> or from your device’s app store.
        </p>
        <p>You can set your browser to block or alert you about these cookies, but some parts of the application may no longer work.</p>

        <h3>Use of cookies</h3>
        <p>
          Below is a detailed list of the cookies we use on this application. We classify the use of cookies into the following categories: (1) necessary; (2) analytical/performance; (3) functionality. You can find more information about each category in the relevant sections
          below. For each cookie listed below, we also note the duration of the cookie and identify whether it is a first party or third party cookie.
        </p>
        <p><strong>‘First party’</strong> cookies are directly stored by the website (or domain) you visit. These cookies allow website owners to collect analytics data, remember language settings, and perform other useful functions that provide a good user experience.</p>
        <p>
          <strong>‘Third party’</strong> cookies are created by domains that are not the website (or domain) that you are visiting. These are usually used for analytics and/or online-advertising purposes and placed on a website through scripts or tags. A third-party cookie is
          accessible on any website that loads the third party server’s code.
        </p>
        <h4>Necessary cookies</h4>
        <p>These cookies are necessary for the website to operate. Our website cannot function without these cookies and they can only be disabled by changing your browser preferences.</p>

        <table>
          <tr>
            <th>Cookie Name</th>
            <th>Host</th>
            <th>Cookie Type</th>
            <th>Life Span</th>
          </tr>
          <tr>
            <td>incap_ses_</td>
            <td>plan.proedge.pwc.com</td>
            <td>First party</td>
            <td>Session</td>
          </tr>
          <tr>
            <td>visid_incap_</td>
            <td>plan.proedge.pwc.com</td>
            <td>First party</td>
            <td>1 Year</td>
          </tr>
        </table>
        <br />

        <h4>Analytical / Performance cookies</h4>
        <p>
          These cookies allow us to measure and report on website activity by tracking page visits, visitor locations and how visitors move around the site. The information collected does not directly identify visitors. We drop these cookies to help us analyze the data. These
          cookies are dropped for users in the United States only.
        </p>
        <table>
          <tr>
            <th>Cookie Name</th>
            <th>Host</th>
            <th>Cookie Type</th>
            <th>Life Span</th>
          </tr>
          <tr>
            <td>_gat_gtag_UA_209584847_8</td>
            <td>pwc.com</td>
            <td>Third party</td>
            <td>8 hours</td>
          </tr>
          <tr>
            <td>_gid</td>
            <td>pwc.com</td>
            <td>Third party</td>
            <td>8 hours</td>
          </tr>
          <tr>
            <td>_ga</td>
            <td>pwc.com</td>
            <td>Third party</td>
            <td>8 hours</td>
          </tr>
        </table>
        <br />

        <h4>Functionality cookies</h4>
        <p>
          These cookies enable the website to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we have added to our pages. If you do not allow these cookies then some or all of these services may not function
          properly.
        </p>
        <table>
          <tr>
            <th>Cookie Name</th>
            <th>Host</th>
            <th>Cookie Type</th>
            <th>Life Span</th>
          </tr>
          <tr>
            <td>vuex</td>
            <td>plan.proedge.pwc.com</td>
            <td>First party Local Storage</td>
            <td>In Perpetuity</td>
          </tr>
        </table>
        <br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'CookieNotice',
  metaInfo() {
    return {
      title: translate('pageTitles.cookieNotice')
    };
  }
};
</script>

<style lang="scss">
body {
  background-color: #efefef;
}
.v-application--wrap {
  background-color: #efefef;
}
</style>

<style lang="scss" scoped>
.cookie-notice-card {
  margin: 3.5% 0% 1% 0%;
  border-radius: 10px;
  padding: 0.5rem 1rem;
}

.cookie-notice-title {
  margin-top: 15px;
  color: $color-primary !important;
  font-family: 'Source Serif';
  font-weight: 700;
  font-size: 3rem;
  line-height: 3.75rem;
  @media screen and (max-width: 1264px) {
    font-size: 2.5rem;
    line-height: 3.12rem;
  }
  @media screen and (max-width: 600px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}
ul.no-bullet {
  a {
    font-family: 'Source Sans';
  }
}
h3 {
  font-family: 'Source Serif' !important;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2.25rem;
  color: #2d2d2d;
  @media screen and (max-width: 1264px) {
    font-size: 1.5rem;
    line-height: 1.87rem;
  }
  @media screen and (max-width: 600px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
h4 {
  font-family: 'Source Sans' !important;
  color: #2d2d2d;
  font-weight: 700;
  line-height: 1.5rem;
}

.v-card-text {
  font-size: 1rem;
  p {
    font-family: 'Source Sans';
    line-height: 1.5rem;
    color: #2d2d2d;
    a {
      font-family: 'Source Sans';
    }
    strong {
      font-family: 'Source Sans';
    }
  }
}

.xsmall {
  font-size: 12px;
}

table {
  border-collapse: collapse;
  width: 80%;
  line-height: 1.5;
  tr,
  th {
    font-family: 'Source Sans' !important;
    font-size: 0.75rem !important;
    font-weight: 700;
    color: #000000 !important;
  }

  td,
  th {
    font-family: 'Source Sans' !important;
    font-size: 0.875rem;

    color: #000000;
    border: 1px solid $color-black;
    text-align: left;
    padding: 0.3rem 0.5rem;
    &:nth-child(2) {
      color: #1f3370;
    }
  }
}
</style>
