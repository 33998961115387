<template>
  <div class="org-structure-container">
    <v-card>
      <div class="header">
        <h2 class="structure">
          {{ $t('orgStructure.orgChart') }}<span>{{ $t('orgStructure.lastUpdated') }} {{ lastUpdated ? localizedDate(moment, lastUpdated) : '&mdash;' }}</span>
        </h2>
        <div class="header-button-group">
          <v-tooltip bottom v-if="tenantType !== 'workday'">
            <template v-slot:activator="{ props }">
              <v-btn data-el="editOrgChart" tabindex="0" icon class="button" aria-label="edit org chart button" v-bind="props" @click="$router.push('/plan-org-details')" v-track="'OrgStructure,EditOrgChart,click'">
                <v-icon dark style="font-size: 25px"> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('orgStructure.editOrgChart') }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ props }">
              <v-btn data-el="downloadOrgChart" tabindex="0" icon class="button" aria-label="download org chart button" v-bind="props" @click="onDownload">
                <v-icon dark style="font-size: 25px"> mdi-download </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('orgStructure.downloadOrgChart') }}</span>
          </v-tooltip>
        </div>
      </div>
      <div v-if="!structure.length" style="margin: 1rem">{{ $t('orgStructure.noOrgStructureAvailable') }}</div>
      <div class="tree">
        <!-- 
        <TreeView activatable dense color="info" :items="structure" :key="structure.length" :load-children="onFetchChildren">
          <template v-slot:label="{ item }">
            <div v-if="item.hasOwnProperty('lead')" @mouseover="onMouseover(item)" @click="onGetPlan(item)">
              <span :id="`treeview-node-label-${item.id}`" class="dept-name">{{ item.name }}</span>
            </div>
            <div v-else @click="onGetProfile(item)">
              <span class="employee-name">{{ item.firstName }} {{ item.lastName }}</span>
              <img class="avatar-img" src="@/assets/images/users/no-avatar.png" />
            </div>
          </template>
        </TreeView> 
        -->
        <BasicTreeView class="structure" :items="structure" :row="0" :mode="1" :key="structure.length" @load-children="onFetchChildren" @on-get-plan="onGetPlan" @on-get-profile="onGetProfile" />
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
// import TreeView from '@/components/molecules/TreeView';
import BasicTreeView from '@/components/molecules/BasicTreeView';

import { localizedDate } from '@/utils';

export default {
  name: 'OrgStructure',
  components: { BasicTreeView },
  emits: ['get-plan', 'get-profile', 'refresh-all', 'refresh-email', 'refresh-role'],
  props: {
    structure: { type: Array, required: true }
  },
  data() {
    return {
      token: '',
      openStructure: [],
      active: [],
      tenantType: '',
      lastUpdated: '',
      tenantSettings: null
    };
  },
  computed: {
    ...mapGetters(['tenant_type', 'tenant_settings'])
  },
  async mounted() {
    this.lastUpdated = await localforage.getItem('last_updated');
    analytics.setVarAndTrack({ c: 'OrgStructure', l: 'OrgChart', a: 'load' });
    this.$gtag.event('OrgStructure', {
      event_category: 'load',
      event_label: 'OrgChart',
      value: 1
    });
    this.tenantSettings = JSON.parse(JSON.stringify(this.tenant_settings));
    this.tenantType = await localforage.getItem('my_tenant_type');
    this.tenantType = this.tenantType ? this.tenantType.toLowerCase() : null;
  },
  methods: {
    localizedDate,
    async onFetchChildren(item) {
      const structure = await this.$planAdmin.getChildrenOrganizationStructure({ orgId: item.id });
      item.children = structure.children;
      item.loaded = true;
    },
    onGetPlan(org) {
      this.active = [org.id];
      this.$emit('get-plan', org);
    },
    onGetProfile(user) {
      this.active = [user.id];
      this.$emit('get-profile', user);
    },
    onMouseover(item) {
      if (!this.openStructure.includes(item.id)) {
        this.openStructure.unshift(item.id);
      }
    },
    async onDownload() {
      analytics.setVarAndTrack({ c: 'OrgStructure', l: 'OrgChart', a: 'download' });
      this.$gtag.event('OrgStructure', {
        event_category: 'download',
        event_label: 'OrgChart',
        value: 1
      });
      const blob = await this.$planAdmin.downloadOrganizationStructure();
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.setAttribute('download', 'org-chart.xlsx');
      a.click();
    },
    onRefreshAll() {
      this.$emit('refresh-all');
    },
    onRefreshEmail() {
      this.$emit('refresh-email');
    },
    onRefreshRole() {
      this.$emit('refresh-role');
    }
  }
};
</script>

<style lang="scss" scoped>
@mixin scrollable {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 1.25rem;
    width: 0.25rem;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.v-treeview {
  min-width: fit-content;
  @include scrollable;
}

.v-card {
  border-radius: 10px;
  padding: 20px 20px;
  min-height: 1060px;
}

.tree {
  max-height: 980px;
  overflow-y: scroll;
  @include scrollable;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px solid $color-grey-light;
  .header-button-group {
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      border-radius: 50%;
      background: #3b4572;
      width: 25px;
      height: 25px;
      .org-icon {
        display: inline-block;
        margin: auto;
        padding: auto;
      }
    }
  }
}

.clickable {
  color: $color-data-2;
  cursor: pointer;
}

.structure {
  padding-bottom: 10px;
  font-weight: bold;
  span {
    display: block;
    font-size: 14px;
  }
}

.structure,
.dept-name {
  color: $color-primary;
  font-size: 18px;
}

.lead-name {
  color: $color-grey;
  font-size: 14px;
}

.employee-name {
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: $color-data-2;
  }
}

.avatar-img {
  float: left;
  border: 1px solid;
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border-radius: 100%;
}

.button {
  position: relative;
  top: -0.5rem;
  width: 37px;
  height: 37px;
  margin-right: 8px;
  text-transform: capitalize;
  background: $button-secondary-color-bg !important;
  color: $button-secondary-color-text !important;
}
</style>

<style lang="scss">
.org-structure-container {
  .v-treeview-node__label {
    width: max-content;
  }
}
</style>
