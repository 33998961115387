import Toasted from 'vue-toasted';

export default {
  install(app) {
    // -- CAN WE DO THIS IN A BETTER WAY
    const prevPrototype = app.prototype;
    app.prototype = {};
    app.use(Toasted);
    Object.assign(app.config.globalProperties, app.prototype);
    app.prototype = prevPrototype;
    // -- CAN WE DO THIS IN A BETTER WAY
  }
};
