<template>
  <div class="carousel-style">
    <VSlickCarousel v-bind="settings" class="edge-achievements">
      <slot></slot>
      <template #customPaging="paging">
        <div class="custom-dot">
          {{ paging.page + 1 }}
        </div>
      </template>
    </VSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import 'v-slick-carousel/style.css';
import { VSlickCarousel } from 'v-slick-carousel';

export default {
  name: 'CarouselDesktop',
  props: {
    slidesShow: { type: Number, required: false, default: 5 },
    responsive: { type: Boolean, required: false, default: true }
  },
  components: {
    VueSlickCarousel,
    VSlickCarousel
  },
  data() {
    return {
      settings: {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: this.slidesShow,
        slidesToScroll: this.slidesShow,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1690,
            settings: {
              dots: true,
              infinite: false,
              slidesToShow: this.responsive ? 4 : this.slidesShow,
              slidesToScroll: this.responsive ? 4 : this.slidesShow
            }
          },
          {
            breakpoint: 1420,
            settings: {
              dots: true,
              infinite: false,
              slidesToShow: this.responsive ? 3 : this.slidesShow,
              slidesToScroll: this.responsive ? 3 : this.slidesShow
            }
          },
          {
            breakpoint: 1380,
            settings: {
              dots: true,
              infinite: false,
              slidesToShow: this.responsive ? 3 : 2,
              slidesToScroll: this.responsive ? 3 : 2
            }
          },
          {
            breakpoint: 1145,
            settings: {
              dots: true,
              infinite: false,
              slidesToShow: this.responsive ? 2 : 2,
              slidesToScroll: this.responsive ? 2 : 2
            }
          },
          {
            breakpoint: 825,
            settings: {
              dots: false,
              infinite: false,
              slidesToShow: this.responsive ? 1 : 1,
              slidesToScroll: this.responsive ? 1 : 1
            }
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss">
.custom-dot {
  width: 20px;
  height: 20px;
  color: #bcc2d4;
  border-radius: 5px;
  transition: all 0.3s ease;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 0.8;
  }
}

.v-slick-dots li.active {
  .custom-dot {
    color: #1f3370;
  }
}

.edge-achievements {
  background-color: transparent;
}

.carousel-style {
  margin: 0 20px;
}
</style>
