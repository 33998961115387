<template>
  <div v-if="dataReady" class="container-fluid organize-track">
    <TrackSaveDialog :trackSaveDialog="trackSaveDialog" :trackPopupDetails="trackPopupDetails" @cancel-navigation="cancelNavigation()" @edit-track="toTrackCreation()" @saved-track="saveLearningTrack()" />
    <SaveDialog :saveDialog="saveDialog" :disableSaveButton="trackList.length === 0" @cancel-and-navigate="cancelAndNavigate()" @cancel-navigation="cancelNavigation()" />
    <NotificationDialog :notificationDialog="notificationDialog" copy="selectionContinueMessageUpdateContent" @save-navigate="saveAndNavigate" @cancel-navigation="cancelNavigation()" />
    <v-row no-gutters>
      <div class="back t-cursor-pointer" @click="backNavigation()">
        <v-icon class="t-text-4xl">{{ 'mdi-chevron-left' }}</v-icon>
        <span>{{ translate('organizeLearningTrack.back') }}</span>
      </div>

      <v-col align-self="center" class="organize-label t-mt-12 t-ml-4">
        <div class="title-text">{{ translate('organizeLearningTrack.organize') }}</div>
        <button
          @click="toTrackCreation()"
          :disabled="!trackGuid"
          class="save-and-continue t-ml-4 t-font-futura-pt-book t-mx-1 t-color-white t-py-3 min-w-min t-px-6 t-rounded-full t-font-bold t-text-base t-bg-credential t-border-credential hover:t-bg-primary-color hover:t-text-white"
        >
          {{ translate('organizeLearningTrack.editBtn') }}
        </button>
      </v-col>
      <v-col align-self="center" class="t-mt-12" style="text-align: center">
        <v-text-field
          ref="organizeSeachInput"
          @keyup="filterOrganizeList"
          data-el="searchTrack"
          class="searchTrack"
          hide-details
          prepend-inner-icon="mdi-magnify"
          :label="translate('organizeLearningTrack.searchThis')"
          rounded
          solo
          clearable
          variant="solo"
          density="compact"
          single-line
          bg-color="white"
          @click:clear="handleClearInput"
        ></v-text-field>
      </v-col>
      <v-col class="t-pr-36 t-mt-12" style="text-align: right">
        <p v-if="this.roleName" class="t-text-sm mt-4 t-underline t-cursor-pointer t-text-primary-color t-font-normal t-mb-10" @click="roleDetails()">
          <span class="t-font-bold t-text-sm t-font-arial t-text-primary-color">{{ translate('organizeLearningTrack.role') }}</span> {{ this.roleName }}
        </p>
      </v-col>
    </v-row>
    <div>
      <div class="edit-track-name-container">
        <div class="t-inline-block edit-track-name">
          <div>
            <span class="track-name">{{ translate('track.name') }}</span>
          </div>
          <h2 class="track-name-title t-leading-8 t-rounded-full t-whitespace-nowrap">
            {{ this.trackName }}
          </h2>
        </div>
      </div>
    </div>
    <div class="expansion-card t-mb-12">
      <v-row class="pl-12 mb-5 expansion-card-header">
        <v-col cols="2" align-self="center" style="text-align: center" class="t-font-bold t-text-base t-font-arial">{{ translate('common.filter.contentType') }} </v-col>
        <v-col cols="5" align-self="center" style="text-align: left; padding-left: 30px" class="t-font-bold t-text-base t-font-arial">{{ $tc('common.titleCase.title') }}</v-col>
        <v-col cols="2" align-self="center" style="text-align: left" class="t-font-bold t-text-base t-font-arial">{{ translate('common.filter.provider') }}</v-col>
        <v-col cols="1" align-self="center" style="text-align: left" class="t-font-bold t-text-base t-font-arial">{{ translate('common.filter.level') }}</v-col>
        <v-col cols="2" align-self="start" style="text-align: left" class="t-font-bold t-text-base t-font-arial t-relative">
          {{ translate('common.filter.duration') }}
          <v-icon class="t-text-xl t-mr-0.5 t-text-black clock-icon">{{ 'mdi-clock-outline' }}</v-icon>
          <span class="t-text-xs header-duration">{{ $sanitize(formatDuration(this.totalDuration)) }}</span>
        </v-col>
      </v-row>
      <v-expansion-panels accordion v-model="active">
        <draggable :list="organizeData" group="tasks" class="draggable-container" @change="itemMoved">
          <v-expansion-panel v-for="(item, i) in organizeData" :key="i">
            <v-expansion-panel-title expand-icon="mdi-menu-down">
              <template v-slot:actions>
                <div class="t-grid action-btn">
                  <v-btn icon @click="moveUp(item)" @click.stop variant="text">
                    <v-icon class="icon-small icon-backgroud">mdi-menu-up</v-icon>
                  </v-btn>
                  <v-btn icon variant="text">
                    <svg-icon icon-class="three-dots" class-name="icon-small"></svg-icon>
                  </v-btn>
                  <v-btn icon @click="moveDown(item)" @click.stop variant="text"> <v-icon class="icon-small icon-backgroud">$dropdown</v-icon></v-btn>
                </div>
              </template>
              <div class="expansion-header">
                <v-row>
                  <v-col cols="2" align-self="center" style="text-align: left">
                    <span class="t-text-xs mr-2 format t-rounded-full t-font-arial" :class="dataFormatBg[item.formatType.toLocaleLowerCase()]">
                      {{ getLocalizedContentType(item.formatType) }}
                    </span>
                  </v-col>
                  <v-col cols="10" align-self="left" class="t-flex t-justify-center t-flex-col t-gap-2.5 t-items-start t-p-0">
                    <div class="expand-title t-text-xl t-font-futura-pt-demi t-text-left t-text-primary-color">
                      {{ item.title }}
                    </div>
                    <div class="t-flex t-gap-2 t-items-center t-text-xs meta">
                      <span>{{ item.provider }}</span>
                      <span>|</span>
                      <span>{{ item.levels ? item.levels : 'N/A' }}</span>
                      <span>|</span>
                      <span
                        ><span class="t-w-16 t-text-left">{{ $sanitize(formatDuration(item.duration)) }}</span></span
                      >
                      <span v-if="item.contentFeature">
                        <span style="margin-right: 0.5rem">|</span>
                        {{ getContentFeature(item.contentFeature) }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div class="expand-actions t-inline-flex">
                <v-btn icon class="t-mr-3" variant="text">
                  <v-icon class="expand-icon icon t-border-solid t-border t-text-black t-border-gray-400 t-rounded-full">$expand</v-icon>
                </v-btn>
                <v-btn icon @click.stop @click="removeItem(i)" variant="text">
                  <svg-icon icon-class="ico-trash" class-name="icon-large" />
                </v-btn>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div>
                <CourseCard :item="item" />
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </draggable>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import CourseCard from '@/views/catalog/components/CourseCard';
import NotificationDialog from './NotificationDialog.vue';
import SaveDialog from './SaveDialog.vue';
import TrackSaveDialog from './TrackSaveDialog.vue';
import { mapState, mapActions } from 'vuex';
import { VueDraggableNext } from 'vue-draggable-next';
import { dataFormatBg, formatDuration, getLocalizedContentType } from '@/utils';
import localforage from 'localforage';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'OrganizeLearningTrack',
  components: {
    draggable: VueDraggableNext,
    CourseCard,
    SaveDialog,
    TrackSaveDialog,
    NotificationDialog
  },
  data() {
    return {
      dataFormatBg,
      organizeData: [],
      active: null,
      dataReady: false,
      roleName: '',
      trackNameOrganize: '',
      totalDurationOrganize: 0,
      saveDialog: false,
      trackSaveDialog: false,
      notificationDialog: false,
      toPath: '',
      saveLearningTrackMessage: translate('organizeLearningTrack.saveLearningTrackMessage'),
      inProgressLearningTrackMessage: translate('organizeLearningTrack.inProgressLearningTrackMessage'),
      failureLearningTrackMessage: translate('organizeLearningTrack.failureLearningTrackMessage'),
      getLocalizedContentType,
      tracksaved: false,
      //editBtnDisable: true,
      trackPopupDetails: {
        title: translate('confirmation.trackPreview.message'),
        message: translate('confirmation.trackPreview.details'),
        okay: 'Okay'
      },
      createTrackPopup: {
        title: translate('confirmation.trackGeneration.message'),
        message: translate('confirmation.trackGeneration.details'),
        edit: 'Edit',
        ok: 'Okay'
      },
      trackCreated: {
        save: false,
        cancel: false
      }
    };
  },
  beforeRouteLeave(to, from, next) {
    const path = ['/catalog/create', '/catalog/edit', '/catalog/stepper/edit'].some((item) => {
      return to.path.includes(item);
    });
    if (!path && (this.trackUnsavedChanges || this.trackNameUpdated || this.saveExitButton) && !this.trackSaveButtonState) {
      this.saveDialog = true;
      this.toPath = to.path;
      next(false);
    } else {
      this.active = null;
      this.EventBus.off('save-track-edit-content-organize');
      if (!path && !this.trackSaveButtonState) {
        this.EventBus.emit('clear-data');
        this.setSaveButtonState(true);
        this.setSaveExitButton(false);
      }
      this.handleClearInput();
      next();
    }
  },
  computed: {
    ...mapState('track', ['roleNameTrack', 'reCreateTrack', 'saveExitButton', 'trackGuid', 'trackName', 'roleGuid', 'trackList', 'trackDetails', 'trackSaveButtonState', 'trackUnsavedChanges', 'totalDuration', 'trackNameUpdated', 'showNotificationDialog'])
  },
  watch: {
    trackNameOrganize(name) {
      this.setTrackName(name);
    },
    totalDurationOrganize(val) {
      this.setTotalDuration(val);
    }
  },
  async mounted() {
    this.toPath = '';
    this.trackList = this.trackList || (await localforage.getItem('trackList'));
    this.trackDetails = this.trackDetails || (await localforage.getItem('trackDetails'));
    this.organizeData = this.trackList.slice(0);
    this.roleName = this.roleNameTrack || (await localforage.getItem('roleNameTrack'));
    this.trackNameOrganize = this.trackName;
    this.totalDurationOrganize = this.totalDuration;
    this.trackSaveDialog = this.$route.query.createTrack && this.saveExitButton ? true : false;
    this.dataReady = true;
    if (!Object.keys(this.EventBus.all).includes('save-track-edit-content-organize')) {
      this.EventBus.on('save-track-edit-content-organize', this.saveLearningTrack);
    }
  },
  methods: {
    ...mapActions('track', ['setReCreateTrack', 'setTotalTrackItems', 'setTrackName', 'setTrackGuid', 'setTotalDuration', 'setTrackList', 'setSaveExitButton', 'resetTrackList', 'setSaveButtonState', 'setUnsavedChangesState', 'setTrackNameUpdated']),
    translate,
    formatDuration,
    getSaveButtonState() {
      return true;
    },
    async removeItem(index) {
      this.active = null;
      this.setUnsavedChangesState(true);
      this.totalDurationOrganize -= this.organizeData[index].duration;
      const indexToDelete = this.trackList.findIndex((item) => item.id === this.organizeData[index].id);
      this.organizeData.splice(index, 1);
      this.trackList.splice(indexToDelete, 1);
      this.setSaveButtonState(false);
    },
    backNavigation() {
      this.setReCreateTrack(true);
      this.setSaveExitButton(false);
      this.handleClearInput();
      this.$router.go(-1);
    },
    toTrackCreation() {
      this.setSaveExitButton(false);
      this.handleClearInput();
      this.$router.push({ path: '/catalog/stepper/edit', query: { isFromOrganize: true } });
    },
    async moveUp(item) {
      const index = this.organizeData.findIndex((e) => e.id == item.id);
      if (index > 0) {
        this.setSaveButtonState(false);
        this.setUnsavedChangesState(true);
        const el = this.organizeData[index];
        this.organizeData[index] = this.organizeData[index - 1];
        this.organizeData[index - 1] = el;
        const updatedTrackList = this.organizeData.slice(0);
        await this.setTrackList(updatedTrackList);
      }
    },
    async moveDown(item) {
      const index = this.organizeData.findIndex((e) => e.id == item.id);
      if (index !== -1 && index < this.organizeData.length - 1) {
        this.setSaveButtonState(false);
        this.setUnsavedChangesState(true);
        const el = this.organizeData[index];
        this.organizeData[index] = this.organizeData[index + 1];
        this.organizeData[index + 1] = el;
        const updatedTrackList = this.organizeData.slice(0);
        await this.setTrackList(updatedTrackList);
      }
    },
    async itemMoved(eve) {
      if (this.$refs.organizeSeachInput && this.$refs.organizeSeachInput.$el.getElementsByTagName('input')[0] && this.$refs.organizeSeachInput.$el.getElementsByTagName('input')[0].value) {
        return;
      }
      this.setSaveButtonState(false);
      this.setUnsavedChangesState(true);
      if (eve.moved) {
        const updatedTrackList = this.organizeData.slice(0);
        await this.setTrackList(updatedTrackList);
      }
    },
    async roleDetails() {
      this.$router.push({ name: 'RoleDetails', params: { guid: this.roleGuid } });
    },
    async filterOrganizeList(event) {
      this.organizeData = this.trackList.filter((item) => {
        return item.title.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase()) || item.formatType.toLocaleLowerCase().includes(event.target.value.toLocaleLowerCase());
      });
    },
    async handleClearInput() {
      if (this.$refs.organizeSeachInput) {
        this.$refs.organizeSeachInput.$el.getElementsByTagName('input')[0].value = '';
        this.organizeData = this.trackList;
      }
    },
    cancelAndNavigate() {
      this.saveDialog = false;
      if (this.reCreateTrack) this.setSaveButtonState(true);
      this.setTrackNameUpdated(false);
      this.setUnsavedChangesState(false);
      this.setSaveExitButton(false);
      this.$router.push({ path: this.toPath });
    },
    saveAndNavigate(shouldNotifyLearners) {
      this.notificationDialog = false;
      this.saveTrack(shouldNotifyLearners);
    },
    cancelNavigation() {
      this.notificationDialog = false;
      this.saveDialog = false;
      this.trackSaveDialog = false;
      this.toPath = '';
    },
    saveLearningTrack() {
      if (this.$route.query.createTrack && this.saveExitButton && !this.trackGuid) this.onSaveCreatedTrack();
      else if (this.trackUnsavedChanges && this.showNotificationDialog) this.notificationDialog = true;
      else if (this.trackNameUpdated || this.trackUnsavedChanges) this.saveTrack();
    },

    async onSaveCreatedTrack() {
      if (this.trackGuid) return;
      else if ({}.hasOwnProperty.call(this.trackDetails, 'skills')) {
        try {
          if (!this.trackCreated.cancel) this.showToast(translate('createDetails.inProgressSaveDraftToastMessage'), 'info', null, true);
          let response;
          this.trackCreated.cancel = true;
          if (!this.trackCreated.save) {
            const formData = new FormData();
            if (this.trackDetails?.image) {
              let payoadImgName = this.trackDetails?.image?.name?.replace(/\s+/g, '');
              formData.append('image', this.trackDetails?.image, payoadImgName);
            }
            formData.set('description', this.trackDetails?.description);
            formData.set('name', this.trackDetails?.name);
            formData.set('requestedDuration', this.trackDetails?.requestedDuration);
            formData.set('languageFilters', this.trackDetails?.languageFilter);
            formData.set('durationFilters', this.trackDetails?.durationFilters);
            formData.set('technologyFilters', this.trackDetails?.technologyFilters);
            formData.set('providerFilters', this.trackDetails?.providerFilters);
            formData.set('levelFilters', this.trackDetails?.levelFilters);
            formData.set('contentTypes', this.trackDetails?.contentTypes);
            formData.set('accreditations', this.trackDetails?.accreditations);
            formData.set('requestedSkills', this.trackDetails?.requestedSkills);
            formData.set('skills', this.trackDetails?.skills);
            formData.set('contentFeature', this.trackDetails?.contentFeature);
            response = await this.$planAdmin.createTrack(formData);
            const editTrackPayload = {
              items: this.trackDetails?.items,
              trackGuid: response.data.guid
              //body: formData
            };
            await this.$planAdmin.editCreatedTrack(editTrackPayload);
            this.showToastMessage(response);
            if (response.code === 200) {
              this.trackCreated.save = true;
              this.trackSaveDialog = true;
              this.trackPopupDetails = this.createTrackPopup;
              await Promise.all([this.setTrackNameUpdated(true), this.setReCreateTrack(false), this.setTrackGuid(response?.data?.guid), this.setSaveExitButton(false), this.setSaveButtonState(true)]);
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    async saveTrack(shouldNotifyLearners = { notifyLearners: false }) {
      this.setSaveButtonState(true);
      this.showToast(translate('track.inProgressLearningTrackMessage'), 'info', null, true);
      const ccc_items_to_save = this.trackList.map((item) => {
        return {
          itemId: item.id,
          name: item.title,
          format: item.formatType.toLocaleLowerCase(),
          duration: {
            value: item.duration,
            type: 'seconds'
          },
          description: '',
          languageFilter: item.languageFilter
        };
      });
      const payload = {
        name: this.trackName,
        assignees: {
          roles: [{ guid: this.roleGuid, name: this.roleName }]
        },
        items: ccc_items_to_save,
        trackGuid: this.trackGuid,
        ...shouldNotifyLearners
      };
      if (!this.roleGuid) {
        delete payload.assignees.roles;
      }
      this.setUnsavedChangesState(false);
      this.setTrackNameUpdated(false);
      const response = this.trackGuid ? await this.$planAdmin.updateLearningTrack(payload) : await this.$planAdmin.saveLearningTrack(payload);
      this.showToastMessage(response);
      this.handleClearInput();
      if (response.code == 200) {
        this.$router.push('/catalog/stepper/manage');
        await this.setTotalTrackItems(response.data.size);
      }
    },
    updateTrackName() {
      //this.setUnsavedChangesState(true)
      this.setTrackNameUpdated(true);
      this.setSaveButtonState(false);
    },
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });

      this.$toasted.show(msg, options);
    },
    showToastMessage(response) {
      this.$toasted.clear();

      if (response.code == 200) {
        this.showToast(translate('organizeLearningTrack.saveLearningTrackMessage'), 'success', 3000, false);
      } else {
        this.showToast(translate('track.failureLearningTrackMessage'), 'error', 3000, false);
        this.setSaveButtonState(false);
        this.setUnsavedChangesState(true);
      }
    },
    getContentFeature(contentFeature) {
      if (contentFeature) return contentFeature.join(', ');
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.organizeLearningTrack')
    };
  }
};
</script>

<style lang="scss">
.organize-track {
  margin-top: -5rem;
  .v-text-field.v-text-field--solo .v-input__control {
    min-height: unset;
    padding: 0;
  }
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control .v-input__slot,
  .v-btn--is-elevated {
    min-height: 2.625rem !important;
    box-shadow: none !important;
    width: 492px;
  }
}
</style>

<style lang="scss" scoped>
@mixin scrollable {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 1.25rem;
    width: 0.25rem;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
}
.header-duration {
  width: 4rem;
}
.organize-track .expansion-card {
  padding: 1.875rem 1.875rem 1.5rem 3.125rem;
  background-color: rgb(255, 255, 255, 0.45);
  border-radius: 1.25rem;
}
.organize-track .expansion-card-header {
  padding-right: 9rem;
  div {
    font-family: source sans bold !important;
    font-size: 12px !important;
    letter-spacing: 0rem;
    color: #2d2d2d;
    opacity: 1;
  }
}
.organize-track .expand-title {
  display: block;
  display: -webkit-box;
  max-height: 3.75rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
  font-size: 18px !important;
  font-family: Source Serif !important;
}
.organize-track .skillBuilderCard {
  margin-right: 1.25rem;
  box-shadow: 0rem 0.1875rem 0.375rem #00000029;
  opacity: 1;
}
.organize-track .header {
  background-color: $color-grey-light;
}
.organize-track .v-expansion-panel {
  border-radius: 0.625rem;
}
.organize-track .v-expansion-panel-title {
  border-radius: 0.625rem;
  margin-bottom: 0.625rem;
}

.organize-track .draggable-container {
  width: 100%;
  @include scrollable;
  max-height: 40.625rem;
  overflow-y: scroll;
  padding-right: 1.25rem;
}
.organize-track .card1 {
  border-right: 0.0625rem solid #707070;
  margin: 0.625rem 0rem 5rem 5.625rem;
  padding-right: 1.875rem;
}
.organize-track .card2 {
  padding: 0.625rem 0rem 5rem 5.625rem;
}
.organize-track .content-skills {
  max-height: 12.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.meta {
  span {
    font-size: 12px !important;
    font-family: source sans !important;
    color: #2d2d2d;
  }
}

.ellipsis-text {
  text-align: center;
  max-width: 43%;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.organize-track .title-text {
  @include header-font-3;
  color: $color-primary;
  display: inline-block;
  text-transform: capitalize;
}

.organize-track .format {
  border-radius: 0.625rem;
  padding: 0.24rem 0.7rem;
  margin-bottom: 0.5rem;
  height: 1.375rem;

  font-family: source sans bold !important;
  font-weight: bold;
  font-size: 0.75 rem !important;
}
.organize-track .icon {
  order: 0;
}
.organize-track .expand-actions {
  max-width: 6.25rem;
  min-width: 6.25rem;
  order: 2;
}
.organize-track .expansion-header {
  order: 1;
  padding-left: 2rem;
  width: -webkit-fill-available;
  .row {
    display: flex;
    align-items: center;
  }
}
.organize-track .back-btn {
  color: $color-white !important;
  font-weight: bold;
  text-transform: capitalize !important;
  padding: 0 1.25rem !important;
}
.organize-track .save-btn {
  text-transform: capitalize !important;
  font-weight: bold;
  padding: 0 1.25rem !important;
}
.organize-track .v-btn {
  text-transform: capitalize !important;
}
.organize-track .download-btn {
  border: 0.125rem solid #ffb92e !important;
  margin-left: 1.25rem;
  font-weight: bold;
}
.organize-track .card-title {
  font-size: 1.75rem;
}
.organize-track .icon-large {
  width: 3em;
  height: 3em;
}
.organize-track .icon-small {
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  &.icon-backgroud {
    border-radius: var(--corner-radius-radius-xs, 4px);
    background: var(--fill-grey-lighter-3, #eaeaea);
  }
}
.searchTrack {
  width: 492px;
  .v-text-field .v-field {
    background-color: #ffffff !important;
  }
}

.action-btn {
  gap: 8px;
  .v-btn {
    width: 36px;
    height: 1rem !important;
  }
}
</style>
<style lang="scss">
.theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: black;
}
.organize-track .sortable-chosen .v-expansion-panel-header--mousedown {
  cursor: move;
}

.organize-track .v-expansion-panel-title--active .expand-icon {
  transform: rotate(180deg);
}
.organize-track .v-expansion-panel--active > .v-expansion-panel-title--active .v-expansion-panel-title__icon:not(.v-expansion-panel-title__icon--disable-rotate) .v-icon {
  transform: rotate(0deg);
}
.organize-label {
  margin-right: 6.25rem;
  display: flex;
  align-items: center;
  gap: 8px;
  button:disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
  .save-and-continue:disabled {
    background-color: rgba(255, 185, 46, 0.5) !important;
    color: rgba(31, 51, 112, 0.26);
    pointer-events: none;
  }
}
.organize-track {
  .v-expansion-panel-title__icon {
    margin-left: 0px;
  }
  .edit-track-name-container {
    margin: 1rem 7rem 1rem 4rem;
    .track-name-title {
      @include body-font-bold-1-5;
    }
    .track-name {
      @include body-font-1;
    }
  }
  .back {
    z-index: 1;
    margin-left: -2rem;
    span {
      margin-left: -0.25rem;
      text-align: left;
      font: normal normal bold 0.875rem/1.125rem Arial;
      letter-spacing: 0.0875rem;
      color: #5c5c5c;
      text-transform: uppercase;
    }
  }
  .action-buttons-container {
    margin-top: 2rem;
    margin-bottom: 1rem;
    .v-btn {
      height: 3.125rem !important;
      span {
        font: normal normal normal 1rem/1.375rem 'Futura PT Demi';
        letter-spacing: 0.0013rem;
      }
    }

    .exit-button {
      width: 6.1875rem !important;
    }
    .save-button {
      width: 5.375rem !important;
      &:disabled {
        color: rgba(0, 0, 0, 0.26) !important;
      }
    }
    .save-and-continue:disabled {
      background-color: rgba(255, 185, 46, 0.5) !important;
      color: rgba(31, 51, 112, 0.26);
      pointer-events: none;
    }
  }
}
</style>
