<template>
  <div class="announcements-container" ref="createFormTop" v-if="dataReady">
    <TrackSaveDialog :trackSaveDialog="submitChange" :trackPopupDetails="saveAnnouncementDetails" @cancel-navigation="cancelSubmit()" @saved-track="submitAnnouncement()" />
    <SaveDialog :saveDialog="showSaveDialog" :disableSaveButton="showSaveButton" @cancel-and-navigate="cancelAndNavigate()" @cancel-navigation="cancelSubmit()" />
    <div v-if="startDropdownOpen || endDropdownOpen" class="a-overlay" @click="onCloseDropDown()"></div>
    <div class="announcement-header">
      <h2 class="t-text-dark-grey">{{ announcementTitle }}</h2>
      <h5 class="t-text-dark-grey">{{ $t('settings.createAnnouncement.subTitle') }}</h5>
      <h6 class="t-text-black">{{ $t('settings.createAnnouncement.requiredInformation') }}</h6>
    </div>

    <div class="tenant-custom-panel-expand">
      <v-row class="t-items-center t-gap-8">
        <v-col cols="6.5">
          <div class="static-announcement">
            <h3 class="t-text-dark-grey">{{ $t('settings.createAnnouncement.clientNameStatic') }}</h3>
            <h2 class="t-text-primary-color">{{ $t('settings.createAnnouncement.announcementTitleStatic') }}</h2>
            <h6 class="t-text-dark-grey">{{ $t('settings.createAnnouncement.announcementCommentStatic') }}</h6>

            <div class="t-flex t-gap-4">
              <h6 class="t-text-primary-color t-font-bold">{{ $t('settings.createAnnouncement.primaryLinkStatic') }}</h6>
              <h6 class="t-text-primary-color t-font-bold">{{ $t('settings.createAnnouncement.secondaryLinkStatic') }}</h6>
            </div>
          </div>
        </v-col>
        <v-col :cols="imageSize ? 5 : 4">
          <div class="tenant-media">
            <h2 class="upload-text t-text-primary-color">{{ $t('settings.createAnnouncement.uploadImageStatic') }}</h2>
          </div>
        </v-col>
      </v-row>

      <div class="t-ml-8">
        <svg-icon class="collapse-icon" icon-class="Icon-feather-chevron-right" />
      </div>
    </div>

    <div>
      <label for="announcement-client-name" class="label-view">{{ translate('settings.createAnnouncement.clientName') }}<span class="asterisk">*</span></label>
      <input id="announcement-client-name" class="t-mt-2" type="text" :placeholder="translate('settings.createAnnouncement.namePlaceholder')" v-model="newAnnouncement.clientName" maxlength="75" @input="onFieldValidate" />
      <span class="t-float-right t-mt-2 count">{{ translate('settings.createAnnouncement.characterCount') }}</span>
    </div>
    <div>
      <label for="announcement-title" class="label-view">{{ translate('settings.createAnnouncement.announcementTitle') }}<span class="asterisk">*</span></label>
      <input id="announcement-title" class="t-mt-2" type="text" :placeholder="translate('settings.createAnnouncement.namePlaceholder')" v-model="newAnnouncement.announcementTitle" maxlength="75" @input="onFieldValidate" />
      <span class="t-float-right t-mt-2 count">{{ translate('settings.createAnnouncement.characterCount') }}</span>
    </div>
    <div>
      <label for="announcement-description" class="label-view">{{ translate('settings.createAnnouncement.annoncementDescription') }}<span class="asterisk">*</span></label>
      <input id="announcement-description" class="t-mt-2" type="text" :placeholder="translate('settings.createAnnouncement.descriptionPlaceholder')" v-model="newAnnouncement.annoncementDescription" maxlength="285" @input="onFieldValidate" />
      <span class="t-float-right t-mt-2 count">{{ translate('settings.createAnnouncement.desCharacterCount') }}</span>
    </div>
    <UploadAnnouncementFile v-if="loadUploadSection" @uploadMedia="getUploadMediaId" @uploadMediaError="getUploadMediaError" :uploadDetails="uploadDetails" />

    <div class="link-container">
      <label for="announcement-primary-link" class="label-view">{{ translate('settings.createAnnouncement.primaryLink') }}</label>
      <div class="link-details">
        <div class="link-property">
          <input id="announcement-primary-link" type="text" placeholder="Title|" v-model="newAnnouncement.primaryLinkTitle" maxlength="70" />
          <span class="t-float-right t-mt-2 count">{{ translate('settings.createAnnouncement.maxCharacterLimit') }}</span>
        </div>
        <div class="link-name">
          <input
            id="announcement-primary-link"
            type="url"
            :placeholder="translate('settings.createAnnouncement.linkType')"
            v-model="newAnnouncement.primaryLinkUrl"
            ref="newAnnouncement.primaryLinkUrl"
            :style="isLinkUrl.isPrimaryLinkError && 'border: 2px solid #e0301e'"
            @input="onUrlUpdate"
          />
          <p v-if="isLinkUrl.isPrimaryLinkError" class="t-mt-2 url-error-message">{{ translate('settings.createAnnouncement.primaryLinkErrMsg') }}</p>
        </div>
      </div>
    </div>
    <div class="link-container">
      <label for="announcement-seconadary-link" class="label-view">{{ translate('settings.createAnnouncement.secondaryLink') }}</label>
      <div class="link-details">
        <div class="link-property">
          <input id="announcement-seconadary-link" type="text" placeholder="Title|" v-model="newAnnouncement.secondaryLinkTitle" maxlength="70" />
          <span class="t-float-right t-mt-2 count">{{ translate('settings.createAnnouncement.maxCharacterLimit') }}</span>
        </div>
        <div class="link-name">
          <input
            id="announcement-seconadary-link"
            type="url"
            :placeholder="translate('settings.createAnnouncement.linkType')"
            v-model="newAnnouncement.secondaryLinkUrl"
            ref="newAnnouncement.primaryLinkUrl"
            :style="isLinkUrl.isSecondaryLinkError && 'border: 2px solid #E0301E'"
            @input="onUrlUpdate"
          />
          <p v-if="isLinkUrl.isSecondaryLinkError" class="t-mt-2 url-error-message">{{ translate('settings.createAnnouncement.primaryLinkErrMsg') }}</p>
        </div>
      </div>
    </div>

    <div class="date-time-container">
      <p class="t-dark-grey date-time-text">Select the dates for when you want the announcement module to go <b>Live</b> and <b>Expire</b>. It will appear on the homepage for all learners starting on the specified date and disappear on the end date.</p>
      <div class="date-container">
        <div class="date-time-details">
          <span class="date-time-text">{{ translate('settings.createAnnouncement.startDate') }}<span class="asterisk">*</span></span>
          <date-picker
            v-model:value="startDatePicker"
            value-type="D-MMM-YYYY"
            placeholder="DD MM YYYY"
            confirm
            confirm-text="Select"
            @confirm="globalSelectStartDate(startDatePicker)"
            format="D MMM YYYY"
            title-format="D MMM YYYY"
            :clearable="false"
            :editable="false"
            :disabled-date="disableDates"
          ></date-picker>
        </div>
        <div class="date-time-details">
          <span class="date-time-text">{{ translate('settings.createAnnouncement.startTime') }}</span>
          <div class="time-dropdown">
            <div :class="{ disabled: isStartPastTime(startTime) }" class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black" id="startTime" :disabled="true" @click="toggleDropdown('startTime')">
              <span class="t-font-arial t-text-sm">{{ startTime }}</span>
              <v-icon>mdi-chevron-down</v-icon>
            </div>
            <div v-if="startDropdownOpen" class="time-options">
              <ul>
                <li v-for="time in timeValue" :key="time" @click="isStartPastTime(time) ? null : selectTime(time, 'startTime')" :class="{ disabled: isStartPastTime(time), selected: time === startTime }">
                  {{ time }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="date-time-details">
          <span class="date-time-text">{{ translate('settings.createAnnouncement.endDate') }}<span class="asterisk">*</span></span>
          <date-picker
            v-model:value="endDatePicker"
            value-type="D-MMM-YYYY"
            placeholder="DD MM YYYY"
            confirm
            confirm-text="Select"
            @confirm="globalSelectEndDate(endDatePicker)"
            format="D MMM YYYY"
            title-format="D MMM YYYY"
            :clearable="false"
            :editable="false"
            :disabled-date="disableDates"
          ></date-picker>
        </div>
        <div class="date-time-details">
          <span class="date-time-text">{{ translate('settings.createAnnouncement.endTime') }}</span>
          <div class="time-dropdown">
            <div :class="{ disabled: isEndPastTime(endTime) }" class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black" id="endTime" :disabled="true" @click="toggleDropdown('endTime')">
              <span class="t-font-arial t-text-sm">{{ endTime }}</span>
              <v-icon>mdi-chevron-down</v-icon>
            </div>
            <div v-if="endDropdownOpen" class="time-options">
              <ul>
                <li v-for="time in timeValue" :key="time" @click="isEndPastTime(time) ? null : selectTime(time, endTime)" :class="{ disabled: isEndPastTime(time), selected: time === endTime }">
                  {{ time }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-container">
      <button class="cancel-btn t-mx-1 min-w-min t-border-primary-color" text @click="cancelNavigation()">
        {{ $t('button.cancel') }}
      </button>
      <button :disabled="isSaveBtnDisabled" @click="publish" class="button-style t-color-white t-rounded-full hover:t-bg-primary-color hover:t-text-white">
        {{ translate('settings.createAnnouncement.publish') }}
      </button>
      <button :disabled="isSaveBtnDisabled" @click="save" class="save-btn t-color-white t-rounded-full t-bg-credential t-border-credential hover:t-bg-primary-color hover:t-text-white">
        {{ translate('settings.createAnnouncement.save') }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { translate } from '@/plugins/i18n.js';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import { validURL } from '@/utils/validate.js';
import TrackSaveDialog from '../../catalog/components/TrackSaveDialog.vue';
import UploadAnnouncementFile from './UploadAnnouncementFile.vue';
import { localizedDate, localizedDate12Hour } from '@/utils';
import SaveDialog from '../../catalog/components/SaveDialog.vue';

const initialState = () => ({
  announcementTitle: translate('settings.createAnnouncement.title'),
  startDropdownOpen: false,
  endDropdownOpen: false,
  dataReady: false,
  showSaveDialog: false,
  toPath: '',
  searchTerm: '',
  submitChange: false,
  publishClicked: false,
  imageSize: true,
  saveAnnouncementDetails: {},
  saveAnnouncementData: {
    title: translate('settings.createAnnouncement.saveChange'),
    message: translate('settings.createAnnouncement.saveMessage'),
    cancel: 'Cancel',
    ok: 'Yes'
  },
  publishAnnouncementData: {
    title: translate('settings.createAnnouncement.publishChange'),
    message: translate('settings.createAnnouncement.publishMessage'),
    cancel: 'Cancel',
    ok: 'Yes'
  },
  unablePublishAnnouncement: {
    title: translate('settings.createAnnouncement.unablePublish'),
    message: translate('settings.createAnnouncement.unableMessage'),
    okay: 'Okay'
  },
  reception_hours_start: null,
  timeValue: [
    translate('settings.createAnnouncement.timeValue[0]'),
    translate('settings.createAnnouncement.timeValue[1]'),
    translate('settings.createAnnouncement.timeValue[2]'),
    translate('settings.createAnnouncement.timeValue[3]'),
    translate('settings.createAnnouncement.timeValue[4]'),
    translate('settings.createAnnouncement.timeValue[5]'),
    translate('settings.createAnnouncement.timeValue[6]'),
    translate('settings.createAnnouncement.timeValue[7]'),
    translate('settings.createAnnouncement.timeValue[8]'),
    translate('settings.createAnnouncement.timeValue[9]'),
    translate('settings.createAnnouncement.timeValue[10]'),
    translate('settings.createAnnouncement.timeValue[11]'),
    translate('settings.createAnnouncement.timeValue[12]'),
    translate('settings.createAnnouncement.timeValue[13]'),
    translate('settings.createAnnouncement.timeValue[14]'),
    translate('settings.createAnnouncement.timeValue[15]'),
    translate('settings.createAnnouncement.timeValue[16]'),
    translate('settings.createAnnouncement.timeValue[17]'),
    translate('settings.createAnnouncement.timeValue[18]'),
    translate('settings.createAnnouncement.timeValue[19]'),
    translate('settings.createAnnouncement.timeValue[20]'),
    translate('settings.createAnnouncement.timeValue[21]'),
    translate('settings.createAnnouncement.timeValue[22]')
  ],
  isLinkUrl: {
    isPrimaryLinkError: false,
    isSecondaryLinkError: false
  },
  uploadMediaError: false,
  notificationMessage: '',
  isSubmitting: false,
  isSaveBtnDisabled: true,
  newAnnouncement: {
    clientName: '',
    announcementTitle: '',
    annoncementDescription: '',
    primaryLinkTitle: '',
    primaryLinkUrl: '',
    secondaryLinkTitle: '',
    secondaryLinkUrl: '',
    globalSelectedDate: '',
    globalStartDatePickerMenu: false,
    showExpandedFilter: false,
    uploadAnnouncementMediaId: '',
    navigateNext: false
  },
  deleteUplodedAttachment: [],
  startTime: translate('settings.createAnnouncement.timeDefault'),
  endTime: translate('settings.createAnnouncement.timeDefault'),
  startDatePicker: '',
  endDatePicker: '',
  uploadDetails: {},
  loadUploadSection: false
});
export default {
  name: 'CreateEditAnnouncement',
  props: {
    announcementInFocus: {
      require: false,
      type: Object,
      default: () => {
        return {};
      }
    },
    dateRange: {
      require: false,
      type: Array,
      default: () => []
    },
    optionSelected: {
      requied: false,
      type: String,
      default: ''
    }
  },
  emits: ['announcementData', 'announcement-created'],
  components: { DatePicker, UploadAnnouncementFile, TrackSaveDialog, SaveDialog },
  computed: {
    ...mapGetters([])
  },

  data() {
    return initialState();
  },

  watch: {
    announcementInFocus(newItems) {
      if (newItems) this.getAnnouncementInFocusData();
      else return;
    },
    optionSelected(newItems) {
      if (newItems) this.getAnnouncementInFocusData();
      else return;
    }
  },
  async activated() {},
  async created() {},
  async mounted() {
    Object.assign(this.$data, initialState());
    if (this.announcementInFocus.guid && this.announcementInFocus.id) {
      await this.getAnnouncementInFocusData();
    } else {
      this.announcementTitle = translate('settings.createAnnouncement.title');
      this.loadUploadSection = true;
    }
    this.imageSize = window.innerWidth > 1200 ? true : false;
    this.dataReady = true;
    if (this.dataReady) {
      this.$nextTick(() => {
        const formSection = this.$refs.createFormTop;
        if (formSection && formSection.scrollIntoView) {
          formSection.scrollIntoView({ behavior: 'smooth' });
        }
      });
    }
  },
  methods: {
    translate,
    localizedDate,
    localizedDate12Hour,
    isEndPastTime(time) {
      const currentDate = moment(new Date()).format('D-MMM-YYYY');
      if (currentDate == this.endDatePicker) {
        return this.isPastTime(time);
      } else {
        return null;
      }
    },
    isStartPastTime(time) {
      const currentDate = moment(new Date()).format('D-MMM-YYYY');
      if (currentDate == this.startDatePicker) {
        return this.isPastTime(time);
      } else {
        return null;
      }
    },
    isPastTime(time) {
      const [timePart, ampm] = time.split(' ');
      let [hours, minutes] = timePart.split(':').map(Number);
      if (ampm === 'PM' && hours !== 12) hours += 12;
      if (ampm === 'AM' && hours === 12) hours = 0;
      const now = new Date();
      const selectedDate = new Date();
      selectedDate.setHours(hours, minutes, 0, 0);
      return selectedDate < now;
    },
    onCloseDropDown() {
      this.startDropdownOpen = false;
      this.endDropdownOpen = false;
    },
    toggleDropdown(id) {
      if (id == translate('settings.createAnnouncement.startTimeId')) {
        this.startDropdownOpen = !this.startDropdownOpen;
      } else {
        this.endDropdownOpen = !this.endDropdownOpen;
      }
    },
    selectTime(time, type) {
      if (type == translate('settings.createAnnouncement.startTimeId')) {
        this.startTime = time;
        this.startDropdownOpen = false;
      } else {
        this.endTime = time;
        this.endDropdownOpen = false;
      }
      this.onFieldValidate();
    },
    prepareToExit(to, from, next) {
      if (this.navigateNext) {
        next();
      } else {
        if (!this.isSaveBtnDisabled) {
          this.toPath = to.path || '';
          this.showSaveDialog = true;
          return true;
        } else {
          return false;
        }
      }
    },
    async getAnnouncementInFocusData() {
      if (this.dataReady) {
        this.$nextTick(() => {
          const formSection = this.$refs.createFormTop;
          if (formSection && formSection.scrollIntoView) {
            formSection.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
      try {
        if (this.announcementInFocus.guid) {
          const payload = {
            guid: this.announcementInFocus.guid
          };
          this.$planAdmin.getAnnouncementDetails(payload).then((res) => {
            if (res?.error) {
              this.announcementTitle = translate('settings.createAnnouncement.title');
              this.newAnnouncement = {};
              this.startDatePicker = '';
              this.startTime = translate('settings.createAnnouncement.timeDefault');
              this.endDatePicker = '';
              this.endTime = translate('settings.createAnnouncement.timeDefault');
              this.loadUploadSection = true;
              this.onFieldValidate();
            } else if (res) {
              this.announcementTitle = translate('settings.createAnnouncement.editTitle');
              this.onFieldValidate();
              this.uploadDetails = res.content;
              this.newAnnouncement = {
                clientName: res.clientName,
                announcementTitle: this.optionSelected === translate('settings.annoucements.dataTable.options[3]') ? `${translate('settings.createAnnouncement.duplicateTitle')}${res.title}` : res.title,
                annoncementDescription: res.description,
                primaryLinkTitle: res?.primaryLinkName,
                primaryLinkUrl: res?.primaryLink,
                secondaryLinkTitle: res?.secondaryLinkName,
                secondaryLinkUrl: res?.secondaryLink,
                uploadAnnouncementMediaId: res?.content?.mediaId,
                globalStartDatePickerMenu: false,
                showExpandedFilter: false,
                navigateNext: false
              };
              this.startDatePicker = this.optionSelected === translate('settings.annoucements.dataTable.options[1]') ? moment(new Date(res.startDate).getTime()).format('D-MMM-YYYY') : '';
              this.startTime = moment(new Date(res.startDate).getTime()).format('h:mm A');
              this.endDatePicker = this.optionSelected === translate('settings.annoucements.dataTable.options[1]') ? moment(new Date(res.endDate).getTime()).format('D-MMM-YYYY') : '';
              this.endTime = moment(new Date(res.endDate).getTime()).format('h:mm A');
              this.loadUploadSection = true;
            }
          });
        } else {
          this.uploadDetails = {};
          this.newAnnouncement = {};
          this.announcementTitle = translate('settings.createAnnouncement.title');
          this.startDatePicker = '';
          this.startTime = translate('settings.createAnnouncement.timeDefault');
          this.endDatePicker = '';
          this.endTime = translate('settings.createAnnouncement.timeDefault');
          this.loadUploadSection = true;
          this.onFieldValidate();
        }
      } catch (error) {
        console.log(error);
      }
    },
    cancelNavigation() {
      this.publishClicked = false;
      this.showSaveDialog = false;
      this.submitChange = false;
      this.navigateNext = true;
      this.clearData();
      this.$emit('announcementData');
    },
    clearData() {
      this.deleteUplodedAttachment = [];
      this.newAnnouncement = {};
      this.announcementTitle = translate('settings.createAnnouncement.title');
    },
    cancelAndNavigate() {
      this.navigateNext = true;
      this.showSaveDialog = false;
      this.submitChange = false;
      this.publishClicked = false;
      this.clearData();
      this.$router.push({ path: this.toPath });
    },
    async submitAnnouncement() {
      this.submitChange = false;
      if (this.publishClicked) {
        this.startDatePicker = moment().format('D-MMM-YYYY');
        this.startTime = moment().format('h:00 A');
      }
      this.showToast(translate('createDetails.inProgressSaveDraftToastMessage'), 'info', null, true);
      try {
        const newAnnouncementObj = {
          clientName: this.newAnnouncement.clientName,
          title: this.newAnnouncement.announcementTitle,
          description: this.newAnnouncement.annoncementDescription,
          primaryLinkName: this.newAnnouncement.primaryLinkTitle,
          secondaryLinkName: this.newAnnouncement.secondaryLinkTitle,
          primaryLink: this.newAnnouncement.primaryLinkUrl,
          secondaryLink: this.newAnnouncement.secondaryLinkUrl,
          startDate: this.getNewTimeStamp(this.startDatePicker, this.startTime),
          endDate: this.getNewTimeStamp(this.endDatePicker, this.endTime),
          mediaId: this.newAnnouncement.uploadAnnouncementMediaId,
          isPublishNow: this.publishClicked || false
        };
        if (this.optionSelected === translate('settings.annoucements.dataTable.options[1]') && this.announcementInFocus?.guid) {
          const UpdatePayload = {
            guid: this.announcementInFocus.guid,
            ...newAnnouncementObj
          };
          this.$planAdmin.updateAnnouncementDetails(UpdatePayload).then((updateResult) => {
            if (this.deleteUplodedAttachment.length > 0) this.clearDeletedUploadedAttachments();
            this.showResponseMessage(updateResult);
          });
        } else {
          this.$planAdmin.submitAnnouncement(newAnnouncementObj).then((res) => {
            this.showResponseMessage(res);
          });
        }
      } catch (error) {
        this.$toasted.clear();
        console.log(error);
      }
    },
    clearDeletedUploadedAttachments() {
      const payload = this.deleteUplodedAttachment;
      this.$planAdmin
        .deleteAnnoncementFile(payload)
        .then((res) => {
          if (res.length > 0) {
            this.deleteUplodedAttachment = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async save() {
      if (this.isSaveBtnDisabled) {
        return true;
      }
      this.saveAnnouncementDetails = this.saveAnnouncementData;
      this.publishClicked = false;
      this.submitChange = true;
    },
    async publish() {
      if (this.isSaveBtnDisabled) {
        return true;
      }
      this.saveAnnouncementDetails = this.publishAnnouncementData;
      this.publishClicked = true;
      this.submitChange = true;
    },
    onFieldValidate() {
      this.isSaveBtnDisabled = !this.isFormValid();
    },

    isFormValid() {
      if (this.isLinkUrl.isPrimaryLinkError || this.isLinkUrl.isSecondaryLinkError || this.uploadMediaError) return false;
      if (!this.newAnnouncement.clientName || this.newAnnouncement.clientName === '') return false;
      if (!this.newAnnouncement.announcementTitle || this.newAnnouncement.announcementTitle === '') return false;
      if (!this.newAnnouncement.annoncementDescription || this.newAnnouncement.annoncementDescription === '') return false;
      if (!this.startDatePicker || this.startDatePicker === '') return false;
      if (!this.endDatePicker || this.endDatePicker === '') return false;
      if (this.isStartPastTime(this.startTime)) return false;
      if (this.isEndPastTime(this.endTime)) return false;
      return true;
    },
    onUrlUpdate($event) {
      if ($event.target.id === 'announcement-primary-link') {
        this.newAnnouncement.primaryLinkUrl = $event.target.value;
        this.isLinkUrl.isPrimaryLinkError = !validURL(this.newAnnouncement.primaryLinkUrl);
      } else {
        this.newAnnouncement.secondaryLinkUrl = $event.target.value;
        this.isLinkUrl.isSecondaryLinkError = !validURL(this.newAnnouncement.secondaryLinkUrl);
      }
    },
    showResponseMessage(response) {
      this.$toasted.clear();
      this.publishClicked = false;
      if (response?.code == 201 || response?.status == 'success') {
        this.submitChange = false;
        this.navigateNext = true;
        this.$emit('announcementData');
        this.EventBus.emit('announcement-created');
        this.showToast(translate('createDetails.successSaveDraftToastMessage'), 'success', 3000, false);
      } else if (response?.debugMessage?.includes('CONFLICT')) {
        this.submitChange = true;
        this.saveAnnouncementDetails = this.unablePublishAnnouncement;
      } else if (response?.status == 400) {
        response.message == translate('settings.createAnnouncement.startDatePriorBackendMsg') ? this.showToast(translate('settings.createAnnouncement.startDatePriorErrMsg'), 'error', 3000, false) : this.showToast(response.message, 'error', 3000, false);
      } else {
        this.submitChange = false;
        this.showToast(translate('createDetails.failureSaveDraftToastMessage'), 'error', 3000, false);
      }
    },
    disableDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      if (this.dateRange.length > 0) {
        const bookedDatesMillis = this.dateRange.map((bookedDate) => new Date(bookedDate).getTime());
        const currentDateMillis = date.getTime();
        return date < today || bookedDatesMillis.includes(currentDateMillis);
      } else {
        return date < today;
      }
    },
    globalSelectStartDate(startDatePicker) {
      if (startDatePicker) {
        this.isStartPastTime(this.startTime);
        this.globalStartDatePickerMenu = false;
        this.startDatePicker = startDatePicker;
        this.onFieldValidate();
      }
    },
    globalSelectEndDate(endDatePicker) {
      if (endDatePicker) {
        this.isEndPastTime(this.endTime);
        this.globalendDatePickerMenu = false;
        this.endDatePicker = endDatePicker;
        this.onFieldValidate();
      }
    },
    getUploadMediaId(type, mediaId) {
      if (type === 'deleteAttachment') this.deleteUplodedAttachment.push(mediaId);
      if (this.deleteUplodedAttachment.includes(this.newAnnouncement.uploadAnnouncementMediaId)) {
        this.newAnnouncement.uploadAnnouncementMediaId = '';
      } else {
        this.newAnnouncement.uploadAnnouncementMediaId = mediaId || '';
      }
    },
    getUploadMediaError(error) {
      this.uploadMediaError = error;
      this.onFieldValidate();
    },
    cancelSubmit() {
      this.submitChange = false;
      this.publishClicked = false;
      this.showSaveDialog = false;
      this.toPath = '';
    },
    getNewTimeStamp(date, time) {
      const dateTimeStr = date + ' ' + time;
      const dateTime = moment(dateTimeStr, 'DD-MMM-YYYY h:mm A');
      return dateTime.valueOf();
    },
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };
      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });

      this.$toasted.show(msg, options);
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.announcements')
    };
  }
};
</script>

<style lang="scss">
.mx-datepicker {
  width: 140px !important;
  .mx-input {
    height: 40px;
  }
}
.mx-datepicker-popup {
  z-index: 100000;
}
.mx-calendar {
  width: 332px;
}
.mx-calendar-content {
  height: 308px !important;
}
.mx-calendar-header {
  .mx-btn-text {
    color: #5c5c5c;
    font: normal normal bold 1.5rem source serif;
  }
}
.mx-table {
  th {
    color: #2d2d2d;
    border-bottom: 1px solid #d6d6d6;
    font: normal normal 1rem source serif;
  }
  td {
    color: #5c5c5c;
    font: normal normal bold 1.5rem source serif;
    div {
      padding: 6px;
    }
  }
  .cell.active {
    background-color: #ffffff;
    div {
      color: #000000;
      background-color: #ffb92e;
      border-radius: 30px;
    }
  }
}
.mx-datepicker-footer {
  .mx-btn {
    color: #000000;
    background: #ffb92e;
    border: unset;
    border-radius: 20px;
    font: normal normal 1rem Source Sans Bold;
  }
}
</style>

<style lang="scss" scoped>
.announcements-container {
  padding: 3rem;
  background-color: #ffffff;
  margin: 3rem 0 0 0;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
  gap: 32px;
  .a-overlay {
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
  }
  .asterisk {
    color: red;
  }
  h2 {
    @include header-font-2;
    color: #2d2d2d;
  }
  h3 {
    @include header-font-1-5;
    color: #2d2d2d;
  }
  h5 {
    @include body-font-1-25;
    color: #2d2d2d;
  }
  h6 {
    @include body-font-1;
    color: #2d2d2d;
  }
  .label-view {
    @include header-font-0-875;
    color: var(--Text-Body-DarkGrey, #2d2d2d);
  }
  .announcement-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    .create-btn {
      background: #ffb92e;
      padding: 1rem 2rem;
      border-radius: 200px;
      @include body-font-button-text;
      color: #000;
    }
  }
  #announcement-client-name,
  #announcement-title,
  #announcement-description {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e0e1e1;
    border-radius: 4px;
    width: 100%;
    height: 56px;
    padding: 1rem;
    resize: none;
    color: var(--Text-Body-DarkGrey, #2d2d2d);
    @include body-font-1;
  }
  #announcement-description {
    height: 80px;
  }
  .count {
    color: var(--PE-Grayscale-Gray, var(--Colors-Grayscale-Grey, #5c5c5c));
    @include body-font-0-75;
  }
  .tenant-custom-panel-expand {
    border: 1px solid var(--Border-Border-Midnight, #d6d6d6);
    display: inline-block;
    padding: 32px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    .static-announcement {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      position: relative;
    }
    .tenant-media {
      display: flex;
      width: 540px;
      height: 270px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      border-radius: 24px;
      background: var(--Fill-Midnight-Lighter3, #dde0e9);
      @media screen and (max-width: 1200px) {
        width: 300px !important;
        height: 150px !important;
      }
      .upload-text {
        @media screen and (max-width: 1200px) {
          padding: 0 2.5rem;
          font-size: 24px !important;
        }
      }
    }
    .collapse-icon {
      border-radius: var(--corner-radius-radius-100, 200px);
      border: 1px solid var(--Border-Border-Grey-Lighter2, #d6d6d6);
      background: var(--Fill-White, #fff);
      display: flex;
      width: 40px;
      height: 40px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
  }
  .file-upload-container {
    display: flex;
    height: 183px;
    padding: 32px 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    border: 1px dashed var(--Colors-Grayscale-Grey-Lighter1, #adadad);
    background: var(--Colors-Primary-White, #fff);
    .upload-btn-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      .upload-icon {
        color: #2d2d2d;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      .supported-format {
        @include body-font-0-75;
      }
    }
    .file-upload-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      .file-size-text {
        @include body-font-0-875;
      }
      .choose-file {
        @include body-font-bold-1;
        .upload-input {
          display: none;
        }
      }
    }
  }
  .link-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;
    #announcement-primary-link,
    #announcement-seconadary-link {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e0e1e1;
      border-radius: 4px;
      width: 100%;
      height: 56px;
      padding: 1rem;
      resize: none;
      color: var(--Text-Body-DarkGrey, #2d2d2d);
      @include body-font-1;
    }
    .link-details {
      display: flex;
      flex-direction: row;
      border: 1px solid #e0e1e1;
      border-radius: 10px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.122);
      .link-property {
        width: 40%;
        padding: 16px;
      }
      .link-name {
        width: 60%;
        padding: 16px;
        .url-error-message {
          color: var(--PE-Status-Colors-Warning-Status, #e0301e);
          @include body-font-0-75;
        }
      }
      .link-value {
        display: flex;
        padding: 24px 24px 32px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        align-self: stretch;
        border-radius: 0px 8px 0px 0px;
      }
    }
  }
  .date-time-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    .date-time-text {
      @include body-font-1-25;
    }
    .date-container {
      display: flex;
      align-items: flex-end;
      gap: 48px;
      .date-time-details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        .time-dropdown {
          position: relative;
          display: inline-block;
          .filter-pill {
            display: flex;
            width: 144px;
            height: 40px;
            padding: 8px;
            justify-content: space-between;
            align-items: center;
            border-radius: 4px !important;
            border: 1px solid var(--Colors-Grayscale-Grey-Lighter1, #adadad);
            background: var(--pe-primary-secondary-colors-white, #fff);
          }
          .filter-pill.disabled {
            color: #ccc;
          }
          .time-options {
            background: var(--pe-primary-secondary-colors-white, #fff);
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
            padding: 16px;
            width: 9rem;
            overflow-y: scroll;
            height: 15rem;
            position: absolute;
            top: calc(100% + 5px);
            left: 0;
            background-color: #fff;
            border: 1px solid #ccc;
            border-radius: 4px;
            z-index: 1000;
          }

          .time-options ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }

          .time-options ul li {
            padding: 8px;
            border-radius: 4px;
            cursor: pointer;
          }

          .time-options ul li:hover {
            background-color: #f0f0f0;
          }

          .time-options ul li.selected {
            background-color: #ddd;
          }
          .time-options ul li.disabled {
            color: #ccc;
            cursor: not-allowed;
            pointer-events: none;
          }

          .time-options ul li:hover:not(.disabled) {
            background-color: #f0f0f0;
          }
        }
        .date-time-text {
          color: var(--Text-Body-DarkGrey, #2d2d2d);
          @include body-font-bold-0-75;
        }
        .time-field {
          width: 128px;
          height: 40px;

          justify-content: space-between;
          align-items: center;
        }
        .time-zone-label {
          width: 304px;
          height: 40px;

          justify-content: space-between;
          align-items: center;

          .v-label {
            font-weight: normal;
            font-family: Arial;
            font-size: 0.875rem;
            color: black;
          }
          .v-field--variant-solo {
            background-color: white !important;
            height: 2.3125rem;
            border: 0.125rem solid #e0e1e1;
          }

          .v-field__field,
          .v-field__prepend-inner {
            height: 2.3125rem;
          }
          .v-field__input {
            min-height: 40px;

            input {
              margin: 0px;
              &::placeholder {
                font: normal normal normal 1rem/1.375rem Arial;
                letter-spacing: 0.0006rem;
                color: #9f9f9f;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .button-container {
    display: flex;
    align-items: flex-start;
    gap: 32px;
    margin-left: auto;
    display: flex;
    .cancel-btn {
      @include body-font-bold-1;
      color: var(--Text-Body-Midnight, #1f3370);
      text-align: center;
      display: flex;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .save-btn:disabled {
      background-color: rgba(255, 185, 46, 0.5) !important;
      color: rgba(31, 51, 112, 0.26);
      pointer-events: none;
    }
    .save-btn {
      display: flex;
      padding: 16px 32px;
      justify-content: center;
      align-items: center;
      text-align: center;
      @include body-font-bold-1;
      cursor: pointer;
    }
    .button-style {
      border-radius: var(--corner-radius-radius-100, 200px);
      border: 2px solid var(--Border-Border-Midnight, #1f3370);
      display: flex;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      @include body-font-bold-1;
      color: var(--Text-Body-Midnight, #1f3370);
      text-align: center;
      cursor: pointer;
    }
    .button-style:disabled {
      border: 2px solid var(--Border-Border-Midnight, white);
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
