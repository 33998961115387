<template>
  <v-dialog class="refresh-dialog" :modelValue="refreshDialog" @update:modelValue="refreshDialog = $event" persistent width="450">
    <v-card class="t-rounded-20-xl t-pt-6 t-px-0">
      <v-card-title class="t-text-2xl t-tracking-normal t-font-futura-pt-demi t-text-black t-pb-5"> {{ $t('confirmation.refreshTrack.message') }} </v-card-title>
      <v-card-text class="t-text-base t-tracking-normal t-font-arial">
        {{ $t('confirmation.refreshTrack.details') }}
        <br /><br />
        {{ $t('confirmation.refreshTrack.note') }}
      </v-card-text>
      <v-card-actions class="t-pb-8 t-place-content-center">
        <button class="t-font-futura-pt-book t-mx-4 t-color-white t-py-2 min-w-min t-px-6 t-rounded-full t-font-bold t-text-base t-bg-yellow-400 t-border-yellow-400 hover:t-bg-primary-color hover:t-text-white" text @click="confirmRefresh()">{{ $t('button.confirm') }}</button>
        <button class="t-font-futura-pt-book t-mx-4 t-color-white t-py-2 min-w-min t-px-6 t-rounded-full t-font-bold t-text-base t-border-solid t-border-primary-color hover:t-bg-primary-color hover:t-text-white" text @click="cancelRefresh()">{{ $t('button.cancel') }}</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'RefreshTrackDialog',
  props: {
    refreshDialog: {
      type: Boolean,
      required: true,
      default() {
        return false;
      }
    }
  },
  methods: {
    confirmRefresh() {
      this.$emit('refresh-confirm');
    },
    cancelRefresh() {
      this.$emit('refresh-cancel');
    }
  }
};
</script>

<style lang="scss" scoped></style>
