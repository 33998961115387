<template>
  <v-dialog activator="parent" :value="dialog" @input="$emit('input', $event)" @click:outside="closeDialog()" content-class="v-content-dialog" persistent>
    <v-card v-if="catalogItem" style="margin: 0; border-radius: 0">
      <div class="catalog-dialog-close-modal-container">
        <v-btn class="float-right t-text-black catalog-dialog-close-button" variant="text" icon @click="closeDialog()" style="margin: 1.75rem 1.75rem 0rem 0rem">
          <svg-icon id="cancel-success-toast" icon-class="icon-cancel" />
        </v-btn>
      </div>
      <div class="catalog-dialog-container">
        <div class="catalog-dialog-content-container">
          <div class="catalog-dialog-content-column-left">
            <div style="margin-bottom: 1.75rem">
              <span class="t-uppercase t-rounded-full t-font-bold t-text-xs t-mb-0.5 t-h-6 t-font-arial t-px-3 t-py-1 t-mr-2" :class="dataFormatBg[catalogItem.formatType.toLocaleLowerCase()]">
                {{ catalogItem.formatType ? getLocalizedContentType(catalogItem.formatType) : getLocalizedContentType('Credential') }}</span
              >
              <span class="catalog-dialog-provider">
                <span v-if="catalogItem.provider === 'N/A'"></span>
                <span v-else> {{ catalogItem.provider && catalogItem.provider }}</span>
              </span>
            </div>
            <div class="catalog-dialog-title">{{ catalogItem.title }}</div>
            <div class="ratings-total-container">
              <div class="retier" v-if="catalogItem.isRetiring">
                <span>
                  <svg-icon icon-class="clock-grey" />
                  {{ $t('catalog.retirementText') }}
                </span>
                <span class="retier-tooltip-text">{{ $t('catalog.retirementMessage') }}{{ getLocalDateFormat(catalogItem.retirementDate) }}</span>
              </div>
              <span v-if="!catalogItem.rating">{{ $t('common.noRating') }}</span>
              <span v-else class="ratings-total-content">
                <span class="ratings-total">{{ catalogItem.rating }}</span>
                <StarRating inactiveColor="#d0cfce" starColor="#b98032" v-model="catalogItem.rating" :read-only="true" :increment="0.1" :starSize="16" :show-rating="false" :round-start-rating="true" class="rating" />
                <span class="ratings-count">{{ numberWithCommas(catalogItem.numOfRatings) }} {{ catalogItem.numOfRatings > 1 || catalogItem.numOfRatings === 0 ? $t('catalog.ratings') : $t('catalog.rating') }}</span>
              </span>

              <div class="learning-time">
                {{ formatDuration(catalogItem.duration) }}
              </div>

              <span class="total-items-in-track" v-if="catalogItem.formatType.toLocaleLowerCase() === 'track'">
                <svg-icon icon-class="list-ul-solid" />
                <span>{{ numberWithCommas(catalogItem.totalItemsInTrack) }} {{ catalogItem.totalItemsInTrack === 1 ? $t('catalog.item') : $t('catalog.items') }}</span>
              </span>

              <div class="learning-level">
                <span v-if="catalogItem.level === 'N/A'"></span>
                <span v-else> {{ getLocalizedLevel(catalogItem.level) }}</span>
              </div>

              <div v-if="catalogItem.contentFeature" style="margin: 0 0 0 2rem">
                {{ getContentFeature(catalogItem.contentFeature) }}
              </div>
            </div>
            <div class="catalog-dialog-actions-container">
              <div>
                <button v-if="catalogItem.formatType.toLocaleLowerCase() === 'track'" class="catalog-dialog-actions-link" @click="$emit('edit-content', 'create', catalogItem)">{{ $t('editDetails.headerTitle') }}</button>
                <button v-if="catalogItem.formatType.toLocaleLowerCase() === 'track'" class="catalog-dialog-actions-link" @click="$emit('edit-content', 'edit', catalogItem)">{{ $t('trackListing.railKebob.edit') }}</button>
                <button v-if="catalogItem.formatType.toLocaleLowerCase() === 'track' || enableManagePeopleForContent" class="catalog-dialog-actions-link" @click="$emit('edit-content', 'manage', catalogItem)">{{ $t('trackListing.railKebob.manage') }}</button>
              </div>
              <SocialShare size="lg" :catalogItem="catalogItem" />
              <div class="tooltip-wrapper" v-if="catalogItem.formatType.toLocaleLowerCase() === 'track'">
                <div :style="{ width: `${width}px`, height: `${height}px` }" class="delete" v-if="isDeleteEnabled">
                  <svg-icon icon-class="trash-bold" @click="deleteTrack(catalogItem.trackGuid)" @mouseover="showTooltip = true" @mouseleave="showTooltip = false"></svg-icon>
                </div>
                <div v-else class="tooltip-wrapper delete" @mouseover="showTooltip = true" @mouseleave="showTooltip = false">
                  <svg-icon icon-class="trash-bold " :style="{ 'pointer-events': 'none', opacity: isDeleteEnabled ? 1 : 0.5 }"></svg-icon>
                  <div class="tooltip-text-delete" v-if="showTooltip">This track cannot be deleted because it's a role-based track and could be required learning as well</div>
                </div>
              </div>
              <v-dialog v-model="deleteConfirmation" persistent width="400">
                <v-card class="t-rounded-20-xl t-p-8" style="width: 400px !important">
                  <div class="t-flex t-gap-4 t-flex-row-reverse close-btn">
                    <v-btn icon class="t-float-right t-shadow-none" @click="cancelDelete">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <p class="t-text-2xl t-font-bold t-tracking-normal t-text-black delete-title">Do you want to delete the track titled “{{ catalogItem.title }}”?</p>
                  </div>
                  <v-card-text class="t-mt-6 t-p-0 t-text-base t-tracking-normal delete-text">Please note that this action is irreversible, and any associated data will be permanently deleted. </v-card-text>
                  <v-card-actions class="t-primary-color t-mt-8 t-p-0 t-place-content-end">
                    <button class="btn-text t-mx-1 t-color-white t-py-2 min-w-min t-px-6 t-font-bold t-text-base" style="color: #1f3370" text @click="cancelDelete()">
                      {{ $t('button.cancel') }}
                    </button>
                    <button class="btn-text t-mx-1 t-color-white t-py-3 min-w-min t-px-6 t-rounded-full t-font-bold t-text-base t-bg-credential t-border-credential hover:t-bg-primary-color hover:t-text-white" text @click="confirmDelete()">Delete</button>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <p v-if="catalogItem.publishedAt" class="catalog-dialog-content-date">{{ localizedDate(moment, catalogItem.publishedAt) }}</p>
            <p v-html="$sanitize(catalogItem.synopsis)"></p>
          </div>
          <div class="catalog-dialog-content-column-right">
            <div v-if="catalogItem.accreditation.length" class="content-dialog-ccc-info-container">
              <p class="content-dialog-ccc-info-title">{{ $t('catalog.dialogAccreditation') }}</p>
              <p v-for="(acc, index) in catalogItem.accreditation" :key="index" class="content-dialog-ccc-info-content">
                <b>{{ acc.org }}</b> issues <b>{{ acc.creditHours }}</b> <b>{{ acc.creditHours === 1 ? 'hour' : 'hours' }}</b> / <b>{{ acc.creditType }}</b> Credit
              </p>
            </div>
            <div v-if="catalogItem.fieldOfStudy.length" class="content-dialog-ccc-info-container">
              <p class="content-dialog-ccc-info-title">{{ $t('catalog.dialogFieldOfStudy') }}</p>
              <p class="content-dialog-ccc-info-content">
                {{ catalogItem.fieldOfStudy }}
              </p>
            </div>
            <div v-if="catalogItem.subCategory.length" class="content-dialog-ccc-info-container">
              <p class="content-dialog-ccc-info-title">{{ $t('catalog.dialogCategoriesTopics') }}</p>
              <p class="content-dialog-ccc-info-content">
                {{ catalogItem.subCategory }}
              </p>
            </div>
            <div v-if="catalogItem.skills.length" class="content-dialog-ccc-info-container">
              <p class="content-dialog-ccc-info-title">{{ $t('catalog.dialogSkills') }}</p>
              <p class="content-dialog-ccc-info-content">
                {{ catalogItem.skills }}
              </p>
            </div>

            <div v-if="typeof catalogItem.vdiRequired === 'boolean'" class="content-dialog-ccc-info-container">
              <p class="content-dialog-ccc-info-title">{{ $t('catalog.VDIenabled') }}</p>
              <p class="content-dialog-ccc-info-content">
                {{ catalogItem.vdiRequired ? $t('common.titleCase.yes') : $t('common.titleCase.no') }}
              </p>
            </div>
          </div>
        </div>

        <div v-if="catalogItem.skillBuilder.length || typeof catalogItem.capstone === 'object'" class="catalog-hr"></div>

        <div v-for="(list, index) in lodash.chunk([...catalogItem.skillBuilder, ...catalogItem?.capstone], cardItemsPerRow)" :key="index" class="catalog-dialog-expanded-card-container">
          <div v-for="(ele, i) in list" :key="ele.title" class="catalog-dialog-expanded-card">
            <div v-if="!('scormcloudId' in ele)">
              <div class="catalog-dialog-expanded-card-heading">{{ $t('catalog.skillBuilder') }} {{ index * cardItemsPerRow + (i + 1) }}</div>
              <div class="catalog-dialog-expanded-card-title">{{ ele.title }}</div>
              <div class="catalog-dialog-expanded-card-synopsis">{{ ele.synopsis }}</div>

              <div class="catalog-dialog-expanded-card-metadata">
                <div v-if="ele.Duration">
                  <v-icon class="t-text-2xl t-mr-0.5 t-text-black">{{ 'mdi-clock-outline' }}</v-icon> <span class="t-text-xs">{{ $sanitize(formatDuration(ele.Duration)) }}</span>
                </div>

                <div v-if="ele.level">
                  <v-icon class="t-text-2xl t-text-black">{{ 'mdi-check-circle-outline' }}</v-icon> <span class="t-text-xs">{{ ele.level }}</span>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="catalog-dialog-expanded-card-heading">{{ $tc('common.titleCase.capstone') }}</div>
              <div class="catalog-dialog-expanded-card-title">{{ catalogItem.capstone.title }}</div>
              <div class="catalog-dialog-expanded-card-synopsis">{{ catalogItem.capstone.synopsis }}</div>

              <div class="catalog-dialog-expanded-card-metadata">
                <div v-if="catalogItem.capstone.Duration">
                  <v-icon class="t-text-2xl t-mr-0.5 t-text-black">{{ 'mdi-clock-outline' }}</v-icon> <span class="t-text-xs">{{ $sanitize(formatDuration(catalogItem.capstone.Duration)) }}</span>
                </div>

                <div v-if="catalogItem.capstone.level">
                  <v-icon class="t-text-2xl t-text-black">{{ 'mdi-check-circle-outline' }}</v-icon> <span class="t-text-xs">{{ catalogItem.capstone.level }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-for="ele in cardItemsPerRow" :key="ele.title" class="border-item"></div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import StarRating from 'vue3-star-ratings';
import { translate } from '@/plugins/i18n.js';
import { dataFormatBg, convertDuration, numberWithCommas, formatDuration, getLocalizedContentType, getLocalizedLevel, localizedDate, getLocalDateFormat } from '@/utils';
import SocialShare from './SocialShare.vue';

export default {
  name: 'CatalogDialog',
  components: { StarRating, SocialShare },
  emits: ['close-dialog', 'open-dialog', 'add-track-item', 'edit-content', 'toast'],
  data() {
    return {
      dataFormatBg,
      getLocalizedContentType,
      getLocalizedLevel,
      cardItemsPerRow: 4,
      deleteConfirmation: false,
      isDeleteEnabled: this.catalogItem.assignedAs === 'Recommended' && !('roleGuid' in this.catalogItem),
      trackGuid: '',
      showTooltip: false,
      width: 0,
      height: 0,
      enableManagePeopleForContent: false
    };
  },
  props: {
    catalogItem: {
      require: true,
      default: () => {
        return {};
      }
    },
    index: {
      default: () => {
        return 0;
      }
    },
    dialog: {
      default: () => {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters(['enabledFeatureFlags'])
  },
  mounted() {
    this.isDeleteEnabled = this.catalogItem?.assignedAs === 'Recommended' && !this.catalogItem?.roleGuid;
    this.enableManagePeopleForContent = this.enabledFeatureFlags && this.enabledFeatureFlags['plan_manage_people_enable_for_content'];
  },
  created() {
    switch (this.size) {
      case 'xlg':
        this.width = 45;
        this.height = 45;
        break;
      case 'lg':
        this.width = 40;
        this.height = 40;
        break;
      case 'md':
        this.width = 30;
        this.height = 30;
        break;
      case 'sm':
        this.width = 25;
        this.height = 25;
        break;
      default:
        this.width = 40;
        this.height = 40;
        break;
    }
  },
  methods: {
    formatDuration,
    convertDuration,
    getLocalDateFormat,
    localizedDate,
    numberWithCommas,
    deleteTrack(trackGuid) {
      this.trackGuid = trackGuid;
      this.deleteConfirmation = true;
    },
    cancelDelete() {
      this.deleteConfirmation = false;
    },
    async confirmDelete() {
      try {
        let response = await this.$planAdmin.deleteCreatedTrack(this.trackGuid);
        this.showResponseMessage(response);

        if (response) {
          this.deleteConfirmation = false;
          this.closeDialog();
        }
      } catch (error) {
        console.log(error);
      }
    },

    closeDialog() {
      this.$emit('close-dialog');
      document.documentElement.style.overflow = 'scroll';
      document.body.scroll = 'yes';
      this.$parent.$options.name === 'draggable' && document.getElementById('learning-track-container').classList.add('t-overflow-y-scroll');
    },

    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });

      this.$toasted.show(msg, options);
    },

    showResponseMessage(response) {
      this.$toasted.clear();
      if (response.code === 200) {
        this.showToast(translate('delDetails.successSaveDraftToastMessage'), 'success', 3000, false);
        window.location.reload();
      } else {
        this.showToast(translate('delDetails.failureSaveDraftToastMessage'), 'error', 3000, false);
      }
    },
    addItemToLearningTrack(index) {
      this.closeDialog();
      this.$emit('add-track-item', index);
    },

    getContentFeature(contentFeature) {
      if (contentFeature) return contentFeature.join(', ');
    }
  },
  metaInfo() {
    return {
      title: this.catalogItem?.title || null
    };
  }
};
</script>
<style lang="scss">
.tooltip {
  position: absolute;
  background-color: #fff;
  padding: 5px;
  border-radius: 3px;
  z-index: 9999;
}

.tooltip-wrapper {
  position: relative;
  display: inline-block;

  .tooltip-text-delete {
    visibility: visible;
    position: absolute;
    z-index: 1;
    top: 44px;
    left: 0;
    visibility: visible;
    height: 1.875rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.125rem 0.375rem #0000001f;
    border-radius: 0.25rem;
    opacity: 1;
    text-align: center;
    font: normal normal normal 0.75rem/1rem Arial;
    letter-spacing: 0rem;
    color: #000000de;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.375rem;
    min-width: 490px;
    font-size: 12px;

    font-family: 'Source Sans 3';
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
  }
  &:hover {
    .tooltip-text-delete {
      visibility: visible;
    }
  }
}
.v-content-dialog {
  max-height: 80% !important;
  margin: 0 auto;
  margin-bottom: 3.125rem;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0.625rem;
  width: 90% !important;
  max-width: 125rem;
  padding: 2rem !important;

  .catalog-dialog-close-modal-container {
    display: flex;
    justify-content: flex-end;
  }
  .catalog-dialog-container {
    padding: 0 6rem 3rem;

    .catalog-dialog-content-container {
      display: grid;
      grid-template-columns: 10fr 2fr;

      .catalog-dialog-content-column-left {
        width: 85%;
      }
      .catalog-dialog-content-column-right {
        .content-dialog-ccc-info-container {
          margin-bottom: 1rem;
          .content-dialog-ccc-info-title {
            margin-bottom: 0.25rem;
            text-align: left;
            font: normal normal bold 1rem/1.25rem Arial;
            letter-spacing: 0rem;
            color: #000000de;
            opacity: 1;
          }
          .content-dialog-ccc-info-content {
            text-align: left;
            font: normal normal normal 1rem/1.5rem Arial;
            letter-spacing: 0rem;
            color: #000000;
            opacity: 1;
          }
        }
      }

      .ratings-total-container {
        display: flex;
        align-items: center;
        text-align: left;
        font: normal normal normal 0.875rem/1rem Arial;
        letter-spacing: 0.0437rem;
        color: #2d2d2d;
        opacity: 1;
        flex-wrap: wrap;
        .retier {
          @include body-font-bold-0-875;
          color: #2d2d2d;
          display: flex;
          padding: 0px 8px;
          align-items: center;
          gap: 4px;
          border-radius: 20px;
          border: 2px solid var(--Text-Body-DarkGrey, #2d2d2d);
          margin: 0 2rem 0 0;
          position: relative;
          .retier-tooltip-text {
            white-space: nowrap;
            visibility: hidden;
            text-align: center;
            padding: 8px 16px;
            position: absolute;
            z-index: 1;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1215686275);
            border-radius: 4px;
            opacity: 1;
            @include body-font-0-75;
            top: -45px;
            left: 0;
            color: rgba(0, 0, 0, 0.8705882353);
          }
        }
        .retier {
          background: none;
        }
        .retier:hover .retier-tooltip-text {
          visibility: visible;
        }
        .ratings-total-content {
          display: flex;
          align-items: center;
        }
        .ratings-count,
        .ratings-total {
          text-align: left;
          font: normal normal normal 0.75rem/0.875rem Arial;
          letter-spacing: 0rem;
          color: #000000;
          text-transform: lowercase;
          opacity: 1;
        }
        .ratings-count {
          margin-left: 0.5rem;
        }

        .ratings-total {
          margin-right: 0.5rem;
        }

        .learning-time {
          margin: 0rem 2rem;
        }
        .total-items-in-track {
          margin: 0 2rem 0 0;
          .svg-icon {
            margin-right: 0.5rem;
          }
        }
      }
      .catalog-dialog-actions-container {
        width: 100%;
        display: flex;
        align-items: center;
        margin: 2rem 0rem;

        .catalog-dialog-actions-link {
          margin-right: 2rem;
          text-align: left;
          text-decoration: underline;
          font: normal normal normal 1rem/1.375rem 'Futura PT Demi';
          letter-spacing: 0.0013rem;
          color: #1f3370;
          opacity: 1;
          cursor: pointer;
        }
      }
      .catalog-dialog-content-date {
        text-align: left;
        font: normal normal bold 1rem/1.25rem Arial;
        letter-spacing: 0rem;
        color: #000000de;
        opacity: 1;
        margin-bottom: 0.25rem;
      }
    }
  }
  .catalog-dialog-close-button {
    .svg-icon {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
  .catalog-dialog-provider {
    text-align: left;
    font: normal normal bold 0.75rem/0.875rem Arial;
    letter-spacing: 0.075rem;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
  }

  .catalog-dialog-title {
    text-align: left;
    font: normal normal normal 1.75rem/2.25rem 'Futura PT Demi' !important;
    letter-spacing: 0rem;
    color: #000000;
    opacity: 1;
  }

  .catalog-hr {
    width: 100%;
    border-bottom: 2px solid #dde0e8;
    margin: 3rem 0rem;
  }

  .catalog-dialog-expanded-card-container {
    display: grid;
    grid-template-columns: repeat(4, 3fr);

    &:last-of-type {
      margin-bottom: 4rem;

      .border-item {
        display: none;
      }
    }

    .border-item {
      margin: 2rem 0rem;
      border-bottom: 2px solid #dde0e8;
      opacity: 1;
      span {
        margin: 2rem 0rem;
        border-bottom: 2px solid #dde0e8;
        opacity: 1;
      }
    }

    .catalog-dialog-expanded-card {
      padding: 0rem 2rem;
      position: relative;
      background: #ffffff 0% 0% no-repeat padding-box;
      min-height: 23.75rem;

      &:nth-child(4n + 1) {
        padding-left: 0px;
        border-right: 2px solid #dde0e8;
      }

      &:nth-child(4n - 2) {
        border-right: 2px solid #dde0e8;
      }

      &:nth-child(4n - 1) {
        border-right: 2px solid #dde0e8;
      }

      &:nth-child(4n) {
        padding-right: 0px;
      }

      .catalog-dialog-expanded-card-heading {
        text-align: left;
        font: normal normal bold 1.25rem/1.25rem Arial;
        letter-spacing: 0rem;
        color: #000000;
        opacity: 1;
        margin-bottom: 2rem;
      }

      .catalog-dialog-expanded-card-title {
        text-align: left;
        font: normal normal bold 1rem/1.25rem Arial;
        letter-spacing: 0rem;
        color: #000000;
        opacity: 1;
        margin-bottom: 1rem;
      }

      .catalog-dialog-expanded-card-synopsis {
        text-align: left;
        font: normal normal normal 1rem/1.5rem Arial;
        letter-spacing: 0rem;
        color: #5c5c5c;
        opacity: 1;
        margin-bottom: 2rem;
      }

      .catalog-dialog-expanded-card-metadata {
        display: flex;
        gap: 3rem;
        position: absolute;
        bottom: 0rem;
      }
    }
  }
}

.create-track {
  .v-btn__content {
    font-family: 'Futura PT Demi';
  }
}
.create-track:focus-visible {
  outline: black 2px solid;
  outline-offset: 1px;
}
.action-btn {
  box-shadow: none !important;
}
.track-btn-actions {
  margin-left: 14rem;
}
.create-track {
  background: '#FFB92';
}

.delete {
  align-items: center;
  background: #fff;
  border: 0.0625rem solid #efefef;
  border-radius: 50%;
  display: flex;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
  margin-left: 10px;
}

.delete:hover {
  background: #d6d6d6;
}
.close-btn {
  .v-btn--icon.v-btn--density-default {
    height: 24px;
    width: 24px;
  }
}
.delete-title {
  overflow: hidden;
  color: var(--pe-primary-secondary-colors-black, var(--Colors-Primary-Black, #000));
  font-family: 'Source Serif 4 Subhead bold';
}
.delete-text {
  color: var(--Text-Body-DarkGrey, #2d2d2d);
  font-family: 'Source Sans 3' !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.btn-text {
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
}

.v-card-actions {
  justify-content: end;
}
.v-dialog > .v-overlay__content > .v-card {
  padding: 0px;
  width: calc(100% - 48px) !important;
}
</style>
