<template>
  <div class="app-container">
    <Banner company="" :left-btn="bannerLeftBtn" :right-btn="bannerRightBtn" :left-btn-is-cancel="false" />
    <v-card class="error-card" v-if="notification.available && notification.type == 'error'">
      <v-card-title>{{ $t('orgDetails.error') }}</v-card-title>
      <v-card-text class="error-msg">{{ notification.message }}.</v-card-text>
      <v-card-text class="error-msg">{{ $t('orgDetails.tryAgain') }}</v-card-text>
    </v-card>
    <v-card class="summary-card" v-else>
      <div class="header">{{ $t('orgDetails.defineOrgStruct') }}</div>
      <div v-if="notification.available && notification.type != 'error'" class="warning">{{ notification.message }}</div>
      <div v-if="!dataReady" class="loader">
        <v-card-text class="loader-text">{{ $t('common.titleCase.loading') }}</v-card-text>
        <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
      </div>
      <div v-if="displayBanner" class="success">{{ $t('orgDetails.uploadSuccessful') }}</div>
      <!-- 
      <TreeView v-if="dataReady && !notification.available" activatable open-on-click :items="structure" :load-children="onFetchChildren" :key="structure.length" class="structure">
        <template v-slot:label="{ item }">
          <div v-if="item.hasOwnProperty('lead')" @mouseover="onMouseover(item)">
            <span :id="`treeview-node-label-${item.id}`" class="dept-name">{{ item.name }}</span>
          </div>
          <div v-else>
            <span class="employee-name">{{ item.firstName }} {{ item.lastName }}</span>
            <img class="avatar-img" src="@/assets/images/users/no-avatar.png" />
          </div>
        </template>
      </TreeView>
      -->
      <BasicTreeView v-if="dataReady && !notification.available" class="structure" :items="structure" :row="0" :mode="1" @load-children="onFetchChildren" :key="structure.length" :parent="'OrgDetails'" />
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import localforage from 'localforage';
import Banner from '@/components/molecules/Banner';
// import TreeView from '@/components/molecules/TreeView';
import BasicTreeView from '@/components/molecules/BasicTreeView';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'OrgDetails',
  components: { Banner, BasicTreeView },
  data() {
    return {
      notification: { available: false, type: '', message: '' },
      organization: '',
      logoImg: '',
      token: '',
      structure: [],
      openStructure: [],
      bannerLeftBtn: {},
      bannerRightBtn: {},
      dataReady: false,
      displayBanner: false,
      uploadDone: false
    };
  },
  computed: {
    ...mapGetters(['company', 'logo', 'orgUploaded', 'enabledFeatureFlags'])
  },
  async mounted() {
    this.uploadDone = this.$route.query?.uploadDone;
    this.organization = (await localforage.getItem('my_company')) || this.company;
    this.logoImg = (await localforage.getItem('my_logo')) || this.logo;
    this.structure = await this.$planAdmin.getRootOrganizationStructure();
    if (this.structure.code == 404) {
      this.structure = [];
      this.bannerLeftBtn = { text: translate('orgDetails.updatePeople'), action: this.onUpdatePeople };
      this.bannerRightBtn = { text: translate('orgDetails.newUpload'), action: this.onNewUpload };
      this.notification.type = 'error';
      this.notification.message = translate('orgDetails.noDefaultOrg');
      this.notification.available = true;
    } else {
      this.updateStatus();
    }
    this.dataReady = true;
  },
  methods: {
    ...mapActions('plan', ['setOrgUploaded', 'updateWorkForcePeople']),
    onBack() {
      this.$router.go(-1);
    },
    async onConfirm() {
      await this.updateWorkForcePeople(true);
      this.displayBanner = false;
      this.uploadDone = false;
      this.$router.push('/workforce-planner');
    },
    async onFetchChildren(item) {
      const structure = await this.$planAdmin.getChildrenOrganizationStructure({ orgId: item.id });
      item.children = structure.children;
      item.loaded = true;
    },
    onUpdatePeople() {
      this.$router.push({ name: 'PlanImportOrg', query: { uploadType: 'update-people' } });
    },
    onNewUpload() {
      this.$router.push({ name: 'PlanImportOrg', query: { uploadType: 'new-upload' } });
    },
    onMouseover(item) {
      this.openStructure.unshift(item.id);
    },
    onClick(item) {
      if (!this.openStructure.includes(item.id)) {
        this.openStructure.unshift(item.id);
      } else {
        this.openStructure.pop(item.id);
      }
    },
    onOpen(items) {
      items.forEach((item) => {
        this.openStructure.unshift(item.id);
      });
    },
    async updateStatus() {
      const uploadOrgStatus = await this.$planAdmin.getUploadOrganizationStatus();
      const status = uploadOrgStatus.status.toLowerCase();
      this.bannerLeftBtn = status.includes('running') ? { text: translate('orgDetails.back'), action: this.onBack } : status.includes('success') && this.uploadDone ? { text: '', action: null } : { text: translate('orgDetails.updatePeople'), action: this.onUpdatePeople };
      this.bannerRightBtn = status.includes('success') && this.uploadDone ? { text: translate('button.confirm'), action: this.onConfirm } : status.includes('running') ? { text: '', action: null } : { text: translate('orgDetails.newUpload'), action: this.onNewUpload };
      this.notification.type = status.includes('running') ? 'warning' : status.includes('error') ? 'error' : '';
      this.notification.message = status.includes('running') ? translate('orgDetails.uploadingCSVInProgress') : status.includes('error') ? uploadOrgStatus.message : '';
      this.notification.available = status.includes('running') || status.includes('error');
      this.displayBanner = status.includes('success') && !((await localforage.getItem('my_org_uploaded')) || this.orgUploaded);
      await this.setOrgUploaded(status.includes('success'));
      if (status.toLowerCase().includes('running')) {
        setTimeout(() => {
          this.updateStatus();
        }, 10000);
      }
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.orgDetails')
    };
  }
};
</script>

<style scoped lang="scss">
.app-container {
  margin-top: 20px;
  background: $color-grey-lighter;
  min-height: 100vh;
}

.header {
  margin-bottom: 30px;
  font-size: 25px;
  color: $color-primary;
  font-weight: bold;
  text-align: center;
}

.structure {
  padding: 0% 2%;
}

.office-name {
  font-size: 20px;
}

.banner {
  background: none !important;
  height: 5rem;
  padding: 1.25rem;
  margin: 1.125rem 1.875rem -1.25rem 1.875rem;
  border-bottom-left-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem;
  justify-content: center;

  .left-item {
    font-size: 20px;
    color: $color-grey-lighter;
    float: left;
  }

  .right-item {
    float: right;
  }
}

button {
  border: 1px solid $button-primary-color-bg;
  border-radius: 20px;
  background: $button-primary-color-bg;
  color: $button-primary-color-text;
  padding: 0px 10px;
  width: 160px;
  margin-right: 10px;
  height: 35px;

  &:hover {
    background: $button-primary-color-bg-hover;
    color: $button-primary-color-text-hover;
  }

  &.button-cancel {
    border: 1px solid $color-grey-light;
    background: $color-grey-light;
    color: $color-grey-dark;

    &:hover {
      background: $color-grey-lighter;
      color: $color-grey;
    }
  }
}

.summary-card {
  margin: 20px 30px;
  padding: 20px 30px 40px 30px;
  border-radius: 20px;

  .dept-name {
    color: $color-primary;
    font-size: 25px;
    cursor: pointer;
  }

  .lead-name {
    color: $color-grey;
    font-size: 20px;
  }

  .employee-name {
    font-size: 25px;

    &:hover {
      color: $color-data-2;
      font-size: 30px;
    }
  }

  .avatar-img {
    float: left;
    border: 1px solid;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border-radius: 100%;
  }
}
.error-card {
  margin: 20px 15vw 15vw;
  padding: 20px 30px 40px 30px;
  border-radius: 20px;
}

.error-msg {
  font-size: 18px;
  color: black !important;
  font-weight: bold;
  margin-bottom: 16px;
}

.warning {
  font-size: 20px;
  text-align: center;
  border-radius: 8px !important;
  color: $color-white;
  padding: 10px;
  margin: 10px 0;
  background-color: #fb8c00 !important;
  border-color: #fb8c00 !important;
}

.success {
  font-size: 20px;
  text-align: center;
  border-radius: 8px;
  color: $color-white;
  padding: 10px;
  margin: 10px 0;
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}

.loader {
  margin: 100px 100px;
  text-align: center;

  .loader-text {
    font-size: 30px;
    color: $color-primary;
  }

  .loader-bar {
    position: relative;
    top: -30px;
  }
}
.re {
  float: right;
}
</style>
