<template>
  <v-list v-if="activities" class="activity-comment">
    <v-list-item>
      <v-list-item-subtitle v-if="['rejected', 'approved', 'in edit'].includes(reviewStatus)" class="t-whitespace-pre-line t-break-all t-mb-5 t-relative"
        ><svg-icon icon-class="icon-info" class="t-text-xl t-mr-1 t-absolute t-top-0 t-left-0"></svg-icon>
        <p v-if="reviewStatus == 'in edit'" class="t-font-arial t-text-sm support-text t-pl-6">
          {{ $t('activitiesAndComments.reviewStatusInfo1') }} <a target="_blank" :href="`mailto:support@proedge.pwc.com?subject=Proedge%20Support`" ref="mylink">{{ $t('activitiesAndComments.reviewStatusInfo2') }}</a> {{ $t('activitiesAndComments.reviewStatusInfo3') }}
        </p>
        <p v-else class="t-font-arial text-sm support-text t-pl-6">
          {{ $t('activitiesAndComments.reviewStatusInfo4') }} <a target="_blank" :href="`mailto:support@proedge.pwc.com?subject=Proedge%20Support`" ref="mylink">{{ $t('activitiesAndComments.reviewStatusInfo2') }}</a> {{ $t('activitiesAndComments.reviewStatusInfo3') }}
        </p>
      </v-list-item-subtitle>
      <v-list-item-title>
        <h2 class="v-list-item__title t-mb-5 t-text-2xl t-font-futura-pt-demi t-font-normal">{{ $t('activitiesAndComments.commentsAndActivity') }}</h2>
      </v-list-item-title>
      <v-list-item class="t-p-0" v-for="(activity, index) in activities" :key="index">
        <template v-slot:prepend>
          <v-avatar color="grey-lighten-3">
            <span class="t-text-xs short-name-container"> {{ activity.shortName }} </span>
          </v-avatar>
        </template>

        <v-list-item v-if="activity.nameWithAction">
          <v-list-item-title class="t-whitespace-pre-line"
            ><div class="t-font-bold t-text-sm">{{ activity.nameWithAction }}</div>
            <div v-if="activity.createdAt" class="t-text-xs">{{ formatLatestDate(activity.createdAt) }}</div>
          </v-list-item-title>
          <v-list-item-subtitle class="t-whitespace-pre-line t-break-all" v-if="activity.comment">{{ activity.comment }}</v-list-item-subtitle>
        </v-list-item>

        <v-list-item v-if="activity.userNameWithAction">
          <v-list-item-title class="t-whitespace-pre-line"
            ><div class="t-font-bold t-text-sm">{{ activity.userNameWithAction }}</div>
            <div v-if="activity.createdAt" class="t-text-xs">{{ formatLatestDate(activity.createdAt) }}</div>
          </v-list-item-title>
          <v-list-item-subtitle class="t-whitespace-pre-line t-break-all" v-if="activity.comment">{{ activity.comment }}</v-list-item-subtitle>
        </v-list-item>
      </v-list-item>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'ActivitiesAndComments',
  props: {
    activities: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    reviewStatus: {
      type: String,
      required: true,
      default() {
        return '';
      }
    }
  },
  methods: {
    formatLatestDate(createdAt) {
      const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
      const timeOption = { hour: 'numeric', minute: 'numeric' };
      const date = new Date(createdAt);
      var time = date.toLocaleTimeString('en-US', timeOption);
      const duration = new Date() - date;
      const days = Math.ceil(duration / (1000 * 60 * 60 * 24));
      if (days == 1) return `Today at ${time}`;
      else if (days == 2) return `Yesterday at ${time}`;
      else if (days > 2 && days < 7) return `${days} days ago at ${time}`;
      else return date.toLocaleDateString('en-US', options);
    }
  }
};
</script>
<style lang="scss">
.activity-comment {
  .v-list-item--one-line .v-list-item-subtitle {
    -webkit-line-clamp: 2;
  }
}
</style>

<style lang="scss" scoped>
.short-name-container {
  background: #efefef;
}
.support-text {
  word-break: break-word;
}
</style>
