import axios from 'axios';
import { ShareKpiContainer } from '../model/share-kpi';
import { getMetaData } from '../utils/helper';
export default {
  async getAssetKpi(url) {
    const { authJsonHeader } = await getMetaData();
    const response = await axios.get(url, { headers: authJsonHeader }).catch((error) => {
      return { data: error.response.data };
    });
    const resultSet = new ShareKpiContainer(response.data);
    return resultSet;
  },
  async basicApplyGetRequest(url) {
    const { authJsonHeader } = await getMetaData();
    const response = await axios.get(url, { headers: authJsonHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response.data;
  },
  async onReviewSubmit(url, payload) {
    const { authJsonHeader } = await getMetaData();

    const payloadn = { action: payload.action, comment: payload.comment };
    const response = await axios.post(url, payloadn, { headers: authJsonHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return response;
  },
  async downloadHistoryLog(url) {
    const { authJsonHeader } = await getMetaData();

    const response = await axios({ url: url, method: 'GET', responseType: 'blob', headers: authJsonHeader }).catch((error) => {
      return { data: error.response.data };
    });
    return new Blob([response.data]);
  }
};
