import localforage from 'localforage';
import store from '@/store';

const state = {
  location: 'All Locations',
  isCreated: false,
  version: '',
  orgUploaded: true,
  csvUploaded: true,
  clearSearchResult: false,
  selectedProfile: false,
  resetProfile: false,
  searchedRole: '',
  selectedFunc: null,
  selectedDept: null,
  resetSelectedFunc: false,
  resetSelectedDept: false,
  workForcePeopleUpdate: false,
  uploadSuccessful: false,
  allFunctions: [],
  allDepartments: [],
  allRoles: [],
  selectedRole: ''
};

const mutations = {
  SET_LOCATION: (state, location) => {
    state.location = location;
  },
  SET_IS_CREATED: (state, isCreated) => {
    state.isCreated = isCreated;
  },
  SET_VERSION: (state, version) => {
    state.version = version;
  },
  SET_ORG_UPLOADED: (state, orgUploaded) => {
    state.orgUploaded = orgUploaded;
  },
  SET_CSV_UPLOADED: (state, csvUploaded) => {
    state.csvUploaded = csvUploaded;
  },
  UPLOAD_SUCCESSFUL: (state, uploadStatus) => {
    state.uploadSuccessful = uploadStatus;
  },
  SET_SEARCHED_ROLE: (state, searchedRole) => {
    state.searchedRole = searchedRole;
  },
  SET_SELECTED_FUNCTION: (state, selectedFunc) => {
    state.selectedFunc = selectedFunc;
  },
  SET_SELECTED_DEPARTMENT: (state, selectedDept) => {
    state.selectedDept = selectedDept;
  },
  RESET_SELECTED_FUNCTION: (state, selectedFunc) => {
    state.resetSelectedFunc = selectedFunc;
  },
  RESET_SELECTED_DEPARTMENT: (state, selectedDept) => {
    state.resetSelectedDept = selectedDept;
  },
  SET_ALL_DEPARTMENTS: (state, depts) => {
    state.allDepartments = depts;
  },
  SET_ALL_FUNCTIONS: (state, funcs) => {
    state.allFunctions = funcs;
  },
  SET_ALL_ROLES: (state, roles) => {
    state.allRoles = roles;
  },
  SET_CURRENT_ROLE: (state, cRole) => {
    state.selectedRole = cRole;
  },
  CLEAR_SEARCH_RESULT: (state, clearResult) => {
    state.clearSearchResult = clearResult;
  },
  SELECTED_PROFILE: (state, selectedProfile) => {
    state.selectedProfile = selectedProfile;
  },
  RESET_SELECTED_PROFILE: (state, resetProfile) => {
    state.resetProfile = resetProfile;
  },
  UPDATE_WORKFORCE_PEOPLE: (state, updateWorkForcePeople) => {
    state.workForcePeopleUpdate = updateWorkForcePeople;
  }
};

const actions = {
  setPlanLocation({ commit }, location) {
    commit('SET_LOCATION', location);
  },
  async setPlanCreated({ commit }, isCreated) {
    commit('SET_IS_CREATED', isCreated);
    await localforage.setItem('my_plan_created', isCreated).then(() => {});
  },
  async setPlanVersion({ commit }, version) {
    commit('SET_VERSION', version);
    await localforage.setItem('my_plan_version', version).then(() => {});
  },
  async setOrgUploaded({ commit }, orgUploaded) {
    commit('SET_ORG_UPLOADED', orgUploaded);
    await localforage.setItem('my_org_uploaded ', orgUploaded).then(() => {});
  },
  async setCsvUploaded({ commit }, csvUploaded) {
    commit('SET_CSV_UPLOADED', csvUploaded);
    await localforage.setItem('my_csv_uploaded ', csvUploaded).then(() => {});
  },
  async setSearchedRole({ commit }, searchedRole) {
    commit('SET_SEARCHED_ROLE', searchedRole);
  },
  async setSelectedFunc({ commit }, selectedFunc) {
    commit('SET_SELECTED_FUNCTION', selectedFunc);
  },
  async setSelectedDept({ commit }, selectedDept) {
    commit('SET_SELECTED_DEPARTMENT', selectedDept);
  },
  async resetPlanState({ commit }) {
    store.dispatch('plan/setPlanLocation', '');
    store.dispatch('plan/setPlanCreated', false);
    // store.dispatch('plan/setPlanVersion', '')
    store.dispatch('plan/setOrgUploaded', true);
    store.dispatch('plan/setCsvUploaded', true);
    store.dispatch('plan/setSearchedRole', '');
    store.dispatch('plan/setSelectedFunc', null);
    store.dispatch('plan/setSelectedDept', null);
    commit('SET_ALL_DEPARTMENTS', null);
    commit('SET_ALL_FUNCTIONS', null);
    commit('SET_ALL_ROLES', null);
    commit('SET_CURRENT_ROLE', '');
  },
  async setClearSearchResult({ commit }, clearSearchResult) {
    commit('CLEAR_SEARCH_RESULT', clearSearchResult);
  },
  async setSelectedProfile({ commit }, selectedProfile) {
    commit('SELECTED_PROFILE', selectedProfile);
  },
  async resetSelectedProfile({ commit }, resetSelectedProfile) {
    commit('RESET_SELECTED_PROFILE', resetSelectedProfile);
  },
  async updateWorkForcePeople({ commit }, updateWorkForcePeople) {
    commit('UPDATE_WORKFORCE_PEOPLE', updateWorkForcePeople);
  },
  async setUploadStatus({ commit }, uploadStatus) {
    commit('UPLOAD_SUCCESSFUL', uploadStatus);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
