<template>
  <div class="consumption-dashboard-container-c1">
    <div class="content-drilldown-header-container" :style="displaySelectedFilters.length && 'padding-bottom: 0rem;'" v-show="structureReady">
      <h2>{{ $t('insights.content.detailsHeadline') }}</h2>

      <ContentCSVDownload
        :orgNameInFocus="orgNameInFocus"
        :skillOrgInFocus="orgInFocus ? orgInFocus.id : structure.length ? structure[0].id : 0"
        :orgInFocus="orgInFocus ? orgInFocus.id : 0"
        :timeFilter="selectedTimeOptionString"
        :drillDownTableFilters="displaySelectedFilters"
        :searchInputText="''"
        :isC1="true"
        @downloadParentCSV="downloadCSVPerORG"
      />
    </div>
    <!-- remove job Title as a filter- keeping the code in case we need it in the future -->
    <div class="dashboard-details-table-header-actions-c1" :style="displaySelectedFilters.length && 'padding-bottom: 0rem;'" v-show="structureReady">
      <div class="content-drilldown-header-container-c1" :style="structureReady"></div>
      <div class="consumption-details-table-filters">
        <InsightsDrillDowntableFilters @filter-applied="applyFilters" :filterResults="filterResults" @interface="getChildInterface" />
      </div>
    </div>

    <div class="dashboard-details-search-chips" :style="displaySelectedFilters.length && 'margin-top: -1.5625rem'" v-show="displaySelectedFilters.length && structureReady">
      <div>
        <span v-for="(item, index) in displaySelectedFilters" :key="index" id="chips-container">
          <v-chip in class="chip t-text-xs ma-2 t-bg-black" closable @click:close="onRemoveFilter(item)"> {{ item.name }} </v-chip>
        </span>
        <span class="clear-all-filters" @click="onClearFilters">{{ $t('common.clearAllFilters') }}</span>
      </div>
    </div>

    <div class="dashboard-details-table-container" v-show="structureReady">
      <div class="consumption-details-treeview-container">
        <div class="tree-view-header">{{ $t('orgStructure.orgChart') }}</div>
        <!-- 
        <TreeView v-if="treeViewReady" class="dashboard-details-treeview" :items="structure" activatable>
          <template v-slot:label="{ item, active }">
            <div v-if="item.hasOwnProperty('lead')" @click="active ? $event.stopPropagation() : onGetPlan(item)" class="t-flex t-justify-between t-items-center">
              <span :id="`treeview-node-label-${item.id}`" class="dept-name">{{ item.name }}</span>
            </div>
          </template>
        </TreeView> 
        -->
        <DataTableTreeView v-if="treeViewReady" class="dashboard-details-treeview" :items="structure" :row="0" @on-get-plan="onGetPlan" :withDownload="true" @download-csv="downloadCSVPerORG" />

        <div v-else>
          <div class="loader t-flex t-justify-center t-items-center">
            <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Tree View" />
          </div>
        </div>
      </div>

      <v-data-table class="consumption-details-table-c1" :headers="headers" :header-props="{ sortIcon: null }" :items="consumptionSummary.counts" :items-per-page-options="pageSizeOption" :fixed-header="true" :key="$i18n.locale" hide-default-footer>
        <template v-slot:[`no-data`]>
          <span class="no-data-available">{{ $t('common.noData10Learners') }}</span>
        </template>

        <template v-slot:[`header.learningPlan`]="{ column }">
          <div class="v-data-table-header__content">
            <span style="margin-right: 0.375rem">{{ column.title }}</span>
            <!-- <span class="progress-tooltip-wrapper">
              <ToolTip :content="$t('insights.progressToolTip')" width="16.3125rem" />
            </span> -->
            <svg-icon icon-class="info-icon" class="info-icon" />
            <span class="info-tooltip">{{ $t('insights.learningPlanToolTip') }}</span>
          </div>
        </template>

        <template v-slot:[`header.progress`]="{ column }">
          <div class="v-data-table-header__content">
            <span style="margin-right: 0.375rem">{{ column.title }}</span>
            <span class="progress-tooltip-wrapper">
              <ToolTip :content="$t('insights.progressToolTip')" width="16.3125rem" />
            </span>
          </div>
        </template>

        <template v-slot:[`body`] v-if="!tableDataReady">
          <div class="dashboard-details-table-loader">
            <v-card-text class="loader-text">{{ $t('common.titleCase.loading') }}</v-card-text>
            <v-text-field class="loader-bar" color="primary" loading disabled></v-text-field>
          </div>
        </template>
      </v-data-table>
    </div>

    <div v-show="!structureReady">
      <div class="loader drilldown-loader t-flex t-justify-center t-items-center">
        <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Drilldown Table" />
      </div>
    </div>

    <json2csv :data="consumptionCSVPerOrg" :labels="labelsForCSV" :title="jsonTitle" v-if="consumptionCSVPerOrg && consumptionCSVPerOrg.length">
      <button ref="csvDownloadBtnC1"></button>
    </json2csv>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import ContentCSVDownload from '../components/ContentCSVDownload.vue';
import JSON2CSV from '@/components/molecules/JSON2CSV';
import DataTableTreeView from '@/components/molecules/DataTableTreeView';
import { getDateForCSV, generateCSVTimeFilter, numberWithCommas, convertHourMinuteStringtoStringWithCommas, getElementWidth } from '@/utils';
import ToolTip from '../../../components/molecules/ToolTip.vue';
import InsightsDrillDowntableFilters from './InsightsDrillDowntableFilters.vue';
import { translate } from '@/plugins/i18n.js';

const initialState = () => ({
  jsonTitle: '',
  isC1: false,
  selectedTimeOptionString: 'YEAR',
  displaySelectedFilters: [],
  structureReady: false,
  structure: [],
  tableDataReady: false,
  treeViewReady: true,
  orgInFocus: null,
  orgNameInFocus: 'All Orgs',
  filterResults: {},
  headers: [],
  employees: [],
  titles: [],
  statusValues: [],
  consumptionSummary: {},
  consumptionCSVPerOrg: [],
  componentIsActive: false,
  pageSizeOption: [
    { value: 10, title: '10' },
    { value: 25, title: '25' },
    { value: 50, title: '50' },
    { value: 100, title: '100' }
  ],
  orgData: {
    id: '',
    name: ''
  }
});

export default {
  name: 'ConsumptionDrillDownTableC1',
  props: {
    timeFilter: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    updateContentProps: { type: Function, required: true }
  },
  components: { json2csv: JSON2CSV, ContentCSVDownload, ToolTip, InsightsDrillDowntableFilters, DataTableTreeView },
  data() {
    return initialState();
  },
  computed: {
    ...mapGetters(['enabledFeatureFlags'])
  },
  deactivated() {
    this.componentIsActive = false;
  },
  async activated() {
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
    Object.assign(this.$data, initialState());
    this.$data.labelsForCSV = {
      labelsForCSV: {
        totalLearners: { title: translate('insights.content.drilldownHeaders.email') },
        totalTitles: { title: translate('insights.content.drilldownHeaders.title') },
        totalRoles: { title: translate('insights.content.drilldownHeaders.role') },
        totalCredentialInProgress: { title: translate('insights.content.drilldownHeaders.credentialsInProgress') },
        totalCredentialCompleted: { title: translate('insights.content.drilldownHeaders.credentialsCompleted') },
        totalCourseInProgress: { title: translate('insights.content.drilldownHeaders.coursesInProgress') },
        totalCourseCompleted: { title: translate('insights.content.drilldownHeaders.coursesCompleted') },
        totalAudioInProgress: { title: translate('insights.content.drilldownHeaders.audiosInProgress') },
        totalAudioCompleted: { title: translate('insights.content.drilldownHeaders.audiosCompleted') },
        totalVideoInProgress: { title: translate('insights.content.drilldownHeaders.videosInProgress') },
        totalVideoCompleted: { title: translate('insights.content.drilldownHeaders.credentialsCompleted') },
        totalArticleInProgress: { title: translate('insights.content.drilldownHeaders.articlesInProgress') },
        totalArticleCompleted: { title: translate('insights.content.drilldownHeaders.articlesCompleted') },
        totalTrackItems: { title: translate('insights.content.drilldownHeaders.trackItems') },
        totalTrackItemsCompleted: { title: translate('insights.content.drilldownHeaders.trackItemsCompleted') },
        totalCompletedTimeSpent: { title: translate('insights.content.drilldownHeaders.overallCompletedTimeSpent') },
        totalInProgressTimeSpent: { title: translate('insights.content.drilldownHeaders.inProgressTimeSpent') },
        totalLearning: { title: translate('insights.content.drilldownHeaders.totalLearning') },
        progress: { title: translate('insights.content.drilldownHeaders.totalProgress') }
      }
    };
    this.fetchInitialData();
    this.componentIsActive = true;
  },
  watch: {
    structureReady() {
      this.$emit('setIsStructureReady', this.structureReady);
    },
    '$i18n.locale'() {
      if (this.componentIsActive) {
        this.setHeaders();
        this.fetchInitialData();
      }
    },
    tableDataReady: function (val) {
      this.$nextTick(async () => {
        const container = document.getElementsByClassName('consumption-details-table-c1')[0];

        if (container) {
          const wrapper = document.getElementsByClassName('v-table__wrapper')[0];
          wrapper.scrollLeft && (wrapper.scrollLeft = 0);
          const thead = container.getElementsByTagName('thead')[0];
          const tbody = container.getElementsByTagName('tbody')[0];

          if (val) {
            thead.style.display = 'table-header-group';
            tbody.style.overflow = 'auto';
          } else {
            thead.style.display = 'inline-table';
            tbody.style.overflow = 'hidden';
            tbody.style.width = `${container.clientWidth / 16}rem`;
          }
        }
      });
    },
    timeFilter: function () {
      this.consumptionSummary = {};
      this.selectedTimeOptionString = this.timeFilter;
      this.fetchInitialData();
    },
    orgInFocus: function () {
      this.updateContentProps('orgInFocus', this.orgInFocus?.id || null);
      this.orgNameInFocus = this.orgInFocus?.name;
    }
  },
  childInterface: {
    onClearFilters: () => {},
    onRemoveFilter: () => {}
  },
  methods: {
    getElementWidth,
    getDateForCSV,
    generateCSVTimeFilter,
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    async fetchInitialData() {
      this.structureReady = false;
      this.tableDataReady = false;
      await this.getTreeData();

      if ({}.hasOwnProperty.call(this.structure, 'code') && this.structure.code == 401) {
        this.$stopSessionTimer();
      }

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus);
      } else {
        await this.getAllEmployees();
      }
      await this.setup();
      this.structureReady = true;
      this.tableDataReady = true;
    },
    async getAllEmployees(filtersRequired = true, titles) {
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      const payload = {
        pageNo: 1,
        pageSize: 25,
        titles: titles || [],
        filterValuesRequired: true
      };
      const reqParams = {
        timeFilter: this.timeFilter || 'YEAR',
        tenantGuid: tenantGuid
      };
      await this.$planAdmin.getConsumptionDetailAllEmployees(reqParams, payload).then((res) => {
        if (res.isEnabled) {
          this.consumptionSummary.titles = res.titles;
          this.consumptionSummary.counts = [
            {
              totalLearnerCount: numberWithCommas(res.totalLearners),
              totalJobTitleCount: numberWithCommas(res.totalTitles),
              totalRoleCount: numberWithCommas(res.totalRoles),
              credentialsInProgress: numberWithCommas(res.totalCredentialInProgress),
              credentialsCompleted: numberWithCommas(res.totalCredentialCompleted),
              coursesInProgress: numberWithCommas(res.totalCourseInProgress),
              coursesCompleted: numberWithCommas(res.totalCourseCompleted),
              audiosInProgress: numberWithCommas(res.totalAudioInProgress),
              audiosCompleted: numberWithCommas(res.totalAudioCompleted),
              videosInProgress: numberWithCommas(res.totalVideoInProgress),
              videosCompleted: numberWithCommas(res.totalVideoCompleted),
              articlesInProgress: numberWithCommas(res.totalArticleInProgress),
              articlesCompleted: numberWithCommas(res.totalArticleCompleted),
              trackItems: numberWithCommas(res.totalTrackItems),
              trackItemsCompleted: numberWithCommas(res.totalTrackItemsCompleted),
              completedTimeSpent: convertHourMinuteStringtoStringWithCommas(res.totalCompletedTimeSpent),
              inProgressTimeSpent: convertHourMinuteStringtoStringWithCommas(res.totalInProgressTimeSpent),
              totalLearning: convertHourMinuteStringtoStringWithCommas(res.totalLearning),
              overallCompletedTimeSpent: convertHourMinuteStringtoStringWithCommas(res.totalOverallCompletedTimeSpent),
              progress: res.progress + '%',
              overallLearning: '',
              learningPlan: ''
            }
          ];
        } else {
          this.consumptionSummary.counts = [];
        }

        if (filtersRequired) {
          this.titles = this.consumptionSummary.titles || [];
        }
      });
    },
    async getEmployees(org, titles, statusValues) {
      this.orgInFocus = org;
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      const reqParams = {
        timeFilter: this.timeFilter || 'YEAR',
        tenantGuid: tenantGuid,
        id: org.id
      };
      const payload = { pageNo: 1, pageSize: 25, titles: titles || [], statusValues: statusValues || [] };
      const summaryResponse = await this.$planAdmin.getConsumptionDetailAllEmployeesFromOrg(reqParams, payload);
      if (summaryResponse.isEnabled) {
        this.consumptionSummary.titles = summaryResponse.titles;
        this.consumptionSummary.counts = [
          {
            totalLearnerCount: numberWithCommas(summaryResponse.totalLearners),
            totalJobTitleCount: numberWithCommas(summaryResponse.totalTitles),
            totalRoleCount: numberWithCommas(summaryResponse.totalRoles),
            credentialsInProgress: numberWithCommas(summaryResponse.totalCredentialInProgress),
            credentialsCompleted: numberWithCommas(summaryResponse.totalCredentialCompleted),
            coursesInProgress: numberWithCommas(summaryResponse.totalCourseInProgress),
            coursesCompleted: numberWithCommas(summaryResponse.totalCourseCompleted),
            audiosInProgress: numberWithCommas(summaryResponse.totalAudioInProgress),
            audiosCompleted: numberWithCommas(summaryResponse.totalAudioCompleted),
            videosInProgress: numberWithCommas(summaryResponse.totalVideoInProgress),
            videosCompleted: numberWithCommas(summaryResponse.totalVideoCompleted),
            articlesInProgress: numberWithCommas(summaryResponse.totalArticleInProgress),
            articlesCompleted: numberWithCommas(summaryResponse.totalArticleCompleted),
            trackItems: numberWithCommas(summaryResponse.totalTrackItems),
            trackItemsCompleted: numberWithCommas(summaryResponse.totalTrackItemsCompleted),
            completedTimeSpent: convertHourMinuteStringtoStringWithCommas(summaryResponse.totalCompletedTimeSpent),
            inProgressTimeSpent: convertHourMinuteStringtoStringWithCommas(summaryResponse.totalInProgressTimeSpent),
            totalLearning: convertHourMinuteStringtoStringWithCommas(summaryResponse.totalLearning),
            progress: summaryResponse.progress + '%'
          }
        ];
      } else {
        this.consumptionSummary.counts = [];
      }
    },
    async setup() {
      this.setHeaders();
      // keeping this here to not show empty filters
      this.generateFilterData();
      this.componentIsActive = true;
    },
    async getTreeData() {
      const data = await this.$planAdmin.getRootOrgManagePeopleReporting();
      let startingStruct = data || [];
      this.orgData = {
        id: startingStruct[0]?.id || '',
        name: startingStruct[0]?.name || ''
      };
      this.addStatus(startingStruct, 1);
      this.structure = startingStruct;
    },
    addStatus(arr) {
      arr.forEach((i) => {
        i.downloadStatus = 'READY';
        if (i.children && i.children.length) {
          this.addStatus(i.children || []);
        }
      });
    },

    async downloadCSVPerORG(orgTemp) {
      let org = Object.keys(orgTemp).length === 0 ? this.orgData : orgTemp;
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      org.downloadStatus = 'LOADING';
      const payload = { orgId: org.id || 'root', tenantGuid: tenantGuid, timeFilter: this.timeFilter || 'YEAR' };
      const reqId = await this.$planAdmin.getORGConsumptionSummaryByRequestID(payload);
      let loadingStatus = 'IN_PROGRESS';
      let res = {};
      let counter = 0;
      while (loadingStatus == 'IN_PROGRESS' && counter < 200) {
        // keep checking every 3 seconds
        res = await this.$planAdmin.consumptionDownloadPerORGgetUpdate(reqId);
        loadingStatus = res.status;
        await new Promise((resolve) => setTimeout(resolve, 3000));
        counter++;
      }
      if (loadingStatus == 'FINISHED') {
        let filename = this.$t('common.titleCase.learningRecord') + `_${org?.name || 'Org'}_${generateCSVTimeFilter('YEAR')}_${this.getDateForCSV()}`;
        let a = document.createElement('a');
        a.href = res.generatedUri;
        a.setAttribute('download', filename);
        a.click();
        org.downloadStatus = Object.keys(orgTemp).length === 0 ? 'READY' : 'SUCCESS';
      } else {
        // this can also mean we timed out/counter went to 200(10 minutes)
        org.downloadStatus = Object.keys(orgTemp).length === 0 ? 'READY' : 'ERROR';
        if (loadingStatus == 'IN_PROGRESS') {
          // this means we timed out while trying to download
          console.log('Retrieving Learner Records is taking too long');
        } else {
          console.log(res.message);
        }
      }
    },
    async applyFilters(filters, selectedFilters) {
      this.$emit('filters-applied', selectedFilters);
      this.displaySelectedFilters = selectedFilters;
      this.tableDataReady = false;
      const titles = [...filters[1].values];

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus, titles);
      } else {
        await this.getAllEmployees(true, titles);
      }
      await this.setup();
      this.tableDataReady = true;
    },
    async onGetPlan(org) {
      this.tableDataReady = false;
      this.consumptionSummary = {};
      this.selectDepartment = true;
      if (org.id) {
        await this.getEmployees(org);
      }
      this.$options.childInterface.clearFilters();
      await this.setup();
      this.tableDataReady = true;
    },
    setHeaders() {
      this.headers = [];
      if (!this.headers.length) {
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.learners'),
          key: 'totalLearnerCount',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.learners')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.learners'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.title'),
          key: 'totalJobTitleCount',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.title')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.title'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.role'),
          key: 'totalRoleCount',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.role')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.role'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.overallLearning'),
          key: 'overallLearning',
          sortable: false,
          width: '5rem',
          minWidth: '5rem'
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.overallCompletedTimeSpent'),
          key: 'overallCompletedTimeSpent',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.overallCompletedTimeSpent')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.overallCompletedTimeSpent'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.credentialsInProgress'),
          key: 'credentialsInProgress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.credentialsInProgress')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.credentialsInProgress'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.credentialsCompleted'),
          key: 'credentialsCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.credentialsCompleted')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.credentialsCompleted'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.coursesInProgress'),
          key: 'coursesInProgress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.coursesInProgress')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.coursesInProgress'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.coursesCompleted'),
          key: 'coursesCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.coursesCompleted')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.coursesCompleted'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.audiosInProgress'),
          key: 'audiosInProgress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.audiosInProgress')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.audiosInProgress'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.audiosCompleted'),
          key: 'audiosCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.audiosCompleted')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.audiosCompleted'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.videosInProgress'),
          key: 'videosInProgress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.videosInProgress')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.videosInProgress'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.videosCompleted'),
          key: 'videosCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.videosCompleted')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.videosCompleted'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.articlesInProgress'),
          key: 'articlesInProgress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.articlesInProgress')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.articlesInProgress'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.articlesCompleted'),
          key: 'articlesCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.articlesCompleted')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.articlesCompleted'))
        });
        this.headers.push({
          title: this.$t('insights.content.drilldownHeaders.learningPlan'),
          key: 'learningPlan',
          sortable: false,
          width: this.getElementWidth(this.$t('insights.content.drilldownHeaders.learningPlan')),
          minWidth: this.getElementWidth(this.$t('insights.content.drilldownHeaders.learningPlan'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.trackItems'),
          key: 'trackItems',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.trackItems')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.trackItems'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.trackItemsCompleted'),
          key: 'trackItemsCompleted',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.trackItemsCompleted')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.trackItemsCompleted'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.completedTimeSpent'),
          key: 'completedTimeSpent',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.completedTimeSpent')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.completedTimeSpent'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.inProgressTimeSpent'),
          key: 'inProgressTimeSpent',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.inProgressTimeSpent')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.inProgressTimeSpent'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.totalLearning'),
          key: 'totalLearning',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.totalLearning')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.totalLearning'))
        });
        this.headers.push({
          title: translate('insights.content.drilldownHeaders.totalProgress'),
          key: 'progress',
          width: this.getElementWidth(translate('insights.content.drilldownHeaders.totalProgress')),
          minWidth: this.getElementWidth(translate('insights.content.drilldownHeaders.totalProgress'))
        });
      }
    },
    checkSearchLength(val) {
      !val ? (this.clearSearchCross ? (this.clearSearchCross = false) : this.handleSearchClear()) : void 0;
    },
    async resetTreeView() {
      this.treeViewReady = false;
      await this.getTreeData();
      this.treeViewReady = true;
    },
    async handleSearchClear() {
      this.clearSearchCross = true;
      this.$options.childInterface.clearFilters();
      this.orgInFocus = '';
      await Promise.all([this.resetTreeView(), this.getAllEmployees('', true)]);

      await this.setup();
    },
    async handlePageClick() {
      const titles = [];
      const statusValues = [];
      this.tableDataReady = false;
      for (let filter of this.displaySelectedFilters) {
        switch (filter.type) {
          case 'Job Title':
            titles.push(filter.name);
            break;
          default:
            break;
        }
      }

      if (this.orgInFocus) {
        await this.getEmployees(this.orgInFocus, titles, statusValues);
      } else {
        await this.getAllEmployees(true, titles, statusValues);
      }

      await this.setup();
      this.tableDataReady = true;
    },
    generateFilterData() {
      this.filterResults = {
        // remove job Title as a filter- keeping the code in case we need it in the future
        // jobTitleFilterValues: []
      };
      // if (this.consumptionSummary.titles) {
      //   for (let name of this.consumptionSummary.titles) {
      //     // remove job Title as a filter- keeping the code in case we need it in the future
      //     // this.filterResults.jobTitleFilterValues.push({ name })
      //   }
      // }
    },
    onRemoveFilter(item) {
      this.$options.childInterface.removeFilter(item);
    },
    async onClearFilters() {
      this.$options.childInterface.clearFilters();
      this.$nextTick(async () => {
        if (this.orgInFocus) {
          await this.getEmployees(this.orgInFocus);
        } else {
          await this.getAllEmployees(true);
        }
        await this.setup();
      });
    }
  }
};
</script>

<style lang="scss">
@mixin scrollable {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 1.25rem;
    width: 0.25rem;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
}

.consumption-dashboard-container-c1 {
  max-width: 90rem;
  margin: 0 auto;

  .content-drilldown-header-container {
    display: grid !important;
    grid-template-columns: 6fr 6fr !important;
    margin: 2rem 0rem !important;
  }
  .info-icon {
    width: 16px;
    height: 16px;
    align-items: center;
    vertical-align: middle;
  }
  .info-tooltip {
    visibility: hidden;
    text-align: center;
    padding: 8px 16px;
    position: absolute !important;
    z-index: 999;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000001f;
    border-radius: 4px;
    opacity: 1;
    color: #2d2d2d !important;
    top: 65%;
    font: normal normal normal 12px source sans !important;
    left: 68%;
    width: max-content;
    align-items: center;
    color: var(--Text-Body-DarkGrey, #2d2d2d);
    line-height: 18px;
  }
  .info-icon:hover ~ .info-tooltip {
    visibility: visible;
  }
  .progress-tooltip {
    position: relative;
    &:after {
      content: '\24D8';
      font-size: 0.625rem;
      color: #000;
    }
    &:hover {
      .tooltip-text {
        z-index: 999;
        visibility: visible;
        left: 0rem;
        top: 1rem;
        .tooltip-text-content {
          margin: 1rem 0rem !important;
        }
      }
    }
  }

  .dashboard-details-table-header-actions-c1 {
    display: grid;
    grid-template-columns: 5fr 7fr;
    margin: 1rem 0rem;
    display: none;

    .dashboard-details-table-search {
      padding: 1.5rem 0rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }

    .consumption-details-table-filters {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .dashboard-details-search-chips {
    padding: 1.5rem 0rem;
    padding-top: 0rem;
    display: flex;
    align-items: center;
    flex-direction: row;

    .clear-all-filters {
      text-align: left;
      font: normal normal bold 0.6875rem/1.875rem Arial;
      letter-spacing: -0.0069rem;
      color: #1f3370;
      opacity: 1;
      cursor: pointer;
      display: inline-block;
    }
  }

  .dashboard-details-table-container {
    display: grid;
    grid-template-columns: 19.6875rem 9fr;
    margin-bottom: 5rem;

    .consumption-details-treeview-container {
      .tree-view-header {
        height: 4.6875rem;
        display: flex;
        align-items: center;
        padding: 2rem;
        font: normal normal bold 1rem/1.125rem Arial;
        letter-spacing: 0rem;
        color: #000000;
        border-bottom-left-radius: 0.3125rem;
        border-top-left-radius: 0.3125rem;
        background-color: #d0d0d0;
      }
      .dashboard-details-treeview {
        margin-right: 2rem;
        overflow: scroll;
        @include scrollable;
        height: 30.25rem;

        .v-treeview-node__label {
          overflow: initial;
          text-overflow: initial;
        }

        .v-treeview-node__root {
          cursor: pointer;
          .v-icon {
            background: #e4e5e4 0% 0% no-repeat padding-box;
            border: 0.0625rem solid #e5e5e5;
            opacity: 1;
            border-radius: 6.25rem;
            height: 1.9375rem;
            width: 1.9375rem;
          }

          .download-csv {
            opacity: 0;
            transition: all 0.5s ease-in-out;
          }
          &:hover {
            .download-csv {
              opacity: 1;
            }
          }

          .dept-name {
            font: normal normal normal 1rem/2.5rem Arial;
            letter-spacing: 0rem;
            color: #000000;
            opacity: 1;
            cursor: pointer;
          }
        }
        .v-treeview-node__children {
          .v-treeview-node__root {
            .dept-name {
              font: normal normal normal 1rem/2.5rem Arial;
              letter-spacing: 0rem;
              color: #000000;
              opacity: 1;
            }
          }
        }

        .mdi-menu-down::before {
          content: '\F035D';
          content: '\F0140';
        }
        .v-treeview-node--active {
          background: rgba(255, 255, 255, 0.6);
          color: $color-white !important;
          min-width: fit-content;

          .dept-name {
            font-weight: bold !important;
          }
          .download-csv {
            opacity: 1;
          }
        }
      }
    }
    .v-data-table--fixed-header {
      border: none !important;
    }
    .consumption-details-table-c1 {
      background: inherit;
      display: grid;

      .caption {
        position: absolute !important;
        width: 1px !important;
        height: 1px !important;
        padding: 0 !important;
        margin: -1px !important;
        overflow: hidden !important;
        clip: rect(0, 0, 0, 0) !important;
        white-space: nowrap !important;
        border: 0 !important;
      }

      .no-data-available {
        font-size: 1.5rem;
        font-weight: normal;
        font-family: 'Futura PT Demi';
        text-align: start;
        padding: 12rem 2rem 2rem 14rem;
        color: rgba(0, 0, 0, 0.38);
      }

      .dashboard-details-table-loader {
        margin: 10rem 3.125rem 3.125rem;
        text-align: center;
        width: 56rem;

        .v-input {
          display: contents !important;
          .v-field__overlay {
            display: none;
          }
        }

        .loader-text {
          font-size: 1.875rem;
          color: $color-primary;
        }

        .loader-bar {
          position: relative;
          top: -1.875rem;
        }
      }

      .v-table__wrapper {
        @include scrollable;
        height: 35rem !important;
        overflow: auto;
        position: relative;

        &::-webkit-scrollbar-track {
          background: #fff;
          border-radius: 1.25rem;
          margin-bottom: 1.25rem;
          margin-right: 7rem;
          margin-top: 4.6875rem;
        }

        &::-webkit-scrollbar:vertical {
          border-radius: 0rem;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #d0d0d0 56%);
          margin-bottom: 1.25rem;
        }
        table {
          thead {
            border-bottom-right-radius: 0.3125rem !important;
            border-top-right-radius: 0.3125rem !important;
            height: 4.6875rem;
            border-radius: 0.3125rem;
            background: #d0d0d0;
          }

          tbody {
            overflow: auto;
            @include scrollable;

            .v-data-table-rows-no-data {
              height: 35vh;
              text-align: left;
            }
          }

          thead th:nth-child(4),
          tbody td:nth-child(4),
          thead th:nth-child(15),
          tbody td:nth-child(15) {
            width: 5rem;
          }

          thead {
            th:nth-child(4),
            th:nth-child(5),
            th:nth-child(6),
            th:nth-child(7),
            th:nth-child(8),
            th:nth-child(9),
            th:nth-child(10),
            th:nth-child(11),
            th:nth-child(12),
            th:nth-child(13),
            th:nth-child(14),
            th:nth-child(15),
            th:nth-child(16),
            th:nth-child(17),
            th:nth-child(18),
            th:nth-child(19),
            th:nth-child(20),
            th:nth-child(21) {
              padding: 0rem;
              .v-data-table-header__content {
                background: #fff;
                height: 80%;
                align-items: center;
                display: inline-flex;
                padding: 0rem 1rem;
                width: 100%;
              }

              span {
                font-size: 0.825rem;
              }
            }
          }
          thead th:nth-child(n + 16) {
            z-index: 1;
          }
          thead th:nth-child(4),
          thead th:nth-child(15) {
            .v-data-table-header__content {
              border-top-left-radius: 3.125rem;
              border-bottom-left-radius: 3.125rem;
            }
          }

          thead th:nth-child(14),
          thead th:nth-child(21) {
            .v-data-table-header__content {
              border-top-right-radius: 3.125rem;
              border-bottom-right-radius: 3.125rem;
            }
          }

          thead {
            height: 4.6875rem;
            background: #d0d0d0;
            th {
              box-shadow: none;
            }
            .v-data-table__td {
              background-color: #d0d0d0;
            }

            span {
              text-align: left;
              font: normal normal bold 1rem/1.125rem Arial;
              letter-spacing: 0rem;
              color: #000000;
              opacity: 1;
            }
          }

          tbody {
            .v-input {
              margin: 0rem;
              display: flex;
              align-items: center;
              height: 2.75rem;
              justify-content: center;
              padding-top: 1.375rem;
            }

            .employee-data-field {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 15rem;
            }
            .half-opacity {
              opacity: 0.5;
            }

            tr {
              td {
                height: 2.75rem;
                font-weight: bold;
                font-size: 1.125rem;
                background: #efefef 0% 0% no-repeat padding-box;
              }
              .v-input {
                &:not(.v-input--is-disabled) {
                  .mdi-checkbox-blank-outline {
                    border-radius: 0.25rem;
                    color: #1f3370;
                  }
                }
              }
            }
            @keyframes anim {
              0% {
                transform: scale(1, 0);
              }
              100% {
                transform: scale(1, 1);
              }
            }
            tr.group-inactive > td {
              opacity: 0;
              line-height: 0;
              padding: 0 0.625rem;
              height: 0 !important;
            }
          }
          .v-data-table__td {
            border: none !important;
          }
        }
      }
      .v-data-table-footer {
        display: none;
      }
    }
  }

  .loader {
    margin: 3.125rem;
    margin-left: 2.1875rem;
    text-align: center;

    .loader-text {
      font-size: 1.875rem;
      color: $color-primary;
    }

    .loader-bar {
      position: relative;
      top: -1.875rem;
    }
  }
  .drilldown-loader {
    height: 49.1875rem;
    background: #fff;
    margin: 2rem 0rem 0rem 0rem !important;
    border-radius: 1.25rem;
  }
  .download-csv-tooltip {
    background: #ddd !important;
    color: #5c5c5c;
    font: normal normal normal 1rem/1.375rem Arial;
    padding: 0.725rem 2rem !important;
    opacity: 1 !important;
  }
  .progress-tooltip-wrapper {
    position: relative;
    &:after {
      content: '\24D8';
      font-size: 0.625rem;
      color: #000;
    }
    &:hover {
      .tooltip-text {
        z-index: 999;
        visibility: visible;
        left: 0rem;
        top: 1rem;
        .tooltip-text-content {
          margin: 1rem 0rem !important;
        }
      }
    }
  }
}
</style>
