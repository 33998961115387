<template>
  <div class="container-fluid redesign">
    <transition name="fade">
      <div v-if="showModal" class="app-modal"></div>
    </transition>
    <div v-if="isAboutTimeout && $route.name !== 'Welcome'" class="timeout-panel">
      <v-card>
        <h4>{{ $t('warning.timeout.message') }}</h4>
        <p>{{ $t('warning.timeout.details') }}</p>
        <v-row no-gutters>
          <v-btn class="continue-button" rounded @click="onContinue">{{ $t('button.continue') }}</v-btn>
        </v-row>
      </v-card>
    </div>
    <v-app>
      <a id="main-content-link" :href="`#${mainContentId}`">{{ $t('nav.skipToMainContent') }}</a>
      <nav id="nav" class="d-flex" :style="navigation ? 'display: block' : 'display: none !important'">
        <button @click.stop="toggleSideMenu" class="menu-button" aria-label="open drawer menu" v-if="$route.path !== '/' && $route.path !== '/maintenance' && isPlanCreated">
          <svg-icon icon-class="hamburger-menu-icon" class="menu-button-icon" />
        </button>
        <NavBarSideMenu
          v-if="$route.path !== '/' && $route.path !== '/maintenance' && isPlanCreated"
          :navBarMenu="navBarMenu"
          :isSideMenuActive="isSideMenuActive"
          :hideDashboardC1="hidden"
          @toggle-side-menu="toggleSideMenu"
          @log-out="onLogout"
          @toggle-side-menu-drawer="toggleSideMenuDrawer"
          :key="$i18n.locale"
        />
        <div class="logo d-flex">
          <a href="javascript:void(0);" aria-label="pro edge home" @click="redirectToHome()">
            <svg-icon icon-class="plan-logo" class-name="logo--size" />
          </a>
        </div>
        <div :class="showInsights ? 'menu-long' : 'menu-short'" class="d-flex" v-if="$route.path !== '/' && $route.path !== '/maintenance' && isPlanCreated">
          <ul>
            <router-link data-el="app-dashboard" v-if="isPlanCreated && myName && showInsights" tag="li" class-active="active" :to="'/enrollment'" exact>
              <li class="main-router-link-li" :class="['enrollment', 'usage', 'content', 'trending', 'required-learning'].some((slug) => $route.path.includes(slug)) && 'router-link-active'">
                <a @click="toggleSubmenu()" v-track="'Navbar,Insights,click'" class="t-text-base">{{ $t('nav.insights') }}</a>
                <svg-icon @click="toggleSubmenu()" icon-class="icon-awesome-chevron-down" :class="insightsMenuVisible ? 'insights-nav-chevron-up' : 'insights-nav-chevron-down'" />
              </li>
              <ul class="insights-sub-menu" v-if="insightsMenuVisible">
                <li>
                  <router-link to="/enrollment" class-active="active">{{ $t('nav.insightsSubNav.enrollment') }}</router-link>
                </li>
                <li>
                  <router-link to="/usage" class-active="active">{{ $t('nav.insightsSubNav.usage') }}</router-link>
                </li>
                <li>
                  <router-link to="/content" class-active="active">{{ $t('nav.insightsSubNav.content') }}</router-link>
                </li>
                <li>
                  <router-link to="/trending" class-active="active">{{ $t('nav.insightsSubNav.trending') }}</router-link>
                </li>
                <li v-if="isRequiredLearningEnabled">
                  <router-link to="/required-learning" class-active="active">{{ $t('nav.insightsSubNav.requiredLearning') }}</router-link>
                </li>
              </ul>
            </router-link>

            <router-link data-el="app-catalog" v-if="isPlanCreated && myName" tag="li" :to="'/catalog/landing'">
              <li class="main-router-link-li" :class="$route.path.includes('/catalog') ? 'router-link-active' : ''">
                <a @click="closeSubmenu()" v-track="'Navbar,LearningTrack,click'" class="t-text-base">
                  {{ $t('nav.catalog') }}
                </a>
              </li>
            </router-link>

            <router-link data-el="app-plan" v-if="isPlanCreated && myName" tag="li" class-active="active" to="/role-explorer">
              <li class="main-router-link-li" :class="$route.path.includes('/role-explorer') ? 'router-link-active' : ''">
                <a @click="closeSubmenu()" v-track="'Navbar,RoleExplorer,click'" class="t-text-base">{{ $t('nav.role') }}</a>
              </li>
            </router-link>

            <router-link data-el="app-workforce-planner" v-if="isPlanCreated && myName" tag="li" class-active="active" :to="'/workforce-planner'" exact>
              <li class="main-router-link-li" :class="$route.path.includes('/workforce-planner') ? 'router-link-active' : ''">
                <a @click="closeSubmenu()" v-track="'Navbar,WorkforcePlanner,click'" class="t-text-base">{{ $t('nav.workforce') }}</a>
              </li>
            </router-link>

            <router-link data-el="app-manage-submissions" v-if="isPlanCreated && myName && visibility.manageSubmissions" tag="li" class-active="active" to="/manage-submissions">
              <li class="main-router-link-li" :class="$route.path.includes('/manage-submissions') ? 'router-link-active' : ''">
                <a @click="closeSubmenu()" v-track="'Navbar,ManageSubmissions,click'" class="t-text-base">{{ $t('nav.grading') }}</a>
              </li>
            </router-link>

            <router-link data-el="app-manage-submissions" v-if="isPlanCreated && myName" tag="li" class-active="active" to="/settings/announcements">
              <li class="main-router-link-li" :class="$route.path.includes('/settings') ? 'router-link-active' : ''">
                <a @click="closeSubmenu()" v-track="'Navbar,Settings,click'" class="t-text-base">{{ $t('nav.settings') }}</a>
              </li>
            </router-link>

            <router-link data-el="app-admin-planner" v-if="isPlanCreated && myName && isEnableAdminPlanner && isGlobalAdmin" tag="li" class-active="active" to="/admin-planner/add-access" class="admin-planner">
              <li class="main-router-link-li" :class="$route.path.includes('/admin-planner/add-access') ? 'router-link-active' : ''">
                <a @click="closeSubmenu()" v-track="'Navbar,AdminPlanner,click'" class="t-text-base">{{ $t('nav.admin_plan') }}</a>
              </li>
            </router-link>

            <router-link data-el="app-hr-tools" v-if="isPlanCreated && myName" tag="li" class-active="active" to="/hr-tools/skill-extraction" class="hr-tools">
              <li class="main-router-link-li" :class="$route.path.includes('/hr-tools') ? 'router-link-active' : ''">
                <a @click="closeSubmenu()" v-track="'Navbar,AdminPlanner,click'" class="t-text-base">{{ $t('nav.hr_tools') }}</a>
              </li>
            </router-link>
          </ul>
        </div>
        <v-row justify="end" align="center" v-if="$route.path == '/'" class="nav-right-items">
          <RegisterModal class="action-btn" />
          <LoginModal class="action-btn" :error="loginError" :key="JSON.stringify(loginError)" />
        </v-row>
        <v-row justify="end" align="center" v-else-if="!isPlanCreated && ($route.path == '/terms' || $route.path == '/cookie-notice' || $route.path == '/privacy' || $route.path == '/help-center')" class="nav-right-items">
          <v-btn id="nav_bar-back-button" name="nav_bar-back-button" v-if="!ssoRedirect" color="#ffb92e" dark rounded class="black--text action-btn" style="min-width: 100px" @click="goBack">{{ $t('button.back') }}</v-btn>
        </v-row>
        <v-row justify="end" v-else class="nav-right-items log-out-menu">
          <v-menu v-if="$route.path !== '/maintenance'" :close-on-content-click="false" offset-y attach>
            <template v-slot:activator="{ props }">
              <button v-bind="props" aria-label="Profile" class="t-h-7 t-flex t-items-center" style="max-width: 11rem">
                <v-icon class="t-text-white t-mr-1">mdi-account-circle</v-icon>

                <v-tooltip color="#1f3370" right v-if="organization && organization.length > 20">
                  <template v-slot:activator="{ props }">
                    <span class="login-username t-text-xs t-cursor-default" v-bind="props"> {{ organization }}</span>
                  </template>
                  <span style="font-size: 10px">{{ organization }}</span>
                </v-tooltip>
                <span v-else class="login-username t-text-xs t-cursor-default">{{ organization }}</span>
              </button>
            </template>
            <v-list color="#3450A3" class="t-px-0 t-py-0">
              <v-list-item @click="onLogout" class="t-pl-5 t-pr-16 t-cursor-pointer hover:t-bg-menu-hover">
                <button id="nav_bar-logout-button" @click="onLogout" name="nav_bar-logout-button" class="action-btn t-text-white t-text-xs">{{ $t('button.logout') }}</button>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </nav>
      <div id="main-content-fallback" class="wrapper">
        <router-view v-slot="{ Component, route }">
          <keep-alive :key="route.fullPath">
            <transition name="fade">
              <component :is="Component" />
            </transition>
          </keep-alive>
        </router-view>
      </div>

      <FooterView v-if="$route.path !== '/' && $route.path !== '/maintenance'"></FooterView>

      <v-footer v-else id="footer" :style="navigation ? 'display: block' : 'display: none !important'">
        <v-row>
          <v-col md="7" sm="12" class="d-flex copyright">{{ $t('phrase.disclaimer.rightsReserved', { year: currentYear }) }}</v-col>
          <v-col md="1" sm="12" @click="$router.push('/help-center').catch(() => {})" style="cursor: pointer">{{ $t('legal.help') }}</v-col>
          <v-col md="1" sm="12" @click="goToPrivacy" style="cursor: pointer">{{ $t('legal.privacy') }}</v-col>
          <v-col md="1" sm="12" @click="goToCookieNotice" style="cursor: pointer">{{ $t('legal.cookie') }}</v-col>
          <v-col md="1" sm="12" @click="goToTerms" style="cursor: pointer">{{ $t('legal.terms') }}</v-col>
        </v-row>
      </v-footer>
      <StepperFooter v-if="$route.path.includes('/stepper') || $route.path.includes('/settings/company-logo')" />
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import localforage from 'localforage';
import NavBarSideMenu from '@/components/organisms/NavBarSideMenu';
import LoginModal from '@/components/organisms/authentication/LoginModal';
import RegisterModal from '@/components/organisms/authentication/RegisterModal';
import FooterView from '@/components/organisms/FooterView';
import StepperFooter from '../views/catalog/components/StepperFooter.vue';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'LayoutContent',
  components: { LoginModal, RegisterModal, NavBarSideMenu, FooterView, StepperFooter },
  data() {
    return {
      isRequiredLearningEnabled: null,
      privacyLink: false,
      navigation: true,
      backReference: '',
      ssoRedirect: false,
      showInsights: false,
      hidden: false,
      visibility: {
        assessments: true,
        share: true,
        idea: true,
        settings: true
      },
      insightsMenuVisible: true,
      isPlanCreated: false,
      myName: '',
      organization: '',
      gcsToken: '',
      myUserName: '',
      currentYear: '',
      loginError: {
        exist: false,
        message: ''
      },
      isAboutTimeout: false,
      isNewVersionAvailable: false,
      isLoggedIn: false,
      insightsSubmenu: {
        submenu: []
      },
      navBarMenu: [],
      isSideMenuActive: false,
      mainContentId: 'main-content-fallback',
      isGlobalAdmin: false,
      isEnableAdminPlanner: false,
      isEnableHrTools: false
    };
  },
  computed: {
    ...mapGetters(['planCreated', 'tenant_settings', 'show_share', 'show_idea', 'showModal', 'enabledFeatureFlags', 'isIdle'])
  },
  async created() {
    this.SET_SHOW_MODAL(false);
    this.ssoRedirect = document.cookie && document.cookie.includes('ProAuthSSO=');
    const date = new Date();
    this.currentYear = date.getFullYear();
    await this.setNavigationBar();
    this.watchPlanCreated = this.$store.watch(
      (state, getters) => getters.planCreated,
      (newValue, oldValue) => {
        if (newValue && !oldValue) {
          this.setNavigationBar();
        }
      }
    );
    this.watchAboutTimeout = this.$store.watch(
      (state, getters) => getters.aboutTimeout,
      (newValue, oldValue) => {
        if (newValue != oldValue) {
          this.isAboutTimeout = newValue;
        }
      }
    );
    this.$store.watch(
      (state, getters) => getters.navigationBar,
      (newValue, oldValue) => {
        if (newValue && !oldValue) {
          this.setNavigationBar();
        }
      }
    );
    window.addEventListener('keyup', (e) => {
      if (e.which === 27) {
        this.SET_SHOW_MODAL(false);
      }
    });
    if (this.$route.query.ProAuthSSOError) {
      this.isSSOError(this.$route);
    }
    this.EventBus.on('new-version', this.onNewVersion);
  },
  async mounted() {
    setTimeout(() => {
      const t = this.$getTimer();
      if (!t.timerInitialized && !!this.myUserName) {
        this.$startSessionTimer(true);
      }
    }, 5000);
    if (this?.$store?.getters?.enabledFeatureFlags['plan_sso_accept_terms']) {
      this.showRegisterPopup = (await localforage.getItem('show-registration-popup')) === true;
    }
    this.$nextTick().then(() => {
      this.mainContentId = this.getFirstHeadingIdIfAny();
    });
  },
  watch: {
    // isIdle() {
    //   if (this.myUserName && this.gcsToken) {
    //     this.$resetSessionTimers();
    //   }
    // },
    '$i18n.locale'() {
      this.setNavigationBar();
    },
    async myName() {
      if (this.isRequiredLearningEnabled === null && this.myName) {
        const tenantSettings = await localforage.getItem('my_tenant_settings');
        this.isRequiredLearningEnabled = tenantSettings ? tenantSettings['required-learning'] : false;

        this.isRequiredLearningEnabled &&
          this.insightsSubmenu.submenu.push({
            name: 'Required Learning',
            display: translate('nav.insightsSubNav.requiredLearning'),
            link: '/required-learning'
          });
      }
    },
    async $route(to, from) {
      if (to.path == '/privacy') {
        this.navigation = true;
        this.privacyLink = true;
      }
      if (from.path !== '/help-center' && from.path !== '/terms' && from.path !== '/privacy' && from.path !== '/cookie-notice' && from.name !== null) {
        localforage.setItem('back_referrer', from.path);
      }
      const insightsArray = ['/required-learning', '/enrollment', '/content', '/trending', '/usage'];
      if (insightsArray.includes(to.path)) {
        this.insightsMenuVisible = true;
      } else {
        this.insightsMenuVisible = false;
      }
      this.$route.meta && !this.$route.meta.window ? !this.privacyLink && this.navigation : (this.navigation = this.privacyLink);

      if (to.query.ProAuthSSOError) {
        this.isSSOError(to);
      }
    }
  },
  methods: {
    ...mapMutations('user', ['SET_SHOW_MODAL']),
    ...mapActions('user', ['logout', 'setAboutTimeout']),
    redirectToHome() {
      this.$router.push('/home');
    },
    toggleSubmenu() {
      this.insightsMenuVisible = !this.insightsMenuVisible;
    },
    closeSubmenu() {
      this.insightsMenuVisible = false;
    },
    setInitialMenu() {
      this.navBarMenu = [
        { name: 'Catalog', display: translate('nav.catalog'), link: '/catalog/landing' },
        { name: 'Role explorer', display: translate('nav.role'), link: '/role-explorer' },
        { name: 'Workforce planner', display: translate('nav.workforce'), link: '/workforce-planner' }
      ];
      this.insightsSubmenu = {
        name: 'Insights',
        display: translate('nav.insights'),
        link: '/required-learning',
        submenu: [
          {
            name: 'Enrollment',
            display: translate('nav.insightsSubNav.enrollment'),
            link: '/enrollment'
          },
          {
            name: 'Usage',
            display: translate('nav.insightsSubNav.usage'),
            link: '/usage'
          },
          {
            name: 'Content',
            display: translate('nav.insightsSubNav.content'),
            link: '/content'
          },
          {
            name: 'Trending',
            display: translate('nav.insightsSubNav.trending'),
            link: '/trending'
          }
        ]
      };
      if (this.enabledFeatureFlags && this.enabledFeatureFlags['plan_looker_enable']) {
        this.insightsSubmenu.submenu.push({
          name: 'Looker',
          display: 'Looker',
          link: '/looker-dashboard'
        });
      }
    },
    isSSOError(route) {
      const ssoError = route.query.ProAuthSSOError ? JSON.parse(decodeURIComponent(route.query.ProAuthSSOError)) : null;
      this.loginError.exist = ssoError && ssoError.message ? true : false;
      this.loginError.message = ssoError && ssoError.message ? 'Unauthorized access. Please contact your administrator.' : '';
    },
    goToPrivacy() {
      window.open('https://privacyhub.pwc.com/us/en/proedge/privacy-policy.html', '_blank');
    },
    goToCookieNotice() {
      window.open('https://privacyhub.pwc.com/us/en/proedge/cookie-notice.html', '_blank');
    },
    goToTerms() {
      window.open('https://productcentral.products.pwc.com/legal/Terms-of-Use', '_blank');
    },
    async goBack() {
      this.backReference = (await localforage.getItem('back_referrer')) || '';
      this.$router.push(this.backReference).catch(() => {});
    },
    async onLogout() {
      await this.removeCookies();
      await this.$stopSessionTimer();
      await this.setNavigationBar();
      this.hidden = false;
      this.visibility.share = true;
      this.visibility.idea = true;
      this.visibility.manageSubmissions = true;
      this.visibility.assessments = true;
      this.showInsights = true;
      this.loginError = {
        exist: false,
        message: ''
      };
    },
    async removeCookies() {
      var res = document.cookie;
      var multiple = res.split(';');
      const promise = multiple.map((item) => {
        var key = item.split('=');
        document.cookie = key[0] + ' =; expires = Thu, 01 Jan 1970 00:00:00 UTC';
      });
      await Promise.all([promise]);
    },
    async setNavigationBar() {
      this.myName = await localforage.getItem('my_name');
      this.organization = await localforage.getItem('my_company');
      this.myUserName = await localforage.getItem('my_username');
      this.gcsToken = await localforage.getItem('my_access_token');
      this.isPlanCreated = (await localforage.getItem('my_plan_created')) || this.planCreated;
      const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
      const email = await localforage.getItem('my_username');
      const pwcEmail = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(pwc)\.com$/g;
      const tenantSettings = (await localforage.getItem('my_tenant_settings')) || this.tenant_settings;
      this.hidden = clientCategory && clientCategory.toLowerCase() === 'c1';
      this.visibility.share = (await localforage.getItem('show_share')) || this.show_share;
      this.visibility.manageSubmissions = tenantSettings?.['tenant-assets-submission-enabled'] === true || false;
      this.visibility.idea = (await localforage.getItem('show_idea')) || this.show_idea;
      this.visibility.assessments = pwcEmail.test(email);
      this.showInsights = tenantSettings?.['ui-insight-display'] === true || false;
      this.isEnableAdminPlanner = tenantSettings?.['rbac_enabled'] === true || false;
      const showHrToolsTenant = tenantSettings?.['enable_workforce_solutions'] === true || false;
      const roles = await localforage.getItem('my_roles');
      this.isGlobalAdmin = roles && roles.includes('GLOBAL_ADMIN');
      this.setInitialMenu();
      if (this.isPlanCreated && this.myName && this.showInsights && !this.navBarMenu.find((item) => item.name === 'Insights')) {
        if (tenantSettings?.['required-learning']) {
          this.insightsSubmenu.submenu.push({
            name: 'Required Learning',
            display: translate('nav.insightsSubNav.requiredLearning'),
            link: '/required-learning'
          });
        }
        this.navBarMenu.unshift(this.insightsSubmenu);
      }
      if (this.isPlanCreated && this.myName && this.visibility.manageSubmissions && !this.navBarMenu.find((item) => item.name === 'Manage submissions')) {
        this.navBarMenu.push({ name: 'Manage submissions', display: translate('nav.grading'), link: '/manage-submissions' });
      }
      if (this.isPlanCreated && this.myName && this.visibility.assessments && !this.navBarMenu.find((item) => item.name === 'Assessments')) {
        this.navBarMenu.push({ name: 'Assessments', display: translate('nav.assessments'), link: '/assessments' });
      }
      if (this.isPlanCreated && this.myName && !this.navBarMenu.find((item) => item.name === 'Settings')) {
        this.navBarMenu.push({ name: 'Settings', display: translate('nav.settings'), link: '/settings/announcements' });
      }
      if (this.isEnableAdminPlanner && this.isGlobalAdmin && this.isPlanCreated && this.myName && !this.navBarMenu.find((item) => item.name === 'Admin planner')) {
        this.navBarMenu.push({ name: 'AdminPlanner', display: translate('nav.admin_plan'), link: '/admin-planner/add-access' });
      }
      if (this.enabledFeatureFlags && this.enabledFeatureFlags['plan_hr_tools_enable'] && showHrToolsTenant && !this.navBarMenu.find((item) => item.name === 'HR tools')) {
        this.navBarMenu.push({ name: 'HrTools', display: translate('nav.hr_tools'), link: '/hr-tools/skill-extraction' });
      }
      this.isGATracking();
    },
    /*
     * Enable/Disable Google Analytics's rule
     *   If the feature flag is OFF, then it is OFF for all
     *   If the feature flag is ON, then it depends on trackingDisabled from tenant settings
     */
    async isGATracking() {
      let isGAFlagEnabled = this.enabledFeatureFlags && this.enabledFeatureFlags['plan_google_analytics_enabled'] ? this.enabledFeatureFlags['plan_google_analytics_enabled'] : false;
      let trackingDisabled = await localforage.getItem('trackingDisabled');
      trackingDisabled = trackingDisabled == null ? true : trackingDisabled;
      const isGAEnabled = isGAFlagEnabled && !trackingDisabled;
      if (isGAEnabled) {
        this.$gtag.config({
          cookie_prefix: '_proedge',
          cookie_flags: 'max-age=7200;secure;samesite=none'
        });
        this.$gtag.optIn();
      } else {
        this.$gtag.optOut();
        this.$gtag.config({
          client_storage: 'none'
        });
      }
    },
    async onAccept() {
      await this.$planAdmin.ssoUserAcceptTerms();
      this.$startSessionTimer();
      if (this.showInsights) this.$router.push('/enrollment');
      else this.$router.push('/role-explorer');
    },
    onContinue() {
      this.$resetSessionTimers();
      this.setAboutTimeout(false);
      this.isAboutTimeout = false;
    },
    onNewVersion() {
      this.isNewVersionAvailable = true;
    },
    onCancelRefresh() {
      this.isNewVersionAvailable = false;
    },
    onRefresh() {
      this.$loadVersion();
      this.isNewVersionAvailable = false;
      window.location.reload();
    },
    toggleSideMenu() {
      this.isSideMenuActive = !this.isSideMenuActive;
    },
    toggleSideMenuDrawer(data) {
      this.isSideMenuActive = data;
    },
    getFirstHeadingIdIfAny() {
      const fallbackId = 'main-content-fallback';
      const firstH1 = document.querySelector('h1');

      if (!firstH1) return fallbackId;

      const givenId = firstH1.getAttribute('id');

      if (!givenId) {
        const newId = 'first-heading-of-the-page';
        firstH1.setAttribute('id', newId);
        return newId;
      }

      return givenId;
    }
  },
  beforeDestroy() {
    this.watchPlanCreated();
    this.watchAboutTimeout();
  }
};
</script>

<style lang="scss">
#main-content-fallback {
  background-color: #efefef;
}
#app .redesign .v-application--wrap,
.redesign .theme--light.v-application {
  background-color: #efefef !important;
}
.v-application--wrap {
  background-color: #efefef;
  min-height: calc(100vh - 25px); //leave room for the footer
}
html,
body {
  margin: 0;
}
html {
  scroll-padding-top: 5rem;
}
.wrapper {
  flex: 1;
  margin-top: 5rem;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.d-flex {
  display: flex;
  &.copyright {
    text-align: left;
  }
}

.menu {
  margin-left: 0;
  min-width: 325px;
  @media screen and (max-width: 1280px) {
    margin-left: 5%;
  }
}

.redesign #nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 5rem;
  z-index: 3000;
  width: 100%;
  background: $color-primary;
  box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 0, 0, 0.15);
  font-size: 1rem;

  @media screen and (min-width: 960px) {
    padding-left: 2%;
  }

  .logo {
    width: 160px;
    height: 25px;
    margin: 1.3rem 2rem 1.3rem 1rem;

    @media screen and (max-width: 600px) {
      width: 140px;
    }

    @media screen and (max-width: 400px) {
      width: 120px;
    }

    @media screen and (max-width: 432px) {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
  .logo--size {
    width: 7.125rem;
    height: 2.125rem;
  }
  .app-header {
    color: #fff;
  }

  ul {
    margin: 0;
    padding: 0;

    .main-router-link-li {
      &:hover {
        border-bottom: 0.3125rem solid $color-data-1;
      }
    }

    li {
      display: inline-block;
      padding: 1.6rem 0;
      margin: 0 1.8rem;

      a {
        font-weight: normal;
        text-decoration: none;
      }

      &.router-link-active {
        border-bottom: 0.3125rem solid $color-data-1;
        a {
          font-weight: bold;
        }
      }

      &.router-link-exact-active {
        border-bottom: 0.3125rem solid $color-data-1;
      }
      .active {
        a {
          padding: 0 10px;
          padding-bottom: 18px;
          border-bottom: 5px solid $color-data-1;
        }
      }
    }
  }

  ul.insights-sub-menu {
    display: block;
    position: absolute;
    top: 79px;
    left: 0px;
    padding-left: 500px;
    width: 100%;
    background-color: #4c5c8d;

    li {
      margin: -0.5rem 2.5rem 0rem 2.5rem;
      padding: 1.3rem 0 0.6825rem 0;

      a {
        padding: 0.5875rem;
        border-bottom: 0.3125rem solid transparent;
        font-weight: 400;
        transition: 0.25s ease-in-out;
      }
      .router-link-exact-active {
        border-bottom: 0.3125rem solid #00d1b0;
        font-weight: bold;
      }
      a:hover {
        color: white;
        padding: 0.5875rem;
        border-bottom: 0.3125rem solid #00d1b0;
      }
    }
  }

  a {
    color: #fff;
    &.router-link-exact-active {
      color: white;
    }
  }

  @media screen and (max-width: 432px) {
    padding-left: 0;
  }
}

.explorer {
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 15px;
}

.search-input-wrapper {
  position: relative;
  margin: 5px 10px 15px;
  input {
    padding: 10px;
    border: 1px solid $color-grey-lighter;
    border-radius: 20px;
    width: 250px;
  }
  button {
    border: 1px solid $button-primary-color-bg;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background: $button-primary-color-bg;
    position: absolute;
    right: 0;
    padding: 10px;
  }
}

.profile-menu {
  margin-top: 3px;
  height: 40px;
  background-color: $color-white;
  display: flex;
  align-items: center;
  border-radius: 20px;
  color: $color-primary;
  font-weight: 700;
  width: 100px;
  padding: 1.9px 5px 1.9px 15px;
  .profile-circle {
    width: 25px;
    border-radius: 50%;
    margin: 0 10px 0 10px;
    border: 3px solid $color-secondary;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.nav-right-items {
  padding-right: 40px;
  min-width: 200px;

  .action-btn {
    text-transform: none;
    margin-right: 15px;
  }

  .login-username {
    color: $color-white;
    font-size: 20px;
    margin-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  @media screen and (max-width: 432px) {
    margin-right: 0;

    .action-btn {
      margin-right: 8px;

      button {
        font-size: 0.6rem;
      }
    }
  }
}
.insights-nav-chevron-up {
  transform: rotate(180deg);
  color: #00d1b0 !important;
  margin-left: 0.6rem;
}

.insights-nav-chevron-down {
  color: white !important;
  margin-left: 0.6rem;
}
.redesign #footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 50px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  background: $color-white;
  color: $color-primary;
  padding: 12px 30px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  display: flex !important;
  max-height: 5rem;

  .logo {
    float: left;
    margin-right: 10px;
    height: 20px;
  }

  @media screen and (max-width: 432px) {
    & > .row {
      display: initial;
    }

    .col {
      margin-bottom: 6px;
    }
  }
}

.app-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.2;
  z-index: 3020;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.timeout-panel {
  position: fixed;
  top: 35%;
  left: 35vw;
  width: 450px;
  z-index: 3040;
  .v-card {
    height: 150px;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid $button-primary-color-bg;
    h4,
    p {
      font-size: 16px;
    }
    h4 {
      margin: 0 0 20px;
    }
  }
  .no-gutters {
    justify-content: flex-end;
    align-items: center;
    button {
      margin-right: 20px;
    }
  }
}

.refresh-panel {
  position: fixed;
  padding: 0.75rem 0rem;
  width: 450px;
  right: 3rem;
  bottom: 4.5rem;
  z-index: 4040;
  border-radius: 12px;
  border: 2px solid $color-secondary;
  text-align: center;
  background: $color-primary-super-lightest;
  .row {
    .prompt {
      margin: auto;
      font-size: 1rem;
      font-weight: 500;
    }
    .refresh {
      background: $button-primary-color-bg !important;
      color: $button-primary-color-text !important;
      text-transform: capitalize;
    }
  }
}

.continue-button {
  width: 120px;
  margin-top: 10px;
  background: $button-primary-color-bg !important;
  color: $button-primary-color-text !important;
  text-transform: capitalize;
}

.menu-button {
  margin-left: 0;
}

.menu-button-icon {
  height: 1.875rem !important;
  width: 1.875rem !important;
  margin: 0.5rem 1.75rem 0 0.675rem;
}

.log-out-menu {
  margin-right: 8.625rem !important;
  display: flex;
  align-items: center;
  .v-list {
    background: #3450a3 !important;
  }
}

#main-content-link {
  position: fixed;
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  outline-offset: -0.5rem;
  background: $color-primary;
  color: #fff;
  z-index: 3000;

  &:not(:focus) {
    & + nav {
      top: 0 !important;
    }
  }

  & + nav {
    top: 3rem !important;
  }
}

.menu-long.d-flex ul {
  .hr-tools {
    display: none;
  }
}

@media screen and (max-width: 1492px) {
  .menu-short.d-flex ul {
    display: none !important;
  }
}

@media screen and (max-width: 1648px) {
  .menu-long.d-flex ul {
    display: none !important;
  }
}

@media screen and (max-width: 1720px) {
  .menu-long.d-flex ul {
    .admin-planner,
    .hr-tools {
      display: none;
    }
  }
}

@media screen and (max-width: 432px) {
  .row {
    margin: initial;
    max-width: 100%;

    & > * {
      margin: initial;
      padding: initial;
    }
  }

  * {
    max-width: 100vw !important;
  }
}
</style>
