<template>
  <v-dialog content-class="notification-dialog-tracks t-rounded-20-xl" class="save-dialog" :modelValue="notificationDialog" @update:modelValue="notificationDialog = $event" persistent width="367">
    <!-- <v-dialog content-class="notification-dialog-tracks t-rounded-20-xl" class="save-dialog" v-model="notificationDialog" persistent width="367" height="261"> -->
    <v-card class="t-rounded-20-xl t-pt-6 t-px-0 notification-dialog-card">
      <div :class="header === 'headerRemovePeople' ? 't-pl-8' : 't-pl-4'">
        <v-btn icon class="notification-close-btn t-float-right" @click="cancelNavigation" variant="text">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <p class="t-w-64 t-ml-4 t-text-2xl t-tracking-normal t-font-futura-pt-demi t-text-black t-mb-4">{{ $t(`confirmation.notification.${header}`) }}</p>
        <v-radio-group v-model="radioGroup" @change="userSelection" class="pl-2 notify-user-selection">
          <v-radio v-if="requiredLearningSelection" width="36" :ripple="false" :label="$t(`confirmation.notification.requiredLearningSelectionMessageAdd`)" :value="3"></v-radio>
          <v-radio :ripple="false" :label="$t(`confirmation.notification.${copy}`)" :value="1"></v-radio>
          <v-radio :ripple="false" :label="$t(`confirmation.notification.${copySecondary}`)" :value="2"></v-radio>
        </v-radio-group>
      </div>
      <v-card-actions class="t-pb-8 t-place-content-end pr-8">
        <button class="t-font-futura-pt-book t-mx-1 t-color-white t-py-2 min-w-min t-px-6 t-font-bold t-text-base" text @click="cancelNavigation()">{{ $t('button.cancel') }}</button>
        <button :disabled="disableConfirmButton" class="t-font-futura-pt-book t-mx-1 t-color-white t-py-2 min-w-min t-px-11 t-rounded-full t-font-bold t-text-base t-bg-credential t-border-credential hover:t-bg-primary-color hover:t-text-white" text @click="saveAndNavigate()">
          {{ $t('button.confirm') }}
        </button>
      </v-card-actions>
      <div v-if="requiredLearningSelection" class="t-text-xs t-text-center t-px-4 t-pb-6">{{ $t('track.notificationCancelMessage') }}</div>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'NotificationDialog',
  emits: ['save-navigate', 'save-navigate-reqd-learning', 'cancel-and-navigate', 'cancel-navigation'],
  props: {
    notificationDialog: {
      type: Boolean,
      required: true,
      default() {
        return false;
      }
    },
    copy: {
      type: String,
      required: false,
      default() {
        return 'selectionContinueMessageUpdateContent';
      }
    },
    copySecondary: {
      type: String,
      required: false,
      default() {
        return 'selectionDoNotNotifyMessage';
      }
    },
    header: {
      type: String,
      required: false,
      default() {
        return 'header';
      }
    },
    requiredLearningSelection: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      notifyLearners: false,
      disableConfirmButton: true,
      radioGroup: '',
      userSelectionValue: 0
    };
  },
  methods: {
    userSelection(e) {
      const value = e.target.value;
      this.notifyLearners = value == 1;
      this.userSelectionValue = value;
      this.disableConfirmButton = false;
    },
    saveAndNavigate() {
      this.userSelectionValue == 3 ? this.$emit('save-navigate-reqd-learning') : this.$emit('save-navigate', { notifyLearners: this.notifyLearners });
      this.radioGroup = '';
    },
    cancelNavigation() {
      this.notifyLearners = false;
      this.disableConfirmButton = true;
      this.radioGroup = '';
      this.$emit('cancel-navigation');
    }
  }
};
</script>
<style lang="scss" scoped>
.notification-dialog-tracks {
  button:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .v-input {
    &:not(.v-input--is-disabled) {
      .mdi-checkbox-blank-outline {
        border-radius: 0.25rem;
        color: #1f3370;
      }
    }
  }
  .notification-close-btn {
    margin-right: 1.125rem;
    margin-top: -0.125rem;
    .v-icon {
      font-size: 1.875rem;
    }
  }
  .notify-user-selection {
    .v-label {
      color: rgba(0, 0, 0, 0.87) !important;
      font-size: 0.875rem;
    }
    .v-input--selection-controls .v-radio > .v-label {
      margin-top: 1rem;
    }
    .v-input--selection-controls__input .v-icon {
      font-size: 18px;
      color: #dedfdf;
    }
  }
}
</style>
