<template>
  <div v-if="dataReady" class="t-mt-9 edit-due-dates-container">
    <NotificationDialog :notificationDialog="notificationDialog" copy="selectionContinueMessageEditDueDates" @save-navigate="saveAndNavigate" @cancel-navigation="cancelNavigation()" />
    <override-due-date-dialog
      :notificationDialog="warningDueDateDialog"
      header="warningHeader"
      subHeader1="warningSubHeader1"
      subHeader2="warningSubHeader2"
      subHeader3="warningSubHeader3"
      subHeader5="warningSubHeader5"
      subHeader4="warningSubHeader4"
      copy="selectionContinueMessageEditDueDates"
      @proceed-due-date="proceedDueDate()"
      @cancel-due-date="cancelDueDate()"
    />
    <SaveDialog :saveDialog="saveDialog" @cancel-and-navigate="cancelAndNavigate()" @cancel-navigation="cancelNavigation()" />
    <div class="back t-cursor-pointer t-flex t-items-center" @click="toManagePeople()">
      <v-icon class="t-text-2xl">{{ 'mdi-chevron-left' }}</v-icon>
      <span>{{ $t('button.back') }}</span>
    </div>
    <v-row class="t-ml-8">
      <v-col class="edit-due-date-label t-mt-6">
        <div class="title-text">{{ $t('editDueDates.headerTitle') }}</div>
        <div class="track-detail-container">
          <div class="header-sub-title">{{ $t('editDueDates.headerSubTitle') }}</div>
          <div class="header-sub-title">
            <span>{{ contentFormat }}:</span>
            <span>{{ ' ' + title }}</span>
          </div>
          <div class="header-sub-title t-mt-4 t-flex t-justify-between">
            <div class="t-inline-flex t-items-center">
              <span class="t-text-xs">{{ getSelectedText() }} </span>
            </div>
            <div class="t-inline-flex t-items-center">
              <label class="t-mr-4 t-text-xs">{{ $t('editDueDates.sameDueDateV2') }}</label>
              <date-picker v-model:value="picker" placeholder="DD Mon YYYY" confirm confirm-text="Select" @confirm="globalSelectDate(picker)" format="D MMM YYYY" title-format="D MMM YYYY" :clearable="false" :editable="false" :disabled-date="disableDates"></date-picker>
              <span class="t-ml-4 t-cursor-pointer t-text-xs t-text-primary-color t-underline clear-all" @click="globalClearDate()">{{ $t('editDueDates.clearAll') }}</span>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div v-if="!contentId" class="expansion-card-due-dates t-mb-12">
      <div class="t-grid due-dates-table-header t-mb-4 t-pr-6">
        <div class="t-font-bold t-text-base t-text-center t-font-arial">{{ $t('editDueDates.contentType') }}</div>
        <div class="t-font-bold t-text-base t-font-arial">{{ $t('editDueDates.title') }}</div>
        <div class="t-font-bold t-text-base t-font-arial">{{ $t('editDueDates.provider') }}</div>
        <div class="t-font-bold t-text-base t-text-center t-font-arial">{{ $t('editDueDates.duration') }}</div>
        <div class="t-font-bold t-text-base t-text-center t-font-arial">{{ $t('editDueDates.assignedAs') }}</div>
        <div class="t-font-bold t-text-base t-font-arial">{{ $t('editDueDates.dueDates') }}</div>
      </div>
      <v-expansion-panels accordion v-model="active">
        <div class="scrollable-container">
          <v-expansion-panel class="t-mb-4" v-for="(item, i) in trackItems" :key="i">
            <v-expansion-panel-title>
              <div class="due-dates-expansion-header">
                <div class="t-font-bold t-font-arial t-text-xs t-text-center ellipsis-text">
                  <span class="t-rounded-full t-font-bold t-mb-0.5 t-h-6 t-font-arial t-px-3 t-py-1" :class="dataFormatBg[item.formatType.toLocaleLowerCase()]">{{ getLocalizedContentType(item.formatType) }}</span>
                </div>
                <div class="t-overflow-hidden t-pr-4">
                  <div class="expand-title text-ellipsis t-overflow-hidden t-text-lg t-font-futura-pt-demi t-text-left t-text-primary-color">{{ item.title }}</div>
                </div>
                <div class="t-font-arial t-text-xs t-text-left ellipsis-text">{{ item.provider }}</div>
                <div class="t-text-center t-text-xs ellipsis-text">{{ $sanitize(formatDuration(item.duration)) }}</div>
                <div class="t-text-center t-text-xs">{{ item.selectedDate || (employeeGuids.length > 1 && item.hasDueDate) ? 'Required' : 'Recommended' }}</div>
                <div class="t-text-xs t-flex t-justify-between t-h-full t-items-center">
                  <div class="t-inline-flex t-relative" @click.stop>
                    <date-picker
                      v-model:value="item.pickerVal"
                      placeholder="DD Mon YYYY"
                      confirm
                      confirm-text="Select"
                      @confirm="selectDate(item.pickerVal, i)"
                      format="D MMM YYYY"
                      title-format="D MMM YYYY"
                      :clearable="false"
                      :editable="false"
                      :disabled-date="disableDates"
                    ></date-picker>
                    <v-icon class="t-absolute t--right-6 t-mt-2" v-if="item.selectedDate || item.pickerVal" @click="clearDate(i)">mdi-close</v-icon>
                  </div>
                </div>
              </div>
              <template v-slot:actions>
                <div class="expand-actions t-inline-flex">
                  <v-btn icon class="t-mr-3" variant="text" width="36" height="36">
                    <v-icon class="expand-icon icon t-border-solid t-border t-text-black t-border-gray-400 t-rounded-full">$expand</v-icon>
                  </v-btn>
                </div>
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div>
                <CourseCard :item="item" :dueDatesExpanel="true" />
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </div>
      </v-expansion-panels>
    </div>

    <div v-if="contentId" class="expansion-card-due-dates t-mb-12">
      <v-data-table-server :headers="headers" :items="trackItems" class="content-due-dates">
        <template v-slot:[`header.name`]="{ column }"> {{ column.title }} ({{ trackItems.length }}) </template>
        <template v-slot:[`item.license`]="{ item }">
          <div class="ellipsis-text ellipsis-text-content" :title="item.license">{{ item.license }}</div>
        </template>
        <template v-slot:[`item.dueDate`]="{ index, item }">
          <div class="t-text-xs t-flex t-justify-between t-h-full t-items-center">
            <div class="t-inline-flex t-relative" @click.stop>
              <date-picker
                v-model:value="item.pickerVal"
                placeholder="DD Mon YYYY"
                confirm
                confirm-text="Select"
                @confirm="selectDate(item.pickerVal, index)"
                format="D MMM YYYY"
                title-format="D MMM YYYY"
                :clearable="false"
                :editable="false"
                :disabled-date="disableDates"
              ></date-picker>
              <v-icon class="t-absolute t--right-6 t-mt-2" v-if="item.selectedDate || item.pickerVal" @click="clearDate(index)">mdi-close</v-icon>
            </div>
          </div>
        </template>
        <template v-slot:bottom></template>
      </v-data-table-server>
    </div>
  </div>
  <div v-else>
    <div class="loader t-flex t-justify-center t-items-center t-h-screen">
      <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Edit Due Dates" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import { mapState, mapActions } from 'vuex';
import { dataFormatBg, formatDuration, getLocalizedContentType, localizedDate, getDateForCSV } from '@/utils';
import CourseCard from '@/views/catalog/components/CourseCard';
import NotificationDialog from './NotificationDialog.vue';
import OverrideDueDateDialog from './OverrideDueDateDialog.vue';
import SaveDialog from './SaveDialog.vue';
import { translate } from '@/plugins/i18n.js';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

const initialState = () => ({
  dataFormatBg,
  picker: null,
  currentDate: new moment().format('YYYY-MM-DD').toString(),
  menu: false,
  globalSelectedDate: '',
  globalDatePickerMenu: false,
  trackItems: [],
  notificationDialog: false,
  saveDialog: false,
  active: null,
  dataReady: false,
  toPath: '',
  editDueDateItems: [],
  warningDueDateDialog: false,
  previousDateItemIndex: -1,
  isGlobalDateSelected: false,
  headers: [],
  title: ''
});
export default {
  name: 'EditDueDates',
  components: {
    CourseCard,
    NotificationDialog,
    SaveDialog,
    DatePicker,
    OverrideDueDateDialog
  },
  data() {
    return initialState();
  },
  created() {
    if (!Object.keys(this.EventBus.all).includes('save-track-edit-due-dates')) this.EventBus.on('save-track-edit-due-dates', this.saveEditDueDates);
    const EVENT_NAME = 'download-csv-for-due-dates';
    if (!this.EventBus.all.has(EVENT_NAME)) {
      this.EventBus.on(EVENT_NAME, () => this.downloadDueDatesCsv());
    }
  },
  computed: {
    ...mapState('track', ['trackGuid', 'trackName', 'trackList', 'employeeGuids', 'slectedEmployeeName', 'selectedEmployeeData', 'contentId', 'contentTitle', 'contentFormat'])
  },
  async mounted() {
    if (!this.contentId) {
      Object.assign(this.$data, initialState());
      const payload = {
        trackGuid: this.trackGuid,
        employeeGuids: this.employeeGuids
      };
      this.title = this.trackName;
      const trackItems = await this.$planAdmin.getTrackItemsList(payload);
      trackItems.map((item) => {
        item.isVisible = false;
        item.pickerVal = this.getPickerVal(item);
        item.selectedDate = item.pickerVal ? new moment(item.pickerVal).format('YYYY-MM-DD') : '';
      });
      this.trackItems = trackItems;
    } else {
      await this.setHeaders();
      const trackItems = [...JSON.parse(this.selectedEmployeeData)];
      trackItems.map((item) => {
        item.isVisible = false;
        item.dueDate = item.dueDate && item.dueDate != 'N/A' ? item.dueDate : '';
        item.pickerVal = this.getPickerVal(item);
        item.selectedDate = item.pickerVal ? new moment(item.pickerVal).format('YYYY-MM-DD') : '';
      });
      this.title = this.contentTitle;
      this.trackItems = trackItems;
    }
    this.dataReady = true;
  },
  beforeRouteLeave(to, from, next) {
    if (!['/catalog/stepper/edit-due-dates'].includes(to.path) && this.employeeGuids.length) {
      this.saveDialog = true;
      this.toPath = to.path;
    } else {
      next();
    }
  },
  watch: {
    '$i18n.locale'() {
      this.trackItems.forEach((item) => {
        item.localizedDate = this.localizedDate(this.moment, item.selectedDate);
      });
    }
  },
  methods: {
    localizedDate,
    ...mapActions('track', ['setTrackName', 'setEmployeeGuids', 'setContentId', 'setSelectedEmployeeData']),
    formatDuration,
    getLocalizedContentType,
    getDateForCSV,
    selectDate(pickerVal, i) {
      if (pickerVal) {
        this.trackItems[i].isVisible = false;
        const formatDate = new moment(pickerVal).format('YYYY-MM-DD');
        const localizedDate = this.localizedDate(this.moment, formatDate);
        this.trackItems[i].selectedDate = formatDate;
        this.trackItems[i].localizedDate = localizedDate;
        const dueDate = this.getUtcDate(this.trackItems[i].dueDate);
        this.previousDateItemIndex = i;
        const itemDueDate = this.moment(dueDate).format('YYYY-MM-DD');
        const editDueDate = this.moment(formatDate).isSame(itemDueDate);
        this.isGlobalDateSelected = false;
        if (!editDueDate || this.employeeGuids.length > 1) this.warningDueDateDialog = true;
      }
    },
    globalSelectDate(pickerVal) {
      if (pickerVal) {
        this.globalDatePickerMenu = false;
        const formatDate = new moment(pickerVal).format('YYYY-MM-DD');
        const localizedDate = this.localizedDate(this.moment, formatDate);
        this.trackItems.forEach((item) => {
          item.selectedDate = formatDate;
          item.localizedDate = localizedDate;
          item.pickerVal = pickerVal;
        });
        this.isGlobalDateSelected = true;
        this.warningDueDateDialog = true;
        this.globalSelectedDate = localizedDate;
      }
    },
    setHeaders() {
      this.headers = [
        {
          title: translate('common.titleCase.name'),
          key: 'name',
          align: 'start'
        },
        {
          title: translate('common.filter.jobTitle'),
          key: 'title',
          align: 'start'
        },
        {
          title: translate('common.filter.role'),
          key: 'role',
          align: 'start'
        },
        {
          title: translate('common.filter.license'),
          key: 'license',
          align: 'start',
          width: '25%'
        },
        {
          title: translate('common.filter.status'),
          key: 'status'
        },
        {
          title: translate('common.filter.dueDate'),
          key: 'dueDate',
          align: 'start'
        }
      ];
    },
    clearDate(i) {
      this.trackItems[i].selectedDate = '';
      this.trackItems[i].localizedDate = '';
      this.trackItems[i].isVisible = false;
      this.trackItems[i].pickerVal = null;
      this.picker = null;
      this.isGlobalDateSelected = false;
      this.previousDateItemIndex = i;
      if (this.trackItems[i].dueDate) this.warningDueDateDialog = true;
    },
    globalClearDate() {
      this.globalSelectedDate = '';
      this.trackItems.forEach((item) => {
        item.selectedDate = '';
        item.localizedDate = '';
        item.pickerVal = null;
      });
      this.globalDatePickerMenu = false;
      this.picker = null;
      this.isGlobalDateSelected = true;
      this.warningDueDateDialog = true;
    },
    toManagePeople() {
      this.$router.push('/catalog/stepper/manage');
    },
    async saveEditDueDates() {
      this.notificationDialog = true;
    },
    async saveAndNavigate(shouldNotifyLearners) {
      this.notificationDialog = false;
      this.showToast(translate('editDetails.inProgressSaveDraftToastMessage'), 'info', null, true);
      if (!this.contentId) {
        let learningTrackItems = this.trackItems.map(({ id, selectedDate }) => {
          if (this.employeeGuids.length > 1 && !this.isGlobalDateSelected) {
            if (selectedDate != '') return { contentId: id, dueDate: selectedDate };
            else return { contentId: id, dueDate: '' };
          } else {
            return { contentId: id, dueDate: selectedDate };
          }
        });
        learningTrackItems = learningTrackItems.filter((item) => item);
        const payload = {
          ...shouldNotifyLearners,
          trackGuid: this.trackGuid,
          learners: [...this.employeeGuids],
          requiredLearning: [...learningTrackItems]
        };
        try {
          const response = learningTrackItems.length > 0 ? await this.$planAdmin.saveDueDates(payload) : 'SUCCESS';
          this.showResponseMessage(response);
          await this.setEmployeeGuids([]);
          this.$router.push('/catalog/landing');
        } catch (error) {
          console.log(error);
        }
      } else {
        const users = this.trackItems.map(({ guid, selectedDate }) => {
          if (this.employeeGuids.length > 1 && !this.isGlobalDateSelected) {
            if (selectedDate != '') return { guid: guid, dueDate: selectedDate };
            else return { guid: guid };
          } else {
            return { guid: guid, dueDate: selectedDate };
          }
        });
        const payload = {
          userGuidsDueDate: users,
          contentId: this.contentId,
          sendNotifications: shouldNotifyLearners.notifyLearners
        };
        try {
          const response = users.length > 0 ? await this.$planAdmin.saveDueDatesForContent(payload) : 'SUCCESS';
          const res = response === 'SUCCESS' ? 'SUCCESS' : response.SUCCESS ? 'SUCCESS' : 'FAILURE';
          this.showResponseMessage(res);
          await this.setEmployeeGuids([]);
          await this.setSelectedEmployeeData('');
          this.$router.push('/catalog/landing');
        } catch (error) {
          console.log(error);
        }
      }
    },
    showResponseMessage(response) {
      this.$toasted.clear();
      response === 'SUCCESS' ? this.showToast(translate('editDetails.successSaveDraftToastMessage'), 'success', 3000, false) : this.showToast(translate('editDetails.failureSaveDraftToastMessage'), 'error', 3000, false);
    },
    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });

      this.$toasted.show(msg, options);
    },
    async cancelAndNavigate() {
      this.saveDialog = false;
      await this.setEmployeeGuids([]);
      this.$router.push({ path: this.toPath });
    },
    cancelNavigation() {
      this.notificationDialog = false;
      this.saveDialog = false;
      this.toPath = '';
    },
    disableDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date < today;
    },
    getPickerVal(item) {
      if (!this.contentId) {
        if (this.employeeGuids.length === 1 && item.hasDueDate && item.dueDate) {
          const dueDate = this.getUtcDate(item.dueDate);
          return dueDate;
        } else {
          return null;
        }
      } else {
        if (this.employeeGuids.length && item.dueDate) {
          const dueDate = this.getUtcDate(item.dueDate);
          return dueDate;
        } else {
          return null;
        }
      }
    },
    getUtcDate(dueDate) {
      dueDate = this.moment(dueDate);
      let offset = dueDate.format('Z');
      offset = offset.includes('-') ? offset.replace('-', '+') : offset.replace('+', '-');
      dueDate.add(offset, 'hours');
      return new Date(dueDate);
    },
    proceedDueDate() {
      this.warningDueDateDialog = false;
    },
    cancelDueDate() {
      if (!this.isGlobalDateSelected) {
        this.trackItems[this.previousDateItemIndex].pickerVal = this.getPickerVal(this.trackItems[this.previousDateItemIndex]);
        this.trackItems[this.previousDateItemIndex].selectedDate = this.trackItems[this.previousDateItemIndex].pickerVal ? new moment(this.trackItems[this.previousDateItemIndex].pickerVal).format('YYYY-MM-DD') : '';
      } else {
        this.trackItems.forEach((item) => {
          item.pickerVal = this.getPickerVal(item);
          item.selectedDate = item.pickerVal ? new moment(item.pickerVal).format('YYYY-MM-DD') : '';
        });
      }
      this.picker = null;
      this.warningDueDateDialog = false;
    },
    getSelectedText() {
      let text = this.employeeGuids.length + ' ' + translate('editDueDates.peopleSelected');
      if (this.employeeGuids.length === 1) {
        text = `${this.slectedEmployeeName} selected`;
      }
      return text;
    },
    async downloadDueDatesCsv() {
      if (this.employeeGuids.length > 0) {
        this.showToast(translate('editDueDates.downloadInProgress'), 'success', 3000, false);
        const payload = {
          trackGuid: this.trackGuid,
          employeeGuids: this.employeeGuids
        };
        const response = await this.$planAdmin.getGuidForDueDatesCsvDownload(payload);
        const reqId = response.requestGuid;
        if (reqId) {
          let res = {};
          let counter = 0;
          let loadingStatus = 'IN_PROGRESS';
          while (loadingStatus == 'IN_PROGRESS' && counter < 200) {
            // keep checking every 3 seconds
            res = await this.$planAdmin.getCsvForDueDates(reqId);
            loadingStatus = res.status;
            await new Promise((resolve) => setTimeout(resolve, 3000));
            counter++;
          }
          if (loadingStatus == 'FINISHED') {
            this.showToast(translate('editDueDates.downloadComplete'), 'success', 3000, false);
            let filename = translate('common.titleCase.learningRecord') + `_${this.trackName}_with due dates_${this.getDateForCSV()}`;
            let a = document.createElement('a');
            a.href = res.output;
            a.setAttribute('download', filename);
            a.click();
          } else {
            // this can also mean we timed out/counter went to 200(10 minutes)
            if (loadingStatus == 'IN_PROGRESS') {
              this.showToast(translate('editDueDates.downloadInProgress'), 'success', 3000, false);
              // this means we timed out while trying to download
              console.log('Retrieving Learner Records is taking too long');
            } else {
              this.showToast(translate('editDueDates.downloadError'), 'error', 3000, false);
              console.log(res.message);
            }
          }
        }
      }
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.editDueDates')
    };
  }
};
</script>

<style lang="scss">
.content-due-dates {
  .v-table__wrapper {
    table {
      tbody {
        tr {
          td {
            @include body-font-0-875;
          }
        }
        tr:nth-child(even) {
          td {
            background: #fff;
          }
        }
        tr:nth-child(odd) {
          td {
            background: #efefef 0% 0% no-repeat padding-box;
          }
        }
      }
      thead {
        tr {
          th {
            @include body-font-bold-0-75;
            span {
              @include body-font-bold-0-75;
            }
          }
        }
      }
    }
  }
}
.mx-datepicker {
  width: 140px !important;
}
.mx-datepicker-popup {
  z-index: 100000;
}
.mx-calendar {
  width: 332px;
}
.mx-calendar-content {
  height: 308px !important;
}
.mx-calendar-header {
  .mx-btn-text {
    color: #5c5c5c;
    font: normal normal bold 1.5rem source serif;
  }
}
.mx-table {
  th {
    color: #2d2d2d;
    border-bottom: 1px solid #d6d6d6;
    font: normal normal 1rem source serif;
  }
  td {
    color: #5c5c5c;
    font: normal normal bold 1.5rem source serif;
    div {
      padding: 6px;
    }
  }
  .cell.active {
    background-color: #ffffff;
    div {
      color: #000000;
      background-color: #ffb92e;
      border-radius: 30px;
    }
  }
}
.mx-datepicker-footer {
  .mx-btn {
    color: #000000;
    background: #ffb92e;
    border: unset;
    border-radius: 20px;
    font: normal normal 1rem Source Sans Bold;
  }
}
</style>

<style lang="scss" scoped>
.edit-due-dates-container {
  .back {
    span {
      margin-left: -0.25rem;
      text-align: left;
      font: normal normal bold 0.875rem/1.125rem Arial;
      letter-spacing: 0.0875rem;
      color: #5c5c5c;
      text-transform: uppercase;
    }
  }
  .title-text {
    font-family: 'Futura PT Demi';
    color: $color-black;
    font-size: 2.625rem;
  }

  .ellipsis-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ellipsis-text-content {
    width: 216px;
  }

  .track-detail-container {
    margin: 0rem 4rem 1rem 0;
    padding-left: 0;
    max-width: 96%;
    padding-top: 0.5rem;
    .header-title {
      text-align: left;
      font: normal normal normal 2.625rem/3.75rem 'Futura PT Demi';
      letter-spacing: 0rem;
      color: #1f3370;
    }
    .header-sub-title {
      text-align: left;
      font-family: 'Source Sans';
      font-size: 1rem;
      letter-spacing: 0rem;
      color: #000000;
      span,
      label {
        font-family: 'Source Sans';
        font-size: 1rem !important;
      }
      label {
        font-size: 14px !important;
      }
      .clear-all {
        font-size: 14px !important;
      }
    }
  }
  .due-dates-table-header,
  .due-dates-expansion-header {
    display: grid;
    grid-template-columns: 2fr 4fr 1fr 1fr 2fr 2fr;
    height: 100%;
    align-items: center;
    width: 100%;
  }

  .expansion-card-due-dates {
    padding: 1.875rem 2.5rem 1.5rem 2.5rem;
    background-color: #fafafa;
    border-radius: 1.25rem;
    .v-expansion-panel-title {
      padding: 0;
      height: 4.68rem;

      .expand-title {
        text-overflow: ellipsis;
        display: -webkit-box;
        max-height: 3.75rem;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        max-width: fit-content;
      }
      &--active .expand-icon {
        transform: rotate(180deg);
      }
    }
  }
  .edit-date-field {
    box-shadow: inset 2px 2px 1px 2px rgb(0 0 0 / 30%);
    width: 8rem;
    padding: 1rem;
    outline: none;
    height: 1.5rem;
    font-size: 0.875rem !important;
  }

  .edit-date-field::-webkit-input-placeholder {
    padding-left: -0.5rem;
    font-size: 0.6875rem;
  }
  .theme--light.v-icon:focus:after {
    opacity: 0;
  }
  .calendar-icon {
    position: relative;
    color: dimgray;
    top: 0.175rem;
    right: 0.275rem;
    width: 1.2rem;
    height: 1.2rem;
    pointer-events: none;
    &.inside-icon {
      top: 0.34rem;
    }
  }
}
</style>
<style lang="scss">
.edit-due-dates-container {
  .v-expansion-panel-header__icon {
    display: none !important;
  }
  .v-expansion-panel {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0.625rem;
  }
  .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child):after {
    border-color: transparent;
  }
  .scrollable-container {
    width: 100%;
    @include scrollable;
    max-height: 40.625rem;
    overflow-y: scroll;
    padding-right: 1.25rem;
  }
}
.due-date-picker {
  .v-picker__actions.v-card__actions {
    display: flex;
    justify-content: center;
  }
}
</style>
