<template>
  <div id="chart">
    <apexchart type="radialBar" :height="height" :options="options" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: 'RadialBarChart',

  props: {
    labelFontSize: { type: Number, required: false, default: 15 },
    data: {
      type: Object,
      required: true,
      default: () => {
        return {
          label: '50%',
          percentage: 50,
          color: '#00d1b0',
          height: 100
        };
      }
    }
  },
  data() {
    return {
      height: 0,
      series: [],
      options: {}
    };
  },
  watch: {
    height: {
      immediate: true,
      handler() {
        this.options = {
          chart: {
            height: this.data.height,
            type: 'radialBar'
          },
          plotOptions: {
            radialBar: {
              hollow: {
                size: '50%'
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  offsetY: 5,
                  show: true,
                  fontSize: this.labelFontSize + 'px',
                  color: '#000'
                },
                value: {
                  show: false
                }
              }
            }
          },
          colors: [this.data.color],
          stroke: {
            lineCap: 'round'
          },
          labels: [this.data.label]
        };
      }
    }
  },
  mounted() {
    this.height = this.data.height;
    this.series = [this.data.percentage];
  }
};
</script>

<style lang="scss" scoped></style>
