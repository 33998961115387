<template>
  <div v-if="!trackResultsLoaded" class="t-grid track-catalog-card-container t-justify-center t-w-full" style="width: 100%">
    <Skeleton v-for="i in columnSize" :key="i" />
  </div>
  <draggable v-else class="t-grid track-catalog-card-container t-grid-rows-3 t-justify-center t-w-full" :list="catalogDisplay" :sort="false" :group="{ name: 'tasks', put: false }">
    <v-card class="col-11 t-w-90 t-max-h-60 t-pb-0 draggable-card t-cursor-move" style="padding: 1.5rem" v-for="(catalogItem, index) in catalogDisplay" :key="index">
      <v-card-text class="text-left catalog-card">
        <span class="t-capitalize t-rounded-full t-font-bold t-h-6 t-font-arial t-px-3 t-py-1 t-mr-2" :class="dataFormatBg[catalogItem.formatType.toLocaleLowerCase()]">{{ getLocalizedContentType(catalogItem.formatType) }}</span>
        <span class="text-capitalize ellipsis-text">{{ catalogItem.provider }}</span>
        <v-btn @click="addItemToLearningTrack(index)" width="36" :aria-label="`${catalogItem.title} add to track`" icon>
          <v-icon class="plus-icon">{{ 'mdi-plus' }}</v-icon>
        </v-btn>
        <v-btn @click="openDialog(index)" width="36" :aria-label="`${catalogItem.title} more info`" icon>
          <v-icon class="expand-icon">{{ 'mdi-arrow-expand' }}</v-icon>
        </v-btn>
      </v-card-text>
      <v-card-title class="card-title t-pt-6 t-pb-6 t-font-futura-pt t-text-xl"> {{ catalogItem.title }} </v-card-title>

      <v-card-text class="publish-duration t-flex t-justify-between mt-5">
        <p v-if="catalogItem.duration" class="duration">
          <span class="duration-text">
            <span>{{ convertDuration(catalogItem.duration) }}</span>
            {{ catalogItem.durationType }}
          </span>
        </p>
        <span
          v-if="(catalogItem.publishedDate && catalogItem.formatType.toLocaleLowerCase() === 'article') || catalogItem.formatType.toLocaleLowerCase() === 'audio' || catalogItem.formatType.toLocaleLowerCase() === 'video'"
          class="published t-font-normal t-text-right t-text-grey"
        >
          {{ catalogItem.publishedDate }}</span
        >
      </v-card-text>
    </v-card>
    <DialogView :catalogItem="dialogData" :index="dialogDataIndex" @add-track-item="addItemToLearningTrack" @close-dialog="dialog = false" @open-dialog="dialog = true" :dialog="dialog" />
  </draggable>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import Skeleton from '../../../components/molecules/Skeleton.vue';
import DialogView from './DialogView.vue';
import { dataFormatBg, convertDuration, getLocalizedContentType } from '@/utils';
export default {
  name: 'CatalogCard',
  emits: ['add-track-item'],
  props: {
    catalogDisplay: Array,
    trackResultsLoaded: Boolean
  },
  components: {
    draggable: VueDraggableNext,
    Skeleton,
    DialogView
  },
  data() {
    return {
      dataFormatBg,
      getLocalizedContentType,
      dialog: false,
      dialogData: {},
      dialogDataIndex: 0,
      columnSize: 9
    };
  },
  created() {
    this.resizeColumnSize();
    window.addEventListener('resize', this.resizeColumnSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeColumnSize);
  },
  methods: {
    convertDuration,
    resizeColumnSize() {
      this.columnSize = window.innerWidth >= 1920 ? 12 : 9;
    },
    openDialog(index) {
      console.log(index);
      this.dialogDataIndex = index;
      this.dialogData = this.catalogDisplay[index];
      this.dialog = true;
      document.documentElement.style.overflow = 'hidden';
      document.getElementById('learning-track-container').classList.remove('t-overflow-y-scroll');
    },
    addItemToLearningTrack(index) {
      this.$emit('add-track-item', index);
    }
  }
};
</script>
<style lang="scss">
.track-catalog-card-container {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
@media screen and (min-width: 1920px) {
  .track-catalog-card-container {
    grid-template-columns: repeat(4, minmax(0, 1fr));

    .ellipsis-text {
      max-width: 35% !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.track-catalog-card-container {
  .v-btn {
    box-shadow: none;
    background: transparent;
    &:hover {
      box-shadow: none;
      background: transparent;
    }
  }
  .plus-icon,
  .expand-icon {
    border: 0.0625rem solid $color-grey-light;
    border-radius: 50%;
    height: 25px;
    width: 25px;
  }
  .plus-icon {
    width: 1.5625rem;
    height: 1.5625rem;
    background: #ffb92e;
    font-size: 1.125rem;
    &:hover {
      background-color: #1f3370;
      color: #fff;
    }
  }

  .expand-icon:hover {
    background-color: #1f3370;
    color: #fff;
  }
  .duration {
    padding-top: 0.125rem;
    width: 1.875rem;
    height: 1.875rem;
    text-align: center;
    border-radius: 50%;
    border: 0.0625rem solid $color-grey-lighter;
  }
  .card-title {
    display: -webkit-box;
    height: 3.125rem;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    white-space: break-spaces;
  }
  .duration-text {
    font-size: 0.5rem !important;
    font-family: 'Arial';
    font-weight: bold;
    max-width: 4ch;
    text-align: center;
    margin: 0.125rem 0rem 0rem 0rem;
    span {
      font-size: 0.5rem !important;
      display: block;
    }
  }
  .publish-duration {
    margin-bottom: 1rem;
    p {
      line-height: 0.5rem;
    }
  }
}
.v-card {
  margin: 0.3125rem 0.3125rem 0.75rem 0.3125rem;
  position: relative;
  border-radius: 0.75rem;
}

.scrollable {
  &::-webkit-scrollbar {
    width: 0.25rem;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: $color-primary-lightest;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 1.25rem;
    width: 0.25rem;
  }
}

.show-expanded .v-card-text {
  color: $color-grey;
  font-size: 10px;
  line-height: 14px;
}
.add-to-track {
  text-transform: none;
  padding: 0 10px !important;
}
.track-list-details p {
  color: $color-primary;
}
.plus-icon {
  width: 25px;
  height: 25px;
  background: #ffb92e;
  font-size: 18px;
}
.draggable-card.sortable-chosen {
  cursor: crosshair;
}

html,
.track-container {
  scroll-behavior: smooth;
}
.ellipsis-text {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
}
.mdi-chevron-down:before {
  color: $color-black;
}

.catalog-card {
  display: grid;
  grid-template-columns: 2fr 8fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}
</style>
