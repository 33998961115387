<template>
  <div class="trending-container">
    <ReportLastUpdatedHeader />

    <div class="trending-header-container">
      <div class="trending-header-title">
        <h1>{{ $t('insights.trending.header') }}</h1>
        <span>{{ $t('insights.trending.subHeader') }}</span>
      </div>
      <keep-alive>
        <TrendingCSVDownload :orgId="selectedOrgId" :orgName="orgName" :duration="selectedTimeOptionString" :progress="progressOptionString" :type="contentTypeOptionString" :dateRange="dateRange" />
      </keep-alive>
    </div>

    <div class="trending-header-container">
      <div class="trending-header-title"></div>

      <div class="trending-drop-filter-container">
        <!-- --------  Time Filter ----------- -->
        <div class="trending-header-filter filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black">
          <v-col class="filter-cols t-relative" id="time-filter-dropdown" :style="timeFilter.showExpandedFilter ? 'zIndex:1' : 'zIndex:2'">
            <v-col class="trendingFilter" role="combobox" tabindex="0" @click="openDropdown()" @keydown.enter="openDropdown()">
              <div class="filter">
                <div :class="timeFilter.showExpandedFilter ? 't-border-black' : ''" class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black">
                  <span class="t-font-arial t-text-sm capitalize" :style="timeFilter.disabled ? 'color:rgb(0, 0, 0, 0.2)' : ''">{{ selectedTimeOption }}</span>
                  <v-icon :style="timeFilter.disabled ? 'opacity:0.3' : ''">mdi-chevron-down</v-icon>
                </div>
              </div>
            </v-col>
            <div class="time-filter-dropdown time-filter-dropdown-margin" v-if="timeFilter.showExpandedFilter">
              <v-radio-group v-model="selectedTimeOptionLabel">
                <v-radio v-for="option in timeFilter.options" :key="option.id" :label="$t(option.translationKey)" :value="option.label"></v-radio>
                <div v-if="timeFilter.options.length === 5" class="date-range">
                  <div class="date-label">From</div>
                  <date-picker
                    v-model:value="fromDate"
                    placeholder="DD Mon YYYY"
                    confirm
                    confirm-text="Select"
                    @confirm="onSelectDate($event, 'fromDate')"
                    format="D MMM YYYY"
                    title-format="D MMM YYYY"
                    :clearable="false"
                    :editable="false"
                    :disabled-date="disableDatesFrom"
                    :disabled="disabledDatePicker"
                  ></date-picker>
                </div>
                <div v-if="timeFilter.options.length === 5" class="date-range">
                  <div class="date-label">To</div>
                  <date-picker
                    v-model:value="toDate"
                    placeholder="DD Mon YYYY"
                    confirm-text="Select"
                    confirm
                    @confirm="onSelectDate($event, 'toDate')"
                    format="D MMM YYYY"
                    title-format="D MMM YYYY"
                    :clearable="false"
                    :editable="false"
                    :disabled-date="disableDatesTo"
                    :disabled="disabledDatePicker"
                  ></date-picker>
                </div>
              </v-radio-group>
              <div class="filter-option-footer">
                <div class="filter-option-footer-cancel" @click="onTimeFilterCancel()">{{ $t('insights.cancelButton') }}</div>
                <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" :disabled="disableApply" @click="onTimeFilterApply()">{{ $t('insights.applyButton') }}</v-btn>
              </div>
            </div>
            <div v-if="selectedTimeOptionString === 'CUSTOM' && toDate && fromDate" class="dashboard-details-date-chips">
              <div id="chips-container">
                <v-chip in class="chip t-text-xs mt-2 t-bg-black" closable @click:close="onRemoveDateRange()"> {{ showSelectedFilter() }} </v-chip>
              </div>
            </div>
          </v-col>
        </div>

        <!-- --------  Progress Filter ----------- -->
        <div class="trending-header-filter filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black">
          <v-col class="filter-cols t-relative" id="time-filter-dropdown" :style="progressFilter.showExpandedFilter ? 'zIndex:1' : 'zIndex:2'">
            <v-col class="trendingFilter" role="combobox" tabindex="0" @click="openProgressDropdown()" @keydown.enter="openProgressDropdown()">
              <div class="filter">
                <div :class="progressFilter.showExpandedFilter ? 't-border-black' : ''" class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black">
                  <span class="t-font-arial t-text-sm" :style="timeFilter.disabled ? 'color:rgb(0, 0, 0, 0.2)' : ''">{{ progressOption }}</span>
                  <v-icon :style="progressFilter.disabled ? 'opacity:0.3' : ''">mdi-chevron-down</v-icon>
                </div>
              </div>
            </v-col>
            <div class="time-filter-dropdown" v-if="progressFilter.showExpandedFilter">
              <v-radio-group v-model="progressOptionLabel">
                <v-radio v-for="option in progressFilter.options" :key="option.id" :label="$t(option.translationKey)" :value="option.label"></v-radio>
              </v-radio-group>

              <!-- keeping here because this may need to be a checkbox in the future -->
              <!-- 
              <ul>
                <li v-for="(item, index) in progressFilter.options" :key="index">
                  <v-checkbox :key="item.name" :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable" :label="item.label" dense :value="item.label" v-model="progressOption" style="display: inline-block" />
                </li>
              </ul> 
              -->

              <div class="filter-option-footer">
                <div class="filter-option-footer-cancel" @click="onProgressCancel()">{{ $t('insights.cancelButton') }}</div>
                <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onProgressApply()">{{ $t('insights.applyButton') }}</v-btn>
              </div>
            </div>
          </v-col>
        </div>
        <!-- --------  Content Type Filter ----------- -->
        <div class="trending-header-filter filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black">
          <v-col class="filter-cols t-relative" id="time-filter-dropdown" :style="contentTypeFilter.showExpandedFilter ? 'zIndex:1' : 'zIndex:2'">
            <v-col class="trendingFilter" role="combobox" tabindex="0" @click="openContentTypeDropdown()" @keydown.enter="openContentTypeDropdown()">
              <div class="filter">
                <div :class="contentTypeFilter.showExpandedFilter ? 't-border-black' : ''" class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black">
                  <span class="t-font-arial t-text-sm" :style="contentTypeFilter.disabled ? 'color:rgb(0, 0, 0, 0.2)' : ''">{{ contentTypeOption }}</span>
                  <v-icon :style="contentTypeFilter.disabled ? 'opacity:0.3' : ''">mdi-chevron-down</v-icon>
                </div>
              </div>
            </v-col>
            <div class="time-filter-dropdown" v-if="contentTypeFilter.showExpandedFilter">
              <v-radio-group v-model="contentTypeOptionLabel">
                <v-radio v-for="option in contentTypeFilter.options" :key="option.id" :label="$t(option.translationKey)" :value="option.label"></v-radio>
              </v-radio-group>
              <div class="filter-option-footer">
                <div class="filter-option-footer-cancel" @click="onContentTypeCancel()">{{ $t('insights.cancelButton') }}</div>
                <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onContentTypeApply()">{{ $t('insights.applyButton') }}</v-btn>
              </div>
            </div>
          </v-col>
        </div>
      </div>
    </div>

    <div class="trending-chart-container">
      <div class="dashboard-trending-treeview-container">
        <div class="tree-view-header">{{ $t('common.titleCase.organizationChart') }}</div>
        <!-- 
        <TreeView v-if="structureReady" class="dashboard-trending-treeview" :items="structure" :load-children="onFetchChildren" activatable>
          <template v-slot:label="{ item, active }">
            <div v-if="item.hasOwnProperty('lead')" @click="active ? $event.stopPropagation() : onGetPlan(item)">
              <span :id="`treeview-node-label-${item.id}`" class="dept-name">{{ item.name }}</span>
            </div>
          </template>
        </TreeView> 
        -->
        <DataTableTreeView v-if="structureReady" class="dashboard-trending-treeview" :items="structure" :row="0" :mode="1" @on-get-plan="onGetPlan" @load-children="onFetchChildren" />

        <div v-else class="t-flex t-justify-center t-items-center">
          <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Tree View" />
        </div>
      </div>

      <div class="trending-chart">
        <TrendingChart v-if="structureReady" :orgName="orgName" :orgId="selectedOrgId" :duration="selectedTimeOptionString" :progress="progressOptionString" :type="contentTypeOptionString" :dateRange="dateRange" />
      </div>
    </div>
  </div>
</template>

<script>
import TrendingChart from '@/views/insights/components/TrendingChart';
import ReportLastUpdatedHeader from '@/views/insights/components/ReportLastUpdatedHeader';
import TrendingCSVDownload from '@/views/insights/components/TrendingCSVDownload';
import localforage from 'localforage';
import DataTableTreeView from '@/components/molecules/DataTableTreeView';
import { translate } from '@/plugins/i18n.js';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';

const initialState = () => ({
  checkboxes: [],
  timeFilter: {
    options: [
      { id: 1, label: 'All Time', translationKey: 'common.filter.timeOptions.allTime', value: 'ALLTIME' },
      { id: 2, label: '30 Days', translationKey: 'common.filter.timeOptions.30Days', value: 'DAYS30' },
      { id: 3, label: '90 Days', translationKey: 'common.filter.timeOptions.90Days', value: 'DAYS90' },
      { id: 4, label: '12 Months', translationKey: 'common.filter.timeOptions.12Months', value: 'YEAR' }
    ],
    showExpandedFilter: false
  },
  progressFilter: {
    options: [
      { id: 1, label: 'All Progress', translationKey: 'common.filter.progressOptions.allProgress', value: 'ALL' },
      { id: 2, label: 'In Progress', translationKey: 'common.filter.progressOptions.inProgress', value: 'IN_PROGRESS' },
      { id: 3, label: 'Completed', translationKey: 'common.filter.progressOptions.completed', value: 'COMPLETED' }
    ],
    showExpandedFilter: false,
    disabled: false
  },
  contentTypeFilter: {
    options: [
      { id: 1, label: 'All Content', translationKey: 'common.filter.contentTypeOptions.allContent', value: 'ALL' },
      { id: 2, label: 'Audio', translationKey: 'common.filter.contentTypeOptions.audio', value: 'AUDIO' },
      { id: 3, label: 'Course', translationKey: 'common.filter.contentTypeOptions.course', value: 'COURSE' },
      { id: 4, label: 'Video', translationKey: 'common.filter.contentTypeOptions.video', value: 'VIDEO' },
      { id: 5, label: 'Article', translationKey: 'common.filter.contentTypeOptions.article', value: 'ARTICLE' },
      { id: 6, label: 'Credential', translationKey: 'common.filter.contentTypeOptions.credential', value: 'CREDENTIAL' }
    ],
    showExpandedFilter: false,
    disabled: false
  },
  series: [],
  labels: [],
  height: 350,
  selectedOrgId: 'root',
  selectedTimeOptionLabel: 'All Time',
  selectedTimeOptionString: 'ALLTIME',
  progressOptionLabel: 'All Progress',
  progressOptionString: 'ALL',
  contentTypeOptionLabel: 'All Content',
  contentTypeOptionString: 'ALL',
  structure: [],
  structureReady: false,
  orgName: 'All Orgs',
  selectedTimeOption: '',
  progressOption: '',
  contentTypeOption: '',
  fromDate: '',
  toDate: '',
  disabledDatePicker: true,
  disableApply: false,
  dateRange: {
    startDate: '',
    endDate: ''
  }
});

export default {
  name: 'Trending',
  components: { ReportLastUpdatedHeader, TrendingChart, TrendingCSVDownload, DataTableTreeView, DatePicker },
  data() {
    return initialState();
  },
  async mounted() {
    Object.assign(this.$data, initialState());
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
    if (this.isC1) {
      this.timeFilter = {
        options: [
          { id: 1, label: '30 Days', translationKey: 'common.filter.timeOptions.30Days', value: 'DAYS30' },
          { id: 2, label: '90 Days', translationKey: 'common.filter.timeOptions.90Days', value: 'DAYS90' },
          { id: 3, label: '12 Months', translationKey: 'common.filter.timeOptions.12Months', value: 'YEAR' }
        ],
        showExpandedFilter: false
      };
      this.selectedTimeOption = translate('insights.time.12Months');
      this.selectedTimeOptionString = 'YEAR';
      this.selectedTimeOptionLabel = '12 Months';
    } else {
      this.selectedTimeOption = translate('insights.time.allTime');
      this.timeFilter.options.push({ id: 5, label: 'Date range', translationKey: 'Date range', value: 'CUSTOM' });
    }

    this.progressOption = translate(this.progressFilter.options[0].translationKey);
    this.contentTypeOption = translate(this.contentTypeFilter.options[0].translationKey);
    document.addEventListener('click', this.closePopup);
    await this.fetchInitialData();
  },
  watch: {
    '$i18n.locale'() {
      this.onTimeFilterApply();
      this.onProgressApply();
      this.onContentTypeApply();
    },
    selectedTimeOptionLabel() {
      this.disabledDatePicker = this.selectedTimeOptionLabel.toLowerCase() !== 'date range';
      if (this.disabledDatePicker) {
        this.fromDate = '';
        this.toDate = '';
        this.disableApply = false;
      } else {
        this.disableApply = this.fromDate === '' || this.toDate === '';
      }
    }
  },
  filters: {
    formatLastUpdated(date) {
      const d = new Date(date);
      const options = { month: 'numeric', day: 'numeric', year: 'numeric' };
      let datePart = d.toLocaleDateString('en-US', options);
      return datePart;
    }
  },
  methods: {
    async fetchInitialData() {
      this.structureReady = false;
      await this.getTreeData();

      if ({}.hasOwnProperty.call(this.structure, 'code') && this.structure.code == 401) {
        this.$stopSessionTimer();
      }

      this.structureReady = true;
    },
    async getTreeData() {
      const data = await this.$planAdmin.getRootOrgManagePeople();
      this.structure = data.children;
    },
    async onFetchChildren(item) {
      const structure = await this.$planAdmin.getManageChildrenOrgStructure({ orgId: item.id });
      item.children = structure.children;
    },
    async onGetPlan(org) {
      this.orgName = org.name;
      this.selectedOrgId = String(org.id);
    },
    async openDropdown() {
      this.timeFilter.showExpandedFilter = true;
      this.progressFilter.showExpandedFilter = false;
      this.contentTypeFilter.showExpandedFilter = false;
    },
    async openProgressDropdown() {
      this.progressFilter.showExpandedFilter = true;
      this.timeFilter.showExpandedFilter = false;
      this.contentTypeFilter.showExpandedFilter = false;
    },
    async openContentTypeDropdown() {
      this.contentTypeFilter.showExpandedFilter = true;
      this.progressFilter.showExpandedFilter = false;
      this.timeFilter.showExpandedFilter = false;
    },
    async onTimeFilterApply() {
      const selectedTimeOptionObject = this.timeFilter.options.find((o) => o.label === this.selectedTimeOptionLabel);
      this.selectedTimeOptionString = selectedTimeOptionObject.value;
      this.selectedTimeOption = translate(selectedTimeOptionObject.translationKey);
      this.dateRange.startDate = this.fromDate;
      this.dateRange.endDate = this.toDate;
      this.timeFilter.showExpandedFilter = false;
    },
    onTimeFilterCancel() {
      const selectedTimeOptionObject = this.timeFilter.options.find((o) => o.value === this.selectedTimeOptionString);
      this.selectedTimeOption = translate(selectedTimeOptionObject.translationKey);
      this.selectedTimeOptionLabel = selectedTimeOptionObject.label;
      this.timeFilter.showExpandedFilter = false;
    },
    async onProgressApply() {
      const selectedProgressOptionObject = this.progressFilter.options.find((o) => o.label === this.progressOptionLabel);
      this.progressOptionString = selectedProgressOptionObject.value;
      this.progressOption = translate(selectedProgressOptionObject.translationKey);
      this.progressFilter.showExpandedFilter = false;
    },
    onProgressCancel() {
      const selectedProgressOptionObject = this.progressFilter.options.find((o) => o.value === this.progressOptionString);
      this.progressOption = translate(selectedProgressOptionObject.translationKey);
      this.progressFilter.showExpandedFilter = false;
    },
    async onContentTypeApply() {
      const selectedContentTypeOptionObject = this.contentTypeFilter.options.find((o) => o.label === this.contentTypeOptionLabel);
      this.contentTypeOptionString = selectedContentTypeOptionObject.value;
      this.contentTypeOption = translate(selectedContentTypeOptionObject.translationKey);
      this.contentTypeFilter.showExpandedFilter = false;
    },
    onContentTypeCancel() {
      const selectedContentTypeOptionObject = this.contentTypeFilter.options.find((o) => o.value === this.contentTypeOptionString);
      this.contentTypeOption = selectedContentTypeOptionObject.label;
      this.contentTypeFilter.showExpandedFilter = false;
    },
    onSelectDate() {
      this.disableApply = this.fromDate === '' || this.toDate === '';
    },
    disableDatesFrom(date) {
      return date > new Date() || this.toDate ? date > this.toDate : '';
    },
    disableDatesTo(date) {
      return date > new Date() || date < this.fromDate;
    },
    onRemoveDateRange() {
      this.dateRange.startDate = '';
      this.dateRange.endDate = '';
      this.fromDate = '';
      this.toDate = '';
      this.selectedTimeOption = translate('insights.time.allTime');
      this.selectedTimeOptionString = 'ALLTIME';
      this.selectedTimeOptionLabel = 'All Time';
    },
    showSelectedFilter() {
      const from = this.dateRange.startDate ? this.moment(this.dateRange.startDate).format('D MMM YYYY') : '';
      const to = this.dateRange.endDate ? this.moment(this.dateRange.endDate).format('D MMM YYYY') : '';
      return to && from ? `${from} - ${to}` : '';
    },
    closePopup(e) {
      if (this.timeFilter.showExpandedFilter && document.getElementById('time-filter-dropdown') && !document.getElementById('time-filter-dropdown').contains(e.target) && !e.target.parentNode.className.includes('mx') && !e.target.parentNode.className.includes('cell')) {
        this.onTimeFilterCancel();
      }
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.trending')
    };
  }
};
</script>
<style lang="scss">
#chips-container .v-chip .v-chip__content {
  color: white;
}
#chips-container .v-chip .v-chip__close {
  color: white;
}
.mx-datepicker-popup {
  z-index: 100000;
}
.mx-calendar {
  width: 332px;
}
.mx-calendar-content {
  height: 308px !important;
}
.mx-calendar-header {
  .mx-btn-text {
    color: #5c5c5c;
    font: normal normal bold 1.5rem source serif;
  }
}
.mx-table {
  th {
    color: #2d2d2d;
    border-bottom: 1px solid #d6d6d6;
    font: normal normal 1rem source serif;
  }
  td {
    color: #5c5c5c;
    font: normal normal bold 1.5rem source serif;
    div {
      padding: 6px;
    }
  }
  .cell.active {
    background-color: #ffffff;
    div {
      color: #000000;
      background-color: #ffb92e;
      border-radius: 30px;
    }
  }
}
.mx-datepicker-footer {
  .mx-btn {
    color: #000000;
    background: #ffb92e;
    border: unset;
    border-radius: 20px;
    font: normal normal 1rem Source Sans Bold;
  }
}
</style>
<style lang="scss">
@mixin scrollable {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 1.25rem;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 1.25rem;
    margin-bottom: 1.25rem;
    margin-right: 1.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-primary;
    border-radius: 1.25rem;
    width: 0.25rem;
  }
  &::-webkit-scrollbar-corner {
    display: none;
  }
}
.trending-container {
  max-width: 90rem;
  padding: 0 3rem;
  margin: 6.5rem auto 0;

  .v-btn {
    box-shadow: none;
    background: transparent;
    &:hover {
      box-shadow: none;
      background: transparent;
    }
  }

  .trending-report-update-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3rem;

    .trending-report-update-text {
      padding-top: 0.125rem;
      text-align: left;
      font: normal normal normal 0.75rem/0.875rem Arial;
      letter-spacing: 0rem;
      color: #000000;
    }
  }
  .trending-header-container {
    display: grid;
    grid-template-columns: 6fr 6fr;
    height: 5rem;
    margin-bottom: 2rem;

    .trending-header-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h1 {
        font: normal normal normal 2.625rem/3.375rem 'Futura PT Demi';
        letter-spacing: 0rem;
        color: #1f3370;
      }
      span {
        font: normal normal normal 1rem/1.125rem Arial;
        letter-spacing: 0rem;
        color: #2d2d2d;
      }
    }

    .trending-header-filter {
      display: flex;
      align-items: center;
      background-color: white;
      height: 2.5rem;
      border: 0.125rem solid rgba(0, 0, 0, 0.2);
      margin-left: 2rem;
      width: max-content;

      #time-filter-dropdown {
        .time-filter-dropdown-margin {
          .v-radio {
            &:nth-child(5) {
              margin-top: 0.5rem;
            }
          }
        }
        .time-filter-dropdown {
          width: max-content;
          background-color: $color-skill-table !important;
          border-radius: 1.25rem;
          box-shadow: 0.125rem 0.1875rem 0.0625rem -0.125rem rgb(0 0 0 / 20%), 0 0.125rem 0.125rem 0 rgb(0 0 0 / 14%), 0 0.3125rem 0.3125rem 0 rgb(0 0 0 / 12%);
          position: absolute;
          z-index: 2 !important;
          left: 0;
          top: 3rem;
          ul {
            margin: 2rem 0rem;
          }
          li {
            max-height: 1.875rem;
            display: flex;
            align-items: center;
          }
          .v-radio {
            padding: 0 1rem 0 5px;
            max-height: 2rem;
          }
          .filter-option-footer {
            display: flex;
            gap: 3rem;
            padding: 0.825rem 1.5rem 0.875rem 1.5rem;
            align-items: center;
            border-top: 0.0625rem solid #e0e1e1;
            .filter-option-footer-cancel {
              font-family: 'Futura PT Demi';
              font-size: 1rem;
              color: #1f3370;
              cursor: pointer;
            }
            .filter-option-footer-btn-apply {
              cursor: pointer;
              text-transform: none;
              width: 5.75rem;
              box-shadow: none;
            }
          }
          .date-range {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 10px;
            padding: 0 1rem;
            .date-label {
              font: normal normal bold 1rem Arial;
              color: #2d2d2d;
            }
          }
        }
        .t-font-arial {
          display: flex;
          align-items: center;
          text-align: left;
          font: normal normal bold 0.875rem/1.875rem Arial;
          letter-spacing: -0.0088rem;
          color: #000000;
          opacity: 1;
          padding-right: 1rem;
        }
        i {
          color: #000000;
        }
        .dashboard-details-date-chips {
          position: absolute;
          margin-left: -1rem;
          .v-chip__close {
            i {
              color: white;
            }
          }
        }
      }
    }
  }
  .trending-progress-filter {
    display: flex;
    align-items: center;
    background-color: white;
    height: 2.5rem;
    max-width: 10rem;
    border: 0.125rem solid rgba(0, 0, 0, 0.2);
    width: 9.375rem;

    #progress-filter-dropdown {
      .progress-filter-dropdown {
        width: max-trending;
        background-color: $color-skill-table !important;
        border-radius: 1.25rem;
        box-shadow: 0.125rem 0.1875rem 0.0625rem -0.125rem rgb(0 0 0 / 20%), 0 0.125rem 0.125rem 0 rgb(0 0 0 / 14%), 0 0.3125rem 0.3125rem 0 rgb(0 0 0 / 12%);
        position: absolute;
        z-index: 2 !important;
        left: 0;
        top: 3rem;
        .v-radio {
          padding: 0rem 1rem;
        }
        .progress-filter-option-footer {
          display: flex;
          gap: 3rem;
          padding: 0.825rem 1.5rem 0.875rem 1.5rem;
          align-items: center;
          border-top: 0.0625rem solid #e0e1e1;
          .progress-filter-option-footer-cancel {
            font-family: 'Futura PT Demi';
            font-size: 1rem;
            color: #1f3370;
            cursor: pointer;
          }
          .progress-filter-option-footer-btn-apply {
            cursor: pointer;
            text-transform: none;
            width: 5.75rem;
            box-shadow: none;
          }
        }
      }
      span {
        display: flex;
        align-items: center;
        text-align: left;
        font: normal normal bold 0.875rem/1.875rem Arial;
        letter-spacing: -0.0088rem;
        color: #000000;
        opacity: 1;
      }
      i {
        color: #000000;
      }
    }
  }

  .trending-drop-filter-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .apexcharts-toolbar {
    z-index: 0 !important;
  }
  .card {
    padding: 1rem;
    border-radius: 1.25rem;
    min-width: 15.625rem;
    box-shadow: none !important;
    background: #fff;
  }

  .trending-insights-container {
    display: block;
    margin: 2rem 0rem;

    h3 {
      text-align: left;
      font: normal normal bold 1.125rem/1.875rem Arial;
      letter-spacing: 0.0006rem;
      color: #1f3370;
      opacity: 1;
    }
  }

  .trending-footer {
    display: grid;
    grid-template-columns: 10fr 2fr;
    margin: 3rem 0rem;
    text-align: left;
    font: normal normal normal 0.75rem/0.875rem Arial;
    letter-spacing: 0rem;
    color: #2d2d2d;
    opacity: 1;

    p {
      margin: 0rem;
    }
    .mdi:before,
    .mdi-set {
      display: inline-block;
      font: normal normal normal 3.5rem/1 'Material Design Icons';
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .trending-footer-chevron {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .trending-chart-container {
    display: grid;
    grid-template-columns: 19.25rem 1fr;
    grid-gap: 2rem;
    margin-bottom: 4rem;
    .dashboard-trending-treeview-container {
      .tree-view-header {
        display: flex;
        align-items: center;
        padding: 1rem;
        padding-left: 2.75rem;
        font: normal normal bold 1rem/1.125rem Arial;
        letter-spacing: 0rem;
        color: #000000;
        background-color: #d0d0d0;
        height: 4.6875rem;
      }
      .dashboard-trending-treeview {
        overflow: scroll;
        @include scrollable;
        height: 42.25rem;

        .v-treeview-node__label {
          overflow: initial;
          text-overflow: initial;
        }

        .v-treeview-node__root {
          cursor: pointer;
          .v-icon {
            background: #e4e5e4 0% 0% no-repeat padding-box;
            border: 0.0625rem solid #e5e5e5;
            opacity: 1;
            border-radius: 6.25rem;
            height: 1.9375rem;
            width: 1.9375rem;
          }

          .dept-name {
            font: normal normal normal 1rem/2.5rem Arial;
            letter-spacing: 0rem;
            color: #000000;
            opacity: 1;
            cursor: pointer;
          }
        }
        .v-treeview-node__children {
          .v-treeview-node__root {
            .dept-name {
              font: normal normal normal 1rem/2.5rem Arial;
              letter-spacing: 0rem;
              color: #000000;
              opacity: 1;
            }
          }
        }

        .mdi-menu-down::before {
          content: '\F035D';
          content: '\F0140';
        }
        .v-treeview-node--active {
          background: rgba(255, 255, 255, 0.6);
          color: $color-white !important;
          min-width: fit-content;

          .dept-name {
            font-weight: bold !important;
          }
        }
      }
    }

    .trending-chart {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 1.25rem;
      width: 100%;
      padding: 1rem;
      height: 46.875rem;
    }
  }
}
</style>
