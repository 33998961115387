import localforage from 'localforage';
import store from '@/store';

const state = {
  accessTags: '',
  logo: '',
  is_dashboard_download_on: false,
  show_share: true,
  show_idea: true,
  trackingDisabled: false,
  requiredLearning: false,
  tenant_settings: {},
  showModal: false,
  roleStructure: false,
  uploadingState: false,
  aboutTimeout: false,
  trustedDeviceToken: '',
  navigationBar: false
};

const mutations = {
  SET_NAVIGATION_BAR: (state, value) => {
    state.navigationBar = value;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_USERNAME: (state, username) => {
    state.username = username;
  },
  SET_USER_GUID: (state, user_guid) => {
    state.user_guid = user_guid;
  },
  SET_TENANT_TYPE: (state, tenant_type) => {
    state.tenant_type = tenant_type;
  },
  SET_TENANT_GUID: (state, tenant_guid) => {
    state.tenant_guid = tenant_guid;
  },
  SET_CLIENT_CATEGORY: (state, client_category) => {
    state.client_category = client_category;
  },
  SET_NEXT_REPORT_DATE: (state, next_report_date) => {
    state.next_report_date = next_report_date;
  },
  SET_LAST_REPORT_DATE: (state, last_report_date) => {
    state.last_report_date = last_report_date;
  },
  SET_LAST_UPDATED: (state, last_updated) => {
    state.last_updated = last_updated;
  },
  SET_IS_DASHBOARD_DOWNLOAD_ON: (state, is_dashboard_download_on) => {
    state.is_dashboard_download_on = is_dashboard_download_on;
  },
  SET_TENANT_SETTINGS: (state, settings) => {
    state.tenant_settings = settings;
  },
  SET_TENANT_ACCESS_TAGS: (state, accessTags) => {
    state.accessTags = accessTags;
  },
  SET_COMPANY: (state, company) => {
    state.company = company;
  },
  SET_LOGO: (state, logo) => {
    state.logo = logo;
  },
  SET_GCS_TOKEN: (state, gcs_token) => {
    state.gcs_token = gcs_token;
  },
  SET_SHOW_SHARE: (state, show_share) => {
    state.show_share = show_share;
  },
  SET_SHOW_IDEA: (state, show_idea) => {
    state.show_idea = show_idea;
  },
  SET_TRACKING_OPTION: (state, trackingOption) => {
    state.trackingDisabled = trackingOption;
  },
  SET_REQUIRED_LEARNING: (state, requiredLearning) => {
    state.requiredLearning = requiredLearning;
  },
  SET_SHOW_MODAL: (state, showModal) => {
    state.showModal = showModal;
  },
  SET_ROLES: (state, roleStructure) => {
    state.roleStructure = roleStructure;
  },
  SET_UPLOADING: (state, uploadingState) => {
    state.uploadingState = uploadingState;
  },
  SET_ABOUT_TIMEOUT: (state, aboutTimeout) => {
    state.aboutTimeout = aboutTimeout;
  },
  SET_COMPANY_LOGO_SAVE_BTN_STATE: (state, companyLogoSaveBtnState) => {
    state.companyLogoSaveBtnState = companyLogoSaveBtnState;
  },
  SET_TRUSTED_DEVICE: (state, trustedDeviceToken) => {
    state.trustedDeviceToken = trustedDeviceToken;
  }
};

const actions = {
  // eslint-disable-next-line
  async setAccessToken({}, token) {
    await localforage.setItem('my_access_token', token).then(() => {});
  },
  // eslint-disable-next-line
  async setGCSToken({}, token) {
    await localforage.setItem('my_gcs_token', token).then(() => {});
  },

  async setTrustedDevice({ commit }, token) {
    commit('SET_TRUSTED_DEVICE', token);
    await localforage.setItem('my_trusted_device_token', token).then(() => {});
  },

  async setUserInfo({ commit }, userInfo) {
    const { firstName, email, company, guid, logo, roles } = userInfo;
    commit('SET_LOGO', logo);
    await localforage.setItem('my_name', firstName).then(() => {});
    await localforage.setItem('my_username', email).then(() => {});
    await localforage.setItem('my_company', company).then(() => {});
    await localforage.setItem('my_user_guid', guid).then(() => {});
    await localforage.setItem('my_logo', logo).then(() => {});
    await localforage.setItem('my_roles', roles).then(() => {});
    commit('SET_NAVIGATION_BAR', true);
  },
  // eslint-disable-next-line
  async setTenantType({}, tenantType) {
    await localforage.setItem('my_tenant_type', tenantType).then(() => {});
  },

  async setTenantGuid({ commit }, tenant_guid) {
    commit('SET_TENANT_GUID', tenant_guid);
    await localforage.setItem('my_tenant_guid', tenant_guid).then(() => {});
  },
  // eslint-disable-next-line
  async setClientCategory({}, clientCategory) {
    await localforage.setItem('my_client_category', clientCategory).then(() => {});
  },

  async setNextReportDate({ commit }, date) {
    commit('SET_NEXT_REPORT_DATE', date);
    await localforage.setItem('next_report_date', date).then(() => {});
  },

  async setLastReportDate({ commit }, date) {
    commit('SET_LAST_REPORT_DATE', date);
    await localforage.setItem('last_report_date', date).then(() => {});
  },

  async setLastUpdated({ commit }, date) {
    commit('SET_LAST_UPDATED', date);
    await localforage.setItem('last_updated', date).then(() => {});
  },

  async setIsDashboardDownloadOn({ commit }, isDashboardDownloadOn) {
    commit('SET_IS_DASHBOARD_DOWNLOAD_ON', isDashboardDownloadOn);
    await localforage.setItem('is_dashboard_download_on', isDashboardDownloadOn).then(() => {});
  },

  async setTenantSettings({ commit }, settings) {
    commit('SET_TENANT_SETTINGS', settings);
    await localforage.setItem('my_tenant_settings', settings).then(() => {});
  },
  async setTenantAccessTags({ commit }, accessTags) {
    commit('SET_TENANT_ACCESS_TAGS', accessTags);
    await localforage.setItem('my_tenant_tags', accessTags).then(() => {});
  },

  async setShowShare({ commit }, showShare) {
    commit('SET_SHOW_SHARE', showShare);
    await localforage.setItem('show_share', showShare).then(() => {});
  },

  async setShowIdea({ commit }, showIdea) {
    commit('SET_SHOW_IDEA', showIdea);
    await localforage.setItem('show_idea', showIdea).then(() => {});
  },

  async setTrackingDisabled({ commit }, trackingOption) {
    commit('SET_TRACKING_OPTION', trackingOption);
    await localforage.setItem('trackingDisabled', trackingOption).then(() => {});
  },
  async setRequiredLearning({ commit }, isRequiredLearning) {
    commit('SET_REQUIRED_LEARNING', isRequiredLearning);
    await localforage.setItem('requiredLearning', isRequiredLearning).then(() => {});
  },
  async setAboutTimeout({ commit }, aboutTimeout) {
    commit('SET_ABOUT_TIMEOUT', aboutTimeout);
  },
  async setCompanyLogoSaveBtnState({ commit }, companyLogoSaveBtnState) {
    commit('SET_COMPANY_LOGO_SAVE_BTN_STATE', companyLogoSaveBtnState);
    await localforage.setItem('my_tenant_company_logo_save_btn_state', companyLogoSaveBtnState).then(() => {});
  },

  async logout({ commit }) {
    const cloudEnvInfo = {
      details: { name: '', mode: '', cloudRegion: '', cloudProvider: '' },
      extras: { proAuthBaseUri: '', proAuthApiKey: '' },
      baseApis: { learnApiBaseUri: '', shareApiBaseUri: '', cmsApiBaseUri: '', planApiBaseUri: '' },
      cdn: { contentCdn: '', credentialCdn: '', staticCdn: '' },
      microservices: {
        ffApi: '',
        clickstreamApiBaseUri: '',
        learningLockerApiBaseUri: '',
        learningLockerBaseUri: '',
        courseProgressApi: '',
        autographer: '',
        bouncer: ''
      }
    };

    const userConfig = {
      user: { lang: 'en-US' },
      company: { isC1: false, lang: 'en-us', regions: ['US'], helpdesk: { email: '', phone: '' } },
      flags: { clickstreamEnabled: false, autographerEnabled: false, tierEnabled: false }
    };
    await Promise.allSettled([
      localforage.removeItem('my_access_token'),
      localforage.removeItem('my_name'),
      localforage.removeItem('my_username'),
      localforage.removeItem('my_company'),
      localforage.removeItem('my_user_guid'),
      localforage.removeItem('my_tenant_type'),
      localforage.removeItem('my_client_category'),
      localforage.removeItem('my_tenant_settings'),
      localforage.removeItem('my_tenant_tags'),
      localforage.removeItem('show_share'),
      localforage.removeItem('show_idea'),
      localforage.removeItem('trackingDisabled'),
      localforage.removeItem('requiredLearning'),
      localforage.removeItem('my_logo'),
      localforage.removeItem('my_plan_created'),
      localforage.removeItem('my_gcs_token'),
      localforage.removeItem('next_report_date'),
      localforage.removeItem('last_report_date'),
      localforage.removeItem('last_updated'),
      localforage.removeItem('is_dashboard_download_on'),
      localforage.removeItem('ssoRedirect'),
      localforage.removeItem('enable_role_func_dept'),
      localforage.removeItem('cloudEnv'),
      localforage.removeItem('cloud_env.details'),
      localforage.removeItem('config.user'),
      localforage.removeItem('config.company'),
      localforage.removeItem('config.flags'),
      localforage.removeItem('SET_TOKEN', ''),
      localforage.removeItem('sso-user-registration'),
      localforage.removeItem('sso-user-login'),
      localforage.removeItem('talent-gpt-tasks'),

      commit('SET_USER_GUID', ''),
      commit('SET_SHOW_SHARE', true),
      commit('SET_SHOW_IDEA', true),
      commit('SET_TRACKING_OPTION', false),
      commit('SET_REQUIRED_LEARNING', false),
      commit('SET_LOGO', ''),
      commit('SET_NAVIGATION_BAR', false),
      commit('SET_NEXT_REPORT_DATE', ''),
      commit('SET_LAST_REPORT_DATE', ''),
      commit('SET_LAST_UPDATED', ''),
      commit('SET_IS_DASHBOARD_DOWNLOAD_ON', false),
      commit('SET_TENANT_SETTINGS', null),
      commit('SET_TENANT_ACCESS_TAGS', ''),
      commit('SET_ABOUT_TIMEOUT', false),
      commit('SET_COMPANY_LOGO_SAVE_BTN_STATE', false),
      store.dispatch('track/setTrackList', ''),
      store.dispatch('track/setSaveButtonState', true),
      store.dispatch('track/clearTrackData'),
      store.dispatch('track/clearStepperState'),
      store.dispatch('plan/resetPlanState'),
      store.dispatch('dashboard/resetDashboardData'),
      store.dispatch('dashboard/resetDashboardExpiration'),
      store.dispatch('dashboard/setDashboardReady', false),
      store.dispatch('environment/setCloudEnvInfo', cloudEnvInfo),
      store.dispatch('environment/setDetails', cloudEnvInfo.details),
      store.dispatch('environment/setUserConfig', userConfig.user),
      store.dispatch('environment/setCompanyConfig', userConfig.company),
      store.dispatch('environment/setFlagConfig', userConfig.flags),
      store.dispatch('workforce/resetWorkforceStore')
    ]);
    window.localStorage.clear();
    window.location.href = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
