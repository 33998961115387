<template>
  <div class="overlay">
    <div class="addskills-modal">
      <v-btn class="t-absolute t-top-3 t-right-3 t-shadow-none" icon @click="onCancel()">
        <v-icon class="cancel-btn">mdi-close</v-icon>
      </v-btn>
      <p class="success-icn">
        <svg-icon :icon-class="alertTypes[modalProperties.type]" :class="modalProperties.type == 'success' ? 'g-icon--small' : ''"></svg-icon>
        <span class="modal-header-style">{{ modalProperties.header }}</span>
      </p>
      <v-row>
        <v-col>
          <div class="modal-message-style" v-html="modalProperties.message"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="modalProperties.buttons.length > 0" size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="text-container">
          <template v-for="(btn, index) in modalProperties.buttons">
            <v-btn v-if="btn.type == 'btn'" :key="index" rounded :class="btn.class" :disabled="btn.disabled" @click="btn.click">
              {{ btn.text }}
            </v-btn>
            <span v-if="btn.type == 'link'" :key="index" style="cursor: pointer; font-family: Futura PT Demi !important; color: #1f3370; margin-left: 2rem" @click="btn.click">
              {{ btn.text }}
            </span>
          </template>
        </v-col>
        <v-col v-else size-xl="12" size-lg="12" size-md="12" size-sm="12" size-xs="12" class="text-container">
          <v-btn rounded class="t-bg-yellow-400 btn-text-font modal-btn-style" @click="onCancel()">
            <span style="font-family: Futura PT Demi !important">{{ modalProperties.btnText }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertsModal',
  emits: ['closeAlertsModal'],
  props: {
    modalProperties: {
      type: Object,
      required: true,
      default: () => {
        return {
          closeIcon: false,
          header: '',
          message: '',
          btnText: 'Ok',
          buttons: [],
          type: 'success'
        };
      }
    }
  },
  data() {
    return {
      alertTypes: {
        success: 'icon-success',
        error: 'alert',
        warn: 'alert-yellow'
      }
    };
  },
  methods: {
    onCancel() {
      const length = Array.isArray(this.modalProperties.buttons) && !this.modalProperties.closeIcon ? this.modalProperties.buttons.length : 0;
      this.$emit('closeAlertsModal', length);
    }
  }
};
</script>
<style lang="scss" scoped>
.modal-cancel-icon-style {
  float: right;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
}

.modal-header-style {
  font: normal normal 1.5rem/2.5rem 'Futura PT Demi';
  opacity: 1;
}

.modal-message-style {
  font: normal normal normal 1rem/1.375rem 'Arial';
  opacity: 1;
  color: #5c5c5c;
  text-align: left;
  word-break: break-word;
}

.modal-btn-style {
  font: normal normal normal 1rem/1.375rem 'Futura PT Demi';
  opacity: 1;
  text-align: center;
  width: 4rem;
  text-transform: none;
  margin-left: 1rem;
}

.success-icn {
  text-align: left;
  display: flex;
  align-items: center;

  .svg-icon {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.4rem 0.12rem 0;
  }

  .success-title {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: 'Futura PT Demi';
  }
}

.close-btn {
  text-transform: none;
  font-family: 'Futura PT Demi';
  width: 4rem;
  margin-top: 0.6rem;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.cancel-container {
  text-decoration: unset;
  padding: 2rem;
  color: var(--ion-color-tertiary);
}

.text-container {
  text-align: left;
  font-family: Arial;
  font-size: 1rem;
  color: #5c5c5c;
}

.addskills-modal {
  position: fixed;
  padding: 1.5rem;
  width: 25rem;
  height: auto;
  border-radius: 1.25rem;
  background-color: #ffffff;
  border: 2px solid var(--ion-color-light-gray);
  z-index: 2;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 14rem;

  .backdrop {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: var(--ion-color-role-grey);
    opacity: 0.5;
    margin-top: 4rem;
    z-index: 1;
  }
}

// Screens under 480px
@media screen and (max-width: 30rem) {
  .addskills-modal {
    width: 100%;
    left: 0px;

    .backdrop {
      display: none;
    }
  }
}

.btn-margin {
  margin-left: 1.25rem !important;
}
</style>
