<template>
  <div ref="parent">
    <v-col class="search-filter-child-container t-flex">
      <!-- we'll remove the v-if when the language filter is added back to the EditContent page -->
      <!--  v-if="filter.name !== 'Language'" -->
      <!-- we'll remove the v-if when the language filter is added back to the EditContent page -->

      <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
      <div class="t-relative t-flex-1" v-for="(filter, i) in filterValues.slice(0, 5).filter((data) => data.name !== 'Language' && data.name !== 'Content feature')" :key="filter.name">
        <v-col class="filter-cols" :style="filter.showExpandedFilter ? 'zIndex:1' : 'zIndex:2'">
          <div class="backdrop" v-if="filter.showExpandedFilter" @click="onBackdrop(i)"></div>
          <div class="filter">
            <button
              :class="filter.showExpandedFilter ? 't-border-black' : ''"
              class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black"
              :style="!catalogDisplay > 0 || filter.disabled || filter.values.length == 0 ? 'pointer-events: none' : ''"
              @click="openDropdown(filter, i)"
              @keydown.esc="onCancel(i)"
            >
              <span class="t-font-arial t-text-sm" :style="!catalogDisplay > 0 || filter.values.length == 0 || filter.disabled ? 'color:rgb(0, 0, 0, 0.2)' : ''">{{ sentenceCase($t(filter.translationKey)) }}</span>
              <v-icon :style="!catalogDisplay > 0 || filter.values.length == 0 || filter.disabled ? 'opacity:0.3' : ''">mdi-chevron-down</v-icon>
            </button>
          </div>

          <div class="filter-dropdown" v-if="filter.showExpandedFilter && filter.name === 'Language' && filter.values.length <= 5" @keydown.esc="onCancel(i)">
            <ul>
              <li v-for="(item, index) in filter.values" :key="index" style="max-height: 30px; display: flex; align-items: center" class="catalog-list-item">
                <v-checkbox :key="item.name" :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable" :label="filter.name == 'Language' ? item.display : item.name" dense :value="item.name" v-model="selectedFilters[i].values" style="display: inline-block" />
                <span class="filter-value-count" :class="item.count > 0 ? '' : 'disabled-count'">({{ numberWithCommas(item.count) }})</span>
              </li>
            </ul>
            <div class="filter-option-footer">
              <button class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</button>
              <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
            </div>
          </div>

          <div class="filter-dropdown" v-if="filter.showExpandedFilter && (filter.values.length <= 5 || filter.name == 'Duration') && filter.name !== 'Language'" @keydown.esc="onCancel(i)">
            <ul>
              <li v-for="(item, index) in filter.values" :key="index" style="max-height: 30px; display: flex; align-items: center" class="catalog-list-item">
                <v-checkbox :key="item.name" :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable" :label="filter.name == 'Provider' ? item.name : $t(item.translationKey)" dense :value="item.name" v-model="selectedFilters[i].values" style="display: inline-block" />
                <span class="filter-value-count" :class="item.count > 0 ? '' : 'disabled-count'">({{ numberWithCommas(item.count) }})</span>
              </li>
            </ul>
            <div class="filter-option-footer">
              <button class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</button>
              <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
            </div>
          </div>

          <div class="filter-dropdown" v-if="filter.showExpandedFilter && filter.values.length > 5 && filter.name != 'Duration'" @keydown.esc="onCancel(i)">
            <v-text-field
              prepend-inner-icon="mdi-magnify"
              :label="$t('common.filter.searchPrompt') + $t(filter.translationKey).toLocaleLowerCase()"
              v-model="queryFilter"
              @keyup="filterOptions($event, i)"
              rounded
              clearable
              single-line
              hide-details
              filled
              dense
              solo
              variant="solo"
            ></v-text-field>

            <div class="t-text-center" v-if="filteredOptions.length == 0">{{ $t('common.noMatchesFound') }}</div>
            <ul :class="filteredOptions.length > 5 ? 'scrollable' : ''">
              <li v-for="(item, index) in filteredOptions" :key="index" style="max-height: 1.875rem; display: flex; align-items: center" class="catalog-list-item">
                <v-checkbox :key="item.name" :disabled="{}.hasOwnProperty.call(item, 'disable') && !item.disable" :label="filter.name == 'Language' ? item.display : item.name" dense :value="item.name" v-model="selectedFilters[i].values" style="display: inline-block" />
                <span style="margin-left: 6px; margin-top: -1.4rem; font-weight: normal; font-size: 14px">({{ numberWithCommas(item.count) }})</span>
              </li>
            </ul>
            <div class="filter-option-footer">
              <div class="filter-option-footer-cancel" @click="onCancel(i)">{{ $t('button.cancel') }}</div>
              <v-btn color="#feb92e" class="t-rounded-full filter-option-footer-btn-apply" @click="onShow(i)">{{ $t('button.apply') }}</v-btn>
            </div>
          </div>
        </v-col>
      </div>
      <v-col class="filter-cols all-filters" style="z-index: 2">
        <div class="filter">
          <button :class="isFilterSideMenuActive ? 't-border-black' : ''" class="t-cursor-pointer filter-pill t-rounded-full t-flex t-justify-between hover:t-border-black" @click="toggleSideMenu">
            <span class="t-font-arial t-text-sm" :style="!catalogDisplay > 0 || Object.keys(filterResults).length === 0 ? 'color:rgb(0, 0, 0, 0.2)' : ''">{{ $t('common.titleCase.all') }}</span>
            <svg-icon :style="!catalogDisplay > 0 || Object.keys(filterResults).length === 0 ? 'opacity:0.3' : ''" icon-class="filters-icon" class-name="all-filters-icon" />
          </button>
        </div>
      </v-col>
    </v-col>
    <AllFiltersSideBar
      :filterValues="filterValues"
      :selectedFilters="selectedFilters"
      :isFilterSideMenuActive="isFilterSideMenuActive"
      :filteredOptions="filteredOptions"
      @toggle-side-menu-overlay="toggleSideMenuOverlay"
      @all-filters-toggle-side-menu="isFilterSideMenuActive = !isFilterSideMenuActive"
      @clear-side-filter="clearSideFilter"
      :totalResults="totalResults"
      :displaySelectedFilters="displaySelectedFilters"
    />
  </div>
</template>

<script>
import { mapDurations, numberWithCommas, sentenceCase } from '@/utils';
import en_json from '@/locales/en-us.json';
import AllFiltersSideBar from './AllFiltersSideBar';

export default {
  components: { AllFiltersSideBar },
  name: 'SearchFilter',
  emits: ['filter-applied', 'track-filters-interface'],
  props: {
    selectedEditFilters: {
      type: Array,
      required: true,
      default: () => {
        return [];
      }
    },
    filterResults: {
      type: Object,
      require: true,
      default: () => {
        return {};
      }
    },
    catalogDisplay: {
      type: Number,
      require: true,
      default: () => {
        return 0;
      }
    },
    totalResults: {
      type: Number,
      required: true,
      default: () => {
        return 0;
      }
    }
  },
  data() {
    return {
      mapDurations,
      queryFilter: '',
      filteredOptions: [],
      showExpandedFilter: false,
      values: [],
      showFilters: false,
      selectedFilters: [
        { name: 'Content Type', translationKey: 'common.filter.contentType', key: 'content_types', values: [] },
        { name: 'Skill', translationKey: 'common.filter.skill', key: 'skills', values: [] },
        { name: 'Provider', translationKey: 'common.filter.provider', key: 'publishers', values: [] },
        { name: 'Duration', translationKey: 'common.filter.duration', key: 'durations', values: [] },
        { name: 'Level', translationKey: 'common.filter.level', key: 'levels', values: [] },
        { name: 'Technology', translationKey: 'common.filter.technology', key: 'tools', values: [] },
        { name: 'Accreditation', translationKey: 'common.filter.accreditation', key: 'accreditations', values: [] },
        // the Language filter will need to be the 5th filter to be seen on the Edit Content page
        { name: 'Language', translationKey: 'common.filter.language', key: 'languages', values: [] },
        { name: 'Content feature', translationKey: 'common.filter.contentFeature', key: 'contentFeature', values: [] }
      ],
      displaySelectedFilters: [],
      filterValues: [
        {
          name: 'Content Type',
          translationKey: 'common.filter.contentType',
          values: [
            { name: 'Course', translationKey: 'common.filter.contentTypeOptions.course', disable: true },
            { name: 'Credential', translationKey: 'common.filter.contentTypeOptions.credential', disable: true },
            { name: 'Video', translationKey: 'common.filter.contentTypeOptions.video', disable: true },
            { name: 'Audio', translationKey: 'common.filter.contentTypeOptions.audio', disable: true },
            { name: 'Article', translationKey: 'common.filter.contentTypeOptions.article', disable: true }
          ],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false
        },
        { name: 'Skill', translationKey: 'common.filter.skill', values: [], showExpandedFilter: false, showAccordion: false, disabled: false },
        { name: 'Provider', translationKey: 'common.filter.provider', values: [], showExpandedFilter: false, showAccordion: false, disabled: false },
        {
          name: 'Duration',
          translationKey: 'common.filter.duration',
          values: [
            { name: '< 15 min', translationKey: 'common.filter.durationOptions.duration1', disable: true },
            { name: '15 - 60 min', translationKey: 'common.filter.durationOptions.duration2', disable: true },
            { name: '1 - 3 hr', translationKey: 'common.filter.durationOptions.duration3', disable: true },
            { name: '3 - 5 hr', translationKey: 'common.filter.durationOptions.duration4', disable: true },
            { name: '5 - 10 hr', translationKey: 'common.filter.durationOptions.duration5', disable: true },
            { name: '10+ hr', translationKey: 'common.filter.durationOptions.duration6', disable: true }
          ],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false
        },
        {
          name: 'Level',
          translationKey: 'common.filter.level',
          values: [
            { name: 'Beginner', translationKey: 'common.filter.levelOptions.beginner', disable: true },
            { name: 'Intermediate', translationKey: 'common.filter.levelOptions.intermediate', disable: true },
            { name: 'Advanced', translationKey: 'common.filter.levelOptions.advanced', disable: true }
          ],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false
        },
        { name: 'Technology', translationKey: 'common.filter.technology', values: [], showExpandedFilter: false, showAccordion: false, disabled: false },
        { name: 'Accreditation', translationKey: 'common.filter.accreditation', values: [], showExpandedFilter: false, showAccordion: false, disabled: false },
        // the Language filter will need to be the 5th filter to be seen on the Edit Content page
        { name: 'Language', translationKey: 'common.filter.language', values: [], showExpandedFilter: false, showAccordion: false, disabled: false },
        {
          name: 'Content feature',
          translationKey: 'common.filter.contentFeature',
          values: [],
          showExpandedFilter: false,
          showAccordion: false,
          disabled: false
        }
      ],
      isFilterSideMenuActive: false
    };
  },
  mounted() {
    this.EventBus.on('search-applied', this.clearFilters);
    this.EventBus.on('search-cleared', this.clearFilters);
    this.EventBus.on('show-filters', this.onShowFromAllFilters);
    this.EventBus.on('all-filters-cleared', this.clearFilters);
    this.setSelectedFilter();
    this.setFilterValues();
    this.emitInterface();
  },
  watch: {
    filterResults() {
      this.setFilterValues();
    }
  },
  methods: {
    sentenceCase,
    numberWithCommas,
    toggleSideMenu() {
      if (!this.catalogDisplay > 0 || Object.keys(this.filterResults).length === 0) return;
      this.filterValues.forEach((item) => {
        item.showExpandedFilter = false;
      });
      this.isFilterSideMenuActive = !this.isFilterSideMenuActive;
      if (this.isFilterSideMenuActive) {
        document.documentElement.style.overflow = 'hidden';
        const filterContainer = document.getElementsByClassName('filters-container')[0];
        filterContainer && (filterContainer.style['overflow-y'] = 'scroll');
      } else {
        document.documentElement.style.overflow = 'scroll';
      }
    },
    setSelectedFilter() {
      this.displaySelectedFilters = this.selectedEditFilters;
      this.selectedFilters.map(async (item, index) => {
        this.selectedEditFilters.some((selectedItem) => {
          let filterData = selectedItem.key == 'technologies' ? 'tools' : selectedItem.key;
          if (item.key == filterData) {
            this.selectedFilters[index].values.push(selectedItem.name);
          }
        });
      });
    },
    toggleSideMenuOverlay(val) {
      if (val) {
        document.documentElement.style.overflow = 'hidden';
        const filterContainer = document.getElementsByClassName('filters-container')[0];
        filterContainer && (filterContainer.style['overflow-y'] = 'scroll');
      } else {
        document.documentElement.style.overflow = 'scroll';
      }
      this.isFilterSideMenuActive = val;
    },
    onCancel(index) {
      this.filterValues[index].showExpandedFilter = !this.filterValues[index].showExpandedFilter;
      this.resetDropDownValues(index);
    },
    async onShow(index, toggle = true) {
      await this.applyFilters(index, toggle);
      this.$emit('filter-applied', this.selectedFilters, this.showFilters, this.displaySelectedFilters);
    },
    async onShowFromAllFilters(index, toggle = false) {
      await this.applyFilters(index, toggle);
      this.$emit('filter-applied', this.selectedFilters, this.showFilters, this.displaySelectedFilters);
    },
    onBackdrop(index) {
      this.queryFilter = '';
      this.filterValues[index].showExpandedFilter = false;
      this.resetDropDownValues(index);
    },
    resetDropDownValues(index) {
      /****
       * remove values from filter if clicked outside, but keep the ones already applied from displaySelected array
       */
      if (!this.displaySelectedFilters.length) {
        this.selectedFilters[index].values = [];
      } else {
        const doesKeyExistSelectedFilters = this.displaySelectedFilters.some((item) => {
          return item.key === this.selectedFilters[index].key;
        });
        if (doesKeyExistSelectedFilters) {
          const selectedArrayItems = [];
          this.displaySelectedFilters.filter((item) => {
            if (this.selectedFilters[index].key === item.key) {
              if (!this.selectedFilters[index].values.includes(item.name)) {
                this.selectedFilters[index].values.push(item.name);
                selectedArrayItems.push(item.name);
              } else {
                selectedArrayItems.push(item.name);
              }
            }
          });
          this.selectedFilters[index].values = selectedArrayItems;
        } else {
          this.selectedFilters[index].values = [];
        }
      }
    },
    openDropdown(filterItem, i) {
      this.filteredOptions = this.filterValues[i].values;
      this.filterValues.map((filter, index) => {
        // if previously any filter was open, then reset its value
        if (filter.showExpandedFilter) {
          this.resetDropDownValues(index);
        }
        if (filterItem.name === filter.name) {
          analytics.setVarAndTrack({ c: 'Filter type', a: 'click', l: filter.name });
          filter.showExpandedFilter = true;
        } else {
          filter.showExpandedFilter = false;
        }
      });
      this.$nextTick(() => {
        const dropdown = this.$refs.parent.querySelector('.filter-dropdown');
        const focusableElement = dropdown?.querySelector('input:not([disabled]),button:not([disabled])');
        focusableElement?.focus();
      });
    },
    filterOptions(event, i) {
      if (this.queryFilter.length == 0) this.filteredOptions = this.filterValues[i].values;
      else
        this.filteredOptions = this.filterValues[i].values.filter((ele) => {
          return ele.name.toLowerCase().includes(event.target.value.toLowerCase());
        });
    },
    async applyFilters(i, toggle = true) {
      if (i >= 0) {
        if (toggle) {
          this.filterValues[i].showExpandedFilter = !this.filterValues[i].showExpandedFilter;
        }

        /****
         * add value to displaySelectedFilter if new value has been checked from filter dropdown
         */
        let key = '';
        this.selectedFilters.map(async (item) => {
          if (item.name === this.filterValues[i].name) {
            key = item.key;
            item.values.forEach((filterItem) => {
              const obj = {};
              obj.key = key;
              obj.type = item.name;
              obj.name = filterItem;
              if (!this.displaySelectedFilters.some((selectedItem) => selectedItem.key == obj.key && selectedItem.type === obj.type && selectedItem.name === obj.name)) {
                this.displaySelectedFilters.push(obj);
              }
            });
            /***
             * find item which has been deselected from dropdown and remove it from displaySelectedFilter
             */
            const itemToBeRemoved = await this.displaySelectedFilters.filter((changeItem) => {
              if (changeItem.key === key) {
                if (!item.values.includes(changeItem.name)) {
                  return changeItem;
                }
              }
            });
            if (itemToBeRemoved.length) {
              this.displaySelectedFilters = this.displaySelectedFilters.filter(function (itemInDisplay) {
                return !itemToBeRemoved.find(function (removeItem) {
                  return itemInDisplay.name === removeItem.name && itemInDisplay.key === removeItem.key;
                });
              });
            }
            /****
             * clickstream update
             */
            const appliedFilters = {
              filters: item.values,
              count: item.values.length
            };
            analytics.setVarAndTrack({ c: item.name + ' filter', a: 'Apply filter', l: appliedFilters });
          }
        });
      }
      this.showFilters = this.selectedFilters.some((item) => {
        return item.values.length;
      });
    },
    async clearSideFilter(index) {
      this.selectedFilters[index].values = [];
      await this.applyFilters(index, false);
      this.$emit('filter-applied', this.selectedFilters, this.showFilters, this.displaySelectedFilters);
    },
    clearFilters() {
      this.selectedFilters.forEach((item) => {
        item.values = [];
        this.showFilters = false;
      });
      this.displaySelectedFilters = [];
      this.$emit('filter-applied', this.selectedFilters, this.showFilters, this.displaySelectedFilters);
      /**
       * clickstream update
       */
      const tempArry = [];
      this.displaySelectedFilters.forEach((item) => {
        tempArry.push(item.name);
      });
      analytics.setVarAndTrack({ c: 'Clear all filters', a: 'click', l: { clearedValues: tempArry } });
      this.displaySelectedFilters = [];
    },
    /****
     * removeFilter() -> Remove value from both selectedFilters and displaySelectedFilters pill
     */
    async removeFilter(item) {
      this.selectedFilters.forEach((filterItem) => {
        if (filterItem.name == item.type) {
          const index = filterItem.values.indexOf(item.name);
          filterItem.values.splice(index, 1);
        }
      });

      if (this.displaySelectedFilters.some((filter) => filter.name === item.name)) {
        const index = this.displaySelectedFilters.findIndex((selectedFilter) => selectedFilter.name === item.name && selectedFilter.type === item.type);
        /**
         * clickstream update
         */
        analytics.setVarAndTrack({ c: 'Clear single filter', a: 'click', l: item.name });
        this.displaySelectedFilters.splice(index, 1);
      }
      await this.applyFilters();
      this.$emit('filter-applied', this.selectedFilters, this.showFilters, this.displaySelectedFilters);
    },
    setFilterValues() {
      this.filterValues.forEach((ele) => {
        if (ele.name == 'Content Type') {
          ele.values.map((item) => {
            if ({}.hasOwnProperty.call(this.filterResults, 'typeFilterValues')) {
              item.disable = this.filterResults.typeFilterValues.some((i) => i.name.toLowerCase() == item.name.toLowerCase());
              item.count = this.filterResults.typeFilterValues.filter((filterItem) => item.name === filterItem.name)[0]?.count || 0;
            }
          });
        }
        if (ele.name == 'Duration') {
          ele.values.map((item) => {
            if ({}.hasOwnProperty.call(this.filterResults, 'durationFilterValues')) {
              item.disable = this.filterResults.durationFilterValues.some((i) => this.mapDurations[i.name.toLowerCase()] == item.name.toLowerCase());
              item.count = this.filterResults.durationFilterValues.filter((filterItem) => item.name.toLowerCase() === this.mapDurations[filterItem.name.toLowerCase()])[0]?.count || 0;
            }
          });
        }
        if (ele.name == 'Level') {
          ele.values.map((item) => {
            if ({}.hasOwnProperty.call(this.filterResults, 'levelFilterValues')) {
              item.disable = this.filterResults.levelFilterValues.some((i) => i.name.toLowerCase() == item.name.toLowerCase());
              item.count = this.filterResults.levelFilterValues.filter((filterItem) => item.name === filterItem.name)[0]?.count || 0;
            }
          });

          ele.disabled = Object.keys(this.filterResults).length === 0 || this.filterResults?.levelFilterValues?.length === 0;
        }
        if (ele.name == 'Provider') {
          const index = this.filterValues.findIndex((x) => x.name === 'Provider');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'providerFilterValues') ? this.filterResults.providerFilterValues : [];
        }
        if (ele.name == 'Skill') {
          const index = this.filterValues.findIndex((x) => x.name === 'Skill');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'skillFilterValues') ? this.filterResults.skillFilterValues : [];
        }
        if (ele.name == 'Accreditation') {
          const index = this.filterValues.findIndex((x) => x.name === 'Accreditation');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'accreditationFilterValues') ? this.filterResults.accreditationFilterValues : [];
        }
        if (ele.name == 'Technology') {
          const index = this.filterValues.findIndex((x) => x.name === 'Technology');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'toolFilterValues') ? this.filterResults.toolFilterValues : [];
        }
        if (ele.name == 'Language') {
          const index = this.filterValues.findIndex((x) => x.name === 'Language');
          this.filterValues[index].values = {}.hasOwnProperty.call(this.filterResults, 'languageFilterValues') ? this.filterResults.languageFilterValues : [];

          for (const language of this.filterValues[index].values) {
            language.display = en_json.languagePicker && en_json.languagePicker[language.name] ? en_json.languagePicker[language.name] : language.name;
          }
          this.filterValues[index].values.sort(function (a, b) {
            return b.count - a.count || a.display.localeCompare(b.display);
          });
        }
        if (ele.name == 'Content feature') {
          // const index = this.filterValues.findIndex((x) => x.name === 'Content feature')
          ele.values = {}.hasOwnProperty.call(this.filterResults, 'contentFeatureFilterValues') ? this.filterResults.contentFeatureFilterValues : [];
        }
      });
    },
    emitInterface() {
      this.$emit('track-filters-interface', {
        clearFilters: () => this.clearFilters(),
        removeFilter: (item) => this.removeFilter(item)
      });
    }
  }
};
</script>

<style lang="scss">
#chips-container .v-chip .v-chip__content {
  color: white;
}
#chips-container .v-chip .v-chip__close {
  color: white;
}

.filter {
  .theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    border: 2px solid black;
    background: none;
    margin-bottom: 0;
  }
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
  }
}

.search-filter-child-container {
  .filter-dropdown {
    .v-text-field {
      margin-top: 1.5rem;
    }
  }
}

.filter-dropdown {
  .catalog-list-item {
    .v-input {
      flex: unset;
      .v-input__control {
        padding-right: 0px;
      }
    }
    label {
      opacity: 1;
    }
  }
  .v-input--selection-controls .v-input__slot > .v-label,
  .v-input--selection-controls .v-radio > .v-label {
    font-size: 14px;
  }
  .v-input--selection-controls .v-input__slot > .v-label {
    color: $color-black;
  }
  .v-input--selection-controls .v-input__slot > .v-label.v-label--is-disabled {
    color: rgba(0, 0, 0, 0.2);
  }

  .v-input__control {
    padding: 0rem 1rem;
    .v-label {
      font-weight: normal;
      font-family: Arial;
      font-size: 0.875rem;
      color: black;
    }

    .v-field--variant-solo {
      background-color: white !important;
      height: 2.3125rem;
      border: 0.125rem solid #c4c4c4;

      input[type='text'] {
        padding-left: 1rem;
      }
    }

    .v-field__field,
    .v-field__prepend-inner {
      height: 2.3125rem;
    }

    .v-field__input {
      min-height: unset;
      padding: 0rem;
      height: inherit;
      height: 2.3125rem;
      input {
        margin: 0px;
      }
    }
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none;
  }
}

.filter-dropdown .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
  background: transparent;
  transform: none;
  transition: none;
}
.filter-dropdown .v-input--selection-controls__input .v-ripple__container > span {
  opacity: 0 !important;
}
.filter-dropdown .mdi-checkbox-blank-outline {
  color: #bcc2d4;
  font-size: 22px !important;
}
.filter-dropdown .mdi-checkbox-blank-outline.theme--light.v-icon:focus:after {
  opacity: 0 !important;
}
.filter-dropdown .v-input--is-disabled .v-input--selection-controls__input {
  opacity: 0.3;
}
.filter-option-footer-btn-apply {
  span {
    font-family: 'Futura PT Demi';
    font-weight: 500 !important;
    font-size: 14px !important;
  }
}

/* .search-container > .v-input {
  &:focus-within {
    outline: auto;
    outline-width: 2px;
  }
} */
</style>
<style lang="scss" scoped>
.scrollable {
  padding-right: 30px;
  max-height: 200px;
  overflow-y: scroll;
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #d8d8d8;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4c5c8d;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #4c5c8d;
  }
}
.filter-pill {
  padding: 0.3125rem 0.875rem;
  height: 2.25rem;
  white-space: nowrap;
  border: 0.125rem solid rgb(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    text-align: left;
    font: normal normal bold 0.875rem/1.875rem Arial;
    letter-spacing: -0.0088rem;
    color: #000000;
    opacity: 1;
    padding: 0rem 0.5rem;
  }
}
.filter-pill:hover {
  border: 2px solid rgb(0, 0, 0);
}
.mdi-chevron-down::before {
  color: $color-black;
}

.filter-dropdown {
  width: max-content;
  background-color: $color-skill-table !important;
  border-radius: 20px;
  box-shadow: 2px 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 5px 5px 0 rgb(0 0 0 / 12%);
  position: absolute;
  z-index: 2 !important;
}

.filter-dropdown ul {
  padding: 1.875rem 1.875rem 0.3125rem 0rem;
  list-style: none;
}
.filter-dropdown ul.scrollable {
  margin-top: 1rem;
  margin-right: 1.25rem;
}
.backdrop {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: none;
  z-index: 1;
  margin-top: 5rem;
}
.filter-option-footer {
  display: flex;
  gap: 3rem;
  padding: 0.825rem 2rem 0.875rem 2rem;
  align-items: center;
  border-top: 0.0625rem solid #e0e1e1;
}
.filter-option-footer-cancel {
  font-family: 'Futura PT Demi';
  font-size: 1rem;
  color: #1f3370;
  cursor: pointer;
}
.filter-option-footer-btn-apply {
  cursor: pointer;
  text-transform: none;
  width: 5.75rem;
  box-shadow: none;
}

.filter-cols {
  padding-right: 0.9375rem;
  padding-left: 0;
}
.search-filter-child-container {
  padding: 0 0.575rem;

  .filter-value-count {
    margin-left: 0.375rem;
    margin-top: -1.4rem;
    font-weight: normal;
    font-size: 0.875rem;
  }
  .disabled-count {
    color: rgba(0, 0, 0, 0.38);
  }
}

.all-filters-icon {
  margin: 0.15rem 0.5rem 0rem 0rem;
}

@media screen and (max-width: 1240px) {
  .search-filter-child-container .filter-cols.col {
    display: none;
  }
  .search-filter-child-container .filter-cols.col:nth-last-child(1) {
    display: block;
    .filter {
      width: 10rem;
    }
  }
}
</style>
