export default {
  bind(el, binding, vueInstance) {
    el.addEventListener('click', () => {
      const { value } = binding
      let trackObj = value
      if (typeof value === 'string') {
        const values = value.replace(' ', '').split(',')
        trackObj = { c: values[0], l: values[1], a: values[2] }
        vueInstance.context.$gtag.event(values[0], {
          event_category: values[2],
          event_label: values[1],
          value: 1
        })
      }

      analytics.setVarAndTrack(trackObj)
    })
  }
}
