<template>
  <div v-if="dataReady" class="container-fluid track-listing-container">
    <div class="track-listing-header">
      <div class="header-actions">
        <div>
          <h2 class="t-text-4xl t-font-futura-pt-demi t-font-normal t-text-primary-color">{{ $t('catalog.catalogHeader') }}</h2>
        </div>
        <v-btn data-el="editRole" rounded dark color="#FFB92E" class="create-track action-btn black--text" @click="onCreateTrack">{{ $t('trackListing.create') }}</v-btn>
      </div>
    </div>

    <CatalogDialog v-if="openCatalogModal" :catalogItem="cccInFocus" :index="0" @add-track-item="() => {}" @close-dialog="openCatalogModal = false" @open-dialog="openCatalogModal = true" :dialog="openCatalogModal" @edit-content="manageLearningTrack" @toast="openToast" />

    <!-- Hide for phase one -->
    <div class="catalog-search-filter-container">
      <div class="catalog-search-container">
        <v-text-field
          id="catalog-autocomplete-input"
          class="search-text-field"
          @click:clear="handleSearchClear"
          @keypress="validateInput($event)"
          @keypress.enter="searchLaunchedTracks"
          @keyup="handleAutocomplete"
          prepend-inner-icon="mdi-magnify"
          :label="$t('catalog.searchTextFieldBlurb')"
          :model-value="searchText"
          rounded
          clearable
          single-line
          hide-details
          variant="solo"
        >
        </v-text-field>
        <v-card class="t-max-w-md t-mt-0 t-ml-2 t-rounded-xl t-absolute t-z-10 t-top-10 t-pt-3" v-if="showAutocomplete" id="catalog-autocomplete-list">
          <ul class="t-list-none t-px-0">
            <li @click="setSearchText(item.queryText)" class="t-px-6 t-py-2 t-cursor-pointer hover:t-text-primary-color hover:t-bg-indigo-100" v-for="(item, index) in autoCompleteList" :tabindex="index + 1" :key="index">
              <div class="catalog-search-autocomplete-list-item"><v-icon style="color: #1f3370">mdi-magnify</v-icon> <span class="t-text-base t-ml-2 t-font-arial" v-html="item.text"></span></div>
            </li>
          </ul>
          <p v-if="autoCompleteList.length > 0 && searchText" class="autocomplete-see-all t-text-primary-color" @click="setSearchText(searchText)">{{ $t($sanitize('common.seeAllResultsFor')) }} "{{ $sanitize(searchText) }}"</p>
        </v-card>
      </div>
      <CatalogSearchFilter @catalog-filter-applied="applyFilters" :totalResults="totalResults" :filterResults="filterResults" />
    </div>

    <div class="catalog-details-search-chips" v-show="displaySelectedFilters.length || totalResults">
      <div class="catalog-details-search-total-results">{{ numberWithCommas(totalResults) }} {{ $tc('common.result', totalResults) }}</div>
      <div v-show="displaySelectedFilters.length">
        <span v-for="(item, index) in displaySelectedFilters" :key="index + item.name" id="chips-container">
          <v-chip in class="chip t-text-xs ma-2 t-bg-black" closable @click:close="onRemoveFilter(item)"> {{ item.type === 'Language' || item.type === 'Content Type' || item.type === 'Level' || item.type === 'Duration' ? item.display : item.name }} </v-chip>
        </span>
        <span class="clear-all-filters" @click="onClearFilters">{{ $t('common.clearAllFilters') }}</span>
      </div>
    </div>

    <v-data-table-server
      v-if="tableDataReady"
      :page="pageNum"
      :headers="headers"
      :items="tableData"
      :items-per-page-options="pageSizeOption"
      :items-per-page="pageSize"
      :items-length="totalResults"
      :sort-by="sortBy"
      hide-default-footer
      @update:options="loadItems"
      :loading="loading"
    >
      <template v-slot:[`header.assignedAs`]="{ column }">
        <span>{{ column.title }}</span>
        <svg-icon icon-class="info-icon" class="info-icon" />
        <span class="info-tooltip">{{ column.infoMsg }}</span>
      </template>

      <template v-slot:[`header.assignedTo`]="{ column }">
        <span>{{ column.title }}</span>
        <svg-icon icon-class="info-icon" class="info-icon" />
        <span class="info-tooltip">{{ column.infoMsg }}</span>
      </template>

      <template v-slot:[`header.trackItems`]="{ column }">
        <span>{{ column.title }}</span>
        <svg-icon icon-class="info-icon" class="info-icon" />
        <span class="info-tooltip">{{ column.infoMsg }}</span>
      </template>

      <template v-slot:[`item.formatType`]="{ item }">
        <span>{{ getLocalizedContentType(item.formatType) }}</span>
      </template>

      <template v-slot:[`item.title`]="{ item }">
        <span class="t-font-arial t-text-xxs mt-4 t-cursor-pointer t-text-primary-color" @click="handleOpenCatalogModal(item)">{{ item.title }}</span>
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        <span>{{ formatDuration(item.duration) }}</span>
      </template>

      <template v-slot:[`item.level`]="{ item }">
        <span v-if="item.level === 'N/A'">{{ $t('common.notApplicable') }}</span>
        <span v-else>{{ getLocalizedLevel(item.level) }}</span>
      </template>

      <template v-slot:[`item.rating`]="{ item }">
        <v-menu bottom right>
          <template v-slot:activator="{ props }">
            <!-- Patrick will talk to Kamal to hide this or not  -->
            <div class="table-ratings-container">
              <!-- <div v-if="item.rating" class="table-ratings-container"> -->
              <span v-if="!item.rating">{{ $t('common.noRating') }}</span>
              <span v-else>
                <span>{{ item.rating }}</span>
                <v-icon v-bind="props" style="color: #b98032" size="16">mdi-star</v-icon>
              </span>
            </div>
          </template>
        </v-menu>
      </template>

      <template v-slot:footer><div class="table-footer-spacer"></div></template>
    </v-data-table-server>

    <div v-else class="loader t-flex t-justify-center t-items-center card">
      <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" :alt="$t('catalog.loadingCatalogTable')" />
    </div>
  </div>

  <div v-else class="loader t-flex t-justify-center t-items-center">
    <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" :alt="$t('common.titleCase.loadingLearningTrack')" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { formatDuration, numberWithCommas, mapDurations, getLocalizedContentType, getLocalizedLevel } from '@/utils';
import CatalogSearchFilter from '@/views/catalog/components/CatalogSearchFilter';
import CatalogDialog from './components/CatalogDialog.vue';
import en_json from '@/locales/en-us.json';
import { translate, translateTC } from '@/plugins/i18n.js';
import localforage from 'localforage';
const initialState = () => ({
  dataReady: false,
  tableDataReady: false,
  tableData: [],
  tableDataProxy: [],
  tabIndex: 0,
  searchText: '',
  numberOfLaunchedTracks: 0,
  numberOfDrafedTracks: 0,
  totalNumberOfLaunchedTracks: 0,
  totalNumberOfDrafedTracks: 0,
  headers: [],
  sortBy: [],
  currentPage: 1,
  pageRange: 6,
  totalNumOfPages: 0,
  pageNum: 1,
  pageSize: 25,
  pageSizeOption: [
    { value: 10, title: '10' },
    { value: 25, title: '25' },
    { value: 50, title: '50' },
    { value: 100, title: '100' }
  ],
  filterResults: {},
  totalResults: 0,
  displaySelectedFilters: [],
  showAutocomplete: false,
  autoCompleteList: [],
  openCatalogModal: false,
  cccInFocus: null,
  isC1: false,
  loading: false
});

export default {
  name: 'CatalogLanding',
  components: { CatalogSearchFilter, CatalogDialog },
  data() {
    return initialState();
  },
  watch: {
    '$i18n.locale'() {
      this.setHeaders();
    }
  },
  computed: { ...mapGetters(['tenantAccessTags', 'client_category']), ...mapState('user', ['tenant_guid']) },
  async activated() {
    Object.assign(this.$data, initialState());
    this.resetTrackDetails();
    this.setReCreateTrack(false);
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
    this.setHeaders();
    await this.getFilterData();
    await this.setSaveExitButton(false);
  },
  filters: {
    numberWithCommas(val) {
      return numberWithCommas(val);
    },
    formatTime(time) {
      return formatDuration(time);
    }
  },
  methods: {
    ...mapActions('track', ['setRoleGuid', 'setReCreateTrack', 'resetTrackDetails', 'setRoleNameTrack', 'setContentId', 'setContentTitle', 'setContentFormat', 'setTrackGuid', 'setSaveExitButton', 'clearTrackData', 'setTotalTrackItems']),
    ...mapActions('stepper', ['clearStepperState']),
    getLocalizedContentType,
    getLocalizedLevel,
    numberWithCommas,
    formatDuration,
    setHeaders() {
      this.$data.headers = [
        { title: '', width: '0.625rem', sortable: false },
        { title: translate('common.filter.contentType'), key: 'formatType', align: 'start' },
        { title: translateTC('common.titleCase.title'), key: 'title', width: '25%', align: 'start' },
        { title: translate('common.filter.provider'), key: 'provider', align: 'start' },
        {
          title: translate('common.filter.duration'),
          key: 'duration',
          align: 'start'
        },
        {
          title: translate('common.filter.level'),
          key: 'level',
          align: 'start'
        },
        { title: translateTC('common.titleCase.trackItems'), key: 'trackItems', align: 'start', infoMsg: translate('catalog.trackItemsInfoMsg') },
        { title: translateTC('common.titleCase.assignedTo'), key: 'assignedTo', align: 'start', infoMsg: translate('catalog.assignedToInfoMsg') },
        {
          title: translateTC('common.titleCase.rating'),
          key: 'rating',
          align: 'start',
          sortable: false
        },
        { title: '', width: '0.625rem', sortable: false }
      ];
      if (!this.isC1) {
        this.$data.headers.splice(3, 0, { title: translateTC('common.titleCase.assignedAs'), key: 'assignedAs', align: 'start', infoMsg: translate('catalog.assignedAsInfoMsg') });
      }
    },
    openToast() {
      this.$toasted.show('succcess', {
        position: 'bottom-center',
        duration: 2000
      });
    },
    async getFilterData() {
      try {
        this.loading = true;
        // we may need to add this back
        // this.searchText = this.searchText.replace(/[^0-9a-z]/gi, ' ').trim()
        this.searchText = this.searchText.trim();

        const durations = [];
        const publishers = [];
        const levels = [];
        const accreditations = [];
        const skills = [];
        const content_types = [];
        const tools = [];
        const languages = [];
        const contentFeature = [];
        const assignedAs = [];

        for (let filter of this.displaySelectedFilters) {
          switch (filter.key) {
            case 'durations':
              durations.push(Object.keys(mapDurations).find((key) => mapDurations[key] === filter.name));
              break;
            case 'publishers':
              publishers.push(filter.name);
              break;
            case 'levels':
              levels.push(filter.name);
              break;
            case 'accreditations':
              accreditations.push(filter.name);
              break;
            case 'skills':
              skills.push(filter.name);
              break;
            case 'content_types':
              content_types.push(filter.name);
              break;
            case 'tools':
              tools.push(filter.name);
              break;
            case 'languages':
              languages.push(filter.name);
              break;
            case 'contentFeature':
              contentFeature.push(filter.name);
              break;
            case 'assignedAs':
              assignedAs.push(filter.name);
              break;
            default:
              break;
          }
        }

        const payload = {
          accessTags: this.tenantAccessTags,
          pageno: this.currentPage,
          pagesize: this.pageSize,
          query: encodeURIComponent(this.searchText),
          durations,
          publishers,
          levels,
          content_types,
          accreditations,
          skills,
          tools,
          languages,
          contentFeature,
          assignedAs,
          sortby: 'newest'
        };

        const response = await this.$planAdmin.searchCCCTPlan(payload);

        this.filterResults = response.filterValuesMap;
        this.totalResults = response.totalResults;

        this.tableData = response.results.map((data) => this.transformData(data));
        this.tableDataProxy = this.tableData;
        this.totalNumOfPages = response.totalPages || 0;
        this.dataReady = true;
        this.tableDataReady = true;
        this.loading = false;
        this.$nextTick(async function () {
          await this.setupPagination(true);
        });
      } catch (error) {
        console.log(error);
      }
    },
    validateInput() {
      return true;
      // keeping this here - we may need it in the future
      // const char = String.fromCharCode(e.keyCode)
      // if (/^[ A-Za-z0-9\r&%+-]*$/.test(char)) return true
      // else e.preventDefault()
    },
    manageLearningTrack(path, catalogItem) {
      /*****
       * code logic will need to be updated here for railkebobs
       */
      if (catalogItem.formatType.toLowerCase() === 'track') {
        this.setRoleNameTrack(catalogItem.roleName);
        this.setRoleGuid(catalogItem.roleGuid);
        this.setTrackGuid(catalogItem.trackGuid);
        this.setTotalTrackItems(catalogItem.totalItemsInTrack);
        this.setContentFormat(catalogItem.formatType);
        const urlToPush = `/catalog/stepper/${path}`;
        const route = path === 'create' ? { name: 'CreateTrack', params: { createTrack: true } } : { path: urlToPush };
        this.$router.push(route);
      } else {
        this.setContentId(catalogItem.id);
        this.setContentTitle(catalogItem.title);
        this.setContentFormat(catalogItem.formatType);
        const urlToPush = `/catalog/stepper/${path}`;
        this.$router.push({ path: urlToPush });
      }
    },
    onCreateTrack() {
      this.setTrackGuid('');
      this.setReCreateTrack(false);
      this.resetTrackDetails();
      /***
       * params createTrack needed to handle back button scenarios when clicking outside track flow.
       * usage in beforeRouteEnter for EditDetails.vue
       * */
      this.$router.push({ name: 'CreateTrack', params: { createTrack: true } });
    },
    async handlePageClick(page) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.currentPage = page;
      this.pageNum = page;
      await this.getFilterData();
    },
    async setupPagination() {
      if (this.tableData.length) {
        const elements = document.getElementsByClassName('pagination');

        if (elements.length && elements[0].childNodes) {
          elements[0].childNodes.forEach((element) => {
            if (element.childNodes.length && element.className.includes('pagination')) {
              const pageNumber = element.childNodes[0].innerHTML;

              if (element.className.includes('pagination-nav')) {
                if (pageNumber === '‹') {
                  element.setAttribute('data-tooltip-content', `Go to previous page`);
                  element.setAttribute('aria-label', `Go to previous page`);
                } else {
                  element.setAttribute('data-tooltip-content', `Go to next page`);
                  element.setAttribute('aria-label', `Go to next page`);
                }
              }
              if (element.className.includes('pagination-item active')) {
                element.setAttribute('aria-current', `page`);
              } else {
                element.setAttribute('aria-current', `false`);
                if (element.className.includes('pagination-item') && !isNaN(pageNumber)) {
                  element.setAttribute('aria-label', `page ${pageNumber}`);
                }
              }
            }
          });
        }
      }
    },
    async handleSearchClear() {
      this.pageNum = 1;
      this.searchText = '';
      this.currentPage = 1;
      this.autoCompleteList = [];
      this.sortBy = [];
      this.showAutocomplete = false;
      await this.getFilterData();
      await this.setupPagination(true);
    },
    scrollList() {
      const first = document.getElementById('catalog-autocomplete-list')?.firstChild;
      const maininput = document.getElementById('catalog-autocomplete-input');

      document.onkeydown = function (e) {
        switch (e.key) {
          case 'Enter':
            document.activeElement?.click();
            break;
          case 'ArrowUp': // if the UP key on the keyboard is pressed
            if (document.activeElement == (maininput || first)) {
              break;
            } else {
              maininput.value = document.activeElement?.previousSibling?.textContent ? document.activeElement?.previousSibling?.textContent : document.activeElement.textContent;
              document?.activeElement?.previousSibling?.focus();
            }
            break;
          case 'ArrowDown': {
            // if the DOWN key on the keyboard is pressed
            e.preventDefault();
            if (document.activeElement == maininput) {
              first.firstChild.focus();
            } else {
              maininput.value = document.activeElement?.nextSibling?.textContent ? document.activeElement?.nextSibling?.textContent : document.activeElement.textContent;
              document.activeElement?.nextSibling?.focus();
            }
            break;
          }
        }
      };
    },
    async handleAutocomplete(event) {
      // not using v-model="searchText" for v-text-field
      // because this.searchText becomes null on clear - causing issues

      if (this.searchText.length && !event.target.value.length) {
        this.handleSearchClear();
      }

      if (event.target.value) this.searchText = event.target.value;
      if (this.enabledFeatureFlags && !this.enabledFeatureFlags['plan_autocomplete_enabled']) return;
      if (event.key === 'Enter') {
        this.showAutocomplete = false;
        this.autoCompleteList = [];
        return;
      }
      this.showAutocomplete = true;
      const query = event.target && event.target.value ? event.target.value.replace(/[^0-9a-z]/gi, ' ').trim() : '';
      const response = await this.$planAdmin.searchAutocomplete({ query: encodeURIComponent(query), accessTags: this.tenantAccessTags });

      const regex = new RegExp(this.searchText.replace('|', ''), 'ig');
      const mask = `<span style="font-size:1rem; font-weight:normal; font-family: 'Arial'; color: #5C5C5C;">${this.searchText.replace('|', '')}</span>`;
      response.forEach((text) => {
        text.queryText = text.text;
        text.text = text.text.replace(regex, mask);
      });

      this.scrollList();
      this.autoCompleteList = response;
    },
    async setSearchText(text) {
      this.searchText = text;
      this.autoCompleteList = [];
      this.showAutocomplete = false;
      await this.getFilterData();
    },
    async searchLaunchedTracks(e) {
      !e.target.value.length && (await this.getFilterData());
      if (e.key !== 'Enter') return;
      this.pageNum = 1;
      this.sortBy = [];
      this.currentPage = 1;
      await this.getFilterData();
    },
    async applyFilters(displaySelectedFilters) {
      this.displaySelectedFilters = displaySelectedFilters.map((filter) => (filter.type === 'Language' ? { ...filter, display: en_json.languagePicker && en_json.languagePicker[filter.name] ? en_json.languagePicker[filter.name] : filter.name } : filter));
      this.currentPage = 1;
      await this.getFilterData();
      this.tableDataReady = true;
    },
    onRemoveFilter(item) {
      this.EventBus.emit('catalog-remove-filter', item);
    },
    onClearFilters() {
      this.pageNum = 1;
      this.EventBus.emit('catalog-search-cleared');
    },
    handleOpenCatalogModal(item) {
      this.cccInFocus = {
        ...item
      };
      this.openCatalogModal = true;
    },
    transformData(data) {
      let Obj = {};
      /*****
       * we need to get the format name credential from backend, once the api has that,
       * we will remove the following to give formatType Credential by default
       */

      Obj.id = data.id;
      Obj.formatType = data.format && data.format.name ? data.format.name : 'credential';
      Obj.title = data?.name || data?.title || '';
      Obj.synopsis = data.synopsis ? data.synopsis : '';
      Obj.duration = data.duration;
      Obj.durationMinutes = data.durationMinutes ? data.durationMinutes : 0;
      Obj.publishedAt = data.publishedAt;
      Obj.skills = data.emsiSkills ? data.emsiSkills && data.emsiSkills.join(', ') : '';
      Obj.accreditation = data.accreditation ? data.accreditation : [];
      Obj.fieldOfStudy = data.fieldOfStudy ? data.fieldOfStudy.join(', ') : [];
      Obj.subCategory = data.subCategory ? data.subCategory : '';
      Obj.vdiRequired = data.vdiEnabled !== null ? data.vdiEnabled : '';
      Obj.skillBuilder = data.content && data.content.skillBuilders ? data.content.skillBuilders : '';
      Obj.capstone = data.content && data.content.capstone ? data.content.capstone : '';
      Obj.provider = data?.provider?.name || 'N/A';
      Obj.level = data?.levels && data?.levels?.length && data?.levels[0]?.length ? data?.levels[0] : 'N/A';
      Obj.rating = data?.ratingReviews?.avgRating || 0;
      Obj.numOfRatings = data?.ratingReviews?.numOfRatings || 0;
      Obj.trackGuid = data.guid || '';
      Obj.totalItemsInTrack = data.totalItemsInTrack || 0;
      Obj.roleGuid = data.roleGuid;
      Obj.roleName = data.roleName;
      Obj.isRetiring = data.isRetiring || false;
      Obj.retirementDate = data.retirementDate || '';
      Obj.contentFeature = data.contentFeature ? data.contentFeature : [];
      Obj.assignedAs = Obj.formatType.toLowerCase() === 'track' && data.stat && data.stat.assignedAs ? data.stat.assignedAs : 'N/A';
      Obj.assignedTo = Obj.formatType.toLowerCase() === 'track' && data.stat ? data.stat.learnersCount : 'N/A';
      Obj.trackItems = Obj.formatType.toLowerCase() === 'track' && data.totalItemsInTrack >= 0 ? data.totalItemsInTrack : 'N/A';

      return Obj;
    },
    async onPageSizeChange(size) {
      this.pageSize = size;
      this.pageNum = 1;
      this.handlePageClick(this.pageNum);
    },
    loadItems(tableData) {
      const { page, itemsPerPage, sortBy } = tableData;

      if (this.pageSize !== itemsPerPage) {
        this.onPageSizeChange(itemsPerPage);
      }

      if (this.pageNum !== page) {
        this.handlePageClick(page);
      }

      if (this.sortBy !== sortBy) {
        this.sortBy = sortBy;
        if (sortBy.length) {
          this.tableData = this.lodash.orderBy(this.tableData, [sortBy[0].key], [sortBy[0].order]);
        } else {
          this.tableData = this.tableDataProxy;
        }
      }
    }
  },
  metaInfo() {
    return {
      title: translate('pageTitles.catalogLanding')
    };
  }
};
</script>

<style lang="scss">
.wrapper {
  .track-listing-container {
    max-width: 90rem;
    padding: 0rem 3rem;
    margin: 0 auto;

    .track-listing-header {
      display: grid;
      grid-auto-rows: 1fr 2fr;

      .header-actions {
        display: flex;
        justify-content: space-between;
        margin: 3.75rem 0 1.5rem;
        padding: 0rem 2rem 0rem 0rem;

        .create-track {
          .v-btn__content {
            font-family: 'Futura PT Demi';
          }
        }
        .create-track:focus-visible {
          outline: black 2px solid;
          outline-offset: 1px;
        }
        .action-btn {
          box-shadow: none !important;
        }
      }
    }

    .catalog-search-filter-container {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .catalog-search-container {
        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot,
        .v-btn--is-elevated {
          box-shadow: none !important;
        }
        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot,
        .v-btn--is-elevated {
          box-shadow: none !important;
        }

        .search-text-field .v-input__slot {
          min-height: 2.625rem !important;
          width: 27.188rem;
        }
        .search-text-field .mdi-magnify {
          font-size: 1.725rem;
          margin-right: 0.725rem;
        }

        .search-text-field {
          min-height: 2.625rem !important;
          width: 27.188rem;
          font-size: 1.725rem;

          .v-field--variant-solo {
            background-color: white !important;
            height: 2.625rem;
          }
          .v-field__field,
          .v-field__prepend-inner {
            height: 2.625rem;
          }

          .v-field__input {
            padding-top: 0rem !important;
          }
        }
        .v-autocomplete__content.v-menu__content {
          border-radius: 1.25rem;
          background-color: $color-grey !important;
        }
        .autocomplete-see-all {
          font-family: 'Arial';
          font-weight: bold;
          font-size: 1rem;
          border-top: solid;
          border-top-color: rgb(0, 0, 0, 0.2);
          padding: 0.625rem 1.25rem;
          cursor: pointer;
        }

        .catalog-search-autocomplete-list-item {
          padding-left: 2em;
          text-indent: -2em;
          span {
            font-weight: bold;
          }
        }
        position: relative;
      }
    }

    .catalog-search-filter-container {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // grid-template-columns: 10fr 2fr;
      .catalog-search-container {
        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot,
        .v-btn--is-elevated {
          box-shadow: none !important;
        }
        .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot,
        .v-btn--is-elevated {
          box-shadow: none !important;
        }

        .search-text-field .v-input__slot {
          min-height: 2.625rem !important;
          width: 27.188rem;
        }
        .search-text-field .mdi-magnify {
          font-size: 1.725rem;
          margin-right: 0.725rem;
        }

        .search-text-field {
          min-height: 2.625rem !important;
          width: 27.188rem;
          font-size: 1.725rem;

          .v-field--variant-solo {
            background-color: white !important;
            height: 2.625rem;
          }
          .v-field__field,
          .v-field__prepend-inner {
            height: 2.625rem;
          }

          .v-field__input {
            padding-top: 0rem !important;
          }
        }
        .v-autocomplete__content.v-menu__content {
          border-radius: 1.25rem;
          background-color: $color-grey !important;
        }
        .autocomplete-see-all {
          font-family: 'Arial';
          font-weight: bold;
          font-size: 1rem;
          border-top: solid;
          border-top-color: rgb(0, 0, 0, 0.2);
          padding: 0.625rem 1.25rem;
          cursor: pointer;
        }

        .catalog-search-autocomplete-list-item {
          padding-left: 2em;
          text-indent: -2em;
          span {
            font-weight: bold;
          }
        }
        position: relative;
      }
    }

    .catalog-details-search-chips {
      padding: 0 0.5rem 1.5rem;
      display: flex;
      align-items: center;
      flex-direction: row;
      padding: 0rem 1rem;
      margin-bottom: 1rem;
      min-height: 3rem;
      .catalog-details-search-total-results {
        margin-right: 0.5rem;
      }

      .clear-all-filters {
        text-align: left;
        font: normal normal normal 1rem/1.875rem Arial;
        letter-spacing: -0.0069rem;
        color: #1f3370;
        opacity: 1;
        cursor: pointer;
        display: inline-block;
      }
    }

    .v-data-table {
      border-radius: 1.563rem;
      thead {
        background-color: #cecece;
        tr {
          background-color: #cecece;
        }
        span {
          color: #000 !important;
          font: normal normal bold 12px source sans;
          letter-spacing: 0rem;
        }
      }
      .v-table__wrapper {
        border-radius: 0rem;
      }
      .v-table__wrapper > table {
        border-collapse: separate;

        thead > tr > th {
          border-bottom: none !important;
          height: 2.5rem;
          background-color: #cecece;
          position: relative;
          .info-icon {
            width: 22px;
            height: 22px;
            vertical-align: middle;
            margin-left: 8px;
          }
          .info-tooltip {
            visibility: hidden;
            text-align: center;
            padding: 8px 16px;
            position: absolute;
            z-index: 1;
            background: #ffffff 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 6px #0000001f;
            border-radius: 4px;
            opacity: 1;
            color: #2d2d2d !important;
            top: 90%;
            font: normal normal normal 12px source sans;
            right: 25%;
            width: max-content;
          }

          .info-icon:hover ~ .info-tooltip {
            visibility: visible;
          }

          &:first-child {
            padding: 0rem !important;
            .v-data-table__td {
              padding: 0rem !important;
            }
          }
          &:nth-child(2) {
            padding-left: 0rem !important;
            .text-start {
              padding-left: 0rem !important;
            }
          }
          &:last-child {
            padding: 0rem !important;
            .v-data-table__td {
              padding: 0rem !important;
            }
          }
          @media screen and (max-width: 1264px) {
            &:nth-child(8) {
              padding-right: 0rem !important;
            }
            &:nth-child(9) {
              display: none;
            }
            &:nth-child(10) {
              display: none;
            }
          }
          @media screen and (max-width: 960px) {
            &:nth-child(6) {
              padding-right: 0rem !important;
            }
            &:nth-child(7) {
              display: none;
            }
            &:nth-child(8) {
              display: none;
            }
          }
        }
        tbody {
          tr {
            td {
              font: normal normal normal 12px source sans;
              letter-spacing: 0rem;
              color: #262626;
              max-width: 0rem;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              &:first-child {
                border-bottom: none !important;
              }

              &:nth-child(2) {
                padding-left: 0rem !important;
                .text-start {
                  padding-left: 0rem !important;
                }
              }

              &:last-child {
                border-bottom: none !important;
              }

              @media screen and (max-width: 1264px) {
                &:nth-child(8) {
                  padding-right: 0rem !important;
                }
                &:nth-child(9) {
                  display: none;
                }
                &:nth-child(10) {
                  display: none;
                }
              }

              @media screen and (max-width: 960px) {
                &:nth-child(6) {
                  padding-right: 0rem !important;
                }
                &:nth-child(7) {
                  display: none;
                }
                &:nth-child(8) {
                  display: none;
                }
              }

              .table-ratings-container {
                display: flex;
                align-items: center;
                button {
                  margin-left: 0.25rem;
                }
              }
            }
          }
        }
      }

      td,
      tr {
        border: none;
      }
      .v-data-table-footer {
        padding: 2rem 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 2.5rem;
      }
    }
  }

  .table-footer-spacer {
    height: 4rem;
  }

  .tracklist-pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 4.375rem 2.875rem;
    position: relative;
    height: 8.25rem;

    .pagination {
      display: flex;
      justify-content: center;
      list-style-type: none;
      padding: 0rem;
      width: 50%;
    }

    .pagination-item {
      margin: 0 0.313rem;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    li.pagination-nav {
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .pagination-item-link {
      color: var(--ion-color-post-gray);
      font-size: 0.875rem !important;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .pagination-nav-link {
      position: relative;
      color: #5c5c5c;
      font-size: 3rem !important;
      vertical-align: middle;
      font-family: 'Futura PT Book';
      width: inherit;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.9375rem;
    }

    li.disabled {
      border: none;
      cursor: default;
      .pagination-item-link {
        cursor: default;
      }
    }

    li.active {
      border: 0.125rem solid #1dd1b0;
      border-radius: 0.25rem;
      background: white;
      .pagination-item-link {
        color: #000000;
      }
    }

    li:hover {
      background: #e0e1e1;
    }

    li .tooltiptext {
      visibility: hidden;
      width: max-content;
      height: 1.875rem;
      background-color: #efefef;
      color: #000000de;
      text-align: center;
      padding: 0.4375rem 0.625rem;
      border-radius: 0.25rem;
      position: absolute;
      z-index: 1;
      bottom: 90%;
      left: 50%;
      margin-left: -1.25rem;
      font-size: 0.75rem;
      font-family: 'Futura PT Demi';
    }

    .li:hover .tooltiptext {
      visibility: visible;
    }

    .pagination-tooltip {
      position: relative;
    }

    .pagination-tooltip::after {
      content: attr(data-tooltip-content);
      padding: 0.5rem;
      border-radius: 0.25rem;
      position: absolute;
      color: white;
      bottom: 135%;
      right: 50%;
      left: 50%;
      width: max-content;
      opacity: 0;
      font-size: 0.8rem;
      visibility: hidden;
      transform: translate(-50%, 1.125rem) scale(0.8);
      transition: visibility, opacity, transform 250ms;
      text-align: left;
      font: normal normal normal 0.75rem/1rem Arial;
      letter-spacing: 0rem;
      color: #000000de;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0rem 0.125rem 0.375rem #0000001f;
      border-radius: 0.25rem;
    }

    .pagination-tooltip:hover::after {
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }
}
</style>

<style lang="scss" scoped>
.listing-rail-kebob {
  padding: 0;
  .v-list-item:hover {
    background: #eee;
  }
}

.loader {
  height: 37.5rem;
  width: 100%;
}
</style>
