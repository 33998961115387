export default {
  cloud_env: 'cloudEnv',
  user: 'user',
  reset_password: 'reset-password',
  set_password: 'set-password',
  register: 'register',
  activate: 'activate',
  verify_and_login: 'verify-and-login',
  log_me_out: 'logmeout',
  accept_terms: 'accept-terms',
  renew_token: 'renewToken',
  me: 'me',
  tenant: 'tenant',
  upload_status: 'upload-status',
  jobs: 'jobs',
  login: 'login',
  check_sso_login: 'check-sso-login',
  users: 'users',
  orgs: 'orgs',
  download_role_skill_template: 'download-role-skill-template',
  download_role_credential_template: 'download-role-credential-template',
  download_role_course_template: 'download-role-course-template',
  download_role_content_template: 'download-role-content-template',
  tree: 'tree',
  top_level: 'toplevel',
  root: 'root',
  children_orgs: 'childrenOrgs',
  reporting: 'reporting',
  active_users: 'active-users',
  skills: 'skills',
  plan_reports: 'plan-reports',
  enrollment: 'enrollment',
  tracks: 'tracks',
  employee_list: 'employeelist',
  employee_Lists: 'employeeList',
  c2c_analytics: 'c2c-analytics',
  roles: 'roles',
  recommended_catalog: 'recommended-catalog',
  learners: 'learners',
  reports: 'reports',
  send_notifications: 'sendNotifications',
  learners_grid: 'learners-grid',
  consumption: 'consumption',
  search: 'search',
  target_companies: 'target-companies',
  profile: 'profile',
  catalog: 'catalog',
  v2: 'v2',
  v1: 'v1',
  capstones: 'capstones',
  review: 'review',
  governance: 'governance',
  items: 'items',
  announcement: 'announcement',
  activity_summary: 'activity-summary',
  generic: 'generic',
  tenants: 'tenants',
  job_desc: 'job-desc',
  parser: 'parser',
  list_files: 'list-files',
  get_download_url: 'get-download-url',
  hr_tools: 'hr-tools',
  light_cast: 'lightCast',
  taxonomy: 'taxonomy',
  architecture: 'architecture',
  skillExtraction: 'skillExtraction',
  error: 'error',
  file_formats: '.csv, .xls, .xlsx',
  pwc: 'pwc',
  global_admin: 'GLOBAL_ADMIN',
  group_admin: 'GROUP_ADMIN',
  permissions_table: 'permissions table',
  Permissions: 'permissions',
  task_options: {
    option1: 'Skill Extractor',
    option2: 'Career Level',
    option3: 'Career Track',
    option4: 'Job Family',
    option5: 'Job Function',
    option6: 'Select All',
    option7: 'Create Job Descriptions',
    option8: 'Extract Skill and talent attributes'
  },
  contents: 'contents',
  soc_value: 'soc',
  soc_name: 'Standard Occupational Classification',
  skill_profile: 'skill-profile-creation',
  skill_extraction: 'job-description-skill-parser',
  talent_architecture: 'talent-architecture',
  talent_architecture_job_type: 'talent-architecture-extraction',
  upload_file: 'upload-file',
  excel: 'xls',
  csv: 'csv',
  public: 'public',
  client: 'client'
};
