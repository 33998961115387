<template>
  <div class="fow-main">
    <FowScreen1 v-if="currentPage === 1" @on-selected-file-name="onSelectedFileName" @on-selected-option="onSelectedOption" :prevPage="prevPage" :currentPage="currentPage" />
    <FowScreen2 v-if="currentPage === 2" @on-selected-roles="onSelectedRoles" @on-empty-functions="onEmptyFunctions" :prevPage="prevPage" :currentPage="currentPage" />
    <FowScreen3 v-if="currentPage === 3" />
    <div class="action-section t-mt-16">
      <div class="middle-content">
        <svg-icon v-if="currentPage !== 1" icon-class="left-chevron" class="svg-pointer" @click="onClickPrev" />
        <div class="current-page page-section-font">{{ currentPage }}</div>
        <div class="page-section-font">{{ translate('hr_tools.fow.of') }} {{ totalPages }}</div>
        <svg-icon v-if="enableBtn && currentPage !== 3" icon-class="right-chevron-enable" class="svg-pointer" @click="onClickNext" />
        <svg-icon v-else-if="currentPage !== 3" icon-class="right-chevron-disable" class="" />
      </div>
      <v-btn data-el="next" variant="outlined" class="next-btn" @click="onClickNext" :disabled="!enableBtn">{{ btnName }}</v-btn>
    </div>
  </div>
</template>

<script>
import { translate } from '@/plugins/i18n.js';
import FowScreen1 from './FowScreen1';
import FowScreen2 from './FowScreen2';
import FowScreen3 from './FowScreen3';
import { mapActions } from 'vuex';

export default {
  name: 'FOW',
  emits: ['on-selected-file-name', 'on-selected-roles'],
  components: { FowScreen1, FowScreen2, FowScreen3 },
  computed: {
    btnName() {
      return this.currentPage === 1 ? translate('hr_tools.fow.nxt_btn_name') : translate('hr_tools.fow.submit');
    },

    enableBtn() {
      if (this.currentPage === 1) {
        return this.file || this.selectedOption === 'onet';
      } else if (this.currentPage === 2) {
        return this.roles.length > 0;
      } else {
        return true;
      }
    }
  },
  data() {
    return {
      currentPage: 1,
      prevPage: 0,
      totalPages: 3,
      file: '',
      roles: [],
      selectedOption: ''
    };
  },

  async mounted() {},

  async unMounted() {},

  deactivated() {},

  methods: {
    translate,
    ...mapActions('workforce', ['setIsUpdate']),
    onSelectedFileName(file) {
      this.file = file;
    },

    onSelectedRoles(roles) {
      this.roles = roles;
    },

    onEmptyFunctions() {
      this.prevPage = this.currentPage;
      this.currentPage = 1;
    },

    onClickNext() {
      this.prevPage = this.currentPage;
      this.currentPage += 1;
      this.scrollToTop();
    },

    async onClickPrev() {
      this.prevPage = this.currentPage;
      this.currentPage -= 1;
      this.scrollToTop();
    },

    async onSelectedOption(option) {
      this.selectedOption = option;
    },

    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },

  metaInfo() {
    return {
      title: translate('pageTitles.hr_tools')
    };
  }
};
</script>

<style lang="scss"></style>

<style lang="scss" scoped>
.fow-main {
  background: #fff;
  padding: 3rem;
  border-radius: 1.5rem;
}

.action-section {
  display: flex; // Enable flexbox
  align-items: center; // Vertically center items

  .middle-content {
    margin-left: auto; // Pushes this and subsequent content to the right
    display: flex;
    align-items: center;
    gap: 1rem;
    .svg-pointer {
      cursor: pointer;
    }
  }

  .current-page {
    border-radius: 4px;
    border: 1px solid #d1d1d1;
    width: 3rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .page-section-font {
    font-family: 'Helvetica Neue';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: -0.4px;
    color: #252525;
  }

  .next-btn {
    margin-left: auto; // This pushes the button to the right
    background-color: #415385;
  }
}
</style>
