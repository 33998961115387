<template>
  <div v-if="columns" class="content-insights-row" :style="gridTemplate">
    <div v-for="n in columns.length" :key="n" class="basic-card loading-card">
      <v-card v-if="!insightsDataLoaded" class="basic-card loading-card">
        <div class="loader t-flex t-justify-center t-items-center">
          <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Insight Tile" />
        </div>
      </v-card>

      <div v-else class="content-insights-card">
        <span class="h3">{{ headers[n - 1] }}</span>
        <div v-if="data[n - 1]" :class="`insights-card-grid-${data[n - 1].length}-items`">
          <div v-for="i in data[n - 1].length + data[n - 1].length - 1" :key="i" :class="i % 2 ? 'insights-row-container' : 'divider-container'">
            <div v-if="i % 2" class="insights-row-container">
              <div class="insights-content">
                <div class="insights-count" :count="data[n - 1][getIndex(i)].count">0</div>
                <div class="insights-status">
                  {{ getTileLabel(data[n - 1][getIndex(i)].activityType) }}
                  <span v-if="toolTipText[n - 1]" class="status-info" :aria-describedby="id" tabindex="0">
                    <ToolTip :content="toolTipText[n - 1][getIndex(i)]" :id="id" width="13.8125rem" />
                  </span>
                </div>
                <!-- Showing hours will be in Q3 - keep here for now -->
                <!-- <div v-if="showHoursPosition[n - 1] === 'bottom'" class="insights-time">{{ data[n - 1][getIndex(i)].hours }} hr</div> -->
              </div>

              <ContentPercentageArrow :value="data[n - 1][getIndex(i)].percentage" />
              <!-- Showing hours will be in Q3 - keep here for now -->
              <!-- <div v-if="showHoursPosition[n - 1] === 'right'" class="insights-time">{{ data[n - 1][getIndex(i)].hours }} hr</div> -->
            </div>
            <div v-else class="divider-container">
              <span class="dividing-line"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ToolTip from '../../../components/molecules/ToolTip.vue';
import ContentPercentageArrow from './ContentPercentageArrow.vue';
import { numberWithCommas } from '@/utils';
import { translateTC, translate } from '@/plugins/i18n.js';

export default {
  name: 'ContentInsightTileRow',
  components: { ToolTip, ContentPercentageArrow },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    insightsDataLoaded: {
      type: Boolean,
      default: false
    },
    showAssetsTile: {
      type: Boolean,
      default: false
    },
    headers: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    showHoursPosition: {
      type: Array,
      default: () => []
    },
    toolTipText: {
      type: Array,
      default: () => [[], []],
      required: true
    }
  },
  data() {
    return {
      memo: {}
    };
  },
  computed: {
    id() {
      return Math.floor(Math.random() * 10000000);
    },
    gridTemplate() {
      let gridString;
      if (this.$vuetify.display.smAndDown) {
        gridString = '1fr';
      } else {
        gridString = this.columns.map((column) => `${column}fr`).join(' ');
      }
      return `grid-template-columns: ${gridString}`;
    }
  },
  watch: {
    insightsDataLoaded() {
      if (this.insightsDataLoaded) {
        this.$nextTick(async () => {
          const counters = document.querySelectorAll('.insights-count');
          const speed = 200;

          counters.forEach((counter) => {
            const animate = () => {
              const value = +counter.getAttribute('count');
              const data = +counter.innerText;
              const time = value / speed;
              if (data < value) {
                counter.innerText = Math.ceil(data + time);
                setTimeout(animate, 10);
              } else {
                counter.innerText = this.numberWithCommas(value);
              }
            };
            animate();
          });
        });
      }
    }
  },
  methods: {
    numberWithCommas,
    getIndex(index) {
      if (!this.memo[index]) {
        this.memo[index] = Array.from(Array(index).keys()).filter((num) => num % 2 === 1).length;
      }
      return this.memo[index];
    },
    getTileLabel(activityType) {
      switch (activityType.toLowerCase()) {
        case 'credential_in_progress':
        case 'course_in_progress':
        case 'audio_in_progress':
        case 'video_in_progress':
        case 'article_in_progress':
          return translate('common.sentenceCase.inProgress');
        case 'credential_completed':
        case 'course_completed':
        case 'audio_completed':
        case 'video_completed':
        case 'article_completed':
          return translate('common.titleCase.completed');
        case 'capstone_uploads':
          return translateTC('common.titleCase.upload', 2);
        case 'capstone_passed':
          return translate('common.titleCase.passed');
        case 'capstone_failed':
          return translate('common.titleCase.failed');
        case 'capstone_pending':
          return translate('common.titleCase.pending');
        case 'assets_bookmarked':
          return translateTC('common.titleCase.bookmark', 2);
        case 'asset_downloaded':
          return translateTC('common.titleCase.download', 2);
        case 'asset_viewed':
          return translateTC('common.titleCase.view', 2);
        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.content-insights-row {
  display: grid;
  grid-gap: 1rem;
  margin: 1rem 0rem;

  .loading-card,
  .loader {
    height: 100%;
  }
  .basic-card {
    padding: 1rem;
    border-radius: 1.25rem;
    box-shadow: none !important;
    background: #fff;
  }

  .content-insights-card {
    display: grid;
    grid-template-rows: 2fr 10fr;

    .h3 {
      text-align: left;
      font: normal normal bold 1.125rem/1.875rem Arial;
      letter-spacing: 0.0006rem;
      color: #1f3370;
      opacity: 1;
    }

    .insights-card-grid-2-items {
      display: grid;
      grid-template-columns: 5.5fr 1fr 5.5fr;
      align-items: center;
    }
    .insights-card-grid-3-items {
      display: grid;
      grid-template-columns: 5.5fr 1fr 5.5fr 1fr 5.5fr;
      align-items: center;
    }
    .insights-card-grid-4-items {
      display: grid;
      grid-template-columns: 5.5fr 1fr 5.5fr 1fr 5.5fr 1fr 5.5fr;
      align-items: center;
    }
  }
  .insights-row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .insights-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .insights-count {
        text-align: left;
        font: normal normal normal 3.625rem/4.625rem 'Futura PT Demi';
        letter-spacing: 0rem;
        color: #1f3370;
        opacity: 1;
      }
      .insights-status {
        font: normal normal normal 0.875rem/1rem Arial;
        letter-spacing: 0rem;
        color: #5c5c5c;
        opacity: 1;

        .status-info {
          position: relative;
          &:after {
            content: '\24D8';
            font-size: 0.625rem;
            color: #000;
          }
          &:focus .tooltip-text,
          &:hover .tooltip-text {
            visibility: visible;
            left: -6.9063rem;
            top: -0.625rem;
          }
        }
      }
    }

    .insights-time {
      margin: 0.5rem;
      text-align: left;
      font: normal normal normal 0.75rem/0.875rem Arial;
      letter-spacing: 0rem;
      color: #00a78d;
      opacity: 0.82;
    }
  }
  .divider-container {
    height: calc(100% - 1.875rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .dividing-line {
      width: 0.0625rem;
      height: 80%;
      background: #d3d3d3;
    }
  }
}
</style>
