import moment from 'moment';
import { translate } from '../plugins/i18n.js';

export class RecommendTrackContainer {
  trackData = {};
  catalog = [];
  learning_track = {};
  filterValuesMap = {};
  totalPages = 0;
  totalResults = 0;
  skills = '';
  constructor(data) {
    this.learning_track = {
      ccc_items: [],
      total_duration: 0,
      name: '',
      description: '',
      guid: '',
      totalItemsInTrack: 0,
      roleBased: false
    };
    this.trackData = data;
    this.trackData.catalog.map((item) => {
      this.catalog.push(this.transformData(item));
    });
    if (this.trackData?.learning_track?.ccc_items)
      this.trackData.learning_track.ccc_items.map((item) => {
        this.learning_track.ccc_items.push(this.transformData(item));
      });

    this.learning_track.guid = data?.learning_track?.guid;
    this.learning_track.description = data?.learning_track?.description;
    this.learning_track.totalItemsInTrack = data?.learning_track?.totalItemsInTrack;
    this.learning_track.roleBased = data?.learning_track?.roleBased;
  }
  getDataObject() {
    this.filterValuesMap = this.trackData.filterValuesMap;
    this.totalPages = this.trackData.totalPages;
    this.totalResults = this.trackData.totalResults;
    this.skills = this.trackData.skills;
    if (this.trackData?.learning_track?.total_duration) this.learning_track.total_duration = this.trackData.learning_track?.total_duration?.value || 0;
    if (this.trackData.learning_track?.name) this.learning_track.name = this.trackData.learning_track?.name;
    return {
      catalog: this.catalog,
      learning_track: this.learning_track,
      totalPages: this.totalPages,
      totalResults: this.totalResults,
      filterValuesMap: this.filterValuesMap,
      skills: this.skills
    };
  }
  transformData(data) {
    let Obj = {};
    /*****
     * we need to get the format name credential from backend, once the api has that,
     * we will remove the following to give formatType Credential by default
     */
    Obj.id = data.id;
    Obj.formatType = data.format && data.format.name ? data.format.name : 'credential';
    Obj.provider = data.provider && data.provider.name ? data.provider.name : '';
    Obj.title = data.title ? data.title : '';
    Obj.synopsis = data.synopsis ? data.synopsis : '';
    Obj.duration = data.duration;
    Obj.durationType = data.duration ? this.getDurationType(data.duration) : '';
    Obj.durationMinutes = data.durationMinutes ? data.durationMinutes : 0;
    Obj.publishedDate = data.publishedAt ? this.formatDate(data.publishedAt) : '';
    Obj.skills = data.emsiSkills ? data.emsiSkills && data.emsiSkills.join(', ') : '';
    Obj.accreditation = data.accreditation ? data.accreditation : [];
    Obj.fieldOfStudy = data.fieldOfStudy ? data.fieldOfStudy.join(', ') : [];
    Obj.subCategory = data.subCategory ? data.subCategory : '';
    Obj.vdiRequired = data.vdiEnabled !== null ? data.vdiEnabled : '';
    Obj.skillBuilder = data.content && data.content.skillBuilders ? data.content.skillBuilders : '';
    Obj.capstone = data.content && data.content.capstone ? data.content.capstone : '';
    Obj.levels = data.levels ? data.levels[0] : '';
    Obj.languageFilter = data.languageFilter;
    Obj.isRetiring = data.isRetiring || false;
    Obj.retirementDate = data.retirementDate || '';
    Obj.contentFeature = data.contentFeature ? data.contentFeature : [];
    return Obj;
  }

  getDurationType(duration) {
    if (duration === 3600) {
      return translate('common.time.hr').toUpperCase();
    } else if (duration > 3600) {
      return translate('common.time.hr').toUpperCase();
    } else if (duration > 59 && duration < 3600) {
      return translate('common.time.min').toUpperCase();
    } else {
      return '';
    }
  }

  formatDate(date) {
    if (!date) {
      return '';
    } else {
      const formattedDate = moment(new Date(date)).format('DD MMM YYYY');
      return formattedDate !== 'Invalid date' ? formattedDate : '';
    }
  }
}
