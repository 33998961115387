// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/fonts/futura/Futura-PT-Book.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("@/assets/fonts/futura/Futura-PT-Demi.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("@/assets/fonts/futura/Futura-PT-Light.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("@/assets/fonts/futura/Futura-PT-Medium.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("@/assets/fonts/source-serif/SourceSerif4Subhead-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("@/assets/fonts/source-serif/SourceSerif4Subhead-Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("@/assets/fonts/source-sans/SourceSans3-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("@/assets/fonts/source-sans/SourceSans3-Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_8___ = new URL("@/assets/fonts/source-sans/SourceSans3-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_9___ = new URL("@/assets/fonts/source-sans/SourceSans3-Bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Futura PT Book;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}@font-face{font-family:Futura PT Demi;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}@font-face{font-family:Futura PT Light;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}@font-face{font-family:Futura PT Md;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}@font-face{font-family:\"Source Serif\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"opentype\")}@font-face{font-family:Source Sans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"opentype\")}@font-face{font-family:Source Sans Bold;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"truetype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"opentype\")}.redesign *[data-v-29c91686]{font-family:Arial,sans-serif}.redesign h1[data-v-29c91686],.redesign h2[data-v-29c91686],.redesign h3[data-v-29c91686],.redesign h4[data-v-29c91686],.redesign h5[data-v-29c91686],.redesign h6[data-v-29c91686]{font-family:Arial,Futura PT Demi,sans-serif}.texty-input[data-v-29c91686]{border:1px solid #dedede;border-radius:2px;font-size:14px;padding-left:10px}.error[data-v-29c91686],.success[data-v-29c91686],.warning[data-v-29c91686]{border-radius:15px;color:#fff;font-size:25px;margin:20px;padding-top:15px;text-align:center}.close-btn[data-v-29c91686]{color:#dd5217!important}.close-btn[data-v-29c91686],.close-btn-success[data-v-29c91686]{background:#fff!important;float:right;height:25px;position:relative;top:0;width:25px}.close-btn-success[data-v-29c91686]{color:#175d2d!important}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
