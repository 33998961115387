<template>
  <div class="tooltip-wrapper">
    <div :style="{ width: `${width}px`, height: `${height}px` }" class="catalog-social-share-container">
      <v-menu :attach="true" v-model="openSocialShareBool" :close-on-content-click="false" location="center" transition="scale-x-transition">
        <template v-slot:activator="{ props }">
          <button v-bind="props" :aria-label="$t('catalog.clickToCopy')">
            <svg-icon icon-class="click-to-copy" />
          </button>
        </template>
        <v-list class="catalog-social-share-content">
          <div class="tooltip-bottom" @click.stop="onToolTipClick" :style="{ cursor: textCopied ? 'not-allowed' : 'pointer' }">
            <svg-icon icon-class="chain-link" class="chain-link"></svg-icon>
            <span class="tooltip-bottom-text">{{ textCopied ? $t('catalog.linkCopied') : $t('catalog.copyLink') }}</span>
            <svg-icon icon-class="green-checkmark" class="green-checkmark" :style="{ display: textCopied ? 'inline-flex' : 'none' }"></svg-icon>
          </div>
        </v-list>
      </v-menu>
    </div>
    <span class="tooltip-text">{{ $t('catalog.share') }}</span>
  </div>
</template>
<script>
export default {
  name: 'SocialShare',
  data() {
    return { openSocialShareBool: false, width: 0, height: 0, textCopied: false };
  },
  props: {
    size: {
      type: String,
      required: false,
      default: () => {
        return 'lg';
      }
    },
    catalogItem: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    }
  },
  created() {
    switch (this.size) {
      case 'xlg':
        this.width = 45;
        this.height = 45;
        break;
      case 'lg':
        this.width = 40;
        this.height = 40;
        break;
      case 'md':
        this.width = 30;
        this.height = 30;
        break;
      case 'sm':
        this.width = 25;
        this.height = 25;
        break;
      default:
        this.width = 40;
        this.height = 40;
        break;
    }
  },
  methods: {
    async onToolTipClick() {
      const routeInfo = this.getRouteInfo(this.catalogItem);
      const location = `${process.env.VUE_APP_LEARN_BASE_URI}/${routeInfo.name}/${routeInfo.params.id}`;

      if (!this.textCopied) {
        navigator.clipboard.writeText(location);
        this.textCopied = true;
        setTimeout(() => (this.textCopied = false), 5000);
      }
    },
    getRouteInfo(item) {
      switch (item.formatType.toLowerCase()) {
        case 'article':
        case 'video':
        case 'audio':
        case 'podcast':
          return { name: 'content', params: { id: item.id, title: item.title, pgrTrk: true } };
        case 'asset':
          return { name: 'asset', params: { id: item.id, asset: item } };
        case 'course':
          return { name: 'course', params: { id: item.id, pgrTrk: true } };
        case 'credential':
          return { name: 'credential', params: { id: item.id, pgrTrk: true } };
        case 'track':
          return { name: 'collection', params: { id: item.trackGuid } };
        default:
          return { name: 'collection', params: { id: item.trackGuid } };
      }
    },
    OnShare() {}
  }
};
</script>
<style lang="scss">
.catalog-social-share-container {
  .v-list {
    .v-list-item__content {
      display: flex;
      align-items: center;
    }
  }
}
</style>
<style lang="scss" scoped>
.tooltip-wrapper {
  position: relative;
  display: inline-block;

  .tooltip-text {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    top: 0rem;
    left: 115%;
    width: 3.5rem;
    height: 1.875rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0rem 0.125rem 0.375rem #0000001f;
    border-radius: 0.25rem;
    opacity: 1;
    text-align: center;
    font: normal normal normal 0.75rem/1rem Arial;
    letter-spacing: 0rem;
    color: #000000de;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.375rem;
  }
  &:hover {
    .tooltip-text {
      visibility: visible;
    }
  }
}

.catalog-social-share-container {
  width: 2.5rem;
  height: 2.5rem;
  background: #fff;
  border: 0.0625rem solid #efefef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  .svg-icon {
    width: 1.5rem;
    height: 1.5rem;
    display: block;
  }
}

.v-menu {
  margin-top: 2rem;
  top: -2rem !important;
  left: 0rem !important;

  .catalog-social-share-content {
    overflow: hidden;
    transition: all 0.05s;
    text-transform: none !important;
    width: 9.375rem !important;
    height: 2.5rem;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 0.625rem;
    padding: 0rem;
    display: inline-flex;
    align-items: center;
    .tooltip-bottom {
      height: 100%;
      font-size: 0.75rem;
      width: 100% !important;
      display: inline-flex;
      align-items: center;

      .chain-link {
        width: 1.5625rem;
        height: 1.5625rem;
        margin: 0rem 0.5rem;
        display: inline-flex;
        align-items: center;
      }

      .green-checkmark {
        width: 1.25rem;
        height: 1.25rem;
        margin: 0rem 0.625rem;
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
</style>
