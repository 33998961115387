<template>
  <v-container>
    <v-row>
      <v-col class="upload-prompt">
        {{ uploadPrompt }}
        <br />
        <br />{{ $t('uploadFile.clickForTemplate[0]') }} <span class="template" @click="onDownloadTemplate">{{ $t('uploadFile.clickForTemplate[1]') }}</span> {{ $t('uploadFile.clickForTemplate[2]') }}
      </v-col>
    </v-row>
    <v-row class="centered">
      <v-col class="centered">
        <span class="container">
          <input ref="upload-input" class="upload-input" type="file" :accept="acceptedFormats" @change="handleClick" />
          <div class="drop" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover">
            <div v-if="!fileToUpload">{{ ReminderText }}</div>
            <div class="upload-ready" v-else>{{ fileToUpload.name }}<v-icon @click="removeFile"> mdi-delete </v-icon></div>
            <v-btn :disabled="!!fileToUpload" :class="!fileToUpload ? 'cancel-button' : 'disabled'" rounded color="primary" @click="handleUpload">{{ $t('uploadFile.browse') }}</v-btn>
          </div>
        </span>
      </v-col>
    </v-row>
    <v-row no-gutters class="pg-actions">
      <button :class="!fileRunning && fileToUpload ? 'button' : 'button disabled'" :disabled="!fileToUpload || fileRunning == true" @click="uploadIntent">{{ $t('uploadFile.upload') }}</button>
      <a data-el="cancelEditLearning" :class="!fileRunning ? 'cancel-link' : 'cancel-link disable '" :disabled="fileRunning" color="primary" @click="onCancel">{{ $t('button.cancel') }}</a>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'UploadFile',
  props: {
    uploadPrompt: String,
    ReminderText: String,
    acceptedFormats: String,
    onSuccess: Function
  },
  computed: {
    ...mapGetters(['company'])
  },
  data() {
    return {
      token: '',
      isUploading: false,
      loading: false,
      displayData: false,
      fileData: {
        header: null,
        results: null
      },
      isError: false,
      fileToUpload: null,
      fileRunning: false
    };
  },
  created() {
    this.EventBus.on('upload-running', this.uploadRunning);
  },

  methods: {
    ...mapMutations('user', ['SET_SHOW_MODAL', 'SET_ROLES', 'SET_UPLOADING']),
    removeFile() {
      this.fileToUpload = null;
      this.$refs['upload-input'].value = null;
      this.fileRunning = false;
    },
    uploadRunning() {
      this.fileRunning = true;
    },
    uploadIntent() {
      this.SET_SHOW_MODAL(true);
    },
    onCancel() {
      this.$emit('cancel-upload');
    },
    // not used here
    // generateData({ header, results }) {
    //   this.fileData.header = header
    //   this.fileData.results = results
    //   this.onSuccess && this.onSuccess(this.fileData)
    // },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.EventBus.emit('upload-error', { err: 'Please upload only one file at a time' });
        return;
      }
      const rawFile = files[0];

      if (!this.isRightSuffix(rawFile)) {
        const errorString = 'File type not supported. Please upload a file with extension: ' + this.acceptedFormats;
        this.EventBus.emit('upload-error', { err: errorString });
        return false;
      }
      //this.upload(rawFile)
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    handleUpload() {
      const browserObj = { c: 'Integrate', l: 'Browse', a: 'click' };
      analytics.setVarAndTrack(browserObj);
      this.$gtag.event('Integrate', {
        event_category: 'click',
        event_label: 'Browse',
        value: 1
      });

      setTimeout(() => {
        this.$refs['upload-input'].click();
      }, 400);
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0];
      this.fileToUpload = rawFile;
      if (!rawFile) return;
      if (!this.isRightSuffix(rawFile)) {
        const errorString = 'File type not supported. Please upload a file with extension: ' + this.acceptedFormats;
        this.EventBus.emit('upload-error', { err: errorString });
        this.fileToUpload = null;
        return false;
      }
      const uploadObj = { c: 'Integrate', l: 'File upload', a: 'click' };
      analytics.setVarAndTrack(uploadObj);
      this.$gtag.event('Integrate', {
        event_category: 'click',
        event_label: 'File upload' + rawFile.name,
        value: 1
      });
    },
    async upload() {
      //this.isUploading = true
      this.SET_UPLOADING(true);
      this.$refs['upload-input'].value = null;
      const formData = new FormData();
      formData.append('file', this.fileToUpload);

      this.SET_SHOW_MODAL(false);

      return formData;
    },
    isRightSuffix(file) {
      const fileExt = '.' + file.name.split('.').pop();
      const allowedExtArray = this.acceptedFormats.split(',');
      return allowedExtArray.includes(fileExt);
    },
    async onDownloadTemplate() {
      this.$emit('downloadTemplate');
    }
  }
};
</script>

<style scoped lang="scss">
.centered {
  height: 100%;
  display: flex;
  align-items: center;
}
.banner {
  margin: 0px 0px;
  justify-content: center;
  font-size: 18px;

  .left-item {
    float: left;
    color: #dd5217;
    font-weight: bold;
  }

  .center-item {
    float: left;
    color: #dd5217;
    font-weight: bold;
    width: inherit;
    text-align: center;
    text-transform: uppercase;
  }

  .right-item {
    float: right;
    color: #1f3370;
  }
}

.button {
  background-color: #ffb92e;
  color: #1f3370;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02px;
  font-weight: 600;
  font-family: 'Futura';
  margin: 0;
  border: none;
  padding: 0 30px;
  height: 40px;
  border-radius: 20px;
  line-height: 40px;
}
.upload-input {
  display: none;
  z-index: -9999;
}

.container {
  width: 100%;
}

.drop {
  border: 2px dashed #bbb;
  width: 50%;
  line-height: 80px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}

.upload-prompt {
  text-align: center;
  font-size: 20px;
}

a {
  text-decoration: none;
  color: $color-data-2;
}

.error {
  margin: 0px 100px;
  font-size: 25px;
  text-align: center;
  color: $color-white;
}

.template {
  cursor: pointer;
  color: #dd5217;
}

.disabled {
  background: lighten($color-grey-dark, 55%) !important;
  color: $color-white !important;
}
.disable {
  color: black;
  pointer-events: none;
}

.pg-actions.no-gutters {
  justify-content: flex-end;
  align-items: center;
  button {
    margin-right: 20px;
  }
}

.cancel-link {
  color: $color-primary;
}

.upload-ready {
  display: flex;
  padding: 0 20px;
  color: lighten($color-grey-dark, 30%);
  font-size: smaller;
  .v-icon {
    position: absolute;
    right: 30px;
    top: 25px;
    color: lighten($color-grey-dark, 10%);
  }
}
</style>
