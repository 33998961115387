<template>
  <div class="skill-extraction-container">
    <v-dialog v-model="dialog" width="584" max-width="584" class="dialog-container">
      <v-card max-width="584" :text="dialogSubTitle" :title="translate('hr_tools.skill_extraction.dialog_title')">
        <template v-slot:append>
          <v-btn variant="plain" icon="mdi-close" @click="dialog = false"></v-btn>
        </template>
        <template v-slot:actions>
          <v-btn class="ms-auto" :text="translate('button.cancel')" @click="dialog = false"></v-btn>
          <v-btn data-el="addTag" rounded dark color="#000" class="add action-btn black--text ok-btn">{{ translate('button.ok') }}</v-btn>
        </template>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="failedDialog" width="85%" max-width="85%" class="failure-dialog-container">
        <v-card :title="translate('hr_tools.skill_extraction.failed_dialog_title')">
          <template v-slot:append>
            <v-btn variant="plain" icon="mdi-close" @click="failedDialog = false"></v-btn>
          </template>
          <v-card-text class="">
            <div class="t-flex t-gap-8">
              <div>
                <h4>{{ translate('hr_tools.skill_extraction.failure_msg') }}</h4>
                <p>{{ fileFailure.msg }}</p>
              </div>
              <div>
                <h4>{{ translate('hr_tools.skill_extraction.date_range') }}</h4>
                <p>{{ fileFailure.createdDate }}</p>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <h3>{{ translate('hr_tools.skill_extraction.title') }}</h3>
    <div class="skill-taxonomy-header">
      <h6>{{ translate('hr_tools.skill_extraction.taxonomy_header') }}</h6>
      <div class="t-flex t-gap-8" style="width: fit-content">
        <SelectView v-model="selectedTaxonomy" :items="taxonomyOptions" item-title="name" item-value="value" return-object variant="outlined" placeholder=""></SelectView>
        <v-btn data-el="addTag" rounded dark color="#FFB92E" class="add action-btn black--text" @click="onUploadCustomTaxonomy()">{{ translate('hr_tools.skill_extraction.upload_taxonomy_btn_text') }}</v-btn>
      </div>
    </div>
    <div v-if="showCustomUploadTaxonomy" class="file-view-container">
      <h6>{{ translate('hr_tools.skill_extraction.custom_taxonomy_name') }}<span class="required">*</span></h6>
      <v-text-field v-model="customTaxonomyName" placeholder="" variant="outlined" density="compact"></v-text-field>
    </div>
    <UploadFile v-if="showCustomUploadTaxonomy" @upload-skill-file="getUploadFile" :uploadType="constant.taxonomy" />
    <div class="skill-taxonomy-header">
      <h6>{{ translate('hr_tools.skill_extraction.architecture') }}</h6>
      <div class="t-flex t-gap-8" style="width: fit-content">
        <SelectView v-model="selectedArchitecture" :items="architectureOptions" item-title="name" item-value="value" return-object variant="outlined" placeholder=""></SelectView>
        <v-btn data-el="addTag" rounded dark color="#FFB92E" class="add action-btn black--text" @click="onUploadCustomArchitecture()">{{ translate('hr_tools.skill_extraction.upload_architecture_btn_text') }}</v-btn>
      </div>
    </div>
    <div v-if="showCustomUploadArchitecture" class="file-view-container">
      <h6>{{ translate('hr_tools.skill_extraction.custom_architecture') }}<span class="required">*</span></h6>
      <v-text-field v-model="customArchitectureName" placeholder="" variant="outlined" density="compact"></v-text-field>
    </div>
    <UploadFile v-if="showCustomUploadArchitecture" @upload-skill-file="getUploadFile" :uploadType="constant.architecture" />
    <div class="skill-task-header">
      <h6>{{ translate('hr_tools.skill_extraction.task_header') }}<span class="required">*</span></h6>
      <div class="task-options">
        <v-checkbox v-for="(task, index) in taskOptions" :key="index" :label="task.name" :value="task.value" v-model="selectedTasks" :ripple="false" color="#1F3370" :disabled="task.disabled" />
      </div>
    </div>
    <div>
      <UploadFile @upload-skill-file="getUploadFile" :uploadType="constant.skillExtraction" :key="currentTime" />
      <div class="t-w-full" v-if="fileList.length > 0">
        <p class="download-msg">{{ translate('hr_tools.skill_extraction.upload_msg') }}</p>
        <div class="file-meta-data" v-for="(file, index) in fileList" :key="index">
          <div class="t-flex t-items-center t-gap-8">
            <p v-if="file.metadata && file.metadata.fileName" :title="file.metadata.fileName">{{ file.metadata.fileName }}</p>
            <div class="t-flex t-items-center t-gap-2">
              <svg-icon v-if="file.status === 'completed'" icon-class="complete-progress" style="width: 22px; height: 22px" />
              <svg-icon v-else-if="file.status === 'failed'" icon-class="failed" style="width: 22px; height: 22px" />
              <svg-icon v-else icon-class="loader" style="width: 22px; height: 22px" />
              <span :class="file.status === 'failed' ? 'loading-label failed' : 'loading-label'" @click="onClickFailureMsg(file)">{{ file.status }}</span>
            </div>
          </div>
          <v-btn data-el="addTag" rounded dark color="#FFB92E" class="add action-btn black--text" @click="onDownloadCSV(file)" :disabled="file.isDownloadDisable"
            ><svg-icon icon-class="icon-download" class="icon-medium t-cursor-pointer" />{{ translate('hr_tools.skill_extraction.download') }}</v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import { translate } from '@/plugins/i18n.js';
import SelectView from '@/components/molecules/SelectView';
import UploadFile from './UploadFile';
import constantData from '@/api/constant';

export default {
  name: 'SkillExtraction',
  emits: [],
  components: { SelectView, UploadFile },
  computed: {
    ...mapGetters([]),
    dialogSubTitle() {
      const baseKey = 'hr_tools.skill_extraction.dialog-sub_title';
      const subtitleMap = {
        [constantData.taxonomy]: '2',
        [constantData.architecture]: '3'
      };

      const suffix = subtitleMap['taxonomy'];
      if (suffix) {
        return `${translate(`${baseKey}1`)} "" ${translate(`${baseKey}${suffix}`)}`;
      }

      return '';
    }
  },

  data() {
    return {
      downloadFileReference: {},
      fileList: [],
      showCustomUploadTaxonomy: false,
      showCustomUploadArchitecture: false,
      constant: {},
      customTaxonomyName: '',
      customArchitectureName: '',
      dialog: false,
      failedDialog: false,
      uploadType: 'taxonomy',
      uploadFiles: [],
      currentTime: Date.now(),
      taskOptions: [
        {
          name: constantData.task_options.option1,
          value: constantData.task_options.option1,
          disabled: false
        },
        {
          name: constantData.task_options.option2,
          value: constantData.task_options.option2,
          disabled: false
        },
        {
          name: constantData.task_options.option3,
          value: constantData.task_options.option3,
          disabled: false
        },
        {
          name: constantData.task_options.option4,
          value: constantData.task_options.option4,
          disabled: false
        },
        {
          name: constantData.task_options.option5,
          value: constantData.task_options.option5,
          disabled: false
        }
      ],
      selectedTasks: [],
      selectedTaxonomy: {
        name: translate('hr_tools.skill_extraction.taxonomy_options.option1'),
        value: constantData.pwc
      },
      selectedArchitecture: {
        name: translate('hr_tools.skill_extraction.architecture_options.option1'),
        value: constantData.pwc
      },
      architectureOptions: [
        {
          name: translate('hr_tools.skill_extraction.architecture_options.option1'),
          value: constantData.pwc
        }
      ],
      taxonomyOptions: [
        {
          name: translate('hr_tools.skill_extraction.taxonomy_options.option1'),
          value: constantData.pwc
        },
        {
          name: translate('hr_tools.skill_extraction.taxonomy_options.option2'),
          value: translate('hr_tools.skill_extraction.taxonomy_options.option2')
        },
        {
          name: translate('hr_tools.skill_extraction.taxonomy_options.option3'),
          value: translate('hr_tools.skill_extraction.taxonomy_options.option3')
        }
      ],
      fileFailure: {
        msg: '',
        createdDate: ''
      }
    };
  },

  async mounted() {
    this.constant = constantData;
    await this.setup();
  },

  async unMounted() {
    this.downloadFileReference = {};
    this.fileList = [];
    this.showCustomUploadTaxonomy = false;
    this.showCustomUploadArchitecture = false;
  },

  deactivated() {},

  methods: {
    translate,
    async setup() {
      const payload = {
        jobType: this.constant.skill_extraction
      };
      this.fileList = await this.$planAdmin.getSkillExtractionFiles(payload);
      await this.fileList.forEach((file) => {
        file.isDownloadDisable = file.status !== 'completed';
      });
    },

    async downloadFile(relativeUri) {
      try {
        if (relativeUri) {
          this.downloadPercentage = 0;
          let filename = this.downloadFileReference.fileName;
          let a = document.createElement('a');
          a.href = relativeUri;
          a.setAttribute('download', filename);
          a.click();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async uploadSkillFile(files) {
      try {
        if (files.length > 3) {
          this.showToast(translate('hr_tools.skill_extraction.max_file_error'), 'error', 5000);
        } else if (this.selectedTaxonomy.value.toLowerCase() === this.constant.light_cast.toLowerCase()) {
          this.showToast('LightCast is not available.Please select other option', 'error', 5000);
        } else if (this.uploadType === this.constant.skillExtraction && this.selectedTasks.length === 0) {
          this.showToast(translate('hr_tools.skill_extraction.taskErrorMsg'), 'error', 5000);
        } else {
          const formData = new FormData();
          const token = await localforage.getItem('my_access_token');
          formData.set('token', token);
          formData.set('isSkillExtractor', this.selectedTasks.includes(constantData.task_options.option1));
          formData.set('isTrackExtractor', this.selectedTasks.includes(constantData.task_options.option3));
          formData.set('isLevelExtractor', this.selectedTasks.includes(constantData.task_options.option2));
          formData.set('isJobFamily', this.selectedTasks.includes(constantData.task_options.option4));
          formData.set('isJobFunction', this.selectedTasks.includes(constantData.task_options.option5));
          formData.set('architectureName', this.selectedArchitecture.value);
          formData.set('taxonomy', this.selectedTaxonomy.value);
          for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
          }
          const payload = {
            body: formData
          };
          this.isUploaded = true;
          const response = await this.$planAdmin.uploadSkillExtractionFile(payload);
          if (response) {
            this.showToast(translate('hr_tools.skill_extraction.file_upload_success'), 'success', 5000);
            this.selectedTasks = [];
            this.selectedTaxonomy = this.taxonomyOptions[0];
            await response.forEach((file) => {
              file.isDownloadDisable = file.status !== 'completed';
            });
            this.fileList = [...this.fileList, ...response];
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    async onDownloadCSV(file) {
      const payload = {
        userEmail: file.userEmail,
        relativeUrl: file.metadata.outputFileRelativeUri
      };
      this.downloadFileReference = file;
      const response = await this.$planAdmin.getSkillExtractionFileSignedRelativeUri(payload);
      if (response) {
        this.downloadFile(response);
      } else {
        this.showToast(translate('hr_tools.skill_extraction.file_process_error'), 'error', 5000);
      }
    },

    async getUploadFile(data) {
      this.uploadType = data.type;
      this.uploadFiles = data.files;
      const isSkillExtraction = this.uploadType === this.constant.skillExtraction;
      this.dialog = !isSkillExtraction;
      this.currentTime = Date.now();
      if (isSkillExtraction) {
        this.uploadSkillFile(this.uploadFiles);
      }
    },

    async proceedFileUpload() {
      this.dialog = false;
      const uploadFunctions = {
        [this.constant.taxonomy]: this.uploadCustomTaxonomyFile,
        [this.constant.architecture]: this.uploadCustomArchitectureFile
      };

      const uploadFunction = uploadFunctions[this.uploadType];
      if (uploadFunction) {
        await uploadFunction(this.uploadFiles);
      }
    },

    onClickFailureMsg(file) {
      if (file.status === 'failed') {
        this.failedDialog = true;
        this.fileFailure.msg = file.message;
        this.fileFailure.createdDate = file.createdAt;
      }
    },

    async onUploadCustomTaxonomy() {
      this.showCustomUploadTaxonomy = true;
    },

    async onUploadCustomArchitecture() {
      this.showCustomUploadArchitecture = true;
    },

    async uploadCustomTaxonomyFile() {
      this.showCustomUploadTaxonomy = false;
    },

    async uploadCustomArchitectureFile() {
      this.showCustomUploadArchitecture = false;
    },

    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });
      this.$toasted.show(msg, options);
    }
  },

  metaInfo() {
    return {
      title: translate('pageTitles.hr_tools')
    };
  }
};
</script>
<style lang="scss">
.task-options {
  display: flex;
  gap: 2rem;
  align-items: center;
  .v-selection-control__input,
  .v-selection-control__wrapper {
    height: fit-content;
    width: fit-content;
  }
  .v-input__details {
    display: none !important;
  }
  .v-selection-control .v-label {
    color: #2d2d2d;
    @include body-font-1;
    opacity: 1;
  }
  .v-input {
    flex: unset;
    .v-input__control {
      padding-right: 0px;
    }
  }
  .v-checkbox .v-selection-control {
    min-height: 2rem !important;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .v-selection-control--disabled {
    opacity: 1;
    color: #1f3370;
  }
  .v-input--disabled {
    opacity: 0.5 !important;
  }
}

.skill-taxonomy-header {
  .v-input {
    width: 320px !important;
  }
  .v-select .v-field.v-field {
    height: 40px !important;
  }
  .v-field--center-affix .v-field__append-inner {
    height: 40px !important;
  }
  .v-field__input {
    padding: 8px !important;
    height: 40px !important;
  }
  .v-input__details {
    display: none !important;
  }
  .v-select__selection {
    align-self: baseline;
  }
}

.file-view-container {
  .v-input {
    width: 585px !important;
  }
  .v-input__details {
    display: none !important;
  }
  .v-select .v-field.v-field {
    height: 56px !important;
  }
  .v-field--center-affix .v-field__append-inner {
    height: 56px !important;
  }
  .v-field__input {
    padding: 1rem !important;
    height: 56px !important;
  }
}

.dialog-container,
.failure-dialog-container {
  .v-card {
    padding: 2rem !important;
    .v-card-item {
      padding: unset !important;
      .v-card-title {
        @include header-font-1-5;
        color: #2d2d2d;
        margin-bottom: 1.5rem;
      }
    }
    .v-card-text {
      padding: unset !important;
      @include body-font-1;
      margin-bottom: 2rem;
    }
    .v-card-item__append {
      margin-bottom: 1.5rem;
      font-size: 1.5rem;
    }
  }
  .ok-btn {
    background-color: #ffb92e;
    height: 52px;
  }
}
</style>

<style lang="scss" scoped>
.skill-extraction-container {
  background: #fff;
  padding: 3rem;
  border-radius: 1.5rem;
  h3 {
    @include header-font-2;
    color: #2d2d2d;
    margin-bottom: 2rem;
  }
}

.skill-task-header {
  margin-bottom: 2rem;
  h6 {
    @include body-font-bold-0-875;
  }
}

.skill-taxonomy-header {
  margin-bottom: 2rem;
  h6 {
    margin-bottom: 8px;
    @include body-font-bold-0-875;
  }
}

.file-view-container {
  margin-bottom: 2rem;
  h6 {
    margin-bottom: 8px;
    @include body-font-bold-0-875;
  }
}

.required {
  @include body-font-bold-0-875;
  color: #e0301e;
  margin-left: 4px;
}

.upload-error-message {
  color: var(--PE-Status-Colors-Warning-Status, #e0301e);
  @include body-font-0-75;
}

.icon-medium {
  height: 1.5rem;
  width: 1.5rem;
}

.download-msg {
  margin-bottom: 2rem;
  @include body-font-1;
}

.file-meta-data {
  border-radius: var(--Corner-Radius-Radius-XS, 8px);
  background: var(--Fill-Gainsboro, #f5f5f5);
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  align-items: center;
  align-self: stretch;
  margin-bottom: 1rem;
  p {
    max-width: 335px;
    width: 335px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.loading-label {
  @include body-font-0-875;
  color: #2d2d2d;
}

.failed {
  color: #e0301e;
  text-decoration: underline;
  cursor: pointer;
}

.add {
  .v-btn__content {
    @include body-font-bold-1;
  }
}

.add:focus-visible {
  outline: black 2px solid;
  outline-offset: 1px;
}

.action-btn {
  box-shadow: none !important;
  padding: 8px 24px;
  float: right;
  display: flex;
  align-items: center;
  @include body-font-bold-1;
  background-color: #ffb92e;
}

.label-view {
  @include header-font-0-875;
  color: var(--Text-Body-DarkGrey, #2d2d2d);
}

.notification-close-btn {
  margin-right: 1.125rem;
  margin-top: -0.125rem;
  .v-icon {
    font-size: 1.875rem;
  }
}
</style>
