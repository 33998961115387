<template>
  <div class="profile-header">
    <span>
      <svg :viewBox="`0 0 ${UNITS} ${UNITS}`" :height="size || '50%'" :width="size || '50%'">
        <path class="profile-header-inactive" :d="describeArc(UNITS / 2, UNITS / 2, getRadius(), start, end)" fill="none" :stroke-width="getStrokeWidth(strokeWidth)" :stroke-dasharray="getLengths()" />
        <path class="profile-header-active" :d="describeArc(UNITS / 2, UNITS / 2, getRadius(), start, activeEnd())" fill="none" :stroke-width="getStrokeWidth(activeWidth)" :stroke-dasharray="getLengths()" />
        <pattern id="me" height="100%" width="100%" patternContentUnits="objectBoundingBox">
          <image height="1" width="1" preserveAspectRatio="none" :href="profileHeader.avatarImage" />
        </pattern>
        <circle :cx="getRadius() + getStrokeWidth(strokeWidth) / 2" :cy="getRadius() + getStrokeWidth(strokeWidth) / 2" :r="getRadius() - getStrokeWidth(strokeWidth) - 5" fill="url(#me)" />
      </svg>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ProfileHeader',
  beforeCreate() {
    this.UNITS = 200
  },
  props: {
    profileHeader: {
      type: Object,
      required: true,
      default: () => {
        return {
          avatarImage: require('@/assets/images/users/no-avatar.png'),
          activeCount: 0,
          dashCount: 0
        }
      }
    },
    profileSize: {
      type: Number,
      require: false,
      default: 50
    }
  },
  data() {
    return {
      dashSpacing: 1 / 16,
      start: -135,
      end: 135,
      strokeWidth: 10,
      activeWidth: 10,
      size: this.profileSize.toString() + '%'
    }
  },
  methods: {
    getStrokeWidth(stroke) {
      return (stroke * this.UNITS) / 200
    },

    activeEnd() {
      if (this.profileHeader.activeCount == 0) {
        return this.start
      }
      return this.start + (this.end - this.start) * (this.profileHeader.activeCount * this.dashPerc() + (this.profileHeader.activeCount - 1) * this.spacePerc())
    },

    getLengths() {
      return [this.getArcLength() * this.dashPerc(), this.getArcLength() * this.spacePerc()]
    },

    spacePerc() {
      return this.dashSpacing / (this.profileHeader.dashCount - this.dashSpacing)
    },

    dashPerc() {
      return (1 - this.dashSpacing) / (this.profileHeader.dashCount - this.dashSpacing)
    },

    getArcLength() {
      return (Math.abs(this.end - this.start) / 360) * 2 * Math.PI * this.getRadius()
    },

    getRadius() {
      return (this.UNITS - Math.max(this.getStrokeWidth(this.strokeWidth), this.getStrokeWidth(this.activeWidth))) / 2
    },

    polarToCartesian(cx, cy, radius, degrees) {
      const radians = ((degrees - 90) * Math.PI) / 180.0
      return {
        x: cx + radius * Math.cos(radians),
        y: cy + radius * Math.sin(radians)
      }
    },

    describeArc(cx, cy, radius, startDegrees, endDegrees) {
      const start = this.polarToCartesian(cx, cy, radius, startDegrees)
      const end = this.polarToCartesian(cx, cy, radius, endDegrees)
      let largeArc = Math.abs(endDegrees - startDegrees) < 180 ? 0 : 1
      let sweep = endDegrees < startDegrees ? 0 : 1
      return `M${start.x} ${start.y} A${radius} ${radius} 0 ${largeArc} ${sweep} ${end.x} ${end.y}`
    }
  }
}
</script>
<style lang="scss" scoped>
.profile-header {
  padding: 0px;
}

.profile-header-active {
  stroke: $color-secondary;
}

.profile-header-inactive {
  stroke: $color-grey;
}
</style>
