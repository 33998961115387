<template>
  <div class="assessment-wrapper">
    <v-card>
      <div class="stats-header">
        <div class="stats-title">Stats</div>
      </div>
      <v-container class="stats-panel">
        <v-row>
          <v-col cols="7" class="stats-field">Uploads</v-col>
          <v-col cols="auto" class="stats-val">{{ stats.uploads }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="7" class="stats-field">Failed</v-col>
          <v-col cols="auto" class="stats-val">{{ stats.fails }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="7" class="stats-field">Passed</v-col>
          <v-col cols="auto" class="stats-val">{{ stats.passes }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="7" class="stats-field">Pending Review</v-col>
          <v-col cols="auto" class="stats-val">{{ stats.pendingReview }}</v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AssessmentStats',
  props: {
    stats: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 10px;
  margin: 0;
  padding: 20px;

  .stats-header {
    display: flex;
    border-bottom: 1px solid #ccc;
    .stats-title {
      margin: 0;
      font-size: 25px;
      font-weight: bold;
    }
  }

  .stats-panel {
    margin: 10px 0px;
    padding: 0;
    .row {
      padding: 5px 0px;
      margin: 0;
      .col {
        padding: 0;
        margin: 0 10px;
      }
      .stats-field {
        text-align: right;
      }
      .stats-val {
        font-weight: bold;
        color: $color-violet;
      }
    }
  }
}
</style>
