<template>
  <div @drop="handleDrop($event)" @dragover="handleDragover" @dragenter="handleDragover">
    <label for="announcement-description" class="label-view">{{ uploadTitle }}</label>
    <div class="file-upload-container t-mt-2" :style="uploadError && 'border: 1px dashed #e0301e'">
      <div class="upload-btn-content">
        <svg-icon icon-class="upload-outline" class="g-icon--large upload-icon"></svg-icon>
        <div class="supported-format t-dark-grey">
          <span class="t-font-bold">{{ translate('settings.createAnnouncement.uploadText') }}</span
          >{{ translate('hr_tools.skill_extraction.uploadFormat') }}
        </div>
      </div>
      <label class="file-upload-wrapper t-opacity-50 hover:t-opacity-100" tabindex="0">
        <div class="file-size-text t-dark-grey" v-text="translate('hr_tools.skill_extraction.file_title')"></div>
        <span class="choose-file t-primary-color">
          <input id="skillExtractionFile" data-cy="asset-file-zip" ref="skillExtractionFile" class="upload-input" type="file" accept=".csv, .xls, .xlsx" @change="handleUpload" multiple />
          {{ translate('settings.createAnnouncement.chooseFile') }}</span
        >
      </label>
    </div>
    <p v-if="uploadError" class="t-mt-2 upload-error-message">{{ translate('settings.createAnnouncement.uploadErrorMsg') }}</p>
    <AlertsModal v-if="showAlertsModal && modalMessageObj.header" :modalProperties="modalMessageObj" @closeAlertsModal="closeShowModal"></AlertsModal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { translate } from '@/plugins/i18n.js';
import AlertsModal from '../../manage-submissions/components/AlertsModal.vue';
import constant from '@/api/constant';

export default {
  name: 'UploadFile',
  emits: ['upload-skill-file'],
  props: {
    uploadType: {
      require: true,
      default: () => {
        return constant.skillExtraction;
      }
    }
  },
  components: { AlertsModal },
  computed: {
    ...mapGetters([]),
    uploadTitle() {
      const titleMap = {
        [constant.taxonomy]: 'hr_tools.skill_extraction.upload_custom_taxonomy_title',
        [constant.architecture]: 'hr_tools.skill_extraction.upload_custom_architecture_title'
      };

      return translate(titleMap[this.uploadType] || 'hr_tools.skill_extraction.sub_title');
    }
  },
  data() {
    return {
      showAlertsModal: false,
      modalMessageObj: {},
      downloadPercentage: 0,
      pdfFileExceedLimitMsg: {
        header: translate('hr_tools.skill_extraction.file_limit_header'),
        message: translate('hr_tools.skill_extraction.max_file_error'),
        btnText: translate('hr_tools.skill_extraction.btn_text'),
        buttons: [],
        type: constant.error
      },
      uploadError: false,
      acceptedFormats: constant.file_formats
    };
  },
  async mounted() {},
  deactivated() {},
  methods: {
    translate,
    handleUpload(event) {
      const files = event.target.files;
      const rawFile = files;
      if (!files.length) return;
      this.upload(rawFile);
    },

    handleDragover(event) {
      event.stopPropagation();
      event.preventDefault();
      event.dataTransfer.dropEffect = 'copy';
    },

    handleDrop(event) {
      event.stopPropagation();
      event.preventDefault();
      const files = event.dataTransfer.files;
      const rawFile = files;
      if (!files.length) return;
      this.upload(rawFile);
      event.stopPropagation();
      event.preventDefault();
    },

    isRightSuffix(file) {
      const fileExt = '.' + file.name.split('.').pop().toLowerCase();
      const allowedExtArray = this.acceptedFormats.split(',');
      return allowedExtArray.includes(fileExt);
    },

    async upload(rawFile) {
      this.uploadError = false;
      const data = {
        files: rawFile,
        type: this.uploadType
      };
      this.$emit('upload-skill-file', data);
    },

    showToast(msg, className, duration = null, animation = false) {
      let options = {
        containerClass: 'universal-toast-container',
        iconPack: 'universal-icon-container',
        className: className,
        position: 'bottom',
        duration: duration,
        action: {
          text: '',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          }
        }
      };

      animation &&
        (options = {
          ...options,
          icon: {
            name: ' ',
            after: true
          }
        });
      this.$toasted.show(msg, options);
    }
  },

  metaInfo() {
    return {
      title: translate('pageTitles.hr_tools')
    };
  }
};
</script>
<style lang="scss">
.task-options {
  display: flex;
  gap: 2rem;
  align-items: center;
  .v-selection-control__input,
  .v-selection-control__wrapper {
    height: fit-content;
    width: fit-content;
  }
  .v-input__details {
    display: none !important;
  }
  .v-selection-control .v-label {
    color: #2d2d2d;
    @include body-font-1;
    opacity: 1;
  }
  .v-input {
    flex: unset;
    .v-input__control {
      padding-right: 0px;
    }
  }
  .v-checkbox .v-selection-control {
    min-height: 2rem !important;
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  .v-selection-control--disabled {
    opacity: 1;
    color: #1f3370;
  }
  .v-input--disabled {
    opacity: 0.5 !important;
  }
}
.skill-taxonomy-header {
  .v-input {
    width: 320px !important;
  }
  .v-select .v-field.v-field {
    height: 40px !important;
  }
  .v-field--center-affix .v-field__append-inner {
    height: 40px !important;
  }
  .v-field__input {
    padding: 8px !important;
    height: 40px !important;
  }
  .v-input__details {
    display: none !important;
  }
  .v-select__selection {
    align-self: baseline;
  }
}
</style>
<style lang="scss" scoped>
.skill-extraction-container {
  background: #fff;
  padding: 3rem;
  border-radius: 1.5rem;
  h3 {
    @include header-font-2;
    color: #2d2d2d;
    margin-bottom: 2rem;
  }
}
.skill-task-header {
  margin-bottom: 2rem;
  h6 {
    @include body-font-bold-0-875;
  }
}
.skill-taxonomy-header {
  margin: 2rem 0;
  h6 {
    margin-bottom: 8px;
    @include body-font-bold-0-875;
  }
}
.upload-error-message {
  color: var(--PE-Status-Colors-Warning-Status, #e0301e);
  @include body-font-0-75;
}
.icon-medium {
  height: 1.5rem;
  width: 1.5rem;
}
.file-upload-container {
  display: flex;
  //height: 183px;
  padding: 32px 16px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border: 1px dashed var(--Colors-Grayscale-Grey-Lighter1, #adadad);
  background: var(--Colors-Primary-White, #fff);
  margin-bottom: 2rem;

  .upload-btn-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .file-details {
      width: 100%;
    }
    .upload-icon {
      color: #2d2d2d;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    .supported-format {
      @include body-font-0-75;
    }
  }
  .file-upload-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .file-size-text {
      @include body-font-0-875;
    }
    .choose-file {
      @include body-font-bold-1;
      .upload-input {
        display: none;
      }
    }
  }
}
.download-msg {
  margin: 2rem 0;
  @include body-font-1;
}
.file-meta-data {
  border-radius: var(--Corner-Radius-Radius-XS, 8px);
  background: var(--Fill-Gainsboro, #f5f5f5);
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;
  align-items: center;
  align-self: stretch;
  margin-bottom: 1rem;
  // .section-meta-info {
  //   display: flex;
  //   align-items: center;
  //   flex: 1;
  //   justify-content: flex-end;
  //   gap: 8px;
  // }
}
.loading-label {
  @include body-font-0-875;
  color: #2d2d2d;
}
.add {
  .v-btn__content {
    @include body-font-bold-1;
  }
}
.add:focus-visible {
  outline: black 2px solid;
  outline-offset: 1px;
}
.action-btn {
  box-shadow: none !important;
  padding: 8px 24px;
  float: right;
  display: flex;
  align-items: center;
  @include body-font-bold-1;
}
.label-view {
  @include header-font-0-875;
  color: var(--Text-Body-DarkGrey, #2d2d2d);
}
#announcement-description {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e1e1;
  border-radius: 4px;
  width: 100%;
  height: 56px;
  padding: 1rem;
  resize: none;
  color: var(--Text-Body-DarkGrey, #2d2d2d);
  @include body-font-1;
}
#announcement-description {
  height: 80px;
}
</style>
