<template>
  <div class="app-container assessments">
    <div class="insights-wrapper">
      <InsightsTab :tabs="tabs" :selected="0">
        <component :is="currentTab" class="insights-component" />
      </InsightsTab>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import localforage from 'localforage';
import Banner from '@/components/molecules/Banner';
import InsightsTab from '@/components/organisms/tab/InsightsTab';
import AssessmentsList from './components/AssessmentsList';
import { translate } from '@/plugins/i18n.js';

export default {
  name: 'Assessments',
  components: { InsightsTab, Banner, AssessmentsList },
  computed: {
    ...mapGetters(['planCreated', 'company', 'logo'])
  },
  data() {
    return {
      organization: '',
      logoImg: '',
      planExist: false,
      tabs: [{ title: 'Capstone Projects Queue', component: 'AssessmentsList' }],
      selectedTabIndex: 0,
      currentTab: 'AssessmentsList',
      bannerLeftBtn: {},
      bannerRightBtn: {}
    };
  },
  async mounted() {
    this.organization = (await localforage.getItem('my_company')) || this.company;
    this.planExist = (await localforage.getItem('my_plan_created')) || this.planCreated;
    this.logoImg = (await localforage.getItem('my_logo')) || this.logo;
    this.bannerLeftBtn = { text: '', action: null };
    this.bannerRightBtn = this.planExist ? { text: '', action: null } : { text: 'Create Plan', action: this.onCreate };
  },
  metaInfo() {
    return {
      title: translate('pageTitles.assessments')
    };
  }
};
</script>

<style lang="scss" scoped>
.app-container.assessments {
  background: $color-grey-lighter;
  z-index: 0;
}

.dots-array {
  float: right;
  margin: 5px 200px;
  padding: 0px 0px;
  width: 250px;
  height: 150px;
  object-fit: cover;
}

.insights-wrapper {
  margin-top: 3rem;
  padding: 1% 1%;
  background-color: $color-grey-lighter;

  .insights-header {
    display: flex;
    flex-direction: column;
    align-items: left;

    .insights-title {
      margin-bottom: 10px;
      font-size: 40px;
      font-weight: bold;
      margin-right: 50px;
      color: $color-primary;
    }

    .insights-description {
      font-size: 18px;
    }
  }

  .insights-component {
    margin: 20px 0px;
    padding: 0px 0px;
  }
}
</style>
