<template>
  <div class="dashboard-trends-chart">
    <div class="enrollment-trends-container">
      <div id="chart" v-if="dataReady">
        <div class="chart-actions">
          <v-btn name="download" icon aria-label="download Trends" title="download Trends" @click="downloadPNG()" class="enrollmentTrendsFocus"><svg-icon icon-class="PNG_icon" class="t-w-8 t-h-8" /></v-btn>
        </div>
        <apexchart ref="trendsChart" type="bar" height="350" :options="getChartOptions()" :series="trendsChartData.chartData"></apexchart>
        <p v-if="isC1 && timeFilter === 'ALLTIME'" class="enrollment-trends-disclaimer-text">{{ $t('insights.enrollmentTrendsChartDisclaimer') }}</p>
      </div>
      <div v-else class="loader t-flex t-justify-center t-items-center">
        <img src="@/assets/images/icons/plan-loading-transparent.gif" width="150" alt="Loading Learning Track" />
      </div>
    </div>
  </div>
</template>

<script>
import localforage from 'localforage';
import { getDateForCSV, generateCSVTimeFilter, localizedMonthYR, localizedMonthName, localizedWeekName, numberWithCommas } from '@/utils';
import { translateTC, translate } from '@/plugins/i18n.js';

export default {
  name: 'EnrollmentTrends',

  props: {
    timeFilter: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    drillDownTableFilters: {
      type: Array,
      required: true,
      default() {
        return [];
      }
    },
    searchInputText: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    statusCategory: {
      type: String,
      required: true,
      default() {
        return '';
      }
    },
    orgInFocus: {
      type: Number,
      required: true,
      default() {
        return 0;
      }
    },
    dateRange: {
      type: Object
    }
  },
  data() {
    return {
      isC1: null,
      trendsChartData: {},
      dataReady: false,
      isComponentRefreshed: false
    };
  },
  async mounted() {
    this.dataReady = false;
    const clientCategory = (await localforage.getItem('my_client_category')) || this.client_category;
    this.isC1 = clientCategory && clientCategory.toLowerCase() === 'c1';
  },
  updated() {
    this.isComponentRefreshed = false;
  },
  watch: {
    timeFilter: function (newVal, oldVal) {
      if (this.timeFilter.length && newVal !== oldVal) {
        this.getEnrollmentTrends();
        this.trendsChartData = {};
        this.dataReady = false;
        this.isComponentRefreshed = true;
      }
    },
    dateRange: {
      handler() {
        if (!this.isComponentRefreshed) {
          this.getEnrollmentTrends();
          this.trendsChartData = {};
          this.dataReady = false;
        } else {
          this.isComponentRefreshed = false;
        }
      },
      deep: true
    }
  },
  methods: {
    getDateForCSV,
    generateCSVTimeFilter,
    localizedMonthYR,
    localizedMonthName,
    localizedWeekName,
    async getEnrollmentTrends() {
      const tenantGuid = await localforage.getItem('my_tenant_guid');
      const time = this.timeFilter === 'CUSTOM' && this.dateRange.startDate && this.dateRange.endDate ? `${this.timeFilter}?startDate=${this.dateRange.startDate}&endDate=${this.dateRange.endDate}&dateFormat=MM-dd-yyyy` : this.timeFilter;
      const payload = { tenantGuid: tenantGuid, timePeriod: time };

      await this.$planAdmin
        .getEnrolledTrends(payload)
        .then((response) => {
          if (response) {
            this.trendsChartData = response;
            this.trendsChartData.chartData = response.chartData.filter((item) => item.name !== 'USER_UPLOADED').map((item) => ({ ...item, isVisible: true }));
            this.dataReady = true;
          }
        })
        .catch((error) => {
          throw new Error(error);
        });
    },
    getChartOptions() {
      const tickAmount = this.evaluateChartData(this.trendsChartData.chartData) ? 1 : undefined;
      return {
        colors: ['#00a78d', '#1f3370', '#E0E1E1'],
        chart: {
          toolbar: {
            show: false
          },
          type: 'bar',
          events: {
            legendClick: (chartContext, seriesIndex, config) => {
              this.handleLegendClick(seriesIndex, config);
            }
          },
          height: 350,
          stacked: true,
          zoom: {
            enabled: true
          }
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              states: {
                hover: {
                  filter: {
                    type: 'none'
                  }
                }
              },
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0,
                markers: {
                  radius: 20
                }
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 0,
            columnWidth: 30
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories:
            this.timeFilter == 'ALLTIME'
              ? this.formatMonthYR(this.trendsChartData.axisReferenceData)
              : this.timeFilter == 'YEAR'
              ? this.formatMonthName(this.trendsChartData.axisReferenceData)
              : this.timeFilter == 'DAYS90'
              ? this.formatWeekName(this.trendsChartData.axisData)
              : this.trendsChartData.axisData
        },
        yaxis: {
          tickAmount: tickAmount,
          labels: {
            formatter: function (val) {
              return numberWithCommas(val.toFixed(0));
            }
          },
          title: {
            text: translateTC('common.titleCase.user', 2),
            rotate: 360,
            offsetX: -8,
            style: {
              fontSize: '12px',
              fontFamily: 'Arial',
              color: '#5C5C5C'
            }
          }
        },
        tooltip: {
          y: {
            title: {
              formatter: (val) => {
                return val == 'USER_ENROLLED' ? translate('insights.enrollments.enrolledLabel') : val == 'USER_INVITED' ? translate('insights.enrollments.invitedLabel') : val == 'USER_NOT_INVITED' ? translate('insights.enrollments.notInvitedLabel') : '';
              }
            }
          }
        },
        legend: {
          position: 'right',
          offsetY: 80,
          offsetX: -20,
          inverseOrder: true,
          horizontalAlign: 'center',
          formatter: (val) => {
            return val == 'USER_ENROLLED' ? translate('insights.enrollments.enrolledLabel') : val == 'USER_INVITED' ? translate('insights.enrollments.invitedLabel') : val == 'USER_NOT_INVITED' ? translate('insights.enrollments.notInvitedLabel') : '';
          },
          markers: {
            radius: '50%',
            offsetX: -5
          },
          itemMargin: {
            horizontal: 5,
            vertical: 8
          }
        },
        fill: {
          opacity: 1
        }
      };
    },
    handleLegendClick(seriesIndex, config) {
      if (config.config.series[seriesIndex]) {
        if (config.config.series[seriesIndex].data.length) {
          config.config.series[seriesIndex].isVisible = !config.config.series[seriesIndex].isVisible;
          const visibleSeriesData = config.config.series.filter((chart) => {
            return chart.isVisible === true;
          });
          this.updateYAxis(visibleSeriesData);
        }
      }
    },
    updateYAxis(visibleSeriesData) {
      const tickAmount = this.evaluateChartData(visibleSeriesData) ? 1 : undefined;
      this.$refs.trendsChart.updateOptions(
        {
          yaxis: {
            tickAmount: tickAmount,
            labels: {
              formatter: function (val) {
                return numberWithCommas(val.toFixed(0));
              }
            },
            title: {
              text: translateTC('common.titleCase.user', 2),
              rotate: 360,
              offsetX: -8,
              style: {
                fontSize: '12px',
                fontFamily: 'Arial',
                color: '#5C5C5C'
              }
            }
          }
        },
        true,
        false
      );
    },
    evaluateChartData(chartData) {
      const hasGreaterThanOne = chartData.some((series) => series.data.some((value) => value > 1));
      if (hasGreaterThanOne) return false;
      const allZero = chartData.every((series) => series.data.every((value) => value === 0));
      if (allZero) return false;
      return true;
    },
    formatMonthYR(xaxisArray) {
      return xaxisArray.map((x) => {
        return localizedMonthYR(x);
      });
    },
    formatMonthName(xaxisArray) {
      return xaxisArray.map((x) => {
        return localizedMonthName(x);
      });
    },
    formatWeekName(xaxisArray) {
      return xaxisArray.map((x) => {
        return localizedWeekName(x);
      });
    },
    downloadPNG() {
      this.$refs.trendsChart.dataURI().then(({ imgURI }) => {
        let a = document.createElement('a');
        a.setAttribute('id', 'download_png-anchor');
        a.href = imgURI;
        a.setAttribute('download', `Org Enrollment Trend Chart_${this.generateCSVTimeFilter(this.timeFilter)}_${this.getDateForCSV()}`);
        a.click();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.enrollmentTrendsFocus:focus-visible {
  height: 32px;
  width: 32px;
  margin-top: 2.4px;
  margin-right: 2px;
  margin-bottom: 2px;
}
.chart-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}
.enrollment-trends-container {
  min-height: 23.875rem;
  flex: 1;

  .enrollment-trends-disclaimer-text {
    font: normal normal normal 0.75rem/0.875rem Arial;
    letter-spacing: 0rem;
    color: #2d2d2d;
  }
}
.loader {
  margin: 0px !important;
  height: 100%;
  text-align: center;

  .loader-text {
    font-size: 1.875rem;
    color: $color-primary;
  }

  .loader-bar {
    position: relative;
    top: -1.875rem;
  }
}
</style>
