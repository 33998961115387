<template>
  <div class="assessment-wrapper">
    <v-card data-el="assessmentDetail">
      <div class="assessment-credentials">
        <v-row class="credentials-row">
          <v-col cols="2" class="credentials-item"
            ><div class="credentials">{{ $t('assessments.credential') }}</div></v-col
          >
          <v-col cols="8" class="credentials-item">{{ assessment.credentialTitle }}</v-col>
        </v-row>
      </div>
      <div class="assessment-header">
        <h2 class="assessment-title">{{ $sanitize(assessment.name) }}</h2>
      </div>
      <div class="assessment-description">{{ $sanitize(assessment.shortDescription) }}</div>
      <div class="assessment-footer">
        <v-row style="padding: 0; margin: 0">
          <v-col class="footer-item">Author: {{ assessmentAuthor }}</v-col>
          <v-col cols="2" class="footer-item">
            <div class="status">
              <button class="reviewButton" @click="toAssessmentDetail(assessment)" :aria-label="`${$sanitize(assessment.name)} - ${assessment.status}`">{{ assessment.status }}</button>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AssessmentCard',
  props: {
    assessment: {
      type: Object,
      required: true,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    assessmentAuthor() {
      let name = '';
      if (this.assessment.lastName) {
        name = this.assessment.firstName ? `${this.assessment.firstName} ${this.assessment.lastName}` : this.assessment.lastName;
      } else {
        name = this.assessment.firstName ? this.assessment.firstName : 'None Available';
      }

      return name;
    }
  },
  methods: {
    toAssessmentDetail(assessment) {
      analytics.setVarAndTrack({ c: 'Assessments', l: 'AssessmentCard', a: 'click' });
      this.$gtag.event('Assessments', {
        event_category: 'click',
        event_label: 'AssessmentCard' + assessment.id,
        value: 1
      });
      this.$router.push({ name: 'AssessmentDetail', params: { id: assessment.id } });
    }
  }
};
</script>

<style lang="scss" scoped>
.reviewButton:focus-visible {
  width: 100%;
}
.v-card {
  padding: 25px 35px;
  margin-bottom: 20px;
  border-radius: 10px;

  .assessment-header {
    display: flex;
    .assessment-title {
      font-size: 25px;
      font-weight: bold;
    }
  }

  .assessment-description {
    margin-top: 10px;
    color: $color-grey;
    display: -webkit-box;
    max-width: 100%;
    margin-bottom: 1rem;
    line-height: 1.25;
    height: 4.7rem;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .assessment-footer {
    border-top: 2px solid $color-grey-light;
    padding: 10px 0px 0px 0px;
    display: flex;
    .footer-item {
      padding: 0;
      font-size: 14px;
      text-transform: uppercase;
      color: $color-black;
      font-weight: 600;
    }
  }

  .status {
    background: $color-secondary;
    border-radius: 5px;
    // padding: 0px 10px;
    text-align: center;
  }
  .assessment-credentials {
    padding: 20px 0px 40px 0px;
    display: flex;
    .credentials-item {
      padding: 0px 20px 0px 0px;
      font-size: 14px;
      text-transform: uppercase;
      color: $color-black;
      font-weight: 600;
    }
  }
  .credentials-row {
    padding: 0px;
    margin: 0px;
  }
  .credentials {
    background: $color-secondary;
    border-radius: 12px;
    text-align: center;
  }
}
.v-card.v-sheet.theme--light {
  border-left: 5px solid $color-secondary !important;
}
</style>
